<template>
  <div class="inputer">
    <div class="edittitle__background" v-on:click.stop="notify()"></div>
    <input
      ref="inputer_input"
      class="inputer__input"
      type="text"
      placeholder="ページ名を入力する"
      v-model="inputtext"
      @keydown.enter="notify()"
    />
  </div>
</template>

<style scoped>
input[type='text'] {
  border: none;
  box-shadow: initial;
  text-align: left;
  /* font: initial; */
  font-size: 11px;
  /* letter-spacing: 2.16px; */
  color: #3f4a56;
  background: rgba(255,255,255,.0)
}

input[type='text']:focus {
  box-shadow: initial;
  outline: 0;
}
</style>

<style scoped>
.edittitle__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  z-index: 1;
}

.inputer__input {
  position: relative;
  z-index: 2;
}
</style>

<script>
export default {
  name: "Inputer",
  data() {
    return {
      inputtext: "",
    };
  },
  computed: {},
  mounted() {
    this.$refs.inputer_input.focus();
    this.inputtext = this.inputer.text;
  },
  methods: {
    notify() {
      this.inputer.notify(this.inputtext);
    },
  },
  components: {},
  props: {
    inputer: Object,
  },
};
</script>
