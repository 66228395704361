/**
 * @fileoverview gRPC-Web generated client stub for adx.sprint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.sprint = require('./sprint_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.sprint.SprintServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.sprint.SprintServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.sprint.GenerateNewSprintRequest,
 *   !proto.adx.sprint.GenerateNewSprintResponse>}
 */
const methodDescriptor_SprintService_GenerateNewSprint = new grpc.web.MethodDescriptor(
  '/adx.sprint.SprintService/GenerateNewSprint',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.sprint.GenerateNewSprintRequest,
  proto.adx.sprint.GenerateNewSprintResponse,
  /**
   * @param {!proto.adx.sprint.GenerateNewSprintRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.sprint.GenerateNewSprintResponse.deserializeBinary
);


/**
 * @param {!proto.adx.sprint.GenerateNewSprintRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.GenerateNewSprintResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServiceClient.prototype.generateNewSprint =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/GenerateNewSprint',
      request,
      metadata || {},
      methodDescriptor_SprintService_GenerateNewSprint);
};


/**
 * @param {!proto.adx.sprint.GenerateNewSprintRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.GenerateNewSprintResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServicePromiseClient.prototype.generateNewSprint =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/GenerateNewSprint',
      request,
      metadata || {},
      methodDescriptor_SprintService_GenerateNewSprint);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.sprint.CreateSprintRequest,
 *   !proto.adx.sprint.CreateSprintResponse>}
 */
const methodDescriptor_SprintService_CreateSprint = new grpc.web.MethodDescriptor(
  '/adx.sprint.SprintService/CreateSprint',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.sprint.CreateSprintRequest,
  proto.adx.sprint.CreateSprintResponse,
  /**
   * @param {!proto.adx.sprint.CreateSprintRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.sprint.CreateSprintResponse.deserializeBinary
);


/**
 * @param {!proto.adx.sprint.CreateSprintRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.CreateSprintResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServiceClient.prototype.createSprint =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/CreateSprint',
      request,
      metadata || {},
      methodDescriptor_SprintService_CreateSprint);
};


/**
 * @param {!proto.adx.sprint.CreateSprintRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.CreateSprintResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServicePromiseClient.prototype.createSprint =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/CreateSprint',
      request,
      metadata || {},
      methodDescriptor_SprintService_CreateSprint);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.sprint.DeleteSprintRequest,
 *   !proto.adx.sprint.DeleteSprintResponse>}
 */
const methodDescriptor_SprintService_DeleteSprint = new grpc.web.MethodDescriptor(
  '/adx.sprint.SprintService/DeleteSprint',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.sprint.DeleteSprintRequest,
  proto.adx.sprint.DeleteSprintResponse,
  /**
   * @param {!proto.adx.sprint.DeleteSprintRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.sprint.DeleteSprintResponse.deserializeBinary
);


/**
 * @param {!proto.adx.sprint.DeleteSprintRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.DeleteSprintResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServiceClient.prototype.deleteSprint =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/DeleteSprint',
      request,
      metadata || {},
      methodDescriptor_SprintService_DeleteSprint);
};


/**
 * @param {!proto.adx.sprint.DeleteSprintRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.DeleteSprintResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServicePromiseClient.prototype.deleteSprint =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/DeleteSprint',
      request,
      metadata || {},
      methodDescriptor_SprintService_DeleteSprint);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.sprint.GetConsultantSprintsRequest,
 *   !proto.adx.sprint.GetConsultantSprintsResponse>}
 */
const methodDescriptor_SprintService_GetConsultantSprints = new grpc.web.MethodDescriptor(
  '/adx.sprint.SprintService/GetConsultantSprints',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.sprint.GetConsultantSprintsRequest,
  proto.adx.sprint.GetConsultantSprintsResponse,
  /**
   * @param {!proto.adx.sprint.GetConsultantSprintsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.sprint.GetConsultantSprintsResponse.deserializeBinary
);


/**
 * @param {!proto.adx.sprint.GetConsultantSprintsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.GetConsultantSprintsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServiceClient.prototype.getConsultantSprints =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/GetConsultantSprints',
      request,
      metadata || {},
      methodDescriptor_SprintService_GetConsultantSprints);
};


/**
 * @param {!proto.adx.sprint.GetConsultantSprintsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.GetConsultantSprintsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServicePromiseClient.prototype.getConsultantSprints =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/GetConsultantSprints',
      request,
      metadata || {},
      methodDescriptor_SprintService_GetConsultantSprints);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.sprint.GetAdvertizerSprintsRequest,
 *   !proto.adx.sprint.GetAdvertizerSprintsResponse>}
 */
const methodDescriptor_SprintService_GetAdvertizerSprints = new grpc.web.MethodDescriptor(
  '/adx.sprint.SprintService/GetAdvertizerSprints',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.sprint.GetAdvertizerSprintsRequest,
  proto.adx.sprint.GetAdvertizerSprintsResponse,
  /**
   * @param {!proto.adx.sprint.GetAdvertizerSprintsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.sprint.GetAdvertizerSprintsResponse.deserializeBinary
);


/**
 * @param {!proto.adx.sprint.GetAdvertizerSprintsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.GetAdvertizerSprintsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServiceClient.prototype.getAdvertizerSprints =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/GetAdvertizerSprints',
      request,
      metadata || {},
      methodDescriptor_SprintService_GetAdvertizerSprints);
};


/**
 * @param {!proto.adx.sprint.GetAdvertizerSprintsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.GetAdvertizerSprintsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServicePromiseClient.prototype.getAdvertizerSprints =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/GetAdvertizerSprints',
      request,
      metadata || {},
      methodDescriptor_SprintService_GetAdvertizerSprints);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.sprint.CreateCustomFieldRequest,
 *   !proto.adx.sprint.CreateCustomFieldResponse>}
 */
const methodDescriptor_SprintService_CreateCustomField = new grpc.web.MethodDescriptor(
  '/adx.sprint.SprintService/CreateCustomField',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.sprint.CreateCustomFieldRequest,
  proto.adx.sprint.CreateCustomFieldResponse,
  /**
   * @param {!proto.adx.sprint.CreateCustomFieldRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.sprint.CreateCustomFieldResponse.deserializeBinary
);


/**
 * @param {!proto.adx.sprint.CreateCustomFieldRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.CreateCustomFieldResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServiceClient.prototype.createCustomField =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/CreateCustomField',
      request,
      metadata || {},
      methodDescriptor_SprintService_CreateCustomField);
};


/**
 * @param {!proto.adx.sprint.CreateCustomFieldRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.CreateCustomFieldResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServicePromiseClient.prototype.createCustomField =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/CreateCustomField',
      request,
      metadata || {},
      methodDescriptor_SprintService_CreateCustomField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.sprint.UpdateCustomFieldRequest,
 *   !proto.adx.sprint.UpdateCustomFieldResponse>}
 */
const methodDescriptor_SprintService_UpdateCustomField = new grpc.web.MethodDescriptor(
  '/adx.sprint.SprintService/UpdateCustomField',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.sprint.UpdateCustomFieldRequest,
  proto.adx.sprint.UpdateCustomFieldResponse,
  /**
   * @param {!proto.adx.sprint.UpdateCustomFieldRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.sprint.UpdateCustomFieldResponse.deserializeBinary
);


/**
 * @param {!proto.adx.sprint.UpdateCustomFieldRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.UpdateCustomFieldResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServiceClient.prototype.updateCustomField =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/UpdateCustomField',
      request,
      metadata || {},
      methodDescriptor_SprintService_UpdateCustomField);
};


/**
 * @param {!proto.adx.sprint.UpdateCustomFieldRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.UpdateCustomFieldResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServicePromiseClient.prototype.updateCustomField =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/UpdateCustomField',
      request,
      metadata || {},
      methodDescriptor_SprintService_UpdateCustomField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.sprint.DeleteCustomFieldRequest,
 *   !proto.adx.sprint.DeleteCustomFieldResponse>}
 */
const methodDescriptor_SprintService_DeleteCustomField = new grpc.web.MethodDescriptor(
  '/adx.sprint.SprintService/DeleteCustomField',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.sprint.DeleteCustomFieldRequest,
  proto.adx.sprint.DeleteCustomFieldResponse,
  /**
   * @param {!proto.adx.sprint.DeleteCustomFieldRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.sprint.DeleteCustomFieldResponse.deserializeBinary
);


/**
 * @param {!proto.adx.sprint.DeleteCustomFieldRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.DeleteCustomFieldResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServiceClient.prototype.deleteCustomField =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/DeleteCustomField',
      request,
      metadata || {},
      methodDescriptor_SprintService_DeleteCustomField);
};


/**
 * @param {!proto.adx.sprint.DeleteCustomFieldRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.DeleteCustomFieldResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServicePromiseClient.prototype.deleteCustomField =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/DeleteCustomField',
      request,
      metadata || {},
      methodDescriptor_SprintService_DeleteCustomField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.sprint.GetCustomFieldsRequest,
 *   !proto.adx.sprint.GetCustomFieldsResponse>}
 */
const methodDescriptor_SprintService_GetCustomFields = new grpc.web.MethodDescriptor(
  '/adx.sprint.SprintService/GetCustomFields',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.sprint.GetCustomFieldsRequest,
  proto.adx.sprint.GetCustomFieldsResponse,
  /**
   * @param {!proto.adx.sprint.GetCustomFieldsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.sprint.GetCustomFieldsResponse.deserializeBinary
);


/**
 * @param {!proto.adx.sprint.GetCustomFieldsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.GetCustomFieldsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServiceClient.prototype.getCustomFields =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/GetCustomFields',
      request,
      metadata || {},
      methodDescriptor_SprintService_GetCustomFields);
};


/**
 * @param {!proto.adx.sprint.GetCustomFieldsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.sprint.GetCustomFieldsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.sprint.SprintServicePromiseClient.prototype.getCustomFields =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.sprint.SprintService/GetCustomFields',
      request,
      metadata || {},
      methodDescriptor_SprintService_GetCustomFields);
};


module.exports = proto.adx.sprint;

