<template>
  <div class="customreport_range_calendar">
    <div v-if="false">
      <div class="customreport_range_calendar__selector"
        style="margin: auto; width: 200px; margin-top: 16px; margin-bottom: 16px" v-if="true">
        <div v-on:click.stop="selectYmdMode('month')">
          <img width="12" height="12" src="@/assets/icon/checkbox_no.png" v-if="!ismc" /><img width="12" height="12"
            src="@/assets/icon/checkbox.png" v-if="ismc" />
          月別
        </div>
        <div v-on:click.stop="selectYmdMode('week')" style="margin-left: 24px; margin-right: 24px">
          <img width="12" height="12" src="@/assets/icon/checkbox_no.png" v-if="!iswc" /><img width="12" height="12"
            src="@/assets/icon/checkbox.png" v-if="iswc" />
          週別
        </div>
        <div v-on:click.stop="selectYmdMode('day')">
          <img width="12" height="12" src="@/assets/icon/checkbox_no.png" v-if="!isdc" /><img width="12" height="12"
            src="@/assets/icon/checkbox.png" v-if="isdc" />
          日別
        </div>
      </div>
    </div>
    <div v-if="false" style="font-size: 12px; color: rgb(175 175 175); text-align: center">
      ※選択した日付において合計値を表示したい場合はチェックしないでください
    </div>
    <div class="cr_range_calender">
      <div class="cr_range_calender__box">
        <div class="cr_selected_day" :class="[mode == 'start' ? 'cr_selected_day--active' : '']"
          style="display: flex; padding: 1px; border-radius: 5px">
          <img src="@/assets/icon/date_range-24px.svg" style="
                      margin-left: 20px;
                      margin-right: 20px;
                      margin-top: 4px;
                      width: 18px;
                      height: 18px;
                    " />
          <div style="font: 18px Noto Sans JP; letter-spacing: 0px; color: #4d4f5c" v-if="!isEditStartDate"
            v-on:click="editStartDate($event)">
            {{ crrc.selector.range.startYmd.year }}/{{ crrc.selector.range.startYmd.month }}/{{
              crrc.selector.range.startYmd.day
            }}
          </div>
          <div style="font: 18px Noto Sans JP; letter-spacing: 0px; color: #4d4f5c">
            <input type="date" id="start" name="trip-start" v-if="isEditStartDate"
              v-on:keydown.enter="setStartDate($event)" value="2022-02-22" class="icon-del">
          </div>

        </div>

        <div class="cr_range_calender__ctl">
          <div v-on:click.stop="bb()">
            <img src="@/assets/icon/keyboard_arrow_left-24px.svg" class="date_fromto_selector__minusmonth" />
          </div>
          <div style="margin: auto">{{ baseymd.year }} {{ baseymd.month }}</div>
          <div v-on:click.stop="ba()" v-if="false">
            <img src="@/assets/icon/keyboard_arrow_right-24px.svg" class="date_fromto_selector__plusmonth" />
          </div>
        </div>
        <div class="cr_range_calender__calender">
          <CalendarR :mode="mode" :year="baseymd.year" :month="baseymd.month" :range="crrc.selector.range" />
        </div>
      </div>
      <div class="cr_range_calender__box">
        <div class="cr_selected_day" :class="[mode == 'end' ? 'cr_selected_day--active' : '']"
          style="display: flex; padding: 1px; border-radius: 5px">
          <img src="@/assets/icon/date_range-24px.svg" style="
                      margin-left: 20px;
                      margin-right: 20px;
                      margin-top: 4px;
                      width: 18px;
                      height: 18px;
                    " />
          <div style="font: 18px Noto Sans JP; letter-spacing: 0px; color: #4d4f5c" v-if="!isEditEndDate"
            v-on:click="editEndDate()">
            {{ crrc.selector.range.endYmd.year }}/{{ crrc.selector.range.endYmd.month }}/{{
              crrc.selector.range.endYmd.day
            }}
          </div>
          <div style="font: 18px Noto Sans JP; letter-spacing: 0px; color: #4d4f5c">
            <input type="date" id="start" name="trip-start" v-if="isEditEndDate" v-on:keydown.enter="setEndDate($event)"
              value="2022-02-22" class="icon-del">
          </div>

        </div>

        <div class="cr_range_calender__ctl">
          <div v-on:click.stop="ab()" v-if="false">
            <img src="@/assets/icon/keyboard_arrow_left-24px.svg" class="date_fromto_selector__minusmonth" />
          </div>
          <div style="margin: auto">{{ nextMonthYmd().year }} {{ nextMonthYmd().month }}</div>
          <div v-on:click.stop="aa()">
            <img src="@/assets/icon/keyboard_arrow_right-24px.svg" class="date_fromto_selector__plusmonth" />
          </div>
        </div>
        <div class="cr_range_calender__calender">
          <CalendarR :mode="mode" :year="nextMonthYmd().year" :month="nextMonthYmd().month"
            :range="crrc.selector.range" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.icon-del::-webkit-calendar-picker-indicator {
  display: none;
}

.customreport_range_calendar {
  padding: 8px;
  width: fit-content;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d7dae2;
  border-radius: 3px;
}

.customreport_range_calendar__selector {
  display: flex;
}

.cr_range_calender {
  display: flex;
}

.cr_range_calender__box {
  padding: 8px;
}

.cr_range_calender__calender {
  width: 245px;
}

.cr_range_calender__ctl {
  pointer-events: all;
  display: flex;
}

.cr_selected_day {
  border: 1px solid rgb(210, 215, 221);
}

.cr_selected_day--active {
  border: 1px solid rgb(60 149 255);
}
</style>

<script>
import CalendarR from '@/components/CalendarR.vue';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';

export default {
  name: 'CustomReportRangeCalendar',
  data() {
    return {
      // c1: new TimeYmdRange(),
      mode: 'end',
      ymdmode: 'year',
      baseymd: new TimeYmd(),
      ismc: false,
      iswc: false,
      isdc: false,
      stat: 0,
      isEditStartDate: false,
      isEditEndDate: false,
    };
  },
  computed: {},
  mounted() {
    this.baseymd.year = this.crrc.selector.range.startYmd.year;
    this.baseymd.month = this.crrc.selector.range.startYmd.month;
    this.baseymd.day = this.crrc.selector.range.startYmd.day;
    this.crrc.selector.subject.removeAll();
    this.crrc.selector.subject.addObserver(this.calendarchange);
  },
  methods: {
    setStartDate() {
      this.isEditStartDate = false;
    },
    setEndDate() {
      this.isEditEndDate = false;
    },
    editStartDate() {
      this.isEditStartDate = true;
    },
    editEndDate() {
      this.isEditEndDate = true;
    },
    calendarchange() {
      if (this.mode == 'start') {
        this.mode = 'end';
      } else {
        this.mode = 'start';
      }
      let eymd = this.crrc.selector.range.endYmd;
      let symd = this.crrc.selector.range.startYmd;
      if (symd.year == eymd.year && symd.month == eymd.month && symd.day == eymd.day) {
        this.mode = 'end';
      }
    },
    mctoggle() {
      this.ismc = !this.ismc;
    },
    wctoggle() {
      this.iswc = !this.iswc;
    },
    dctoggle() {
      this.isdc = !this.isdc;
    },
    test() { },
    ab() {
      this.baseymd.decMonth();
    },
    aa() {
      this.baseymd.incMonth();
    },
    bb() {
      this.baseymd.decMonth();
    },
    ba() {
      this.baseymd.incMonth();
    },
    nextMonthYmd() {
      let ymd = new TimeYmd(this.baseymd.year, this.baseymd.month, this.baseymd.day);
      ymd.incMonth();
      return ymd;
    },
    _changeYmd() {
      if (this.ismc) {
        this.crrc?.selector.setYmdMode(1);
        return;
      }
      if (this.iswc) {
        this.crrc?.selector.setYmdMode(2);
        return;
      }
      if (this.isdc) {
        this.crrc?.selector.setYmdMode(3);
        return;
      }
      this.crrc?.selector.setYmdMode(0);
    },
    selectYmdMode(ymd) {
      switch (ymd) {
        case 'month':
          this.ismc = !this.ismc;
          this.iswc = false;
          this.isdc = false;
          break;
        case 'week':
          this.iswc = !this.iswc;
          this.ismc = false;
          this.isdc = false;
          break;
        case 'day':
          this.isdc = !this.isdc;
          this.ismc = false;
          this.iswc = false;
          break;
      }
      this._changeYmd();
      this.ymdmode = ymd;
    },
  },
  components: {
    CalendarR,
  },
  props: {
    crrc: Object,
  },
};
</script>
