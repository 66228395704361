// source: report_page/consultant_ad_provider_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.displayName = 'proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 adProviderId = 3;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    dimensionList: jspb.Message.toObjectList(msg.getDimensionList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.toObject, includeInstance),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.deserializeBinaryFromReader);
      msg.addDimension(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDimensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension.serializeBinaryToWriter
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Dimension dimension = 1;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.getDimensionList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension, 1));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.setDimensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.addDimension = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Dimension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.clearDimensionList = function() {
  return this.setDimensionList([]);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Report report = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 categoryId = 6;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string sort = 7;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    adgroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdgroup(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdgroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string adGroup = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.prototype.getAdgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.prototype.setAdgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adgroupreportList: jspb.Message.toObjectList(msg.getAdgroupreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.deserializeBinaryFromReader);
      msg.addAdgroupreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdgroupreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AdGroupReport adGroupReport = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.prototype.getAdgroupreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.prototype.setAdgroupreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.prototype.addAdgroupreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.AdGroupReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.prototype.clearAdgroupreportList = function() {
  return this.setAdgroupreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignreportList: jspb.Message.toObjectList(msg.getCampaignreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.deserializeBinaryFromReader);
      msg.addCampaignreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CampaignReport campaignReport = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.getCampaignreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.setCampaignreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.addCampaignreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.CampaignReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result.prototype.clearCampaignreportList = function() {
  return this.setCampaignreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryidList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string categoryId = 4;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.getCategoryidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.setCategoryidList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.addCategoryid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.clearCategoryidList = function() {
  return this.setCategoryidList([]);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    monthlyreportList: jspb.Message.toObjectList(msg.getMonthlyreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader);
      msg.addMonthlyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonthlyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MonthlyReport monthlyReport = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.getMonthlyreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.setMonthlyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.addMonthlyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.MonthlyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.prototype.clearMonthlyreportList = function() {
  return this.setMonthlyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryreportList: jspb.Message.toObjectList(msg.getCategoryreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.deserializeBinaryFromReader);
      msg.addCategoryreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CategoryReport categoryReport = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.getCategoryreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.setCategoryreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.addCategoryreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.CategoryReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result.prototype.clearCategoryreportList = function() {
  return this.setCategoryreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryidList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string categoryId = 4;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.getCategoryidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.setCategoryidList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.addCategoryid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.clearCategoryidList = function() {
  return this.setCategoryidList([]);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    weeklyreportList: jspb.Message.toObjectList(msg.getWeeklyreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader);
      msg.addWeeklyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWeeklyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated WeeklyReport weeklyReport = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.getWeeklyreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.setWeeklyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.addWeeklyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.WeeklyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.prototype.clearWeeklyreportList = function() {
  return this.setWeeklyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryreportList: jspb.Message.toObjectList(msg.getCategoryreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.deserializeBinaryFromReader);
      msg.addCategoryreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CategoryReport categoryReport = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.getCategoryreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.setCategoryreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.addCategoryreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.CategoryReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result.prototype.clearCategoryreportList = function() {
  return this.setCategoryreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryidList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string categoryId = 4;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.getCategoryidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.setCategoryidList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.addCategoryid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.clearCategoryidList = function() {
  return this.setCategoryidList([]);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dailyreportList: jspb.Message.toObjectList(msg.getDailyreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.deserializeBinaryFromReader);
      msg.addDailyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDailyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DailyReport dailyReport = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.getDailyreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.setDailyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.addDailyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.DailyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.prototype.clearDailyreportList = function() {
  return this.setDailyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryreportList: jspb.Message.toObjectList(msg.getCategoryreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.deserializeBinaryFromReader);
      msg.addCategoryreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CategoryReport categoryReport = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.getCategoryreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.setCategoryreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.addCategoryreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.CategoryReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result.prototype.clearCategoryreportList = function() {
  return this.setCategoryreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string sort = 5;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 6;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthlyreportList: jspb.Message.toObjectList(msg.getMonthlyreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader);
      msg.addMonthlyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthlyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MonthlyReport monthlyReport = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.prototype.getMonthlyreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.prototype.setMonthlyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.prototype.addMonthlyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.MonthlyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.prototype.clearMonthlyreportList = function() {
  return this.setMonthlyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Report report = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string sort = 5;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 6;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    weeklyreportList: jspb.Message.toObjectList(msg.getWeeklyreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader);
      msg.addWeeklyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWeeklyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated WeeklyReport weeklyReport = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.prototype.getWeeklyreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.prototype.setWeeklyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.prototype.addWeeklyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.WeeklyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.prototype.clearWeeklyreportList = function() {
  return this.setWeeklyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Report report = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string sort = 5;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 6;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dayofweek: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDayofweek(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayofweek();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dayOfWeek = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.getDayofweek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.setDayofweek = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dailyreportList: jspb.Message.toObjectList(msg.getDailyreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.deserializeBinaryFromReader);
      msg.addDailyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDailyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DailyReport dailyReport = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.prototype.getDailyreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.prototype.setDailyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.prototype.addDailyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.DailyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.prototype.clearDailyreportList = function() {
  return this.setDailyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Report report = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    campaign: jspb.Message.getFieldWithDefault(msg, 4, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string campaign = 4;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.clearCampaign = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthlyreportList: jspb.Message.toObjectList(msg.getMonthlyreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader);
      msg.addMonthlyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthlyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MonthlyReport monthlyReport = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.prototype.getMonthlyreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.prototype.setMonthlyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.prototype.addMonthlyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.MonthlyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.prototype.clearMonthlyreportList = function() {
  return this.setMonthlyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Report report = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    campaign: jspb.Message.getFieldWithDefault(msg, 4, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string campaign = 4;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.clearCampaign = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    weeklyreportList: jspb.Message.toObjectList(msg.getWeeklyreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader);
      msg.addWeeklyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWeeklyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated WeeklyReport weeklyReport = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.prototype.getWeeklyreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.prototype.setWeeklyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.prototype.addWeeklyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.WeeklyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.prototype.clearWeeklyreportList = function() {
  return this.setWeeklyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Report report = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    campaign: jspb.Message.getFieldWithDefault(msg, 4, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string campaign = 4;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.clearCampaign = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dayofweek: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDayofweek(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayofweek();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dayOfWeek = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.getDayofweek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.setDayofweek = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dailyreportList: jspb.Message.toObjectList(msg.getDailyreportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.deserializeBinaryFromReader);
      msg.addDailyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDailyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DailyReport dailyReport = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.prototype.getDailyreportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.prototype.setDailyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.prototype.addDailyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.DailyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.prototype.clearDailyreportList = function() {
  return this.setDailyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Report report = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryidsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategoryids(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 categoryIds = 4;
 * @return {!Array<number>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.getCategoryidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.setCategoryidsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.addCategoryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.clearCategoryidsList = function() {
  return this.setCategoryidsList([]);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryidsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    categorynamesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryids(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorynames(value);
      break;
    case 5:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getCategorynamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryIds = 3;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.getCategoryidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.setCategoryidsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.addCategoryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.clearCategoryidsList = function() {
  return this.setCategoryidsList([]);
};


/**
 * repeated string categoryNames = 4;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.getCategorynamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.setCategorynamesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.addCategorynames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.clearCategorynamesList = function() {
  return this.setCategorynamesList([]);
};


/**
 * repeated Report report = 5;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report, 5));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryidsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategoryids(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 categoryIds = 4;
 * @return {!Array<number>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.getCategoryidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.setCategoryidsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.addCategoryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.clearCategoryidsList = function() {
  return this.setCategoryidsList([]);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryidsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    categorynamesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryids(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorynames(value);
      break;
    case 5:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getCategorynamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryIds = 3;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.getCategoryidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.setCategoryidsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.addCategoryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.clearCategoryidsList = function() {
  return this.setCategoryidsList([]);
};


/**
 * repeated string categoryNames = 4;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.getCategorynamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.setCategorynamesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.addCategorynames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.clearCategorynamesList = function() {
  return this.setCategorynamesList([]);
};


/**
 * repeated Report report = 5;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report, 5));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryidsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategoryids(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 categoryIds = 4;
 * @return {!Array<number>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.getCategoryidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.setCategoryidsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.addCategoryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.clearCategoryidsList = function() {
  return this.setCategoryidsList([]);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.TypeCase}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dayofweek: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDayofweek(value);
      break;
    case 3:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayofweek();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dayOfWeek = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.getDayofweek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.setDayofweek = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table, 3));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryidsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    categorynamesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result;
  return proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryids(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorynames(value);
      break;
    case 5:
      var value = new proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report;
      reader.readMessage(value,proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getCategorynamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryIds = 3;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.getCategoryidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.setCategoryidsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.addCategoryids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.clearCategoryidsList = function() {
  return this.setCategoryidsList([]);
};


/**
 * repeated string categoryNames = 4;
 * @return {!Array<string>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.getCategorynamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.setCategorynamesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.addCategorynames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.clearCategorynamesList = function() {
  return this.setCategorynamesList([]);
};


/**
 * repeated Report report = 5;
 * @return {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report>}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.getReportList = function() {
  return /** @type{!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report, 5));
};


/**
 * @param {!Array<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report>} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Report, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.Result|undefined} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse} returns this
*/
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse} returns this
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.consultant_ad_provider_report);
