// source: side_menu.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.adx.side_menu.AdvertizerListRequest', null, global);
goog.exportSymbol('proto.adx.side_menu.AdvertizerListResponse', null, global);
goog.exportSymbol('proto.adx.side_menu.AdvertizerListResponse.Result', null, global);
goog.exportSymbol('proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers', null, global);
goog.exportSymbol('proto.adx.side_menu.AdvertizerListResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.side_menu.SideMenuCategoryListRequest', null, global);
goog.exportSymbol('proto.adx.side_menu.SideMenuCategoryListResponse', null, global);
goog.exportSymbol('proto.adx.side_menu.SideMenuCategoryListResponse.Result', null, global);
goog.exportSymbol('proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories', null, global);
goog.exportSymbol('proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu', null, global);
goog.exportSymbol('proto.adx.side_menu.SideMenuCategoryListResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.side_menu.SideMenuCategoryListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.side_menu.SideMenuCategoryListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.side_menu.SideMenuCategoryListRequest.displayName = 'proto.adx.side_menu.SideMenuCategoryListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.side_menu.AdvertizerListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.side_menu.AdvertizerListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.side_menu.AdvertizerListRequest.displayName = 'proto.adx.side_menu.AdvertizerListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.side_menu.AdvertizerListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.side_menu.AdvertizerListResponse.oneofGroups_);
};
goog.inherits(proto.adx.side_menu.AdvertizerListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.side_menu.AdvertizerListResponse.displayName = 'proto.adx.side_menu.AdvertizerListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.side_menu.AdvertizerListResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.side_menu.AdvertizerListResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.side_menu.AdvertizerListResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.side_menu.AdvertizerListResponse.Result.displayName = 'proto.adx.side_menu.AdvertizerListResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.displayName = 'proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.side_menu.SideMenuCategoryListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.side_menu.SideMenuCategoryListResponse.oneofGroups_);
};
goog.inherits(proto.adx.side_menu.SideMenuCategoryListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.side_menu.SideMenuCategoryListResponse.displayName = 'proto.adx.side_menu.SideMenuCategoryListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.side_menu.SideMenuCategoryListResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.side_menu.SideMenuCategoryListResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.side_menu.SideMenuCategoryListResponse.Result.displayName = 'proto.adx.side_menu.SideMenuCategoryListResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.repeatedFields_, null);
};
goog.inherits(proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.displayName = 'proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.displayName = 'proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.side_menu.SideMenuCategoryListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.side_menu.SideMenuCategoryListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.side_menu.SideMenuCategoryListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertizerid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.side_menu.SideMenuCategoryListRequest}
 */
proto.adx.side_menu.SideMenuCategoryListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.side_menu.SideMenuCategoryListRequest;
  return proto.adx.side_menu.SideMenuCategoryListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.side_menu.SideMenuCategoryListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.side_menu.SideMenuCategoryListRequest}
 */
proto.adx.side_menu.SideMenuCategoryListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.side_menu.SideMenuCategoryListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.side_menu.SideMenuCategoryListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.side_menu.SideMenuCategoryListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 advertizerId = 1;
 * @return {number}
 */
proto.adx.side_menu.SideMenuCategoryListRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListRequest} returns this
 */
proto.adx.side_menu.SideMenuCategoryListRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.side_menu.AdvertizerListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.side_menu.AdvertizerListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.side_menu.AdvertizerListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.AdvertizerListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    aduseruuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.side_menu.AdvertizerListRequest}
 */
proto.adx.side_menu.AdvertizerListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.side_menu.AdvertizerListRequest;
  return proto.adx.side_menu.AdvertizerListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.side_menu.AdvertizerListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.side_menu.AdvertizerListRequest}
 */
proto.adx.side_menu.AdvertizerListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAduseruuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.side_menu.AdvertizerListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.side_menu.AdvertizerListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.side_menu.AdvertizerListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.AdvertizerListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAduseruuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string adUserUuid = 1;
 * @return {string}
 */
proto.adx.side_menu.AdvertizerListRequest.prototype.getAduseruuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.side_menu.AdvertizerListRequest} returns this
 */
proto.adx.side_menu.AdvertizerListRequest.prototype.setAduseruuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.side_menu.AdvertizerListResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.side_menu.AdvertizerListResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.side_menu.AdvertizerListResponse.TypeCase}
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.side_menu.AdvertizerListResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.side_menu.AdvertizerListResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.side_menu.AdvertizerListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.side_menu.AdvertizerListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.AdvertizerListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.side_menu.AdvertizerListResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.side_menu.AdvertizerListResponse}
 */
proto.adx.side_menu.AdvertizerListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.side_menu.AdvertizerListResponse;
  return proto.adx.side_menu.AdvertizerListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.side_menu.AdvertizerListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.side_menu.AdvertizerListResponse}
 */
proto.adx.side_menu.AdvertizerListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.side_menu.AdvertizerListResponse.Result;
      reader.readMessage(value,proto.adx.side_menu.AdvertizerListResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.side_menu.AdvertizerListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.side_menu.AdvertizerListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.AdvertizerListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.side_menu.AdvertizerListResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.side_menu.AdvertizerListResponse.Result.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.side_menu.AdvertizerListResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.side_menu.AdvertizerListResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.AdvertizerListResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertizersList: jspb.Message.toObjectList(msg.getAdvertizersList(),
    proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.side_menu.AdvertizerListResponse.Result}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.side_menu.AdvertizerListResponse.Result;
  return proto.adx.side_menu.AdvertizerListResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.side_menu.AdvertizerListResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.side_menu.AdvertizerListResponse.Result}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers;
      reader.readMessage(value,proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.deserializeBinaryFromReader);
      msg.addAdvertizers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.side_menu.AdvertizerListResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.side_menu.AdvertizerListResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.AdvertizerListResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertizersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers;
  return proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers} returns this
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers} returns this
 */
proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Advertizers advertizers = 1;
 * @return {!Array<!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers>}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.prototype.getAdvertizersList = function() {
  return /** @type{!Array<!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers, 1));
};


/**
 * @param {!Array<!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers>} value
 * @return {!proto.adx.side_menu.AdvertizerListResponse.Result} returns this
*/
proto.adx.side_menu.AdvertizerListResponse.Result.prototype.setAdvertizersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers}
 */
proto.adx.side_menu.AdvertizerListResponse.Result.prototype.addAdvertizers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.adx.side_menu.AdvertizerListResponse.Result.Advertizers, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.side_menu.AdvertizerListResponse.Result} returns this
 */
proto.adx.side_menu.AdvertizerListResponse.Result.prototype.clearAdvertizersList = function() {
  return this.setAdvertizersList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.side_menu.AdvertizerListResponse.Result}
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.side_menu.AdvertizerListResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.side_menu.AdvertizerListResponse.Result, 1));
};


/**
 * @param {?proto.adx.side_menu.AdvertizerListResponse.Result|undefined} value
 * @return {!proto.adx.side_menu.AdvertizerListResponse} returns this
*/
proto.adx.side_menu.AdvertizerListResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.side_menu.AdvertizerListResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.side_menu.AdvertizerListResponse} returns this
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.side_menu.AdvertizerListResponse} returns this
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.side_menu.AdvertizerListResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.side_menu.AdvertizerListResponse} returns this
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.side_menu.AdvertizerListResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.side_menu.AdvertizerListResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.side_menu.SideMenuCategoryListResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.side_menu.SideMenuCategoryListResponse.TypeCase}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.side_menu.SideMenuCategoryListResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.side_menu.SideMenuCategoryListResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.side_menu.SideMenuCategoryListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.side_menu.SideMenuCategoryListResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.side_menu.SideMenuCategoryListResponse;
  return proto.adx.side_menu.SideMenuCategoryListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.side_menu.SideMenuCategoryListResponse.Result;
      reader.readMessage(value,proto.adx.side_menu.SideMenuCategoryListResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.side_menu.SideMenuCategoryListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.side_menu.SideMenuCategoryListResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.side_menu.SideMenuCategoryListResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.side_menu.SideMenuCategoryListResponse.Result;
  return proto.adx.side_menu.SideMenuCategoryListResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories;
      reader.readMessage(value,proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.side_menu.SideMenuCategoryListResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.toObject = function(includeInstance, msg) {
  var f, obj = {
    categorytype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sidemenuList: jspb.Message.toObjectList(msg.getSidemenuList(),
    proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories;
  return proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategorytype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = new proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu;
      reader.readMessage(value,proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.deserializeBinaryFromReader);
      msg.addSidemenu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategorytype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSidemenuList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayorder: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu;
  return proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDisplayorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayorder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 displayOrder = 3;
 * @return {number}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.prototype.getDisplayorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu.prototype.setDisplayorder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string categoryType = 1;
 * @return {string}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.getCategorytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.setCategorytype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string displayName = 2;
 * @return {string}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SideMenu sideMenu = 3;
 * @return {!Array<!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu>}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.getSidemenuList = function() {
  return /** @type{!Array<!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu, 3));
};


/**
 * @param {!Array<!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu>} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories} returns this
*/
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.setSidemenuList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.addSidemenu = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.SideMenu, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories.prototype.clearSidemenuList = function() {
  return this.setSidemenuList([]);
};


/**
 * repeated Categories categories = 1;
 * @return {!Array<!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories>}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories, 1));
};


/**
 * @param {!Array<!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories>} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result} returns this
*/
proto.adx.side_menu.SideMenuCategoryListResponse.Result.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.adx.side_menu.SideMenuCategoryListResponse.Result.Categories, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse.Result} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.Result.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.side_menu.SideMenuCategoryListResponse.Result}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.side_menu.SideMenuCategoryListResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.side_menu.SideMenuCategoryListResponse.Result, 1));
};


/**
 * @param {?proto.adx.side_menu.SideMenuCategoryListResponse.Result|undefined} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse} returns this
*/
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.side_menu.SideMenuCategoryListResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.side_menu.SideMenuCategoryListResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.side_menu.SideMenuCategoryListResponse} returns this
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.side_menu.SideMenuCategoryListResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.side_menu.SideMenuCategoryListResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.side_menu);
