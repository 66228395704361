import { AdvertizerListRequest, SideMenuCategoryListRequest } from './protos_gen/side_menu_pb.js';
import { SideMenuClient } from './protos_gen/side_menu_grpc_web_pb.js';
import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function getAdvertizerList(id, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        //"X-Auth0-UUID": id,
        'access-token': dto.token,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new AdvertizerListRequest();
    request.setAduseruuid(id);

    let client = new SideMenuClient(url.grpc, null, null);
    const stream = client.getAdvertizerList(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

function getSideMenuCategoryList(id, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        // "X-Auth0-UUID": "auth0|605af9e9cea7a7007478061c",
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new SideMenuCategoryListRequest();
    request.setAdvertizerid(id);

    let client = new SideMenuClient(url.grpc, null, null);
    const stream = client.getSideMenuCategoryList(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}


export {
    getSideMenuCategoryList,
    getAdvertizerList,
}
