<template>
  <div>
    <svg :viewBox="vbox" xmlns="http://www.w3.org/2000/svg">
      <line
        :x1="stroke"
        :y1="stroke"
        :x2="100 - stroke"
        :y2="stroke"
        v-bind:stroke-width="stroke"
        stroke-linecap="round"
        stroke="#E6ECF0"
      />
      <line
        id="bar"
        class="bar"
        :x1="stroke"
        :y1="stroke"
        :x2="100 - stroke"
        :y2="stroke"
        v-bind:stroke-width="stroke"
        stroke-linecap="round"
        stroke-dasharray="100"
        v-bind:stroke-dashoffset="barp"
        v-bind:stroke="color"
        style="transition: stroke-dashoffset 0.3s linear"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Summary',
  data() {
    return {
      name: 'サマリ',
      color: '#3B86FF',
      barp: 100,
      vbox: '0 0 100 ' + this.stroke * 2,
    };
  },
  computed: {},
  mounted() {
    let self = this;
    setTimeout(function () {
      if (!isNaN(Number(self.value))) {
        let v = Number(self.value);
        if (v < 0) {
          v = 0;
        }
        if (v > 100) {
          v = 100;
        }
        self.barp = 100 - v;
      }
    }, 100);
    if (!isNaN(Number(this.value))) {
      if (Number(this.value) < 100) {
        this.color = '#ff6565';
      }
    }
  },
  methods: {},
  components: {},
  props: {
    value: String,
    stroke: {
      type: Number,
      default: 1.5,
    },
  },
};
</script>

