<template>
  <div
    tabindex="0"
    ref="fmain"
    v-on:blur="lost()"
    class="edtcstselectitem"
    style="padding: 8px; background: #fff; box-shadow: 0px 3px 20px #00000029;"
  >
    <template v-for="(option, index) in item.options" :key="index">
      <div style="height: initial;" class="selectitem" v-on:click="decide(option.id)">{{ option.name }}</div>
    </template>
  </div>
</template>

<style scoped>
.selectitem {
  text-align: center;
  padding: 8px;
}

.selectitem:hover {
  background: rgba(200, 200, 200, 0.5);
}

.edtcstselectitem:focus {
  outline: solid 1px #00000029;
  box-shadow: 0px 3px 20px #00000029;
}

</style>

<script>
export default {
  name: 'EditItem',
  data() {
    return {
      name: 'EditItem',
      selected: '',
      options: [],
    };
  },
  computed: {},
  mounted() {
    this.selected = this.item.value;
    this.$refs.fmain.focus();
  },
  methods: {
    lost() {
      this.item.decide();
    },
    decide(selected) {
      this.item.setValue(selected);
      this.item.decide();
    },
  },
  components: {},
  props: {
    mode: Number,
    item: Object,
    next: Object,
    prev: Object,
  },
};
</script>
