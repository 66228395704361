/**
 * @fileoverview gRPC-Web generated client stub for adx.action_log
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.action_log = require('./action_log_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.action_log.ActionLogClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.action_log.ActionLogPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.action_log.CreateActionLogsRequest,
 *   !proto.adx.action_log.CreateActionLogsResponse>}
 */
const methodDescriptor_ActionLog_CreateActionLogs = new grpc.web.MethodDescriptor(
  '/adx.action_log.ActionLog/CreateActionLogs',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.action_log.CreateActionLogsRequest,
  proto.adx.action_log.CreateActionLogsResponse,
  /**
   * @param {!proto.adx.action_log.CreateActionLogsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.action_log.CreateActionLogsResponse.deserializeBinary
);


/**
 * @param {!proto.adx.action_log.CreateActionLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.CreateActionLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogClient.prototype.createActionLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/CreateActionLogs',
      request,
      metadata || {},
      methodDescriptor_ActionLog_CreateActionLogs);
};


/**
 * @param {!proto.adx.action_log.CreateActionLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.CreateActionLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogPromiseClient.prototype.createActionLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/CreateActionLogs',
      request,
      metadata || {},
      methodDescriptor_ActionLog_CreateActionLogs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.action_log.DeleteActionLogsRequest,
 *   !proto.adx.action_log.DeleteActionLogsResponse>}
 */
const methodDescriptor_ActionLog_DeleteActionLogs = new grpc.web.MethodDescriptor(
  '/adx.action_log.ActionLog/DeleteActionLogs',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.action_log.DeleteActionLogsRequest,
  proto.adx.action_log.DeleteActionLogsResponse,
  /**
   * @param {!proto.adx.action_log.DeleteActionLogsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.action_log.DeleteActionLogsResponse.deserializeBinary
);


/**
 * @param {!proto.adx.action_log.DeleteActionLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.DeleteActionLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogClient.prototype.deleteActionLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/DeleteActionLogs',
      request,
      metadata || {},
      methodDescriptor_ActionLog_DeleteActionLogs);
};


/**
 * @param {!proto.adx.action_log.DeleteActionLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.DeleteActionLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogPromiseClient.prototype.deleteActionLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/DeleteActionLogs',
      request,
      metadata || {},
      methodDescriptor_ActionLog_DeleteActionLogs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.action_log.DeleteActionLogsFilesRequest,
 *   !proto.adx.action_log.DeleteActionLogsFilesResponse>}
 */
const methodDescriptor_ActionLog_DeleteActionLogsFiles = new grpc.web.MethodDescriptor(
  '/adx.action_log.ActionLog/DeleteActionLogsFiles',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.action_log.DeleteActionLogsFilesRequest,
  proto.adx.action_log.DeleteActionLogsFilesResponse,
  /**
   * @param {!proto.adx.action_log.DeleteActionLogsFilesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.action_log.DeleteActionLogsFilesResponse.deserializeBinary
);


/**
 * @param {!proto.adx.action_log.DeleteActionLogsFilesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.DeleteActionLogsFilesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogClient.prototype.deleteActionLogsFiles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/DeleteActionLogsFiles',
      request,
      metadata || {},
      methodDescriptor_ActionLog_DeleteActionLogsFiles);
};


/**
 * @param {!proto.adx.action_log.DeleteActionLogsFilesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.DeleteActionLogsFilesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogPromiseClient.prototype.deleteActionLogsFiles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/DeleteActionLogsFiles',
      request,
      metadata || {},
      methodDescriptor_ActionLog_DeleteActionLogsFiles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.action_log.GetActionLogsRequest,
 *   !proto.adx.action_log.GetActionLogsResponse>}
 */
const methodDescriptor_ActionLog_GetActionLogs = new grpc.web.MethodDescriptor(
  '/adx.action_log.ActionLog/GetActionLogs',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.action_log.GetActionLogsRequest,
  proto.adx.action_log.GetActionLogsResponse,
  /**
   * @param {!proto.adx.action_log.GetActionLogsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.action_log.GetActionLogsResponse.deserializeBinary
);


/**
 * @param {!proto.adx.action_log.GetActionLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.GetActionLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogClient.prototype.getActionLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/GetActionLogs',
      request,
      metadata || {},
      methodDescriptor_ActionLog_GetActionLogs);
};


/**
 * @param {!proto.adx.action_log.GetActionLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.GetActionLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogPromiseClient.prototype.getActionLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/GetActionLogs',
      request,
      metadata || {},
      methodDescriptor_ActionLog_GetActionLogs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.action_log.GetActionLogsFilesRequest,
 *   !proto.adx.action_log.GetActionLogsFilesResponse>}
 */
const methodDescriptor_ActionLog_GetActionLogsFiles = new grpc.web.MethodDescriptor(
  '/adx.action_log.ActionLog/GetActionLogsFiles',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.action_log.GetActionLogsFilesRequest,
  proto.adx.action_log.GetActionLogsFilesResponse,
  /**
   * @param {!proto.adx.action_log.GetActionLogsFilesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.action_log.GetActionLogsFilesResponse.deserializeBinary
);


/**
 * @param {!proto.adx.action_log.GetActionLogsFilesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.GetActionLogsFilesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogClient.prototype.getActionLogsFiles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/GetActionLogsFiles',
      request,
      metadata || {},
      methodDescriptor_ActionLog_GetActionLogsFiles);
};


/**
 * @param {!proto.adx.action_log.GetActionLogsFilesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.GetActionLogsFilesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogPromiseClient.prototype.getActionLogsFiles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/GetActionLogsFiles',
      request,
      metadata || {},
      methodDescriptor_ActionLog_GetActionLogsFiles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.action_log.UpdateActionLogsRequest,
 *   !proto.adx.action_log.UpdateActionLogsResponse>}
 */
const methodDescriptor_ActionLog_UpdateActionLogs = new grpc.web.MethodDescriptor(
  '/adx.action_log.ActionLog/UpdateActionLogs',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.action_log.UpdateActionLogsRequest,
  proto.adx.action_log.UpdateActionLogsResponse,
  /**
   * @param {!proto.adx.action_log.UpdateActionLogsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.action_log.UpdateActionLogsResponse.deserializeBinary
);


/**
 * @param {!proto.adx.action_log.UpdateActionLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.UpdateActionLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogClient.prototype.updateActionLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/UpdateActionLogs',
      request,
      metadata || {},
      methodDescriptor_ActionLog_UpdateActionLogs);
};


/**
 * @param {!proto.adx.action_log.UpdateActionLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.UpdateActionLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogPromiseClient.prototype.updateActionLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/UpdateActionLogs',
      request,
      metadata || {},
      methodDescriptor_ActionLog_UpdateActionLogs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.action_log.SaveActionLogsOrderRequest,
 *   !proto.adx.action_log.SaveActionLogsOrderResponse>}
 */
const methodDescriptor_ActionLog_SaveActionLogsOrder = new grpc.web.MethodDescriptor(
  '/adx.action_log.ActionLog/SaveActionLogsOrder',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.action_log.SaveActionLogsOrderRequest,
  proto.adx.action_log.SaveActionLogsOrderResponse,
  /**
   * @param {!proto.adx.action_log.SaveActionLogsOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.action_log.SaveActionLogsOrderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.action_log.SaveActionLogsOrderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.SaveActionLogsOrderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogClient.prototype.saveActionLogsOrder =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/SaveActionLogsOrder',
      request,
      metadata || {},
      methodDescriptor_ActionLog_SaveActionLogsOrder);
};


/**
 * @param {!proto.adx.action_log.SaveActionLogsOrderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.action_log.SaveActionLogsOrderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.action_log.ActionLogPromiseClient.prototype.saveActionLogsOrder =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.action_log.ActionLog/SaveActionLogsOrder',
      request,
      metadata || {},
      methodDescriptor_ActionLog_SaveActionLogsOrder);
};


module.exports = proto.adx.action_log;

