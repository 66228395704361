// source: report_page/keyword_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.adx.keyword_report.KeywordReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.keyword_report.KeywordReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.keyword_report.KeywordReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.keyword_report.TabListOfKeywordReportPageRequest', null, global);
goog.exportSymbol('proto.adx.keyword_report.TabListOfKeywordReportPageResponse', null, global);
goog.exportSymbol('proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result', null, global);
goog.exportSymbol('proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider', null, global);
goog.exportSymbol('proto.adx.keyword_report.TabListOfKeywordReportPageResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.keyword_report.TabListOfKeywordReportPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.keyword_report.TabListOfKeywordReportPageRequest.displayName = 'proto.adx.keyword_report.TabListOfKeywordReportPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.keyword_report.KeywordReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.keyword_report.KeywordReportByAdProviderRequest.displayName = 'proto.adx.keyword_report.KeywordReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.keyword_report.TabListOfKeywordReportPageResponse.oneofGroups_);
};
goog.inherits(proto.adx.keyword_report.TabListOfKeywordReportPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.keyword_report.TabListOfKeywordReportPageResponse.displayName = 'proto.adx.keyword_report.TabListOfKeywordReportPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.displayName = 'proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.displayName = 'proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.keyword_report.KeywordReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.keyword_report.KeywordReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.keyword_report.KeywordReportByAdProviderResponse.displayName = 'proto.adx.keyword_report.KeywordReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.displayName = 'proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.keyword_report.TabListOfKeywordReportPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adpageid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.keyword_report.TabListOfKeywordReportPageRequest;
  return proto.adx.keyword_report.TabListOfKeywordReportPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.keyword_report.TabListOfKeywordReportPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adPageId = 2;
 * @return {number}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.keyword_report.KeywordReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sortitem: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 7, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.keyword_report.KeywordReportByAdProviderRequest;
  return proto.adx.keyword_report.KeywordReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortitem(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSort(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.keyword_report.KeywordReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSortitem();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSort();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sortItem = 6;
 * @return {string}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.getSortitem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.setSortitem = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sort = 7;
 * @return {string}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.getSort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 categoryId = 8;
 * @return {number}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 advertizerId = 9;
 * @return {number}
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.keyword_report.TabListOfKeywordReportPageResponse.TypeCase}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.keyword_report.TabListOfKeywordReportPageResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.keyword_report.TabListOfKeywordReportPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.keyword_report.TabListOfKeywordReportPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.keyword_report.TabListOfKeywordReportPageResponse;
  return proto.adx.keyword_report.TabListOfKeywordReportPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result;
      reader.readMessage(value,proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.keyword_report.TabListOfKeywordReportPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adpagesid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    adprovider: (f = msg.getAdprovider()) && proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result;
  return proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagesid(value);
      break;
    case 3:
      var value = new proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider;
      reader.readMessage(value,proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.deserializeBinaryFromReader);
      msg.setAdprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdpagesid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdprovider();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adproviderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tab: jspb.Message.getFieldWithDefault(msg, 2, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 3, ""),
    providerforshort: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider;
  return proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTab(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderforshort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTab();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProviderforshort();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 adProviderId = 1;
 * @return {number}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tab = 2;
 * @return {string}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.getTab = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.setTab = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string provider = 3;
 * @return {string}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string providerForShort = 4;
 * @return {string}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.getProviderforshort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider.prototype.setProviderforshort = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adPagesId = 2;
 * @return {number}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.getAdpagesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.setAdpagesid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AdProvider adProvider = 3;
 * @return {?proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.getAdprovider = function() {
  return /** @type{?proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider} */ (
    jspb.Message.getWrapperField(this, proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider, 3));
};


/**
 * @param {?proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.AdProvider|undefined} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result} returns this
*/
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.setAdprovider = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.clearAdprovider = function() {
  return this.setAdprovider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result.prototype.hasAdprovider = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result, 1));
};


/**
 * @param {?proto.adx.keyword_report.TabListOfKeywordReportPageResponse.Result|undefined} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse} returns this
*/
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.keyword_report.TabListOfKeywordReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.keyword_report.TabListOfKeywordReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.keyword_report.TabListOfKeywordReportPageResponse} returns this
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.keyword_report.TabListOfKeywordReportPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.keyword_report.TabListOfKeywordReportPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.keyword_report.KeywordReportByAdProviderResponse.TypeCase}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.keyword_report.KeywordReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.keyword_report.KeywordReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.keyword_report.KeywordReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.keyword_report.KeywordReportByAdProviderResponse;
  return proto.adx.keyword_report.KeywordReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.keyword_report.KeywordReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keywordsMap: (f = msg.getKeywordsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result;
  return proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = msg.getKeywordsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeywordsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> keywords = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.prototype.getKeywordsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result.prototype.clearKeywordsMap = function() {
  this.getKeywordsMap().clear();
  return this;};


/**
 * optional Result result = 1;
 * @return {?proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.keyword_report.KeywordReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse} returns this
*/
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.keyword_report.KeywordReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.keyword_report.KeywordReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.keyword_report.KeywordReportByAdProviderResponse} returns this
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.keyword_report.KeywordReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.keyword_report.KeywordReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.keyword_report);
