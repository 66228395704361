
function AutoFileUploader() {

}

AutoFileUploader.prototype._onUpload = function (file) {
    console.log(file);
};
AutoFileUploader.prototype.upload = function (file) {
    this._onUpload(file);
};
AutoFileUploader.prototype.onUploaded = function () { };

export { AutoFileUploader }