// source: excel_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.adx.excel_report.GetAdBindingKeysRequest', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdBindingKeysResponse', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdBindingKeysResponse.Result', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdBindingKeysResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport', null, global);
goog.exportSymbol('proto.adx.excel_report.GetExcelReportTemplateRequest', null, global);
goog.exportSymbol('proto.adx.excel_report.GetExcelReportTemplateResponse', null, global);
goog.exportSymbol('proto.adx.excel_report.GetExcelReportTemplateResponse.Result', null, global);
goog.exportSymbol('proto.adx.excel_report.GetExcelReportTemplateResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdBindingKeysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.excel_report.GetAdBindingKeysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdBindingKeysRequest.displayName = 'proto.adx.excel_report.GetAdBindingKeysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdBindingKeysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.excel_report.GetAdBindingKeysResponse.oneofGroups_);
};
goog.inherits(proto.adx.excel_report.GetAdBindingKeysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdBindingKeysResponse.displayName = 'proto.adx.excel_report.GetAdBindingKeysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.displayName = 'proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdBindingKeysResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdBindingKeysResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdBindingKeysResponse.Result.displayName = 'proto.adx.excel_report.GetAdBindingKeysResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetExcelReportTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.excel_report.GetExcelReportTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetExcelReportTemplateRequest.displayName = 'proto.adx.excel_report.GetExcelReportTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetExcelReportTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.excel_report.GetExcelReportTemplateResponse.oneofGroups_);
};
goog.inherits(proto.adx.excel_report.GetExcelReportTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetExcelReportTemplateResponse.displayName = 'proto.adx.excel_report.GetExcelReportTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.excel_report.GetExcelReportTemplateResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetExcelReportTemplateResponse.Result.displayName = 'proto.adx.excel_report.GetExcelReportTemplateResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.displayName = 'proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdBindingKeysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdBindingKeysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdBindingKeysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdBindingKeysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdBindingKeysRequest}
 */
proto.adx.excel_report.GetAdBindingKeysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdBindingKeysRequest;
  return proto.adx.excel_report.GetAdBindingKeysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdBindingKeysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdBindingKeysRequest}
 */
proto.adx.excel_report.GetAdBindingKeysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdBindingKeysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdBindingKeysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdBindingKeysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdBindingKeysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdBindingKeysRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysRequest} returns this
 */
proto.adx.excel_report.GetAdBindingKeysRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.excel_report.GetAdBindingKeysRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysRequest} returns this
 */
proto.adx.excel_report.GetAdBindingKeysRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.excel_report.GetAdBindingKeysResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.excel_report.GetAdBindingKeysResponse.TypeCase}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.excel_report.GetAdBindingKeysResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.excel_report.GetAdBindingKeysResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdBindingKeysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdBindingKeysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.excel_report.GetAdBindingKeysResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdBindingKeysResponse;
  return proto.adx.excel_report.GetAdBindingKeysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.excel_report.GetAdBindingKeysResponse.Result;
      reader.readMessage(value,proto.adx.excel_report.GetAdBindingKeysResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdBindingKeysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdBindingKeysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.excel_report.GetAdBindingKeysResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: jspb.Message.getFieldWithDefault(msg, 1, ""),
    element1: jspb.Message.getFieldWithDefault(msg, 2, ""),
    element2: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scalefactor: jspb.Message.getFieldWithDefault(msg, 5, 0),
    scalefactorforexcel: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isoverride: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey;
  return proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setElement1(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setElement2(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperation(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScalefactor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScalefactorforexcel(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsoverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getElement1();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getElement2();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScalefactor();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getScalefactorforexcel();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getIsoverride();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string target = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string element1 = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.getElement1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.setElement1 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string element2 = 3;
 * @return {string}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.getElement2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.setElement2 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string operation = 4;
 * @return {string}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.getOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.setOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 scaleFactor = 5;
 * @return {number}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.getScalefactor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.setScalefactor = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 scaleFactorForExcel = 6;
 * @return {number}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.getScalefactorforexcel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.setScalefactorforexcel = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool isOverride = 7;
 * @return {boolean}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.getIsoverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.prototype.setIsoverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdBindingKeysResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adbindingkeysList: jspb.Message.toObjectList(msg.getAdbindingkeysList(),
    proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.Result}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdBindingKeysResponse.Result;
  return proto.adx.excel_report.GetAdBindingKeysResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.Result}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey;
      reader.readMessage(value,proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.deserializeBinaryFromReader);
      msg.addAdbindingkeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdBindingKeysResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdbindingkeysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.Result} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AdBindingKey adBindingKeys = 2;
 * @return {!Array<!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey>}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.prototype.getAdbindingkeysList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey, 2));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey>} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.Result} returns this
*/
proto.adx.excel_report.GetAdBindingKeysResponse.Result.prototype.setAdbindingkeysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.prototype.addAdbindingkeys = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.excel_report.GetAdBindingKeysResponse.AdBindingKey, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse.Result} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.Result.prototype.clearAdbindingkeysList = function() {
  return this.setAdbindingkeysList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.excel_report.GetAdBindingKeysResponse.Result}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.excel_report.GetAdBindingKeysResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.excel_report.GetAdBindingKeysResponse.Result, 1));
};


/**
 * @param {?proto.adx.excel_report.GetAdBindingKeysResponse.Result|undefined} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse} returns this
*/
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.excel_report.GetAdBindingKeysResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetAdBindingKeysResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdBindingKeysResponse} returns this
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetAdBindingKeysResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdBindingKeysResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetExcelReportTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetExcelReportTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetExcelReportTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetExcelReportTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetExcelReportTemplateRequest}
 */
proto.adx.excel_report.GetExcelReportTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetExcelReportTemplateRequest;
  return proto.adx.excel_report.GetExcelReportTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetExcelReportTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetExcelReportTemplateRequest}
 */
proto.adx.excel_report.GetExcelReportTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetExcelReportTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetExcelReportTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetExcelReportTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetExcelReportTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetExcelReportTemplateRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetExcelReportTemplateRequest} returns this
 */
proto.adx.excel_report.GetExcelReportTemplateRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.excel_report.GetExcelReportTemplateResponse.TypeCase}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.excel_report.GetExcelReportTemplateResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.excel_report.GetExcelReportTemplateResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetExcelReportTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetExcelReportTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.excel_report.GetExcelReportTemplateResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetExcelReportTemplateResponse;
  return proto.adx.excel_report.GetExcelReportTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetExcelReportTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.excel_report.GetExcelReportTemplateResponse.Result;
      reader.readMessage(value,proto.adx.excel_report.GetExcelReportTemplateResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetExcelReportTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetExcelReportTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.excel_report.GetExcelReportTemplateResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetExcelReportTemplateResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetExcelReportTemplateResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    signedurl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse.Result}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetExcelReportTemplateResponse.Result;
  return proto.adx.excel_report.GetExcelReportTemplateResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetExcelReportTemplateResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse.Result}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetExcelReportTemplateResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetExcelReportTemplateResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSignedurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse.Result} returns this
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string signedUrl = 2;
 * @return {string}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.prototype.getSignedurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse.Result} returns this
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.Result.prototype.setSignedurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.excel_report.GetExcelReportTemplateResponse.Result}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.excel_report.GetExcelReportTemplateResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.excel_report.GetExcelReportTemplateResponse.Result, 1));
};


/**
 * @param {?proto.adx.excel_report.GetExcelReportTemplateResponse.Result|undefined} value
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse} returns this
*/
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.excel_report.GetExcelReportTemplateResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse} returns this
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse} returns this
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetExcelReportTemplateResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetExcelReportTemplateResponse} returns this
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetExcelReportTemplateResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetExcelReportTemplateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryidList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string categoryId = 4;
 * @return {!Array<string>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.getCategoryidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.setCategoryidList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.addCategoryid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.clearCategoryidList = function() {
  return this.setCategoryidList([]);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.TypeCase}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    monthlyreportList: jspb.Message.toObjectList(msg.getMonthlyreportList(),
    proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.deserializeBinaryFromReader);
      msg.addMonthlyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonthlyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MonthlyReport monthlyReport = 3;
 * @return {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.getMonthlyreportList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport, 3));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.setMonthlyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.addMonthlyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.MonthlyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.prototype.clearMonthlyreportList = function() {
  return this.setMonthlyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryreportList: jspb.Message.toObjectList(msg.getCategoryreportList(),
    proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.deserializeBinaryFromReader);
      msg.addCategoryreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CategoryReport categoryReport = 3;
 * @return {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.getCategoryreportList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport, 3));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.setCategoryreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.addCategoryreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.CategoryReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result.prototype.clearCategoryreportList = function() {
  return this.setCategoryreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.Result|undefined} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryidList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string categoryId = 4;
 * @return {!Array<string>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.getCategoryidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.setCategoryidList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.addCategoryid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.clearCategoryidList = function() {
  return this.setCategoryidList([]);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.TypeCase}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    weeklyreportList: jspb.Message.toObjectList(msg.getWeeklyreportList(),
    proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.deserializeBinaryFromReader);
      msg.addWeeklyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWeeklyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated WeeklyReport weeklyReport = 3;
 * @return {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.getWeeklyreportList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport, 3));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.setWeeklyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.addWeeklyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.WeeklyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.prototype.clearWeeklyreportList = function() {
  return this.setWeeklyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryreportList: jspb.Message.toObjectList(msg.getCategoryreportList(),
    proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.deserializeBinaryFromReader);
      msg.addCategoryreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CategoryReport categoryReport = 3;
 * @return {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.getCategoryreportList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport, 3));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.setCategoryreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.addCategoryreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.CategoryReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result.prototype.clearCategoryreportList = function() {
  return this.setCategoryreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.Result|undefined} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryidList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.clearAdproviderid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.hasAdproviderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string categoryId = 4;
 * @return {!Array<string>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.getCategoryidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.setCategoryidList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.addCategoryid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.clearCategoryidList = function() {
  return this.setCategoryidList([]);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sort = 6;
 * @return {!Array<string>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.TypeCase}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table, 2));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dailyreportList: jspb.Message.toObjectList(msg.getDailyreportList(),
    proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.deserializeBinaryFromReader);
      msg.addDailyreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDailyreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DailyReport dailyReport = 3;
 * @return {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.getDailyreportList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport, 3));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.setDailyreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.addDailyreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.DailyReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.prototype.clearDailyreportList = function() {
  return this.setDailyreportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryreportList: jspb.Message.toObjectList(msg.getCategoryreportList(),
    proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result;
  return proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport;
      reader.readMessage(value,proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.deserializeBinaryFromReader);
      msg.addCategoryreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CategoryReport categoryReport = 3;
 * @return {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport>}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.getCategoryreportList = function() {
  return /** @type{!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport, 3));
};


/**
 * @param {!Array<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport>} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.setCategoryreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.addCategoryreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.CategoryReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result.prototype.clearCategoryreportList = function() {
  return this.setCategoryreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.Result|undefined} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse} returns this
*/
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse} returns this
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.excel_report);
