function Announcement(id, title, message, modified_at) {
  this.id = id;
  this.title = title;
  this.message = message;
  this.modified_at = modified_at;
}
Announcement.prototype.getYmd = function () {
  return this.modified_at.split('T')[0];
};

function Information(
  id,
  advertizerId,
  adProviderId,
  information,
  informationType,
  isDisplayAdX,
  log,
  progress,
  deliveredAt,
  adProviderName = ''
) {
  this.id = id;
  this.advertizerId = advertizerId;
  this.adProviderId = adProviderId;
  this.information = information;
  this.informationType = informationType;
  this.isDisplayAdX = isDisplayAdX;
  this.log = log;
  this.progress = progress;
  this.deliveredAt = deliveredAt;
  this.adProviderName = adProviderName;
}
Information.prototype.getYmd = function () {
  if (this.deliveredAt) {
    return this.deliveredAt.split('T')[0];
  }
  return '';
};
Information.prototype.getName = function () {
  let dic = [
    'GoogleSearch',
    'YahooSearch',
    'GDN',
    'YDN',
    'GoogleShopping',
    'GoogleUAC',
    'GoogleYoutube',
    'GoogleDicovery',
    'Facebook',
    'Twitter',
    'Criteo',
    'AppleSearchAds',
    'LINE',
    'GoogleAnalytics',
    'AdEbis',
    'Adjust',
    'Appsflyer',
    'SmartNews',
    'Gunosy',
    'indeed',
    'Amazon',
    'シラレル',
    'ADMATRIX',
    'TikTok',
    '求人ボックス',
    'careerJET',
    'MicroAdBLADE',
    'Outbrain',
    'RTBHOUSE',
    'Salesforce',
    'カスタマーリングス',
    'Hub spot',
    'Googleスプレッド',
  ];
  return dic[this.adProviderId];
};
Information.prototype.getProgress = function () {
  return '' + this.progress;
};

function NewsPage() {
  this.isNetworkingAnnouncement = false;
  this.isNetworkingInformation = false;
  this.announcements = [];
  this.informations = [];
  this.advertizerId = 0;
  this.user = null;
}
NewsPage.prototype.initialize = function (advertizerId, user) {
  let self = this;
  this.advertizerId = Number(advertizerId);
  this.isNetworkingAnnouncement = true;
  this.isNetworkingInformation = true;
  this.user = user;
  let header = {
    'X-API-Version': '2',
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization': 'Bearer ' + this.user.auth0.token,
    'X-AdvertizerId': this.advertizerId,
    //'access-token': this.user.auth0.token,
    'X-Auth0-UUID': this.user.auth0.userUuid,
    'X-Timezone': "Asia/Tokyo",
  }
  let requestOptions = {
    method: 'GET',
    headers: header,
  };

  fetch(
    process.env.VUE_APP_RESTFUL_HOST +
    '/api/information?size=10&advertizerId=' +
    this.advertizerId +
    '&isDisplayAdX=true',
    requestOptions
  )
    .then(response => response.json())
    .then(function (json) {
      if (json.data) {
        let datas = json.data;
        for (var key in datas) {
          let data = datas[key];
          self.informations.push(
            new Information(
              data.id,
              data.advertizerId,
              data.adProviderId,
              data.information,
              data.informationType,
              data.isDisplayAdX,
              data.log,
              data.progress,
              data.deliveredAt,
              data.adProviderName
            )
          );
        }
      }
      self.isNetworkingInformation = false;
    });
  // TBD エラー

  fetch(
    process.env.VUE_APP_RESTFUL_HOST +
    '/api/announcement?size=30&advertizerId=' +
    this.advertizerId,
    requestOptions
  )
    .then(response => response.json())
    .then(function (json) {
      if (json.data) {
        let datas = json.data;
        for (var key in datas) {
          let data = datas[key];
          self.announcements.push(
            new Announcement(data.id, data.title, data.message, data.modified_at)
          );
        }
      }
      self.isNetworkingAnnouncement = false;
    });
  // TBD エラー
};

export { NewsPage };
