<template>
  <div class="boxloader boxp1"></div>
</template>

<style scoped>
.boxloader.boxp1 {
  display: block;
  position: relative;
  background-color: #f5f5f5;
  color: #fff;
  overflow: hidden;
  transition: 500ms;
}
.boxloader.boxp1::before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #ffffff;
  transition: 500ms;
  animation: boxp1anim 1.5s ease-in-out infinite;
}

@-webkit-keyframes boxp1anim {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  20% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  21% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  40% {
    -webkit-transform: scale(100) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
}
</style>
