<template>
  <div tabindex="0" ref="fmain" class="tabledivreset" style="height: initial" v-on:blur="lost()">
    <div
      style="
        padding: 8px;
        background: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 16%) 0px 3px 20px;
      "
    >
      <template v-for="(option, index) in options" :key="index">
        <div
          class="msitem"
          v-bind:class="[isSelect(option.name) ? 'msitem--selected' : '']"
          v-on:click="onCheck(option.name)"
        >
          <img
            style="margin-top: 4px"
            width="10"
            src="@/assets/icon/fa_check-square-regular.svg"
            v-if="!isSelect(option.name)"
          /><img
            style="margin-top: 4px"
            width="10"
            src="@/assets/icon/fa_check-square-solid.svg"
            v-if="isSelect(option.name)"
          />
          {{ option.name }}
        </div>
      </template>
      <div
        class="msitem"
        v-on:click="onAll"
        v-bind:class="[isSelect('全媒体') ? 'msitem--selected' : '']"
      >
        <img
          style="margin-top: 4px"
          width="10"
          src="@/assets/icon/fa_check-square-regular.svg"
          v-if="!isSelect('全媒体')"
        /><img
          style="margin-top: 4px"
          width="10"
          src="@/assets/icon/fa_check-square-solid.svg"
          v-if="isSelect('全媒体')"
        />
        全媒体
      </div>
    </div>
  </div>
  <select
    v-if="false"
    v-model="selected"
    id="select_item"
    @keydown.enter.exact="decide()"
    v-on:blur="decide()"
  >
    <option v-for="option in options" v-bind:value="option.id" v-bind:key="option.id">
      {{ option.name }}
    </option>
  </select>
</template>

<style scoped>
.floater_table__body div {
  height: initial;
}
</style>

<style scoped>
.msitem {
}

.msitem--selected {
}

.msitem:hover {
  background: rgba(230, 230, 230, 1);
}
</style>

<script>
export default {
  name: 'EditMultiSelectItem',
  data() {
    return {
      name: 'EditMultiSelectItem',
      selected: '',
      options: [],
      selects: [],
    };
  },
  computed: {},
  mounted() {
    this.selected = this.item.value;
    this.options = this.item.options;
    // if (document.getElementById('select_item')) {
    //   document.getElementById('select_item').focus();
    // }
    this.$refs.fmain.focus();
    let vls = this.item.value.split(',');
    this.selects = [];
    for (let i in vls) {
      if (vls[i].length > 0) {
        this.selects.push(vls[i]);
      }
    }
  },
  methods: {
    isSelect(name) {
      for (let i in this.selects) {
        if (this.selects[i] == name) {
          return true;
        }
      }
      return false;
    },
    onAll() {
      this.selects = ['全媒体'];
      this.item.setValueNoModeChange('全媒体');
    },
    onCheck(name) {
      let isHit = false;
      for (let i = 0; i < this.selects.length; i++) {
        if (this.selects[i] == '全媒体') {
          this.selects.splice(i, 1);
          break;
        }
      }
      for (let i = 0; i < this.selects.length; i++) {
        if (this.selects[i] == name) {
          this.selects.splice(i, 1);
          isHit = true;
          break;
        }
      }
      if (!isHit) {
        this.selects.push(name);
      }
      let str = '';
      for (let i = 0; i < this.selects.length; i++) {
        str += this.selects[i];
        if (this.selects.length > i + 1) {
          str += ',';
        }
      }
      this.item.setValueNoModeChange(str);
    },
    lost() {
      this.item.decide();
    },
    inputText(item) {
      item.edit();
    },
    yyy() {},
    xxx() {},
    decide(mode) {
      this.item.setValue(this.selected);
      this.item.decide();
      if (mode == 1) {
        this.next.edit();
      }
      if (mode == 2) {
        this.prev.edit();
      }
    },
  },
  components: {},
  props: {
    mode: Number,
    item: Object,
    next: Object,
    prev: Object,
  },
};
</script>
