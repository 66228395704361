import { GetColorInfoRequest } from './protos_gen/color_patterns_pb.js';
import { ColorPatternsClient } from './protos_gen/color_patterns_grpc_web_pb.js';
import { url } from '../../setting.js';
import { uuid } from '../../tacklebox/core/uuid.js';
/* eslint-disable */

function getColorInfo(id, dto, callback) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    Authorization: 'Bearer ' + dto.token,
  };
  let uuidStr = uuid();
  let request = new GetColorInfoRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(dto.advertizerId);

  let client = new ColorPatternsClient(url.grpc, null, null);
  let stream = client.getColorInfo(request, meta);
  stream.on('data', (message) => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    if (callback) {
      callback(uuidStr, null);
    }else{
      throw e;
    }
  });
  //TBD エラー

  return uuidStr;
}

export { getColorInfo };
