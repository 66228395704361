<template>
  <div
    ref="main"
    tabindex="0"
    v-on:blur="decide()"
    :style="{
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 8px)',
      fontSize: '12px',
      boxShadow: '0px 3px 20px #00000029',
      backgroundColor: 'white',
      position: 'relative',
      top: '-4px',
      left: '-4px',
      padding: '8px',
      outline: 'none',
    }"
  >
    <template v-for="(ap, index) in dataJson" :key="index">
      <div class="monthlystatusline">
        <div v-on:click="changemode(ap, 'label')">{{ data2str(ap.label) }}</div>
        <div class="monthlystatusline__line">-</div>
        <div class="monthlystatusline__value" v-on:click="changemode(ap, 'value')">
          {{ vtunit(ap.value, ap.type) }}
        </div>
        <div v-on:click="changemode(ap, 'rate')">({{ data2str(ap.rate) }}%)</div>
        <div class="monthlystatusline__delete" v-on:click="ondelete(ap)">×</div>
      </div>
    </template>

    <div style="position: relative">
      <div class="title" v-on:click="test('new')">+NEW</div>
      <div style="position: absolute; z-index: 1000">
        <div
          v-if="isShowAddList == 'new'"
          :style="{
            width: 'calc(100% + 8px)',
            height: 'calc(100% + 8px)',
            fontSize: '12px',
            boxShadow: '0px 3px 20px #00000029',
            backgroundColor: 'white',
            position: 'relative',
            top: '0px',
            left: '16px',
            padding: '4px',
            outline: 'none',
          }"
        >
          <div>
            <div class="title" v-on:click="addcost()">COST</div>
          </div>
          <div>
            <div class="title" v-on:click="addcv()">CV</div>
          </div>
          <div>
            <div class="title" v-on:click="addcpa()">CPA</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.title {
  user-select: none;
  padding: 4px 16px;
  border-radius: 4px;
  background: rgb(238, 238, 238);
  margin: 4px;
  width: fit-content;
}
.body {
  padding: 4px 16px;
}

.monthlystatusline {
  display: flex;
  width: fit-content;
  background: rgb(238, 238, 238);
  padding: 4px 16px;
  border-radius: 4px;
  margin: 4px;
}

.monthlystatusline__value {
  font-weight: bold;
  margin-left: 8px;
  margin-right: 8px;
}

.monthlystatusline__line {
  margin-left: 8px;
  margin-right: 8px;
}

.monthlystatusline__delete {
  margin-left: 16px;
}
</style>

<style scoped>
textarea:focus {
  /* outline: none;
  box-shadow: 0 0 0; */
}
</style>
<script>
import { uuid } from '@/js/tacklebox/core/uuid.js';

export default {
  name: 'SprintEditMonthlyStatusSelect',
  data() {
    return {
      name: 'SprintEditMonthlyStatusSelect',
      inputData: '',
      dataJson: [],
      isShowAddList: '',
      isModeChange: false,
      isDelete: false,
    };
  },
  computed: {},
  mounted() {
    try {
      if (this.editball.raw) {
        this.dataJson = JSON.parse(this.editball.raw);
      }
    } catch (ex) {
      console.log(ex);
    }

    this.inputData = this.editball.raw;

    this.$refs.main.focus();
  },
  methods: {
    vtunit(value, dataType) {
      if (value) {
        //
      } else {
        return 'None';
      }
      function comma(num) {
        return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }
      let result = value;
      let ret = null;
      switch (dataType) {
        case 'rate':
          ret = comma(value);
          if (Number(value) < 10) {
            // 小数点以下2位まで表示
            if (ret.includes('.')) {
              if (ret.split('.')[1].length == 1) ret = ret + '0';
            } else {
              ret = ret + '.00';
            }
          } else {
            // 小数点以下1位まで表示
            if (!ret.includes('.')) {
              ret = ret + '.0';
            }
            if (ret.split('.')[1].length == 2) ret = ret.slice(0, -1);
          }
          return ret + '%';
        case 'currency':
          return '¥' + comma(value);
        case 'number':
          return comma(value);
        case 'count':
          return comma(value);
        case 'rate_roas':
          return comma(value) + '%';
      }

      return result;
    },
    ondelete(ap) {
      this.isDelete = true;
      let arr = [];
      for (let i in this.dataJson) {
        let j = this.dataJson[i];
        try {
          if (j.id != ap.id) {
            arr.push(j);
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      let st = JSON.stringify(arr);
      this.editball.save(st);
    },
    data2str(str) {
      if (str) {
        if (str.length > 0) {
          return str;
        }
      }
      return 'None';
    },
    addcv() {
      this.isModeChange = true;
      this.dataJson.push({
        id: uuid(),
        label: 'CV',
        value: '',
        type: 'count',
        rate: '',
      });
      let st = JSON.stringify(this.dataJson);
      this.editball.save(st);
    },
    addcost() {
      this.isModeChange = true;
      this.dataJson.push({
        id: uuid(),
        label: 'COST',
        value: '',
        type: 'currency',
        rate: '',
      });
      let st = JSON.stringify(this.dataJson);
      this.editball.save(st);
    },
    addcpa() {
      this.isModeChange = true;
      this.dataJson.push({
        id: uuid(),
        label: 'CPA',
        value: '',
        type: 'currency',
        rate: '',
      });
      let st = JSON.stringify(this.dataJson);
      this.editball.save(st);
    },
    test(param) {
      this.isShowAddList = param;
    },
    changemode(ap, mode) {
      this.isModeChange = true;
      this.callbackObj(ap, mode);
    },
    decide() {
      // if (!this.isModeChange && !this.isDelete) {
      //   this.editball.save(this.inputData);
      //   this.callbackObj();
      // }
      if (!this.isModeChange && !this.isDelete) {
        this.callbackObj();
      }
    },
  },
  components: {},
  props: {
    editball: Object,
    holder: Object,
    callbackObj: Object,
  },
};
</script>
