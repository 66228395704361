import * as tacklebox from './tacklebox/core/index.js'
import { ObserverSubject } from './tacklebox/event/ObserverSubject.js'
import { uuid } from './tacklebox/core/uuid.js'

import { TimeYmdRange } from "./tacklebox/date/TimeYmdRange";
import { TimeYmdHms } from "./tacklebox/date/TimeYmdHms.js"

import { getAdvertizerList, getSideMenuCategoryList } from "./com/grpc/sidemenu.js";
import { getAdPagesList, getAdPageComponents } from "./com/grpc/ad_pages.js";

import { getColorInfo } from "./com/grpc/colors.js";


/* global createAuth0Client */

function getUrlQueries() {
    var queryStr = window.location.search.slice(1);  // 文頭?を除外
    let queries = {};

    // クエリがない場合は空のオブジェクトを返す
    if (!queryStr) {
        return queries;
    }

    // クエリ文字列を & で分割して処理
    queryStr.split('&').forEach(function (queryStr) {
        // = で分割してkey,valueをオブジェクトに格納
        var queryArr = queryStr.split('=');
        queries[queryArr[0]] = queryArr[1];
    });

    return queries;
}

export function getAdvertizerIdFromurl() {
    let qs = getUrlQueries();
    if (qs) {
        if ('advertizer_id' in qs) {
            return qs['advertizer_id'];
        }
    }
    return -1;
}

export function getDateFromurl() {
    let qs = getUrlQueries();
    if (qs) {
        if ('rdate' in qs) {
            return qs['rdate'];
        }
    }
    return null;
}

function Config() {
    this.name = "config";
}

function ReportScreenParam() {
    this.name = "ReportScreenParam";
    this.time = "";
}

function ScreenParam() {
    this.report = new ReportScreenParam();
}

function Advertizer(id, name) {
    this.id = id;
    this.name = name;
}

function Auth0() {
    this.token = null;
}
Auth0.prototype.login = function (username, password, callback) {
    let self = this;
    this.token = null;
    //Auth0
    console.log(username);
    console.log(password);
    setTimeout(function () {
        if (callback) {
            self.token = "xxxx";
            callback();
        }
    }, 100);
};

function User(callback) {
    this.userUuid = null;
    this.name = "";
    this.role = "";
    this.advertizers = [];
    this.auth0 = new Auth0();
    this.callback = callback;
    this.isDummyAdvMode = false;
    this.email = "";

    try {
        let sts = localStorage.getItem("usersetting");
        if (sts) {
            let jsts = JSON.parse(sts);
            if (jsts) {
                this.isDummyAdvMode = jsts["advmode"];
            }
        }
    } catch (ex) {
        console.log(ex);
    }

}
User.prototype.setDummyAdvMode = function (isAdv) {
    this.isDummyAdvMode = isAdv;

    try {
        let sts = {
            "advmode": this.isDummyAdvMode,
        };
        let jsts = JSON.stringify(sts)
        localStorage.setItem("usersetting", jsts);
    } catch (ex) {
        console.log(ex);
    }
};
User.prototype.nowRole = function () {
    if (this.isDummyAdvMode) {
        return "advertizer";
    }
    return this.role;
};
User.prototype.login = function (username, password, callback) {
    let self = this;
    this.userUuid = null;
    this.auth0.login(username, password, function () {
        if (self.auth0.token) {
            self.loadUser(self.auth0.token);
        }
        if (callback) {
            callback();
        }
    });
};

User.prototype.tokenLogin = async function () {
    let self = this;
    /*
    setTimeout(function () {
        self.loadUser("auth0|605af9e9cea7a7007478061c");
    }, 1000);
    */

    let auth0 = await createAuth0Client({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,//"pignus-stg.jp.auth0.com",
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,//"wXCOwTnRNJWqQgRtEmqdc9IByYA878xR",
        audience: process.env.VUE_APP_AUTH0_AUDIENCE
    })
    let isAuthed = await auth0.isAuthenticated();
    if (isAuthed) {
        /*  
            defined AuthPipeline rule on Auth0:

            function (user, context, callback) {
                const namespace = 'http://user';
                context.idToken[`${namespace}/metadata`] = {
                    role: user.user_metadata.role,
                    last_name: user.user_metadata.last_name,
                    first_name: user.user_metadata.first_name,
                };
                callback(null, user, context);
            }
            
            Reference about the rule: https://auth0.com/docs/rules/examples#copy-user-metadata-to-id-token
        */
        let user = await auth0.getUser({ audience: process.env.VUE_APP_AUTH0_AUDIENCE });
        try {
            self.name = user.name;
            self.role = user['http://user/metadata'].role;
        } catch (ex) {
            console.log(ex);
        }
        try {
            self.email = user.email;
        } catch(ex) {
            console.log(ex);
        }
        let id_token = await auth0.getIdTokenClaims();
        let access_token = await auth0.getTokenSilently();
        self.auth0.token = access_token;
        try {
            if (process.env.VUE_APP_BASE_URL != 'https://www.ad-x.tech/') {
                console.log(`
    ========AUTH INFO==========
    [access_token]
    ${access_token}
    
    [id_token]
    ${id_token.__raw}
    
    [user_info (decoded from id_token)]
    ${JSON.stringify(user, null, 2)}
    ============================
            `);
            }

        } catch (ex) {
            console.log(ex);
        }
        self.loadUser(id_token.sub);
        return;
    } else {
        const query = window.location.search;
        if (query.includes("code=") && query.includes("state=")) {
            auth0.handleRedirectCallback().then(function (result) {
                console.log(result);
                //window.history.replaceState({}, document.title, "/index.html");
                location.href = window.location.origin + "/index.html";
            });
        } else {
            auth0.loginWithRedirect({
                redirect_uri: process.env.VUE_APP_BASE_URL + "index.html"
            });
        }
    }
};
User.prototype.isReady = function () {
    return this.userUuid != null;
};
User.prototype.loadUser = function (uuid) {
    //TBD Reset
    this.userUuid = uuid;
    this.loadAdvertizer();
}
User.prototype.loadAdvertizer = function () {
    let self = this;
    // GetAdvertizerList()
    let request = {
        token: this.auth0.token,
    };
    getAdvertizerList(this.userUuid, request, function (uuid, data) {
        if (uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            for (let i in json.advertizersList) {
                let advertizer = json.advertizersList[i];
                self.advertizers.push(new Advertizer(advertizer.id, advertizer.name));
            }
        }
        self.callback();
    });

};
User.prototype.isExistAdvertizeId = function (id) {
    for (let key in this.advertizers) {
        if (this.advertizers[key].id == id) {
            return true;
        }
    }
    return false;
};

function ExcelReport() {
    this.isReady = false;
    this.callback = {};
}
ExcelReport.prototype.ready = function () {
    this.isReady = true;
};
ExcelReport.prototype.reset = function () {
    this.isReady = false;
};
ExcelReport.prototype.setCallback = function (callback) {
    this.callback = callback;
};
ExcelReport.prototype.report = function () {
    if (this.callback) {
        this.callback();
    }
};


function ColorDesign(type, backendKey, colors = []) {
    this.type = type;
    this.backendKey = backendKey;
    this.colors = colors;
}

function ColorMaster(raw) {
    this.raw = raw;
}
ColorMaster.prototype.getKeyIndicator = function(indicator) {
    if (this.raw) {
        if (this.raw?.keyindicator) {
            if (indicator in this.raw.keyindicator) {
                return this.raw?.keyindicator[indicator];
            }
        }
    }
    return "";
};
ColorMaster.prototype.getDesignByKey = function(key) {
    if (this.raw) {
        if (this.raw.colorbarsList) {
            for (let i in this.raw.colorbarsList) {
                if (key == this.raw.colorbarsList[i].backendkey) {
                    let list = [];
                    if (this.raw.colorbarsList[i].colorsList) {
                        list = this.raw.colorbarsList[i].colorsList;
                    }
                    return new ColorDesign("bar", key, list);
                }
            }
        }
        if (this.raw.colorscalesList) {
            for (let i in this.raw.colorscalesList) {
                if (key == this.raw.colorscalesList[i].backendkey) {
                    let list = [];
                    if (this.raw.colorscalesList[i].colorsList) {
                        list = this.raw.colorscalesList[i].colorsList;
                    }
                    return new ColorDesign("scale", key, list);
                }
            }
        }
    }
    return null;
};




function Adx() {
    let self = this;
    this.name = "Adx";
    this.ver = "1.1";
    tacklebox.tacklelog("Boot " + this.name + " Ver." + this.ver);
    this.screenStructures = [];
    this.user = new User(function () {
        for (let key in self.screenStructures) {
            let time = self.getNowTime();
            let rdate = getDateFromurl();
            if (rdate) {
                try {
                    var ym = rdate.split('-');
                    if (ym.length > 1) {
                        time.ymd.year = ym[0];
                        time.ymd.month = ym[1];
                    }
                } catch (ex) {
                    console.log(ex);
                }
            }
            self.screenStructures[key].initialize(new DateRangeSelector(new TimeYmdRange(time.ymd.year, time.ymd.month, time.ymd.day, time.ymd.year, time.ymd.month, time.ymd.day)));
        }
    });
    this.config = new Config();
    this.screenParam = new ScreenParam();
    this.excelreport = new ExcelReport();
    this.lastUpdateTime = "";
    this.isExistCstCustomReport = false;
}
Adx.prototype.isCurrentMonth = function (actualMonth) {
    console.log(actualMonth);
    return true;
};
Adx.prototype.changeLastUpdate = function (datetime) {
    this.lastUpdateTime = datetime;
};
Adx.prototype.changeCstCustomReport = function (isExist = false) {
    this.isExistCstCustomReport = isExist;
};
Adx.prototype.initialize = async function () {
    this.screenStructures.push(new StaticScreenStructure("login", this.user));
    this.screenStructures.push(new ReportScreenStructure(this.user));
    this.screenStructures.push(new StaticScreenStructure("log", this.user));
    this.screenStructures.push(new StaticScreenStructure("allocation", this.user));
    this.screenStructures.push(new StaticScreenStructure("news", this.user));
    this.screenStructures.push(new ConsultantScreenStructure(this.user));
    this.screenStructures.push(new StaticScreenStructure("manager", this.user));
    this.changeCstCustomReport(false);
    await this.user.tokenLogin();
};
Adx.prototype.getScreenStructureById = function (id) {
    for (let key in this.screenStructures) {
        if (this.screenStructures[key].id == id) {
            return this.screenStructures[key];
        }
    }

    return new ScreenStructure("");
};
Adx.prototype.getAdvertizerId = function (id) {
    for (let key in this.screenStructures) {
        if (this.screenStructures[key].id == id) {
            return this.screenStructures[key];
        }
    }

    return new ScreenStructure("");
};
Adx.prototype.getNowTime = function () {
    let d = new Date();
    // TBD (server) real time
    return new TimeYmdHms(d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
};

function TimeRequest(actualMonth = null, startDate = null, endDate = null) {
    this.actualMonth = actualMonth;
    this.startDate = startDate;
    this.endDate = endDate;
}
TimeRequest.prototype.isRange = function () {
    return this.actualMonth === null;
};

function DateRangeSelector(timeRange) {
    this.mode = 0;
    if (timeRange) {
        this.timeYmdRange = timeRange;
    } else {
        this.timeYmdRange = new TimeYmdRange();
    }
}
DateRangeSelector.prototype.getSelectedDateLabel = function () {
    if (this.mode == 1) {
        return this.timeYmdRange.startYmd.year + "年" + ("00" + this.timeYmdRange.startYmd.month).slice(-2) + "月" + ("00" + this.timeYmdRange.startYmd.day).slice(-2) + "日" + "～" + this.timeYmdRange.endYmd.year + "年" + ("00" + this.timeYmdRange.endYmd.month).slice(-2) + "月" + ("00" + this.timeYmdRange.endYmd.day).slice(-2) + "日";
    }
    return this.timeYmdRange.startYmd.year + "年" + ("00" + this.timeYmdRange.startYmd.month).slice(-2) + "月";
};
DateRangeSelector.prototype.incMonth = function () {
    this.mode = 0;
    this.timeYmdRange.incMonth();
    this.timeYmdRange.endYmd.year = this.timeYmdRange.startYmd.year;
    this.timeYmdRange.endYmd.month = this.timeYmdRange.startYmd.month;
    this.timeYmdRange.endYmd.day = this.timeYmdRange.startYmd.day;
    //this.setStartEndYmd(this.timeYmdRange.startYmd.year, this.timeYmdRange.startYmd.month, this.timeYmdRange.startYmd.day);
};
DateRangeSelector.prototype.decMonth = function () {
    this.mode = 0;
    this.timeYmdRange.decMonth();
    this.timeYmdRange.endYmd.year = this.timeYmdRange.startYmd.year;
    this.timeYmdRange.endYmd.month = this.timeYmdRange.startYmd.month;
    this.timeYmdRange.endYmd.day = this.timeYmdRange.startYmd.day;

    //this.setStartEndYmd(this.timeYmdRange.startYmd.year, this.timeYmdRange.startYmd.month, this.timeYmdRange.startYmd.day);
};
DateRangeSelector.prototype.getTimeRequest = function () {
    if (this.mode == 1) {
        return new TimeRequest(null, this.timeYmdRange.startYmd.getYmd(), this.timeYmdRange.endYmd.getYmd());
    }
    return new TimeRequest(this.timeYmdRange.startYmd.getYm());
};
DateRangeSelector.prototype.setStartEndYmd = function (y, m, d) {
    this.mode = 0;
    this.timeYmdRange.setStartEndYmd(y, m, d);
};
DateRangeSelector.prototype.setRangeYmd = function (sy, sm, sd, ey, em, ed) {
    this.mode = 1;
    this.timeYmdRange.setRangeYmd(sy, sm, sd, ey, em, ed);
};

function ScreenStructure(id, user = null) {
    this.id = id;
    this.user = user;
    this.advertizerId = null;
    this.pageStructures = [];
    this.menus = [];
    this.subject = new ObserverSubject();
    this.dateRangeSelector = new DateRangeSelector();
    this.colorMaster = new ColorMaster();
}
ScreenStructure.prototype.initialize = function (dateRange) {
    if (dateRange) {
        this.dateRangeSelector = dateRange;
    }
    if (this.user.advertizers && (this.user.advertizers.length > 0)) {
        let urlgetid = getAdvertizerIdFromurl();
        for (let key in this.user.advertizers) {
            if (urlgetid == this.user.advertizers[key].id) {
                this.changeAdvertizer(this.user.advertizers[key].id);
                return;
            }
        }
        this.changeAdvertizer(this.user.advertizers[0].id);
    }
};
ScreenStructure.prototype._onChangedAdvertizerId = function () {

};
ScreenStructure.prototype.changeAdvertizer = function (id) {
    if (this.user.isExistAdvertizeId(id)) {
        //TBD RESET

        this.advertizerId = id;
        this._onChangedAdvertizerId();
        this.loadPageStructures();
    }
};
ScreenStructure.prototype.loadPageStructures = function () {
    let self = this;
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
    };

    request.advertizerId = this.advertizerId;

    getColorInfo(this.advertizerId, request, function (uuid, data) {
        if (uuid) {
            if (data) {
                if (data.result) {
                    let json = JSON.parse(JSON.stringify(data.result));
                    self.colorMaster = new ColorMaster(json);
                }
            }
            getAdPagesList(self.advertizerId, request, function (uuid, data) {
                if (uuid) {
                    let json = JSON.parse(JSON.stringify(data.result));
                    for (let page of json.pagesList) {
                        if (parseInt(page.pageid) != 0) {
                            let structure = new PageStructure(page.pageid, page.page, page.page, null, null, null, 0, self.user);
                            let observer = function () {
                                self.subject.notify();
                            }
                            structure.subject.addObserver(observer);
                            structure.loadComponent(self.advertizerId);
                            self.pageStructures.push(structure);
                        } else {
                            for (let key in page.breakdownList) {
                                let bd = page.breakdownList[key];
                                let structure = new PageStructure(bd.pageid, bd.categoryabbreviation, bd.categoryabbreviation, bd.categoryid, page.page, page.page, 0, self.user);
                                let observer = function () {
                                    self.subject.notify();
                                }
                                structure.subject.addObserver(observer);
                                structure.loadComponent(self.advertizerId);
                                self.pageStructures.push(structure);
                            }
                        }
                    }
                }
            });    
        }
    });


};
ScreenStructure.prototype.getActivePages = function () {
    let pages = [];
    for (let key in this.pageStructures) {
        let page = this.pageStructures[key];
        if (page.isReady) {
            pages.push(page);
        }
    }
    return pages;
};
ScreenStructure.prototype.getPageById = function (id) {
    for (let key in this.pageStructures) {
        let page = this.pageStructures[key];
        if (page.id == id) {
            return page;
        }
    }
    return null;
};

function StaticScreenStructure(id, user = null) {
    ScreenStructure.call(this, id, user);
}
tacklebox.extend(StaticScreenStructure, ScreenStructure);
StaticScreenStructure.prototype.loadPageStructures = function () {
    this.pageStructures.push(new PageStructure(1));
    this.pageStructures[0].isReady = true;
    this.subject.notify();
};

function TreeMenu(title, menus, id = uuid()) {
    this.id = id;
    this.title = title;
    this.menus = menus;
}
TreeMenu.prototype.group = function () {

};

function ReportScreenStructure(user = null) {
    ScreenStructure.call(this, "report", user);
    this.categories = [];
}
tacklebox.extend(ReportScreenStructure, ScreenStructure);
ReportScreenStructure.prototype._onChangedAdvertizerId = function () {
    this.loadSideMenu();
};
ReportScreenStructure.prototype.loadSideMenu = function () {
    let self = this;
    let request = {
        token: this.user.auth0.token,
    };
    getSideMenuCategoryList(this.advertizerId, request, function (uuid, data) {
        if (uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            let ms = [];
            for (let category of json.categoriesList) {
                for (let key in category.sidemenuList) {
                    let sideMenu = category.sidemenuList[key];
                    ms.push(new SideMenu(sideMenu.key, sideMenu.label, sideMenu.displayorder));
                }
                self.categories.push(new MenuCategorie(category.categorytype, category.displayname, ms));
            }
        }
    });
};
ReportScreenStructure.prototype.getSideMenus = function () {
    let menus = [];

    let pages = this.getActivePages();
    for (let key in pages) {
        let page = pages[key];
        if (page.breakdown) {
            for (let key in this.categories) {
                let category = this.categories[key];
                for (let key in category.sideMenus) {
                    let sideMenu = category.sideMenus[key];
                    if (sideMenu.key == page.breakdown) {
                        let isHit = false;
                        for (let key in menus) {
                            let menu = menus[key];
                            if (menu.id == category.categoryType) {
                                menu.menus.push(page);
                                isHit = true;
                                break;
                            }
                        }

                        if (!isHit) {
                            menus.push(new TreeMenu(category.categoryType, category.displayName, [page]));
                        }
                        break;
                    }
                }

            }
        }
    }

    return menus;
};
ReportScreenStructure.prototype.getPageByKey = function (targetKey) {
    for (let key in this.pageStructures) {
        if (this.pageStructures[key].key == targetKey) {
            return this.pageStructures[key];
        }
    }

    return null;
};
ReportScreenStructure.prototype.getDynamicSideMenus = function () {
    let menus = [];

    let pages = this.getActivePages();

    for (let key in pages) {
        let page = pages[key];
        let treemenu = null;
        if (page.group && page.group != "キーワード") {
            for (let key in menus) {
                if (menus[key].title == page.group) {
                    treemenu = menus[key];
                    break;
                }
            }
            if (!treemenu) {
                menus.push(new TreeMenu(page.group, [page]));
            } else {
                treemenu.menus.push(page);
            }

        }
    }
    return menus;
}
ReportScreenStructure.prototype.getKeywordSideMenus = function () {
    let menus = [];

    let pages = this.getActivePages();

    for (let key in pages) {
        let page = pages[key];
        let treemenu = null;
        if (page.group && page.group == "キーワード") {
            for (let key in menus) {
                if (menus[key].title == page.group) {
                    treemenu = menus[key];
                    break;
                }
            }
            if (!treemenu) {
                menus.push(new TreeMenu(page.group, [page]));
            } else {
                treemenu.menus.push(page);
            }
        }
    }
    return menus;
}

function ConsultantScreenStructure(user = null) {
    ScreenStructure.call(this, "consultant", user);
    this.categories = [];
}
tacklebox.extend(ConsultantScreenStructure, ScreenStructure);
ConsultantScreenStructure.prototype._onChangedAdvertizerId = function () {
    this.loadSideMenu();
};
ConsultantScreenStructure.prototype.loadSideMenu = function () {
    let self = this;
    let request = {
        token: this.user.auth0.token,
    };
    getSideMenuCategoryList(this.advertizerId, request, function (uuid, data) {
        if (uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            let ms = [];
            for (let category of json.categoriesList) {
                for (let key in category.sidemenuList) {
                    let sideMenu = category.sidemenuList[key];
                    ms.push(new SideMenu(sideMenu.key, sideMenu.label, sideMenu.displayorder));
                }
                self.categories.push(new MenuCategorie(category.categorytype, category.displayname, ms));
            }
        }
    });
};
ConsultantScreenStructure.prototype.getSideMenus = function () {
    let menus = [];

    let pages = this.getActivePages();
    for (let key in pages) {
        let page = pages[key];
        if (page.breakdown) {
            for (let key in this.categories) {
                let category = this.categories[key];
                for (let key in category.sideMenus) {
                    let sideMenu = category.sideMenus[key];
                    if (sideMenu.key == page.breakdown) {
                        let isHit = false;
                        for (let key in menus) {
                            let menu = menus[key];
                            if (menu.id == category.categoryType) {
                                menu.menus.push(page);
                                isHit = true;
                                break;
                            }
                        }

                        if (!isHit) {
                            menus.push(new TreeMenu(category.categoryType, category.displayName, [page]));
                        }
                        break;
                    }
                }

            }
        }
    }

    return menus;
};
ConsultantScreenStructure.prototype.getPageByKey = function (targetKey) {
    for (let key in this.pageStructures) {
        if (this.pageStructures[key].key == targetKey) {
            return this.pageStructures[key];
        }
    }

    return null;
};
ConsultantScreenStructure.prototype.getDynamicSideMenus = function () {
    let menus = [];

    let pages = this.getActivePages();

    for (let key in pages) {
        let page = pages[key];
        let treemenu = null;
        if (page.group && page.group != "キーワード") {
            for (let key in menus) {
                if (menus[key].title == page.group) {
                    treemenu = menus[key];
                    break;
                }
            }
            if (!treemenu) {
                menus.push(new TreeMenu(page.group, [page]));
            } else {
                treemenu.menus.push(page);
            }

        }
    }
    return menus;
}



function PageStructure(id = null, key = null, title = "", categoryId = null, group = "", groupTitle = "", displayOrder = 0, user) {
    this.id = id;
    this.key = key;
    this.title = title;
    this.categoryId = categoryId;
    this.group = group;
    this.groupTitle = groupTitle;
    this.displayOrder = displayOrder;
    this.pageComponents = [];
    this.subject = new ObserverSubject();
    this.isReady = false;
    this.user = user;
}
PageStructure.prototype.loadComponent = function (advertizerId) {
    let self = this;
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
    };

    request.advertizerId = advertizerId;
    getAdPageComponents(this.id, request, function (uuid, data) {
        if (uuid) {
            if (data.error) {
                console.error(data.error);
                return;
            }
            let json = JSON.parse(JSON.stringify(data.result));
            for (let component of json.adpagecomponentsList) {
                self.pageComponents.push(new PageComponent(component.id, component.kindoftab, component.budgettab, component.component, null, component.destination));
            }
        }
        self._ready();
    });
};
PageStructure.prototype._ready = function () {
    this.isReady = true;
    this.subject.notify();
};

function PageComponent(id = null, kindOfTab = "", budgetTab = "", component = "", structure = null, destination = "") {
    this.id = id;
    this.kindOfTab = kindOfTab;
    this.budgetTab = budgetTab;
    this.component = component;
    this.structure = structure;
    this.destination = destination;
}

function SideMenu(key, label, displayOrder = -1) {
    this.key = key;
    this.label = label;
    this.displayOrder = displayOrder;
}

function MenuCategorie(categoryType, displayName, sideMenus) {
    this.categoryType = categoryType;
    this.displayName = displayName;
    this.sideMenus = sideMenus;
}


export { Adx }
