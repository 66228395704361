<template>
  <!--v-ifで書くとバグる。恐らく、canvasがデータを読んでるときにVueが悪さしちゃってる気がする。-->
  <div style="height: 100%; width: 100%;" v-show="!isNoData()">
    <canvas ref="cnv"></canvas>
  </div>
  <div
    style="
      width: 100%;
      text-align: center;
      display:inline-block;
      vertical-align: middle;
      font-size: 20px;
    "
    v-show="isNoData()"
  >
    NoData
  </div>
</template>

<style scoped>
.floater_table__body td div {
  border: 1px solid rgba(0, 0, 0, 0);
}
</style>

<script>
// @ is an alias to /src
/*global Chart*/

export default {
  name: 'M2ChartJs',
  data() {
    return {
      name: 'M2ChartJs',
      chartdata: {
        labels: [],
        datasets: [],
        yAxes: [],
      },
      isReady: false,
      viewer: null,
    };
  },
  mounted() {
    let self = this;
    this.viewer = new Chart(this.$refs.cnv, {
      type: 'bar',
      data: {
        labels: self.labels,
        datasets: self.graphs.map((graph, idx) => new Object({
          label: graph.label,
          yAxisID: graph.dataGroup,
          type: graph.type,
          backgroundColor: graph.color,
          borderColor: graph.color,
          data: graph.value,
          stack: idx,
          pointHitRadius: 1,
          pointHoverRadius: 1,
        })).map(obj => {
          if(obj.type == "line"){
            // 線の幅(ピクセル単位)
            obj.borderWidth = 1;
            // 線のベジェ曲線の張力。直線を描くには0に設定する。
            obj.lineTension = 0;
            // 点の形状の半径。0に設定すると、点はレンダリングされない。
            obj.pointRadius = 2;
            // マウスオーバー検出のために点半径に追加される半径(ピクセル単位)
            obj.pointHitRadius = 1;
            obj.pointHoverRadius = 1;
            // 線の下を埋めるかどうか
            obj.fill = false;
            return obj;
          }
          if(obj.type == "bar"){
            obj.borderWidth = 0;
            return obj
          }
          if(obj.type == "dash"){
            obj.borderWidth =  1;
            obj.type = 'line';
            obj.borderDash = [5, 5];
            obj.borderColor = obj.backgroundColor;
            delete obj.backgroundColor;
            obj.pointRadius = 0;
            obj.fill = false;
            return obj;
          }
        })
      },
      options: {
        title: {
          display: self.title != null ? true : false,
          text: self.title,
        },
        layout: {
          padding: {
            top: 20
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 14,
            fontSize: 10,
            filter: function(items) {
              return self.graphs[items.datasetIndex].legend == false ? false : true;
            }
          }
        },
        scales: {
          yAxes: self.graphs.filter(g => g.yAxisLabelPosition != null)
            .map(g => new Object({
              id: g.dataGroup,
              position: g.yAxisLabelPosition,
              ticks: {
                beginAtZero: true,
                fontSize: 10,
                callback: v => g.labelGenerator(v)
              },
              gridLines: {
                drawOnChartArea: g.grid,
              },
              stacked: true,
            })),
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              ticks: {
                  fontSize: 10
              },
              categoryPercentage: self.option.categoryPercentage,
              barPercentage: self.option.barPercentage,
              stacked: true,
            },
          ],
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            titleFontSize: 10,
            bodyFontSize: 10,
            backgroundColor: 'rgba(230, 230, 230, 0.8)',
            titleFontColor: "",
            bodyFontColor: "black",
            callbacks: {
              label: function(item, data) {
                if(isNaN(item.yLabel))return null;
                return data.datasets[item.datasetIndex].label + ": " + self.graphs[item.datasetIndex].labelGenerator(item.yLabel);
              },
              title: () => null
            }
        }
      },
    });
  },
  methods: {
    test() {
      this.viewer.data.datasets.forEach((dataset) => {
        console.log('ここ' + dataset);
        dataset.data.push(10);
      });

      this.test2();
    },
    test2() {
      this.viewer.update();
    },
    isNoData() {
      return this.graphs.flatMap(g => g.value).length == 0;
    }
  },
  components: {},
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    graphs: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    },
    option: {
      type: Object,
      default: () => new Object({
        categoryPercentage: 0.8,
        barPercentage: 0.9,
      })
    }
  },
};
</script>

<style>
.chartjs_popup {
  width: 300px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 10px #00000027;
}

.chartjs_popup__header {
  background: rgba(163, 161, 251, 0.3);
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.chartjs_popup__body {
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
}

.chartjs_popup__item {
  display: flex;
  width: 135px;

  vertical-align: text-bottom;
}
</style>