/**
 * @fileoverview gRPC-Web generated client stub for adx.summary_report
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.summary_report = require('./summary_report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.summary_report.SummaryReportClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.summary_report.SummaryReportPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.summary_report.TabListOfSummaryReportPageRequest,
 *   !proto.adx.summary_report.TabListOfSummaryReportPageResponse>}
 */
const methodDescriptor_SummaryReport_GetTabListOfSummaryReportPage = new grpc.web.MethodDescriptor(
  '/adx.summary_report.SummaryReport/GetTabListOfSummaryReportPage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.summary_report.TabListOfSummaryReportPageRequest,
  proto.adx.summary_report.TabListOfSummaryReportPageResponse,
  /**
   * @param {!proto.adx.summary_report.TabListOfSummaryReportPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.summary_report.TabListOfSummaryReportPageResponse.deserializeBinary
);


/**
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.TabListOfSummaryReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportClient.prototype.getTabListOfSummaryReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetTabListOfSummaryReportPage',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetTabListOfSummaryReportPage);
};


/**
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.TabListOfSummaryReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportPromiseClient.prototype.getTabListOfSummaryReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetTabListOfSummaryReportPage',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetTabListOfSummaryReportPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.summary_report.ActualStatusReportRequest,
 *   !proto.adx.summary_report.ActualStatusReportResponse>}
 */
const methodDescriptor_SummaryReport_GetActualStatusReport = new grpc.web.MethodDescriptor(
  '/adx.summary_report.SummaryReport/GetActualStatusReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.summary_report.ActualStatusReportRequest,
  proto.adx.summary_report.ActualStatusReportResponse,
  /**
   * @param {!proto.adx.summary_report.ActualStatusReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.summary_report.ActualStatusReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.summary_report.ActualStatusReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.ActualStatusReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportClient.prototype.getActualStatusReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetActualStatusReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetActualStatusReport);
};


/**
 * @param {!proto.adx.summary_report.ActualStatusReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.ActualStatusReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportPromiseClient.prototype.getActualStatusReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetActualStatusReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetActualStatusReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.summary_report.MonthlyResultReportRequest,
 *   !proto.adx.summary_report.MonthlyResultReportResponse>}
 */
const methodDescriptor_SummaryReport_GetMonthlyResultReport = new grpc.web.MethodDescriptor(
  '/adx.summary_report.SummaryReport/GetMonthlyResultReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.summary_report.MonthlyResultReportRequest,
  proto.adx.summary_report.MonthlyResultReportResponse,
  /**
   * @param {!proto.adx.summary_report.MonthlyResultReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.summary_report.MonthlyResultReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.summary_report.MonthlyResultReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.MonthlyResultReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportClient.prototype.getMonthlyResultReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetMonthlyResultReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetMonthlyResultReport);
};


/**
 * @param {!proto.adx.summary_report.MonthlyResultReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.MonthlyResultReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportPromiseClient.prototype.getMonthlyResultReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetMonthlyResultReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetMonthlyResultReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.summary_report.WeeklyResultReportRequest,
 *   !proto.adx.summary_report.WeeklyResultReportResponse>}
 */
const methodDescriptor_SummaryReport_GetWeeklyResultReport = new grpc.web.MethodDescriptor(
  '/adx.summary_report.SummaryReport/GetWeeklyResultReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.summary_report.WeeklyResultReportRequest,
  proto.adx.summary_report.WeeklyResultReportResponse,
  /**
   * @param {!proto.adx.summary_report.WeeklyResultReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.summary_report.WeeklyResultReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.summary_report.WeeklyResultReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.WeeklyResultReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportClient.prototype.getWeeklyResultReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetWeeklyResultReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetWeeklyResultReport);
};


/**
 * @param {!proto.adx.summary_report.WeeklyResultReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.WeeklyResultReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportPromiseClient.prototype.getWeeklyResultReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetWeeklyResultReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetWeeklyResultReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.summary_report.DailyResultGraphReportRequest,
 *   !proto.adx.summary_report.DailyResultGraphReportResponse>}
 */
const methodDescriptor_SummaryReport_GetDailyResultGraphReport = new grpc.web.MethodDescriptor(
  '/adx.summary_report.SummaryReport/GetDailyResultGraphReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.summary_report.DailyResultGraphReportRequest,
  proto.adx.summary_report.DailyResultGraphReportResponse,
  /**
   * @param {!proto.adx.summary_report.DailyResultGraphReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.summary_report.DailyResultGraphReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.summary_report.DailyResultGraphReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.DailyResultGraphReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportClient.prototype.getDailyResultGraphReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetDailyResultGraphReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetDailyResultGraphReport);
};


/**
 * @param {!proto.adx.summary_report.DailyResultGraphReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.DailyResultGraphReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportPromiseClient.prototype.getDailyResultGraphReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetDailyResultGraphReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetDailyResultGraphReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.summary_report.DimensionCategoryResultReportRequest,
 *   !proto.adx.summary_report.DimensionCategoryResultReportResponse>}
 */
const methodDescriptor_SummaryReport_GetDimensionCategoryResultReport = new grpc.web.MethodDescriptor(
  '/adx.summary_report.SummaryReport/GetDimensionCategoryResultReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.summary_report.DimensionCategoryResultReportRequest,
  proto.adx.summary_report.DimensionCategoryResultReportResponse,
  /**
   * @param {!proto.adx.summary_report.DimensionCategoryResultReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.summary_report.DimensionCategoryResultReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.DimensionCategoryResultReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportClient.prototype.getDimensionCategoryResultReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetDimensionCategoryResultReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetDimensionCategoryResultReport);
};


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.DimensionCategoryResultReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportPromiseClient.prototype.getDimensionCategoryResultReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetDimensionCategoryResultReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetDimensionCategoryResultReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.summary_report.LowPerformanceCampaignReportRequest,
 *   !proto.adx.summary_report.LowPerformanceCampaignReportResponse>}
 */
const methodDescriptor_SummaryReport_GetLowPerformanceCampaignReport = new grpc.web.MethodDescriptor(
  '/adx.summary_report.SummaryReport/GetLowPerformanceCampaignReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.summary_report.LowPerformanceCampaignReportRequest,
  proto.adx.summary_report.LowPerformanceCampaignReportResponse,
  /**
   * @param {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.LowPerformanceCampaignReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportClient.prototype.getLowPerformanceCampaignReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetLowPerformanceCampaignReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetLowPerformanceCampaignReport);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.LowPerformanceCampaignReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportPromiseClient.prototype.getLowPerformanceCampaignReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetLowPerformanceCampaignReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetLowPerformanceCampaignReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.summary_report.LowPerformanceAdGroupReportRequest,
 *   !proto.adx.summary_report.LowPerformanceAdGroupReportResponse>}
 */
const methodDescriptor_SummaryReport_GetLowPerformanceAdGroupReport = new grpc.web.MethodDescriptor(
  '/adx.summary_report.SummaryReport/GetLowPerformanceAdGroupReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.summary_report.LowPerformanceAdGroupReportRequest,
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse,
  /**
   * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportClient.prototype.getLowPerformanceAdGroupReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetLowPerformanceAdGroupReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetLowPerformanceAdGroupReport);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportPromiseClient.prototype.getLowPerformanceAdGroupReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetLowPerformanceAdGroupReport',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetLowPerformanceAdGroupReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.summary_report.GetDimensionCategoryAndComponentRequest,
 *   !proto.adx.summary_report.GetDimensionCategoryAndComponentResponse>}
 */
const methodDescriptor_SummaryReport_GetDimensionCategoryAndComponent = new grpc.web.MethodDescriptor(
  '/adx.summary_report.SummaryReport/GetDimensionCategoryAndComponent',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.summary_report.GetDimensionCategoryAndComponentRequest,
  proto.adx.summary_report.GetDimensionCategoryAndComponentResponse,
  /**
   * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.deserializeBinary
);


/**
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportClient.prototype.getDimensionCategoryAndComponent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetDimensionCategoryAndComponent',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetDimensionCategoryAndComponent);
};


/**
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.summary_report.SummaryReportPromiseClient.prototype.getDimensionCategoryAndComponent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.summary_report.SummaryReport/GetDimensionCategoryAndComponent',
      request,
      metadata || {},
      methodDescriptor_SummaryReport_GetDimensionCategoryAndComponent);
};


module.exports = proto.adx.summary_report;

