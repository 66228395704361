/**
 * @fileoverview gRPC-Web generated client stub for adx.dynamic_dimension_category_report
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.dynamic_dimension_category_report = require('./dynamic_dimension_category_report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest,
 *   !proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetTabListOfDynamicDimensionCategoryReportPage = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetTabListOfDynamicDimensionCategoryReportPage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest,
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getTabListOfDynamicDimensionCategoryReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetTabListOfDynamicDimensionCategoryReportPage',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetTabListOfDynamicDimensionCategoryReportPage);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getTabListOfDynamicDimensionCategoryReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetTabListOfDynamicDimensionCategoryReportPage',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetTabListOfDynamicDimensionCategoryReportPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryMonthlyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryMonthlyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryMonthlyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryMonthlyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDailyResultGraphReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDailyResultGraphReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryDailyResultGraphReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDailyResultGraphReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDailyResultGraphReportByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryDailyResultGraphReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDailyResultGraphReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDailyResultGraphReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest,
 *   !proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse>}
 */
const methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest,
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportClient.prototype.getDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryReportPromiseClient.prototype.getDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.dynamic_dimension_category_report.DynamicDimensionCategoryReport/GetDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_DynamicDimensionCategoryReport_GetDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider);
};


module.exports = proto.adx.dynamic_dimension_category_report;

