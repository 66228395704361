
function ScreenBarValue2() {
  this.isFinish = true;
}
ScreenBarValue2.prototype.start = function () {
  this.isFinish = false;
};
ScreenBarValue2.prototype.stop = function () {
  this.isFinish = true;
};

export { ScreenBarValue2 }
