
import { TimeYmd } from './TimeYmd.js'
import { TimeHms } from './TimeHms.js'

function TimeYmdHms(y = 2021, m = 5, d = 1, h = 0, min = 0, s = 0) {
    this.ymd = new TimeYmd(y, m, d);
    this.hms = new TimeHms(h, min, s);
}
TimeYmdHms.prototype.getYm = function () {
    return this.ymd.getYm();
}

export { TimeYmdHms }