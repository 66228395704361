
function InputerJs(callback) {
    this.text = "";
    this.callback = callback;
}
InputerJs.prototype.notify = function (text) {
    this.text = text;
    if (this.callback) {
        this.callback(text);
    }
};


export { InputerJs }