<template>
  <div class="main_content_wrapper">
    <div class="adx_report_keyword">
      <div class="left_main_content">
        <div style="background: white; width: calc(100% - 20px)">
          <div class="left_main_content__header">
            <ContentHeader title="予算決定" />
          </div>

          <div style="padding: 10px; width: 100%; display: flex">
            <div class="backlash_tab">
              <router-link to="/budget/simulation">
                <div class="backlash_tab__item">シミュレーション</div>
              </router-link>
            </div>
            <div class="backlash_tab backlash_tab--active">
              <router-link to="/budget/allocation">
                <div class="backlash_tab__item backlash_tab__item--active">
                  アロケーション
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="left_main_content__body">
          <div class="box">
            <div class="frame">
              <div class="box">
                <div style="width: 100%">
                  <div class="frame__header">キャンペーン別バジェット調整</div>
                  <div class="frame__body">
                    <Spinner v-if="page.isNetworking" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub_content_layer">
        <div class="sub_content_wrapper">
          <div id="lay_right_sidemenu" class="right_sidemenu_layer">
            <SideMenu :adx="adx" selectId="keyword" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideMenu from "@/components/budget/SideMenu.vue";
import ContentHeader from "@/components/budget/AllocationContentHeader.vue";
import Spinner from "@/components/layout/Spinner.vue";

import { BudgetAllocationPage } from "@/js/page/budget/BudgetAllocationPage.js";

export default {
  name: "BudgetAllocationPage",
  data() {
    return {
      name: "アロケーション",
      page: new BudgetAllocationPage(),
    };
  },
  emits: ['screenevent'],
  mounted() {
    this.$emit("screenevent", "budget");
    this.page.initialize();
  },
  methods: {
    tabselect(id) {
      console.log(id);
    },
  },
  components: {
    SideMenu,
    ContentHeader,
    Spinner,
  },
  props: {
    adx: Object,
  },
};

</script>

