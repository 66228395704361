/**
 * @fileoverview gRPC-Web generated client stub for adx.keyword_report
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.keyword_report = require('./keyword_report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.keyword_report.KeywordReportClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.keyword_report.KeywordReportPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.keyword_report.TabListOfKeywordReportPageRequest,
 *   !proto.adx.keyword_report.TabListOfKeywordReportPageResponse>}
 */
const methodDescriptor_KeywordReport_GetTabListOfKeywordReportPage = new grpc.web.MethodDescriptor(
  '/adx.keyword_report.KeywordReport/GetTabListOfKeywordReportPage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.keyword_report.TabListOfKeywordReportPageRequest,
  proto.adx.keyword_report.TabListOfKeywordReportPageResponse,
  /**
   * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.keyword_report.TabListOfKeywordReportPageResponse.deserializeBinary
);


/**
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.keyword_report.TabListOfKeywordReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.keyword_report.KeywordReportClient.prototype.getTabListOfKeywordReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.keyword_report.KeywordReport/GetTabListOfKeywordReportPage',
      request,
      metadata || {},
      methodDescriptor_KeywordReport_GetTabListOfKeywordReportPage);
};


/**
 * @param {!proto.adx.keyword_report.TabListOfKeywordReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.keyword_report.TabListOfKeywordReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.keyword_report.KeywordReportPromiseClient.prototype.getTabListOfKeywordReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.keyword_report.KeywordReport/GetTabListOfKeywordReportPage',
      request,
      metadata || {},
      methodDescriptor_KeywordReport_GetTabListOfKeywordReportPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.keyword_report.KeywordReportByAdProviderRequest,
 *   !proto.adx.keyword_report.KeywordReportByAdProviderResponse>}
 */
const methodDescriptor_KeywordReport_GetKeywordReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.keyword_report.KeywordReport/GetKeywordReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.keyword_report.KeywordReportByAdProviderRequest,
  proto.adx.keyword_report.KeywordReportByAdProviderResponse,
  /**
   * @param {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.keyword_report.KeywordReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.keyword_report.KeywordReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.keyword_report.KeywordReportClient.prototype.getKeywordReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.keyword_report.KeywordReport/GetKeywordReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_KeywordReport_GetKeywordReportByAdProvider);
};


/**
 * @param {!proto.adx.keyword_report.KeywordReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.keyword_report.KeywordReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.keyword_report.KeywordReportPromiseClient.prototype.getKeywordReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.keyword_report.KeywordReport/GetKeywordReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_KeywordReport_GetKeywordReportByAdProvider);
};


module.exports = proto.adx.keyword_report;

