<template>
  <div class="side_menu">
    <ul class="side-nav">
      <li class="side-nav__item_wrapper">
        <router-link to="/budget/simulation">
          <div
            class="side-nav__item"
            v-bind:class="{
              'side-nav__item--selected': selectedId == 'budget',
            }"
          >
            <i class="circle_icon"></i>目標作成
          </div>
        </router-link>
        <router-link to="/budget/simulations">
          <div
            class="side-nav__item"
            v-bind:class="{
              'side-nav__item--selected': selectedId == 'budgets',
            }"
          >
            <i class="circle_icon"></i>目標変更
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  props: {
    selectId: String,
    adx: Object,
  },
  data() {
    return {
      selectedId: "",
      menus: [],
      dimenstionPath: "/report/dimension/:id",
    };
  },
  mounted() {
    let self = this;
    this.selectedId = this.selectId;

    this.adx.getScreenStructureById("report").subject.addObserver(function () {
      self.loadmenu();
    });
    this.loadmenu();
  },
  methods: {
    loadmenu() {
      let sideMenus = this.adx.getScreenStructureById("report").getSideMenus();
      for (let key in sideMenus) {
        let page = sideMenus[key];
        let isHit = false;
        for (let key in this.menus) {
          let menu = this.menus[key];
          if (menu.id == page.id) {
            isHit = true;
            break;
          }
        }
        if (!isHit) {
          this.menus.push(page);
        }
      }
    },
  },
};
</script>
