<template>
  <div style="height: 60px; width: 100%;">
    <div class="spinningCircle"></div>
  </div>
</template>

<style scoped>
.spinningCircle {
  margin: auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 8px solid rgba(255, 255, 255, 0);
  border-top-color: 4px solid #39434e;
  border-right-color: 4px solid #39434e;
  -webkit-animation: single 2s infinite linear;
  animation: single 2s infinite linear;
}

@-webkit-keyframes single {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
  50% {
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
}

@keyframes single {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
  50% {
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
}
</style>
