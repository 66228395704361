import {
  GetSimulationFormatFileUrlRequest,
  GetSimulationDataFileUrlRequest,
  CampaignSimulatedBudgetInitialBuildRequest,
  CampaignSimulatedBudgetRebuildRequest,
  DynamicCategorySimulatedBudgetInitialBuildRequest,
  DynamicCategorySimulatedBudgetRebuildRequest,
  MonthlySimulatedBudgetOverallInitialBuildRequest,
  MonthlySimulatedBudgetOverallRebuildRequest,
  MonthlySimulatedVersionRequest,
  InsertBasePeriodRequest,
  CampaignSimulatedBudgetRequest,
  UpsertBasePeriodRequest,
  ListValue,
} from './protos_gen/budget_simulation_pb.js';
import { BudgetSimulationClient } from './protos_gen/budget_simulation_grpc_web_pb.js';
import { url } from '../../setting.js';
import { uuid } from '../../tacklebox/core/uuid.js';
/* eslint-disable */

function getSimulationFormatFileUrl(id, dto, callback, callback_end = () => { },
  callback_error = (e) => {
    console.log('Error returned from BFF.');
    throw e;
  },
  user_role = "",
  currentPageStreams = []) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };

  let uuidStr = uuid();

  let request = new GetSimulationFormatFileUrlRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);

  let client = new BudgetSimulationClient(url.grpc, null, null);
  const stream = client.getSimulationFormatFileUrl(request, meta);
  currentPageStreams.push(stream);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', callback_error);

  return uuidStr;
}

function getSimulationDataFileUrl(id, dto, callback, callback_end = () => { },
  callback_error = (e) => {
    console.log('Error returned from BFF.');
    throw e;
  },
  user_role = "",
  currentPageStreams = []) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let actualMonth = dto?.timeRequest?.actualMonth;

  let request = new GetSimulationDataFileUrlRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);

  let client = new BudgetSimulationClient(url.grpc, null, null);
  const stream = client.getSimulationDataFileUrl(request, meta);
  currentPageStreams.push(stream);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', callback_error);

  return uuidStr;
}

function getCampaignSimulatedBudgetInitialBuild(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new CampaignSimulatedBudgetInitialBuildRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);
  request.setAction('access');

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.getCampaignSimulatedBudgetInitialBuild(request);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getCampaignSimulatedBudgetRebuild(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new CampaignSimulatedBudgetRebuildRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);
  request.setAction('access');

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.getCampaignSimulatedBudgetRebuild(request);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicCategorySimulatedBudgetInitialBuild(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new DynamicCategorySimulatedBudgetInitialBuildRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);
  request.setAction('access');

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.getDynamicCategorySimulatedBudgetInitialBuild(request);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicCategorySimulatedBudgetRebuild(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new DynamicCategorySimulatedBudgetRebuildRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);
  request.setAction('access');

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.getDynamicCategorySimulatedBudgetRebuild(request);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getMonthlySimulatedBudgetOverallInitialBuild(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new MonthlySimulatedBudgetOverallInitialBuildRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);
  request.setAction('access');

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.getMonthlySimulatedBudgetOverallInitialBuild(request);
  stream.on('data', message => {
    console.log(message);
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', message => {
    console.log(message);
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });

  //TBD エラー

  return uuidStr;
}

function getMonthlySimulatedBudgetOverallRebuild(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new MonthlySimulatedBudgetOverallRebuildRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);
  request.setAction('access');

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.getMonthlySimulatedBudgetOverallRebuild(request);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getMonthlySimulatedVersion(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new MonthlySimulatedVersionRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.getMonthlySimulatedVersion(request);

  stream.on('data', message => {
    console.log(message);
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function insertBasePeriod(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';
  let request = new InsertBasePeriodRequest();
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);
  request.setStart('2021-01-01');
  request.setEnd('2021-01-30');
  request.setVersion('目標作成');
  request.setRevision('2');
  request.setAdpageid('110');

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.insertBasePeriod(request);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function insertCampaignSimulatedBudget(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new CampaignSimulatedBudgetRequest();
  request.setBaseperiodforforecastid(uuidStr);
  let value = new ListValue();
  value.setSimulatedAt('');
  value.setCampaign('');
  value.setSimulatedDailyBudget('');
  value.setActualDailyBudget('');
  value.setKeyName1('');
  value.setKeyValue1('');
  value.setKeyName2('');
  value.setKeyValue2('');
  value.setKeyName3('');
  value.setKeyValue3('');
  value.setKeyName4('');
  value.setKeyValue4('');
  value.setKeyName5('');
  value.setKeyValue5('');
  value.setKeyName6('');
  value.setKeyValue6('');
  value.setKeyName7('');
  value.setKeyValue7('');
  value.setKeyName8('');
  value.setKeyValue8('');
  value.setKeyName9('');
  value.setKeyValue9('');
  value.setKeyName10('');
  value.setKeyValue10('');
  value.setKeyName11('');
  value.setKeyValue11('');
  value.setKeyName12('');
  value.setKeyValue12('');
  value.setKeyName13('');
  value.setKeyValue13('');
  value.setBracket1('');
  value.setBracket2('');
  value.setBracket3('');
  value.setBracket4('');
  value.setBracket5('');
  value.setBracket6('');
  value.setBracket7('');
  value.setBracket8('');
  value.setBracket9('');
  value.setKeyFormat1('');
  value.setKeyFormat2('');
  value.setKeyFormat3('');
  value.setKeyFormat4('');
  value.setKeyFormat5('');
  value.setKeyFormat6('');
  value.setKeyFormat7('');
  value.setKeyFormat8('');
  value.setKeyFormat9('');
  value.setKeyFormat10('');
  value.setKeyFormat11('');
  value.setKeyFormat12('');
  value.setKeyFormat13('');
  request.setValues('');

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.insertCampaignSimulatedBudget(request);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function upsertBasePeriod(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new UpsertBasePeriodRequest();
  request.setAdvertizerid(id);
  request.setStart('');
  request.setEnd('');
  request.setActualmonth('');
  request.setVersion('');
  request.setAdpageid('10');

  let client = new BudgetSimulationClient(url.grpc, null, null);
  let stream = client.upsertBasePeriod(request);

  stream.on('data', message => {
    if (callback) {
      if (!!message != false) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  return uuidStr;
}

export {
  getSimulationFormatFileUrl,
  getSimulationDataFileUrl,
  getCampaignSimulatedBudgetInitialBuild,
  getCampaignSimulatedBudgetRebuild,
  getDynamicCategorySimulatedBudgetInitialBuild,
  getDynamicCategorySimulatedBudgetRebuild,
  getMonthlySimulatedBudgetOverallInitialBuild,
  getMonthlySimulatedBudgetOverallRebuild,
  getMonthlySimulatedVersion,
  insertBasePeriod,
  insertCampaignSimulatedBudget,
  upsertBasePeriod,
};
