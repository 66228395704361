import { Cell } from '../page/data.js'

function SpanCell(cells) {
    this.cells = cells;
}
SpanCell.prototype.getString = function () {
    if (this.cells.length > 0) {
        return this.cells[0].getString();
    }

    return "";
};
SpanCell.prototype.getTitle = function () {
    if (this.cells.length > 0) {
        let title = this.cells[0]?.getString();
        let t = title.split('_')[0];
        return t;
    }

    return "";
};
SpanCell.prototype.isSpan = function () {
    if (this.cells) {
        if (this.cells.length > 1) {
            return true;
        }
    }
    return false;
};
SpanCell.prototype.spanCount = function () {
    if (this.cells) {
        return this.cells.length;
    }
    return 1;
};

const spantag = '_';

function cells2SpanCells(allcells, exclusions = []) {
    if (allcells) {
        //
    }else{
        return [];
    }
    function isexclusion(index) {
        for (let i in exclusions) {
            if (exclusions[i] == index) {
                return true;
            }
        }
        return false;
    }
    let spanCells = [];
    let spans = [];
    for (let i = 0; i < allcells.length; i++) {
        let tc = allcells[i];
        let isSpanTarget = false;

        if (tc) {
            try {
                if (tc.getString().includes(spantag)) {
                    isSpanTarget = true;
                }
            } catch (ex) {
                console.log(ex);
            }
        }

        if (isSpanTarget) {
            if (spans.length == 0) {
                if (!isexclusion(i)) {
                    spans.push(tc);
                }

                if (i == (allcells.length - 1)) {
                    spanCells.push(new SpanCell(spans));
                }
            } else {
                if (spans[0].getString().split(spantag)[0] == tc.getString().split(spantag)[0]) {
                    if (!isexclusion(i)) {
                        spans.push(tc);
                    }
                    if (i == (allcells.length - 1)) {
                        spanCells.push(new SpanCell(spans));
                    }
                } else {
                    spanCells.push(new SpanCell(spans));
                    spans = [];
                    if (!isexclusion(i)) {
                        spans.push(tc);
                    }
                }
            }
        } else {
            if (spans.length > 0) {
                spanCells.push(new SpanCell(spans));
                spans = [];
            }
            if (!isexclusion(i)) {
                spanCells.push(new SpanCell([tc]));
            }
        }
    }

    return spanCells;
}

function spanTilte2HeaderTitle(title) {
    let ss = title.split(spantag);
    if (ss.length > 1) {
        return ss[1];
    }

    return title;
}

function titles2Cell(titles) {
    let ress = [];

    for (let i in titles) {
        ress.push(new Cell(titles[i]));
    }

    return ress;
}

function is2SpanCell(cell) {
    if (cell) {
        let ss = cell.getString()?.includes(spantag);
        if (ss) {
            return ss;
        }
    }
    return false;
}

export { cells2SpanCells, spanTilte2HeaderTitle, titles2Cell, is2SpanCell }
