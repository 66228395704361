import {
    GenerateNewSprintRequest,
    CreateSprintRequest,
    DeleteSprintRequest,
    GetConsultantSprintsRequest,
    GetAdvertizerSprintsRequest,
    CreateCustomFieldRequest,
    UpdateCustomFieldRequest,
    DeleteCustomFieldRequest,
    GetCustomFieldsRequest,
    Sprint,
    MonthlyStatus,
    ActionLog,
    CustomField,
    CustomFieldStruct,
} from './protos_gen/sprint_pb.js'
import { SprintServiceClient } from './protos_gen/sprint_grpc_web_pb.js'
import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function generateNewSprint(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GenerateNewSprintRequest();
    request.setRequestid(uuidStr);
    request.setStartdate(dto.startDate);
    request.setEnddate(dto.endDate);
    request.setAdvertizerid(dto.advertizerId);

    let client = new SprintServiceClient(url.grpc, null, null);
    const stream = client.generateNewSprint(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        //throw e;
    });
    //TBD エラー

    return uuidStr;
}
function createSprint(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };

    const sprint = new Sprint();

    for (const row of dto.sprint.monthlystatusList) {
        const monthlyStatus = new MonthlyStatus();
        monthlyStatus.setLabel(row.label)
        monthlyStatus.setValue(row.value);
        monthlyStatus.setType(row.type);
        monthlyStatus.setRate(row.rate);
        sprint.addMonthlystatus(monthlyStatus);
    }
    sprint.setMonthlystatustext(dto.sprint.monthlystatustext);
    for (const row of dto.sprint.todoactionitemsList) {
        const actionLogTodo = new ActionLog();
        actionLogTodo.setProvider(row.provider)
        actionLogTodo.setTitle(row.title);
        actionLogTodo.setImplementationdate(row.implementationdate);
        actionLogTodo.setValidationdate(row.validationdate);
        sprint.addTodoactionitems(actionLogTodo);
    }
    for (const row of dto.sprint.inprogressactionitemsList) {
        const actionLogInProgress = new ActionLog();
        actionLogInProgress.setProvider(row.provider)
        actionLogInProgress.setTitle(row.title);
        actionLogInProgress.setImplementationdate(row.implementationdate);
        actionLogInProgress.setValidationdate(row.validationdate);
        sprint.addInprogressactionitems(actionLogInProgress);
    }
    for (const row of dto.sprint.customfieldsList) {
        const customField = new CustomField();
        customField.setField(row.field)
        customField.setValue(row.value);
        sprint.addCustomfields(customField);
    }

    sprint.setId(dto.sprint.id);
    sprint.setUpdatedby(dto.sprint.updatedby);
    sprint.setUpdatedat(dto.sprint.updatedat);
    sprint.setStartdate(dto.sprint.startdate);
    sprint.setEnddate(dto.sprint.enddate);
    sprint.setMayorpolicy(dto.sprint.mayorpolicy);
    sprint.setMonthlygoalachieve(dto.sprint.monthlygoalachieve);
    sprint.setMonthlypolicy(dto.sprint.monthlypolicy);
    sprint.setProblempoints(dto.sprint.problempoints);
    sprint.setOverview(dto.sprint.overview);
    sprint.setProblemfactors(dto.sprint.problemfactors);
    sprint.setApproachpolicy(dto.sprint.approachpolicy);
    sprint.setMdconfirm(dto.sprint.mdconfirm);
    sprint.setMdfeedback(dto.sprint.mdfeedback);

    let uuidStr = uuid();
    let request = new CreateSprintRequest();
    request.setRequestid(uuidStr);
    request.setSprint(sprint);
    request.setAdvertizerid(dto.advertizerId);

    let client = new SprintServiceClient(url.grpc, null, null);
    const stream = client.createSprint(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        //throw e;
    });
    //TBD エラー

    return uuidStr;

}
function deleteSprint(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new DeleteSprintRequest();
    request.setRequestid(uuidStr);
    request.setId(dto.id);

    let client = new SprintServiceClient(url.grpc, null, null);
    const stream = client.deleteSprint(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        //throw e;
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantSprints(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantSprintsRequest();
    request.setRequestid(uuidStr);
    request.setActualmonth(dto.actualMonth);
    request.setAdvertizerid(dto.advertizerId);

    let client = new SprintServiceClient(url.grpc, null, null);
    const stream = client.getConsultantSprints(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        //throw e;
    });
    //TBD エラー

    return uuidStr;
}
function getAdvertizerSprints(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetAdvertizerSprintsRequest();
    request.setRequestid(uuidStr);
    request.setActualmonth(dto.actualMonth);
    request.setAdvertizerid(dto.advertizerId);

    let client = new SprintServiceClient(url.grpc, null, null);
    const stream = client.getAdvertizerSprints(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        //throw e;
    });
    //TBD エラー

    return uuidStr;
}
function createCustomField(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new CreateCustomFieldRequest();
    request.setRequestid(uuidStr);
    request.setLabel(dto.label);
    request.setAdvertizerid(dto.advertizerId);
    let client = new SprintServiceClient(url.grpc, null, null);
    const stream = client.createCustomField(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        //throw e;
    });
    //TBD エラー

    return uuidStr;
}
function updateCustomField(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    const customFieldStruct = new CustomFieldStruct();
    customFieldStruct.setId(dto.field.id)
    customFieldStruct.setLabel(dto.field.label)

    let uuidStr = uuid();
    let request = new UpdateCustomFieldRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setField(customFieldStruct);

    let client = new SprintServiceClient(url.grpc, null, null);
    const stream = client.updateCustomField(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        //throw e;
    });
    //TBD エラー

    return uuidStr;
}
function deleteCustomField(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new DeleteCustomFieldRequest();
    request.setRequestid(uuidStr);
    request.setId(dto.id);
    request.setAdvertizerid(dto.advertizerId);
    let client = new SprintServiceClient(url.grpc, null, null);
    const stream = client.deleteCustomField(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        //throw e;
    });
    //TBD エラー

    return uuidStr;
}
function getCustomFields(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetCustomFieldsRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    let client = new SprintServiceClient(url.grpc, null, null);
    const stream = client.getCustomFields(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        //throw e;
    });
    //TBD エラー

    return uuidStr;
}

export {
    generateNewSprint,
    createSprint,
    deleteSprint,
    getConsultantSprints,
    getAdvertizerSprints,
    createCustomField,
    updateCustomField,
    deleteCustomField,
    getCustomFields,
}
