function ObserverSubject() {
    this.callbacks = [];
}
ObserverSubject.prototype.addObserver = function (callback) {
    this.callbacks.push(callback);
}
ObserverSubject.prototype.notify = function () {
    try {
        for (let key in this.callbacks) {
            this.callbacks[key]();
        }
    } catch (ex) {
        console.log(ex);
    }
}
ObserverSubject.prototype.removeAll = function () {
    this.callbacks = [];
};

export { ObserverSubject }
