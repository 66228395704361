<template>
  <div>
    <div class="main_content_wrapper">
      <div class="adx_report_keyword">
        <div id="main_001" class="left_main_content" :class="[this.adx.user.nowRole() == 'advertizer' ? 'left_main_content--fit' : '' ]">
          <div class="content_wrapper">
            <div class="left_main_content__header" style="position: relative">
              <ContentHeader title="カスタムレポート" />
              <div style="position: absolute; top: 76px; left: 0px; right: 0">
                <ScreenBarProgress2 :process="page.process" />
              </div>
            </div>
          </div>

          <div class="left_main_content__body">
            <div class="box content_wrapper" v-if="page.master?.pageId != null">
              <button class="createreport_btn mousepointer" v-if="this.adx.user.nowRole() != 'advertizer'" v-on:click="page.createReport()">+データ作成</button>
            </div>

            <div class="content_wrapper" v-if="page.master?.pageId != null">
              <template v-for="(item, index) in page.reports" :key="index">
                <div class="box">
                  <div class="frame frame--expansion" style="width: 100%">
                    <div class="box" style="width: 100%">
                      <div style="overflow: auto">
                        <div class="frame__header frame__header--expansion_lock" v-if="true">
                          <div style="position: relative">
                            <div class="frame_header__title">
                              <div class="mousetextpointer" v-if="!item.isEditTitle" v-on:click="edittitle(item)"
                                :class="[item.getTitle() == '無題' ? 'mudai' : '']">
                                {{ item.getTitle() }}
                              </div>
                              <div v-if="item.isEditTitle">
                                <div class="edittitle__background" v-on:click="item.closeEditTitle()"></div>
                                <input ref="title_input" style="padding: 0;font-family: 'Noto Sans JP', sans-serif; position: relative; z-index:2"
                                  id="title_input_id" type="text" v-model="item.title"
                                  @keydown.enter="item.closeEditTitle()" />
                              </div>

                              <div v-if="true" class="mousepointer" style="position: absolute; right: 0; top: 0; z-index: 9;"
                                v-on:click="showReportMenu(item, $event)">
                                ...
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="frame__body scroll_bar" style="overflow: auto" v-if="item.type == 1">
                          <div v-if="false">
                            <div>{{ item.structureHolder.structure }}</div>
                            <div>{{ item.getHeaderCell() }}</div>
                          </div>
                          <table id="summary_table_tag" class="floater_table summary_table expansion_table"
                            style="table-layout: fixed">
                            <thead class="floater_table__header expansion_table__header">
                              <tr>
                                <th style="background: white" class="tadcontrol tadcontrol--titleheader"></th>
                                <template v-for="(hc, index) in item.getHeaderCell()" :key="index">
                                  <th style="cursor: pointer; position: relative" ref="summary_header_ref"
                                    :id="'summary_header_id_' + index"
                                    class="floater_table__header_col expansion_table__th"
                                    v-on:click.stop="showShowHideByKey($event, item, hc.key)"
                                    :class="dragstate == 2 ? 'expansion_table__th--drag' : ''">

                                    <div v-if="true" class="expansion_table__dragmarker" v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart(item, index, $event)">
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18"
                                            viewBox="0 0 16 18" transform="rotate(90)">
                                            <g id="g484" data-name="g484" transform="translate(-8 -123)">
                                              <path id="p530" data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)" fill="#fff" />
                                              <rect id="s_949" data-name="s949" width="16" height="2"
                                                transform="translate(8 131)" fill="#fff" />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="floater_table__header_col_wrapper" :style="{
                                      width: `${item.getSizeByIndex(index)}px`,
                                    }">
                                      <div class="floater_table__header_col_text">
                                        {{ hc.getString('consultant') }}
                                      </div>
                                    </div>
                                  </th>
                                </template>
                              </tr>
                            </thead>

                            <tbody class="floater_table__body" :set="targetRecords = item.getRecords()">
                              <template v-for="(record, pindex) in targetRecords" :key="pindex">
                                <tr>
                                  <td style="background: white" class="tadcontrol tadcontrol--data">
                                    <div class="tadcontrol_box" v-if="this.adx.user.nowRole() != 'advertizer'">
                                      <img v-on:click="insertRecord(item, 1, record, pindex)"
                                        class="cmark yubi recordaddmark mousepointer"
                                        src="@/assets/icon/add_black_24dp.svg" />
                                      <img v-on:click="selectItem(item, pindex, $event)"
                                        class="cmark yubi recordmenumark mousepointer"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg" />
                                    </div>
                                  </td>
                                  <template v-for="(dc, index) in record" :key="index">
                                    <td class="mousepointer" v-if="index == 0"
                                      v-on:click="onTimeChange(item, pindex, $event)">
                                      <div class="floater_table__body_col_wrapper" :style="{
                                        width: `${item.getSizeByIndex(index)}px`,
                                      }">
                                        <div class="floater_table__body_col_text">
                                          {{ ymdformat(dc.getString()) }}
                                        </div>
                                      </div>
                                    </td>
                                    <td class="mousepointer"
                                      v-if="index > 0 && index <= item?.segmentRaw.length - item.countHideSeg()"
                                      v-on:click="onSegmentChange(item, pindex, $event)">
                                      <div class="floater_table__body_col_wrapper" :style="{
                                        width: `${item.getSizeByIndex(index)}px`,
                                      }">
                                        <div class="floater_table__body_col_text" style="white-space: break-spaces">
                                          <template v-for="(seg, sindex) in dc.cells" :key="sindex">
                                            <div :class="[sindex > 0 ? 'floater_table__body_col_text--margin' : '']">{{
                                              seg.getString() }}</div>
                                          </template>
                                        </div>
                                      </div>
                                    </td>
                                    <td v-if="index > item?.segmentRaw.length - item.countHideSeg()"
                                      style="position: relative">
                                      <div class="floater_table__body_col_wrapper"
                                        :style="{ width: `${item.getSizeByIndex(index)}px` }"
                                        :set="(cellDesign = page.getProviderTableCellDesignByAll(dc.data, item.raw, index - item?.segmentRaw.length - 1 - item.countHideSeg(), item, targetRecords, pindex, index))">
                                        <div class="floater_table__body_col_text" style="text-align: right;"
                                          :style="[cellDesign.design != 'box' ? 'position: relative' : '']">
                                          <div v-if="!item.colorHidesByKey(dc.key)" v-bind:style="{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            padding: cellDesign.design == 'box' ? '0px' : '4px',
                                            paddingLeft: '0px',
                                          }">
                                            <div style="height: 100%; width: " v-bind:style="{
                                              width: cellDesign.isColored
                                                ? cellDesign.percent + '%'
                                                : '0%',
                                              background: cellDesign.isColored
                                                ? cellDesign.color
                                                : '#FFFFFF',
                                              border:
                                                cellDesign.border != null
                                                  ? cellDesign.border
                                                  : '',
                                            }"></div>
                                          </div>
                                          <div style="position: relative;">
                                            {{ dc.getString() }}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </template>
                                </tr>
                              </template>
                              <tr v-if="this.adx.user.nowRole() != 'advertizer'">
                                <td style="background: white" class="tadcontrol tadcontrol--addrecord"></td>
                                <td class="mousepointer" :colspan="item.getHeaderCell().length"
                                  v-on:click="onCreateRecord(item, $event)">
                                  <div style="padding: 8px">+New</div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="frame__body scroll_bar" style="overflow: auto" v-if="item.type == 2">
                          <div>
                            <div v-if="false">
                              {{ item.structureHolder.structure }}
                              {{ item.getHeaderCell() }}
                              {{ item.getHeaderDateCell() }}
                              {{ item.getRecords() }}
                            </div>
                            <table id="summary_table_tag"
                              class="floater_table floater_table--full summary_table expansion_table"
                              style="table-layout: auto; margin-top: 24px;">
                              <thead class="floater_table__header expansion_table__header">
                                <tr>
                                  <th style="background: white" class="tadcontrol tadcontrol--type2dateheader"></th>
                                  <template v-for="(hitem, hindex) in item.getHeaderDateCell()" :key="hindex">
                                    <th style="cursor: pointer; position: relative" ref="summary_header_ref"
                                      :id="'summary_header_id_' + hindex"
                                      class="floater_table__header_col expansion_table__th" :colspan="hitem.colspan"
                                      :class="[
                                        hitem.isHide == true ? 'floater_table_th--hide' : '',
                                      ]"
                                      v-on:click="onTimeChange(item, item.getPeriodsIndexByHeaderCellIndex(hindex), $event)">

                                      <div class="vmarkarea" v-if="(item.getPeriodsIndexByHeaderCellIndex(hindex) >= 0) && (this.adx.user.nowRole() != 'advertizer')" v-on:click.stop="empty()">
                                        <div class="vmarker">
                                          <img v-on:click.stop="item.addDate()"
                                            class="cmark yubi vrecordaddmark mousepointer"
                                            src="@/assets/icon/add_black_24dp.svg" />
                                          <img v-on:click.stop="type3menu(item, item.getPeriodsIndexByHeaderCellIndex(hindex), $event)"
                                            class="cmark yubi vrecordmenumark mousepointer"
                                            src="@/assets/icon/drag_indicator_black_24dp.svg" />
                                        </div>
                                      </div>


                                      <div v-if="false" class="expansion_table__dragmarker">
                                        <div class="expansion_table__dragmarker__arrow">
                                          <div class="expansion_table__dragmarker__lrarrow">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18"
                                              viewBox="0 0 16 18" transform="rotate(90)">
                                              <g id="g484" data-name="g484" transform="translate(-8 -123)">
                                                <path id="p530" data-name="p530"
                                                  d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                  transform="translate(4 120)" fill="#fff" />
                                                <rect id="s_949" data-name="s949" width="16" height="2"
                                                  transform="translate(8 131)" fill="#fff" />
                                              </g>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="floater_table__header_col_wrapper">
                                        <div class="floater_table__header_col_text">
                                          {{ ymdformat(hitem.getString('consultant')) }}
                                        </div>
                                      </div>
                                    </th>
                                  </template>
                                  <th v-if="this.adx.user.nowRole() != 'advertizer'" class="mousepointer" style="padding: 8px;" v-on:click="item.addDate()">+NEW</th>
                                </tr>
                                <tr v-if="isCells2SpanCells(item.getHeaderCell()) && false">
                                  <template v-for="(hitem, i1) in cells2SpanCells(item.getHeaderCell(), '')" :key="i1">
                                    <th style="background: white; border: initial"
                                      v-if="hitem.spanCount() == 1 && !is2SpanCell(hitem)"></th>
                                    <th v-if="hitem.spanCount() == 1 && is2SpanCell(hitem)">
                                      {{ hitem.getTitle() }}
                                    </th>
                                    <th v-if="hitem.spanCount() > 1" :colspan="hitem.spanCount()">
                                      {{ hitem.getTitle() }}
                                    </th>
                                  </template>
                                </tr>

                                <tr>
                                  <th style="background: white" class="tadcontrol tadcontrol--titleheader"></th>
                                  <template v-for="(hitem, index) in item.getHeaderCell()" :key="index">
                                    <th style="cursor: pointer; position: relative" ref="summary_header_ref"
                                      v-on:click.stop="showShowHideByKey($event, item, hitem.key)"
                                      :id="'summary_header_id_' + index"
                                      class="floater_table__header_col expansion_table__th">
                                      <div v-if="true" class="expansion_table__dragmarker" v-on:click.stop=""
                                        v-on:mousedown.stop="dragstart(item, index, $event)">
                                        <div class="expansion_table__dragmarker__arrow">
                                          <div class="expansion_table__dragmarker__lrarrow">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18"
                                              viewBox="0 0 16 18" transform="rotate(90)">
                                              <g id="g484" data-name="g484" transform="translate(-8 -123)">
                                                <path id="p530" data-name="p530"
                                                  d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                  transform="translate(4 120)" fill="#fff" />
                                                <rect id="s_949" data-name="s949" width="16" height="2"
                                                  transform="translate(8 131)" fill="#fff" />
                                              </g>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="floater_table__header_col_wrapper" :style="{
                                        width: `${item.getSizeByIndex(index)}px`,
                                      }">
                                        <div class="floater_table__header_col_text">
                                          {{ hitem.getString('consultant') }}
                                        </div>
                                      </div>
                                    </th>
                                  </template>
                                  <th v-if="this.adx.user.nowRole() != 'advertizer'"></th>
                                </tr>
                              </thead>

                              <tbody class="floater_table__body" :set="(targetRecords = item.getRecords())">
                                <template v-for="(record, pindex) in targetRecords" :key="pindex">
                                  <tr>
                                    <td style="background: white" class="tadcontrol tadcontrol--data">
                                      <div class="tadcontrol_box" v-if="this.adx.user.nowRole() != 'advertizer'">
                                        <img v-on:click="item.insertRecord(2, record, pindex, false)"
                                          class="cmark yubi recordaddmark mousepointer"
                                          src="@/assets/icon/add_black_24dp.svg" />
                                        <img v-on:click="selectItem(item, pindex, $event)"
                                          class="cmark yubi recordmenumark mousepointer"
                                          src="@/assets/icon/drag_indicator_black_24dp.svg" />
                                      </div>
                                    </td>
                                    <template v-for="(dc, index) in record" :key="index">
                                      <td class="mousepointer" v-if="index < (item?.segmentRaw.length - item.countHideSeg())"
                                        v-on:click="onSegmentChange(item, pindex, $event)">
                                        <div class="floater_table__body_col_wrapper" :style="{
                                          width: `${item.getSizeByIndex(index)}px`,
                                        }">
                                          <div class="floater_table__body_col_text" style="white-space: break-spaces">
                                            <template v-for="(seg, sindex) in dc.cells" :key="sindex">
                                              <div :class="[sindex > 0 ? 'floater_table__body_col_text--margin' : '']">{{
                                                seg.getString() }}</div>
                                            </template>
                                          </div>
                                        </div>
                                      </td>
                                      <td v-if="index >= (item?.segmentRaw.length - item.countHideSeg())" style="position: relative">
                                        <div class="floater_table__body_col_wrapper" style="text-align: right"
                                          :style="{ width: `${item.getSizeByIndex(index)}px` }"
                                          :set="(cellDesign = page.getProviderTableCellDesignByAll(dc.data, item.raw, (index - item?.segmentRaw.length), item, targetRecords, pindex, index))">
                                          <div class="floater_table__body_col_text" style="text-align: right;"
                                            :style="[cellDesign.design != 'box' ? 'position: relative' : '']">
                                            <div v-if="!item.colorHidesByKey(dc.key)" v-bind:style="{
                                              position: 'absolute',
                                              top: 0,
                                              left: 0,
                                              right: 0,
                                              bottom: 0,
                                              padding: cellDesign.design == 'box' ? '0px' : '4px',
                                              paddingLeft: '0px',
                                            }">
                                              <div style="height: 100%; width: " v-bind:style="{
                                                width: cellDesign.isColored
                                                  ? cellDesign.percent + '%'
                                                  : '0%',
                                                background: cellDesign.isColored
                                                  ? cellDesign.color
                                                  : '#FFFFFF',
                                                border:
                                                  cellDesign.border != null
                                                    ? cellDesign.border
                                                    : '',
                                              }"></div>
                                            </div>
                                            <div style="position: relative;">
                                              {{ dc.getString() }}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </template>
                                    <td v-if="this.adx.user.nowRole() != 'advertizer'"></td>
                                  </tr>
                                </template>
                                <tr v-if="this.adx.user.nowRole() != 'advertizer'">
                                  <td style="background: white" class="tadcontrol tadcontrol--addrecord"></td>
                                  <td class="mousepointer" :colspan="item.getHeaderCell().length"
                                    v-on:click="onCreateRecord(item, $event)">
                                    <div style="padding: 8px">+New</div>
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="frame__body scroll_bar" style="overflow: auto" v-if="item.type == 3">
                          <div v-if="false">
                            {{ item.structureHolder.structure }}
                            <div>{{ item.getHeaderCell() }}</div>
                            <div>{{ item.getProviderDateCell() }}</div>
                          </div>
                          <table id="summary_table_tag" class="floater_table summary_table expansion_table"
                            style="table-layout: auto; margin-top: 24px;">
                            <thead class="floater_table__header expansion_table__header">
                              <tr>
                                <th style="background: white" class="tadcontrol tadcontrol--type3providerheader"></th>
                                <template v-for="(hitem, hindex) in item.getProviderDateCell()" :key="hindex">
                                  <th style="cursor: pointer; position: relative" ref="summary_header_ref"
                                    :id="'summary_header_id_' + hindex"
                                    class="floater_table__header_col expansion_table__th" :colspan="hitem.colspan"
                                    :class="[hitem.isHide == true ? 'floater_table_th--hide' : '']" v-on:click="
                                      onSegmentChange(
                                        item,
                                        item.getSegmentIndexByHeaderCellIndex(hindex),
                                        $event
                                      )
                                    ">
                                    <div class="vmarkarea" v-if="(hindex > 0) && (this.adx.user.nowRole() != 'advertizer')" v-on:click.stop="empty()">
                                      <div class="vmarker">
                                        <img v-on:click.stop="item.addProvider()"
                                          class="cmark yubi vrecordaddmark mousepointer"
                                          src="@/assets/icon/add_black_24dp.svg" />
                                        <img v-on:click.stop="type3menu(item, item.getSegmentIndexByHeaderCellIndex(hindex), $event)"
                                          class="cmark yubi vrecordmenumark mousepointer"
                                          src="@/assets/icon/drag_indicator_black_24dp.svg" />
                                      </div>
                                    </div>
                                    <div v-if="false" class="expansion_table__dragmarker">
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18"
                                            viewBox="0 0 16 18" transform="rotate(90)">
                                            <g id="g484" data-name="g484" transform="translate(-8 -123)">
                                              <path id="p530" data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)" fill="#fff" />
                                              <rect id="s_949" data-name="s949" width="16" height="2"
                                                transform="translate(8 131)" fill="#fff" />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="floater_table__header_col_wrapper">
                                      <div class="floater_table__header_col_text"
                                        v-if="hitem.getString('consultant')?.length > 0">
                                        {{ hitem.getString('consultant') }}
                                      </div>
                                      <div class="floater_table__header_col_text" v-if="
                                        hitem.getString('consultant') == null ||
                                        hitem.getString('consultant').length == 0
                                      ">
                                        -
                                      </div>
                                    </div>
                                  </th>
                                </template>
                                <th v-if="this.adx.user.nowRole() != 'advertizer'" class="mousepointer" style="padding: 8px;" v-on:click="item.addProvider()">+NEW</th>
                              </tr>
                              <tr v-if="isCells2SpanCells(item.getHeaderCell()) && false">
                                <th></th>
                                <template v-for="(hitem, i1) in cells2SpanCells(item.getHeaderCell(), '')" :key="i1">
                                  <th style="background: white; border: initial"
                                    v-if="hitem.spanCount() == 1 && !is2SpanCell(hitem)"></th>
                                  <th v-if="hitem.spanCount() == 1 && is2SpanCell(hitem)">
                                    {{ hitem.getTitle() }}
                                  </th>
                                  <th v-if="hitem.spanCount() > 1" :colspan="hitem.spanCount()">
                                    {{ hitem.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <th style="background: white" class="tadcontrol tadcontrol--titleheader"></th>
                                <template v-for="(hitem, index) in item.getHeaderCell()" :key="index">
                                  <th style="cursor: pointer; position: relative" ref="summary_header_ref"
                                    v-on:click.stop="showShowHide($event, index - 1, item, hitem.key)"
                                    :id="'summary_header_id_' + index"
                                    class="floater_table__header_col expansion_table__th">
                                    <div v-if="true" class="expansion_table__dragmarker" v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart(item, index, $event)">
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18"
                                            viewBox="0 0 16 18" transform="rotate(90)">
                                            <g id="g484" data-name="g484" transform="translate(-8 -123)">
                                              <path id="p530" data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)" fill="#fff" />
                                              <rect id="s_949" data-name="s949" width="16" height="2"
                                                transform="translate(8 131)" fill="#fff" />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="floater_table__header_col_wrapper" :style="{
                                      width: `${item.getSizeByIndex(index)}px`,
                                    }">
                                      <div class="floater_table__header_col_text">
                                        {{ hitem.getString('consultant') }}
                                      </div>
                                    </div>
                                  </th>
                                </template>
                                <th v-if="this.adx.user.nowRole() != 'advertizer'"></th>
                              </tr>
                            </thead>

                            <tbody class="floater_table__body" :set="targetRecords = item.getRecords()">
                              <template v-for="(record, pindex) in targetRecords" :key="pindex">
                                <tr>
                                  <td style="background: white" class="tadcontrol tadcontrol--data">
                                    <div class="tadcontrol_box" v-if="this.adx.user.nowRole() != 'advertizer'">
                                      <img v-on:click="insertRecord(item, 3, record, pindex)"
                                        class="cmark yubi recordaddmark mousepointer"
                                        src="@/assets/icon/add_black_24dp.svg" />
                                      <img v-on:click="selectItem(item, pindex, $event)"
                                        class="cmark yubi recordmenumark mousepointer"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg" />
                                    </div>
                                  </td>
                                  <template v-for="(dc, rindex) in record" :key="rindex">
                                    <td class="mousepointer" v-if="rindex == 0"
                                      v-on:click="onTimeChange(item, pindex, $event)">
                                      <div class="floater_table__body_col_wrapper" :style="{
                                        width: `${item.getSizeByIndex(rindex)}px`,
                                      }">
                                        <div class="floater_table__body_col_text">
                                          {{ ymdformat(dc.getString()) }}
                                        </div>
                                      </div>
                                    </td>
                                    <td v-if="rindex != 0">
                                      <div class="floater_table__body_col_wrapper" style="text-align: right"
                                        :style="{ width: `${item.getSizeByIndex(rindex)}px` }"
                                        :set="(cellDesign = page.getProviderTableCellDesignByAll(dc.data, item.raw, rindex - 1, item, targetRecords, pindex, rindex))">
                                        <div class="floater_table__body_col_text"
                                          style="text-align: right; position: relative">
                                          <div v-if="!item.colorHidesByKey(dc.key)" v-bind:style="{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            padding: cellDesign.design == 'box' ? '0px' : '4px',
                                            paddingLeft: '0px',
                                          }">
                                            <div style="height: 100%; width: " v-bind:style="{
                                              width: cellDesign.isColored
                                                ? cellDesign.percent + '%'
                                                : '0%',
                                              background: cellDesign.isColored
                                                ? cellDesign.color
                                                : '#FFFFFF',
                                              border:
                                                cellDesign.border != null
                                                  ? cellDesign.border
                                                  : '',
                                            }"></div>
                                          </div>
                                          <div style="position: relative;">
                                            {{ dc.getString() }}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </template>
                                  <td></td>
                                </tr>
                              </template>
                              <tr v-if="this.adx.user.nowRole() != 'advertizer'">
                                <td style="background: white" class="tadcontrol tadcontrol--addrecord"></td>
                                <td class="mousepointer" :colspan="item.getHeaderCell().length"
                                  v-on:click="onCreateRecord(item, $event)">
                                  <div style="padding: 8px">+New</div>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div class="customreport_rangecalendar" v-if="isShowTimeRange"
              :style="{ top: `${rangePositionY}px`, left: `${rangePositionX}px` }">
              <div class="customreport_rangecalendar__background" v-on:click="onSelectTime()"></div>
              <CustomReportRangeCalendar style="pointer-events: all" class="customreport_rangecalendar__body"
                :crrc="crrc" />
            </div>

            <div class="segmentselector_screen" :style="{
              top: `${segmentSelectorPositionY}px`,
              left: `${segmentSelectorPositionX}px`,
            }" v-if="isShowSegmentSelector">
              <div class="segmentselector__background" v-on:click="onSelectSegment()"></div>
              <div v-if="page.segmentSelector.isloading && false"></div>
              <div id="main_002" class="segmentselector" v-if="!page.segmentSelector.isloading || true">
                <template v-for="(item, index) in page.segmentSelector.segmentItemBoxes" :key="index">
                  <div>
                    <div>
                      <div style="display: flex; font-size: 13px">
                        <template v-for="(sitem, index2) in item.getSegmentItems()" :key="index2">
                          <div class="segmentselector__segment">
                            <div
                              style="position: absolute; top: 0; left: 0; background: rgb(245, 246, 248); width: 100%; height: 70px; z-index: 2;">
                            </div>
                            <div style="z-index: 2; position: relative" class="segmentselector__segment_title">
                              {{ sitem.name }}
                            </div>
                            <div style="z-index: 2; position: relative" class="segmentselector__segment_filter">
                              <div style="position: absolute; top: 10px; left: 4px"><img height="12"
                                  src="@/assets/icon/search_FILL0_wght400_GRAD0_opsz48.svg" /></div>
                              <div class="mousepointer"
                                style="width: 180px; padding-left: 20px; padding-right: 8px; padding-top: 2px; padding-bottom: 2px; background: #dddfe2 0% 0% no-repeat padding-box; border: 1px solid #dddfe2; border-radius: 3px; font-size: 11px; line-height: 150%; text-overflow: ellipsis; overflow: hidden;">
                                <input type="text" v-on:keydown.enter="onFilterKeyDown(item, sitem, $event)"
                                  v-on:blur="onFilterKeyDown(item, sitem, $event)"
                                  style="padding: 0; margin: 0;font-size: 8px; background: rgba(255,255,255,0); font-family: 'Noto Sans JP', sans-serif;" />
                              </div>
                              <div class="mousepointer" style="margin-left: 4px; margin-top: 2px;"
                                v-on:click="showFilter(index, index2)" v-if="false">
                                <img height="14" src="@/assets/icon/menu_FILL0_wght400_GRAD0_opsz48_filter.svg" />
                              </div>
                              <div class="filter_background" v-on:click="resetFilter()" v-if="
                                targetFilter != null &&
                                targetFilter.index == index &&
                                targetFilter.index2 == index2
                              "></div>

                              <div
                                style="position: absolute; top: 50px; width: 344px; background: #fff; padding: 16px; box-shadow: 0px 3px 6px #00000029; border: 1px solid #d7dae2;"
                                v-if="
                                  targetFilter != null &&
                                  targetFilter.index == index &&
                                  targetFilter.index2 == index2
                                ">
                                <template v-for="(item, findex) in targetFilter.fvs" :key="findex">
                                  <div style="display: flex">
                                    <div style="font-size: 13px; padding-top: 5px; padding-right: 8px">
                                      条件
                                    </div>
                                    <input
                                      style="width: 172px; font-size: 12px; background: rgba(200, 200, 200, 0.5);line-height: 150%;"
                                      type="text" placeholder="テキスト" v-model="item.value" />
                                    <div style="font-size: 13px; padding-top: 5px; padding-left: 8px">
                                      を含む
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                            <div class="segmentselector__segment_itembox" v-if="refreshItemBox">
                              <template v-for="(vitem, vindex) in vfilter(
                                sitem.getFilterValues(),
                                index,
                                index2,
                                sitem
                              )" :key="vindex">
                                <div class="segmentselector__segment_item_btn mousepointer" :class="
                                  item.isSelectedByTNV(sitem.type, sitem.name, vitem) == true
                                    ? 'segmentselector__segment_item_btn--selected'
                                    : ''
                                " v-on:click="item.select(sitem.type, sitem.name, vitem)">
                                  {{ vitem }}
                                </div>
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="mousepointer" v-on:click="page.segmentSelector.addSegmentItemBox()"
                  style="text-align: center; padding: 8px 0; border-bottom: 1px solid #ccc; font-size: 13px;">
                  +AND
                </div>
              </div>

              <div class="segmentselector" v-if="false">
                <template v-for="(sg, gindex) in page.segmentSelector.segmentGroups" :key="gindex">
                  <div class="segmentselector__segmentitem">
                    <template v-for="(item, index) in sg" :key="index">
                      {{ item }}
                    </template>
                  </div>
                </template>

                <div class="segmentselector__segmentitem" v-if="false">
                  <template v-for="(item, index) in page.segmentSelector?.segmentMaster.getSegments()" :key="index">
                    <div class="segmentselector__segment">
                      <div class="segmentselector__segment_title">{{ item.name }}</div>
                      <div class="segmentselector__segment_search"></div>
                      <div class="segmentselector__segment_body">
                        <template v-for="(item2, index2) in item.values" :key="index2">
                          <button class="segmentselector__btn">{{ item2 }}</button>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="segmentselector__control">+AND</div>
              </div>
            </div>

            <div v-if="isShowReportMenu" class="report_menu" :style="{
              top: `${reportMenuPositionY}px`,
              left: `${reportMenuPositionX}px`,
            }">
              <div class="report_menu_cover" v-on:click="closeReportMenu()"></div>
              <div class="page_menuitem_menu__box">
                <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="reportDelete()" v-if="this.adx.user.nowRole() != 'advertizer'">
                  <img class="page_menuitem_menu__icon" height="14" src="@/assets/icon/delete_outline_black_24dp.svg" />
                  削除
                </div>
                <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="reportCopy()" v-if="this.adx.user.nowRole() != 'advertizer'">
                  <img class="page_menuitem_menu__icon" height="14"
                    src="@/assets/icon/content_copy_FILL0_wght400_GRAD0_opsz48.svg" />
                  複製
                </div>
                <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="reportDownload()">
                  <img class="page_menuitem_menu__icon" height="14" src="@/assets/icon/save_alt-24px.svg" />
                  ダウンロード
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sub_content_layer" v-if="this.adx.user.nowRole() != 'advertizer'">
          <div class="sub_content_wrapper">
            <div id="lay_right_sidemenu" class="right_sidemenu_layer">
              <div class="side_menu">
                <ul class="side-nav">
                  <li class="side-nav__item_wrapper">
                    <router-link :to="'/consultant/summary'+'?'+makeStatusQuery()">
                      <div class="side-nav__item"><i class="circle_icon"></i>サマリー</div>
                    </router-link>
                    <router-link :to="'/consultant/adprovider'+'?'+makeStatusQuery()">
                      <div class="side-nav__item"><i class="circle_icon"></i>媒体別実績</div>
                    </router-link>

                    <div class="side-nav__item side-nav__item--customreport">
                      <i class="circle_icon"></i>カスタムレポート<button class="side_menu__customreport_add_page"
                        v-on:click="createPage()">
                        +ページ作成
                      </button>
                    </div>
                    <div class="side-nav__item side-nav__item__custompage" v-if="false"
                      style="padding-left: 72px;"
                      :class="[isMenuOrderCustomerAddMode ? 'side-nav__item--movetarget0' : '', page.master?.pageId == getCustomerPage()?.id ? 'side-nav__item--selected' : '']"
                      v-on:mouseover="mouseOverActionAddCustomer($event)" v-on:mouseleave="mouseLeaveActionAddCustomer($event)"
                      v-on:click.stop="cstOpenClose"
                    ><div style="position: relative;"><img class="publicfoldericon" :class="[ getCustomerPage() != null && isCstOpenClose ? 'publicfoldericon--exist' : '']" src="@/assets/icon/chevron_right_FILL0_wght400_GRAD0_opsz48.svg"/>お客様公開用</div></div>
                    <div class="side-nav__item side-nav__item__custompage side-nav__item__nohover mousepointer"
                      :set="(csttarget = getCustomerPage())"
                        v-if="getCustomerPage() != null && isCstOpenClose == true"
                        v-on:mouseover="mouseOverActionRemoveCustomer($event)" v-on:mouseleave="mouseLeaveActionRemoveCustomer($event)"
                        v-on:click.stop="goto(csttarget?.id, csttarget?.title)"
                        v-bind:class="[ page.master?.pageId == getCustomerPage()?.id ? 'side-nav__item--selected': '', (isMenuOrderCustomerRemoveMode == true && isMenuOrderCustomerMode == true) ? 'side-nav__item--movetarget' : '' ]"
                        style="position: relative; padding-left: 80px;"
                        >
                        <div style="position: absolute; top: 12px; left: 10px;" v-if="isMenuOrderCustomerMode">
                          <div style="display: flex;">
                            <img class="cmark yubi" src="@/assets/icon/add_black_24dp.svg"
                              v-on:click.stop="page.createPageByName(csttarget.title)"/>
                            <div v-if="false" class="cmark yumi dmark" v-on:mousedown.stop="longpressPress(csttarget, -1)">
                            </div>

                          </div>
                        </div>
                        <div style="position: absolute; top: 10px; left: 56px;"><img class="page_menuitem_menu__icon" height="12"
                      src="@/assets/icon/cstpublic.svg" /></div>
                        <div class="side-nav__item_cst" style="display: flex; display: flex; width: 100%">
                          <div class="side-nav__item__custompage_title"
                            v-if="!(page.isPageUpdateEdit() && page?.pageTitleEditId == csttarget.id)">
                            {{ csttarget.title }}
                          </div>
                          <div class="side-nav__item__custompage_title"
                            v-if="page.isPageUpdateEdit() && page?.pageTitleEditId == csttarget.id">
                            <!-- <div class="edittitle__background" v-on:click.stop="xx()"></div> -->
                            <Inputer :inputer="inputer" />
                          </div>
                          <div class="side-nav__item__custompage_menuicon mousepointer"
                            v-on:click.stop="showPageMenu(csttarget.id, $event, 1)">
                            ...
                          </div>
                        </div>
                      </div>
                    <div class="side-nav__item side-nav__item__custompage" v-if="page.isPageCreateEdit()" style="padding-left: 54px;">
                      <div class="page_input_cover" v-on:click="page.pageNameEditClose()"></div>
                      <input v-if="false" class="page_input_cover__input" type="text" placeholder="ページ名を入力する"
                        v-model="page.pageexittext" @keydown.enter="page.pageNameEditDone()" />
                      <Inputer :inputer="inputer" />
                    </div>
                    <template v-for="(p, index) in sortedMenuOrder" :key="index">
                      <div class="side-nav__item side-nav__item__custompage side-nav__item__nohover mousepointer"
                        v-if="p.order > 0"
                        v-bind:class="[ page.master?.pageId == p.id ? 'side-nav__item--selected': '', (menuOrderGoal?.id == p.id) ? 'side-nav__item--movetarget' : '' ]"
                        v-on:click.stop="goto(p.id, p.title)" style="position: relative;padding-left: 54px;"
                        v-on:mouseover="mouseOverAction(p, index, $event)" v-on:mouseleave="mouseLeaveAction($event)">
                        <div v-if="hoverTargetIndex == index" style="position: absolute; top: 12px; left: 10px;">
                          <div style="display: flex;">
                            <img class="cmark yubi" src="@/assets/icon/add_black_24dp.svg"
                            v-on:click.stop="page.createPageByName(p.title)" />
                          <div class="cmark yumi dmark" v-on:mousedown.stop="longpressPress(p, index)">

                          </div>

                          </div>
                          <!-- <img class="cmark yubi" src="@/assets/icon/drag_indicator_black_24dp.svg"
                            v-on:mousedown.stop="longpressPress(p, index)" /> -->
                        </div>
                        <div class="side-nav__item_cst" style="display: flex; display: flex; width: 100%">
                          <div class="side-nav__item__custompage_title"
                            v-if="!(page.isPageUpdateEdit() && page?.pageTitleEditId == p.id)">
                            {{ p.title }}
                          </div>
                          <div class="side-nav__item__custompage_title"
                            v-if="page.isPageUpdateEdit() && page?.pageTitleEditId == p.id">
                            <!-- <div class="edittitle__background" v-on:click.stop="xx()"></div> -->
                            <Inputer :inputer="inputer" />
                          </div>
                          <div class="side-nav__item__custompage_menuicon mousepointer"
                            v-on:click.stop="showPageMenu(p.id, $event)">
                            ...
                          </div>
                        </div>
                      </div>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <div class="">
              <div class="page_menuitem_menu" :style="{ top: `${menuPositionY}px`, left: `${menuPositionX}px` }"
                v-if="page.pageMenuId != null">
                <div class="page_menuitem_cover" v-on:click="page.closePageMenu()"></div>

                <div class="page_menuitem_menu__box">
                  <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="page.pageDelete()">
                    <img class="page_menuitem_menu__icon" height="14" src="@/assets/icon/delete_outline_black_24dp.svg" />
                    削除
                  </div>
                  <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="page.pageCopy()" v-if="page.getReportCount()">
                    <img class="page_menuitem_menu__icon" height="14"
                      src="@/assets/icon/content_copy_FILL0_wght400_GRAD0_opsz48.svg" />
                    複製
                  </div>
                  <div class="page_menuitem_menu__title mousepointer" v-if="!page.getReportCount()" style="color: #ccc;">
                    <img class="page_menuitem_menu__icon" height="14" style="filter: invert(85%) sepia(4%) saturate(0%) hue-rotate(80deg) brightness(98%) contrast(91%);"
                      src="@/assets/icon/content_copy_FILL0_wght400_GRAD0_opsz48.svg" />
                    複製
                  </div>
                  <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="pageNameEdit()">
                    <img class="page_menuitem_menu__icon" height="14"
                      src="@/assets/icon/edit_FILL0_wght400_GRAD0_opsz48.svg" />
                    名前変更
                  </div>
                  <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="pagePublic()" v-if="menutargetmode == 0">
                    <img class="page_menuitem_menu__icon" height="12"
                      src="@/assets/icon/cstpublic.svg" />
                    顧客公開
                  </div>
                  <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="pagePrivate()" v-if="menutargetmode == 1">
                    <img class="page_menuitem_menu__icon" height="12"
                      src="@/assets/icon/cstprivate.svg" />
                    顧客公開解除
                  </div>
                  <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="page.pageDownload()" v-if="false">
                    <img class="page_menuitem_menu__icon" height="14" src="@/assets/icon/save_alt-24px.svg" />
                    ダウンロード
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="page.isShowCreateType()" v-on:click="page.selectCreateType()" class="select_create_type__background">
      <div>
        <div class="select_create_type_window">
          <div class="select_create_type_header">
            <div class="select_create_type_header__title">レポート作成</div>
            <div class="select_create_type_header__description">
              ベースとなるレポートの選択をしてください
            </div>
          </div>
          <div class="select_create_type_body">
            <div class="select_type_box" v-on:click.stop="page.selectCreateType(1)">
              <div class="select_type_box__header">Aパターン</div>
              <img width="323" height="158" src="@/assets/icon/c01.png" />
            </div>
            <div class="select_type_box select_type_box--center" v-on:click.stop="page.selectCreateType(2)">
              <div class="select_type_box__header">Bパターン</div>
              <img width="323" height="158" src="@/assets/icon/c02.png" />
            </div>
            <div class="select_type_box" v-on:click.stop="page.selectCreateType(3)">
              <div class="select_type_box__header">Cパターン</div>
              <img width="323" height="158" src="@/assets/icon/c03.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="overlay" v-if="targetShowHide != null">
      <div class="showhide__background" v-on:click="closeShowHide()"></div>
      <div class="move" :style="{ top: `${showhidePositionY}px`, left: `${showhidePositionX}px` }">
        <div class="listitem">
          <div class="listitem__item" v-if="true" v-on:click.stop="setShowHideHide(targetShowHideKey)">
            <div style="display: flex">
              <div style="margin-top: -2px">
                <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
                  <path
                    d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z" />
                </svg>
              </div>
              <div style="margin-left: 4px">項目を非表示にする</div>
            </div>
          </div>
          <div class="listitem__item" v-on:click.stop="showhideedit(1)">
            <div style="display: flex">
              <div style="margin-top: -2px">
                <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
                  <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
                </svg>
              </div>
              <div style="margin-left: 4px">表示項目の編集</div>
            </div>
          </div>

          <div class="listitem__item" v-if="!targetShowHide.isSegment(targetShowHideKey)"
            v-on:click.stop="targetShowHide.toggleColorHide(targetShowHideKey);">
            <div style="display: flex">
              <div style="margin-top: -2px">
                <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
                  <path v-if="isShowHideByKey(targetShowHideKey)" fill="#ccc" stroke="#ccc"
                    d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z" />
                  <path v-if="!isShowHideByKey(targetShowHideKey)"
                    d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z" />
                </svg>
              </div>
              <div style="margin-left: 4px" v-if="isShowHideByKey(targetShowHideKey)">着色を表示する</div>
              <div style="margin-left: 4px" v-if="!isShowHideByKey(targetShowHideKey)">着色を非表示にする</div>
            </div>
          </div>
          <div class="listitem__item" v-if="!targetShowHide.isSegment(targetShowHideKey)"
            v-on:click.stop="showhideedit(2)">
            <div style="display: flex">
              <div style="margin-top: -2px">
                <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
                  <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
                </svg>
              </div>
              <div style="margin-left: 4px">着色項目の編集</div>
            </div>
          </div>
        </div>
        <div class="allitems" v-if="isShowshowhidelist > 0" :style="{
          top: `${showhidelist.y}px`,
          left: `${showhidelist.x}px`,
        }">
          <div class="allitems__item" style="position: relative" v-on:click.stop="showhideallreset">
            <div style="position: absolute; top: 6px">
              <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
                <path
                  d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z" />
              </svg>
            </div>

            All
          </div>

          <template v-for="(item, index) in showItemList(targetShowHide, isShowshowhidelist, targetShowHideKey)"
            :key="index">
            <div v-if="item.label" class="allitems__item" style="position: relative"
              v-bind:class="[!showhidelistselected(item, targetShowHide) ? 'allitems__item--selected' : '', is_longname(item.label) ? 'allitems__item--longname' : '']"
              v-on:click.stop="showhidelisttoggle(item.key)">
              <div style="position: absolute; top: 8px; left: 6px;">
                <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
                  <path v-if="!showhidelistselected(item, targetShowHide)"
                    d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z" />
                  <path v-if="showhidelistselected(item, targetShowHide)" fill="#ccc" stroke="#ccc"
                    d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z" />
                </svg>
              </div>
              {{ item.label }}
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="overlay" v-if="selectedItem != null && selectedItem.selectedIndex >= 0">
      <div class="trmenu_background" v-on:click="cancelSelectedItem()"></div>
      <div v-if="true" class="trmenu" :style="{
        top: `${showMenu.y}px`,
        left: `${showMenu.x}px`,
      }">
        <div class="trmenu_btn" v-on:click="selectedItem.deleteRecord(selectedItem.selectedIndex)">
          <img class="trmenu_btn_icon" height="14" src="@/assets/icon/delete_outline_black_24dp.svg" />
          削除
        </div>
        <div class="trmenu_btn" v-on:click="selectedItem.copyRecord(selectedItem.selectedIndex)"
          v-if="selectedItem.type != 2">
          <img class="trmenu_btn_icon" height="14" src="@/assets/icon/content_copy_FILL0_wght400_GRAD0_opsz48.svg" />
          複製
        </div>
        <div class="trmenu_btn" v-on:click="aaa(selectedItem, selectedItem.selectedIndex, 1)"
          v-if="selectedItem.type != 2">
          <img class="trmenu_btn_icon" height="14" src="@/assets/icon/calendar_month_FILL0_wght400_GRAD0_opsz48.svg" />
          月別
        </div>
        <div class="trmenu_btn" v-on:click="aaa(selectedItem, selectedItem.selectedIndex, 2)"
          v-if="selectedItem.type != 2">
          <img class="trmenu_btn_icon" height="14"
            src="@/assets/icon/calendar_view_week_FILL0_wght400_GRAD0_opsz48.svg" />
          週別
        </div>
        <div class="trmenu_btn" v-on:click="aaa(selectedItem, selectedItem.selectedIndex, 3)"
          v-if="selectedItem.type != 2">
          <img class="trmenu_btn_icon" height="14" src="@/assets/icon/calendar_view_day_FILL0_wght400_GRAD0_opsz48.svg" />
          日別
        </div>
      </div>


    </div>
    <div class="overlay" v-if="selectedItem != null && selectedItem.selectedVIndex >= 0">
      <div class="trmenu_background" v-on:click="cancelSelectedItem()"></div>
      <div v-if="true" class="trmenu" :style="{
        top: `${showMenu.y}px`,
        left: `${showMenu.x}px`,
      }">
        <div class="trmenu_btn" v-on:click="selectedItem.deleteCol(selectedItem.selectedVIndex)">
          <img class="trmenu_btn_icon" height="14" src="@/assets/icon/delete_outline_black_24dp.svg" />
          削除
        </div>
        <div class="trmenu_btn" v-on:click="selectedItem.copyCol(selectedItem.selectedVIndex)"
          v-if="selectedItem.type != 2 && false">
          <img class="trmenu_btn_icon" height="14" src="@/assets/icon/content_copy_FILL0_wght400_GRAD0_opsz48.svg" />
          複製
        </div>
        <div class="trmenu_btn" v-on:click="bbb(selectedItem, selectedItem.selectedVIndex, 1)"
          v-if="selectedItem.type == 2">
          <img class="trmenu_btn_icon" height="14" src="@/assets/icon/calendar_month_FILL0_wght400_GRAD0_opsz48.svg" />
          月別
        </div>
        <div class="trmenu_btn" v-on:click="bbb(selectedItem, selectedItem.selectedVIndex, 2)"
          v-if="selectedItem.type == 2">
          <img class="trmenu_btn_icon" height="14"
            src="@/assets/icon/calendar_view_week_FILL0_wght400_GRAD0_opsz48.svg" />
          週別
        </div>
        <div class="trmenu_btn" v-on:click="bbb(selectedItem, selectedItem.selectedVIndex, 3)"
          v-if="selectedItem.type == 2">
          <img class="trmenu_btn_icon" height="14" src="@/assets/icon/calendar_view_day_FILL0_wght400_GRAD0_opsz48.svg" />
          日別
        </div>


      </div>


    </div>

  </div>
</template>

<style scoped>

.left_main_content--fit {
  padding-left: 0px;
}

.dmark {
  width: 16px;
  height: 16px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('@/assets/icon/drag_indicator_black_24dp.svg');
}

.side-nav__item--movetarget {
  border-top: solid 2px rgba(52, 183, 254, 0.5);
}
.side-nav__item--movetarget0 {
  background: rgba(52, 183, 254, 0.5);
}

.publicfoldericon {
  position: absolute;
  top: 2px;
  left: -16px;
  width: 16px;
  height: 16px;
}

.publicfoldericon--exist {
  transform: rotate(90deg);
}

</style>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* pointer-events: none; */
  z-index: 1;
}

.move {
  position: absolute;
}

.listitem {
  padding: 8px;
  background: #fff;
  color: #000;
  z-index: 10;
  border-radius: 8px;
  font: normal normal normal 14px/9px Noto Sans JP;
  box-shadow: 0px 4px 10px #00000088;
  width: 150px;
  z-index: 2;
  color: #39434e;
}

.listitem__item {
  padding: 8px;
}

.listitem__item:hover {
  background: #ddd;
  cursor: pointer;
}

.allitems {
  position: absolute;
  margin-top: 16px;
  padding: 8px;
  background: #fff;
  color: #000;
  z-index: 10;
  border-radius: 8px;
  font: normal normal normal 13px/9px Noto Sans JP;
  box-shadow: 0px 4px 10px #00000088;
  width: 610px;
  z-index: 2;
}

.allitems__item {
  padding: 8px;
  margin: 4px;
  width: 140px;
  text-align: center;
  float: left;
  border-radius: 8px;
  border: 0.5px solid #e3e3e3;
  color: #39434e;
  cursor: pointer;
  line-height: 100%;
}

.allitems__item--selected {
  background: #3b86ff;
  color: #fff;
}

.allitems__item:hover {
  background: #ddd;
}

.allitems__item--longname {
  padding-left: 22px;
}
</style>

<style scoped>
.mousepointer {
  cursor: pointer;
}

.mousetextpointer {
  cursor: text;
}

input[type='text'] {
  border: none;
  box-shadow: initial;
  text-align: left;
  font: initial;
  font-size: 18px;
  letter-spacing: 2.16px;
  color: #3f4a56;
}

input[type='text']:focus {
  box-shadow: initial;
  outline: 0;
}
</style>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* pointer-events: none; */
  z-index: 9;
}

.move {
  position: absolute;
  z-index: 2;
}

.listitem {
  padding: 8px;
  background: #fff;
  color: #000;
  z-index: 10;
  border-radius: 8px;
  font: normal normal normal 14px/9px Noto Sans JP;
  box-shadow: 0px 4px 10px #00000088;
  width: 150px;
  z-index: 2;
  color: #39434e;
  font-size: 11px;
}

.listitem__item {
  padding: 8px;
}

.listitem__item:hover {
  background: #ddd;
}

.showhide__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  z-index: 1;
}

.edittitle__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  z-index: 1;
}
</style>

<style scoped>
.expansion_table__dragmarker {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 8px;
  height: 32px;
}

.expansion_table__dragmarker--active {
  background: #2680ebdd;
}

.expansion_table__dragmarker:hover {
  background: #2680ebdd;
}

.expansion_table__dragmarker__arrow {
  display: none;
  position: relative;
  pointer-events: none;
}

.expansion_table__dragmarker__larrow {
  position: absolute;
  top: 6px;
  left: -14px;
}

.expansion_table__dragmarker__rarrow {
  position: absolute;
  top: 6px;
  left: -2px;
}

.expansion_table__dragmarker__lrarrow {
  position: absolute;
  top: 6px;
  left: -4px;
}

.expansion_table__dragmarker:hover .expansion_table__dragmarker__arrow {
  display: block;
}

.expansion_table__dragmarker--active .expansion_table__dragmarker__arrow {
  display: block;
}

.expansion_table__th--drag {
  user-select: none;
}
</style>

<style scoped>
.floater_table__body_col_wrapper {
  overflow: hidden;
}
</style>

<style scoped>
.tadcontrol {
  background: rgba(255, 255, 255, 0);
  border: 1px solid rgba(255, 255, 255, 0);
  border-right: 1px solid #eee;
  padding: 0 !important;
}

.tadcontrol_box {
  width: 32px;
  height: 10px;
  display: flex;
  position: relative;
}

.tadcontrol:hover {
  background: rgba(255, 255, 255);
}

.cmark {
  width: 16px;
}

.vmarkarea {
  position: absolute;top: -24px;left: 0;height: 24px; right: 0;
}

.recordaddmark {
  display: none;
}
.vrecordaddmark {
  display: none;
}

.recordmenumark {
  display: none;
}

.vrecordmenumark {
  display: none;
}

tr:hover .recordaddmark {
  display: block;
}
.vmarkarea:hover .vrecordaddmark {
  display: block;
}

tr:hover .recordmenumark {
  display: block;
}

.vmarkarea:hover .vrecordmenumark {
  display: block;
}

.vmarker {
  display: flex;
}


.trmenu {
  position: absolute;
  top: 0px;
  left: 32px;
  width: 112px;
  background: white;
  padding: 8px;
  box-shadow: 0px 3px 20px #00000029;
}

.trmenu_background {
  position: fixed;
  background: rgba(255, 255, 255, 0);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.trmenu_btn {
  position: relative;
  padding: 8px;
  padding-left: 24px;
  cursor: pointer;
  font: normal normal 300 10px/13px Noto Sans JP;
}

.trmenu_btn:hover {
  background: rgba(200, 200, 200, 0.5);
}

.trmenu_wrapper {
  position: relative;
}

.trmenu_btn_icon {
  position: absolute;
  top: 8px;
  left: 6px;
}

.filter_background {
  position: fixed;
  background: rgba(255, 255, 255, 0);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>

<style scoped>
.segmentselector__segment {
  width: 200px;
  position: relative;
}

.segmentselector__segment_filter {
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
}

.segmentselector__segment_itembox {
  overflow: auto;
  height: 230px;
}

.segmentselector__segment_item_btn {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 11px;
  margin: 0;
  text-align: center;
  margin-top: 8px;
}

.segmentselector__segment_title {
  font-size: 12px;
}

.segmentselector__segment_item_btn--selected {
  background: rgb(215, 231, 254);
}
</style>

<style scoped>
.floater_table {
  max-width: initial;
}

.floater_table__body td {
  padding: 0px;
}

.floater_table__body tr {
  height: initial;
}

.floater_table__body div {
  height: initial;
}

.floater_table__header {
  width: initial;
}

.floater_table__header_col_wrapper {
  overflow: hidden;
}

.floater_table__body_col_wrapper {
  overflow: hidden;
}

.floater_table__header_col_text {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 120%;
  padding: 8px;
}

.floater_table__body_col_text {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 120%;
  padding: 8px;
}

.floater_table__body_col_text--margin {
  margin-top: 8px;
}

.floater_table__header tr {
  height: initial;
}

.floater_table__header_col {
  padding: 8px;
}
</style>

<style scoped>
.segmentselector_screen {
  position: absolute;
}

.report_menu {
  position: absolute;
}

.report_menu_cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000000;
  z-index: 1;
  pointer-events: all;
}

.segmentselector {
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d7dae2;
  margin-bottom: 32px;
  padding: 32px 16px;
  position: relative;
  z-index: 10;
}

.segmentselector__control {
  text-align: center;
  border-bottom: 1px solid #d5d5d5;
}

.segmentselector__segmentitem {
  display: flex;
  border: 1px solid #e3e3e3;
}

.segmentselector__segment {
  width: 200px;
  height: 308px;
  border: 1px solid #e3e3e3;
  padding: 8px 8px;
}

.segmentselector__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  z-index: 1;
}

.segmentselector__segment_body {
  overflow: auto;
  height: 250px;
}

.segmentselector__btn {
  background: white;
  width: 100%;

  border: 0.5px solid #e3e3e3;
  border-radius: 3px;
  font-size: 13px;
  padding: 5px;
  text-align: center;
  margin-top: 8px;
}
</style>

<style scoped>
.customreport_rangecalendar {
  position: absolute;
  top: 200px;
  left: 360px;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9;
}

.customreport_rangecalendar__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  z-index: 1;
}

.customreport_rangecalendar__body {
  position: relative;
  z-index: 2;
}

.expansion_table__th {
  width: initial;
  padding: 0px;
  user-select: none;
}
</style>

<style scoped>
.content_wrapper {
  width: calc(100% - 20px);
}

.frame_header__title {
  border-left: 3px solid #39434e;
  padding-left: 8px;
}

.select_create_type__background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;
}

.select_create_type_window {
  background: #fff;
  padding: 16px 41px;
  padding-bottom: 32px;
  margin: auto;
  width: 1326px;
  margin-top: 320px;
}

.select_create_type_body {
  display: flex;
}

.select_create_type_header__title {
  text-align: center;
}

.select_create_type_header__description {
  text-align: center;
  margin-top: 27px;
  margin-bottom: 27px;
}

.side-nav__item__custompage {
  padding-left: 32px;
  padding-right: 8px;
  word-break: break-all;
  display: flex;
}

.side-nav__item--selected {
  background: #D4D4D4;
  color: #2680EB;
}

.side-nav__item--customreport {
  position: relative;
}

.side_menu__customreport_add_page {
  margin-left: auto;
  position: absolute;
  right: 16px;
  font-size: 8px;
  padding: 3px 10px;
  border-width: 0;
  background: #3b86ff;
  color: #fff;
  font: normal normal normal 6px/9px Noto Sans JP;
  letter-spacing: 0px;
  border: 0.5px solid #3e87ff;
  cursor: pointer;
}

.side-nav__item__custompage_menuicon {
  width: 24px;
}

.side-nav__item__custompage_title {
  margin-right: auto;
}

.createreport_btn {
  font-size: 9px;
  padding: 2px 13px;
  border-width: 0;
  background: #3b86ff;
  color: #fff;
  line-height: 13px;
  font: normal normal normal 9px/13px Noto Sans JP;
  letter-spacing: 0px;
  border-radius: 3px;
}

.select_type_box {
  padding: 25px;
  box-shadow: 0px 3px 6px #00000060;
  border: 0.5px solid #3f4a56;
  border-radius: 17px;
  width: 374px;
}

.select_type_box--center {
  margin-left: 61px;
  margin-right: 61px;
}

.select_type_box:hover {
  background: #eeeeee;
}

.select_type_box__header {
  text-align: center;
  margin-bottom: 16px;
}

.inputer {
  position: relative;
  z-index: 2;
  width: 100%;
}

.page_input_cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff00;
  z-index: 1;
}

.page_menuitem_cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000000;
  z-index: 1;
  pointer-events: all;
}

.page_menuitem_menu {
  position: absolute;
  top: 10px;
  left: 200px;
  z-index: 2;
}

.page_menuitem_menu__box {
  padding: 4px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d7dae2;
  border-radius: 3px;
  width: 112px;
  font: normal normal 300 10px/13px Noto Sans JP;
  pointer-events: all;
  z-index: 10;
  position: relative;
  padding: 8px;
}

.page_menuitem_menu__title {
  padding: 4px 0px;
  padding-left: 20px;
  position: relative
}

.page_menuitem_menu__icon {
  position: absolute;
  top: 4px;
  left: 4px;
}

.page_menuitem_menu__title:hover {
  background: #eeeeee;
}

.floater_table_th--hide {
  background: #39434e00;
  border: 1px solid #cfcfcf00;
}

.side-nav__item--selected2 {
  background: #3b86ff;
  color: #fff;
}

.side-nav__item--selected2:hover {
  color: #3b86ff;
}

.side-nav__item {
  padding-left: 42px;
}

/* .side-nav__item__nohover:hover {
  background-color: #d4d4d4;
} */

.side-nav__item_cst {
  padding: 4px;
}
</style>

<style scoped>
.side_menu {
  padding-left: 0px;
}

.mudai {
  color: #ccc;
}
</style>

<script>
// @ is an alias to /src
import ContentHeader from '@/components/consultant/ContentHeader.vue';
import ScreenBarProgress2 from '@/components/ScreenBarProgress2.vue';
// import BoxSpinner from '@/components/layout/BoxSpinner.vue';

import { CustomReportPage } from '@/js/page/consultant/CustomReportPage.js';

import {
  cells2SpanCells,
  // spanTilte2HeaderTitle,
  // titles2Cell,
  is2SpanCell,
} from '@/js/components/spancell.js';

import Inputer from '@/components/Inputer.vue';
import { InputerJs } from '@/js/components/InputerJs.js';
import { CustomReportRangeCalendarJs } from '@/js/components/CustomReportRangeCalendarJs.js';
import { CustomReportRangeSelector } from '@/js/components/CustomReportRangeCalendarJs.js';

import CustomReportRangeCalendar from '@/components/CustomReportRangeCalendar.vue';
// import { TimeYmdRange } from "@/js/tacklebox/date/TimeYmdRange.js";

//eslint-disable-next-line
function FilterValue(value, type) {
  this.value = value;
  this.type = type;
}

//eslint-disable-next-line
function FilterStock(index, index2, fvs) {
  this.index = index;
  this.index2 = index2;
  this.fvs = fvs;
}

export default {
  name: 'CustomReport',
  data() {
    const self = this;
    const rs = new CustomReportRangeSelector();
    return {
      name: 'カスタムレポート',
      page: new CustomReportPage(),
      rs: rs,
      crrc: new CustomReportRangeCalendarJs(rs, function () {
        //
      }),
      isLoad: false,
      pageId: '',
      inputer: new InputerJs(function (inputtext) {
        if (inputtext.length == 0) {
          inputtext = '-';
        }
        self.page.pageexittext = inputtext;
        self.page.pageNameEditDone();
      }),
      rangePositionY: 0,
      rangePositionX: 0,
      isShowTimeRange: false,
      editReport: null,
      timeEditReport: null,
      segmentSelectorPositionY: 0,
      segmentSelectorPositionX: 0,
      isShowSegmentSelector: false,
      editSegment: null,
      editSegmentIndex: -1,
      menuPositionX: 0,
      menuPositionY: 0,
      reportMenuPositionX: 0,
      reportMenuPositionY: 0,
      isShowReportMenu: false,
      reportMenuEditItem: null,
      isPageMenuMouseHover: false,
      hoverTargetIndex: -1,
      isActive: true,
      filterstocks: [new FilterStock(0, 1, [new FilterValue('', 'and')])],
      targetFilter: null,
      dragstate: 0,
      baseClientX: 0,
      dragTargetItem: null,
      dragTargetIndex: -1,
      dragingSize: 0,
      targetShowHide: null,
      targetShowHideIndex: 0,
      targetShowHideKey: null,
      showhidePositionX: 0,
      showhidePositionY: 0,
      isEditShowHideShowHideList: false,
      isEditShowHideColorHideList: false,
      selectedItem: null,
      showMenu: {
        x: 0,
        y: 0
      },
      refreshItemBox: true,
      targetShowHideReport: null,
      isShowshowhidelist: 0,
      showhidelist: {
        x: 0,
        y: 0
      },
      longpressTimerId: null,
      menuOrderTarget: null,
      menuOrderGoal: null,
      isMenuOrderCustomerAddMode: false,
      isMenuOrderCustomerMode: false,
      isMenuOrderCustomerRemoveMode: false,
      isCstOpenClose: true,
      menutargetmode: 0,
      insertReverse: false,
    };
  },
  emits: ['screenevent'],
  mounted() {
    this.$emit('screenevent', 'customreport');//[CR] close
    const self = this;
    if (this.adx.getScreenStructureById('consultant').getActivePages().length > 0) {
      self.initialize();
    } else {
      this.adx.getScreenStructureById('consultant').subject.addObserver(this.onScreenReady);
    }
    document.addEventListener('mousemove', this.onMouseMoveEvent);
    document.addEventListener('mouseup', this.onMouseUpEvent);
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
    
  },
  watch: {
    $route(to, from) {
      if (to || from) {
        //
      }
      this.isLoad = false;
      this.initialize();//[CR] close
    },
  },
  computed: {
    sortedMenuOrder() {
      if (this.page.pages.length == 0) {
        return [];
      }
      const pages = this.page.pages;
      return pages.sort((a, b) => {
        return a.order - b.order;
      });
    }
  },
  methods: {
    onKeyDown(event) {
      if (event) {
        if (event.altKey) {
          this.insertReverse = true;
        }
      }
    },
    onKeyUp(event) {
      if (event) {
        this.insertReverse = false;
      }
    },
    insertRecord(item, type, record, pindex){
      item.insertRecord(type, record, pindex, this.insertReverse);
    },
    makeStatusQuery() {
      let timeQuery = "";
      let dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
      if (dateRangeSelector) {
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      }
      return 'advertizer_id=' + this.adx.getScreenStructureById('consultant').advertizerId + timeQuery;
    },
    pagePrivate() {
      this.page.pagePrivate();
      this.page.closePageMenu();
    },
    pagePublic() {
      this.page.pagePublic();
      this.page.closePageMenu();
    },
    cstOpenClose() {
      this.isCstOpenClose = !this.isCstOpenClose;
    },
    getCustomerPage() {
      for (let i in this.page.pages) {
        if (this.page.pages[i].order < 0) {
          return this.page.pages[i];
        }
      }
      return null;
    },
    //eslint-disable-next-line
    menuDragStart(item, idx) {
      if (idx) {
        //
      }

      this.menuOrderTarget = item;
      this.menuOrderGoal = item;
    },
    longpressPress(item, idx) {
      if (idx < 0) {
        // this.isMenuOrderCustomerRemoveMode = true;
        return;
      }
      let self = this;
      let cnt = 0;
      let lptid = setInterval(function () {
        if (lptid != self.longpressTimerId) {
          if (lptid) {
            clearInterval(lptid);
          }
          return;
        }
        cnt++;
        if (cnt > 2) {
          self.longressRelease();
          self.menuDragStart(item, idx);
        }
      }, 100 * 2);

      this.longpressTimerId = lptid;
    },
    longressRelease() {
      if (this.longpressTimerId) {
        try {
          clearInterval(this.longpressTimerId);
        } catch (ex) {
          console.log(ex);
        }
      }
      this.longpressTimerId = null;
    },
    is_longname(title) {
      if (title.indexOf("_") > 0) {
        return true;
      }

      return false;
    },
    empty() {
      //
    },
    type3add() {
      //
    },
    type3menu(report, number, event) {
      this.selectVItem(report, number, event)
    },
    showItemList(target, isShowshowhidelist, key) {
      let type = 0;
      let t = target.isSegment(key);
      if (t) {
        type = 1;
      }

      return target.getShowHideItems(isShowshowhidelist, type);
    },
    pageNameEdit() {
      let title = this.page.pageNameEdit()
      this.inputer.text = title;
      this.page.closePageMenu();
    },
    setShowHideHide(key) {
      if (this.targetShowHide) {
        this.targetShowHide.setShowHideHide(key);
      }
      this.closeShowHide();
    },
    showhidelisttoggle(key) {
      if (this.isShowshowhidelist == 1) {
        this.targetShowHide.toggleShowHide(key);
      }
      if (this.isShowshowhidelist == 2) {
        this.targetShowHide.toggleColorHide(key);
      }
    },
    showhidelistselected(item, list) {
      if (this.isShowshowhidelist == 1) {
        for (let i in list.showHides) {
          if (item.key == list.showHides[i].key) {
            return list.showHides[i].isHide;
          }
        }
        for (let i in list.showHideSegs) {
          if (item.key == list.showHideSegs[i].key) {
            return list.showHideSegs[i].isHide;
          }
        }
      }
      if (this.isShowshowhidelist == 2) {
        for (let i in list.colorHides) {
          if (item.key == list.colorHides[i].key) {
            return list.colorHides[i].isHide;
          }

        }
      }
      return false;
    },
    showhideallreset() {
      if (this.isShowshowhidelist == 1) {
        let type = 0;
        if (this.targetShowHide.isSegment(this.targetShowHideKey)) {
          type = 1;
        } else {
          type = 2;
        }
        this.targetShowHide.resetShowHide(type);
      }
      if (this.isShowshowhidelist == 2) {
        this.targetShowHide.resetColorHide();
      }
    },
    showhideedit(type) {
      this.isShowshowhidelist = type;
      if (type == 1) {
        this.showhidelist.y = 35;
      }
      if (type == 2) {
        this.showhidelist.y = 90;
      }
    },
    onFilterKeyDown(item, sitem, event) {
      const self = this;
      item.setFilter(sitem.type, sitem.name, event.target.value);
      self.refreshItemBox = false;
      self.$nextTick(() => (self.refreshItemBox = true));
    },
    selectItem(report, number, event) {
      this.selectedItem = report;
      this.selectedItem.selectIndex(number);
      this.showMenu.x = event.pageX;
      this.showMenu.y = event.pageY;
    },
    selectVItem(report, number, event) {
      this.selectedItem = report;
      this.selectedItem.selectVIndex(number);
      this.showMenu.x = event.pageX;
      this.showMenu.y = event.pageY;
    },
    cancelSelectedItem() {
      this.selectedItem.cancelselectmenu();
      this.selectedItem = null;
    },
    aaa(item, index, ymdMode = 1) {
      let startYmd = [];
      let endYmd = [];

      let se = item.raw[index].date.split('~');
      startYmd = se[0].split('-');
      endYmd = se[1].split('-');

      if (startYmd.length > 2 && endYmd.length > 2) {
        let startDateEndDates = [];
        let startDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
        let endDt = new Date(endYmd[0], endYmd[1], endYmd[2]);
        let targetDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
        let tmpTargetDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), 0);
        startDt.setMonth(startDt.getMonth() - 1);
        endDt.setMonth(endDt.getMonth() - 1);
        if (ymdMode == 1) {
          startDt = new Date(startYmd[0], startYmd[1], 1);
          endDt = new Date(endYmd[0], endYmd[1], 1);
          targetDt = new Date(endDt[0], endDt[1], 1);
          startDt.setMonth(startDt.getMonth() - 1);
          endDt.setMonth(endDt.getMonth() - 1);
          targetDt = new Date(startDt.getFullYear(), startDt.getMonth(), startDt.getDate());

          if (startDt.getTime() == endDt.getTime()) {
            tmpTargetDt = new Date(endDt.getFullYear(), endDt.getMonth() + 1, 0); //月末
            startDateEndDates.push({
              start: startDt.getFullYear() + '-' + (startDt.getMonth() + 1) + '-' + 1,
              end:
                tmpTargetDt.getFullYear() +
                '-' +
                (tmpTargetDt.getMonth() + 1) +
                '-' +
                tmpTargetDt.getDate(),
            });
          } else {
            tmpTargetDt = new Date(startDt.getFullYear(), startDt.getMonth() + 1, 0); //月末
            startDateEndDates.push({
              start: startDt.getFullYear() + '-' + (startDt.getMonth() + 1) + '-' + 1,
              end:
                tmpTargetDt.getFullYear() +
                '-' +
                (tmpTargetDt.getMonth() + 1) +
                '-' +
                tmpTargetDt.getDate(),
            });

            for (let i = 0; i < 12 * 5; i++) {
              targetDt.setMonth(targetDt.getMonth() + 1);
              tmpTargetDt = new Date(targetDt.getFullYear(), targetDt.getMonth() + 1, 0); //月末
              startDateEndDates.push({
                start: targetDt.getFullYear() + '-' + (targetDt.getMonth() + 1) + '-' + 1,
                end:
                  tmpTargetDt.getFullYear() +
                  '-' +
                  (tmpTargetDt.getMonth() + 1) +
                  '-' +
                  tmpTargetDt.getDate(),
              });
              if (targetDt.getTime() == endDt.getTime()) {
                break;
              }
            }
          }
        } else if (ymdMode == 2) {
          targetDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
          targetDt.setMonth(targetDt.getMonth() - 1);
          endDt = new Date(endYmd[0], endYmd[1], endYmd[2]);
          endDt.setMonth(endDt.getMonth() - 1);
          // let stockDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate());
          for (let j = 0; j < 10; j++) {
            let stockDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate() + (7 * j));
            let stockDt2 = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate() + (7 * j) + 6);
            if (stockDt.getTime() > endDt.getTime()) {
              break;
            }
            startDateEndDates.push(
              {
                start:
                  stockDt.getFullYear() +
                  '-' +
                  (stockDt.getMonth() + 1) +
                  '-' +
                  stockDt.getDate(),
                end:
                  stockDt2.getFullYear() +
                  '-' +
                  (stockDt2.getMonth() + 1) +
                  '-' +
                  stockDt2.getDate(),
              }
            );
          }
          // for (let i = 0; i < 31 * 12 * 5; i++) {
          //   if (targetDt.getDay() == 0) {
          //     if (stockDt) {
          //       startDateEndDates.push({
          //         start:
          //           stockDt.getFullYear() +
          //           '-' +
          //           (stockDt.getMonth() + 1) +
          //           '-' +
          //           stockDt.getDate(),
          //         end:
          //           targetDt.getFullYear() +
          //           '-' +
          //           (targetDt.getMonth() + 1) +
          //           '-' +
          //           targetDt.getDate(),
          //       });
          //     }
          //   }
          //   if (targetDt.getDay() == 1) {
          //     stockDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate());
          //   }

          //   if (targetDt.getTime() == endDt.getTime()) {
          //     if (targetDt.getDay() != 0) {
          //       startDateEndDates.push({
          //         start:
          //           stockDt.getFullYear() +
          //           '-' +
          //           (stockDt.getMonth() + 1) +
          //           '-' +
          //           stockDt.getDate(),
          //         end:
          //           targetDt.getFullYear() +
          //           '-' +
          //           (targetDt.getMonth() + 1) +
          //           '-' +
          //           targetDt.getDate(),
          //       });
          //     }

          //     break;
          //   }
          //   targetDt.setDate(targetDt.getDate() + 1);
          // }
        } else if (ymdMode == 3) {
          targetDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
          targetDt.setMonth(targetDt.getMonth() - 1);
          endDt = new Date(endYmd[0], endYmd[1], endYmd[2]);
          endDt.setMonth(endDt.getMonth() - 1);
          for (let i = 0; i < 31 * 12 * 5; i++) {
            startDateEndDates.push({
              start:
                targetDt.getFullYear() + '-' + (targetDt.getMonth() + 1) + '-' + targetDt.getDate(),
              end:
                targetDt.getFullYear() + '-' + (targetDt.getMonth() + 1) + '-' + targetDt.getDate(),
            });

            if (targetDt.getTime() == endDt.getTime()) {
              break;
            }
            targetDt.setDate(targetDt.getDate() + 1);
          }
        }
        item.createRecordFromDate(index, startDateEndDates);
        item.cancelselectmenu();
        this.selectedItem = null;
      }
    },
    ccc(item, index, ymdMode = 1) {
      if (ymdMode) {
        //
      }
      let startYmd = [];
      let endYmd = [];

      let se = item?.raw[0]?.periodsList[index]?.date?.split('~');
      startYmd = se[0].split('-');
      endYmd = se[1].split('-');

      if (startYmd || endYmd) {
        //
      }
    },
    bbb(item, index, ymdMode = 1) {
      let startYmd = [];
      let endYmd = [];

      let se = item?.raw[0]?.periodsList[index]?.date?.split('~');
      startYmd = se[0].split('-');
      endYmd = se[1].split('-');

      if (startYmd.length > 2 && endYmd.length > 2) {
        let startDateEndDates = [];
        let startDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
        let endDt = new Date(endYmd[0], endYmd[1], endYmd[2]);
        let targetDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
        let tmpTargetDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), 0);
        startDt.setMonth(startDt.getMonth() - 1);
        endDt.setMonth(endDt.getMonth() - 1);
        if (ymdMode == 1) {
          startDt = new Date(startYmd[0], startYmd[1], 1);
          endDt = new Date(endYmd[0], endYmd[1], 1);
          targetDt = new Date(endDt[0], endDt[1], 1);
          startDt.setMonth(startDt.getMonth() - 1);
          endDt.setMonth(endDt.getMonth() - 1);
          targetDt = new Date(startDt.getFullYear(), startDt.getMonth(), startDt.getDate());

          if (startDt.getTime() == endDt.getTime()) {
            tmpTargetDt = new Date(endDt.getFullYear(), endDt.getMonth() + 1, 0); //月末
            startDateEndDates.push({
              start: startDt.getFullYear() + '-' + (startDt.getMonth() + 1) + '-' + 1,
              end:
                tmpTargetDt.getFullYear() +
                '-' +
                (tmpTargetDt.getMonth() + 1) +
                '-' +
                tmpTargetDt.getDate(),
            });
          } else {
            tmpTargetDt = new Date(startDt.getFullYear(), startDt.getMonth() + 1, 0); //月末
            startDateEndDates.push({
              start: startDt.getFullYear() + '-' + (startDt.getMonth() + 1) + '-' + 1,
              end:
                tmpTargetDt.getFullYear() +
                '-' +
                (tmpTargetDt.getMonth() + 1) +
                '-' +
                tmpTargetDt.getDate(),
            });

            for (let i = 0; i < 12 * 5; i++) {
              targetDt.setMonth(targetDt.getMonth() + 1);
              tmpTargetDt = new Date(targetDt.getFullYear(), targetDt.getMonth() + 1, 0); //月末
              startDateEndDates.push({
                start: targetDt.getFullYear() + '-' + (targetDt.getMonth() + 1) + '-' + 1,
                end:
                  tmpTargetDt.getFullYear() +
                  '-' +
                  (tmpTargetDt.getMonth() + 1) +
                  '-' +
                  tmpTargetDt.getDate(),
              });
              if (targetDt.getTime() == endDt.getTime()) {
                break;
              }
            }
          }
        } else if (ymdMode == 2) {
          targetDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
          targetDt.setMonth(targetDt.getMonth() - 1);
          endDt = new Date(endYmd[0], endYmd[1], endYmd[2]);
          endDt.setMonth(endDt.getMonth() - 1);
          // let stockDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate());
          for (let j = 0; j < 10; j++) {
            let stockDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate() + (7 * j));
            let stockDt2 = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate() + (7 * j) + 6);
            if (stockDt.getTime() > endDt.getTime()) {
              break;
            }
            startDateEndDates.push(
              {
                start:
                  stockDt.getFullYear() +
                  '-' +
                  (stockDt.getMonth() + 1) +
                  '-' +
                  stockDt.getDate(),
                end:
                  stockDt2.getFullYear() +
                  '-' +
                  (stockDt2.getMonth() + 1) +
                  '-' +
                  stockDt2.getDate(),
              }
            );
          }
          // for (let i = 0; i < 31 * 12 * 5; i++) {
          //   if (targetDt.getDay() == 0) {
          //     if (stockDt) {
          //       startDateEndDates.push({
          //         start:
          //           stockDt.getFullYear() +
          //           '-' +
          //           (stockDt.getMonth() + 1) +
          //           '-' +
          //           stockDt.getDate(),
          //         end:
          //           targetDt.getFullYear() +
          //           '-' +
          //           (targetDt.getMonth() + 1) +
          //           '-' +
          //           targetDt.getDate(),
          //       });
          //     }
          //   }
          //   if (targetDt.getDay() == 1) {
          //     stockDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate());
          //   }

          //   if (targetDt.getTime() == endDt.getTime()) {
          //     if (targetDt.getDay() != 0) {
          //       startDateEndDates.push({
          //         start:
          //           stockDt.getFullYear() +
          //           '-' +
          //           (stockDt.getMonth() + 1) +
          //           '-' +
          //           stockDt.getDate(),
          //         end:
          //           targetDt.getFullYear() +
          //           '-' +
          //           (targetDt.getMonth() + 1) +
          //           '-' +
          //           targetDt.getDate(),
          //       });
          //     }

          //     break;
          //   }
          //   targetDt.setDate(targetDt.getDate() + 1);
          // }
        } else if (ymdMode == 3) {
          targetDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
          targetDt.setMonth(targetDt.getMonth() - 1);
          endDt = new Date(endYmd[0], endYmd[1], endYmd[2]);
          endDt.setMonth(endDt.getMonth() - 1);
          for (let i = 0; i < 31 * 12 * 5; i++) {
            startDateEndDates.push({
              start:
                targetDt.getFullYear() + '-' + (targetDt.getMonth() + 1) + '-' + targetDt.getDate(),
              end:
                targetDt.getFullYear() + '-' + (targetDt.getMonth() + 1) + '-' + targetDt.getDate(),
            });

            if (targetDt.getTime() == endDt.getTime()) {
              break;
            }
            targetDt.setDate(targetDt.getDate() + 1);
          }
        }
        item.createRecordFromDate(index, startDateEndDates);
        item.cancelselectmenu();
        this.selectedItem = null;
      }
    },
    xx() {
      //
    },
    createPage() {
      this.inputer.text = '';
      this.page.createPage();
    },
    titleFocus() {
      document.getElementById('title_input_id').focus();
    },
    edittitle(item) {
      if (this.adx.user.nowRole() == 'advertizer') {
        return;
      }
      item.enterEditTitle();
      const self = this;
      setTimeout(function () {
        self.titleFocus();
      }, 10);
    },
    showHideshowHide() {
      this.isShowshowhidelist = 0;
      this.targetShowHide.setShowHidesByIndex(this.targetShowHideIndex);
      this.targetShowHide = null;
      this.targetShowHideIndex = -1;
    },
    showHidecolorHide() {
      this.isShowshowhidelist = 0;
      this.targetShowHide.setColorHidesByIndex(this.targetShowHideIndex, true);
      this.targetShowHide = null;
      this.targetShowHideIndex = -1;
    },
    isColorShowByKey(key, item) {
      if (key || item) {
        //
      }
    },
    closeShowHide() {
      this.isShowshowhidelist = 0;
      this.targetShowHide = null;
    },
    isShowHideByKey(key) {
      for (let i in this.targetShowHide.colorHides) {
        let sh = this.targetShowHide.colorHides[i];
        if (key == sh.key) {
          return sh.isHide;
        }
      }
      return false;
    },
    showShowHide(e, index, item, key) {
      if (item.type == 1 || item.type == 3) {
        if (index < 0) {
          return;
        }
      }
      this.showhidePositionY = e.pageY;
      this.showhidePositionX = e.pageX;
      this.showhidelist.x = 80;
      if (window.innerWidth < e.x + 140) {
        this.showhidePositionX -= 150;
      }
      if (window.innerHeight < e.y + 130) {
        this.showhidePositionY -= 130;
      }

      if (window.innerWidth < e.x + 600) {
        if (this.showhidePositionX > 600) {
          this.showhidelist.x = -590;
        }
      }

      this.targetShowHide = item;
      this.targetShowHideIndex = index;
      this.targetShowHideKey = key;
    },
    showShowHideByKey(e, item, key) {
      if (item.type == 1) {
        if (key == "" || key == null || key == undefined) {
          return;
        }
      }
      this.showhidePositionY = e.pageY;
      this.showhidePositionX = e.pageX;
      this.showhidelist.x = 80;
      if (window.innerWidth < e.x + 140) {
        this.showhidePositionX -= 150;
      } else {
        //
      }

      if (window.innerHeight < e.y + 130) {
        if (item.isSegment(key)) {
          this.showhidePositionY -= 75;
        } else {
          this.showhidePositionY -= 130;
        }
      }

      if (window.innerWidth < e.x + 600) {
        if (this.showhidePositionX > 600) {
          this.showhidelist.x = -590;
        }
      }

      this.targetShowHide = item;
      this.targetShowHideKey = key;
    },
    resetFilter() {
      this.targetFilter = null;
    },
    showFilter(index, index2) {
      for (let i in this.filterstocks) {
        let fs = this.filterstocks[i];
        if (fs.index == index && fs.index2 == index2) {
          this.targetFilter = fs;
          return;
        }
      }
      this.targetFilter = new FilterStock(index, index2, [new FilterValue('', '')]);
      this.filterstocks.push(this.targetFilter);
    },
    vfiltername(index, index2) {
      let targetFs = null;
      for (let i in this.filterstocks) {
        let fs = this.filterstocks[i];
        if (fs.index == index && fs.index2 == index2) {
          targetFs = fs;
        }
      }

      if (targetFs) {
        let name = '';
        for (let i in targetFs.fvs) {
          let fv = targetFs.fvs[i];
          name += fv.value;
        }
        return name;
      }
      return '　';
    },
    vfilter(vs, index, index2, item) {
      let targetFs = null;
      for (let i in this.filterstocks) {
        let fs = this.filterstocks[i];
        if (fs.index == index && fs.index2 == index2) {
          targetFs = fs;
        }
      }

      let fvs = [];
      if (targetFs) {
        for (let i in vs) {
          let v = vs[i];
          for (let j in targetFs.fvs) {
            let fv = targetFs.fvs[j];
            if (v.indexOf(fv.value) >= 0) {

              if (v.indexOf(item.filterv) >= 0) {
                fvs.push(v);

              }
              break;
            }
          }
        }
      } else {
        fvs = vs;
      }

      return fvs;
    },
    onMouseUpEvent(/*event*/) {
      if (this.isMenuOrderCustomerRemoveMode == true && this.menuOrderGoal == null) {
        for (let i in this.page.pages) {
          if (this.page.pages[i].order < 0) {
            this.page.pages[i].order = 1;
          }
        }
        this.page.pageReorder();

        this.menuOrderTarget = null;
        this.menuOrderGoal = null;
        this.isMenuOrderCustomerAddMode = false;
        this.isMenuOrderCustomerRemoveMode = false;

        return;
      }
      if (this.menuOrderTarget && ((this.isMenuOrderCustomerAddMode == false && this.menuOrderGoal) || this.isMenuOrderCustomerAddMode == true || (this.isMenuOrderCustomerRemoveMode == true && this.menuOrderGoal))) {

        if(this.isMenuOrderCustomerAddMode) {
          //
          for (let i in this.page.pages) {
            if (this.page.pages[i].order < 0) {
              this.page.pages[i].order = 1;
            }
          }
          this.menuOrderTarget.order = -1;

        }
        if (this.isMenuOrderCustomerRemoveMode) {
          this.menuOrderTarget.order = this.menuOrderGoal.order;
        }

        const pages = this.page.pages;
        pages.sort((a, b) => {
          return a.order - b.order;
        });

        let cnt = 0;
        for (let i = 0 ; i < pages.length ; i++) {
          let p = pages[i];
          if (p.order > 0) {
            cnt++;
            if (this.isMenuOrderCustomerAddMode == false && p.id == this.menuOrderTarget.id) {
              for (let j in this.page.pages) {
                if (this.page.pages[j].id == p.id) {
                  if (this.menuOrderGoal.order == 1) {
                    this.page.pages[j].order = 1;
                  }else{
                    this.page.pages[j].order = this.menuOrderGoal.order - 1;
                  }
                  break;
                }
              }
              cnt--;
            } else if (this.isMenuOrderCustomerAddMode == false && p.id == this.menuOrderGoal.id) {
              cnt++;
              for (let j in this.page.pages) {
                if (this.page.pages[j].id == p.id) {
                  this.page.pages[j].order = cnt;
                  break;
                }
              }
            } else {
              for (let j in this.page.pages) {
                if (this.page.pages[j].id == p.id) {
                  this.page.pages[j].order = cnt;
                  break;
                }
              }
            }

          }
        }

        this.page.pageReorder();

        this.menuOrderTarget = null;
        this.menuOrderGoal = null;
        this.isMenuOrderCustomerAddMode = false;
        this.isMenuOrderCustomerRemoveMode = false;
        return;
      }
      if (this.dragstate > 0) {
        //TBD
        this.dragTargetItem.dragChangeSizeByIndex(this.dragTargetIndex);
      }
      this.dragstate = 0;
      this.dragTargetItem = null;
      this.dragTargetIndex = -1;
      this.menuOrderTarget = null;
      this.menuOrderGoal = null;
      this.isMenuOrderCustomerAddMode = false;
      this.isMenuOrderCustomerRemoveMode = false;
      this.isMenuOrderCustomerMode = false;
    },
    onMouseMoveEvent(event) {
      if (this.menuOrderTarget) {
        // console.log("onMouseMoveEvent", event);
        return;
      }

      if (this.dragstate > 0) {
        // console.log('onMouseMoveEvent', event);
        this.dragstate = 2;
        // this.exptarget.style.width = this.baseWidth + event.clientX - this.baseClientX + 'px';
        if (this.dragTargetItem) {
          this.dragingSize = (this.baseClientX - event.clientX) * -1;
          this.dragTargetItem.dragSizeByIndex(this.dragTargetIndex, this.dragingSize);
        }
      }
    },
    dragstart(item, index, e) {
      this.dragstate = 1;
      this.baseClientX = e.clientX;
      this.dragTargetItem = item;
      this.dragTargetIndex = index;
    },
    ymdformat(ymd) {
      let ymdstr = ymd;
      ymdstr = ymdstr.replaceAll('-', '/');

      try {
        let ys = ymdstr.split('~');
        if (ys.length > 1) {
          if (ys[0] == ys[1]) {
            return ys[0];
          }

          let ymd1 = ys[0].split("/");
          let ymd2 = ys[1].split("/");
          if (ymd1.length == 3 && ymd2.length == 3) {
            if (ymd1[0] == ymd2[0]) {
              return ymd1[0] + "/" + ymd1[1] + "/" + ymd1[2] + " ～ " + ymd2[1] + "/" + ymd2[2];
            }
          }
        }

        let y2s = ymdstr.split('～');
        if (y2s.length > 1) {
          if (y2s[0] == y2s[1]) {
            return y2s[0];
          }
          let ymd21 = y2s[0].split("/");
          let ymd22 = y2s[1].split("/");
          if (ymd21.length == 3 && ymd22.length == 3) {
            if (ymd21[0] == ymd22[0]) {
              return ymd21[0] + "/" + ymd21[1] + "/" + ymd21[2] + " ～ " + ymd22[1] + "/" + ymd22[2];
            }
          }
        }
      } catch (ex) {
        console.log(ex);
      }

      return ymdstr;
    },
    //eslint-disable-next-line
    mouseOverAction(p, i, e) {
      if (this.menuOrderTarget) {
        this.menuOrderGoal = p;
      }
      this.isPageMenuMouseHover = true;
      this.hoverTargetIndex = i;
    },
    //eslint-disable-next-line
    mouseLeaveAction(e) {
      if (this.menuOrderTarget) {
        this.menuOrderGoal = null;
      }
      this.isPageMenuMouseHover = false;
      this.hoverTargetIndex = -1;
    },
    //eslint-disable-next-line
    mouseOverActionAddCustomer(e) {
      if (this.menuOrderTarget) {
        // this.isMenuOrderCustomerAddMode = true;
      }
    },
    //eslint-disable-next-line
    mouseLeaveActionAddCustomer(e) {
      this.isMenuOrderCustomerAddMode = false;
    },
    //eslint-disable-next-line
    mouseOverActionRemoveCustomer(e) {
      this.isMenuOrderCustomerMode = true;
    },
    //eslint-disable-next-line
    mouseLeaveActionRemoveCustomer(e) {
      if (this.menuOrderTarget) {
        this.menuOrderGoal = null;
      }
      this.isMenuOrderCustomerMode = false;
    },
    reportDelete() {
      this.page.delete(this.reportMenuEditItem.id);
      this.closeReportMenu();
    },
    reportCopy() {
      this.page.copy(this.reportMenuEditItem.id)
      this.page.reorder();
      this.closeReportMenu();
    },
    reportDownload() {
      this.page.download(this.reportMenuEditItem.id)
      this.closeReportMenu();
    },
    closeReportMenu() {
      this.isShowReportMenu = false;
    },
    showReportMenu(item, e) {
      this.reportMenuPositionX = e.pageX;
      this.reportMenuPositionY = e.pageY;
      if (window.innerWidth - (e.x) > 120) {
        //
      }else{
        if (e.x - 120 > 0) {
        this.reportMenuPositionX -= 120;
      }

      }
      
      this.isShowReportMenu = true;
      this.reportMenuEditItem = item;
    },
    showPageMenu(id, e, mode = 0) {
      this.menuPositionX = e.x;
      this.menuPositionY = e.y - 100;
      this.page.showPageMenu(id);
      this.menutargetmode = mode;
    },
    test(item) {
      console.log(item);
      console.log(item.getSegmentItems());
    },
    onSelectTime() {
      let startYmd = [];
      let endYmd = [];
      switch (this.crrc?.selector?.ymdMode) {
        case 0:
          this.timeEditReport.changeDate(
            this.timeEditReportIndex,
            'range',
            this.crrc.selector.range.startYmd.getYmd(),
            this.crrc.selector.range.endYmd.getYmd()
          );
          break;
        case 1:
        case 2:
        case 3:
          startYmd = this.crrc.selector.range.startYmd.getYmd().split('-');
          endYmd = this.crrc.selector.range.endYmd.getYmd().split('-');
          startYmd = this.crrc.selector.range.startYmd.getYmd().split('-');
          endYmd = this.crrc.selector.range.endYmd.getYmd().split('-');
          break;
      }

      if (startYmd.length > 2 && endYmd.length > 2) {
        let startDateEndDates = [];
        let startDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
        let endDt = new Date(endYmd[0], endYmd[1], endYmd[2]);
        let targetDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
        let tmpTargetDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), 0);
        startDt.setMonth(startDt.getMonth() - 1);
        endDt.setMonth(endDt.getMonth() - 1);
        if (this.crrc?.selector?.ymdMode == 1) {
          startDt = new Date(startYmd[0], startYmd[1], 1);
          endDt = new Date(endYmd[0], endYmd[1], 1);
          targetDt = new Date(endDt[0], endDt[1], 1);
          startDt.setMonth(startDt.getMonth() - 1);
          endDt.setMonth(endDt.getMonth() - 1);
          targetDt = new Date(startDt.getFullYear(), startDt.getMonth(), startDt.getDate());

          if (startDt.getTime() == endDt.getTime()) {
            tmpTargetDt = new Date(endDt.getFullYear(), endDt.getMonth() + 1, 0); //月末
            startDateEndDates.push({
              start: startDt.getFullYear() + '-' + (startDt.getMonth() + 1) + '-' + 1,
              end:
                tmpTargetDt.getFullYear() +
                '-' +
                (tmpTargetDt.getMonth() + 1) +
                '-' +
                tmpTargetDt.getDate(),
            });
          } else {
            tmpTargetDt = new Date(startDt.getFullYear(), startDt.getMonth() + 1, 0); //月末
            startDateEndDates.push({
              start: startDt.getFullYear() + '-' + (startDt.getMonth() + 1) + '-' + 1,
              end:
                tmpTargetDt.getFullYear() +
                '-' +
                (tmpTargetDt.getMonth() + 1) +
                '-' +
                tmpTargetDt.getDate(),
            });

            for (let i = 0; i < 12 * 5; i++) {
              targetDt.setMonth(targetDt.getMonth() + 1);
              tmpTargetDt = new Date(targetDt.getFullYear(), targetDt.getMonth() + 1, 0); //月末
              startDateEndDates.push({
                start: targetDt.getFullYear() + '-' + (targetDt.getMonth() + 1) + '-' + 1,
                end:
                  tmpTargetDt.getFullYear() +
                  '-' +
                  (tmpTargetDt.getMonth() + 1) +
                  '-' +
                  tmpTargetDt.getDate(),
              });
              if (targetDt.getTime() == endDt.getTime()) {
                break;
              }
            }
          }
        } else if (this.crrc?.selector?.ymdMode == 2) {
          targetDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
          targetDt.setMonth(targetDt.getMonth() - 1);
          endDt = new Date(endYmd[0], endYmd[1], endYmd[2]);
          endDt.setMonth(endDt.getMonth() - 1);
          let stockDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate());
          for (let i = 0; i < 31 * 12 * 5; i++) {
            if (targetDt.getDay() == 0) {
              if (stockDt) {
                startDateEndDates.push({
                  start:
                    stockDt.getFullYear() +
                    '-' +
                    (stockDt.getMonth() + 1) +
                    '-' +
                    stockDt.getDate(),
                  end:
                    targetDt.getFullYear() +
                    '-' +
                    (targetDt.getMonth() + 1) +
                    '-' +
                    targetDt.getDate(),
                });
              }
            }
            if (targetDt.getDay() == 1) {
              stockDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate());
            }

            if (targetDt.getTime() == endDt.getTime()) {
              if (targetDt.getDay() != 0) {
                startDateEndDates.push({
                  start:
                    stockDt.getFullYear() +
                    '-' +
                    (stockDt.getMonth() + 1) +
                    '-' +
                    stockDt.getDate(),
                  end:
                    targetDt.getFullYear() +
                    '-' +
                    (targetDt.getMonth() + 1) +
                    '-' +
                    targetDt.getDate(),
                });
              }

              break;
            }
            targetDt.setDate(targetDt.getDate() + 1);
          }
        } else if (this.crrc?.selector?.ymdMode == 3) {
          targetDt = new Date(startYmd[0], startYmd[1], startYmd[2]);
          targetDt.setMonth(targetDt.getMonth() - 1);
          endDt = new Date(endYmd[0], endYmd[1], endYmd[2]);
          endDt.setMonth(endDt.getMonth() - 1);
          for (let i = 0; i < 31 * 12 * 5; i++) {
            startDateEndDates.push({
              start:
                targetDt.getFullYear() + '-' + (targetDt.getMonth() + 1) + '-' + targetDt.getDate(),
              end:
                targetDt.getFullYear() + '-' + (targetDt.getMonth() + 1) + '-' + targetDt.getDate(),
            });

            if (targetDt.getTime() == endDt.getTime()) {
              break;
            }
            targetDt.setDate(targetDt.getDate() + 1);
          }
        }
        this.timeEditReport.createRecordFromDate(this.timeEditReportIndex, startDateEndDates);
      }

      if (this.rangePositionY + 388 > document.getElementById("main_001").clientHeight) {
        setTimeout(function () {
          window.scrollBy(0, -1);
        }, 100);
      }

      this.isShowTimeRange = false;
      this.timeEditReport = null;
    },
    onCreateRecord(item, e) {
      if (item || e) {
        //
      }
      item.createNewRecord();
    },
    onTimeChange(item, index, e) {
      if (this.adx.user.nowRole() == 'advertizer') {
        return;
      }

      let startDateObj = new Date();
      // startDateObj.setDate(1);

      let endDateObj = new Date();
      // endDateObj.setMonth(endDateObj.getMonth() + 1);
      // endDateObj.setDate(0);

      let startDate =
        startDateObj.getFullYear() +
        '-' +
        (startDateObj.getMonth() + 1) +
        '-' +
        startDateObj.getDate();
      let endDate =
        endDateObj.getFullYear() + '-' + (endDateObj.getMonth() + 1) + '-' + endDateObj.getDate();

      switch (item.type) {
        case 1:
          startDate = item.structureHolder.structure.rows[index].date.startDate;
          endDate = item.structureHolder.structure.rows[index].date.endDate;
          break;
        case 2:
          startDate = item.structureHolder.structure.periods[index].startDate;
          endDate = item.structureHolder.structure.periods[index].endDate;
          break;
        case 3:
          startDate = item.structureHolder.structure.rows[index].startDate;
          endDate = item.structureHolder.structure.rows[index].endDate;
          break;
      }

      let startDateYmds = startDate.split('-');
      let endDateYmds = endDate.split('-');

      this.crrc.selector.setYmdMode(0);
      this.crrc.selector.setRangeYmd(
        startDateYmds[0],
        startDateYmds[1],
        startDateYmds[2],
        endDateYmds[0],
        endDateYmds[1],
        endDateYmds[2]
      );
      this.rangePositionX = e.pageX;
      if (window.innerWidth - (e.x) > 540) {
        //
      } else {
        if (e.x - 540 > 0) {
          this.rangePositionX -= 540;

        } else {
          if (e.pageX > (1920 - 540)) {
            this.rangePositionX = 1920 - 540;
          }
        }
      }
      // if (this.rangePositionX > (window.innerWidth - 560)) {
      //   this.rangePositionX = (window.innerWidth - 560);
      // }
      // if (e.pageX > (1980 - 540)) {
      //   this.rangePositionX = e.pageX - 540;
      // }
      this.rangePositionY = e.pageY;
      if (window.innerHeight - (e.y) > 390) {
        //
      } else {
        if (e.y - 390 > 0) {
          this.rangePositionY -= 390;
        }
      }


      this.isShowTimeRange = true;
      this.timeEditReportIndex = index;
      this.timeEditReport = item;
    },
    onSegmentChange(item, index, e) {
      if (this.adx.user.nowRole() == 'advertizer') {
        return;
      }

      let startDateObj = new Date();
      // startDateObj.setDate(1);

      let endDateObj = new Date();
      // endDateObj.setMonth(endDateObj.getMonth() + 1);
      // endDateObj.setDate(0);

      let startDate =
        startDateObj.getFullYear() +
        '-' +
        (startDateObj.getMonth() + 1) +
        '-' +
        startDateObj.getDate();
      let endDate =
        endDateObj.getFullYear() + '-' + (endDateObj.getMonth() + 1) + '-' + endDateObj.getDate();

      let segmentgroups = [];
      switch (item.type) {
        case 1:
          startDate = item.structureHolder.structure.rows[index].date.startDate;
          endDate = item.structureHolder.structure.rows[index].date.endDate;
          segmentgroups = item.structureHolder.structure.rows[index].segmentGroups;

          break;
        case 2:
          // for (let i in item.structureHolder.structure.periods[index].startDate) {

          // }
          startDate = item.structureHolder.structure.periods[0].startDate;
          // for (let i in item.structureHolder.structure.periods[index].endDate) {

          // }
          endDate = item.structureHolder.structure.periods[0].endDate;
          segmentgroups = item.structureHolder.structure.rows[index].segmentGroups;

          break;
        case 3:
          // for(let i in item.structureHolder.structure.rows[index].startDate) {

          // }
          startDate = item.structureHolder.structure.rows[0].startDate;
          // for(let i in item.structureHolder.structure.rows[index].endDate) {

          // }
          endDate = item.structureHolder.structure.rows[0].endDate;

          segmentgroups = item.structureHolder.structure.segments[index].segmentGroups;
          break;
      }
      let isNoCheck = false;
      
      let subcond = false;
      try{
        if (this.page?.master?.advertizerId == 19) {
          if (item.title.indexOf('商材別') >= 0) {
            subcond = true;
          }
        }        
      }catch(ex) {
        console.log(ex);
      }
      if ((item.title == "限界CPA付き商材別実績") || (subcond == true)) {
        isNoCheck = true;
      }
      isNoCheck = true;
      this.page.segmentSelector.initialize(startDate, endDate, segmentgroups, isNoCheck);

      this.segmentSelectorPositionY = e.pageY;
      this.segmentSelectorPositionX = e.pageX;
      // if (e.pageX + 1100 > 1980) {
      //   this.segmentSelectorPositionX = e.pageX - (e.pageX + 1100 - 1980);
      // }

      let segWidth = 1032;
      if (this.page.getSegmentCount() > 0) {
          segWidth = (this.page.getSegmentCount()*200) + 33 - 1
      }
      if (window.innerWidth - e.x > segWidth) {
        //
      } else {
        if (e.x - segWidth > 0) {
          this.segmentSelectorPositionX -= segWidth;
        } else {
          if (e.pageX > 1920 - segWidth) {
            this.segmentSelectorPositionX = 1920 - segWidth;
          }
        }
      }

      this.segmentSelectorPositionY = e.pageY;
      if (window.innerHeight - e.y > 408) {
        //
      } else {
        if (e.y - 408 > 0) {
          this.segmentSelectorPositionY -= 408;
        }
      }

      this.isShowSegmentSelector = true;
      this.editSegment = item;
      this.editSegmentIndex = index;
    },
    onSelectSegment() {
      let segmentgroups = [];
      switch (this.editSegment.type) {
        case 1:
          segmentgroups =
            this.editSegment.structureHolder.structure.rows[this.editSegmentIndex].segmentGroups;
          break;
        case 2:
          segmentgroups =
            this.editSegment.structureHolder.structure.rows[this.editSegmentIndex].segmentGroups;
          break;
        case 3:
          segmentgroups =
            this.editSegment.structureHolder.structure.segments[this.editSegmentIndex]
              .segmentGroups;
          break;
      }
      if (segmentgroups) {
        //
      }

      function VSegmentItem(type, name, values) {
        this.type = type;
        this.name = name;
        this.value = values;
      }
      function VSegmentGroup(items = []) {
        this.segmentItems = items;
      }

      let gs = [];

      for (let i in this.page.segmentSelector.segmentItemBoxes) {
        let items = [];
        let sgis = this.page.segmentSelector.segmentItemBoxes[i].getSelectedSegmentItems();
        for (let j in sgis) {
          let sgi = sgis[j];
          let vs = [];
          if (sgi.values.length > 0) {
            vs.push(sgi.values[0]);
          } else {
            // break;
          }
          if (vs.length > 0) {
            items.push(new VSegmentItem(sgi.type, sgi.name, vs));
          }
        }

        if (items.length > 0) {
          gs.push(new VSegmentGroup(items));
        }
      }

      if (gs.length > 0) {
        this.editSegment.changeSegmentGroups(gs, this.editSegmentIndex);
      }

      if (
        this.segmentSelectorPositionY + 408 >
        document.getElementById("main_001").clientHeight
      ) {
        setTimeout(function () {
          window.scrollBy(0, -1);
        }, 100);
      }

      this.isShowSegmentSelector = false;
      this.editSegment = null;
      this.timeEditReport = null;
      this.editSegmentIndex = -1;
    },
    onShowPageMenu(id) {
      if (id) {
        //
      }
    },
    is2SpanCell(cell) {
      return is2SpanCell(cell);
    },
    isCells2SpanCells(cells, type = null) {
      let cs = cells2SpanCells(cells, type);
      for (let i in cs) {
        let c = cs[i];
        if (c.isSpan()) {
          return true;
        }
      }
      return false;
    },
    cells2SpanCells(cells, type = null) {
      if (type) {
        //
      }
      let numbers = [];
      return cells2SpanCells(cells, numbers);
    },

    linkurl(id) {
      return '/consultant/customreport?id=' + id;
    },
    goto(id, title) {
      if (id != this.page?.master.pageId) {
        this.$router.push(this.linkurl(id)+"&"+this.makeStatusQuery());
      } else {
        // this.inputer.text = title;
        // this.page.editPage(id);
        if (title) {
          //
        }
      }
    },
    onScreenReady() {
      this.initialize();
    },
    initialize() {
      const self = this;
      if (this.isLoad == true) {
        return;
      }
      this.isLoad = true;
      this.page.cancelAllAPICall();
      let pageId = '';
      try {
        pageId = this.$route.query['id'];
      } catch (ex) {
        //
      }
      this.page.initialize(
        pageId,
        this.adx.getScreenStructureById('consultant').advertizerId,
        this.adx.user
      );
      this.page.updateload(
        this.adx.getScreenStructureById('consultant').advertizerId,
        this.adx.user,
        function (date) {
          if (self.isActive) {
            self.adx.changeLastUpdate(date);
          }
        }
      );
      this.adx.changeCstCustomReport(true);
    },
  },
  components: {
    ContentHeader,
    // BoxSpinner,
    ScreenBarProgress2,
    Inputer,
    CustomReportRangeCalendar,
  },
  props: {
    adx: Object,
  },
  beforeUnmount() {
    document.removeEventListener('mousemove', this.onMouseMoveEvent);//[CR] close
    document.removeEventListener('mouseup', this.onMouseUpEvent);
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);

    this.page.cancelAllAPICall();
    this.isActive = false;
    this.adx.changeLastUpdate('');
    // this.adx.changeCstCustomReport(false);
  },
};
</script>
