<template>
  <div style="display: flex; pointer-events: all">
    <div style="width: 340px">
      <div
        v-bind:class="[selectmode == 'start' ? 'timeselector--active' : '']"
        style="display: flex; border: 1px solid #d2d7dd; padding: 1px; border-radius: 5px"
      >
        <img
          src="@/assets/icon/date_range-24px.svg"
          style="margin-left: 20px; margin-right: 20px; margin-top: 4px; width: 18px; height: 18px"
        />
        <div style="font: 18px Noto Sans JP; letter-spacing: 0px; color: #4d4f5c">
          {{ selectRange.startYmd.year }}/{{ ('00' + selectRange.startYmd.month).slice(-2) }}/{{
            ('00' + selectRange.startYmd.day).slice(-2)
          }}
        </div>
      </div>
      <div style="display: flex; margin-top: 10px; margin-bottom: 10px">
        <img
          src="@/assets/icon/keyboard_arrow_left-24px.svg"
          class="date_fromto_selector__minusmonth"
          v-on:click="minusMonth()"
        />
        <div style="width: 100%; text-align: center">
          {{ ('00' + showYmd.month).slice(-2) }} {{ showYmd.year }}
        </div>
        <img
          src="@/assets/icon/keyboard_arrow_right-24px.svg"
          class="date_fromto_selector__plusmonth"
          v-on:click="plusMonth()"
        />
      </div>
      <div style="width: 100%">
        <Calendar
          ref="bcalendar"
          :mode="selectmode"
          :year="showYmd.year"
          :month="showYmd.month"
          :range="selectRange"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.timeselector {
  box-shadow: 5px 5px 5px #0000ff;
}

.timeselector--active {
  box-shadow: 0 0 2px 0 rgb(0, 153, 255);
  outline: 0;
}

.date_fromto_btn:hover {
  background: #39434ee0;
  color: #fff;
}

.date_fromto_decide_btn:hover {
  background: #39434ee0;
  color: #fff;
}
</style>

<script>
import Calendar from '@/components/Calendar.vue';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';
import { TimeYmdRange } from '@/js/tacklebox/date/TimeYmdRange.js';

export default {
  name: 'DateSelectCalendarSimple',
  data() {
    return {
      name: 'DateSelectCalendarSimple',
      inputData: '',
      showYmd: new TimeYmd(this.year, this.month, this.day),
      selectRange: this.range,
      selectmode: 'one',
      rangeMode: 'all',
      today: null,
    };
  },
  computed: {},
  mounted() {
    if (this.startYmd) {
      this.showYmd.year = this.startYmd.year;
      this.showYmd.month = this.startYmd.month;
      this.showYmd.day = this.startYmd.day;
    }
    let self = this;

    this.selectRange.subject.addObserver(function () {
      if (self) {
        //
      }
    });
  },
  emits: ['rangecalendarevent', 'draw'],
  methods: {
    yearFromNextMonth() {
      let t = new TimeYmd(this.showYmd.year, this.showYmd.month);
      t.incMonth();
      return t.year;
    },
    monthFromNextMonth() {
      let t = new TimeYmd(this.showYmd.year, this.showYmd.month);
      t.incMonth();
      return t.month;
    },
    apply() {
      this.$emit('rangecalendarevent', 'apply', this.selectRange);
      //this.range.incMonth();
    },
    cancel() {
      this.$emit('rangecalendarevent', 'close', this.selectRange);
    },
    selectStartYmd() {
      this.selectmode = 'start';
      this.rangeMode = 'custom';
    },
    selectEndYmd() {
      this.selectmode = 'end';
      this.rangeMode = 'custom';
    },
    plusMonth() {
      this.$refs.bcalendar.$emit('draw');
      this.showYmd.incMonth();
    },
    minusMonth() {
      this.$refs.bcalendar.$emit('draw');
      this.showYmd.decMonth();
    },
    selectToday() {
      this.selectmode = '';
      this.rangeMode = 'today';
      this.selectRange.setStartYmd(this.today.year, this.today.month, this.today.day);
      this.selectRange.setEndYmd(this.today.year, this.today.month, this.today.day);
    },
    before(day) {
      this.selectmode = '';
      this.rangeMode = day;

      let t = new TimeYmd(this.today.year, this.today.month, this.today.day);
      t.calcDay(day);
      this.selectRange.setStartYmd(t.year, t.month, t.day);
      this.selectRange.setEndYmd(this.today.year, this.today.month, this.today.day);
    },
    all() {
      this.selectmode = '';
      this.rangeMode = 'all';
    },
    custom() {
      this.selectmode = 'start';
      this.rangeMode = 'custom';
    },
  },
  components: {
    Calendar,
  },
  props: {
    mode: String,
    year: Number,
    month: Number,
    range: {
      type: Object,
      default: new TimeYmdRange(),
    },
    startYmd: {
      type: Object,
      default: new TimeYmd(),
    },
  },
};
</script>
