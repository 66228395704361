import {
  getCampaignAllocatedBudgetInitialBuild,
  getCampaignAllocatedBudgetRebuild,
  updateAllocatedDailyBudget,
} from '../../com/grpc/budget_allocation.js';

function BudgetAllocationPage() {
  this.isNetworking = false;
}
BudgetAllocationPage.prototype.initialize = function() {
  let cuuid = getCampaignAllocatedBudgetInitialBuild(2, function(uuid, data) {
    if (cuuid == uuid && data != null) {
      let json = JSON.parse(JSON.stringify(data.result));
      console.log(json);
    }
  });

  let cuuid1 = getCampaignAllocatedBudgetRebuild(2, function(uuid, data) {
    if (cuuid1 == uuid && data != null) {
      let json = JSON.parse(JSON.stringify(data.result));
      console.log(json);
    }
  });

  let cuuid2 = updateAllocatedDailyBudget(2, function(uuid, data) {
    if (cuuid2 == uuid && data != null) {
      let json = JSON.parse(JSON.stringify(data.result));
      console.log(json);
    }
  });
};

export { BudgetAllocationPage };
