<template>
  <div>
    <div class="overlay_layer" v-if="isShowRange">
      <div class="summary_rangecalendar_background" v-on:click="rangecalendarevent"></div>
      <div class="summary_rangecalendar_layer">
        <div class="summary_rangecalendar">
          <div class="summary_rangecalendar__wrapper">
            <div v-if="dateRangeSelector">
              <RangeCalendar
                :range="dateRangeSelector.timeYmdRange"
                :todayYmd="getTodayYmd()"
                @rangecalendarevent="rangecalendarevent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay_layer" v-if="isShowRange2">
      <div
        class="summary_rangecalendar_background"
        style="z-index: 10"
        v-on:click="rangecalendarevent2"
      ></div>
      <div
        style="z-index: 100"
        class="simple_rangecalendar_layer"
        :style="{ top: `${rangePositionY}px`, left: `${rangePositionX}px`, width: `380px` }"
      >
        <div class="simple_rangecalendar" style="width: 380px">
          <div class="summary_rangecalendar__wrapper">
            <div v-if="dateRangeSelector2">
              <DateSelectCalendarSimple
                year="2022"
                month="3"
                :range="dateRangeSelector2"
                :todayYmd="getTodayYmd()"
                :startYmd="startYmd()"
                @rangecalendarevent="rangecalendarevent2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main_content_wrapper">
      <div class="adx_report_keyword">
        <div class="left_main_content">
          <div style="background: white; width: calc(100% - 20px)">
            <div class="left_main_content__header">
              <ContentHeader
                title="ウィークリースプリント"
                :timeRange="dateRangeSelector"
                :adx="adx"
                @timerangeevent="timerangeevent"
              />
            </div>
          </div>

          <div class="left_main_content__body">
            <div class="box">
              <div class="frame">
                <div class="frame__header" v-if="false" v-on:click="createSprint()">かに</div>
                <div class="frame__body">
                  <div style="overflow: auto">
                    <div style="display: flex">
                      <table class="sprint_box" cellspacing="0" cellpadding="0">
                        <!-- 項目 -->
                        <tr style="height: 67px">
                          <th class="hvh" style="height: 67px">
                            <div class="hvhbox">
                              <div class="spr_heditmark"></div>
                              <div style="width: 100%">
                                <div class="spr_veditmark"></div>
                                <div
                                  class="sprcell"
                                  style="height: initial; border-top: 1px solid rgb(200, 200, 200)"
                                >
                                  項目
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getSprintBalls()"
                            :key="index"
                          >
                            <th class="hv">
                              <div class="hhbox">
                                <div class="spr_veditmark">
                                  <div class="coleditmark">
                                    <div style="display: flex">
                                      <div v-on:click="generateNewSprint()">
                                        <img
                                          class="cmark yubi"
                                          src="@/assets/icon/add_black_24dp.svg"
                                        />
                                      </div>
                                      <div style="position: relative">
                                        <div v-on:click="page.holder.selectDeleteId(item.raw.id)">
                                          <img
                                            class="cmark yubi"
                                            src="@/assets/icon/drag_indicator_black_24dp.svg"
                                          />
                                        </div>
                                        <div
                                          style="position: absolute; z-index: 9999"
                                          v-if="page.holder.selectedDeleteId == item.raw.id"
                                        >
                                          <SprintDeleteMark
                                            :holder="page.holder"
                                            :id="item.raw.id"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-on:click="selectDateCol($event, item, 1)"
                                  class="sprcell"
                                  style="height: initial; border-top: 1px solid rgb(200, 200, 200)"
                                >
                                  <img
                                    v-if="true"
                                    width="14px"
                                    height="14px"
                                    src="@/assets/icon/date_range-24px.svg"
                                    style="
                                      margin-left: 0px;
                                      margin-right: 4px;
                                      width: 14px;
                                      height: 14px;
                                    "
                                  />
                                  {{ item.getWeek() }}週
                                </div>
                              </div>
                            </th>
                          </template>
                          <th class="hv" v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="spr_veditmark"></div>
                              <div
                                v-on:click="generateNewSprint()"
                                class="sprcell yubi"
                                style="
                                  height: initial;
                                  border-top: 1px solid rgb(200, 200, 200);
                                  border-right: initial;
                                "
                              >
                                <div style="color: #39434eaa">+NEW</div>
                              </div>
                            </div>
                          </th>
                        </tr>
                        <!-- 大方針 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd001'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d001')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">大方針</div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls('mayorpolicy')"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  tabindex="0"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'mayorpolicy')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                  v-on:click="page.holder.startEdit(item.raw.id, 'mayorpolicy')"
                                >
                                  <div v-if="!page.holder.isEdit(item.raw.id, 'mayorpolicy')">
                                    {{ item.getDataByKey('mayorpolicy') }}
                                  </div>
                                  <EditText
                                    :editball="item.getEditBallByKey('mayorpolicy')"
                                    v-if="page.holder.isEdit(item.raw.id, 'mayorpolicy')"
                                  />
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- 単月目標達成可否 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd002'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d002')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">
                                  単月目標達成可否
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls(
                              'monthlygoalachieve'
                            )"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  tabindex="0"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'monthlygoalachieve')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                  v-on:click="
                                    page.holder.startEdit(item.raw.id, 'monthlygoalachieve')
                                  "
                                >
                                  <div
                                    v-if="!page.holder.isEdit(item.raw.id, 'monthlygoalachieve')"
                                  >
                                    {{ item.getDataByKey('monthlygoalachieve') }}
                                  </div>
                                  <EditText
                                    :editball="item.getEditBallByKey('monthlygoalachieve')"
                                    v-if="page.holder.isEdit(item.raw.id, 'monthlygoalachieve')"
                                  />
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- 月内方針 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd003'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d003')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">
                                  月内方針
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls('monthlypolicy')"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  tabindex="0"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'monthlypolicy')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                  v-on:click="page.holder.startEdit(item.raw.id, 'monthlypolicy')"
                                >
                                  <div v-if="!page.holder.isEdit(item.raw.id, 'monthlypolicy')">
                                    {{ item.getDataByKey('monthlypolicy') }}
                                  </div>
                                  <EditText
                                    :editball="item.getEditBallByKey('monthlypolicy')"
                                    v-if="page.holder.isEdit(item.raw.id, 'monthlypolicy')"
                                  />
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- 月内進捗状況 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark yubi" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd004'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d004')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">
                                  月内進捗状況
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls(
                              'monthlystatustext'
                            )"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'monthlystatustext')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                >
                                  <div style="height: 100%">
                                    <div
                                      v-if="!page.holder.isEdit(item.raw.id, 'monthlystatustext')"
                                      v-on:click="monthlystatusEdit(item.raw.id)"
                                      style="height: 100%"
                                    >
                                      <div
                                        v-if="
                                          monthlystatusJson(item.getDataByKey('monthlystatustext'))
                                            .length == 0
                                        "
                                      >
                                        -
                                      </div>
                                      <template
                                        v-for="(stat, sindex) in monthlystatusJson(
                                          item.getDataByKey('monthlystatustext')
                                        )"
                                        :key="sindex"
                                      >
                                        <div class="monthlystatusline">
                                          <div>{{ data2str(stat.label) }}</div>
                                          <div class="monthlystatusline__line">-</div>
                                          <div class="monthlystatusline__value">
                                            {{ vtunit(stat.value, stat.type) }}
                                          </div>
                                          <div
                                            style="
                                              padding-left: 8px;
                                              padding-right: 4px;
                                              color: #000;
                                              font-weight: bold;
                                              position: relative;
                                            "
                                          >
                                            <div style="position: absolute; left: 12px; top: 0px">
                                              <svg
                                                v-bind:class="[
                                                  ratescaleArrawClass(stat.label, stat.rate),
                                                ]"
                                                v-if="
                                                  ratescaleArrawClass(stat.label, stat.rate) != ''
                                                "
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 11.268 13.002"
                                              >
                                                <path
                                                  id="合体_22"
                                                  data-name="合体 22"
                                                  d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                  transform="translate(-10732.001 -12773.001)"
                                                />
                                              </svg>
                                            </div>

                                            ({{ data2str(stat.rate) }}%)
                                          </div>
                                        </div>
                                      </template>
                                    </div>
                                    <SprintEditMonthlyStatusSelect
                                      :editball="item.getEditBallByKey('monthlystatustext')"
                                      :callbackObj="callbackMonthlyStatusObject"
                                      v-if="
                                        page.holder.isEdit(item.raw.id, 'monthlystatustext') &&
                                        monthlystatusInputMode == null
                                      "
                                    />
                                    <SprintEditMonthlyStatusText
                                      :editball="item.getEditBallByKey('monthlystatustext')"
                                      :inputmode="monthlystatusInputMode"
                                      :keyparam="monthlystatusInputKey"
                                      v-if="
                                        page.holder.isEdit(item.raw.id, 'monthlystatustext') &&
                                        monthlystatusInputMode != null
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- 概況 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd005'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d005')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">概況</div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls('overview')"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'overview')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                  v-on:click="page.holder.startEdit(item.raw.id, 'overview')"
                                >
                                  <div v-if="!page.holder.isEdit(item.raw.id, 'overview')">
                                    {{ item.getDataByKey('overview') }}
                                  </div>
                                  <EditText
                                    :editball="item.getEditBallByKey('overview')"
                                    v-if="page.holder.isEdit(item.raw.id, 'overview')"
                                  />
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- 課題箇所 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd005'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d005')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">
                                  課題箇所
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls('problempoints')"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'problempoints')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                  v-on:click="page.holder.startEdit(item.raw.id, 'problempoints')"
                                >
                                  <div v-if="!page.holder.isEdit(item.raw.id, 'problempoints')">
                                    {{ item.getDataByKey('problempoints') }}
                                  </div>
                                  <EditText
                                    :editball="item.getEditBallByKey('problempoints')"
                                    v-if="page.holder.isEdit(item.raw.id, 'problempoints')"
                                  />
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- 課題要因 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd006'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d006')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">
                                  課題要因
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls(
                              'problemfactors'
                            )"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'problemfactors')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                  v-on:click="page.holder.startEdit(item.raw.id, 'problemfactors')"
                                >
                                  <div v-if="!page.holder.isEdit(item.raw.id, 'problemfactors')">
                                    {{ item.getDataByKey('problemfactors') }}
                                  </div>
                                  <EditText
                                    :editball="item.getEditBallByKey('problemfactors')"
                                    v-if="page.holder.isEdit(item.raw.id, 'problemfactors')"
                                  />
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- 対策方針 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd007'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d007')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">
                                  対策方針
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls(
                              'approachpolicy'
                            )"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'approachpolicy')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                  v-on:click="approachEditStart(item.raw.id)"
                                >
                                  <div v-if="!page.holder.isEdit(item.raw.id, 'approachpolicy')">
                                    <template
                                      v-for="(ap, index) in approachpolicyJson(
                                        item.getDataByKey('approachpolicy')
                                      )"
                                      :key="index"
                                    >
                                      <div>
                                        <div class="sprint_title">{{ type2str(ap) }}</div>
                                        <div class="sprint_body">{{ ap.data }}</div>
                                      </div>
                                    </template>
                                  </div>
                                  <SprintEditSelect
                                    :editball="item.getEditBallByKey('approachpolicy')"
                                    v-if="
                                      page.holder.isEdit(item.raw.id, 'approachpolicy') &&
                                      approachInputMode == null
                                    "
                                    :holder="page.holder"
                                    :callbackObj="callbackObject"
                                  />
                                  <SprintEditText
                                    :inputmode="approachInputMode"
                                    :editball="item.getEditBallByKey('approachpolicy')"
                                    v-if="
                                      page.holder.isEdit(item.raw.id, 'approachpolicy') &&
                                      approachInputMode != null
                                    "
                                  />
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- 改善施策 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd008'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d008')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">
                                  改善施策
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls(
                              'todoactionitemsList'
                            )"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'todoactionitemsList')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                >
                                  <div>
                                    <template
                                      v-for="(action, sindex) in chengeOrderActionLogs(
                                        item.getDataByKey('todoactionitemsList')
                                      )"
                                      :key="sindex"
                                    >
                                      <div
                                        style="
                                          padding: 4px 0px;
                                          border-radius: 4px;
                                          margin: 4px 0px;
                                          height: initial;
                                          display: block;
                                        "
                                      >
                                        <div>
                                          <template
                                            v-for="(ci, cindex) in csvarr(action.provider)"
                                            :key="cindex"
                                          >
                                            <div style="padding: 0px 3px; float: left">
                                              <div
                                                style="
                                                  font-size: 10px;
                                                  background: #eeeeee;
                                                  color: #000;
                                                  padding: 0px 8px;
                                                  margin: 1px 0px;
                                                  border-radius: 4px;
                                                  width: fit-content;
                                                "
                                              >
                                                {{ ci }}
                                              </div>
                                            </div>
                                          </template>
                                          <div style="clear: both"></div>
                                        </div>
                                        <div
                                          style="
                                            color: #000;
                                            vertical-align: middle;
                                            margin-top: auto;
                                            margin-bottom: auto;
                                            padding: 4px 8px;
                                          "
                                        >
                                          {{ action.title
                                          }}<b
                                            >【{{ action.implementationdate }}-{{
                                              action.validationdate
                                            }}】</b
                                          >
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- 進行中施策 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd009'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d009')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">
                                  進行中施策
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls(
                              'inprogressactionitemsList'
                            )"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'inprogressactionitemsList')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                >
                                  <div>
                                    <template
                                      v-for="(action, sindex) in chengeOrderActionLogs(
                                        item.getDataByKey('inprogressactionitemsList')
                                      )"
                                      :key="sindex"
                                    >
                                      <div
                                        style="
                                          padding: 4px 0px;
                                          border-radius: 4px;
                                          margin: 4px 0px;
                                          height: initial;
                                          display: block;
                                        "
                                      >
                                        <div>
                                          <template
                                            v-for="(ci, cindex) in csvarr(action.provider)"
                                            :key="cindex"
                                          >
                                            <div style="padding: 0px 3px; float: left">
                                              <div
                                                style="
                                                  font-size: 10px;
                                                  background: #eeeeee;
                                                  color: #000;
                                                  padding: 0px 8px;
                                                  margin: 1px 0px;
                                                  width: fit-content;
                                                "
                                              >
                                                {{ ci }}
                                              </div>
                                            </div>
                                          </template>
                                          <div style="clear: both"></div>
                                        </div>

                                        <div
                                          style="
                                            color: #000;
                                            vertical-align: middle;
                                            margin-top: auto;
                                            margin-bottom: auto;
                                            padding: 4px 8px;
                                          "
                                        >
                                          {{ action.title
                                          }}<b
                                            >【{{ action.implementationdate }}-{{
                                              action.validationdate
                                            }}】</b
                                          >
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- MD確認 -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd010'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d010')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">MD確認</div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls('mdconfirm')"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div class="sprcell">
                                  <div>
                                    <div v-if="false">{{ item.getDataByKey('mdconfirm') }}</div>
                                    <input
                                      v-model="item.raw.mdconfirm"
                                      @change="changed(item.getEditBallByKey('mdconfirm'))"
                                      type="checkbox"
                                      class="c-switch-check"
                                      :id="'check' + index"
                                    />
                                    <label class="c-switch-label" :for="'check' + index"></label>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                        <!-- MDフィードバック -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark">
                                <div style="display: flex">
                                  <div class="roweditmark yubi" v-on:click="createCustomField">
                                    <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                  </div>
                                  <div class="roweditmark" style="position: relative">
                                    <div
                                      style="position: absolute; z-index: 9999"
                                      v-if="page.holder.selectedDeleteId == 'd011'"
                                    >
                                      <DummySprintDeleteMark :holder="page.holder" />
                                    </div>
                                    <div v-on:click="page.holder.selectDeleteId('d011')">
                                      <img
                                        class="cmark yubi"
                                        src="@/assets/icon/drag_indicator_black_24dp.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="width: 100%">
                                <div class="sprcell" style="text-decoration: underline">
                                  MDフィードバック
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getDataSprintBalls('mdfeedback')"
                            :key="index"
                          >
                            <td>
                              <div class="hhbox">
                                <div
                                  class="sprcell"
                                  v-bind:class="[
                                    isSelectedCell(item.raw.id, 'mdfeedback')
                                      ? 'sprcell--selected'
                                      : '',
                                  ]"
                                  v-on:click="page.holder.startEdit(item.raw.id, 'mdfeedback')"
                                >
                                  <div v-if="!page.holder.isEdit(item.raw.id, 'mdfeedback')">
                                    {{ item.getDataByKey('mdfeedback') }}
                                  </div>
                                  <EditText
                                    :editball="item.getEditBallByKey('mdfeedback')"
                                    v-if="page.holder.isEdit(item.raw.id, 'mdfeedback')"
                                  />
                                </div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>

                        <template v-for="(cstf, index) in page.holder.customs" :key="index">
                          <tr>
                            <th class="hh">
                              <div class="hvbox" style="z-index: 1">
                                <div class="spr_heditmark">
                                  <div style="display: flex">
                                    <div class="roweditmark yubi" v-on:click="createCustomField">
                                      <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                    </div>
                                    <div class="roweditmark">
                                      <div
                                        style="position: absolute; z-index: 9999"
                                        v-if="page.holder.selectedDeleteId == cstf.id"
                                      >
                                        <SprintCstfDeleteMark :holder="page.holder" :id="cstf.id" />
                                      </div>
                                      <div v-on:click="page.holder.selectDeleteId(cstf.id)">
                                        <img
                                          class="cmark yubi"
                                          src="@/assets/icon/drag_indicator_black_24dp.svg"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style="width: 100%">
                                  <div
                                    class="sprcell"
                                    style="text-decoration: underline"
                                    v-on:click="page.holder.startEdit(cstf.id, 'cstflabel')"
                                  >
                                    <div v-if="!page.holder.isEdit(cstf.id, 'cstflabel')">
                                      {{ cstf.label }}
                                    </div>
                                    <EditText
                                      :editball="page.holder.getcstfEditText(cstf.id, cstf.label)"
                                      v-if="page.holder.isEdit(cstf.id, 'cstflabel')"
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <template
                              v-for="(cstcell, index2) in page.holder.getDataSprintBallsCustomField(
                                cstf.id
                              )"
                              :key="index2"
                            >
                              <td>
                                <div class="hhbox">
                                  <div
                                    class="sprcell"
                                    v-bind:class="[
                                      isSelectedCell(cstcell.raw.id, cstf.id)
                                        ? 'sprcell--selected'
                                        : '',
                                    ]"
                                    v-on:click="page.holder.startEdit(cstcell.raw.id, cstf.id)"
                                  >
                                    <div v-if="!page.holder.isEdit(cstcell.raw.id, cstf.id)">
                                      {{ cstcell.getCstfDataByKey(cstf.id) }}
                                    </div>
                                    <EditText
                                      :editball="cstcell.getEditBallCstFByKey(cstf.id)"
                                      v-if="page.holder.isEdit(cstcell.raw.id, cstf.id)"
                                    />
                                  </div>
                                </div>
                              </td>
                            </template>
                            <td v-if="!page.holder.isDataLoad">
                              <div class="empyt_hh">
                                <div class="sprcell" style="border-right: initial"></div>
                              </div>
                            </td>
                          </tr>
                        </template>
                        <!-- NEW -->
                        <tr>
                          <th class="hh">
                            <div class="hvbox">
                              <div class="spr_heditmark"></div>
                              <div style="width: 100%">
                                <div
                                  style="
                                    color: #39434EAA
                                    border-left: solid 1px white;
                                    border-right: solid 1px white;
                                  "
                                  class="sprcell yubi"
                                  v-on:click="createCustomField"
                                >
                                  +NEW
                                </div>
                              </div>
                            </div>
                          </th>
                          <template
                            v-for="(item, index) in page.holder.getSprintBalls()"
                            :key="index"
                          >
                            <td>
                              <div class="empyt_hh">
                                <div
                                  style="
                                    border-left: solid 1px white;
                                    border-right: solid 1px white;
                                  "
                                  class="sprcell"
                                ></div>
                              </div>
                            </td>
                          </template>
                          <td v-if="!page.holder.isDataLoad">
                            <div class="empyt_hh">
                              <div class="sprcell" style="border-right: initial"></div>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <div style="padding-top: 128px; padding-left: 16px">
                        <Spinner v-if="page.holder.isDataLoad" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sub_content_layer">
          <div class="sub_content_wrapper">
            <div id="lay_right_sidemenu" class="right_sidemenu_layer">
              <SideMenu :adx="adx" selectId="sprint" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cmark {
  width: 16px;
}

table td div {
  height: auto;
}
</style>


<style scoped>
.c-switch-check {
  display: none;
}
.c-switch-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  border-radius: calc(30px / 2);
  background: #cccccc;
  transition: background 200ms;
}
.c-switch-label::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  display: block;
  width: calc(30px - 4px);
  height: calc(30px - 4px);
  border-radius: calc((30px - 4px) / 2);
  background: #ffffff;
  transform: translateX(0);
  transition: transform 200ms;
}
.c-switch-check:checked + .c-switch-label {
  background: #3dcc55;
}
.c-switch-check:checked + .c-switch-label::before {
  transform: translateX(calc((50px - 4px) - (30px - 4px)));
}
</style>

<style scoped>
.hhbox .spr_veditmark .coleditmark {
  display: none;
  /* opacity: 0; */
}

.hhbox:hover .spr_veditmark .coleditmark {
  display: block;
  user-select: none;
  /* opacity: 1;
  transition: 0.8s; */
}

.hvbox .spr_heditmark .roweditmark {
  display: none;
  /* opacity: 0; */
}

.hvbox:hover .spr_heditmark .roweditmark {
  display: block;
  user-select: none;
  /* opacity: 1;
  transition: 0.8s; */
}
</style>

<style scoped>
table {
  height: 100%;
}
table td {
  height: 100%;
}
table td div {
  /* height: 100%; */
}

table td,
th {
  color: #39434e;
  white-space: break-spaces;
  font: 12px / 17px 'Noto Sans JP';
}
.sprint_box {
  border-spacing: 0px;
}
.sprint_box th,
td {
  text-align: left;
}
.hh,
.hv,
.hvh {
  position: sticky;
  top: 0;
  left: 0;
  background: white;
}

.hh,
.hvh {
  z-index: 2;
}
.hv {
  z-index: 1;
}
.hv {
  text-align: left;
}

/* .hh:before, .hv:before, .hvh:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
} */

.hvbox,
.hvhbox {
  width: 240px;
  display: flex;
  height: 100%;
}
.hhbox {
  width: 480px;
  height: 100%;
}

.empyt_hh {
  width: 100%;
  height: 100%;
}

.sprcell {
  padding: 8px;
  height: 100%;
  border-bottom: solid 1px rgb(200, 200, 200);
  border-right: solid 1px rgb(200, 200, 200);
  position: relative;
}

.spr_veditmark {
  height: 32px;
}
.spr_heditmark {
  height: 32px;
  width: 40px;
}
</style>
<style scoped>
.yubi {
  cursor: pointer;
}
</style>

<style scoped>
.temp_bottom {
  margin-bottom: 20px;
}
</style>

<style scoped>
.sprint_title {
  padding: 4px 16px;
  border-radius: 4px;
  background: rgb(238, 238, 238);
  margin: 4px;
  width: fit-content;
}

.sprint_body {
  padding: 4px 16px;
}

.monthlystatusline {
  display: flex;
  width: fit-content;
  background: rgb(238, 238, 238);
  padding: 4px 16px;
  border-radius: 4px;
  margin: 4px;
}

.monthlystatusline__value {
  font-weight: bold;
  margin-left: 8px;
  margin-right: 8px;
}

.monthlystatusline__line {
  margin-left: 8px;
  margin-right: 8px;
}
</style>

<style scoped>
.sprcell--selected {
  background: rgb(60, 170, 255, 0.3);
}
</style>

<style scoped>
.gbarrow3step--1 {
  position: absolute;
  top: -1px;
  left: -16px;
  width: 14px;
  height: 15px;

  fill: #088dff;
}
.gbarrow3step--2 {
  position: absolute;
  top: 2px;
  left: -14px;
  width: 11px;
  height: 12px;

  fill: #088dff;
}
.gbarrow3step--3 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;

  fill: #088dff;
}
.gbarrow3step--4 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--5 {
  position: absolute;
  top: 3px;
  left: -14px;
  width: 10px;
  height: 13px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--6 {
  position: absolute;
  top: 3px;
  left: -16px;
  width: 14px;
  height: 15px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--11 {
  position: absolute;
  top: 3px;
  left: -16px;
  width: 14px;
  height: 15px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--12 {
  position: absolute;
  top: 3px;
  left: -14px;
  width: 10px;
  height: 13px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--13 {
  position: absolute;
  top: 7px;
  left: -12px;
  width: 8px;
  height: 8px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--14 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;

  fill: #ff1744;
}
.gbarrow3step--15 {
  position: absolute;
  top: 2px;
  left: -14px;
  width: 11px;
  height: 12px;

  fill: #ff1744;
}
.gbarrow3step--16 {
  position: absolute;
  top: -1px;
  left: -16px;
  width: 14px;
  height: 15px;

  fill: #ff1744;
}
</style>

<script>
// @ is an alias to /src
import SideMenu from '@/components/consultant/SideMenu.vue';
// import ContentHeader from '@/components/consultant/ContentHeader.vue';
import ContentHeader from '@/components/report/ContentHeader.vue';
import Spinner from '@/components/layout/Spinner.vue';
import RangeCalendar from '@/components/RangeCalendar.vue';
import { SprintPage } from '@/js/page/consultant/SprintPage.js';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';
import EditText from '@/components/EditText.vue';

import SprintDeleteMark from '@/components/SprintDeleteMark.vue';
import SprintCstfDeleteMark from '@/components/SprintCstfDeleteMark.vue';
import DateSelectCalendarSimple from '@/components/DateSelectCalendarSimple.vue';
import { TimeYmdRangeSe } from '@/js/tacklebox/date/TimeYmdRangeSe.js';
import DummySprintDeleteMark from '@/components/DummySprintDeleteMark.vue';

import SprintEditSelect from '@/components/SprintEditSelect.vue';
import SprintEditText from '@/components/SprintEditText.vue';
import SprintEditMonthlyStatusText from '@/components/SprintEditMonthlyStatusText.vue';
import SprintEditMonthlyStatusSelect from '@/components/SprintEditMonthlyStatusSelect.vue';

import { sprintApps, consultantscale2, ratescale3Step } from '@/js/setting.js';

export default {
  name: 'Sprint',
  data() {
    let self = this;
    return {
      name: 'ウィークリースプリント',
      page: new SprintPage(),
      isLoad: false,
      dateRangeSelector: null,
      provider: '',
      isShowRange: false,
      dateRangeSelector2: new TimeYmdRangeSe(),
      rangePositionX: 0,
      rangePositionY: 0,
      isShowRange2: false,
      isShowDailyAlert: false,
      isShowDailyAlertHover: false,
      isShowWeeklyAlert: false,
      isShowWeeklyAlertHover: false,
      approachInputMode: null,
      monthlystatusInputKey: null,
      monthlystatusInputMode: null,
      isActive: true,
      callbackObject: function (param) {
        setTimeout(function () {
          self.approachInputModeChange(param);
        }, 1);
      },
      callbackMonthlyStatusObject: function (param, keyparam) {
        setTimeout(function () {
          if (param) {
            self.monthlystatusInputMode = param;
            self.monthlystatusInputKey = keyparam;
          } else {
            self.monthlystatusInputMode = null;
            self.page.holder.reset();
          }
        }, 1);
      },
      onClickEvent(e) {
        if (e) {
          //
        }
        if (self.page.holder.selectedCells.length > 0) {
          self.page.holder.resetSelectCell();
        }
      },
      onKeyEvent(e) {
        if (e.key == 'ArrowDown') {
          if (e.shiftKey) {
            self.page.holder.keydownShift();
          } else {
            self.page.holder.keydown();
          }
        }
        if (e.key == 'ArrowUp') {
          if (e.shiftKey) {
            self.page.holder.keyupShift();
          } else {
            self.page.holder.keyup();
          }
        }
        if (e.key == 'ArrowLeft') {
          self.page.holder.keyleft();
        }
        if (e.key == 'ArrowRight') {
          self.page.holder.keyright();
        }
        if (e.key == 'C' || e.key == 'c') {
          if (e.ctrlKey) {
            self.page.holder.copyClipboradSelectedCell();
          }
        }
      },
      onKeyUpEvent(e) {
        if (e.key == 'Enter') {
          if (self.page.holder.selectedEditKey == null) {
            self.page.holder.keyEnter();
          }
        }
      },
    };
  },
  computed: {},
  emits: ['screenevent'],
  mounted() {
    let self = this;
    this.$emit('screenevent', 'sprint');
    if (this.adx.getScreenStructureById('consultant').getActivePages().length > 0) {
      self.dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
      self.initialize(
        null,
        null,
        self.adx.getScreenStructureById('consultant').advertizerId,
        self.dateRangeSelector
      );
      self.dateRangeSelector2.subject.addObserver(function () {
        self.rangecalendarevent2();
      });

      self.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
        self.isLoad = false;
        self.initialize(
          null,
          null,
          self.adx.getScreenStructureById('consultant').advertizerId,
          self.dateRangeSelector
        );
      });
    } else {
      this.adx.getScreenStructureById('consultant').subject.addObserver(function () {
        self.dateRangeSelector = self.adx.getScreenStructureById('consultant').dateRangeSelector;
        self.initialize(
          null,
          null,
          self.adx.getScreenStructureById('consultant').advertizerId,
          self.dateRangeSelector
        );
        self.dateRangeSelector2.subject.addObserver(function () {
          self.rangecalendarevent2();
        });

        self.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
          self.isLoad = false;
          self.initialize(
            null,
            null,
            self.adx.getScreenStructureById('consultant').advertizerId,
            self.dateRangeSelector
          );
        });
      });
    }

    document.addEventListener('keydown', this.onKeyEvent);
    document.addEventListener('keyup', this.onKeyUpEvent);
    document.addEventListener('click', this.onClickEvent);
  },
  methods: {
    chengeOrderActionLogs(logs) {
      let stocks = [];
      for (let i in logs) {
        stocks.push(logs[i]);
      }

      let pmaster = [];
      pmaster = [
        '全媒体',
        'GoogleSearch',
        'YahooSearch',
        'GDN',
        'YDN',
        'GoogleDiscovery',
        'Facebook',
        'Criteo',
        'LINE',
        'RTBHOUSE',
        'SmartNews',
        'Twitter',
      ];

      function _str2rank(str) {
        if (str) {
          for (let i = 0; i < pmaster.length; i++) {
            if (str.indexOf(pmaster[i]) >= 0) {
              return i;
            }
          }
        }
        return 99999;
      }

      function _timestr2number(str) {
        try {
          let md = str.split('/');
          if (md && md.length > 1) {
            return Number(md[1]) * 100 + Number(md[0]);
          }
        } catch (ex) {
          console.log(ex);
        }

        return 99999;
      }

      stocks.sort(function (a, b) {
        try {
          if (_str2rank(a.provider) < _str2rank(b.provider)) {
            return -1;
          } else if (_str2rank(a.provider) > _str2rank(b.provider)) {
            return 1;
          } else {
            let dlen = a.provider.split(',').length - b.provider.split(',').length;
            if (dlen != 0) {
              return dlen;
            } else {
              return _timestr2number(a.implementationdate) - _timestr2number(b.implementationdate);
            }
          }
        } catch (ex) {
          console.log(ex);
        }

        return 0;
      });

      return stocks;
    },
    ratescale3StepSprintEx(type, data) {
      let typ = type;
      if (isNaN(data) || data == '') {
        return 0;
      }
      switch (typ) {
        case '売上':
        case '売り上げ':
          if (data >= 130) return 1;
          else if (data >= 115) return 2;
          else if (data >= 100) return 3;
          else if (data >= 86) return 4;
          else if (data >= 71) return 5;
          else return 6;
      }
      return 0;
    },
    ratescaleArrawClass(type, data) {
      let cln = '';
      let typ = '';
      if (type) {
        typ = type.toLowerCase();
      }
      let step = ratescale3Step(typ, data);
      if (step == 0) {
        step = this.ratescale3StepSprintEx(typ, data);
      }
      switch (step) {
        case 1:
          return 'gbarrow3step--1';
        case 2:
          return 'gbarrow3step--2';
        case 3:
          return 'gbarrow3step--3';
        case 4:
          return 'gbarrow3step--4';
        case 5:
          return 'gbarrow3step--5';
        case 6:
          return 'gbarrow3step--6';
        case 11:
          return 'gbarrow3step--11';
        case 12:
          return 'gbarrow3step--12';
        case 13:
          return 'gbarrow3step--13';
        case 14:
          return 'gbarrow3step--14';
        case 15:
          return 'gbarrow3step--15';
        case 16:
          return 'gbarrow3step--16';
      }

      return cln;
    },
    isSelectedCell(id, key) {
      for (let i in this.page.holder.selectedCells) {
        if (
          this.page.holder.selectedCells[i].id == id &&
          this.page.holder.selectedCells[i].key == key
        ) {
          return true;
        }
      }

      return false;
    },
    consultantscale2background(k, data) {
      return consultantscale2(k, data);
    },
    data2str(str) {
      if (str) {
        if (str.length > 0) {
          return str;
        }
      }
      return 'None';
    },
    monthlystatusEdit(id) {
      this.page.holder.startEdit(id, 'monthlystatustext');
      this.monthlystatusInputMode = null;
    },
    type2str(ap) {
      let type = 0;
      try {
        type = ap.type;
      } catch (ex) {
        console.log(ex);
      }
      for (let i in sprintApps) {
        let sa = sprintApps[i];
        if (sa.id == type) {
          return sa.data;
        }
      }
      return '-';
    },
    approachInputModeChange(param) {
      if (param) {
        if (this.approachInputMode == null) {
          this.approachInputMode = param;
        }
      }
    },
    approachEditStart(id) {
      if (!this.page.holder.isEdit(id, 'approachpolicy')) {
        this.approachInputMode = null;
        this.page.holder.startEdit(id, 'approachpolicy');
      }
    },
    approachpolicyJson(str) {
      try {
        if (str) {
          let json = JSON.parse(str);
          return json;
        }
      } catch (ex) {
        console.log(ex);
      }
      return JSON.parse('[]');
    },
    monthlystatusJson(str) {
      try {
        if (str) {
          let json = JSON.parse(str);
          return json;
        }
      } catch (ex) {
        console.log(ex);
      }
      return JSON.parse('[]');
    },
    changed(item) {
      item.save(item.raw);
    },
    vtunit(value, dataType) {
      if (value) {
        //
      } else {
        return 'None';
      }
      function comma(num) {
        return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }
      let result = value;
      let ret = null;
      switch (dataType) {
        case 'rate':
          ret = comma(value);
          if (Number(value) < 10) {
            // 小数点以下2位まで表示
            if (ret.includes('.')) {
              if (ret.split('.')[1].length == 1) ret = ret + '0';
            } else {
              ret = ret + '.00';
            }
          } else {
            // 小数点以下1位まで表示
            if (!ret.includes('.')) {
              ret = ret + '.0';
            }
            if (ret.split('.')[1].length == 2) ret = ret.slice(0, -1);
          }
          return ret + '%';
        case 'currency':
          return '¥' + comma(value);
        case 'number':
          return comma(value);
        case 'count':
          return comma(value);
        case 'rate_roas':
          return comma(value) + '%';
      }

      return result;
    },
    initialize(id, pageComponents, advertizerId, timeRange) {
      const self = this;
      if (this.isLoad) {
        return;
      }
      this.isLoad = true;
      this.page.cancelAllAPICall();
      this.page.initialize(id, pageComponents, advertizerId, timeRange, this.adx.user);
      // let p = this.adx.getScreenStructureById('report').getPageByKey('媒体別実績');
      // this.page.initializeProviderTab(p.id);
      // let p2 = this.adx.getScreenStructureById('report').getPageByKey('媒体別実績');
      // this.page.runAfterPrimaryTablesLoaded = ()=>{
      //   this.page.providerTablesInitialize(p2.id, this.adx.getScreenStructureById('consultant').advertizerId, this.dateRangeSelector, this.adx.user);
      // }

      // let self = this;
      // this.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
      //   self.isLoad = false;
      //   self.initialize(
      //     null,
      //     null,
      //     self.adx.getScreenStructureById('consultant').advertizerId,
      //     self.dateRangeSelector
      //   );
      // });
      this.page.updateload(function (date) {
        if (self.isActive) {
          self.adx.changeLastUpdate(date);
        }
      });
    },
    generateNewSprint() {
      this.page.generateNewSprint(
        this.adx.getScreenStructureById('consultant').advertizerId,
        this.adx.user
      );
    },
    createSprint() {
      this.page.createSprint(
        this.adx.getScreenStructureById('consultant').advertizerId,
        this.adx.user
      );
    },
    createCustomField() {
      this.page.createCustomField(
        this.adx.getScreenStructureById('consultant').advertizerId,
        this.adx.user
      );
    },
    updateCustomField() {
      this.page.updateCustomField(
        this.adx.getScreenStructureById('consultant').advertizerId,
        this.adx.user
      );
    },
    deleteCustomField(id) {
      this.page.deleteCustomField(
        this.adx.getScreenStructureById('consultant').advertizerId,
        this.adx.user,
        id
      );
    },

    getTodayYmd() {
      let time = this.adx.getNowTime();
      return new TimeYmd(time.ymd.year, time.ymd.month, time.ymd.day);
    },
    startYmd() {
      let time = new TimeYmd(
        this.dateRangeSelector2.endYmd.year,
        this.dateRangeSelector2.endYmd.month,
        this.dateRangeSelector2.endYmd.day
      );
      //time.decMonth();
      return time;
    },

    mouseOverDaily() {
      this.isShowDailyAlertHover = true;
    },
    mouseLeaveDaily() {
      this.isShowDailyAlertHover = false;
    },
    mouseOverWeekly() {
      this.isShowWeeklyAlertHover = true;
    },
    mouseLeaveWeekly() {
      this.isShowWeeklyAlertHover = false;
    },
    toggleWeeklyAlert() {
      //this.isShowWeeklyAlert = !this.isShowWeeklyAlert;//[ALERT1]alertは１件でいい
    },
    isShowWeeklyAlertCheck() {
      return this.isShowWeeklyAlertHover || this.isShowWeeklyAlert;
    },
    toggleDailyAlert() {
      //this.isShowDailyAlert = !this.isShowDailyAlert;//[ALERT1]alertは１件でいい
    },
    isShowDailyAlertCheck() {
      return this.isShowDailyAlertHover || this.isShowDailyAlert;
    },
    isRangeMode() {
      if (this.dateRangeSelector) {
        return this.dateRangeSelector.mode == 1;
      }
      return false;
    },
    cpccpacvter(pmtf, cell) {
      if (cell) {
        if (cell.key === 'cpc') {
          return !pmtf;
        }
        if (cell.key.match(/cpa/)) {
          return !pmtf;
        }
      }
      return pmtf;
    },
    ratescale(rate) {
      let r = 0;
      let g = 0;
      let b = 0;
      let a = 0;
      let rt = parseFloat(rate);
      if (!isNaN(rt)) {
        if (rt > 100) {
          r = 92;
          g = 174;
          b = 76;
          a = (rt - 100) / 30;
        }
        if (rt < 100) {
          r = 233;
          g = 70;
          b = 55;

          a = (90 - rt) / 90;
        }
      }

      if (a > 1) {
        a = 1;
      }
      if (a < 0) {
        a = 0;
      }
      return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
    },
    rangecalendarevent: function (event, arg) {
      if (event == 'apply') {
        this.dateRangeSelector.setRangeYmd(
          arg.startYmd.year,
          arg.startYmd.month,
          arg.startYmd.day,
          arg.endYmd.year,
          arg.endYmd.month,
          arg.endYmd.day
        );
      }
      this.isShowRange = false;
    },
    rangecalendarevent2: function (event, value) {
      if (event || value) {
        //
      }
      if (this.dateRangeSelector2.sprintball) {
        //
      } else {
        return;
      }

      let ymd =
        this.dateRangeSelector2.endYmd.year +
        '-' +
        ('00' + this.dateRangeSelector2.endYmd.month).slice(-2) +
        '-' +
        ('00' + this.dateRangeSelector2.endYmd.day).slice(-2);
      this.dateRangeSelector2.sprintball.raw['startdate'] = ymd;
      this.dateRangeSelector2.sprintball.raw['enddate'] = ymd;
      this.page.holder.updateSprint(
        this.adx.getScreenStructureById('consultant').advertizerId,
        this.adx.user,
        this.dateRangeSelector2.sprintball.raw
      );
      this.dateRangeSelector2.mode = 0;
      this.dateRangeSelector2.id = 0;
      this.dateRangeSelector2.type = 0;
      this.dateRangeSelector2.sprintball = null;
      this.isShowRange2 = false;
    },
    timerangeevent: function (event) {
      if (event) {
        //
      }
      this.isShowRange = true;
    },
    getIcon(id) {
      //TBD
      switch (id) {
        case 1:
          return require('@/assets/icon/Google.png');
        case 2:
          return require('@/assets/icon/Google.png');
        case 3:
          return require('@/assets/thirdparty/icon/yahoo.png');
        case 4:
          return require('@/assets/icon/Google.png');
        case 5:
          return require('@/assets/thirdparty/icon/yahoo.png');
        case 6:
          return require('@/assets/icon/f_logo_RGB-Blue_1024.png');
        case 7:
          return require('@/assets/icon/LINE_APP_Android.png');
        case 8:
          return require('@/assets/thirdparty/icon/Criteo.png');
        case 9:
          return require('@/assets/icon/Google.png');
        case 10:
          return require('@/assets/icon/Google.png');
        case 11:
          return require('@/assets/icon/f_logo_RGB-Blue_1024.png');
        case 12:
          return require('@/assets/icon/Google.png');
        case 13:
          return require('@/assets/icon/Google.png');
        case 14:
          return require('@/assets/icon/Google.png');
        case 126:
          return require('@/assets/icon/Google.png');
      }
      return '';
    },
    changeMode(mode) {
      this.page.selectedMode = mode;
    },
    getModeName() {
      let name = '';
      switch (this.page.selectedMode) {
        case 1:
          name = '月別';
          break;
        case 2:
          name = '週別';
          break;
        case 3:
          name = '日別';
          break;
      }
      return name;
    },
    providerSelect(provider) {
      this.page.changeProviderSelect(provider);
    },
    isProviderSelected(provider) {
      return this.page.selectedProvider.map((p) => p.name).includes(provider.name);
    },
    getAlignedTableWidth() {
      // レンダリングされる前に実行されちゃうので、広さが0になっちゃう。苦肉の策で固定値を返す。
      //let providerList = this.page.dimensionHolder.get2DimensionCategories().map(d => d.group);
      //let widthList = providerList
      //                    .filter(providerName => this.isDimensionSelected(providerName))
      //                    .map(providerName => document.getElementById(providerName+'0'))
      //                    .filter(e => e!=null)
      //                    .map(e => e.clientWidth)
      //if(widthList.length == 0) {
      //  return '100%';
      //}
      return this.page.selectedProvider.length == 0 ? '' : '1600px';
    },
    copyToClipboard: (className) => {
      let applyInline = (el_clone, el_origin) => {
        const cs = window.getComputedStyle(el_origin);

        for (let key in cs) {
          if (!isNaN(parseInt(key))) continue;
          if (['length', 'parentRule'].includes(key)) continue;
          //if(! ["background", "font"].includes(key)) return ;
          if (['font', 'backgroundColor', 'color'].includes(key)) el_clone.style[key] = cs[key];
        }
        el_clone.style['fontSize'] = '100%';
        el_clone.children.forEach((n, i) => applyInline(n, el_origin.children[i]));
      };
      let origin = document.getElementsByClassName(className)[0];
      let tbl = origin.cloneNode(true);
      applyInline(tbl, origin);
      var type = 'text/html';
      var blob = new Blob([tbl.outerHTML], { type });
      var data = [new window.ClipboardItem({ [type]: blob })];
      navigator.clipboard.write(data);
    },
    getTableWidthByColumns(additional_columns = 0) {
      let cols = this.page.monthlyTable.getHeaderCell('種別').length - 1; // 基本カラム数 (種別カラムを除外)
      let ret = 100 * ((cols + additional_columns) / (13 + additional_columns));
      return String(ret) + '%';
    },
    getProviderCategoryTableWidth() {
      let cols = this.page.monthlyTable.getHeaderCell('種別').length - 1; // 基本カラム数 (種別カラムを除外)
      let ret = 85 * ((cols + 2) / 15);
      return ['15%', String(ret + 15) + '%'];
    },
    getProviderTableWidth() {
      return document.getElementById('providerWidthTable')?.clientWidth + 'px';
    },
    selectDateCol(e, i, mode = 1) {
      try {
        if (i.raw.startdate) {
          let sds = i.raw.startdate.split('-');
          if (sds.length < 2) {
            sds = i.raw.startdate.split('/');
          }
          switch (mode) {
            case 1:
              this.dateRangeSelector2.setRangeYmd(sds[0], sds[1], sds[2], sds[0], sds[1], sds[2]);
              break;
          }
          this.dateRangeSelector2.id = i.id;
          this.dateRangeSelector2.type = i.type;
          this.dateRangeSelector2.mode = mode;
          this.dateRangeSelector2.sprintball = i;
          this.rangePositionY = e.pageY; //layerY;
          this.rangePositionX = e.pageX; //layerX;
          this.isShowRange2 = true;
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    csv2n(csv) {
      if (csv) {
        return csv.replaceAll(',', '\n');
      }
      return '';
    },
    csvarr(csv) {
      if (csv) {
        return csv.split(',');
      }
      return ['-'];
    },
  },
  components: {
    SideMenu,
    ContentHeader,
    Spinner,
    RangeCalendar,
    EditText,
    SprintDeleteMark,
    SprintCstfDeleteMark,
    DateSelectCalendarSimple,
    DummySprintDeleteMark,
    SprintEditSelect,
    SprintEditText,
    SprintEditMonthlyStatusText,
    SprintEditMonthlyStatusSelect,
  },
  props: {
    adx: Object,
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.onKeyEvent);
    document.removeEventListener('keyup', this.onKeyUpEvent);
    document.removeEventListener('click', this.onClickEvent);

    this.page.cancelAllAPICall();

    this.isActive = false;
    this.adx.changeLastUpdate('');
  },
};
</script>
