<template>
<div v-if="process != null">
  <div class="barloader boxp1" v-if="!process.isFinish"></div>
</div>
</template>

<style scoped>
.barloader.boxp1 {
  display: block;
  position: relative;
  overflow: hidden;
  transition: 200ms;
  height: 4px;
  width: 100%;
}
.barloader.boxp1::before {
  position: absolute;
  content: '';
  display: inline-block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #3582F5;
  transition: 200ms;
  animation: boxp1anim 3s ease-in-out infinite;
}

@-webkit-keyframes boxp1anim {
  0% {
    left: -100%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 1;
  }
}
</style>

<script>
export default {
  name: 'ScreenBarProgress2',
  data() {
    return {
      isShow: false,
    };
  },
  computed: {},
  mounted() {
    this.isShow = true;
  },
  methods: {

  },
  components: {},
  props: {
    process: Object,
  },
};
</script>