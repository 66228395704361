
function Url() {
    this.grpc = `${process.env.VUE_APP_INHOUSE_GRPC_HOST}`;
}

const url = new Url();

const sprintApps = [
    { id: 1, data: "現状施策継続" },
    { id: 2, data: "全体施策実施" },
    { id: 3, data: "個別課題施策実施" },
    { id: 4, data: "間接的解決" },
    { id: 5, data: "ステイ" }];

const colScale2 = [
    "#37803A", // good
    "#449E48",
    "#5CB860",
    "#9BD39E",
    "#FAAEAA",
    "#F66D65",
    "#F2382C",
    "#DA1B0E", // bad
];

function consultantscale2(k, data) {
    let key = k.toLowerCase();
    let color = "#FFFFFF";
    if (data) {
        if (key.indexOf('cost') != -1) {
            if (data >= 130) color = colScale2[7];
            else if (data >= 120) color = colScale2[6];
            else if (data >= 110) color = colScale2[5];
            else if (data >= 105) color = colScale2[4];
            else if (data >= 103) color = colScale2[3];
            else if (data >= 102) color = colScale2[2];
            else if (data >= 101) color = colScale2[1];
            else if (data >= 100) color = colScale2[0];
            else if (data >= 99) color = colScale2[1];
            else if (data >= 98) color = colScale2[2];
            else if (data >= 96) color = colScale2[3];
            else if (data >= 91) color = colScale2[4];
            else if (data >= 81) color = colScale2[5];
            else if (data >= 71) color = colScale2[6];
            else color = colScale2[7];
        }

        if ((key.indexOf('cv') != -1) || (key.indexOf('roas') != -1)) {
            if (data >= 130) color = colScale2[0];
            else if (data >= 120) color = colScale2[1];
            else if (data >= 110) color = colScale2[2];
            else if (data >= 100) color = colScale2[3];
            else if (data >= 91) color = colScale2[4];
            else if (data >= 81) color = colScale2[5];
            else if (data >= 71) color = colScale2[6];
            else color = colScale2[7];
        }

        if (key.indexOf('cpa') != -1) {
            if (data >= 130) color = colScale2[7];
            else if (data >= 120) color = colScale2[6];
            else if (data >= 110) color = colScale2[5];
            else if (data >= 101) color = colScale2[4];
            else if (data >= 91) color = colScale2[3];
            else if (data >= 81) color = colScale2[2];
            else if (data >= 71) color = colScale2[1];
            else color = colScale2[0];
        }
    }
    return color;
}

function ratescale3Step(type, data) {
    if (data == '') {
        return 0;
    }
    if (type == 'cost') {
        if (data >= 130) return 16;
        else if (data >= 115) return 15;
        else if (data >= 105) return 14;
        else if (data >= 103) return 3;
        else if (data >= 101) return 2;
        else if (data >= 100) return 1;
        else if (data >= 98) return 12;
        else if (data >= 96) return 13;
        else if (data >= 86) return 4;
        else if (data >= 71) return 5;
        else return 6;
    }
    if (type == 'cv' || type == 'roas') {
        if (data >= 130) return 1;
        else if (data >= 115) return 2;
        else if (data >= 100) return 3
        else if (data >= 86) return 4;
        else if (data >= 71) return 5;
        else return 6;
    }
    if (type == 'cpa') {
        if (data >= 130) return 16;
        else if (data >= 115) return 15;
        else if (data >= 101) return 14;
        else if (data >= 86) return 13;
        else if (data >= 71) return 12;
        else return 11;
    }

    return 0;
}

function getColorKeyCodeByBackendKey(backendKey, advertizerId, mode=0) {
    if (backendKey || advertizerId || mode) {
        //
    }
    return null; 
    // if (backendKey == "ctr") {
    //     return "colored_ctr";
    // }
    // switch (advertizerId) {
    //     case 2: // キタムラ/スタジオマリオ
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 3: // キタムラ／中古カメラ
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 4: // Luxus
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_install":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "analysis_install_cvr":
    //                 return 'colored_cvr';
    //             case "analysis_install_cpi":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 5: // 藤井大丸
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_sales_1":
    //                 return 'colored_cv';
    //             case "provider_roas_1":
    //                 return 'colored_roas';
    //             default:
    //                 return null;
    //         }
    //     case 6: // ラクーンフィナンシャル/URIHO
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_all":
    //                 return 'colored_cv';
    //             case "business_cvr_all":
    //                 return 'colored_cvr';
    //             case "business_cpa_all":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 8: // ラクーンフィナンシャル/URIHO
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_1":
    //                 return 'colored_cv';
    //             case "business_cvr_1":
    //                 return 'colored_cvr';
    //             case "business_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 9: // イオンライフ／イオンのお葬式
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_1":
    //                 return 'colored_cv';
    //             case "business_cvr_1":
    //                 return 'colored_cvr';
    //             case "business_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 10: // しまうまプリント／フォトブック
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_1":
    //                 return 'colored_cv';
    //             case "business_cvr_1":
    //                 return 'colored_cvr';
    //             case "business_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 11: // しまうまプリント／写真プリント
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_1":
    //                 return 'colored_cv';
    //             case "business_cvr_1":
    //                 return 'colored_cvr';
    //             case "business_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 12: // メディカルコンシェルジュ／本体
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             case "analysis_cv_2":
    //                 return 'colored_cv';
    //             case "analysis_cvr_7":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_2":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 13: // メディカルコンシェルジュ／MC-N
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 14: // メディカルコンシェルジュ／MC-K
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 15: // メディカルコンシェルジュ／MC-D
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 17: // プレアデス／ほいくジョブ
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_all":
    //                 return 'colored_cv';
    //             case "business_cvr_all":
    //                 return 'colored_cvr';
    //             case "business_cpa_all":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 18: // Nature Remo
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_1":
    //                 return 'colored_cv';
    //             case "provider_cvr_1":
    //                 return 'colored_cvr';
    //             case "provider_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }

    //     case 19: // 交換できるくん
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 20: // MANARA
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_1":
    //                 if (mode == 1) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "provider_cpa_1":
    //                 if (mode == 1) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;                    
    //             case "business_cv_4":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_4":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_4":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             default:
    //                 return null;
    //         }
    //     case 22: // 株式会社レックスアドバイザーズ／REXアドバイザーズ
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cv_2":
    //                 return 'colored_cv';
    //             case "provider_cvr_7":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_2":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 24: // REGBODY
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_7":
    //                 return 'colored_cv';
    //             case "business_cvr_7":
    //                 return 'colored_cvr';
    //             case "business_cpa_7":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 27: // TECRA
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 28: // MIKAWAYA21株式会社／まごころサポート
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_1":
    //                 return 'colored_cv';
    //             case "provider_cvr_1":
    //                 return 'colored_cvr';
    //             case "provider_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 33: // オリエンタルランド
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_2":
    //                 return 'colored_cv';
    //             case "analysis_cvr_2":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_2":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 34: // 伊藤忠
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 35: // PICM
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_1":
    //                 return 'colored_cv';
    //             case "provider_cvr_1":
    //                 return 'colored_cvr';
    //             case "provider_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 44: // ランクアップ・トーンアップUV
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_1":
    //                 return 'colored_cv';
    //             case "business_cvr_1":
    //                 return 'colored_cvr';
    //             case "business_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 54: // 株式会社メディカル・コンシェルジュ／MC-P
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 55: // 株式会社メディカル・コンシェルジュ／MC-求人問合せ
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 if (mode == 1) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "analysis_cv_2":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "analysis_cvr_21":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "analysis_cpa_1":
    //                 if (mode == 1) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "analysis_cpa_2":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             default:
    //                 return null;
    //         }
    //     case 60: // 株式会社モード・プランニング・ジャパン／キララサポート
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_1":
    //                 return 'colored_cv';
    //             case "provider_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "provider_cpa_1":
    //                 return 'colored_cpa';
    //             case "analysis_cvr_7":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "analysis_cvr_8":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "analysis_cvr_9":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "analysis_cvr_10":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             default:
    //                 return null;
    //         }

    //     case 63: // タケモトピアノ
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_1":
    //                 if (mode == 1) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "provider_cpa_1":
    //                 if (mode == 1) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cv_2":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_2":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_2":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cv_all":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_all":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_all":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             default:
    //                 return null;
    //         }

    //     case 67: // 67.JIMOS_マキアレイベル
    //         switch (backendKey) {
    //             case "cpm":
    //                 return 'colored_ctr';
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "provider_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "provider_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 68: // テスト顧客
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 70: // キタムラ中古時計
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_all":
    //                 if (mode == 1) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "provider_cpa_all":
    //                 if (mode == 1) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "analysis_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "analysis_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "analysis_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             default:
    //                 return null;
    //         }
    //     case 71: // XXX
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_other":
    //                 return 'colored_cv';
    //             case "business_cv_other":
    //                 return 'colored_cv';
    //             case "provider_cpa_other":
    //                 return 'colored_cpa';
    //             case "business_cpa_other":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 72: // 水の森
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_5":
    //                 return 'colored_cv';
    //             case "business_cv_6":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cv_7":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cv_8":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_5":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_5":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 73: // 水の森SIM
    //         switch (backendKey) {
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_1":
    //                 return 'colored_cv';
    //             case "business_cv_2":
    //                 return 'colored_cv';
    //             case "business_cv_3":
    //                 return 'colored_cv';
    //             case "business_cv_4":
    //                 return 'colored_cv';
    //             default:
    //                 return null;
    //         }
    //     case 78: // CPAエクセレントパートナーズ株式会社／CPAラーニング
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 80: // CPAエクセレントパートナーズ株式会社／CPA会計学院
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 81: // 株式会社ラクス／maildealer
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_2":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "provider_cvr_2":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "provider_cpa_2":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cv_2":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_21":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_2":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cv_3":
    //                 return 'colored_cv';
    //             case "business_cvr_22":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_3":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 82: // AbCash
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_1":
    //                 return 'colored_cv';
    //             case "provider_cvr_1":
    //                 return 'colored_cvr';
    //             case "provider_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 83: // 株式会社ラクスライトクラウド／ブラストメール
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "analysis_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cv_2":
    //                 return 'colored_cv';
    //             case "provider_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "analysis_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "provider_cvr_21":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "provider_cvr_22":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "provider_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "analysis_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cpa_2":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 87: // 株式会社ビタブリッドジャパン／ビタブリッドジャパン
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "cpm":
    //                 return 'colored_ctr';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "analysis_cvr_21":
    //                 if (mode == 0) {
    //                     return 'colored_ctr';
    //                 }
    //                 return null;
    //             case "analysis_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "provider_cpa_1":
    //                 if (mode == 1) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             default:
    //                 return null;
    //         }
    //     case 88: // 株式会社laugh.／laugh._Display
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "business_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_1":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;

    //             case "provider_cv_1":
    //                 if (mode == 0) {
    //                     return 'colored_cv';
    //                 }
    //                 return null;
    //             case "business_cvr_21":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "provider_cpa_1":
    //                 if (mode == 0) {
    //                     return 'colored_cpa';
    //                 }
    //                 return null;
    //             case "business_cv_2":
    //                 return 'colored_cv';
    //             case "business_cvr_23":
    //                 if (mode == 0) {
    //                     return 'colored_cvr';
    //                 }
    //                 return null;
    //             case "business_cpa_2":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 89: // CPAエクセレントパートナーズ株式会社／CPASS
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "provider_cv_other":
    //                 return 'colored_cv';
    //             case "provider_cvr_other":
    //                 return 'colored_cvr';
    //             case "provider_cpa_other":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     case 92: // 株式会社LAVA International／UCHIYOGA+
    //         switch (backendKey) {
    //             case "cpc":
    //                 return 'colored_cpc';
    //             case "cost_with_fee":
    //                 return 'colored_cost';
    //             case "analysis_cv_1":
    //                 return 'colored_cv';
    //             case "analysis_cvr_1":
    //                 return 'colored_cvr';
    //             case "analysis_cpa_1":
    //                 return 'colored_cpa';
    //             default:
    //                 return null;
    //         }
    //     default:
    //         return null;
    // }
}

function cpa11StepColor() {
    return [
        "#E6FAFE", // good
        "#DBF8FC",
        "#CAF5FA",
        "#B6F1F8",
        "#AAEEF6",
        "#9DEBF3",
        "#8DE7F2",
        "#7DE4EF",
        "#6CE0EC",
        "#51D8E7",
        "#27CCDE", // bad
    ];
}

export { url, sprintApps, consultantscale2, ratescale3Step, getColorKeyCodeByBackendKey, cpa11StepColor }
