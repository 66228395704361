
function data2dateandweek(str) {
    try {
        let ymds = str.split("/");

        let d = new Date(ymds[0], Number(ymds[1]) - 1, ymds[2]);
        let w = "";
        switch (d.getDay()) {
            case 0:
                w = "日";
                break;
            case 1:
                w = "月";
                break;
            case 2:
                w = "火";
                break;
            case 3:
                w = "水";
                break;
            case 4:
                w = "木";
                break;
            case 5:
                w = "金";
                break;
            case 6:
                w = "土";
                break;
        }
        return ymds[0] + "/" + ymds[1] + "/" + ymds[2] + "(" + w + ")";
    } catch (ex) {
        console.log(ex);
    }
    return "";
}

export { data2dateandweek }