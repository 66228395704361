// source: report_page/custom_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.adx.custom_report.CopyCustomReportPageRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.CopyCustomReportPageResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.CopyCustomReportPageResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.CopyCustomReportPageResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.CreateCustomReportPageRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.CreateCustomReportPageResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.CreateCustomReportPageResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.CreateCustomReportPageResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.CreateCustomReportRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.CreateCustomReportResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.CreateCustomReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.CreateCustomReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.DeleteCustomReportPageRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.DeleteCustomReportPageResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.DeleteCustomReportPageResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.DeleteCustomReportPageResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.DeleteCustomReportRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.DeleteCustomReportResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.DeleteCustomReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.DeleteCustomReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomAvailableKeysRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomAvailableKeysResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomAvailableKeysResponse.Key', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomAvailableKeysResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomAvailableKeysResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomReportPagesRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomReportPagesResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomReportPagesResponse.Page', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomReportPagesResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomReportPagesResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomReportsRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomReportsResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomReportsResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.GetCustomReportsResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentNameRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentNameResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentNameResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentNameResponse.Result.Segment', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentNameResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentValueRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentValueRequest.Segment', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentValueResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentValueResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentValueResponse.Result.Segment', null, global);
goog.exportSymbol('proto.adx.custom_report.GetSegmentValueResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.RelateCustomReportWithPageRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.RelateCustomReportWithPageResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.RelateCustomReportWithPageResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.RelateCustomReportWithPageResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReport', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReportPage', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReportPagesRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReportPagesResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReportPagesResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReportPagesResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReportsRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReportsResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReportsResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.ReorderCustomReportsResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.ReportData1', null, global);
goog.exportSymbol('proto.adx.custom_report.ReportData2', null, global);
goog.exportSymbol('proto.adx.custom_report.ReportData2.Periods', null, global);
goog.exportSymbol('proto.adx.custom_report.ReportData3', null, global);
goog.exportSymbol('proto.adx.custom_report.ReportData3.Segments', null, global);
goog.exportSymbol('proto.adx.custom_report.SegmentGroup', null, global);
goog.exportSymbol('proto.adx.custom_report.SegmentItem', null, global);
goog.exportSymbol('proto.adx.custom_report.Table', null, global);
goog.exportSymbol('proto.adx.custom_report.UpdateCustomReportPageRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.UpdateCustomReportPageResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.UpdateCustomReportPageResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.UpdateCustomReportPageResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.custom_report.UpdateCustomReportRequest', null, global);
goog.exportSymbol('proto.adx.custom_report.UpdateCustomReportResponse', null, global);
goog.exportSymbol('proto.adx.custom_report.UpdateCustomReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.custom_report.UpdateCustomReportResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.CopyCustomReportPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.CopyCustomReportPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.CopyCustomReportPageRequest.displayName = 'proto.adx.custom_report.CopyCustomReportPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.CopyCustomReportPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.CopyCustomReportPageResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.CopyCustomReportPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.CopyCustomReportPageResponse.displayName = 'proto.adx.custom_report.CopyCustomReportPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.CopyCustomReportPageResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.CopyCustomReportPageResponse.Result.displayName = 'proto.adx.custom_report.CopyCustomReportPageResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.CreateCustomReportPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.CreateCustomReportPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.CreateCustomReportPageRequest.displayName = 'proto.adx.custom_report.CreateCustomReportPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.CreateCustomReportPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.CreateCustomReportPageResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.CreateCustomReportPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.CreateCustomReportPageResponse.displayName = 'proto.adx.custom_report.CreateCustomReportPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.CreateCustomReportPageResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.CreateCustomReportPageResponse.Result.displayName = 'proto.adx.custom_report.CreateCustomReportPageResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.CreateCustomReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.CreateCustomReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.CreateCustomReportRequest.displayName = 'proto.adx.custom_report.CreateCustomReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.CreateCustomReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.CreateCustomReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.CreateCustomReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.CreateCustomReportResponse.displayName = 'proto.adx.custom_report.CreateCustomReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.CreateCustomReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.CreateCustomReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.CreateCustomReportResponse.Result.displayName = 'proto.adx.custom_report.CreateCustomReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.DeleteCustomReportPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.DeleteCustomReportPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.DeleteCustomReportPageRequest.displayName = 'proto.adx.custom_report.DeleteCustomReportPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.DeleteCustomReportPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.DeleteCustomReportPageResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.DeleteCustomReportPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.DeleteCustomReportPageResponse.displayName = 'proto.adx.custom_report.DeleteCustomReportPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.DeleteCustomReportPageResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.DeleteCustomReportPageResponse.Result.displayName = 'proto.adx.custom_report.DeleteCustomReportPageResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.DeleteCustomReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.DeleteCustomReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.DeleteCustomReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.DeleteCustomReportRequest.displayName = 'proto.adx.custom_report.DeleteCustomReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.DeleteCustomReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.DeleteCustomReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.DeleteCustomReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.DeleteCustomReportResponse.displayName = 'proto.adx.custom_report.DeleteCustomReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.DeleteCustomReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.DeleteCustomReportResponse.Result.displayName = 'proto.adx.custom_report.DeleteCustomReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.GetCustomAvailableKeysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomAvailableKeysRequest.displayName = 'proto.adx.custom_report.GetCustomAvailableKeysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.GetCustomAvailableKeysResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.GetCustomAvailableKeysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomAvailableKeysResponse.displayName = 'proto.adx.custom_report.GetCustomAvailableKeysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.GetCustomAvailableKeysResponse.Key, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.displayName = 'proto.adx.custom_report.GetCustomAvailableKeysResponse.Key';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetCustomAvailableKeysResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.displayName = 'proto.adx.custom_report.GetCustomAvailableKeysResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomReportPagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetCustomReportPagesRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetCustomReportPagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomReportPagesRequest.displayName = 'proto.adx.custom_report.GetCustomReportPagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomReportPagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.GetCustomReportPagesResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.GetCustomReportPagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomReportPagesResponse.displayName = 'proto.adx.custom_report.GetCustomReportPagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetCustomReportPagesResponse.Page.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetCustomReportPagesResponse.Page, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomReportPagesResponse.Page.displayName = 'proto.adx.custom_report.GetCustomReportPagesResponse.Page';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetCustomReportPagesResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetCustomReportPagesResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomReportPagesResponse.Result.displayName = 'proto.adx.custom_report.GetCustomReportPagesResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomReportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetCustomReportsRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetCustomReportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomReportsRequest.displayName = 'proto.adx.custom_report.GetCustomReportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.SegmentItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.SegmentItem.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.SegmentItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.SegmentItem.displayName = 'proto.adx.custom_report.SegmentItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.SegmentGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.SegmentGroup.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.SegmentGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.SegmentGroup.displayName = 'proto.adx.custom_report.SegmentGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.Table.displayName = 'proto.adx.custom_report.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReportData1 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.ReportData1.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.ReportData1, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReportData1.displayName = 'proto.adx.custom_report.ReportData1';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReportData2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.ReportData2.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.ReportData2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReportData2.displayName = 'proto.adx.custom_report.ReportData2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReportData2.Periods = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.ReportData2.Periods.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.ReportData2.Periods, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReportData2.Periods.displayName = 'proto.adx.custom_report.ReportData2.Periods';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReportData3 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.ReportData3.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.ReportData3, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReportData3.displayName = 'proto.adx.custom_report.ReportData3';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReportData3.Segments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.ReportData3.Segments.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.ReportData3.Segments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReportData3.Segments.displayName = 'proto.adx.custom_report.ReportData3.Segments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.GetCustomReportsResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.GetCustomReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomReportsResponse.displayName = 'proto.adx.custom_report.GetCustomReportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetCustomReportsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetCustomReportsResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetCustomReportsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetCustomReportsResponse.Result.displayName = 'proto.adx.custom_report.GetCustomReportsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetSegmentNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.GetSegmentNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetSegmentNameRequest.displayName = 'proto.adx.custom_report.GetSegmentNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetSegmentNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.GetSegmentNameResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.GetSegmentNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetSegmentNameResponse.displayName = 'proto.adx.custom_report.GetSegmentNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetSegmentNameResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetSegmentNameResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetSegmentNameResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetSegmentNameResponse.Result.displayName = 'proto.adx.custom_report.GetSegmentNameResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.GetSegmentNameResponse.Result.Segment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.displayName = 'proto.adx.custom_report.GetSegmentNameResponse.Result.Segment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetSegmentValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetSegmentValueRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetSegmentValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetSegmentValueRequest.displayName = 'proto.adx.custom_report.GetSegmentValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetSegmentValueRequest.Segment.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetSegmentValueRequest.Segment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetSegmentValueRequest.Segment.displayName = 'proto.adx.custom_report.GetSegmentValueRequest.Segment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetSegmentValueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.GetSegmentValueResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.GetSegmentValueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetSegmentValueResponse.displayName = 'proto.adx.custom_report.GetSegmentValueResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetSegmentValueResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetSegmentValueResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetSegmentValueResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetSegmentValueResponse.Result.displayName = 'proto.adx.custom_report.GetSegmentValueResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.GetSegmentValueResponse.Result.Segment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.displayName = 'proto.adx.custom_report.GetSegmentValueResponse.Result.Segment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.RelateCustomReportWithPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.RelateCustomReportWithPageRequest.displayName = 'proto.adx.custom_report.RelateCustomReportWithPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.RelateCustomReportWithPageResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.RelateCustomReportWithPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.RelateCustomReportWithPageResponse.displayName = 'proto.adx.custom_report.RelateCustomReportWithPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.RelateCustomReportWithPageResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.displayName = 'proto.adx.custom_report.RelateCustomReportWithPageResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReorderCustomReportPage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.ReorderCustomReportPage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReorderCustomReportPage.displayName = 'proto.adx.custom_report.ReorderCustomReportPage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.ReorderCustomReportPagesRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.ReorderCustomReportPagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReorderCustomReportPagesRequest.displayName = 'proto.adx.custom_report.ReorderCustomReportPagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.ReorderCustomReportPagesResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.ReorderCustomReportPagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReorderCustomReportPagesResponse.displayName = 'proto.adx.custom_report.ReorderCustomReportPagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.ReorderCustomReportPagesResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.displayName = 'proto.adx.custom_report.ReorderCustomReportPagesResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReorderCustomReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.ReorderCustomReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReorderCustomReport.displayName = 'proto.adx.custom_report.ReorderCustomReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReorderCustomReportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.ReorderCustomReportsRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.ReorderCustomReportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReorderCustomReportsRequest.displayName = 'proto.adx.custom_report.ReorderCustomReportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReorderCustomReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.ReorderCustomReportsResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.ReorderCustomReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReorderCustomReportsResponse.displayName = 'proto.adx.custom_report.ReorderCustomReportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.custom_report.ReorderCustomReportsResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.custom_report.ReorderCustomReportsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.ReorderCustomReportsResponse.Result.displayName = 'proto.adx.custom_report.ReorderCustomReportsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.UpdateCustomReportPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.UpdateCustomReportPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.UpdateCustomReportPageRequest.displayName = 'proto.adx.custom_report.UpdateCustomReportPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.UpdateCustomReportPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.UpdateCustomReportPageResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.UpdateCustomReportPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.UpdateCustomReportPageResponse.displayName = 'proto.adx.custom_report.UpdateCustomReportPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.UpdateCustomReportPageResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.UpdateCustomReportPageResponse.Result.displayName = 'proto.adx.custom_report.UpdateCustomReportPageResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.UpdateCustomReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.UpdateCustomReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.UpdateCustomReportRequest.displayName = 'proto.adx.custom_report.UpdateCustomReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.UpdateCustomReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.custom_report.UpdateCustomReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.custom_report.UpdateCustomReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.UpdateCustomReportResponse.displayName = 'proto.adx.custom_report.UpdateCustomReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.custom_report.UpdateCustomReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.custom_report.UpdateCustomReportResponse.Result.displayName = 'proto.adx.custom_report.UpdateCustomReportResponse.Result';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.CopyCustomReportPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.CopyCustomReportPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.CopyCustomReportPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CopyCustomReportPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.CopyCustomReportPageRequest}
 */
proto.adx.custom_report.CopyCustomReportPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.CopyCustomReportPageRequest;
  return proto.adx.custom_report.CopyCustomReportPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.CopyCustomReportPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.CopyCustomReportPageRequest}
 */
proto.adx.custom_report.CopyCustomReportPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.CopyCustomReportPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.CopyCustomReportPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.CopyCustomReportPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CopyCustomReportPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.CopyCustomReportPageRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CopyCustomReportPageRequest} returns this
 */
proto.adx.custom_report.CopyCustomReportPageRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.CopyCustomReportPageRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.CopyCustomReportPageRequest} returns this
 */
proto.adx.custom_report.CopyCustomReportPageRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string pageId = 3;
 * @return {string}
 */
proto.adx.custom_report.CopyCustomReportPageRequest.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CopyCustomReportPageRequest} returns this
 */
proto.adx.custom_report.CopyCustomReportPageRequest.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.CopyCustomReportPageResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.CopyCustomReportPageResponse.TypeCase}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.CopyCustomReportPageResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.CopyCustomReportPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.CopyCustomReportPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.CopyCustomReportPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CopyCustomReportPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.CopyCustomReportPageResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.CopyCustomReportPageResponse;
  return proto.adx.custom_report.CopyCustomReportPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.CopyCustomReportPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.CopyCustomReportPageResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.CopyCustomReportPageResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.CopyCustomReportPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.CopyCustomReportPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CopyCustomReportPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.CopyCustomReportPageResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.CopyCustomReportPageResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.CopyCustomReportPageResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newpageid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse.Result}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.CopyCustomReportPageResponse.Result;
  return proto.adx.custom_report.CopyCustomReportPageResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.CopyCustomReportPageResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse.Result}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewpageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.CopyCustomReportPageResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.CopyCustomReportPageResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewpageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse.Result} returns this
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string newPageId = 2;
 * @return {string}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.prototype.getNewpageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse.Result} returns this
 */
proto.adx.custom_report.CopyCustomReportPageResponse.Result.prototype.setNewpageid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.CopyCustomReportPageResponse.Result}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.CopyCustomReportPageResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.CopyCustomReportPageResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.CopyCustomReportPageResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse} returns this
*/
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.CopyCustomReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse} returns this
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse} returns this
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.CopyCustomReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.CopyCustomReportPageResponse} returns this
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.CopyCustomReportPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.CopyCustomReportPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.CreateCustomReportPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.CreateCustomReportPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.CreateCustomReportPageRequest}
 */
proto.adx.custom_report.CreateCustomReportPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.CreateCustomReportPageRequest;
  return proto.adx.custom_report.CreateCustomReportPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.CreateCustomReportPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.CreateCustomReportPageRequest}
 */
proto.adx.custom_report.CreateCustomReportPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.CreateCustomReportPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.CreateCustomReportPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportPageRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.CreateCustomReportPageRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportPageRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 order = 4;
 * @return {number}
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.CreateCustomReportPageRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportPageRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.CreateCustomReportPageResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.CreateCustomReportPageResponse.TypeCase}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.CreateCustomReportPageResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.CreateCustomReportPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.CreateCustomReportPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.CreateCustomReportPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.CreateCustomReportPageResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.CreateCustomReportPageResponse;
  return proto.adx.custom_report.CreateCustomReportPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.CreateCustomReportPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.CreateCustomReportPageResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.CreateCustomReportPageResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.CreateCustomReportPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.CreateCustomReportPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.CreateCustomReportPageResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.CreateCustomReportPageResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.CreateCustomReportPageResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse.Result}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.CreateCustomReportPageResponse.Result;
  return proto.adx.custom_report.CreateCustomReportPageResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.CreateCustomReportPageResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse.Result}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.CreateCustomReportPageResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.CreateCustomReportPageResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse.Result} returns this
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pageId = 2;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse.Result} returns this
 */
proto.adx.custom_report.CreateCustomReportPageResponse.Result.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.CreateCustomReportPageResponse.Result}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.CreateCustomReportPageResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.CreateCustomReportPageResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.CreateCustomReportPageResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse} returns this
*/
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.CreateCustomReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse} returns this
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse} returns this
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.CreateCustomReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.CreateCustomReportPageResponse} returns this
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.CreateCustomReportPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.CreateCustomReportPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.CreateCustomReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.CreateCustomReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pageid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.CreateCustomReportRequest}
 */
proto.adx.custom_report.CreateCustomReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.CreateCustomReportRequest;
  return proto.adx.custom_report.CreateCustomReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.CreateCustomReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.CreateCustomReportRequest}
 */
proto.adx.custom_report.CreateCustomReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.CreateCustomReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.CreateCustomReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.CreateCustomReportRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string structure = 4;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pageId = 5;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 order = 6;
 * @return {number}
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.CreateCustomReportRequest} returns this
 */
proto.adx.custom_report.CreateCustomReportRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.CreateCustomReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.CreateCustomReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.CreateCustomReportResponse.TypeCase}
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.CreateCustomReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.CreateCustomReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.CreateCustomReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.CreateCustomReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.CreateCustomReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.CreateCustomReportResponse}
 */
proto.adx.custom_report.CreateCustomReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.CreateCustomReportResponse;
  return proto.adx.custom_report.CreateCustomReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.CreateCustomReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.CreateCustomReportResponse}
 */
proto.adx.custom_report.CreateCustomReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.CreateCustomReportResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.CreateCustomReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.CreateCustomReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.CreateCustomReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.CreateCustomReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.CreateCustomReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.CreateCustomReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.CreateCustomReportResponse.Result}
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.CreateCustomReportResponse.Result;
  return proto.adx.custom_report.CreateCustomReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.CreateCustomReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.CreateCustomReportResponse.Result}
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.CreateCustomReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.CreateCustomReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportResponse.Result} returns this
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reportId = 2;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportResponse.Result} returns this
 */
proto.adx.custom_report.CreateCustomReportResponse.Result.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.CreateCustomReportResponse.Result}
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.CreateCustomReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.CreateCustomReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.CreateCustomReportResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.CreateCustomReportResponse} returns this
*/
proto.adx.custom_report.CreateCustomReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.CreateCustomReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.CreateCustomReportResponse} returns this
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.CreateCustomReportResponse} returns this
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.CreateCustomReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.CreateCustomReportResponse} returns this
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.CreateCustomReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.CreateCustomReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.DeleteCustomReportPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.DeleteCustomReportPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.DeleteCustomReportPageRequest}
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.DeleteCustomReportPageRequest;
  return proto.adx.custom_report.DeleteCustomReportPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.DeleteCustomReportPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.DeleteCustomReportPageRequest}
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.DeleteCustomReportPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.DeleteCustomReportPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportPageRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.DeleteCustomReportPageRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string pageId = 3;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportPageRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportPageRequest.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.DeleteCustomReportPageResponse.TypeCase}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.DeleteCustomReportPageResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.DeleteCustomReportPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.DeleteCustomReportPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.DeleteCustomReportPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.DeleteCustomReportPageResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.DeleteCustomReportPageResponse;
  return proto.adx.custom_report.DeleteCustomReportPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.DeleteCustomReportPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.DeleteCustomReportPageResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.DeleteCustomReportPageResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.DeleteCustomReportPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.DeleteCustomReportPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.DeleteCustomReportPageResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.DeleteCustomReportPageResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.DeleteCustomReportPageResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse.Result}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.DeleteCustomReportPageResponse.Result;
  return proto.adx.custom_report.DeleteCustomReportPageResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.DeleteCustomReportPageResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse.Result}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.DeleteCustomReportPageResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.DeleteCustomReportPageResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse.Result} returns this
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pageId = 2;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse.Result} returns this
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.Result.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.DeleteCustomReportPageResponse.Result}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.DeleteCustomReportPageResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.DeleteCustomReportPageResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.DeleteCustomReportPageResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse} returns this
*/
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.DeleteCustomReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse} returns this
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse} returns this
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.DeleteCustomReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.DeleteCustomReportPageResponse} returns this
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.DeleteCustomReportPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.DeleteCustomReportPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.DeleteCustomReportRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.DeleteCustomReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.DeleteCustomReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reportid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageidsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    fromallpages: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest}
 */
proto.adx.custom_report.DeleteCustomReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.DeleteCustomReportRequest;
  return proto.adx.custom_report.DeleteCustomReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.DeleteCustomReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest}
 */
proto.adx.custom_report.DeleteCustomReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPageids(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFromallpages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.DeleteCustomReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.DeleteCustomReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string reportId = 3;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string pageIds = 4;
 * @return {!Array<string>}
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.getPageidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.setPageidsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.addPageids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.clearPageidsList = function() {
  return this.setPageidsList([]);
};


/**
 * optional bool fromAllPages = 5;
 * @return {boolean}
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.getFromallpages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.setFromallpages = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.DeleteCustomReportRequest} returns this
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.clearFromallpages = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.DeleteCustomReportRequest.prototype.hasFromallpages = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.DeleteCustomReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.DeleteCustomReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.DeleteCustomReportResponse.TypeCase}
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.DeleteCustomReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.DeleteCustomReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.DeleteCustomReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.DeleteCustomReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.DeleteCustomReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse}
 */
proto.adx.custom_report.DeleteCustomReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.DeleteCustomReportResponse;
  return proto.adx.custom_report.DeleteCustomReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.DeleteCustomReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse}
 */
proto.adx.custom_report.DeleteCustomReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.DeleteCustomReportResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.DeleteCustomReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.DeleteCustomReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.DeleteCustomReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.DeleteCustomReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.DeleteCustomReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.DeleteCustomReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse.Result}
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.DeleteCustomReportResponse.Result;
  return proto.adx.custom_report.DeleteCustomReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.DeleteCustomReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse.Result}
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.DeleteCustomReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.DeleteCustomReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse.Result} returns this
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reportId = 2;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse.Result} returns this
 */
proto.adx.custom_report.DeleteCustomReportResponse.Result.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.DeleteCustomReportResponse.Result}
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.DeleteCustomReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.DeleteCustomReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.DeleteCustomReportResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse} returns this
*/
proto.adx.custom_report.DeleteCustomReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.DeleteCustomReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse} returns this
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse} returns this
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.DeleteCustomReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.DeleteCustomReportResponse} returns this
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.DeleteCustomReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.DeleteCustomReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomAvailableKeysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysRequest}
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomAvailableKeysRequest;
  return proto.adx.custom_report.GetCustomAvailableKeysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysRequest}
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomAvailableKeysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysRequest} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysRequest} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.GetCustomAvailableKeysResponse.TypeCase}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.GetCustomAvailableKeysResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.GetCustomAvailableKeysResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomAvailableKeysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomAvailableKeysResponse;
  return proto.adx.custom_report.GetCustomAvailableKeysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.GetCustomAvailableKeysResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomAvailableKeysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.toObject = function(includeInstance, msg) {
  var f, obj = {
    backendkey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keyformat: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomAvailableKeysResponse.Key;
  return proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackendkey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyformat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBackendkey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeyformat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string backendKey = 1;
 * @return {string}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.getBackendkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.setBackendkey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string keyFormat = 3;
 * @return {string}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.getKeyformat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.setKeyformat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keysList: jspb.Message.toObjectList(msg.getKeysList(),
    proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Result}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomAvailableKeysResponse.Result;
  return proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Result}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.GetCustomAvailableKeysResponse.Key;
      reader.readMessage(value,proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.deserializeBinaryFromReader);
      msg.addKeys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.GetCustomAvailableKeysResponse.Key.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Key keys = 2;
 * @return {!Array<!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key>}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.prototype.getKeysList = function() {
  return /** @type{!Array<!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.GetCustomAvailableKeysResponse.Key, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key>} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Result} returns this
*/
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.prototype.setKeysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Key}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.prototype.addKeys = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.GetCustomAvailableKeysResponse.Key, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.Result.prototype.clearKeysList = function() {
  return this.setKeysList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.GetCustomAvailableKeysResponse.Result}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.GetCustomAvailableKeysResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.GetCustomAvailableKeysResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.GetCustomAvailableKeysResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse} returns this
*/
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.GetCustomAvailableKeysResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetCustomAvailableKeysResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomAvailableKeysResponse} returns this
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetCustomAvailableKeysResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomAvailableKeysResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetCustomReportPagesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomReportPagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomReportPagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportPagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageidsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomReportPagesRequest}
 */
proto.adx.custom_report.GetCustomReportPagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomReportPagesRequest;
  return proto.adx.custom_report.GetCustomReportPagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomReportPagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomReportPagesRequest}
 */
proto.adx.custom_report.GetCustomReportPagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addPageids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomReportPagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomReportPagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportPagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesRequest} returns this
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesRequest} returns this
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string pageIds = 3;
 * @return {!Array<string>}
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.getPageidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesRequest} returns this
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.setPageidsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetCustomReportPagesRequest} returns this
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.addPageids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetCustomReportPagesRequest} returns this
 */
proto.adx.custom_report.GetCustomReportPagesRequest.prototype.clearPageidsList = function() {
  return this.setPageidsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.GetCustomReportPagesResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.GetCustomReportPagesResponse.TypeCase}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.GetCustomReportPagesResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.GetCustomReportPagesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomReportPagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportPagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.GetCustomReportPagesResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomReportPagesResponse;
  return proto.adx.custom_report.GetCustomReportPagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.GetCustomReportPagesResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.GetCustomReportPagesResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomReportPagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportPagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.GetCustomReportPagesResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomReportPagesResponse.Page.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse.Page} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reportidsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Page}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomReportPagesResponse.Page;
  return proto.adx.custom_report.GetCustomReportPagesResponse.Page.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse.Page} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Page}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addReportids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomReportPagesResponse.Page.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse.Page} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getReportidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string pageId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Page} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Page} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Page} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string reportIds = 4;
 * @return {!Array<string>}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.getReportidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Page} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.setReportidsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Page} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.addReportids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Page} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Page.prototype.clearReportidsList = function() {
  return this.setReportidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomReportPagesResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagesList: jspb.Message.toObjectList(msg.getPagesList(),
    proto.adx.custom_report.GetCustomReportPagesResponse.Page.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Result}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomReportPagesResponse.Result;
  return proto.adx.custom_report.GetCustomReportPagesResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Result}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.GetCustomReportPagesResponse.Page;
      reader.readMessage(value,proto.adx.custom_report.GetCustomReportPagesResponse.Page.deserializeBinaryFromReader);
      msg.addPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomReportPagesResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.GetCustomReportPagesResponse.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Page pages = 2;
 * @return {!Array<!proto.adx.custom_report.GetCustomReportPagesResponse.Page>}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.prototype.getPagesList = function() {
  return /** @type{!Array<!proto.adx.custom_report.GetCustomReportPagesResponse.Page>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.GetCustomReportPagesResponse.Page, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.GetCustomReportPagesResponse.Page>} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Result} returns this
*/
proto.adx.custom_report.GetCustomReportPagesResponse.Result.prototype.setPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.GetCustomReportPagesResponse.Page=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Page}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.prototype.addPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.GetCustomReportPagesResponse.Page, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.Result.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.GetCustomReportPagesResponse.Result}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.GetCustomReportPagesResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.GetCustomReportPagesResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.GetCustomReportPagesResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse} returns this
*/
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.GetCustomReportPagesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetCustomReportPagesResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportPagesResponse} returns this
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetCustomReportPagesResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportPagesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetCustomReportsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomReportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomReportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reportidsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    withdata: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomReportsRequest}
 */
proto.adx.custom_report.GetCustomReportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomReportsRequest;
  return proto.adx.custom_report.GetCustomReportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomReportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomReportsRequest}
 */
proto.adx.custom_report.GetCustomReportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addReportids(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomReportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomReportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReportidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportsRequest} returns this
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.GetCustomReportsRequest} returns this
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string pageId = 3;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportsRequest} returns this
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string reportIds = 4;
 * @return {!Array<string>}
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.getReportidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.custom_report.GetCustomReportsRequest} returns this
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.setReportidsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetCustomReportsRequest} returns this
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.addReportids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetCustomReportsRequest} returns this
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.clearReportidsList = function() {
  return this.setReportidsList([]);
};


/**
 * optional bool withData = 5;
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.getWithdata = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.custom_report.GetCustomReportsRequest} returns this
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.setWithdata = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportsRequest} returns this
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.clearWithdata = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportsRequest.prototype.hasWithdata = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.SegmentItem.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.SegmentItem.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.SegmentItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.SegmentItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.SegmentItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valueList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.SegmentItem}
 */
proto.adx.custom_report.SegmentItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.SegmentItem;
  return proto.adx.custom_report.SegmentItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.SegmentItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.SegmentItem}
 */
proto.adx.custom_report.SegmentItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.SegmentItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.SegmentItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.SegmentItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.SegmentItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.adx.custom_report.SegmentItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.SegmentItem} returns this
 */
proto.adx.custom_report.SegmentItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.custom_report.SegmentItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.SegmentItem} returns this
 */
proto.adx.custom_report.SegmentItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string value = 3;
 * @return {!Array<string>}
 */
proto.adx.custom_report.SegmentItem.prototype.getValueList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.custom_report.SegmentItem} returns this
 */
proto.adx.custom_report.SegmentItem.prototype.setValueList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.SegmentItem} returns this
 */
proto.adx.custom_report.SegmentItem.prototype.addValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.SegmentItem} returns this
 */
proto.adx.custom_report.SegmentItem.prototype.clearValueList = function() {
  return this.setValueList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.SegmentGroup.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.SegmentGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.SegmentGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.SegmentGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.SegmentGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    segmentitemsList: jspb.Message.toObjectList(msg.getSegmentitemsList(),
    proto.adx.custom_report.SegmentItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.SegmentGroup}
 */
proto.adx.custom_report.SegmentGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.SegmentGroup;
  return proto.adx.custom_report.SegmentGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.SegmentGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.SegmentGroup}
 */
proto.adx.custom_report.SegmentGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.SegmentItem;
      reader.readMessage(value,proto.adx.custom_report.SegmentItem.deserializeBinaryFromReader);
      msg.addSegmentitems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.SegmentGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.SegmentGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.SegmentGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.SegmentGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegmentitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.adx.custom_report.SegmentItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SegmentItem segmentItems = 1;
 * @return {!Array<!proto.adx.custom_report.SegmentItem>}
 */
proto.adx.custom_report.SegmentGroup.prototype.getSegmentitemsList = function() {
  return /** @type{!Array<!proto.adx.custom_report.SegmentItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.SegmentItem, 1));
};


/**
 * @param {!Array<!proto.adx.custom_report.SegmentItem>} value
 * @return {!proto.adx.custom_report.SegmentGroup} returns this
*/
proto.adx.custom_report.SegmentGroup.prototype.setSegmentitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.adx.custom_report.SegmentItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.SegmentItem}
 */
proto.adx.custom_report.SegmentGroup.prototype.addSegmentitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.adx.custom_report.SegmentItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.SegmentGroup} returns this
 */
proto.adx.custom_report.SegmentGroup.prototype.clearSegmentitemsList = function() {
  return this.setSegmentitemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.Table}
 */
proto.adx.custom_report.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.Table;
  return proto.adx.custom_report.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.Table}
 */
proto.adx.custom_report.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.custom_report.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.Table} returns this
 */
proto.adx.custom_report.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.custom_report.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.Table} returns this
 */
proto.adx.custom_report.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.ReportData1.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReportData1.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReportData1.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReportData1} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData1.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    segmentgroupsList: jspb.Message.toObjectList(msg.getSegmentgroupsList(),
    proto.adx.custom_report.SegmentGroup.toObject, includeInstance),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.custom_report.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReportData1}
 */
proto.adx.custom_report.ReportData1.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReportData1;
  return proto.adx.custom_report.ReportData1.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReportData1} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReportData1}
 */
proto.adx.custom_report.ReportData1.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.SegmentGroup;
      reader.readMessage(value,proto.adx.custom_report.SegmentGroup.deserializeBinaryFromReader);
      msg.addSegmentgroups(value);
      break;
    case 3:
      var value = new proto.adx.custom_report.Table;
      reader.readMessage(value,proto.adx.custom_report.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReportData1.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReportData1.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReportData1} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData1.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSegmentgroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.SegmentGroup.serializeBinaryToWriter
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.custom_report.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.adx.custom_report.ReportData1.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReportData1} returns this
 */
proto.adx.custom_report.ReportData1.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SegmentGroup segmentGroups = 2;
 * @return {!Array<!proto.adx.custom_report.SegmentGroup>}
 */
proto.adx.custom_report.ReportData1.prototype.getSegmentgroupsList = function() {
  return /** @type{!Array<!proto.adx.custom_report.SegmentGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.SegmentGroup, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.SegmentGroup>} value
 * @return {!proto.adx.custom_report.ReportData1} returns this
*/
proto.adx.custom_report.ReportData1.prototype.setSegmentgroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.SegmentGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.SegmentGroup}
 */
proto.adx.custom_report.ReportData1.prototype.addSegmentgroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.SegmentGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReportData1} returns this
 */
proto.adx.custom_report.ReportData1.prototype.clearSegmentgroupsList = function() {
  return this.setSegmentgroupsList([]);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.custom_report.Table>}
 */
proto.adx.custom_report.ReportData1.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.custom_report.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.Table, 3));
};


/**
 * @param {!Array<!proto.adx.custom_report.Table>} value
 * @return {!proto.adx.custom_report.ReportData1} returns this
*/
proto.adx.custom_report.ReportData1.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.custom_report.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.Table}
 */
proto.adx.custom_report.ReportData1.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.custom_report.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReportData1} returns this
 */
proto.adx.custom_report.ReportData1.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.ReportData2.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReportData2.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReportData2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReportData2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData2.toObject = function(includeInstance, msg) {
  var f, obj = {
    segmentgroupsList: jspb.Message.toObjectList(msg.getSegmentgroupsList(),
    proto.adx.custom_report.SegmentGroup.toObject, includeInstance),
    periodsList: jspb.Message.toObjectList(msg.getPeriodsList(),
    proto.adx.custom_report.ReportData2.Periods.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReportData2}
 */
proto.adx.custom_report.ReportData2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReportData2;
  return proto.adx.custom_report.ReportData2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReportData2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReportData2}
 */
proto.adx.custom_report.ReportData2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.SegmentGroup;
      reader.readMessage(value,proto.adx.custom_report.SegmentGroup.deserializeBinaryFromReader);
      msg.addSegmentgroups(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.ReportData2.Periods;
      reader.readMessage(value,proto.adx.custom_report.ReportData2.Periods.deserializeBinaryFromReader);
      msg.addPeriods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReportData2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReportData2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReportData2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegmentgroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.adx.custom_report.SegmentGroup.serializeBinaryToWriter
    );
  }
  f = message.getPeriodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.ReportData2.Periods.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.ReportData2.Periods.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReportData2.Periods.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReportData2.Periods.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReportData2.Periods} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData2.Periods.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.custom_report.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReportData2.Periods}
 */
proto.adx.custom_report.ReportData2.Periods.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReportData2.Periods;
  return proto.adx.custom_report.ReportData2.Periods.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReportData2.Periods} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReportData2.Periods}
 */
proto.adx.custom_report.ReportData2.Periods.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.Table;
      reader.readMessage(value,proto.adx.custom_report.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReportData2.Periods.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReportData2.Periods.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReportData2.Periods} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData2.Periods.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.Table.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.adx.custom_report.ReportData2.Periods.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReportData2.Periods} returns this
 */
proto.adx.custom_report.ReportData2.Periods.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.custom_report.Table>}
 */
proto.adx.custom_report.ReportData2.Periods.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.custom_report.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.Table, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.Table>} value
 * @return {!proto.adx.custom_report.ReportData2.Periods} returns this
*/
proto.adx.custom_report.ReportData2.Periods.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.Table}
 */
proto.adx.custom_report.ReportData2.Periods.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReportData2.Periods} returns this
 */
proto.adx.custom_report.ReportData2.Periods.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * repeated SegmentGroup segmentGroups = 1;
 * @return {!Array<!proto.adx.custom_report.SegmentGroup>}
 */
proto.adx.custom_report.ReportData2.prototype.getSegmentgroupsList = function() {
  return /** @type{!Array<!proto.adx.custom_report.SegmentGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.SegmentGroup, 1));
};


/**
 * @param {!Array<!proto.adx.custom_report.SegmentGroup>} value
 * @return {!proto.adx.custom_report.ReportData2} returns this
*/
proto.adx.custom_report.ReportData2.prototype.setSegmentgroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.adx.custom_report.SegmentGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.SegmentGroup}
 */
proto.adx.custom_report.ReportData2.prototype.addSegmentgroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.adx.custom_report.SegmentGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReportData2} returns this
 */
proto.adx.custom_report.ReportData2.prototype.clearSegmentgroupsList = function() {
  return this.setSegmentgroupsList([]);
};


/**
 * repeated Periods periods = 2;
 * @return {!Array<!proto.adx.custom_report.ReportData2.Periods>}
 */
proto.adx.custom_report.ReportData2.prototype.getPeriodsList = function() {
  return /** @type{!Array<!proto.adx.custom_report.ReportData2.Periods>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.ReportData2.Periods, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.ReportData2.Periods>} value
 * @return {!proto.adx.custom_report.ReportData2} returns this
*/
proto.adx.custom_report.ReportData2.prototype.setPeriodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.ReportData2.Periods=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.ReportData2.Periods}
 */
proto.adx.custom_report.ReportData2.prototype.addPeriods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.ReportData2.Periods, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReportData2} returns this
 */
proto.adx.custom_report.ReportData2.prototype.clearPeriodsList = function() {
  return this.setPeriodsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.ReportData3.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReportData3.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReportData3.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReportData3} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData3.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.adx.custom_report.ReportData3.Segments.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReportData3}
 */
proto.adx.custom_report.ReportData3.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReportData3;
  return proto.adx.custom_report.ReportData3.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReportData3} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReportData3}
 */
proto.adx.custom_report.ReportData3.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.ReportData3.Segments;
      reader.readMessage(value,proto.adx.custom_report.ReportData3.Segments.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReportData3.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReportData3.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReportData3} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData3.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.ReportData3.Segments.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.ReportData3.Segments.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReportData3.Segments.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReportData3.Segments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReportData3.Segments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData3.Segments.toObject = function(includeInstance, msg) {
  var f, obj = {
    segmentgroupsList: jspb.Message.toObjectList(msg.getSegmentgroupsList(),
    proto.adx.custom_report.SegmentGroup.toObject, includeInstance),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.custom_report.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReportData3.Segments}
 */
proto.adx.custom_report.ReportData3.Segments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReportData3.Segments;
  return proto.adx.custom_report.ReportData3.Segments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReportData3.Segments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReportData3.Segments}
 */
proto.adx.custom_report.ReportData3.Segments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.SegmentGroup;
      reader.readMessage(value,proto.adx.custom_report.SegmentGroup.deserializeBinaryFromReader);
      msg.addSegmentgroups(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.Table;
      reader.readMessage(value,proto.adx.custom_report.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReportData3.Segments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReportData3.Segments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReportData3.Segments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReportData3.Segments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegmentgroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.adx.custom_report.SegmentGroup.serializeBinaryToWriter
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.Table.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SegmentGroup segmentGroups = 1;
 * @return {!Array<!proto.adx.custom_report.SegmentGroup>}
 */
proto.adx.custom_report.ReportData3.Segments.prototype.getSegmentgroupsList = function() {
  return /** @type{!Array<!proto.adx.custom_report.SegmentGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.SegmentGroup, 1));
};


/**
 * @param {!Array<!proto.adx.custom_report.SegmentGroup>} value
 * @return {!proto.adx.custom_report.ReportData3.Segments} returns this
*/
proto.adx.custom_report.ReportData3.Segments.prototype.setSegmentgroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.adx.custom_report.SegmentGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.SegmentGroup}
 */
proto.adx.custom_report.ReportData3.Segments.prototype.addSegmentgroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.adx.custom_report.SegmentGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReportData3.Segments} returns this
 */
proto.adx.custom_report.ReportData3.Segments.prototype.clearSegmentgroupsList = function() {
  return this.setSegmentgroupsList([]);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.custom_report.Table>}
 */
proto.adx.custom_report.ReportData3.Segments.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.custom_report.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.Table, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.Table>} value
 * @return {!proto.adx.custom_report.ReportData3.Segments} returns this
*/
proto.adx.custom_report.ReportData3.Segments.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.Table}
 */
proto.adx.custom_report.ReportData3.Segments.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReportData3.Segments} returns this
 */
proto.adx.custom_report.ReportData3.Segments.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.adx.custom_report.ReportData3.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReportData3} returns this
 */
proto.adx.custom_report.ReportData3.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Segments segments = 2;
 * @return {!Array<!proto.adx.custom_report.ReportData3.Segments>}
 */
proto.adx.custom_report.ReportData3.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.adx.custom_report.ReportData3.Segments>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.ReportData3.Segments, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.ReportData3.Segments>} value
 * @return {!proto.adx.custom_report.ReportData3} returns this
*/
proto.adx.custom_report.ReportData3.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.ReportData3.Segments=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.ReportData3.Segments}
 */
proto.adx.custom_report.ReportData3.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.ReportData3.Segments, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReportData3} returns this
 */
proto.adx.custom_report.ReportData3.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.GetCustomReportsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.GetCustomReportsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.GetCustomReportsResponse.TypeCase}
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.GetCustomReportsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.GetCustomReportsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.GetCustomReportsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse}
 */
proto.adx.custom_report.GetCustomReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomReportsResponse;
  return proto.adx.custom_report.GetCustomReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse}
 */
proto.adx.custom_report.GetCustomReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.GetCustomReportsResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.GetCustomReportsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.GetCustomReportsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.repeatedFields_ = [7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetCustomReportsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetCustomReportsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 4, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reporttype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    reportdata1List: jspb.Message.toObjectList(msg.getReportdata1List(),
    proto.adx.custom_report.ReportData1.toObject, includeInstance),
    reportdata2List: jspb.Message.toObjectList(msg.getReportdata2List(),
    proto.adx.custom_report.ReportData2.toObject, includeInstance),
    reportdata3List: jspb.Message.toObjectList(msg.getReportdata3List(),
    proto.adx.custom_report.ReportData3.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetCustomReportsResponse.Result;
  return proto.adx.custom_report.GetCustomReportsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetCustomReportsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReporttype(value);
      break;
    case 7:
      var value = new proto.adx.custom_report.ReportData1;
      reader.readMessage(value,proto.adx.custom_report.ReportData1.deserializeBinaryFromReader);
      msg.addReportdata1(value);
      break;
    case 8:
      var value = new proto.adx.custom_report.ReportData2;
      reader.readMessage(value,proto.adx.custom_report.ReportData2.deserializeBinaryFromReader);
      msg.addReportdata2(value);
      break;
    case 9:
      var value = new proto.adx.custom_report.ReportData3;
      reader.readMessage(value,proto.adx.custom_report.ReportData3.deserializeBinaryFromReader);
      msg.addReportdata3(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetCustomReportsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetCustomReportsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getReportdata1List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.adx.custom_report.ReportData1.serializeBinaryToWriter
    );
  }
  f = message.getReportdata2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.adx.custom_report.ReportData2.serializeBinaryToWriter
    );
  }
  f = message.getReportdata3List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.adx.custom_report.ReportData3.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reportId = 2;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string structure = 4;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.clearStructure = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.hasStructure = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setOrder = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.clearOrder = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 reportType = 6;
 * @return {number}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getReporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setReporttype = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.clearReporttype = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.hasReporttype = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ReportData1 reportData1 = 7;
 * @return {!Array<!proto.adx.custom_report.ReportData1>}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getReportdata1List = function() {
  return /** @type{!Array<!proto.adx.custom_report.ReportData1>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.ReportData1, 7));
};


/**
 * @param {!Array<!proto.adx.custom_report.ReportData1>} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
*/
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setReportdata1List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.adx.custom_report.ReportData1=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.ReportData1}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.addReportdata1 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.adx.custom_report.ReportData1, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.clearReportdata1List = function() {
  return this.setReportdata1List([]);
};


/**
 * repeated ReportData2 reportData2 = 8;
 * @return {!Array<!proto.adx.custom_report.ReportData2>}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getReportdata2List = function() {
  return /** @type{!Array<!proto.adx.custom_report.ReportData2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.ReportData2, 8));
};


/**
 * @param {!Array<!proto.adx.custom_report.ReportData2>} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
*/
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setReportdata2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.adx.custom_report.ReportData2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.ReportData2}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.addReportdata2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.adx.custom_report.ReportData2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.clearReportdata2List = function() {
  return this.setReportdata2List([]);
};


/**
 * repeated ReportData3 reportData3 = 9;
 * @return {!Array<!proto.adx.custom_report.ReportData3>}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getReportdata3List = function() {
  return /** @type{!Array<!proto.adx.custom_report.ReportData3>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.ReportData3, 9));
};


/**
 * @param {!Array<!proto.adx.custom_report.ReportData3>} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
*/
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setReportdata3List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.adx.custom_report.ReportData3=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.ReportData3}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.addReportdata3 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.adx.custom_report.ReportData3, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.clearReportdata3List = function() {
  return this.setReportdata3List([]);
};


/**
 * optional string error = 10;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.setError = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.clearError = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportsResponse.Result.prototype.hasError = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.GetCustomReportsResponse.Result}
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.GetCustomReportsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.GetCustomReportsResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.GetCustomReportsResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse} returns this
*/
proto.adx.custom_report.GetCustomReportsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.GetCustomReportsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetCustomReportsResponse} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetCustomReportsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetCustomReportsResponse} returns this
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetCustomReportsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetCustomReportsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetSegmentNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetSegmentNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetSegmentNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetSegmentNameRequest}
 */
proto.adx.custom_report.GetSegmentNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetSegmentNameRequest;
  return proto.adx.custom_report.GetSegmentNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetSegmentNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetSegmentNameRequest}
 */
proto.adx.custom_report.GetSegmentNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetSegmentNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetSegmentNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetSegmentNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentNameRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentNameRequest} returns this
 */
proto.adx.custom_report.GetSegmentNameRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.GetSegmentNameRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.GetSegmentNameRequest} returns this
 */
proto.adx.custom_report.GetSegmentNameRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.GetSegmentNameResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.GetSegmentNameResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.GetSegmentNameResponse.TypeCase}
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.GetSegmentNameResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.GetSegmentNameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetSegmentNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetSegmentNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.GetSegmentNameResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetSegmentNameResponse}
 */
proto.adx.custom_report.GetSegmentNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetSegmentNameResponse;
  return proto.adx.custom_report.GetSegmentNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetSegmentNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetSegmentNameResponse}
 */
proto.adx.custom_report.GetSegmentNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.GetSegmentNameResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.GetSegmentNameResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetSegmentNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetSegmentNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.GetSegmentNameResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetSegmentNameResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetSegmentNameResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    segmentList: jspb.Message.toObjectList(msg.getSegmentList(),
    proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetSegmentNameResponse.Result;
  return proto.adx.custom_report.GetSegmentNameResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetSegmentNameResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.GetSegmentNameResponse.Result.Segment;
      reader.readMessage(value,proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.deserializeBinaryFromReader);
      msg.addSegment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetSegmentNameResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetSegmentNameResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSegmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetSegmentNameResponse.Result.Segment;
  return proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment} returns this
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment} returns this
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.Segment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result} returns this
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Segment segment = 2;
 * @return {!Array<!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment>}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.prototype.getSegmentList = function() {
  return /** @type{!Array<!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.GetSegmentNameResponse.Result.Segment, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment>} value
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result} returns this
*/
proto.adx.custom_report.GetSegmentNameResponse.Result.prototype.setSegmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result.Segment}
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.prototype.addSegment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.GetSegmentNameResponse.Result.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetSegmentNameResponse.Result} returns this
 */
proto.adx.custom_report.GetSegmentNameResponse.Result.prototype.clearSegmentList = function() {
  return this.setSegmentList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.GetSegmentNameResponse.Result}
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.GetSegmentNameResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.GetSegmentNameResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.GetSegmentNameResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.GetSegmentNameResponse} returns this
*/
proto.adx.custom_report.GetSegmentNameResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.GetSegmentNameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.GetSegmentNameResponse} returns this
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentNameResponse} returns this
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetSegmentNameResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetSegmentNameResponse} returns this
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetSegmentNameResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetSegmentNameResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetSegmentValueRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetSegmentValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetSegmentValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    segmentList: jspb.Message.toObjectList(msg.getSegmentList(),
    proto.adx.custom_report.GetSegmentValueRequest.Segment.toObject, includeInstance),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetSegmentValueRequest}
 */
proto.adx.custom_report.GetSegmentValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetSegmentValueRequest;
  return proto.adx.custom_report.GetSegmentValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetSegmentValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetSegmentValueRequest}
 */
proto.adx.custom_report.GetSegmentValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = new proto.adx.custom_report.GetSegmentValueRequest.Segment;
      reader.readMessage(value,proto.adx.custom_report.GetSegmentValueRequest.Segment.deserializeBinaryFromReader);
      msg.addSegment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetSegmentValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetSegmentValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSegmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.custom_report.GetSegmentValueRequest.Segment.serializeBinaryToWriter
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetSegmentValueRequest.Segment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetSegmentValueRequest.Segment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filtervaluesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetSegmentValueRequest.Segment}
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetSegmentValueRequest.Segment;
  return proto.adx.custom_report.GetSegmentValueRequest.Segment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetSegmentValueRequest.Segment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetSegmentValueRequest.Segment}
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFiltervalues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetSegmentValueRequest.Segment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetSegmentValueRequest.Segment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFiltervaluesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentValueRequest.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentValueRequest.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string filterValues = 3;
 * @return {!Array<string>}
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.getFiltervaluesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.custom_report.GetSegmentValueRequest.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.setFiltervaluesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetSegmentValueRequest.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.addFiltervalues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetSegmentValueRequest.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.Segment.prototype.clearFiltervaluesList = function() {
  return this.setFiltervaluesList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentValueRequest} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.GetSegmentValueRequest} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Segment segment = 3;
 * @return {!Array<!proto.adx.custom_report.GetSegmentValueRequest.Segment>}
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.getSegmentList = function() {
  return /** @type{!Array<!proto.adx.custom_report.GetSegmentValueRequest.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.GetSegmentValueRequest.Segment, 3));
};


/**
 * @param {!Array<!proto.adx.custom_report.GetSegmentValueRequest.Segment>} value
 * @return {!proto.adx.custom_report.GetSegmentValueRequest} returns this
*/
proto.adx.custom_report.GetSegmentValueRequest.prototype.setSegmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.custom_report.GetSegmentValueRequest.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetSegmentValueRequest.Segment}
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.addSegment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.custom_report.GetSegmentValueRequest.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetSegmentValueRequest} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.clearSegmentList = function() {
  return this.setSegmentList([]);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentValueRequest} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentValueRequest} returns this
 */
proto.adx.custom_report.GetSegmentValueRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.GetSegmentValueResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.GetSegmentValueResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.GetSegmentValueResponse.TypeCase}
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.GetSegmentValueResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.GetSegmentValueResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetSegmentValueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetSegmentValueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.GetSegmentValueResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse}
 */
proto.adx.custom_report.GetSegmentValueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetSegmentValueResponse;
  return proto.adx.custom_report.GetSegmentValueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetSegmentValueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse}
 */
proto.adx.custom_report.GetSegmentValueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.GetSegmentValueResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.GetSegmentValueResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetSegmentValueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetSegmentValueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.GetSegmentValueResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetSegmentValueResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetSegmentValueResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    segmentList: jspb.Message.toObjectList(msg.getSegmentList(),
    proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetSegmentValueResponse.Result;
  return proto.adx.custom_report.GetSegmentValueResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetSegmentValueResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.GetSegmentValueResponse.Result.Segment;
      reader.readMessage(value,proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.deserializeBinaryFromReader);
      msg.addSegment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetSegmentValueResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetSegmentValueResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSegmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.GetSegmentValueResponse.Result.Segment;
  return proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string values = 3;
 * @return {!Array<string>}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.Segment.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Segment segment = 2;
 * @return {!Array<!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment>}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.prototype.getSegmentList = function() {
  return /** @type{!Array<!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.GetSegmentValueResponse.Result.Segment, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment>} value
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result} returns this
*/
proto.adx.custom_report.GetSegmentValueResponse.Result.prototype.setSegmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result.Segment}
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.prototype.addSegment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.GetSegmentValueResponse.Result.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse.Result} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.Result.prototype.clearSegmentList = function() {
  return this.setSegmentList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.GetSegmentValueResponse.Result}
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.GetSegmentValueResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.GetSegmentValueResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.GetSegmentValueResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.GetSegmentValueResponse} returns this
*/
proto.adx.custom_report.GetSegmentValueResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.GetSegmentValueResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.GetSegmentValueResponse} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetSegmentValueResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.GetSegmentValueResponse} returns this
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.GetSegmentValueResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.GetSegmentValueResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.RelateCustomReportWithPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reportid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageRequest}
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.RelateCustomReportWithPageRequest;
  return proto.adx.custom_report.RelateCustomReportWithPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageRequest}
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.RelateCustomReportWithPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageRequest} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageRequest} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string pageId = 3;
 * @return {string}
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageRequest} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reportId = 4;
 * @return {string}
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageRequest} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageRequest} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.RelateCustomReportWithPageResponse.TypeCase}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.RelateCustomReportWithPageResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.RelateCustomReportWithPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.RelateCustomReportWithPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.RelateCustomReportWithPageResponse;
  return proto.adx.custom_report.RelateCustomReportWithPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.RelateCustomReportWithPageResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.RelateCustomReportWithPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse.Result}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.RelateCustomReportWithPageResponse.Result;
  return proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse.Result}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse.Result} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pageId = 2;
 * @return {string}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse.Result} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reportId = 3;
 * @return {string}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse.Result} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.Result.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.RelateCustomReportWithPageResponse.Result}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.RelateCustomReportWithPageResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.RelateCustomReportWithPageResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.RelateCustomReportWithPageResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse} returns this
*/
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.RelateCustomReportWithPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.RelateCustomReportWithPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.RelateCustomReportWithPageResponse} returns this
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.RelateCustomReportWithPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.RelateCustomReportWithPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReorderCustomReportPage.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReorderCustomReportPage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReorderCustomReportPage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportPage.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReorderCustomReportPage}
 */
proto.adx.custom_report.ReorderCustomReportPage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReorderCustomReportPage;
  return proto.adx.custom_report.ReorderCustomReportPage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReorderCustomReportPage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReorderCustomReportPage}
 */
proto.adx.custom_report.ReorderCustomReportPage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReorderCustomReportPage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReorderCustomReportPage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReorderCustomReportPage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportPage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string pageId = 1;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReportPage.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReportPage} returns this
 */
proto.adx.custom_report.ReorderCustomReportPage.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.adx.custom_report.ReorderCustomReportPage.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.ReorderCustomReportPage} returns this
 */
proto.adx.custom_report.ReorderCustomReportPage.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReorderCustomReportPagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagesList: jspb.Message.toObjectList(msg.getPagesList(),
    proto.adx.custom_report.ReorderCustomReportPage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesRequest}
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReorderCustomReportPagesRequest;
  return proto.adx.custom_report.ReorderCustomReportPagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesRequest}
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = new proto.adx.custom_report.ReorderCustomReportPage;
      reader.readMessage(value,proto.adx.custom_report.ReorderCustomReportPage.deserializeBinaryFromReader);
      msg.addPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReorderCustomReportPagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.custom_report.ReorderCustomReportPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesRequest} returns this
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesRequest} returns this
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ReorderCustomReportPage pages = 3;
 * @return {!Array<!proto.adx.custom_report.ReorderCustomReportPage>}
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.getPagesList = function() {
  return /** @type{!Array<!proto.adx.custom_report.ReorderCustomReportPage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.ReorderCustomReportPage, 3));
};


/**
 * @param {!Array<!proto.adx.custom_report.ReorderCustomReportPage>} value
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesRequest} returns this
*/
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.setPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.custom_report.ReorderCustomReportPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.ReorderCustomReportPage}
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.addPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.custom_report.ReorderCustomReportPage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesRequest} returns this
 */
proto.adx.custom_report.ReorderCustomReportPagesRequest.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.ReorderCustomReportPagesResponse.TypeCase}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.ReorderCustomReportPagesResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.ReorderCustomReportPagesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReorderCustomReportPagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReorderCustomReportPagesResponse;
  return proto.adx.custom_report.ReorderCustomReportPagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.ReorderCustomReportPagesResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReorderCustomReportPagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagesList: jspb.Message.toObjectList(msg.getPagesList(),
    proto.adx.custom_report.ReorderCustomReportPage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse.Result}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReorderCustomReportPagesResponse.Result;
  return proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse.Result}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.custom_report.ReorderCustomReportPage;
      reader.readMessage(value,proto.adx.custom_report.ReorderCustomReportPage.deserializeBinaryFromReader);
      msg.addPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.custom_report.ReorderCustomReportPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse.Result} returns this
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ReorderCustomReportPage pages = 2;
 * @return {!Array<!proto.adx.custom_report.ReorderCustomReportPage>}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.prototype.getPagesList = function() {
  return /** @type{!Array<!proto.adx.custom_report.ReorderCustomReportPage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.ReorderCustomReportPage, 2));
};


/**
 * @param {!Array<!proto.adx.custom_report.ReorderCustomReportPage>} value
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse.Result} returns this
*/
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.prototype.setPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.custom_report.ReorderCustomReportPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.ReorderCustomReportPage}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.prototype.addPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.custom_report.ReorderCustomReportPage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse.Result} returns this
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.Result.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.ReorderCustomReportPagesResponse.Result}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.ReorderCustomReportPagesResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.ReorderCustomReportPagesResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.ReorderCustomReportPagesResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse} returns this
*/
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.ReorderCustomReportPagesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse} returns this
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse} returns this
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.ReorderCustomReportPagesResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.ReorderCustomReportPagesResponse} returns this
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.ReorderCustomReportPagesResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.ReorderCustomReportPagesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReorderCustomReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReorderCustomReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReorderCustomReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReorderCustomReport}
 */
proto.adx.custom_report.ReorderCustomReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReorderCustomReport;
  return proto.adx.custom_report.ReorderCustomReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReorderCustomReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReorderCustomReport}
 */
proto.adx.custom_report.ReorderCustomReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReorderCustomReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReorderCustomReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReorderCustomReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string reportId = 1;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReport.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReport} returns this
 */
proto.adx.custom_report.ReorderCustomReport.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.adx.custom_report.ReorderCustomReport.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.ReorderCustomReport} returns this
 */
proto.adx.custom_report.ReorderCustomReport.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.ReorderCustomReportsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReorderCustomReportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReorderCustomReportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    proto.adx.custom_report.ReorderCustomReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReorderCustomReportsRequest}
 */
proto.adx.custom_report.ReorderCustomReportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReorderCustomReportsRequest;
  return proto.adx.custom_report.ReorderCustomReportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReorderCustomReportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReorderCustomReportsRequest}
 */
proto.adx.custom_report.ReorderCustomReportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 4:
      var value = new proto.adx.custom_report.ReorderCustomReport;
      reader.readMessage(value,proto.adx.custom_report.ReorderCustomReport.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReorderCustomReportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReorderCustomReportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.custom_report.ReorderCustomReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReportsRequest} returns this
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.ReorderCustomReportsRequest} returns this
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string pageId = 3;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReportsRequest} returns this
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ReorderCustomReport reports = 4;
 * @return {!Array<!proto.adx.custom_report.ReorderCustomReport>}
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.adx.custom_report.ReorderCustomReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.ReorderCustomReport, 4));
};


/**
 * @param {!Array<!proto.adx.custom_report.ReorderCustomReport>} value
 * @return {!proto.adx.custom_report.ReorderCustomReportsRequest} returns this
*/
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.custom_report.ReorderCustomReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.ReorderCustomReport}
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.custom_report.ReorderCustomReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReorderCustomReportsRequest} returns this
 */
proto.adx.custom_report.ReorderCustomReportsRequest.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.ReorderCustomReportsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.ReorderCustomReportsResponse.TypeCase}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.ReorderCustomReportsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.ReorderCustomReportsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReorderCustomReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReorderCustomReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.ReorderCustomReportsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReorderCustomReportsResponse;
  return proto.adx.custom_report.ReorderCustomReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReorderCustomReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.ReorderCustomReportsResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.ReorderCustomReportsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReorderCustomReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReorderCustomReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.ReorderCustomReportsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.ReorderCustomReportsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.ReorderCustomReportsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    proto.adx.custom_report.ReorderCustomReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse.Result}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.ReorderCustomReportsResponse.Result;
  return proto.adx.custom_report.ReorderCustomReportsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.ReorderCustomReportsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse.Result}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 3:
      var value = new proto.adx.custom_report.ReorderCustomReport;
      reader.readMessage(value,proto.adx.custom_report.ReorderCustomReport.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.ReorderCustomReportsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.ReorderCustomReportsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.custom_report.ReorderCustomReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pageId = 2;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ReorderCustomReport reports = 3;
 * @return {!Array<!proto.adx.custom_report.ReorderCustomReport>}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.adx.custom_report.ReorderCustomReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.custom_report.ReorderCustomReport, 3));
};


/**
 * @param {!Array<!proto.adx.custom_report.ReorderCustomReport>} value
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse.Result} returns this
*/
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.custom_report.ReorderCustomReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.custom_report.ReorderCustomReport}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.custom_report.ReorderCustomReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse.Result} returns this
 */
proto.adx.custom_report.ReorderCustomReportsResponse.Result.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.ReorderCustomReportsResponse.Result}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.ReorderCustomReportsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.ReorderCustomReportsResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.ReorderCustomReportsResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse} returns this
*/
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.ReorderCustomReportsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse} returns this
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse} returns this
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.ReorderCustomReportsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.ReorderCustomReportsResponse} returns this
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.ReorderCustomReportsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.ReorderCustomReportsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.UpdateCustomReportPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.UpdateCustomReportPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.UpdateCustomReportPageRequest}
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.UpdateCustomReportPageRequest;
  return proto.adx.custom_report.UpdateCustomReportPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.UpdateCustomReportPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.UpdateCustomReportPageRequest}
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.UpdateCustomReportPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.UpdateCustomReportPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportPageRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pageId = 2;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportPageRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.UpdateCustomReportPageRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportPageRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.UpdateCustomReportPageRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.UpdateCustomReportPageResponse.TypeCase}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.UpdateCustomReportPageResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.UpdateCustomReportPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.UpdateCustomReportPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.UpdateCustomReportPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.UpdateCustomReportPageResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.UpdateCustomReportPageResponse;
  return proto.adx.custom_report.UpdateCustomReportPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.UpdateCustomReportPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.UpdateCustomReportPageResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.UpdateCustomReportPageResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.UpdateCustomReportPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.UpdateCustomReportPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.UpdateCustomReportPageResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.UpdateCustomReportPageResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.UpdateCustomReportPageResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse.Result}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.UpdateCustomReportPageResponse.Result;
  return proto.adx.custom_report.UpdateCustomReportPageResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.UpdateCustomReportPageResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse.Result}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.UpdateCustomReportPageResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.UpdateCustomReportPageResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse.Result} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pageId = 2;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse.Result} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.Result.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.UpdateCustomReportPageResponse.Result}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.UpdateCustomReportPageResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.UpdateCustomReportPageResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.UpdateCustomReportPageResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse} returns this
*/
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.UpdateCustomReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.UpdateCustomReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.UpdateCustomReportPageResponse} returns this
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.UpdateCustomReportPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.UpdateCustomReportPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.UpdateCustomReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.UpdateCustomReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pageid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    order: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.UpdateCustomReportRequest}
 */
proto.adx.custom_report.UpdateCustomReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.UpdateCustomReportRequest;
  return proto.adx.custom_report.UpdateCustomReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.UpdateCustomReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.UpdateCustomReportRequest}
 */
proto.adx.custom_report.UpdateCustomReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.UpdateCustomReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.UpdateCustomReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reportId = 2;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.UpdateCustomReportRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string structure = 5;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string pageId = 6;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 order = 7;
 * @return {number}
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.custom_report.UpdateCustomReportRequest} returns this
 */
proto.adx.custom_report.UpdateCustomReportRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.custom_report.UpdateCustomReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.custom_report.UpdateCustomReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.custom_report.UpdateCustomReportResponse.TypeCase}
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.custom_report.UpdateCustomReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.custom_report.UpdateCustomReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.UpdateCustomReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.UpdateCustomReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.custom_report.UpdateCustomReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse}
 */
proto.adx.custom_report.UpdateCustomReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.UpdateCustomReportResponse;
  return proto.adx.custom_report.UpdateCustomReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.UpdateCustomReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse}
 */
proto.adx.custom_report.UpdateCustomReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.custom_report.UpdateCustomReportResponse.Result;
      reader.readMessage(value,proto.adx.custom_report.UpdateCustomReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.UpdateCustomReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.UpdateCustomReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.custom_report.UpdateCustomReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.custom_report.UpdateCustomReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.custom_report.UpdateCustomReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse.Result}
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.custom_report.UpdateCustomReportResponse.Result;
  return proto.adx.custom_report.UpdateCustomReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.custom_report.UpdateCustomReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse.Result}
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.custom_report.UpdateCustomReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.custom_report.UpdateCustomReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse.Result} returns this
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reportId = 2;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.prototype.getReportid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse.Result} returns this
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.prototype.setReportid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pageId = 3;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.prototype.getPageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse.Result} returns this
 */
proto.adx.custom_report.UpdateCustomReportResponse.Result.prototype.setPageid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.custom_report.UpdateCustomReportResponse.Result}
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.custom_report.UpdateCustomReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.custom_report.UpdateCustomReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.custom_report.UpdateCustomReportResponse.Result|undefined} value
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse} returns this
*/
proto.adx.custom_report.UpdateCustomReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.custom_report.UpdateCustomReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse} returns this
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse} returns this
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.UpdateCustomReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.custom_report.UpdateCustomReportResponse} returns this
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.custom_report.UpdateCustomReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.custom_report.UpdateCustomReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.custom_report);
