// source: information.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.adx.information.CreateInformationRequest', null, global);
goog.exportSymbol('proto.adx.information.CreateInformationResponse', null, global);
goog.exportSymbol('proto.adx.information.CreateInformationResponse.Data', null, global);
goog.exportSymbol('proto.adx.information.CreateInformationResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.CreateInformationResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.information.DeleteInformationRequest', null, global);
goog.exportSymbol('proto.adx.information.DeleteInformationResponse', null, global);
goog.exportSymbol('proto.adx.information.DeleteInformationResponse.Data', null, global);
goog.exportSymbol('proto.adx.information.DeleteInformationResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.DeleteInformationResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.information.GetInformationLatestUpdateRequest', null, global);
goog.exportSymbol('proto.adx.information.GetInformationLatestUpdateResponse', null, global);
goog.exportSymbol('proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate', null, global);
goog.exportSymbol('proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider', null, global);
goog.exportSymbol('proto.adx.information.GetInformationLatestUpdateResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.GetInformationLatestUpdateResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.information.GetInformationNoticesCountRequest', null, global);
goog.exportSymbol('proto.adx.information.GetInformationNoticesCountResponse', null, global);
goog.exportSymbol('proto.adx.information.GetInformationNoticesCountResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.GetInformationNoticesCountResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.information.GetInformationNoticesRequest', null, global);
goog.exportSymbol('proto.adx.information.GetInformationNoticesResponse', null, global);
goog.exportSymbol('proto.adx.information.GetInformationNoticesResponse.Data', null, global);
goog.exportSymbol('proto.adx.information.GetInformationNoticesResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.GetInformationNoticesResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.information.GetInformationRequest', null, global);
goog.exportSymbol('proto.adx.information.GetInformationResponse', null, global);
goog.exportSymbol('proto.adx.information.GetInformationResponse.Data', null, global);
goog.exportSymbol('proto.adx.information.GetInformationResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.GetInformationResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.information.GetInformationSearchRequest', null, global);
goog.exportSymbol('proto.adx.information.GetInformationSearchResponse', null, global);
goog.exportSymbol('proto.adx.information.GetInformationSearchResponse.Data', null, global);
goog.exportSymbol('proto.adx.information.GetInformationSearchResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.GetInformationSearchResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.information.ResetInformationRequest', null, global);
goog.exportSymbol('proto.adx.information.ResetInformationResponse', null, global);
goog.exportSymbol('proto.adx.information.ResetInformationResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.ResetInformationResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.information.UpdateInformationNoticesReadRequest', null, global);
goog.exportSymbol('proto.adx.information.UpdateInformationNoticesReadResponse', null, global);
goog.exportSymbol('proto.adx.information.UpdateInformationNoticesReadResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.UpdateInformationNoticesReadResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.information.UpdateInformationRequest', null, global);
goog.exportSymbol('proto.adx.information.UpdateInformationResponse', null, global);
goog.exportSymbol('proto.adx.information.UpdateInformationResponse.Data', null, global);
goog.exportSymbol('proto.adx.information.UpdateInformationResponse.Result', null, global);
goog.exportSymbol('proto.adx.information.UpdateInformationResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.CreateInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.CreateInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.CreateInformationRequest.displayName = 'proto.adx.information.CreateInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.CreateInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.CreateInformationResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.CreateInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.CreateInformationResponse.displayName = 'proto.adx.information.CreateInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.CreateInformationResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.CreateInformationResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.CreateInformationResponse.Data.displayName = 'proto.adx.information.CreateInformationResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.CreateInformationResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.CreateInformationResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.CreateInformationResponse.Result.displayName = 'proto.adx.information.CreateInformationResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.DeleteInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.DeleteInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.DeleteInformationRequest.displayName = 'proto.adx.information.DeleteInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.DeleteInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.DeleteInformationResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.DeleteInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.DeleteInformationResponse.displayName = 'proto.adx.information.DeleteInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.DeleteInformationResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.DeleteInformationResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.DeleteInformationResponse.Data.displayName = 'proto.adx.information.DeleteInformationResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.DeleteInformationResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.DeleteInformationResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.DeleteInformationResponse.Result.displayName = 'proto.adx.information.DeleteInformationResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationRequest.displayName = 'proto.adx.information.GetInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.GetInformationResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.GetInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationResponse.displayName = 'proto.adx.information.GetInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationResponse.Data.displayName = 'proto.adx.information.GetInformationResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.information.GetInformationResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.information.GetInformationResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationResponse.Result.displayName = 'proto.adx.information.GetInformationResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationSearchRequest.displayName = 'proto.adx.information.GetInformationSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.GetInformationSearchResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.GetInformationSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationSearchResponse.displayName = 'proto.adx.information.GetInformationSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationSearchResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationSearchResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationSearchResponse.Data.displayName = 'proto.adx.information.GetInformationSearchResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationSearchResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.information.GetInformationSearchResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.information.GetInformationSearchResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationSearchResponse.Result.displayName = 'proto.adx.information.GetInformationSearchResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.ResetInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.information.ResetInformationRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.information.ResetInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.ResetInformationRequest.displayName = 'proto.adx.information.ResetInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.ResetInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.ResetInformationResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.ResetInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.ResetInformationResponse.displayName = 'proto.adx.information.ResetInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.ResetInformationResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.ResetInformationResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.ResetInformationResponse.Result.displayName = 'proto.adx.information.ResetInformationResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.UpdateInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.UpdateInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.UpdateInformationRequest.displayName = 'proto.adx.information.UpdateInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.UpdateInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.UpdateInformationResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.UpdateInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.UpdateInformationResponse.displayName = 'proto.adx.information.UpdateInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.UpdateInformationResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.UpdateInformationResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.UpdateInformationResponse.Data.displayName = 'proto.adx.information.UpdateInformationResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.UpdateInformationResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.UpdateInformationResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.UpdateInformationResponse.Result.displayName = 'proto.adx.information.UpdateInformationResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationNoticesCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationNoticesCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationNoticesCountRequest.displayName = 'proto.adx.information.GetInformationNoticesCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationNoticesCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.GetInformationNoticesCountResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.GetInformationNoticesCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationNoticesCountResponse.displayName = 'proto.adx.information.GetInformationNoticesCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationNoticesCountResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationNoticesCountResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationNoticesCountResponse.Result.displayName = 'proto.adx.information.GetInformationNoticesCountResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationNoticesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationNoticesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationNoticesRequest.displayName = 'proto.adx.information.GetInformationNoticesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationNoticesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.GetInformationNoticesResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.GetInformationNoticesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationNoticesResponse.displayName = 'proto.adx.information.GetInformationNoticesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationNoticesResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationNoticesResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationNoticesResponse.Data.displayName = 'proto.adx.information.GetInformationNoticesResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationNoticesResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.information.GetInformationNoticesResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.information.GetInformationNoticesResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationNoticesResponse.Result.displayName = 'proto.adx.information.GetInformationNoticesResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.UpdateInformationNoticesReadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.information.UpdateInformationNoticesReadRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.information.UpdateInformationNoticesReadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.UpdateInformationNoticesReadRequest.displayName = 'proto.adx.information.UpdateInformationNoticesReadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.UpdateInformationNoticesReadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.UpdateInformationNoticesReadResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.UpdateInformationNoticesReadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.UpdateInformationNoticesReadResponse.displayName = 'proto.adx.information.UpdateInformationNoticesReadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.information.UpdateInformationNoticesReadResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.information.UpdateInformationNoticesReadResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.UpdateInformationNoticesReadResponse.Result.displayName = 'proto.adx.information.UpdateInformationNoticesReadResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationLatestUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationLatestUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationLatestUpdateRequest.displayName = 'proto.adx.information.GetInformationLatestUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationLatestUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.information.GetInformationLatestUpdateResponse.oneofGroups_);
};
goog.inherits(proto.adx.information.GetInformationLatestUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationLatestUpdateResponse.displayName = 'proto.adx.information.GetInformationLatestUpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.displayName = 'proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.displayName = 'proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.information.GetInformationLatestUpdateResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.information.GetInformationLatestUpdateResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.information.GetInformationLatestUpdateResponse.Result.displayName = 'proto.adx.information.GetInformationLatestUpdateResponse.Result';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.CreateInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.CreateInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.CreateInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.CreateInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deliveredat: jspb.Message.getFieldWithDefault(msg, 2, ""),
    information: jspb.Message.getFieldWithDefault(msg, 3, ""),
    informationtype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isdisplayadx: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    log: jspb.Message.getFieldWithDefault(msg, 6, ""),
    progress: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.CreateInformationRequest}
 */
proto.adx.information.CreateInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.CreateInformationRequest;
  return proto.adx.information.CreateInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.CreateInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.CreateInformationRequest}
 */
proto.adx.information.CreateInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveredat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformationtype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdisplayadx(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLog(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.CreateInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.CreateInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.CreateInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.CreateInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeliveredat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInformation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInformationtype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsdisplayadx();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLog();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.CreateInformationRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationRequest} returns this
 */
proto.adx.information.CreateInformationRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deliveredAt = 2;
 * @return {string}
 */
proto.adx.information.CreateInformationRequest.prototype.getDeliveredat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationRequest} returns this
 */
proto.adx.information.CreateInformationRequest.prototype.setDeliveredat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string information = 3;
 * @return {string}
 */
proto.adx.information.CreateInformationRequest.prototype.getInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationRequest} returns this
 */
proto.adx.information.CreateInformationRequest.prototype.setInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string informationType = 4;
 * @return {string}
 */
proto.adx.information.CreateInformationRequest.prototype.getInformationtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationRequest} returns this
 */
proto.adx.information.CreateInformationRequest.prototype.setInformationtype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isDisplayAdx = 5;
 * @return {boolean}
 */
proto.adx.information.CreateInformationRequest.prototype.getIsdisplayadx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.information.CreateInformationRequest} returns this
 */
proto.adx.information.CreateInformationRequest.prototype.setIsdisplayadx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string log = 6;
 * @return {string}
 */
proto.adx.information.CreateInformationRequest.prototype.getLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationRequest} returns this
 */
proto.adx.information.CreateInformationRequest.prototype.setLog = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 progress = 7;
 * @return {number}
 */
proto.adx.information.CreateInformationRequest.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.CreateInformationRequest} returns this
 */
proto.adx.information.CreateInformationRequest.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.information.CreateInformationRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.CreateInformationRequest} returns this
 */
proto.adx.information.CreateInformationRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 adProviderId = 9;
 * @return {number}
 */
proto.adx.information.CreateInformationRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.CreateInformationRequest} returns this
 */
proto.adx.information.CreateInformationRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.CreateInformationResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.CreateInformationResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.CreateInformationResponse.TypeCase}
 */
proto.adx.information.CreateInformationResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.CreateInformationResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.CreateInformationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.CreateInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.CreateInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.CreateInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.CreateInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.CreateInformationResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.CreateInformationResponse}
 */
proto.adx.information.CreateInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.CreateInformationResponse;
  return proto.adx.information.CreateInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.CreateInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.CreateInformationResponse}
 */
proto.adx.information.CreateInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.CreateInformationResponse.Result;
      reader.readMessage(value,proto.adx.information.CreateInformationResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.CreateInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.CreateInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.CreateInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.CreateInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.CreateInformationResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.CreateInformationResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.CreateInformationResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.CreateInformationResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deliveredat: jspb.Message.getFieldWithDefault(msg, 2, ""),
    information: jspb.Message.getFieldWithDefault(msg, 3, ""),
    informationtype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isdisplayadx: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    log: jspb.Message.getFieldWithDefault(msg, 6, ""),
    progress: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.CreateInformationResponse.Data}
 */
proto.adx.information.CreateInformationResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.CreateInformationResponse.Data;
  return proto.adx.information.CreateInformationResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.CreateInformationResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.CreateInformationResponse.Data}
 */
proto.adx.information.CreateInformationResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveredat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformationtype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdisplayadx(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLog(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.CreateInformationResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.CreateInformationResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.CreateInformationResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeliveredat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInformation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInformationtype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsdisplayadx();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLog();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationResponse.Data} returns this
 */
proto.adx.information.CreateInformationResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deliveredAt = 2;
 * @return {string}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.getDeliveredat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationResponse.Data} returns this
 */
proto.adx.information.CreateInformationResponse.Data.prototype.setDeliveredat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string information = 3;
 * @return {string}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.getInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationResponse.Data} returns this
 */
proto.adx.information.CreateInformationResponse.Data.prototype.setInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string informationType = 4;
 * @return {string}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.getInformationtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationResponse.Data} returns this
 */
proto.adx.information.CreateInformationResponse.Data.prototype.setInformationtype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isDisplayAdx = 5;
 * @return {boolean}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.getIsdisplayadx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.information.CreateInformationResponse.Data} returns this
 */
proto.adx.information.CreateInformationResponse.Data.prototype.setIsdisplayadx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string log = 6;
 * @return {string}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.getLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationResponse.Data} returns this
 */
proto.adx.information.CreateInformationResponse.Data.prototype.setLog = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 progress = 7;
 * @return {number}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.CreateInformationResponse.Data} returns this
 */
proto.adx.information.CreateInformationResponse.Data.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.CreateInformationResponse.Data} returns this
 */
proto.adx.information.CreateInformationResponse.Data.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 adProviderId = 9;
 * @return {number}
 */
proto.adx.information.CreateInformationResponse.Data.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.CreateInformationResponse.Data} returns this
 */
proto.adx.information.CreateInformationResponse.Data.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.CreateInformationResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.CreateInformationResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.CreateInformationResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.CreateInformationResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.adx.information.CreateInformationResponse.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.CreateInformationResponse.Result}
 */
proto.adx.information.CreateInformationResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.CreateInformationResponse.Result;
  return proto.adx.information.CreateInformationResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.CreateInformationResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.CreateInformationResponse.Result}
 */
proto.adx.information.CreateInformationResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.information.CreateInformationResponse.Data;
      reader.readMessage(value,proto.adx.information.CreateInformationResponse.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.CreateInformationResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.CreateInformationResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.CreateInformationResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.CreateInformationResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.information.CreateInformationResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.CreateInformationResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationResponse.Result} returns this
 */
proto.adx.information.CreateInformationResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Data data = 2;
 * @return {?proto.adx.information.CreateInformationResponse.Data}
 */
proto.adx.information.CreateInformationResponse.Result.prototype.getData = function() {
  return /** @type{?proto.adx.information.CreateInformationResponse.Data} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.CreateInformationResponse.Data, 2));
};


/**
 * @param {?proto.adx.information.CreateInformationResponse.Data|undefined} value
 * @return {!proto.adx.information.CreateInformationResponse.Result} returns this
*/
proto.adx.information.CreateInformationResponse.Result.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.CreateInformationResponse.Result} returns this
 */
proto.adx.information.CreateInformationResponse.Result.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.CreateInformationResponse.Result.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.CreateInformationResponse.Result}
 */
proto.adx.information.CreateInformationResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.CreateInformationResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.CreateInformationResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.CreateInformationResponse.Result|undefined} value
 * @return {!proto.adx.information.CreateInformationResponse} returns this
*/
proto.adx.information.CreateInformationResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.CreateInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.CreateInformationResponse} returns this
 */
proto.adx.information.CreateInformationResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.CreateInformationResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.CreateInformationResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.CreateInformationResponse} returns this
 */
proto.adx.information.CreateInformationResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.CreateInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.CreateInformationResponse} returns this
 */
proto.adx.information.CreateInformationResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.CreateInformationResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.CreateInformationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.DeleteInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.DeleteInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.DeleteInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.DeleteInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.DeleteInformationRequest}
 */
proto.adx.information.DeleteInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.DeleteInformationRequest;
  return proto.adx.information.DeleteInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.DeleteInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.DeleteInformationRequest}
 */
proto.adx.information.DeleteInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.DeleteInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.DeleteInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.DeleteInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.DeleteInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.DeleteInformationRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.DeleteInformationRequest} returns this
 */
proto.adx.information.DeleteInformationRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.adx.information.DeleteInformationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.DeleteInformationRequest} returns this
 */
proto.adx.information.DeleteInformationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.DeleteInformationResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.DeleteInformationResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.DeleteInformationResponse.TypeCase}
 */
proto.adx.information.DeleteInformationResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.DeleteInformationResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.DeleteInformationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.DeleteInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.DeleteInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.DeleteInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.DeleteInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.DeleteInformationResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.DeleteInformationResponse}
 */
proto.adx.information.DeleteInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.DeleteInformationResponse;
  return proto.adx.information.DeleteInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.DeleteInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.DeleteInformationResponse}
 */
proto.adx.information.DeleteInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.DeleteInformationResponse.Result;
      reader.readMessage(value,proto.adx.information.DeleteInformationResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.DeleteInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.DeleteInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.DeleteInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.DeleteInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.DeleteInformationResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.DeleteInformationResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.DeleteInformationResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.DeleteInformationResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.DeleteInformationResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.DeleteInformationResponse.Data}
 */
proto.adx.information.DeleteInformationResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.DeleteInformationResponse.Data;
  return proto.adx.information.DeleteInformationResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.DeleteInformationResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.DeleteInformationResponse.Data}
 */
proto.adx.information.DeleteInformationResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.DeleteInformationResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.DeleteInformationResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.DeleteInformationResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.DeleteInformationResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.information.DeleteInformationResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.DeleteInformationResponse.Data} returns this
 */
proto.adx.information.DeleteInformationResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.DeleteInformationResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.DeleteInformationResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.DeleteInformationResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.DeleteInformationResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.adx.information.DeleteInformationResponse.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.DeleteInformationResponse.Result}
 */
proto.adx.information.DeleteInformationResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.DeleteInformationResponse.Result;
  return proto.adx.information.DeleteInformationResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.DeleteInformationResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.DeleteInformationResponse.Result}
 */
proto.adx.information.DeleteInformationResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.information.DeleteInformationResponse.Data;
      reader.readMessage(value,proto.adx.information.DeleteInformationResponse.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.DeleteInformationResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.DeleteInformationResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.DeleteInformationResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.DeleteInformationResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.information.DeleteInformationResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.DeleteInformationResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.DeleteInformationResponse.Result} returns this
 */
proto.adx.information.DeleteInformationResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Data data = 2;
 * @return {?proto.adx.information.DeleteInformationResponse.Data}
 */
proto.adx.information.DeleteInformationResponse.Result.prototype.getData = function() {
  return /** @type{?proto.adx.information.DeleteInformationResponse.Data} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.DeleteInformationResponse.Data, 2));
};


/**
 * @param {?proto.adx.information.DeleteInformationResponse.Data|undefined} value
 * @return {!proto.adx.information.DeleteInformationResponse.Result} returns this
*/
proto.adx.information.DeleteInformationResponse.Result.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.DeleteInformationResponse.Result} returns this
 */
proto.adx.information.DeleteInformationResponse.Result.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.DeleteInformationResponse.Result.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.DeleteInformationResponse.Result}
 */
proto.adx.information.DeleteInformationResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.DeleteInformationResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.DeleteInformationResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.DeleteInformationResponse.Result|undefined} value
 * @return {!proto.adx.information.DeleteInformationResponse} returns this
*/
proto.adx.information.DeleteInformationResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.DeleteInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.DeleteInformationResponse} returns this
 */
proto.adx.information.DeleteInformationResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.DeleteInformationResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.DeleteInformationResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.DeleteInformationResponse} returns this
 */
proto.adx.information.DeleteInformationResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.DeleteInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.DeleteInformationResponse} returns this
 */
proto.adx.information.DeleteInformationResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.DeleteInformationResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.DeleteInformationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isdisplayadx: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationRequest}
 */
proto.adx.information.GetInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationRequest;
  return proto.adx.information.GetInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationRequest}
 */
proto.adx.information.GetInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdisplayadx(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSort();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsdisplayadx();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationRequest} returns this
 */
proto.adx.information.GetInformationRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 size = 2;
 * @return {number}
 */
proto.adx.information.GetInformationRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationRequest} returns this
 */
proto.adx.information.GetInformationRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.adx.information.GetInformationRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationRequest} returns this
 */
proto.adx.information.GetInformationRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sort = 4;
 * @return {string}
 */
proto.adx.information.GetInformationRequest.prototype.getSort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationRequest} returns this
 */
proto.adx.information.GetInformationRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isDisplayAdx = 5;
 * @return {boolean}
 */
proto.adx.information.GetInformationRequest.prototype.getIsdisplayadx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.information.GetInformationRequest} returns this
 */
proto.adx.information.GetInformationRequest.prototype.setIsdisplayadx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 advertizerId = 6;
 * @return {number}
 */
proto.adx.information.GetInformationRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationRequest} returns this
 */
proto.adx.information.GetInformationRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.GetInformationResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.GetInformationResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.GetInformationResponse.TypeCase}
 */
proto.adx.information.GetInformationResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.GetInformationResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.GetInformationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.GetInformationResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationResponse}
 */
proto.adx.information.GetInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationResponse;
  return proto.adx.information.GetInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationResponse}
 */
proto.adx.information.GetInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.GetInformationResponse.Result;
      reader.readMessage(value,proto.adx.information.GetInformationResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.GetInformationResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deliveredat: jspb.Message.getFieldWithDefault(msg, 2, ""),
    information: jspb.Message.getFieldWithDefault(msg, 3, ""),
    informationtype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isdisplayadx: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    log: jspb.Message.getFieldWithDefault(msg, 6, ""),
    progress: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    advertizername: jspb.Message.getFieldWithDefault(msg, 9, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    adproviderforshort: jspb.Message.getFieldWithDefault(msg, 11, ""),
    adprovidername: jspb.Message.getFieldWithDefault(msg, 12, ""),
    logoimgpath: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationResponse.Data}
 */
proto.adx.information.GetInformationResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationResponse.Data;
  return proto.adx.information.GetInformationResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationResponse.Data}
 */
proto.adx.information.GetInformationResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveredat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformationtype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdisplayadx(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLog(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertizername(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdproviderforshort(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdprovidername(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoimgpath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeliveredat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInformation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInformationtype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsdisplayadx();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLog();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAdvertizername();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAdproviderforshort();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAdprovidername();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLogoimgpath();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deliveredAt = 2;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getDeliveredat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setDeliveredat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string information = 3;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string informationType = 4;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getInformationtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setInformationtype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isDisplayAdx = 5;
 * @return {boolean}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getIsdisplayadx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setIsdisplayadx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string log = 6;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setLog = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 progress = 7;
 * @return {number}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string advertizerName = 9;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getAdvertizername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setAdvertizername = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 adProviderId = 10;
 * @return {number}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string adProviderForShort = 11;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getAdproviderforshort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setAdproviderforshort = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string adProviderName = 12;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getAdprovidername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setAdprovidername = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string logoImgPath = 13;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Data.prototype.getLogoimgpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Data} returns this
 */
proto.adx.information.GetInformationResponse.Data.prototype.setLogoimgpath = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.information.GetInformationResponse.Result.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    found: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentpage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastpage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.adx.information.GetInformationResponse.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationResponse.Result}
 */
proto.adx.information.GetInformationResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationResponse.Result;
  return proto.adx.information.GetInformationResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationResponse.Result}
 */
proto.adx.information.GetInformationResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFound(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentpage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastpage(value);
      break;
    case 5:
      var value = new proto.adx.information.GetInformationResponse.Data;
      reader.readMessage(value,proto.adx.information.GetInformationResponse.Data.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFound();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCurrentpage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastpage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.information.GetInformationResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse.Result} returns this
 */
proto.adx.information.GetInformationResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 found = 2;
 * @return {number}
 */
proto.adx.information.GetInformationResponse.Result.prototype.getFound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationResponse.Result} returns this
 */
proto.adx.information.GetInformationResponse.Result.prototype.setFound = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 currentPage = 3;
 * @return {number}
 */
proto.adx.information.GetInformationResponse.Result.prototype.getCurrentpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationResponse.Result} returns this
 */
proto.adx.information.GetInformationResponse.Result.prototype.setCurrentpage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 lastPage = 4;
 * @return {number}
 */
proto.adx.information.GetInformationResponse.Result.prototype.getLastpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationResponse.Result} returns this
 */
proto.adx.information.GetInformationResponse.Result.prototype.setLastpage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Data data = 5;
 * @return {!Array<!proto.adx.information.GetInformationResponse.Data>}
 */
proto.adx.information.GetInformationResponse.Result.prototype.getDataList = function() {
  return /** @type{!Array<!proto.adx.information.GetInformationResponse.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.information.GetInformationResponse.Data, 5));
};


/**
 * @param {!Array<!proto.adx.information.GetInformationResponse.Data>} value
 * @return {!proto.adx.information.GetInformationResponse.Result} returns this
*/
proto.adx.information.GetInformationResponse.Result.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.information.GetInformationResponse.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.information.GetInformationResponse.Data}
 */
proto.adx.information.GetInformationResponse.Result.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.information.GetInformationResponse.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.information.GetInformationResponse.Result} returns this
 */
proto.adx.information.GetInformationResponse.Result.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.GetInformationResponse.Result}
 */
proto.adx.information.GetInformationResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.GetInformationResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.GetInformationResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.GetInformationResponse.Result|undefined} value
 * @return {!proto.adx.information.GetInformationResponse} returns this
*/
proto.adx.information.GetInformationResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.GetInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.GetInformationResponse} returns this
 */
proto.adx.information.GetInformationResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.GetInformationResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationResponse} returns this
 */
proto.adx.information.GetInformationResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.GetInformationResponse} returns this
 */
proto.adx.information.GetInformationResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isdisplayadx: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    searchfrom: jspb.Message.getFieldWithDefault(msg, 8, ""),
    searchto: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationSearchRequest}
 */
proto.adx.information.GetInformationSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationSearchRequest;
  return proto.adx.information.GetInformationSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationSearchRequest}
 */
proto.adx.information.GetInformationSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdisplayadx(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchfrom(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSort();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsdisplayadx();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSearchfrom();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSearchto();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationSearchRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchRequest} returns this
 */
proto.adx.information.GetInformationSearchRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 size = 2;
 * @return {number}
 */
proto.adx.information.GetInformationSearchRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchRequest} returns this
 */
proto.adx.information.GetInformationSearchRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.adx.information.GetInformationSearchRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchRequest} returns this
 */
proto.adx.information.GetInformationSearchRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sort = 4;
 * @return {string}
 */
proto.adx.information.GetInformationSearchRequest.prototype.getSort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchRequest} returns this
 */
proto.adx.information.GetInformationSearchRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isDisplayAdx = 5;
 * @return {boolean}
 */
proto.adx.information.GetInformationSearchRequest.prototype.getIsdisplayadx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.information.GetInformationSearchRequest} returns this
 */
proto.adx.information.GetInformationSearchRequest.prototype.setIsdisplayadx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 advertizerId = 6;
 * @return {number}
 */
proto.adx.information.GetInformationSearchRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchRequest} returns this
 */
proto.adx.information.GetInformationSearchRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 adProviderId = 7;
 * @return {number}
 */
proto.adx.information.GetInformationSearchRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchRequest} returns this
 */
proto.adx.information.GetInformationSearchRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string searchFrom = 8;
 * @return {string}
 */
proto.adx.information.GetInformationSearchRequest.prototype.getSearchfrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchRequest} returns this
 */
proto.adx.information.GetInformationSearchRequest.prototype.setSearchfrom = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string searchTo = 9;
 * @return {string}
 */
proto.adx.information.GetInformationSearchRequest.prototype.getSearchto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchRequest} returns this
 */
proto.adx.information.GetInformationSearchRequest.prototype.setSearchto = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.GetInformationSearchResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.GetInformationSearchResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.GetInformationSearchResponse.TypeCase}
 */
proto.adx.information.GetInformationSearchResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.GetInformationSearchResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.GetInformationSearchResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.GetInformationSearchResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationSearchResponse}
 */
proto.adx.information.GetInformationSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationSearchResponse;
  return proto.adx.information.GetInformationSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationSearchResponse}
 */
proto.adx.information.GetInformationSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.GetInformationSearchResponse.Result;
      reader.readMessage(value,proto.adx.information.GetInformationSearchResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.GetInformationSearchResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationSearchResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationSearchResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationSearchResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deliveredat: jspb.Message.getFieldWithDefault(msg, 2, ""),
    information: jspb.Message.getFieldWithDefault(msg, 3, ""),
    informationtype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isdisplayadx: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    log: jspb.Message.getFieldWithDefault(msg, 6, ""),
    progress: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    advertizername: jspb.Message.getFieldWithDefault(msg, 9, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    adproviderforshort: jspb.Message.getFieldWithDefault(msg, 11, ""),
    adprovidername: jspb.Message.getFieldWithDefault(msg, 12, ""),
    logoimgpath: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationSearchResponse.Data}
 */
proto.adx.information.GetInformationSearchResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationSearchResponse.Data;
  return proto.adx.information.GetInformationSearchResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationSearchResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationSearchResponse.Data}
 */
proto.adx.information.GetInformationSearchResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveredat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformationtype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdisplayadx(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLog(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdvertizername(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdproviderforshort(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdprovidername(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoimgpath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationSearchResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationSearchResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationSearchResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeliveredat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInformation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInformationtype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsdisplayadx();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLog();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAdvertizername();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAdproviderforshort();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAdprovidername();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLogoimgpath();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deliveredAt = 2;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getDeliveredat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setDeliveredat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string information = 3;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string informationType = 4;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getInformationtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setInformationtype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isDisplayAdx = 5;
 * @return {boolean}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getIsdisplayadx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setIsdisplayadx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string log = 6;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setLog = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 progress = 7;
 * @return {number}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string advertizerName = 9;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getAdvertizername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setAdvertizername = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 adProviderId = 10;
 * @return {number}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string adProviderForShort = 11;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getAdproviderforshort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setAdproviderforshort = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string adProviderName = 12;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getAdprovidername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setAdprovidername = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string logoImgPath = 13;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.getLogoimgpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Data} returns this
 */
proto.adx.information.GetInformationSearchResponse.Data.prototype.setLogoimgpath = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.information.GetInformationSearchResponse.Result.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationSearchResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationSearchResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationSearchResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    found: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentpage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastpage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.adx.information.GetInformationSearchResponse.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationSearchResponse.Result}
 */
proto.adx.information.GetInformationSearchResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationSearchResponse.Result;
  return proto.adx.information.GetInformationSearchResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationSearchResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationSearchResponse.Result}
 */
proto.adx.information.GetInformationSearchResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFound(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentpage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastpage(value);
      break;
    case 5:
      var value = new proto.adx.information.GetInformationSearchResponse.Data;
      reader.readMessage(value,proto.adx.information.GetInformationSearchResponse.Data.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationSearchResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationSearchResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationSearchResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFound();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCurrentpage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastpage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.information.GetInformationSearchResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Result} returns this
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 found = 2;
 * @return {number}
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.getFound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Result} returns this
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.setFound = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 currentPage = 3;
 * @return {number}
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.getCurrentpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Result} returns this
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.setCurrentpage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 lastPage = 4;
 * @return {number}
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.getLastpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Result} returns this
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.setLastpage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Data data = 5;
 * @return {!Array<!proto.adx.information.GetInformationSearchResponse.Data>}
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.getDataList = function() {
  return /** @type{!Array<!proto.adx.information.GetInformationSearchResponse.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.information.GetInformationSearchResponse.Data, 5));
};


/**
 * @param {!Array<!proto.adx.information.GetInformationSearchResponse.Data>} value
 * @return {!proto.adx.information.GetInformationSearchResponse.Result} returns this
*/
proto.adx.information.GetInformationSearchResponse.Result.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.information.GetInformationSearchResponse.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.information.GetInformationSearchResponse.Data}
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.information.GetInformationSearchResponse.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.information.GetInformationSearchResponse.Result} returns this
 */
proto.adx.information.GetInformationSearchResponse.Result.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.GetInformationSearchResponse.Result}
 */
proto.adx.information.GetInformationSearchResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.GetInformationSearchResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.GetInformationSearchResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.GetInformationSearchResponse.Result|undefined} value
 * @return {!proto.adx.information.GetInformationSearchResponse} returns this
*/
proto.adx.information.GetInformationSearchResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.GetInformationSearchResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.GetInformationSearchResponse} returns this
 */
proto.adx.information.GetInformationSearchResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationSearchResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.GetInformationSearchResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationSearchResponse} returns this
 */
proto.adx.information.GetInformationSearchResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationSearchResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.GetInformationSearchResponse} returns this
 */
proto.adx.information.GetInformationSearchResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationSearchResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationSearchResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.information.ResetInformationRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.ResetInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.ResetInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.ResetInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.ResetInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    providersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    progress: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.ResetInformationRequest}
 */
proto.adx.information.ResetInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.ResetInformationRequest;
  return proto.adx.information.ResetInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.ResetInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.ResetInformationRequest}
 */
proto.adx.information.ResetInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdvertizers(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviders(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.ResetInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.ResetInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.ResetInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.ResetInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizersList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.ResetInformationRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.ResetInformationRequest} returns this
 */
proto.adx.information.ResetInformationRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int32 advertizers = 2;
 * @return {!Array<number>}
 */
proto.adx.information.ResetInformationRequest.prototype.getAdvertizersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.adx.information.ResetInformationRequest} returns this
 */
proto.adx.information.ResetInformationRequest.prototype.setAdvertizersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.adx.information.ResetInformationRequest} returns this
 */
proto.adx.information.ResetInformationRequest.prototype.addAdvertizers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.information.ResetInformationRequest} returns this
 */
proto.adx.information.ResetInformationRequest.prototype.clearAdvertizersList = function() {
  return this.setAdvertizersList([]);
};


/**
 * repeated int32 providers = 3;
 * @return {!Array<number>}
 */
proto.adx.information.ResetInformationRequest.prototype.getProvidersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.adx.information.ResetInformationRequest} returns this
 */
proto.adx.information.ResetInformationRequest.prototype.setProvidersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.adx.information.ResetInformationRequest} returns this
 */
proto.adx.information.ResetInformationRequest.prototype.addProviders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.information.ResetInformationRequest} returns this
 */
proto.adx.information.ResetInformationRequest.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * optional int32 progress = 4;
 * @return {number}
 */
proto.adx.information.ResetInformationRequest.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.ResetInformationRequest} returns this
 */
proto.adx.information.ResetInformationRequest.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.ResetInformationResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.ResetInformationResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.ResetInformationResponse.TypeCase}
 */
proto.adx.information.ResetInformationResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.ResetInformationResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.ResetInformationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.ResetInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.ResetInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.ResetInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.ResetInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.ResetInformationResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.ResetInformationResponse}
 */
proto.adx.information.ResetInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.ResetInformationResponse;
  return proto.adx.information.ResetInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.ResetInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.ResetInformationResponse}
 */
proto.adx.information.ResetInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.ResetInformationResponse.Result;
      reader.readMessage(value,proto.adx.information.ResetInformationResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.ResetInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.ResetInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.ResetInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.ResetInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.ResetInformationResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.ResetInformationResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.ResetInformationResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.ResetInformationResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.ResetInformationResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.ResetInformationResponse.Result}
 */
proto.adx.information.ResetInformationResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.ResetInformationResponse.Result;
  return proto.adx.information.ResetInformationResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.ResetInformationResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.ResetInformationResponse.Result}
 */
proto.adx.information.ResetInformationResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.ResetInformationResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.ResetInformationResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.ResetInformationResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.ResetInformationResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.ResetInformationResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.ResetInformationResponse.Result} returns this
 */
proto.adx.information.ResetInformationResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.ResetInformationResponse.Result}
 */
proto.adx.information.ResetInformationResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.ResetInformationResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.ResetInformationResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.ResetInformationResponse.Result|undefined} value
 * @return {!proto.adx.information.ResetInformationResponse} returns this
*/
proto.adx.information.ResetInformationResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.ResetInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.ResetInformationResponse} returns this
 */
proto.adx.information.ResetInformationResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.ResetInformationResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.ResetInformationResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.ResetInformationResponse} returns this
 */
proto.adx.information.ResetInformationResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.ResetInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.ResetInformationResponse} returns this
 */
proto.adx.information.ResetInformationResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.ResetInformationResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.ResetInformationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.UpdateInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.UpdateInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.UpdateInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deliveredat: jspb.Message.getFieldWithDefault(msg, 3, ""),
    information: jspb.Message.getFieldWithDefault(msg, 4, ""),
    informationtype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isdisplayadx: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    log: jspb.Message.getFieldWithDefault(msg, 7, ""),
    progress: jspb.Message.getFieldWithDefault(msg, 8, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.UpdateInformationRequest}
 */
proto.adx.information.UpdateInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.UpdateInformationRequest;
  return proto.adx.information.UpdateInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.UpdateInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.UpdateInformationRequest}
 */
proto.adx.information.UpdateInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveredat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformationtype(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdisplayadx(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLog(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.UpdateInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.UpdateInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.UpdateInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeliveredat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInformation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInformationtype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsdisplayadx();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLog();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.UpdateInformationRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.adx.information.UpdateInformationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deliveredAt = 3;
 * @return {string}
 */
proto.adx.information.UpdateInformationRequest.prototype.getDeliveredat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setDeliveredat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string information = 4;
 * @return {string}
 */
proto.adx.information.UpdateInformationRequest.prototype.getInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string informationType = 5;
 * @return {string}
 */
proto.adx.information.UpdateInformationRequest.prototype.getInformationtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setInformationtype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool isDisplayAdX = 6;
 * @return {boolean}
 */
proto.adx.information.UpdateInformationRequest.prototype.getIsdisplayadx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setIsdisplayadx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string log = 7;
 * @return {string}
 */
proto.adx.information.UpdateInformationRequest.prototype.getLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setLog = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 progress = 8;
 * @return {number}
 */
proto.adx.information.UpdateInformationRequest.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 advertizerId = 9;
 * @return {number}
 */
proto.adx.information.UpdateInformationRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 adProviderId = 10;
 * @return {number}
 */
proto.adx.information.UpdateInformationRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.UpdateInformationRequest} returns this
 */
proto.adx.information.UpdateInformationRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.UpdateInformationResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.UpdateInformationResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.UpdateInformationResponse.TypeCase}
 */
proto.adx.information.UpdateInformationResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.UpdateInformationResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.UpdateInformationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.UpdateInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.UpdateInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.UpdateInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.UpdateInformationResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.UpdateInformationResponse}
 */
proto.adx.information.UpdateInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.UpdateInformationResponse;
  return proto.adx.information.UpdateInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.UpdateInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.UpdateInformationResponse}
 */
proto.adx.information.UpdateInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.UpdateInformationResponse.Result;
      reader.readMessage(value,proto.adx.information.UpdateInformationResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.UpdateInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.UpdateInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.UpdateInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.UpdateInformationResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.UpdateInformationResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.UpdateInformationResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deliveredat: jspb.Message.getFieldWithDefault(msg, 2, ""),
    information: jspb.Message.getFieldWithDefault(msg, 3, ""),
    informationtype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isdisplayadx: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    log: jspb.Message.getFieldWithDefault(msg, 6, ""),
    progress: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.UpdateInformationResponse.Data}
 */
proto.adx.information.UpdateInformationResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.UpdateInformationResponse.Data;
  return proto.adx.information.UpdateInformationResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.UpdateInformationResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.UpdateInformationResponse.Data}
 */
proto.adx.information.UpdateInformationResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveredat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformationtype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdisplayadx(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLog(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.UpdateInformationResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.UpdateInformationResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeliveredat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInformation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInformationtype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsdisplayadx();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLog();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationResponse.Data} returns this
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deliveredAt = 2;
 * @return {string}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.getDeliveredat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationResponse.Data} returns this
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.setDeliveredat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string information = 3;
 * @return {string}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.getInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationResponse.Data} returns this
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.setInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string informationType = 4;
 * @return {string}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.getInformationtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationResponse.Data} returns this
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.setInformationtype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isDisplayAdx = 5;
 * @return {boolean}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.getIsdisplayadx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.information.UpdateInformationResponse.Data} returns this
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.setIsdisplayadx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string log = 6;
 * @return {string}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.getLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationResponse.Data} returns this
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.setLog = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 progress = 7;
 * @return {number}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.UpdateInformationResponse.Data} returns this
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.UpdateInformationResponse.Data} returns this
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 adProviderId = 9;
 * @return {number}
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.UpdateInformationResponse.Data} returns this
 */
proto.adx.information.UpdateInformationResponse.Data.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.UpdateInformationResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.UpdateInformationResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.UpdateInformationResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.adx.information.UpdateInformationResponse.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.UpdateInformationResponse.Result}
 */
proto.adx.information.UpdateInformationResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.UpdateInformationResponse.Result;
  return proto.adx.information.UpdateInformationResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.UpdateInformationResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.UpdateInformationResponse.Result}
 */
proto.adx.information.UpdateInformationResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.information.UpdateInformationResponse.Data;
      reader.readMessage(value,proto.adx.information.UpdateInformationResponse.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.UpdateInformationResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.UpdateInformationResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.UpdateInformationResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.information.UpdateInformationResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.UpdateInformationResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationResponse.Result} returns this
 */
proto.adx.information.UpdateInformationResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Data data = 2;
 * @return {?proto.adx.information.UpdateInformationResponse.Data}
 */
proto.adx.information.UpdateInformationResponse.Result.prototype.getData = function() {
  return /** @type{?proto.adx.information.UpdateInformationResponse.Data} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.UpdateInformationResponse.Data, 2));
};


/**
 * @param {?proto.adx.information.UpdateInformationResponse.Data|undefined} value
 * @return {!proto.adx.information.UpdateInformationResponse.Result} returns this
*/
proto.adx.information.UpdateInformationResponse.Result.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.UpdateInformationResponse.Result} returns this
 */
proto.adx.information.UpdateInformationResponse.Result.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.UpdateInformationResponse.Result.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.UpdateInformationResponse.Result}
 */
proto.adx.information.UpdateInformationResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.UpdateInformationResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.UpdateInformationResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.UpdateInformationResponse.Result|undefined} value
 * @return {!proto.adx.information.UpdateInformationResponse} returns this
*/
proto.adx.information.UpdateInformationResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.UpdateInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.UpdateInformationResponse} returns this
 */
proto.adx.information.UpdateInformationResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.UpdateInformationResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.UpdateInformationResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationResponse} returns this
 */
proto.adx.information.UpdateInformationResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.UpdateInformationResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.UpdateInformationResponse} returns this
 */
proto.adx.information.UpdateInformationResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.UpdateInformationResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.UpdateInformationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationNoticesCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationNoticesCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationNoticesCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationNoticesCountRequest}
 */
proto.adx.information.GetInformationNoticesCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationNoticesCountRequest;
  return proto.adx.information.GetInformationNoticesCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationNoticesCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationNoticesCountRequest}
 */
proto.adx.information.GetInformationNoticesCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationNoticesCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationNoticesCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationNoticesCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesCountRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesCountRequest} returns this
 */
proto.adx.information.GetInformationNoticesCountRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.information.GetInformationNoticesCountRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationNoticesCountRequest} returns this
 */
proto.adx.information.GetInformationNoticesCountRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesCountRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesCountRequest} returns this
 */
proto.adx.information.GetInformationNoticesCountRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.GetInformationNoticesCountResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.GetInformationNoticesCountResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.GetInformationNoticesCountResponse.TypeCase}
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.GetInformationNoticesCountResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.GetInformationNoticesCountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationNoticesCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationNoticesCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.GetInformationNoticesCountResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationNoticesCountResponse}
 */
proto.adx.information.GetInformationNoticesCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationNoticesCountResponse;
  return proto.adx.information.GetInformationNoticesCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationNoticesCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationNoticesCountResponse}
 */
proto.adx.information.GetInformationNoticesCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.GetInformationNoticesCountResponse.Result;
      reader.readMessage(value,proto.adx.information.GetInformationNoticesCountResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationNoticesCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationNoticesCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.GetInformationNoticesCountResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationNoticesCountResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationNoticesCountResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationNoticesCountResponse.Result}
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationNoticesCountResponse.Result;
  return proto.adx.information.GetInformationNoticesCountResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationNoticesCountResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationNoticesCountResponse.Result}
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationNoticesCountResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationNoticesCountResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesCountResponse.Result} returns this
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationNoticesCountResponse.Result} returns this
 */
proto.adx.information.GetInformationNoticesCountResponse.Result.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.GetInformationNoticesCountResponse.Result}
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.GetInformationNoticesCountResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.GetInformationNoticesCountResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.GetInformationNoticesCountResponse.Result|undefined} value
 * @return {!proto.adx.information.GetInformationNoticesCountResponse} returns this
*/
proto.adx.information.GetInformationNoticesCountResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.GetInformationNoticesCountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.GetInformationNoticesCountResponse} returns this
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesCountResponse} returns this
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationNoticesCountResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.GetInformationNoticesCountResponse} returns this
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationNoticesCountResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationNoticesCountResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationNoticesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationNoticesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationNoticesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    size: jspb.Message.getFieldWithDefault(msg, 4, 0),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationNoticesRequest}
 */
proto.adx.information.GetInformationNoticesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationNoticesRequest;
  return proto.adx.information.GetInformationNoticesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationNoticesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationNoticesRequest}
 */
proto.adx.information.GetInformationNoticesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationNoticesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationNoticesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationNoticesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSort();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesRequest} returns this
 */
proto.adx.information.GetInformationNoticesRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.information.GetInformationNoticesRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationNoticesRequest} returns this
 */
proto.adx.information.GetInformationNoticesRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesRequest} returns this
 */
proto.adx.information.GetInformationNoticesRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 size = 4;
 * @return {number}
 */
proto.adx.information.GetInformationNoticesRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationNoticesRequest} returns this
 */
proto.adx.information.GetInformationNoticesRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 page = 5;
 * @return {number}
 */
proto.adx.information.GetInformationNoticesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationNoticesRequest} returns this
 */
proto.adx.information.GetInformationNoticesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sort = 6;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesRequest.prototype.getSort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesRequest} returns this
 */
proto.adx.information.GetInformationNoticesRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.GetInformationNoticesResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.GetInformationNoticesResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.GetInformationNoticesResponse.TypeCase}
 */
proto.adx.information.GetInformationNoticesResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.GetInformationNoticesResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.GetInformationNoticesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationNoticesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationNoticesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationNoticesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.GetInformationNoticesResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationNoticesResponse}
 */
proto.adx.information.GetInformationNoticesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationNoticesResponse;
  return proto.adx.information.GetInformationNoticesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationNoticesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationNoticesResponse}
 */
proto.adx.information.GetInformationNoticesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.GetInformationNoticesResponse.Result;
      reader.readMessage(value,proto.adx.information.GetInformationNoticesResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationNoticesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationNoticesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationNoticesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.GetInformationNoticesResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationNoticesResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationNoticesResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 5, ""),
    read: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationNoticesResponse.Data}
 */
proto.adx.information.GetInformationNoticesResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationNoticesResponse.Data;
  return proto.adx.information.GetInformationNoticesResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationNoticesResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationNoticesResponse.Data}
 */
proto.adx.information.GetInformationNoticesResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRead(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationNoticesResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationNoticesResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRead();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Data} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string kind = 2;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Data} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Data} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Data} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string createdAt = 5;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Data} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool read = 6;
 * @return {boolean}
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.getRead = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Data} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Data.prototype.setRead = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.information.GetInformationNoticesResponse.Result.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationNoticesResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationNoticesResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    found: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentpage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastpage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.adx.information.GetInformationNoticesResponse.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationNoticesResponse.Result}
 */
proto.adx.information.GetInformationNoticesResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationNoticesResponse.Result;
  return proto.adx.information.GetInformationNoticesResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationNoticesResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationNoticesResponse.Result}
 */
proto.adx.information.GetInformationNoticesResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFound(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentpage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastpage(value);
      break;
    case 5:
      var value = new proto.adx.information.GetInformationNoticesResponse.Data;
      reader.readMessage(value,proto.adx.information.GetInformationNoticesResponse.Data.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationNoticesResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationNoticesResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationNoticesResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFound();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCurrentpage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastpage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.information.GetInformationNoticesResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Result} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 found = 2;
 * @return {number}
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.getFound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Result} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.setFound = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 currentPage = 3;
 * @return {number}
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.getCurrentpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Result} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.setCurrentpage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 lastPage = 4;
 * @return {number}
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.getLastpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Result} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.setLastpage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Data data = 5;
 * @return {!Array<!proto.adx.information.GetInformationNoticesResponse.Data>}
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.getDataList = function() {
  return /** @type{!Array<!proto.adx.information.GetInformationNoticesResponse.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.information.GetInformationNoticesResponse.Data, 5));
};


/**
 * @param {!Array<!proto.adx.information.GetInformationNoticesResponse.Data>} value
 * @return {!proto.adx.information.GetInformationNoticesResponse.Result} returns this
*/
proto.adx.information.GetInformationNoticesResponse.Result.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.information.GetInformationNoticesResponse.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.information.GetInformationNoticesResponse.Data}
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.information.GetInformationNoticesResponse.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.information.GetInformationNoticesResponse.Result} returns this
 */
proto.adx.information.GetInformationNoticesResponse.Result.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.GetInformationNoticesResponse.Result}
 */
proto.adx.information.GetInformationNoticesResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.GetInformationNoticesResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.GetInformationNoticesResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.GetInformationNoticesResponse.Result|undefined} value
 * @return {!proto.adx.information.GetInformationNoticesResponse} returns this
*/
proto.adx.information.GetInformationNoticesResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.GetInformationNoticesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.GetInformationNoticesResponse} returns this
 */
proto.adx.information.GetInformationNoticesResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationNoticesResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.GetInformationNoticesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationNoticesResponse} returns this
 */
proto.adx.information.GetInformationNoticesResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationNoticesResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.GetInformationNoticesResponse} returns this
 */
proto.adx.information.GetInformationNoticesResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationNoticesResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationNoticesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.information.UpdateInformationNoticesReadRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.UpdateInformationNoticesReadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.UpdateInformationNoticesReadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.UpdateInformationNoticesReadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationNoticesReadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.UpdateInformationNoticesReadRequest}
 */
proto.adx.information.UpdateInformationNoticesReadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.UpdateInformationNoticesReadRequest;
  return proto.adx.information.UpdateInformationNoticesReadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.UpdateInformationNoticesReadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.UpdateInformationNoticesReadRequest}
 */
proto.adx.information.UpdateInformationNoticesReadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.UpdateInformationNoticesReadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.UpdateInformationNoticesReadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.UpdateInformationNoticesReadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationNoticesReadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.UpdateInformationNoticesReadRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationNoticesReadRequest} returns this
 */
proto.adx.information.UpdateInformationNoticesReadRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int32 ids = 2;
 * @return {!Array<number>}
 */
proto.adx.information.UpdateInformationNoticesReadRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.adx.information.UpdateInformationNoticesReadRequest} returns this
 */
proto.adx.information.UpdateInformationNoticesReadRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.adx.information.UpdateInformationNoticesReadRequest} returns this
 */
proto.adx.information.UpdateInformationNoticesReadRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.information.UpdateInformationNoticesReadRequest} returns this
 */
proto.adx.information.UpdateInformationNoticesReadRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.UpdateInformationNoticesReadResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.UpdateInformationNoticesReadResponse.TypeCase}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.UpdateInformationNoticesReadResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.UpdateInformationNoticesReadResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.UpdateInformationNoticesReadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.UpdateInformationNoticesReadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationNoticesReadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.UpdateInformationNoticesReadResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.UpdateInformationNoticesReadResponse;
  return proto.adx.information.UpdateInformationNoticesReadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.UpdateInformationNoticesReadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.UpdateInformationNoticesReadResponse.Result;
      reader.readMessage(value,proto.adx.information.UpdateInformationNoticesReadResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.UpdateInformationNoticesReadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.UpdateInformationNoticesReadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationNoticesReadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.UpdateInformationNoticesReadResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.UpdateInformationNoticesReadResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.UpdateInformationNoticesReadResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse.Result}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.UpdateInformationNoticesReadResponse.Result;
  return proto.adx.information.UpdateInformationNoticesReadResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.UpdateInformationNoticesReadResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse.Result}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.UpdateInformationNoticesReadResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.UpdateInformationNoticesReadResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse.Result} returns this
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int32 ids = 2;
 * @return {!Array<number>}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse.Result} returns this
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse.Result} returns this
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse.Result} returns this
 */
proto.adx.information.UpdateInformationNoticesReadResponse.Result.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.UpdateInformationNoticesReadResponse.Result}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.UpdateInformationNoticesReadResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.UpdateInformationNoticesReadResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.UpdateInformationNoticesReadResponse.Result|undefined} value
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse} returns this
*/
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.UpdateInformationNoticesReadResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse} returns this
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse} returns this
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.UpdateInformationNoticesReadResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.UpdateInformationNoticesReadResponse} returns this
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.UpdateInformationNoticesReadResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.UpdateInformationNoticesReadResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationLatestUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationLatestUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationLatestUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationLatestUpdateRequest}
 */
proto.adx.information.GetInformationLatestUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationLatestUpdateRequest;
  return proto.adx.information.GetInformationLatestUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationLatestUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationLatestUpdateRequest}
 */
proto.adx.information.GetInformationLatestUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationLatestUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationLatestUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationLatestUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationLatestUpdateRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationLatestUpdateRequest} returns this
 */
proto.adx.information.GetInformationLatestUpdateRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.information.GetInformationLatestUpdateRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationLatestUpdateRequest} returns this
 */
proto.adx.information.GetInformationLatestUpdateRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.information.GetInformationLatestUpdateResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.information.GetInformationLatestUpdateResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.information.GetInformationLatestUpdateResponse.TypeCase}
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.information.GetInformationLatestUpdateResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.information.GetInformationLatestUpdateResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationLatestUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.information.GetInformationLatestUpdateResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse}
 */
proto.adx.information.GetInformationLatestUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationLatestUpdateResponse;
  return proto.adx.information.GetInformationLatestUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse}
 */
proto.adx.information.GetInformationLatestUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.information.GetInformationLatestUpdateResponse.Result;
      reader.readMessage(value,proto.adx.information.GetInformationLatestUpdateResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationLatestUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.information.GetInformationLatestUpdateResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertizerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    date: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updatedat: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate;
  return proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdatedat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 advertizerId = 1;
 * @return {number}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string date = 2;
 * @return {string}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string updatedAt = 3;
 * @return {string}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.prototype.getUpdatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertizerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    updatedat: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider;
  return proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdatedat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 advertizerId = 1;
 * @return {number}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string updatedAt = 4;
 * @return {string}
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.getUpdatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.information.GetInformationLatestUpdateResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    latestupdate: (f = msg.getLatestupdate()) && proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.toObject(includeInstance, f),
    latestupdatebyproviderList: jspb.Message.toObjectList(msg.getLatestupdatebyproviderList(),
    proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.Result}
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.information.GetInformationLatestUpdateResponse.Result;
  return proto.adx.information.GetInformationLatestUpdateResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.Result}
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate;
      reader.readMessage(value,proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.deserializeBinaryFromReader);
      msg.setLatestupdate(value);
      break;
    case 3:
      var value = new proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider;
      reader.readMessage(value,proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.deserializeBinaryFromReader);
      msg.addLatestupdatebyprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.information.GetInformationLatestUpdateResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLatestupdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate.serializeBinaryToWriter
    );
  }
  f = message.getLatestupdatebyproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.Result} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LatestUpdate latestUpdate = 2;
 * @return {?proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate}
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.getLatestupdate = function() {
  return /** @type{?proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate, 2));
};


/**
 * @param {?proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdate|undefined} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.Result} returns this
*/
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.setLatestupdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.Result} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.clearLatestupdate = function() {
  return this.setLatestupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.hasLatestupdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LatestUpdateByProvider latestUpdateByProvider = 3;
 * @return {!Array<!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider>}
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.getLatestupdatebyproviderList = function() {
  return /** @type{!Array<!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider, 3));
};


/**
 * @param {!Array<!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider>} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.Result} returns this
*/
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.setLatestupdatebyproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider}
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.addLatestupdatebyprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.information.GetInformationLatestUpdateResponse.LatestUpdateByProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse.Result} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.Result.prototype.clearLatestupdatebyproviderList = function() {
  return this.setLatestupdatebyproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.information.GetInformationLatestUpdateResponse.Result}
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.information.GetInformationLatestUpdateResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.information.GetInformationLatestUpdateResponse.Result, 1));
};


/**
 * @param {?proto.adx.information.GetInformationLatestUpdateResponse.Result|undefined} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse} returns this
*/
proto.adx.information.GetInformationLatestUpdateResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.information.GetInformationLatestUpdateResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationLatestUpdateResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.information.GetInformationLatestUpdateResponse} returns this
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.information.GetInformationLatestUpdateResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.information.GetInformationLatestUpdateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.information);
