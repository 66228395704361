<template>
  <div>
    <div style="position: relative">
      <div style="transform: rotate(-90deg); width: 190px; height: 190px; margin: auto">
        <svg height="190px" width="190px" viewBox="0 0 20 20">
          <circle
            cx="50%"
            cy="50%"
            fill="transparent"
            stroke-dasharray="56.5487"
            stroke-dashoffset="0"
            stroke-width="1.2"
            r="9"
            stroke="#E6ECF0"
          />
          <circle
            id="bar"
            class="bar"
            cx="50%"
            cy="50%"
            fill="transparent"
            stroke-dasharray="56.5487"
            v-bind:stroke-dashoffset="barp"
            stroke-width="1.2"
            r="9"
            v-bind:stroke="color"
            style="transition: stroke-dashoffset 0.3s linear"
          />
        </svg>
      </div>
      <div style="position: absolute; top: 80px; left: 0; width: 100%">
        <div
          style="
            text-align: center;
            width: 100%;
            font-size: 50px;
            font: normal normal normal 50px/36px Noto Sans JP;
            letter-spacing: 2.35px;
          "
        >
          {{ getRoundValue() }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Summary',
  data() {
    return {
      name: 'サマリ',
      color: '#3B86FF',
      barp: 56.5487,
    };
  },
  computed: {},
  mounted() {
    if (this.design) {
      if (this.design.color) {
        this.color = this.design.color;
      }
    }

    let self = this;
    setTimeout(function () {
      if (!isNaN(Number(self.value))) {
        let v = Number(self.value);
        if (v >= 0) {
          if (v > 100) {
            v = 100;
          }
          self.barp = 56.5487 - 56.5487 * (v / 100);
        }
      }
    }, 100);

    if (!isNaN(Number(this.value))) {
      if (Number(this.value) < 100) {
        this.color = '#ff6565';

        if (this.design) {
          if (this.design.color) {
            this.color = this.design.color;
          }
        }

        let v = Number(self.value);
        if (v < 0) {
          if (v < -100) {
            v = -100;
          }
          this.barp = 56.5487 - 56.5487 * (v / 100);
        }
      }
    }
  },
  methods: {
    getFloorValue() {
      if (!isNaN(Number(this.value))) {
        return Math.floor(Number(this.value));
      }
      return '';
    },
    getRoundValue() {
      if (!isNaN(Number(this.value))) {
        return Math.round(Number(this.value));
      }
      return '';
    },
  },
  components: {},
  props: {
    value: String,
    design: Object,
  },
};
</script>

