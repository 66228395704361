<template>
  <div class="backlash_line">
    <div class="backlash_line__left">{{ title }}</div>
    <div class="backlash_line__center"></div>
    <div class="backlash_line__right">
      <!-- ダウンロードマークは無効にしているので非表示
      <img class="report_download clickable" src="@/assets/icon/save_alt-24px.svg" />
      -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentHeader',
  props: {
    title: String,
    timeRange: Object,
    adx: Object,
  },
  mounted() {},
  emits: ['timerangeevent'],
  methods: {
    thisMonth() {
      let t = this.adx.getNowTime();
      this.timeRange.setStartEndYmd(t.ymd.year, t.ymd.month, t.ymd.day);
    },
    onTimeRange() {
      // MVPで使うには整備が必要なのでカレンダー操作の機能は一旦停止
      // this.$emit('timerangeevent', 'open');
    },
  },
};
</script>

<style scoped>
.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /*transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;*/
}

.btn-outline-dark {
  border: 1px solid #39434e;
}
</style>
