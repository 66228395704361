<template>
  <table>
    <tbody>
      <tr>
        <td class="title" v-bind:class="titleCss">{{ title }}</td>
        <td class="value" v-bind:class="valueCss">{{ value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>
.title {
    vertical-align: bottom;
    padding-right: 10px;
}

.value {
    vertical-align: bottom;
}
</style>

<script>
export default {
  name: 'Tsv',
  data() {
    return {
      name: 'Tsv',
      tcss: this.titleCss,
    };
  },
  mounted() {},
  props: {
    title: String,
    value: String,
    titleCss: String,
    valueCss: String,
  },
};
</script>