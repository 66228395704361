<template>
  <div class="backlash_line">
    <div class="backlash_line__left">{{ title }}</div>
    <div class="backlash_line__center"></div>
    <div class="backlash_line__right">
      <div class="date_fromto_selector" v-if="false">
        <img
          class="date_fromto_selector__minusmonth"
          src="@/assets/icon/keyboard_arrow_left-24px.svg"
          v-on:click="timeRange.decMonth()"
        />
        <img
          class="date_fromto_selector__plusmonth"
          src="@/assets/icon/keyboard_arrow_right-24px.svg"
          v-on:click="timeRange.incMonth()"
        />
        <div class="date_fromto_selector__date">
          {{ timeRange != null ? timeRange.getSelectedDateLabel() : '' }}
        </div>
        <div v-if="false">
          <div
            style="margin-top: 6px; margin-left: 20px; width: 260px"
            class="date_fromto_selector__thismonth"
            v-on:click="onCalc"
          >
            シミュレーション計算
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentHeader',
  props: {
    title: String,
    timeRange: Object,
    adx: Object,
  },
  emits: ['simcalceevent'],
  methods: {
    onCalc() {
      this.$emit('simcalceevent', 'open');
    },
  },
};
</script>

<style scoped>
.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /*transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;*/
}

.btn-outline-dark {
  border: 1px solid #39434e;
}
</style>
