<template>
  <div v-if="!adx.user.isReady()">
    <div style="margin-top: 100px">
      <Spinner />
    </div>
  </div>
  <div v-if="adx.user.isReady()">
    <Header
      :screen="selectedScreen"
      :adx="adx"
      :enableAdvertizerSelector="isDisplayingManagerPage()"
    />
    <router-view :adx="adx" @screenevent="screenevent" />
  </div>
</template>

<script>
import { Adx } from '@/js/adx.js';

import Header from '@/components/layout/Header.vue';
import Spinner from '@/components/layout/Spinner.vue';

export default {
  mounted: async function () {
    await this.adx.initialize();
    let url = location.href;
    let query = '';
    if (url.includes('?')) {
      query = '?' + url.split('?')[1];
    } else {
      query = '';
    }
    console.log(this.adx.user.role);
    console.log(query);

    if (['consultant', 'manager', 'root'].includes(this.adx.user.nowRole())) {
      // TODO: [RMCA]Advertizer画面, manager画面が完成したら、そこにリダイレクトさせる。
      if (['root', 'manager', 'consultant'].includes(this.adx.user.nowRole())) {
        if (this.$route.path == '/report/summary') {
          this.$router.push('/manager/summary');

          return;
        }
      }

      // this.$router.push('/consultant/summary' + query);
    }
    //  else if (['root', 'manager'].includes(this.adx.user.role)) {
    //   // TODO: [RMCA]こういうことすかね
    //   this.$router.push('/manager/summary' + query);
    // }
  },
  data() {
    return {
      adx: new Adx(),
      selectedScreen: '',
    };
  },
  methods: {
    screenevent: function (event) {
      this.selectedScreen = event;
    },
    isDisplayingManagerPage: function () {
      // 応急処置でURLを見て表示しているページを判別するようにしているが、
      // ちゃんとした状態管理の仕組みを導入した方がいい。
      return !location.href.includes('manager');
    },
  },
  components: {
    Header,
    Spinner,
  },
};

setInterval(function () {
  if (document.getElementById('lay_header_body')) {
    document.getElementById('lay_header_body').scrollTo(window.scrollX, 0);
  }
  if (document.getElementById('lay_adv_selector')) {
    document.getElementById('lay_adv_selector').scrollTo(window.scrollX, 0);
  }

  if (document.getElementById('lay_message')) {
    document.getElementById('lay_message').style.left = '' + (1350 + window.scrollX * -1) + 'px';
  }

  if (document.getElementById('lay_right_sidemenu')) {
    document.getElementById('lay_right_sidemenu').style.left = '' + window.scrollX * -1 + 'px';
  }
}, 30);
</script>

