import { getMonthlyResultReport, getWeeklyResultReport, getDailyResultGraphReport, getActualStatusReport, getDimensionCategoryResultReport/*, getConsultantAdProviderCategoryMonthlyReport, getConsultantAdProviderCategoryWeeklyReport, getConsultantAdProviderCategoryDailyReport*/, getDimensionCategoryAndComponent } from "../../com/grpc/summary.js";
import { getTabListOfAdProviderReportPage } from "../../com/grpc/ad_provider.js";
import { getAdBindingKeys, getAdProviderDynamicCategoryMonthlyReport, getAdProviderDynamicCategoryWeeklyReport, getAdProviderDynamicCategoryDailyReport} from "../../com/grpc/excel.js";
import { NameChartStructureTable, ItemWithTitle, StructureHolder, DimensionHolder, ChartStructure, Cell, ContentRecordHolder } from '../data.js'
import * as tacklebox from '../../tacklebox/core/index.js'
import { uuid } from '../../tacklebox/core/uuid.js';
import {
    getAdvertizerSprints,
} from "../../com/grpc/sprint.js";
import { getSummaryReportRequest } from "../../com/grpc/manager.js";
import { ratescale3Step, getColorKeyCodeByBackendKey, cpa11StepColor } from '../../setting.js';
import { ScreenBarValue2 } from '@/js/components/ScreenBarValue2.js';
import { TimeYmd } from '../../tacklebox/date/TimeYmd.js'

import {
    // ComponentInvisibleColumn,
    // PageInvisibleColumnStocker,
    // AdvertizerInvisibleColumnStocker,
    InvisibleColumnStocker,
    ColumnStyleStocker,
    ColumnDefaultHideSaver,
} from '@/js/components/column.js';
import { getInformationLatestUpdate } from "../../com/grpc/information";
import { data2dateandweek } from '../../tacklebox/date/time.js';
import {
    getCustomReportPages,
} from '../../com/grpc/custom_report';

function hideColIndex2ByStructure(structure) {
    let is = [];
    for (let i in structure?.chart) {
        if (structure?.chart[i]?.label) {
            if (structure.chart[i].label.indexOf("(i)") >= 0) {
                is.push(Number(i) + 1);
            }
        }
    }
    return is;
}

function isCheckDate(year, month, day) {
    if (!year || !month || !day) return false;
    if (!String(year).match(/^[0-9]{4}$/) || !String(month).match(/^[0-9]{1,2}$/) || !String(day).match(/^[0-9]{1,2}$/)) return false;

    var dateObj = new Date(year, month - 1, day),
        dateObjStr = dateObj.getFullYear() + '' + (dateObj.getMonth() + 1) + '' + dateObj.getDate(),
        checkDateStr = year + '' + month + '' + day;

    if (dateObjStr === checkDateStr) return true;

    return false;
}

function datestr2w(datestr) {
    try {
        let ds = datestr.split("/");
        let d = new Date(ds[0], Number(ds[1]) - 1, ds[2]);
        switch (d.getDay()) {
            case 1:
                return "月";
            case 2:
                return "火";
            case 3:
                return "水";
            case 4:
                return "木";
            case 5:
                return "金";
            case 6:
                return "土";
            case 0:
                return "日";
        }
    } catch (ex) {
        console.log(ex);
    }
}


/* global ExcelJS */

let colScale3 = cpa11StepColor();

let colScale2 = [
    "#37803A", // good
    "#449E48",
    "#5CB860",
    "#9BD39E",
    "#FAAEAA",
    "#F66D65",
    "#F2382C",
    "#DA1B0E", // bad
]

// MVPなので、設定は埋め込みで間に合わせの実装。
// TODO: BEから設定を取得する。
let getAdvertizerValueSettings_advertizerId = null;
let getAdvertizerValueSettings = function (backendKey, mode=0) {
    return getColorKeyCodeByBackendKey(backendKey, getAdvertizerValueSettings_advertizerId, mode);
}

function ProgressCard(target, rate, options) {
    this.target = target;
    this.rate = rate;
    this.options = options;
}

function CardTable() {
    this.structure = null;
    this.cards = [];
}
CardTable.prototype.initialize = function (structure, accountType) {
    this.cards = [];
    this.structureHolder = new StructureHolder(structure, accountType);
};
CardTable.prototype.getTypeFromStructure = function (label) {
    let blocks = this.structureHolder.getActiveBlock();
    for (let i in blocks) {
        let block = blocks[i];
        if (block.label == label) {
            return block.type;
        }
    }
    return "string";
};
CardTable.prototype.setCards = function (row) {
    let r = JSON.parse(JSON.stringify(row));
    if (this.structureHolder) {
        let t = r;
        var options = [];
        let type = this.getTypeFromStructure(t.tableList[0].label)
        options.push(new ItemWithTitle(t.tableList[2].label, t.tableList[2].value, "key", type));
        options.push(new ItemWithTitle(t.tableList[3].label, t.tableList[3].value, "key", type));
        this.cards.push(new ProgressCard(new ItemWithTitle(t.tableList[0].label, t.tableList[0].value, "key", type), t.tableList[1].value, options));
    }
}
CardTable.prototype.reset = function () {
    this.structure = null;
    this.cards = [];
};

function GraphRecord(name, items = []) {
    this.name = name;
    this.items = items;
    this.isAlert = false;
}
GraphRecord.prototype.addItem = function (item) {
    this.items.push(item);
};

function Graph() {
    this.structureHolder = null;
    this.records = [];
    this.colorMaster = null;
}
Graph.prototype.loadColorMaster = function(colorMaster) {
    this.colorMaster = colorMaster;
};
Graph.prototype.initialize = function (structure, accountType) {
    this.structureHolder = new StructureHolder(structure, accountType);
    this.records = [];
};
Graph.prototype.getTable = function () {
    // ROAS小数以下切捨て
    this?.structureHolder?.structure?.alert[0].item.forEach((key, i) => {
        if (key.backendKey.includes('roas')) this.records.forEach(row => {
            row.items[i].cell.dataType = "rate_roas";
        });
    });
    return this.records
        .sort((row1, row2) => row1.name > row2.name ? 1 : -1);
};
Graph.prototype.setDatas = function (rows) {
    if (this.structureHolder) {
        let structure = this.structureHolder.structure.alert[0].item;
        let r = new GraphRecord(rows.name);
        r.dayOfWeek = rows.dayofweek;
        structure.forEach(s => {
            rows.tableList.filter(t => s.backendKey == t.key)
                ?.forEach(t => r.addItem(new ItemWithTitle(s.label, t.value, t.key, s.type)));
        });
        this.records.push(r);
    }
};
Graph.prototype.getActiveAxes = function () {
    let self = this;
    function _getItemByKey(key) {
        let activeHover = self.structureHolder.getActiveHover();
        for (let k in activeHover) {
            if (activeHover[k].backendKey == key) {
                return activeHover[k];
            }
        }
        return null;
    }
    var axss = [];
    if (this.structureHolder) {
        let graphs = this.structureHolder.getActiveGraph();
        for (let key in graphs) {
            let item = _getItemByKey(graphs[key].backendKey);
            axss.push({
                key: graphs[key].backendKey,
                type: graphs[key].type,
                label: item.label
            });
        }
    }
    return axss.reverse();
};
Graph.prototype.getItems = function () {
    let rss = [];

    let axss = this.getActiveAxes();
    for (let key in this.records) {
        let r = this.records[key];
        let res = {};
        res.name = r.name;

        res.isAlert = false;
        if ('isAlert' in r) {
            if (r.isAlert == true || r.isAlert == 'true') {
                res.isAlert = true;
            }
        }
        res.datas = [];
        for (let k in axss) {
            let axs = axss[k];
            for (let k in r.items) {
                let item = r.items[k];
                if (axs.key == item.key) {
                    res.datas.push(item.getRawValue());
                }
            }
        }
        rss.push(res);
    }
    return rss;
};
Graph.prototype.reset = function () {
    this.structureHolder = null;
    this.records = [];
};
Graph.prototype.isAlert = function () {
    for (let i in this.records) {
        if (this.records[i].isAlert == true) {
            return true;
        }
    }
    return false;
};
Graph.prototype.getTableTitle = function () {
    let titles = [];
    if (this.records.length > 0) {
        titles.push("種別");
        this.structureHolder.structure.alert[0].item.forEach(t => {
            let label = this.structureHolder.structure.alert[0].item.find(h => h.backendKey == t.backendKey)?.label;
            label ? titles.push(label) : titles.push("undefined");
        });
    }

    return titles;
};
Graph.prototype.getCellDesign = function (data, cindex) {
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
        border: null
    };
    ret.isColored = true;

    let k = this.structureHolder.structure.alert[0].item[cindex];
    // if (getAdvertizerValueSettings(k.backendKey) == null) return ret; // no color
    let cd = this.colorMaster.getDesignByKey(k.backendKey);
    if (cd == null) {
        return ret;
    }

    let numList = this.records.map(row => Number(row.items[cindex].cell.data));
    if (k.backendKey.includes('cpa')) numList = numList.filter(val => val != 0);
    if (k.backendKey.includes('roas')) numList = numList.filter(val => val != 0);
    let max = numList.length != 0 ? numList.reduce((a, b) => a > b ? a : b) : 0;
    let min = numList.length != 0 ? numList.reduce((a, b) => a < b ? a : b) : 0;

    // define color 
    switch (getAdvertizerValueSettings(k.backendKey)) {
        case "colored_ctr":
            ret.color = '#BCECFE';
            break;
        case "colored_cpc":
            ret.color = '#FFBF00';
            break;
        case "colored_cost":
            ret.color = '#FF579B';
            break;
        case "colored_cv":
            ret.color = '#34B7FD';
            break;
        case "colored_cvr":
            ret.color = '#A6E7FF';
            break;
        case "colored_cpa": {
            let colScale = Array.from(colScale3);
            ret.color = colScale
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
        case "colored_roas": {
            let colScale = Array.from(colScale3);
            ret.color = colScale.reverse()
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
    }

    // define percent
    let r = data / max * 100;
    r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
    if (r > 100) {
        r = 100;
    }
    switch (getAdvertizerValueSettings(k.backendKey)) {
        case "colored_ctr":
            ret.percent = r; break;
        case "colored_cpc":
            ret.percent = r; break;
        case "colored_cost":
            ret.percent = r; break;
        case "colored_cv":
            ret.percent = r; break;
        case "colored_cvr":
            ret.percent = r; break;
        case "colored_cpa":
            ret.percent = 100; break;
        case "colored_roas":
            ret.percent = 100; break;
    }


    switch (getAdvertizerValueSettings(k.backendKey)) {
        case "colored_ctr":
            ret.design = "bar"; break;
        case "colored_cpc":
            ret.design = "bar"; break;
        case "colored_cost":
            ret.design = "bar_gradation"; break;
        case "colored_cv":
            ret.design = "bar_gradation"; break;
        case "colored_cvr":
            ret.design = "bar"; break;
        case "colored_cpa":
            ret.design = "box"; break;
        case "colored_roas":
            ret.design = "box"; break;
    }

    if (cd.type == "bar" && cd.colors.length > 0) {
        ret.design = "bar";
        ret.color = '#FFFFFF';
        ret.percent = r;
        ret.color = cd.colors[0];
    }
    if (cd.type == "scale" && cd.colors.length > 0) {
        ret.design = "box";
        ret.percent = 100;

        let colScales = cd.colors;

        let colScale = Array.from(colScales);
        ret.color = colScale.reverse()
            .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
            .reduce((colVal1, colVal2) =>
                Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                    colVal2
                    : colVal1
            )[0];
        if (data == 0) ret.color = "";

    }

    return ret;
}

function LowPerformanceCampaignCardStructure(structure, accountType) {
    this.structure = JSON.parse(JSON.stringify(structure));
    this.accountType = accountType;
}
LowPerformanceCampaignCardStructure.prototype.getActiveVariables = function () {
    var variables = [];
    if (this.structure.deterioration) {
        if (this.structure.deterioration.length > 0) {
            for (let i in this.structure.deterioration[0].variables) {
                variables.push(this.structure.deterioration[0].variables[i]);
            }
        }
    }
    return variables;
}

function LowPerformanceCampaignCard() {
    this.structureHolder = null;
    this.providerName = "";
    this.campaignName = "";
    this.items = [];
}
LowPerformanceCampaignCard.prototype.initialize = function (structure, accountType) {
    this.structureHolder = new LowPerformanceCampaignCardStructure(structure, accountType);
};
LowPerformanceCampaignCard.prototype.setDeterioration = function (deterioration) {
    for (let i in deterioration) {
        if ("providerName" in deterioration[i]) {
            this.providerName = deterioration[i].providerName;
        }
        if ("campaign" in deterioration[i]) {
            this.campaignName = deterioration[i].campaign;
        }
        if ("key" in deterioration[i]) {
            let deteriorationValue = deterioration[i];
            let activeVariables = this.structureHolder.getActiveVariables();
            for (let j in activeVariables) {
                let variable = activeVariables[j];
                if (variable.backendKey == deteriorationValue.key) {
                    this.items.push(new ItemWithTitle(variable.label, deteriorationValue.value, deteriorationValue.key, variable.type));
                    break;
                }
            }
        }
    }
}

function LowPerformanceCampaignHolder() {
    this.lowPerformanceCampaigns = [];
}
LowPerformanceCampaignHolder.prototype.initialize = function () {
    this.lowPerformanceCampaigns = [];
};
LowPerformanceCampaignHolder.prototype.pushInitStream = function (structure, accountType, requestId, order) {
    let lpc = new LowPerformanceCampaign();
    lpc.initialize(structure, accountType, requestId, order)
    this.lowPerformanceCampaigns.push(lpc);
};
LowPerformanceCampaignHolder.prototype.pushDataStream = function (deterioration, monthlyList, weeklyList, requestId) {
    let target = null
    for (let i in this.lowPerformanceCampaigns) {
        if (this.lowPerformanceCampaigns[i].requestId == requestId) {
            target = this.lowPerformanceCampaigns[i];
            break;
        }
    }
    if (target) {
        target.addLowPerformanceCampaignRecord(deterioration, monthlyList, weeklyList)
    }
};
LowPerformanceCampaignHolder.prototype.getLowPerformanceCampaignRecords = function () {
    return this.lowPerformanceCampaigns.sort(function (a, b) {
        return a.order - b.order;
    });
};

function LowPerformanceCampaign() {
    this.structure = null;
    this.accountType = null;
    this.requestId = null;
    this.lowPerformanceCampaignRecords = [];
}
LowPerformanceCampaign.prototype.initialize = function (structure, accountType, requestId, order) {
    this.structure = structure;
    this.accountType = accountType;
    this.requestId = requestId;
    this.order = order;
}
LowPerformanceCampaign.prototype.addLowPerformanceCampaignRecord = function (deterioration, monthlyList, weeklyList) {
    let r = new LowPerformanceCampaignRecord();
    r.initialize(this.structure, this.accountType);
    r.addRowData(deterioration, monthlyList, weeklyList);
    this.lowPerformanceCampaignRecords.push(r);
};

function LowPerformanceCampaignRecord() {
    this.campaignCard = new LowPerformanceCampaignCard();
    this.monthlyTable = new NameChartStructureTable(null);
    this.weeklyGraph = new Graph();
}
LowPerformanceCampaignRecord.prototype.initialize = function (structure, accountType) {
    this.campaignCard.initialize(structure, accountType);
    this.monthlyTable.initialize(structure, accountType);
    this.weeklyGraph.initialize(structure, accountType);
}
LowPerformanceCampaignRecord.prototype.addRowData = function (deterioration, monthlyList, weeklyList) {
    this.campaignCard.setDeterioration(deterioration);
    for (let i in monthlyList) {
        this.monthlyTable.addNameAndRow(monthlyList[i].name, monthlyList[i].tableList);
    }
    for (let i in weeklyList) {
        this.weeklyGraph.setDatas(weeklyList[i]);
    }
};

function AlertRecord(cells, isAlert) {
    this.isAlert = isAlert;
    this.cells = cells;
}

function NameChartStructureAlertTable() {
    NameChartStructureTable.call(this);
    this.colorMaster = null;
}
tacklebox.extend(NameChartStructureAlertTable, NameChartStructureTable);
NameChartStructureAlertTable.prototype.loadColorMaster = function(colorMaster) {
    this.colorMaster = colorMaster;
};
NameChartStructureAlertTable.prototype.addNameAndRowAlert = function (name, row, isAlert) {
    let r = JSON.parse(JSON.stringify(row));
    if (this.structure) {
        let cells = [];

        cells.push(new Cell(name, "string"));
        let charts = this.structure.getActiveCharts();
        for (let i in charts) {
            let chart = charts[i];
            for (let key in r) {
                if (r[key].key == chart.backendKey) {
                    cells.push(new Cell(r[key].value, chart.type));
                    break;
                }
            }
        }
        this.cellRows.push(new AlertRecord(cells, isAlert));
    }
};
NameChartStructureAlertTable.prototype.getTable = function () {
    // ROAS小数以下切捨て
    this?.structure?.structure?.chart.forEach((key, i) => {
        if (key.backendKey.includes('roas')) this.cellRows.forEach(row => {
            row.cells[i + 1].dataType = "rate_roas";
        });
    });
    return this.cellRows
        .filter(row => row.cells[0].data.indexOf("20") == 0)
        .sort((row1, row2) => row1.cells[0].data > row2.cells[0].data ? 1 : -1);
}
NameChartStructureAlertTable.prototype.isAlert = function () {
    for (let i in this.cellRows) {
        if (this.cellRows[i].isAlert == "true" || this.cellRows[i].isAlert == true) {
            return true;
        }
    }
    return false;
}
NameChartStructureAlertTable.prototype.getCellDesign = function (data, cindex) {
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
        border: null
    };
    if (cindex <= 0) return ret; // 種別
    ret.isColored = true;

    let k = this.structure.getActiveCharts()[cindex - 1];
    // if (getAdvertizerValueSettings(k.backendKey) == null) return ret; // no color
    let cd = this.colorMaster.getDesignByKey(k.backendKey);
    if (cd == null) {
        return ret;
    }

    let numList = this.cellRows.map(row => Number(row.cells[cindex].data));
    if (k.backendKey.includes('cpa')) numList = numList.filter(val => val != 0);
    if (k.backendKey.includes('roas')) numList = numList.filter(val => val != 0);
    let max = numList.length != 0 ? numList.reduce((a, b) => a > b ? a : b) : 0;
    let min = numList.length != 0 ? numList.reduce((a, b) => a < b ? a : b) : 0;

    // define color 
    switch (getAdvertizerValueSettings(k.backendKey)) {
        case "colored_ctr":
            ret.color = '#BCECFE';
            break;
        case "colored_cpc":
            ret.color = '#FFBF00';
            break;
        case "colored_cost":
            ret.color = '#FF579B';
            break;
        case "colored_cv":
            ret.color = '#34B7FD';
            break;
        case "colored_cvr":
            ret.color = '#A6E7FF';
            break;
        case "colored_cpa": {
            let colScale = Array.from(colScale3);
            ret.color = colScale
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
        case "colored_roas": {
            let colScale = Array.from(colScale3);
            ret.color = colScale.reverse()
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
    }

    // define percent
    let r = data / max * 100;
    r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
    if (r > 100) {
        r = 100;
    }
    switch (getAdvertizerValueSettings(k.backendKey)) {
        case "colored_ctr":
            ret.percent = r; break;
        case "colored_cpc":
            ret.percent = r; break;
        case "colored_cost":
            ret.percent = r; break;
        case "colored_cv":
            ret.percent = r; break;
        case "colored_cvr":
            ret.percent = r; break;
        case "colored_cpa":
            ret.percent = 100; break;
        case "colored_roas":
            ret.percent = 100; break;
    }


    switch (getAdvertizerValueSettings(k.backendKey)) {
        case "colored_ctr":
            ret.design = "bar"; break;
        case "colored_cpc":
            ret.design = "bar"; break;
        case "colored_cost":
            ret.design = "bar_gradation"; break;
        case "colored_cv":
            ret.design = "bar_gradation"; break;
        case "colored_cvr":
            ret.design = "bar"; break;
        case "colored_cpa":
            ret.design = "box"; break;
        case "colored_roas":
            ret.design = "box"; break;
    }

    if (cd.type == "bar" && cd.colors.length > 0) {
        ret.design = "bar";
        ret.color = '#FFFFFF';
        ret.percent = r;
        ret.color = cd.colors[0];
    }
    if (cd.type == "scale" && cd.colors.length > 0) {
        ret.design = "box";
        ret.percent = 100;

        let colScales = cd.colors;

        let colScale = Array.from(colScales);
        ret.color = colScale.reverse()
            .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
            .reduce((colVal1, colVal2) =>
                Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                    colVal2
                    : colVal1
            )[0];
        if (data == 0) ret.color = "";

    }

    return ret;
}

function AlertInfo(label, value, type, rate) {
    this.label = label;
    this.cell = new Cell(value, type);
    this.rate = rate;
}

function AlertCard(title, infos) {
    this.title = title;
    this.infos = infos;
}

function AlertBox() {
    this.structure = null;
    this.cards = [];
}
AlertBox.prototype.initialize = function (structure, accountType) {
    this.structure = new ChartStructure(structure, accountType);
    this.cards = [];
};
AlertBox.prototype.addAlert = function (title, alerts) {
    let charts = this.structure.getActiveCharts();
    let as = [];
    for (let i in charts) {
        let chart = charts[i];
        for (let key in alerts) {
            if (alerts[key].key == chart.backendKey) {
                as.push(new AlertInfo(chart.label, alerts[key].value, chart.type, alerts[key].rate));
            }
        }
    }
    this.cards.push(new AlertCard(title, as));
};
AlertBox.prototype.addHoverAlert = function (title, alerts) {
    let charts = this.structure.structure.hover;//getActiveCharts();
    let as = [];
    for (let i in charts) {
        let chart = charts[i];
        for (let key in alerts) {
            if (alerts[key].key == chart.backendKey) {
                as.push(new AlertInfo(chart.label, alerts[key].value, chart.type, alerts[key].rate));
            }
        }
    }
    this.cards.push(new AlertCard(title, as));
};

function ConsulSumNameChartStructureTable() {
    NameChartStructureTable.call(this);
    this.colorMaster = null;
}
tacklebox.extend(ConsulSumNameChartStructureTable, NameChartStructureTable);
ConsulSumNameChartStructureTable.prototype.loadColorMaster = function(colorMaster) {
    this.colorMaster = colorMaster;
};
ConsulSumNameChartStructureTable.prototype.getCellDesign = function (data, cindex) {
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
        border: null
    };
    if (cindex <= 0) return ret; // 種別
    ret.isColored = true;

    let k = this.structure.getActiveCharts()[cindex - 1];
    // if (getAdvertizerValueSettings(k.backendKey) == null) return ret; // no color
    let cd = this.colorMaster.getDesignByKey(k.backendKey);
    if (cd == null) {
        return ret;
    }

    let numList = this.cellRows.filter(row => ((row[0].data.indexOf("20") == 0) || (row[0].data.indexOf("着地想定") == 0)) || (row[0].data.indexOf("当月実績") == 0)).map(row => Number(row[cindex].data));
    if (k.backendKey.includes('cpa')) numList = numList.filter(val => val != 0);
    if (k.backendKey.includes('roas')) numList = numList.filter(val => val != 0);
    let max = numList.length != 0 ? numList.reduce((a, b) => a > b ? a : b) : 0;
    let min = numList.length != 0 ? numList.reduce((a, b) => a < b ? a : b) : 0;

    // define color 
    switch (getAdvertizerValueSettings(k.backendKey)) {
        case "colored_ctr":
            ret.color = '#BCECFE';
            break;
        case "colored_cpc":
            ret.color = '#FFBF00';
            break;
        case "colored_cost":
            ret.color = '#FF579B';
            break;
        case "colored_cv":
            ret.color = '#34B7FD';
            break;
        case "colored_cvr":
            ret.color = '#A6E7FF';
            break;
        case "colored_cpa": {
            let colScale = Array.from(colScale3);
            ret.color = colScale
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
        case "colored_roas": {
            let colScale = Array.from(colScale3);
            ret.color = colScale.reverse()
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
    }

    // define percent
    let r = data / max * 100;
    r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
    if (r > 100) {
        r = 100;
    }
    switch (getAdvertizerValueSettings(k.backendKey)) {
        case "colored_ctr":
            ret.percent = r; break;
        case "colored_cpc":
            ret.percent = r; break;
        case "colored_cost":
            ret.percent = r; break;
        case "colored_cv":
            ret.percent = r; break;
        case "colored_cvr":
            ret.percent = r; break;
        case "colored_cpa":
            ret.percent = 100; break;
        case "colored_roas":
            ret.percent = 100; break;
    }


    switch (getAdvertizerValueSettings(k.backendKey)) {
        case "colored_ctr":
            ret.design = "bar"; break;
        case "colored_cpc":
            ret.design = "bar"; break;
        case "colored_cost":
            ret.design = "bar_gradation"; break;
        case "colored_cv":
            ret.design = "bar_gradation"; break;
        case "colored_cvr":
            ret.design = "bar"; break;
        case "colored_cpa":
            ret.design = "box"; break;
        case "colored_roas":
            ret.design = "box"; break;
    }

    if (cd.type == "bar" && cd.colors.length > 0) {
        ret.design = "bar";
        ret.color = '#FFFFFF';
        ret.percent = r;
        ret.color = cd.colors[0];
    }
    if (cd.type == "scale" && cd.colors.length > 0) {
        ret.design = "box";
        ret.percent = 100;

        let colScales = cd.colors;

        let colScale = Array.from(colScales);
        ret.color = colScale.reverse()
            .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
            .reduce((colVal1, colVal2) =>
                Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                    colVal2
                    : colVal1
            )[0];
        if (data == 0) ret.color = "";

    }

    return ret;
}

ConsulSumNameChartStructureTable.prototype.getMonthSummary = function () {
    let records = [];
    for (let i in this.cellRows) {
        if (this.cellRows[i][0].data.indexOf("20") != 0) {
            records.push(this.cellRows[i]);
        }
    }

    if (records.length >= 3) {
        let buf = records[2];
        records[2] = records[1];
        records[1] = buf;
    }

    // goal not defined
    let is_goal_defined = true;
    if (records.length >= 1 && records.length <= 2) {
        is_goal_defined = false;
        let goal = records[0].map((cell, i) => {
            if (i == 0) {
                return new Cell("目標", "string");
            }
            return new Cell(0, cell.dataType);
        });
        records.splice(1, 0, goal);
    }
    let kr = [];
    if (records.length > 2) {
        kr.push(new Cell("想定乖離率", "string"));

        for (let i in records[0]) {
            let c = records[0][i];
            if (c.dataType != "string") {
                let cell = null;
                if (is_goal_defined && !Number(records[1][i].data) == 0) cell = new Cell(Math.round(records[2][i].data / records[1][i].data * 100), "rate");
                else cell = new Cell(0, "rate");
                cell.getString = () => {
                    return `${Math.round(cell.data)}%`
                }
                kr.push(cell);
            }
        }
        records.push(kr);
    }

    // ROAS小数以下切捨て
    this?.structure?.structure?.chart.forEach((key, i) => {
        if (key.backendKey.includes('roas')) records.slice(0, 3).forEach(row => {
            row[i + 1].dataType = "rate_roas";
        });
    });
    //records.push(new ItemWithTitle("種別1", (this.cards[key].options[0].getRawValue() / this.cards[key].options[1].getRawValue() * 100).toFixed(2), "key", "rate"));
    return records;
};

ConsulSumNameChartStructureTable.prototype.getMonthSummaryCellDesign = function (data, cindex) {
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
    };
    if (cindex <= 0) return ret; // 種別

    let k = this.structure.getActiveCharts()[cindex - 1];
    // if (getAdvertizerValueSettings(k.backendKey, 1) == null) return ret; // no color
    // if (getAdvertizerValueSettings(k.backendKey, 1) == "colored_cpc") return ret;
    // if (getAdvertizerValueSettings(k.backendKey, 1) == "colored_cvr") return ret;

    let colortype = null;
    if (this.colorMaster) {
        if (k.backendKey == this.colorMaster.getKeyIndicator("cost")) {
            colortype = "colored_cost";
        }
        if (k.backendKey == this.colorMaster.getKeyIndicator("cv")) {
            colortype = "colored_cv";
        }
        if (k.backendKey == this.colorMaster.getKeyIndicator("cparoas")) {
            colortype = "colored_cpa";
            if (k.backendKey.indexOf("roas") > 0) {
                colortype = "colored_roas";
            }
        }
    }
    if (colortype == null) {
        return ret;
    }
    ret.isColored = true;
    // define color 
    switch (colortype) {
        case "colored_cost": {
            if (data >= 130) ret.color = colScale2[7];
            else if (data >= 120) ret.color = colScale2[6];
            else if (data >= 110) ret.color = colScale2[5];
            else if (data >= 105) ret.color = colScale2[4];
            else if (data >= 103) ret.color = colScale2[3];
            else if (data >= 102) ret.color = colScale2[2];
            else if (data >= 101) ret.color = colScale2[1];
            else if (data >= 100) ret.color = colScale2[0];
            else if (data >= 99) ret.color = colScale2[1];
            else if (data >= 98) ret.color = colScale2[2];
            else if (data >= 96) ret.color = colScale2[3];
            else if (data >= 91) ret.color = colScale2[4];
            else if (data >= 81) ret.color = colScale2[5];
            else if (data >= 71) ret.color = colScale2[6];
            else ret.color = colScale2[7];

            let rs3 = ratescale3Step('cost', data);
            if (rs3 > 0) {
                ret.cellclass = "gbarrow3step--" + rs3;
            }
            break;
        }
        case "colored_cv": {
            if (data >= 130) ret.color = colScale2[0];
            else if (data >= 120) ret.color = colScale2[1];
            else if (data >= 110) ret.color = colScale2[2];
            else if (data >= 100) ret.color = colScale2[3];
            else if (data >= 91) ret.color = colScale2[4];
            else if (data >= 81) ret.color = colScale2[5];
            else if (data >= 71) ret.color = colScale2[6];
            else ret.color = colScale2[7];

            let rs3 = ratescale3Step('cv', data);
            if (rs3 > 0) {
                ret.cellclass = "gbarrow3step--" + rs3;
            }
            break;
        }
        case "colored_cpa": {
            if (data >= 130) ret.color = colScale2[7];
            else if (data >= 120) ret.color = colScale2[6];
            else if (data >= 110) ret.color = colScale2[5];
            else if (data >= 101) ret.color = colScale2[4];
            else if (data >= 91) ret.color = colScale2[3];
            else if (data >= 81) ret.color = colScale2[2];
            else if (data >= 71) ret.color = colScale2[1];
            else ret.color = colScale2[0];

            let rs3 = ratescale3Step('cpa', data);
            if (rs3 > 0) {
                ret.cellclass = "gbarrow3step--" + rs3;
            }
            break;
        }
        case "colored_roas": {
            if (data >= 130) ret.color = colScale2[0];
            else if (data >= 120) ret.color = colScale2[1];
            else if (data >= 110) ret.color = colScale2[2];
            else if (data >= 101) ret.color = colScale2[3];
            else if (data >= 91) ret.color = colScale2[4];
            else if (data >= 81) ret.color = colScale2[5];
            else if (data >= 71) ret.color = colScale2[6];
            else ret.color = colScale2[7];

            let rs3 = ratescale3Step('roas', data);
            if (rs3 > 0) {
                ret.cellclass = "gbarrow3step--" + rs3;
            }
            break;
        }
    }
    ret.percent = 100;
    ret.design = "box";
    return ret;
}

ConsulSumNameChartStructureTable.prototype.getTable = function () {
    let records = this.cellRows.map(row => {
        return row.map(cell => {
            return new Cell(cell.data, cell.dataType);
        })
    });

    try{
        let isCurrentMonth = false;
        if (this.currentMonth) {
            let ym = this.currentMonth.split("/");
            if (new Date().getFullYear() == Number(ym[0]) && (new Date().getMonth() + 1) == Number(ym[1])) {
                isCurrentMonth = true
            }
        }
        if (isCurrentMonth == false) {
            records = records.filter(row => row[0].data != "着地想定");
        }    
    }catch(ex){
        console.log(ex);
    }

    records.filter(row => row[0].data == "当月実績")
        .forEach(row => row[0].data = row[0].data = this.currentMonth);

    records.filter(row => row[0].data == "着地想定")
        .forEach(row => row[0].data = row[0].data = this.currentMonth + " (着地想定)");

    //roas 小数点以下切捨て
    this?.structure?.structure?.chart.forEach((key, i) => {
        if (key.backendKey.includes('roas')) records.forEach(row => {
            row[i + 1].dataType = "rate_roas";
        });
    });

    return records.filter(row => row[0].data != "目標")
        .sort((row1, row2) => row1[0].data < row2.data ? 1 : -1);
};


function ConsulDimensionHolder() {
    DimensionHolder.call(this);
    this.colorMaster = null;
}
tacklebox.extend(ConsulDimensionHolder, DimensionHolder);
ConsulDimensionHolder.prototype.loadColorMaster = function(colorMaster) {
    this.colorMaster = colorMaster;
};
ConsulDimensionHolder.prototype.get3Dimension = function () {
    let ds = [];
    for (let i in this.dimensions) {
        if (this.dimensions[i].count() == 3) {
            ds.push(this.dimensions[i]);
        }
    }

    return ds;
};
ConsulDimensionHolder.prototype.get2Dimension = function () {
    let ds = [];
    for (let i in this.dimensions) {
        if (this.dimensions[i].count() == 2) {
            ds.push(this.dimensions[i]);
        }
    }

    return ds;
};
ConsulDimensionHolder.prototype.get2DimensionCategories = function () {
    let dc = this.get2Dimension();

    if (dc.length > 0) {

        return dc[0].dimensions;
    }

    return [];
};
ConsulDimensionHolder.prototype.get1Dimension = function () {
    let ds = [];
    for (let i in this.dimensions) {
        if (this.dimensions[i].count() == 1) {
            ds.push(this.dimensions[i]);
        }
    }

    return ds;
};
// override
ConsulDimensionHolder.prototype.getProviderCategoryTable = function () {
    // ここで表示すべきデータを全て処理しちゃいたいけど、データがネストしすぎててつらい。
    // あきらめた。

    //let dimensionsList = this.dimensions.sort(function (a, b) {
    //    return a.order - b.order;
    //});
    //let ret_dimensionList = [];
    //for(let dimension_i in dimensionsList){
    //    let dimension = [];
    //    for(let cellRowsList_i in dimensionsList[dimension_i].dimensions){
    //        let cellRowsList = [];
    //        for(let cellRows_i in dimensionsList[dimension_i].dimensions[cellRowsList_i].cellRows){
    //            let cellRow = [];
    //            if(dimensionsList[dimension_i].dimensions[cellRowsList_i].cellRows[cellRows_i][0].data.indexOf("20") == 0) continue;
    //            for(let cellRow_i in dimensionsList[dimension_i].dimensions[cellRowsList_i].cellRows[cellRows_i]){
    //                let cell = dimensionsList[dimension_i].dimensions[cellRowsList_i].cellRows[cellRows_i][cellRow_i];
    //                cellRow.push(new Cell(cell.data, cell.dataType));
    //            }
    //            cellRowsList.push(cellRow);
    //        }
    //        dimension.push(cellRowsList);
    //    }
    //    dimension.count = () => dimensionsList[dimension_i].count();
    //    dimension.title = () => dimensionsList[dimension_i].title();
    //    dimension.getHeaderCell = () => dimensionsList[dimension_i].getHeaderCell();
    //    ret_dimensionList.push(dimension);
    //}


    // superにdeligateしたい。
    return this.dimensions.filter(dimension => dimension.requestId == this.providerCategoryRequestId);
}
ConsulDimensionHolder.prototype.generateProviderCategoryRows = function (cellRows) {
    if (!cellRows) {
        return [];
    }
    let ret_cellRows = [];
    cellRows.forEach(row => { // 20xx年xx月 のデータを削除
        if (row[0].data.indexOf("20") == 0) return;
        let ret_row = [];
        row.forEach(cell => {
            ret_row.push(new Cell(cell.data, cell.dataType));
        });
        ret_cellRows.push(ret_row);
    });

    if (ret_cellRows.length == 0) return ret_cellRows;

    if (ret_cellRows.length >= 3) {
        let buf = ret_cellRows[2];
        ret_cellRows[2] = ret_cellRows[1];
        ret_cellRows[1] = buf;
    }

    let isHit = false;
    for (let i in cellRows) {
        let cellRow = cellRows[i];
        if (cellRow?.length > 0) {
            if (cellRow[0].data.indexOf("実績") >= 0) {
                isHit = true;
            }
        }
    }

    if (!isHit) {
        return [];
    }

    // goal not defined
    // let is_goal_defined = true;
    // if (ret_cellRows.length >= 1 && ret_cellRows.length <= 2) {
    //     is_goal_defined = false;
    //     let goal = ret_cellRows[0].map((cell, i) => {
    //         if (i == 0) {
    //             return new Cell("目標", "string");
    //         }
    //         return new Cell(0, cell.dataType);
    //     });
    //     ret_cellRows.splice(1, 0, goal);
    // }

    let kr = [];
    if (ret_cellRows.length > 2) {
        kr.push(new Cell("想定乖離率", "string"));

        for (let i in ret_cellRows[0]) {
            let c = ret_cellRows[0][i];
            if (c.dataType != "string") {
                let cell = null;
                // if (is_goal_defined) cell = new Cell(ret_cellRows[2][i].data / ret_cellRows[1][i].data * 100, "rate");
                // else cell = new Cell(0, "rate");
                cell = new Cell(Math.round(ret_cellRows[2][i].data / ret_cellRows[1][i].data * 100), "rate");
                kr.push(cell);
            }
        }
        ret_cellRows.push(kr);
    }

    // 想定乖離率の小数点以下切捨て
    let comma = (num) => {
        return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    try {
        if (ret_cellRows.length > 3) {
            ret_cellRows[3].forEach((cell, i) => {
                if (i == 0) return;
                cell.getString = () => {
                    return comma(String(Math.round(Number(cell.data)))) + "%";
                }
            });
        }

    } catch (ex) {
        console.log(ex);
    }

    try {
        if (ret_cellRows.length > 3) {
            ret_cellRows[3].forEach((cell, i) => {
                if (i == 0) return;
                if (cell.data == Infinity) cell.getString = () => "--";
                if (isNaN(cell.data)) cell.getString = () => "--";
            });
        }

    } catch (ex) {
        console.log(ex);
    }

    ret_cellRows.forEach(row => {
        row.pairent = ret_cellRows;
    });

    // ROAS小数以下切捨て
    this.dimensions[0]?.structureHolder?.structure?.chart.forEach((key, i) => {
        if (key.backendKey.includes('roas')) ret_cellRows.slice(0, 3).forEach(row => {
            row[i + 1].dataType = "rate_roas";
        });
    });
    return ret_cellRows;
}

ConsulDimensionHolder.prototype.GetCellDesign = function (data, group, index) {
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
        border: null
    };

    if (index <= 0) return ret; // 種別
    ret.isColored = true;

    let ks = this.get2Dimension()[0].structureHolder.getActiveCharts();
    if (getAdvertizerValueSettings(ks[index - 1].backendKey) == null) return ret;

    let numList = group.filter(row => row[0].data.indexOf("20") == 0).map(row => Number(row[index].data));
    if (ks[index - 1].backendKey.includes('cpa')) numList = numList.filter(val => val != 0);
    if (ks[index - 1].backendKey.includes('roas')) numList = numList.filter(val => val != 0);
    let max = numList.length != 0 ? numList.reduce((a, b) => a > b ? a : b) : 0;
    let min = numList.length != 0 ? numList.reduce((a, b) => a < b ? a : b) : 0;

    // define color 
    switch (getAdvertizerValueSettings(ks[index - 1].backendKey)) {
        case "colored_cpc":
            ret.color = '#E3F2FD'; break;
        case "colored_cost":
            ret.color = 'linear-gradient(to right, #FF595E, 50%, #FEE3E4)';
            ret.border = '1px solid #FF595E'; break;
        case "colored_cv":
            ret.color = 'linear-gradient(to right, #068DEF, 50%, #D0E9FB)';
            ret.border = '1px solid #068DEF'; break;
        case "colored_cvr":
            ret.color = '#F9E9DA'; break;
        case "colored_cpa": {
            let colScale = Array.from(colScale3);
            ret.color = colScale
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
        case "colored_roas": {
            let colScale = Array.from(colScale3);
            ret.color = colScale.reverse()
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
    }

    // define percent
    let r = data / max * 100;
    r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
    if (r > 100) {
        r = 100;
    }
    switch (getAdvertizerValueSettings(ks[index - 1].backendKey)) {
        case "colored_cpc":
            ret.percent = r; break;
        case "colored_cost":
            ret.percent = r; break;
        case "colored_cv":
            ret.percent = r; break;
        case "colored_cvr":
            ret.percent = r; break;
        case "colored_cpa":
            ret.percent = 100; break;
        case "colored_roas":
            ret.percent = 100; break;
    }

    switch (getAdvertizerValueSettings(ks[index - 1].backendKey)) {
        case "colored_cpc":
            ret.design = "bar"; break;
        case "colored_cost":
            ret.design = "bar_gradation"; break;
        case "colored_cv":
            ret.design = "bar_gradation"; break;
        case "colored_cvr":
            ret.design = "bar"; break;
        case "colored_cpa":
            ret.design = "box"; break;
        case "colored_roas":
            ret.design = "box"; break;
    }

    return ret;
}

// eslint-disable-next-line no-unused-vars
ConsulDimensionHolder.prototype.getProviderReportCellDesign = function (data, dimensions, group, index_row, index_col) {
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
        border: null,
        cellclass: null,
    };
    if (index_col <= 0) return ret; // 種別
    if (index_row <= 1) return ret; // 実績,目標
    ret.isColored = true;

    let ks = this.get1Dimension()[0].structureHolder.getActiveCharts();
    // if (getAdvertizerValueSettings(ks[index_col - 1].backendKey, 1) == null) return ret;
    let colortype = null;
    if (this.colorMaster) {
        if (ks[index_col - 1].backendKey == this.colorMaster.getKeyIndicator("cost")) {
            colortype = "colored_cost";
        }
        if (ks[index_col - 1].backendKey == this.colorMaster.getKeyIndicator("cv")) {
            colortype = "colored_cv";
        }
        if (ks[index_col - 1].backendKey == this.colorMaster.getKeyIndicator("cparoas")) {
            colortype = "colored_cpa";
            if (ks[index_col - 1].backendKey.indexOf("roas") > 0) {
                colortype = "colored_roas";
            }
        }
    }
    if (colortype == null) {
        return ret;
    }

    let numList = dimensions.flatMap(
        dimensionCategory =>
            dimensionCategory.cellRows.filter(cell =>
                cell[0].data == "着地想定"
            ).flatMap(row => {
                return Number(row[index_col].data);
            })
    );

    if (ks[index_col - 1].backendKey.includes('cpa')) numList = numList.filter(val => val != 0);
    if (ks[index_col - 1].backendKey.includes('roas')) numList = numList.filter(val => val != 0);
    let max = numList.length != 0 ? numList.reduce((a, b) => a > b ? a : b) : 0;
    //let min = numList.length != 0 ? numList.reduce((a,b)=>a<b?a:b) : 0;


    // define color 
    switch (colortype) {
        case "colored_ctr":
            return ret;
        case "colored_cpc":
            return ret;
        case "colored_cost":
            return ret;
        case "colored_cv": {
            if (index_row == 2) { // 目標
                ret.color = '#34B7FD';
                ret.border = '1px solid #34B7FD'; break;
            } else if (index_row == 3) { // 想定乖離率
                if (data >= 130) ret.color = colScale2[0];
                else if (data >= 120) ret.color = colScale2[1];
                else if (data >= 110) ret.color = colScale2[2];
                else if (data >= 100) ret.color = colScale2[3];
                else if (data >= 91) ret.color = colScale2[4];
                else if (data >= 81) ret.color = colScale2[5];
                else if (data >= 71) ret.color = colScale2[6];
                else ret.color = colScale2[7];

                if (!isNaN(data) && (data != Infinity)) {
                    let rs3 = ratescale3Step('cv', data);
                    if (rs3 > 0) {
                        ret.cellclass = "gbarrow3step--" + rs3;
                    }
                }
            }
            break;
        }
        case "colored_cvr":
            return ret;
        case "colored_cpa": {
            if (data == 0) ret.color = ""
            else if (data >= 130) ret.color = colScale2[7];
            else if (data >= 120) ret.color = colScale2[6];
            else if (data >= 110) ret.color = colScale2[5];
            else if (data >= 101) ret.color = colScale2[4];
            else if (data >= 91) ret.color = colScale2[3];
            else if (data >= 81) ret.color = colScale2[2];
            else if (data >= 71) ret.color = colScale2[1];
            else ret.color = colScale2[0];
            if (!isNaN(data) && (data != Infinity)) {
                let rs3 = ratescale3Step('cpa', data);
                if (rs3 > 0) {
                    ret.cellclass = "gbarrow3step--" + rs3;
                }
            }
            break;
        }
        case "colored_roas": {
            if (data == 0) ret.color = ""
            else if (data >= 130) ret.color = colScale2[0];
            else if (data >= 120) ret.color = colScale2[1];
            else if (data >= 110) ret.color = colScale2[2];
            else if (data >= 101) ret.color = colScale2[3];
            else if (data >= 91) ret.color = colScale2[4];
            else if (data >= 81) ret.color = colScale2[5];
            else if (data >= 71) ret.color = colScale2[6];
            else ret.color = colScale2[7];
            if (!isNaN(data) && (data != Infinity)) {
                let rs3 = ratescale3Step('roas', data);
                if (rs3 > 0) {
                    ret.cellclass = "gbarrow3step--" + rs3;
                }
            }
            break;
        }
    }

    // define percent
    let r = data / max * 100;
    r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
    if (r > 100) {
        r = 100;
    }
    switch (colortype) {
        case "colored_cv":
            if (index_row == 2) {
                ret.percent = r;
            } else if (index_row == 3) {
                ret.percent = 100;
            }
            break;
        case "colored_cpa":
            if (index_row == 2) {
                ret.percent = 0;
            } else if (index_row == 3) {
                ret.percent = 100;
            }
            break;
        case "colored_roas":
            if (index_row == 2) {
                ret.percent = 0;
            } else if (index_row == 3) {
                ret.percent = 100;
            }
            break;
    }

    // define design
    switch (colortype) {
        case "colored_cv":
            ret.design = "bar_gradation"; break;
        case "colored_cpa":
            ret.design = "box"; break;
        case "colored_roas":
            ret.design = "box"; break;
    }
    return ret; // 種別
};

ConsulDimensionHolder.prototype.getProviderCategoryReportCellDesign = function (data, dimensions, group, index_row, index_col) {
    data = Number(data);
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
        border: null,
        cellclass: null,
    };
    if (index_col <= 1) return ret; // 種別1, 種別2
    if (index_row <= 1) return ret; // 目標, 着地想定
    ret.isColored = true;

    let ks = this.get2Dimension()[0].structureHolder.getActiveCharts();
    // if (getAdvertizerValueSettings(ks[index_col - 1].backendKey, 1) == null) return ret;
    let colortype = null;
    if (this.colorMaster) {
        if (ks[index_col - 1].backendKey == this.colorMaster.getKeyIndicator("cost")) {
            colortype = "colored_cost";
        }
        if (ks[index_col - 1].backendKey == this.colorMaster.getKeyIndicator("cv")) {
            colortype = "colored_cv";
        }
        if (ks[index_col - 1].backendKey == this.colorMaster.getKeyIndicator("cparoas")) {
            colortype = "colored_cpa";
            if (ks[index_col - 1].backendKey.indexOf("roas") > 0) {
                colortype = "colored_roas";
            }
        }
    }
    if (colortype == null) {
        return ret;
    }

    let numList = dimensions.flatMap(dimensionCategoryGroup =>
        dimensionCategoryGroup.dimensionCategories.flatMap(dimensionCategory =>
            dimensionCategory.cellRows.filter(cell =>
                cell[0].data == "着地想定"
            ).flatMap(row => {
                return Number(row[index_col].data);
            })
        ));
    if (ks[index_col - 1].backendKey.includes('cpa')) numList = numList.filter(val => val != 0);
    if (ks[index_col - 1].backendKey.includes('roas')) numList = numList.filter(val => val != 0);
    let max = numList.length != 0 ? numList.reduce((a, b) => a > b ? a : b) : 0;
    //let min = numList.length != 0 ? numList.reduce((a,b)=>a<b?a:b) : 0;

    // define color 
    switch (colortype) {
        case "colored_ctr":
            return ret;
        case "colored_cpc":
            return ret;
        case "colored_cost":
            return ret;
        case "colored_cv": {
            if (index_row == 2) { // 目標
                ret.color = '#34B7FD';
                ret.border = '1px solid #34B7FD'; break;
            } else if (index_row == 3) { // 想定乖離率
                if (data >= 130) ret.color = colScale2[0];
                else if (data >= 120) ret.color = colScale2[1];
                else if (data >= 110) ret.color = colScale2[2];
                else if (data >= 100) ret.color = colScale2[3];
                else if (data >= 91) ret.color = colScale2[4];
                else if (data >= 81) ret.color = colScale2[5];
                else if (data >= 71) ret.color = colScale2[6];
                else ret.color = colScale2[7];

                if (!isNaN(data) && (data != Infinity)) {
                    let rs3 = ratescale3Step('cv', data);
                    if (rs3 > 0) {
                        ret.cellclass = "gbarrow3step--" + rs3;
                    }
                }
            }
            break;
        }
        case "colored_cvr":
            return ret;
        case "colored_cpa": {
            if (data == 0) ret.color = ""
            else if (data >= 130) ret.color = colScale2[7];
            else if (data >= 120) ret.color = colScale2[6];
            else if (data >= 110) ret.color = colScale2[5];
            else if (data >= 101) ret.color = colScale2[4];
            else if (data >= 91) ret.color = colScale2[3];
            else if (data >= 81) ret.color = colScale2[2];
            else if (data >= 71) ret.color = colScale2[1];
            else ret.color = colScale2[0];
            if (!isNaN(data) && (data != Infinity)) {
                let rs3 = ratescale3Step('cpa', data);
                if (rs3 > 0) {
                    ret.cellclass = "gbarrow3step--" + rs3;
                }
            }
            break;
        }
        case "colored_roas": {
            if (data == 0) ret.color = ""
            else if (data >= 130) ret.color = colScale2[0];
            else if (data >= 120) ret.color = colScale2[1];
            else if (data >= 110) ret.color = colScale2[2];
            else if (data >= 101) ret.color = colScale2[3];
            else if (data >= 91) ret.color = colScale2[4];
            else if (data >= 81) ret.color = colScale2[5];
            else if (data >= 71) ret.color = colScale2[6];
            else ret.color = colScale2[7];
            if (!isNaN(data) && (data != Infinity)) {
                let rs3 = ratescale3Step('roas', data);
                if (rs3 > 0) {
                    ret.cellclass = "gbarrow3step--" + rs3;
                }
            }
            break;
        }
    }

    // define percent
    let r = data / max * 100;
    r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
    if (r > 100) {
        r = 100;
    }
    switch (colortype) {
        case "colored_cv":
            if (index_row == 2) {
                ret.percent = r;
            } else if (index_row == 3) {
                ret.percent = 100;
            }
            break;
        case "colored_cpa":
            if (index_row == 2) {
                ret.percent = 0;
            } else if (index_row == 3) {
                ret.percent = 100;
            }
            break;
        case "colored_roas":
            if (index_row == 2) {
                ret.percent = 0;
            } else if (index_row == 3) {
                ret.percent = 100;
            }
            break;
    }

    // define design
    switch (colortype) {
        case "colored_cv":
            ret.design = "bar_gradation"; break;
        case "colored_cpa":
            ret.design = "box"; break;
        case "colored_roas":
            ret.design = "box"; break;
    }

    // define design
    // モデル的に厳しいので無視。
    //switch (getAdvertizerValueSettings(ks[index_col - 1].backendKey)) {
    //    case "colored_cv":
    //        ret.design = "bar_gradation";break;
    //    case "colored_cpa":
    //        ret.design = "box";break;
    //}


    return ret;
}
ConsulDimensionHolder.prototype.getProviderCategoryTableByTags = function (tags) {
    for (let i in this.dimensions) {
        let dimension = this.dimensions[i];
        if (tags.length == dimension.structureHolder.structure.category.length) {
            for (let t = 0; t < tags.length; t++) {
                if (tags[t] === dimension.structureHolder.structure.category[t]) {
                    if (t == tags.length - 1) {
                        return [dimension];
                    }
                } else {
                    break;
                }
            }
        }
    }

    return [];
};


function DimenstionSelector(selectCallback = ()=>{}) {
    this.tagmaster = ["媒体", "カテゴリ"];
    this.selectedTags = [];
    this.tags = [];
    this.selectCallback = selectCallback;
    this.adpagecomponetids = [];
}
DimenstionSelector.prototype.initialize = function () {
    this.tags = [];
};
DimenstionSelector.prototype.getTags = function () {
    return this.tags;
};
DimenstionSelector.prototype.getDimensionNumberByTag = function (tag) {
    let tags = this.getTags();
    for (let i = 0; i < tags.length; i++) {
        if (tags[i] == tag) {
            return i + 1;
        }
    }
    return 0;
};
DimenstionSelector.prototype.getClassDimensionNumberByTag = function (tag) {
    let tags = this.selectedTags;
    for (let i = 0; i < tags.length; i++) {
        if (tags[i] == tag) {
            return "selectdimension" + (i + 1);
        }
    }
    return "selectdimension0";
};
DimenstionSelector.prototype.getTitle = function () {
    let title = "";
    for (let i = 0; i < this.selectedTags.length; i++) {
        title += this.selectedTags[i];
        if (i < this.selectedTags.length - 1) {
            title += "×";
        }
    }
    if (title == "") {
        title = "未選択";
    }
    return title;
};
DimenstionSelector.prototype.getSelectedTags = function () {
    return this.selectedTags;
};
DimenstionSelector.prototype.setSelectByTag = function (tag) {
    let tags = this.getTags();
    let isHit = false;
    for (let i in tags) {
        if (tags[i] == tag) {
            isHit = true;
        }
    }

    if (!isHit) {
        return;
    }

    let self = this;
    function _deleteitem(t) {
        for (let i = 0; i < self.selectedTags.length; i++) {
            if (self.selectedTags[i] == t) {
                self.selectedTags.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    if (!_deleteitem(tag)) {
        if (this.selectedTags.length < 3) {
            this.selectedTags.push(tag);
        }
    }

    this.selectCallback();
};
DimenstionSelector.prototype.selectedDimensionCount = function () {
    return this.selectedTags.length;
};
DimenstionSelector.prototype.addTag = function (tags) {
    const self = this;
    for (let i in tags) {
        let tag = tags[i];
        let isHit = false;
        for (let j in this.tags) {
            let st = this.tags[j];
            if (st == tag) {
                isHit = true;
                break;
            }
        }
        if (!isHit) {
            this.tags.push(tag);
        }
    }

    function _tindex(tag) {
        let ms = self.tagmaster;
        for (let i = 0; i < ms.length; i++) {
            let t = ms[i];
            if (t == tag) {
                return i;
            }
        }
        return 999999;
    }

    this.tags.sort(function (a, b) {
        let ai = _tindex(a);
        let bi = _tindex(b);
        if (ai > bi) {
            return 1;
        } else if (ai < bi) {
            return -1;
        }
        return 0
    });
};
DimenstionSelector.prototype.getComponentId = function (tags = []) {
    for (let i in this.adpagecomponetids) {
        if (this.adpagecomponetids[i].categoryList.length == tags.length) {
            for (let j = 0 ; j < tags.length ; j++) {
                if (this.adpagecomponetids[i].categoryList[j] == tags[j] ) {
                    if (j == tags.length - 1) {
                        return this.adpagecomponetids[i].id;
                    }
                }else{
                    break;
                }
            }
        }
    }
    return null;
};

function SummaryPage() {
    this.currentPageStreams = [];

    this.monthlyTable = new ConsulSumNameChartStructureTable(null);
    this.monthlyTable.currentMonth = "";
    this.isMonthlyTableNetworking = false;
    this.isMonthlyTableLoaded = false;

    this.weeklyTable = new NameChartStructureAlertTable(null);
    this.isWeeklyTableNetworking = false;
    this.isWeeklyTableLoaded = false;

    this.dailyTable = new Graph();
    this.isDailyTableNetWorking = false;
    this.isDailyTableLoaded = false;

    this.providerTables = [];
    this.providerTablesList = [];
    this.providerTablesProviders = [];
    this.providerTablesAllStarted = false;

    this.weeklyAlert = new AlertBox(null);
    this.isActualStatusNetworking = false;
    this.isMonthlyResultNetworking = false;
    this.isWeeklyResultNetworking = false;
    this.isLoadCategoryNetworking = false;
    this.isLoadDimensionCategoryNetworking = false;
    this.actualStatusCard = new CardTable(null);
    this.dailyGraph = new Graph();
    this.dailyAlert = new AlertBox(null);
    this.pageId = 0;
    this.advertizerId = 2;
    this.timeRange = null;
    this.pageComponents = [];
    this.lowPerformanceCampaignHolder = new LowPerformanceCampaignHolder();
    this.dimensionHolder = new ConsulDimensionHolder();
    this.dimensionHolder.providerCategoryRequestId = "";
    this.isProviderCategoryTableNetworking = false;
    this.isProviderCategoryTableLoaded = false;
    this.providerTablesLoaded = [];

    this.user = null;
    this.currentMonth = "";
    this.selectedMode = 1;

    this.cuuidList = [];

    this.sprintHolder = new SprintHolder([]);
    this.recordHolder = new SummaryRawRecordHolder(function () { });

    this.isSprintPanelOpen = false;

    this.process = new ScreenBarValue2();

    this.isDimensionNetwork = false;
    this.dimensionLastUuid = uuid();
    this.loadedcids = [];
    const self = this;
    this.dimensionSelector = new DimenstionSelector(function(){
        self.dimensionChangeEvent();
    });
    this.dimensionSelector.initialize();
    this.invisibleColumnStocker = new InvisibleColumnStocker();
    this.columnStyleStocker = new ColumnStyleStocker();

    this.cuuidmr = "";

    this.isDimensionNetworkEvent = (isNetwork) => {console.log(isNetwork);};
}
SummaryPage.prototype.changeDimensionNetwork = function (isNetwork) {
    this.isDimensionNetworkEvent(isNetwork);
}
SummaryPage.prototype.dimensionChangeEvent = function () {
    const self = this;
    this.changeDimensionNetwork(true);

    let targetTags = self.dimensionSelector.getSelectedTags();
    if (targetTags.length == 0) {
        self.changeDimensionNetwork(false);
    }

    let cid = self.dimensionSelector.getComponentId(targetTags);
    for (let i in self.loadedcids) {
        if(self.loadedcids[i] == cid) {
            self.changeDimensionNetwork(false);
            return;
        }
    }
    if (cid) {
        let request = {
            sortItem: "",
            sort: "",
            token: self.user.auth0.token,
            userUuid: self.user.userUuid,
        };
    
        request.timeRequest = self.timeRange.getTimeRequest();
        request.advertizerId = self.advertizerId;
        const fuuid = getDimensionCategoryResultReport(self.advertizerId, cid, request, (uuid, data)=> {
            if (fuuid == uuid) {
                let json = JSON.parse(JSON.stringify(data.result));
                if (!!(json.structure) != false) {
                    let structure = JSON.parse(json.structure);
                    self.dimensionHolder.pushInitStream(structure, "all", uuid, 0);
                    self.loadedcids.push(cid);
                } else if (Object.entries(json.dimensioncategoryresultreportList).length != 0) {
                    self.dimensionHolder.pushDataStream(json.categorytypeList, json.dimensioncategoryresultreportList, uuid);
                }
            }
        }, (uuid) => {
            if (self.dimensionLastUuid == uuid) {
                self.changeDimensionNetwork(false);
            }
        }, "consultant", this.currentPageStreams);
        this.dimensionLastUuid = fuuid;
    }

};
SummaryPage.prototype.updateload = function (callback) {
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    const cuuid = getInformationLatestUpdate(this.advertizerId, request, function (uuid, data) {
        if (cuuid == uuid) {
            try {
                let json = JSON.parse(JSON.stringify(data.result));
                if (json?.latestupdate?.date) {
                    if (callback) {
                        callback(data2dateandweek(json.latestupdate.date));
                    }
                }
            } catch (ex) {
                console.log(ex);
            }
        }
    });
};
SummaryPage.prototype.updatecstrp = function (callback) {
    let request = {
        sortItem: '',
        sort: '',
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
      };
    
      request.advertizerId = this.advertizerId;
      const cuid = getCustomReportPages(
        [],
        request,
        function (uid, data) {
            if (cuid == uid) {
                if (data) {
                    if (data?.result) {
                      let result = data?.result;
                      if (result?.pagesList) {
                        for (let i in result?.pagesList) {
                            if (result?.pagesList[i].order < 0) {
                                callback(true);
                                return;
                            }
                        }
                      }
                    }
                }
            }
        },
        function () {
            // if (callback) {
            //     callback();
            // }
        },
        null,
        this._currentPageStreams
      );
};


SummaryPage.prototype.comLoaded = function () {
    this.loadCountDown--;
    if (this.loadCountDown <= 0) {
        this.process.stop();
    }
};
SummaryPage.prototype.cancelAllAPICall = function () {
    this.currentPageStreams.forEach(stream => stream.cancel());
}

let gAdBindingKeys = [];
SummaryPage.prototype.initialize = function (pageId, pageComponents, advertizerId, timeRange, user, colorMaster) {
    this.cuuidList = [];

    this.currentPageStreams = [];

    this.isMonthlyTableLoaded = false;
    this.isWeeklyTableLoaded = false;
    this.isDailyTableLoaded = false;

    this.providerTablesAllStarted = false;
    this.providerTables = [];
    this.providerTablesList = [];
    this.providerTablesProviders = [];
    this.providerTablesLoaded = [];
    this.providerloadUid = uuid();

    this.process.start();

    this.pageId = pageId;
    this.advertizerId = advertizerId;
    getAdvertizerValueSettings_advertizerId = advertizerId;
    this.timeRange = timeRange;
    this.pageComponents = pageComponents;
    this.user = user;
    this.currentMonth = this.timeRange.getTimeRequest().actualMonth.replace("-", "/");
    let self = this;
    this.isMonthlyResultNetworking = true;
    this.isMonthlyResultNoData = false;

    this.colorMaster = colorMaster;

    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };

    request.timeRequest = this.timeRange.getTimeRequest();
    request.advertizerId = this.advertizerId;
    request.limit = 5;

    this.monthlyTable.currentMonth = timeRange.getTimeRequest().actualMonth.replace("-", "/");
    this.monthlyTable.reset();
    this.isMonthlyTableNetworking = true;

    gAdBindingKeys = [];
    let fgabkuid = getAdBindingKeys(request, function (uid, data) {
        if (fgabkuid == uid) {
            if (data != null) {
                let json = JSON.parse(JSON.stringify(data.result));
                if ('adbindingkeysList' in json) {
                    gAdBindingKeys = json.adbindingkeysList;
                }
            }
        }
    }, () => { }, "", this.currentPageStreams);

    this.loadCountDown = 4;
    getMonthlyResultReport(this.advertizerId, request, function (uuid, data) {
        if (data != null) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                let structure = JSON.parse(json.structure);
                self.monthlyTable.initialize(structure, "all");
                self.monthlyTable.loadColorMaster(self.colorMaster);
                let hides = hideColIndex2ByStructure(structure);
                if (hides.length > 0) {
                    let cc = new ColumnDefaultHideSaver();
                    if (!cc.isExist(self.advertizerId, "summary")) {
                        const visummary = self.invisibleColumnStocker.getComponentInvisibleColumn(self.advertizerId, "summary", "summary");
                        hides.map(x=>visummary.setNumberInvisible(x));
                        const vimonthly = self.invisibleColumnStocker.getComponentInvisibleColumn(self.advertizerId, "summary", "monthly");
                        hides.map(x=>vimonthly.setNumberInvisible(x));
                        const vidimension1 = self.invisibleColumnStocker.getComponentInvisibleColumn(self.advertizerId, "summary", "dimension1");
                        hides.map(x=>vidimension1.setNumberInvisible(x+1));
                        const vidimension2 = self.invisibleColumnStocker.getComponentInvisibleColumn(self.advertizerId, "summary", "dimension2");
                        hides.map(x=>vidimension2.setNumberInvisible(x+2));
                        const vidimension3 = self.invisibleColumnStocker.getComponentInvisibleColumn(self.advertizerId, "summary", "dimension3");
                        hides.map(x=>vidimension3.setNumberInvisible(x+3));
                        cc.save(self.advertizerId, "summary");
                    }
                }
            } else if (Object.entries(json.monthlyresultreportList).length != 0) {
                for (let i in json.monthlyresultreportList) {
                    let monthlyResultReport = json.monthlyresultreportList[i];
                    // let monthlyResultReport = json.monthlyresultreport;
                    self.monthlyTable.addNameAndRow(monthlyResultReport.name, monthlyResultReport.tableList);
                }
                self.monthlyTable.cellRows
                    .filter(row => row[0].data.indexOf("20") == 0)
                    .forEach(row => row[0].data = row[0].data.replace("年", "/").replace("月", ""));
            }
        }
    }, () => {
        self.comLoaded();
        self.isMonthlyTableNetworking = false;
        self.isMonthlyTableLoaded = true;
    }, "consultant", this.currentPageStreams);

    this.isWeeklyTableNetworking = true;
    this.weeklyTable.reset();

    request.limit = 13;
    getWeeklyResultReport(this.advertizerId, request, function (uuid, data) {
        if (data != null) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                let structure = JSON.parse(json.structure);
                self.weeklyTable.initialize(structure, "all");
                self.weeklyTable.loadColorMaster(self.colorMaster);
            } else if (Object.entries(json.weeklyresultreportList).length != 0) {
                for (let i in json.weeklyresultreportList) {
                    let weeklyResultReport = json.weeklyresultreportList[i];
                    // let weeklyResultReport = json.weeklyresultreportList;
                    self.weeklyTable.addNameAndRowAlert(weeklyResultReport.name, weeklyResultReport.tableList, (weeklyResultReport.isalert == "true" || weeklyResultReport.isalert == true) ? true : false);
                }
                self.weeklyTable.cellRows
                    .forEach(row => row.cells[0].data = row.cells[0].data.slice(0, 7) + "/" + row.cells[0].data.slice(7));
            }
        }
    }, () => {
        self.comLoaded();
        self.isWeeklyTableNetworking = false;
        self.isWeeklyTableLoaded = true;
    }, "consultant", this.currentPageStreams);

    this.isActualStatusNetworking = true;
    this.isActualStatusNoData = false;
    this.actualStatusCard.reset();
    getActualStatusReport(this.advertizerId, request, function (uuid, data) {
        if (data != null) {
            if (data.error) {
                if (data.error.length > 0) {
                    self.isActualStatusNetworking = false;
                    self.isActualStatusNoData = true;
                }
            }
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                let structure = JSON.parse(json.structure);
                self.actualStatusCard.initialize(structure, "all");
            } else if (Object.entries(json.actualstatusreportList).length != 0) {
                for (let a of json.actualstatusreportList)
                    self.actualStatusCard.setCards(a);
            }
        }
        self.isActualStatusNetworking = false;
    }, () => {
        self.comLoaded();
        self.isActualStatusNetworking = false;
    }, this.currentPageStreams);

    this.isDailyTableNetworking = true;
    this.dailyTable.reset();

    let drequest = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    drequest.timeRequest = this.timeRange.getTimeRequest();
    drequest.advertizerId = this.advertizerId;
    drequest.limit = 31;
    let d = new Date();
    if ((d.getFullYear() == drequest.timeRequest.actualMonth.split("-")[0]) && ((d.getMonth() + 1) == drequest.timeRequest.actualMonth.split("-")[1]) && (d.getDate() == 1)) {
        let tymd = new TimeYmd(d.getFullYear(), d.getMonth() + 1, d.getDate());
        tymd.decMonth();
        drequest.timeRequest.actualMonth = tymd.getYm();
    }
    getDailyResultGraphReport(this.advertizerId, drequest, function (uuid, data) {
        if (data != null) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                let structure = JSON.parse(json.structure);
                self.dailyTable.initialize(structure, "all");
                self.dailyTable.loadColorMaster(self.colorMaster);
            } else if (Object.entries(json.dailyresultgraphreportList).length != 0) {
                for (let a of json.dailyresultgraphreportList) {
                    self.dailyTable.setDatas(a);
                }
                // 無理やりYearを付与。ほんとはBFF層で付けてほしい。
                let year = drequest.timeRequest.actualMonth.slice(0, 4);
                let month = drequest.timeRequest.actualMonth.slice(5);
                self.dailyTable.records.forEach((row) => {
                    if (row.name.slice(0, 2) <= month) {
                        row.name = year + "/" + row.name.split("/")[0] + "/" + row.name.split("/")[1].padStart(2, "0");
                    } else {
                        row.name = "" + (parseInt(year) - 1) + "/" + row.name.split("/")[0] + "/" + row.name.split("/")[1].padStart(2, "0");
                    }
                });

                self.dailyTable.records = self.dailyTable.records.filter(function (row) {
                    return (parseInt(row.name.split("/")[1]) == parseInt(month));
                });

            }
        }
    }, () => {
        self.comLoaded();
        self.isDailyTableNetworking = false;
        self.isDailyTableLoaded = true;
    }, "consultant", this.currentPageStreams);

    this.isProviderCategoryTableNetworking = true;
    this.dimensionHolder.initialize();
    this.dimensionHolder.loadColorMaster(this.colorMaster);
    this.dimensionSelector.initialize();
    this.loadedcids = [];

    const fdcauuid = getDimensionCategoryAndComponent(this.advertizerId ,request, function(uuid, data){
        if (fdcauuid == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            self.dimensionSelector.tagmaster = [];
            for (let i in json.categoryList) {
                self.dimensionSelector.tagmaster.push(json.categoryList[i]);
                self.dimensionSelector.addTag([json.categoryList[i]]);
            }
            self.dimensionSelector.adpagecomponetids = json.adpagecomponentidList;

            self.isProviderCategoryTableNetworking = false;
            self.isProviderCategoryTableLoaded = true;

        }
    }, ()=>{
        //
    }, "consultant", this.currentPageStreams);

    this.ninit();
    this.invisibleColumnStocker.initialize(this.advertizerId, "summary");
    this.columnStyleStocker.initialize(this.advertizerId, "summary");
};

SummaryPage.prototype.initializeProviderTab = function (pageId, callback = function () { }) {
    let self = this;
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.advertizerId = this.advertizerId;
    this.providerTablesProviders = [];
    self.cuuidList.push(getTabListOfAdProviderReportPage(pageId, request, function (uuid, data) {
        if (self.cuuidList.includes(uuid)) {
            let json = JSON.parse(JSON.stringify(data.result));
            self.providerTablesProviders.push({ label: "ALL", id: -1, name: "ALL" });
            for (let a of json.adproviderList) {
                self.providerTablesProviders.push({ label: a.tab, id: a.adproviderid, name: a.provider });
            }
            if (callback) {
                callback();
            }

        }
    }));
};
SummaryPage.prototype.providerLoadFinish = function () { };
SummaryPage.prototype.providerTablesInitialize = function (pageId, advertizerId, timeRange, user, callback = function () { }) {
    // if (this.providerTablesAllStarted) return;
    this.providerTablesAllStarted = true;

    let self = this;
    let request = {
        sortItem: "",
        sort: "",
        token: user.auth0.token,
        userUuid: user.userUuid,
    };
    request.advertizerId = this.advertizerId;

    const loadProviderUid = uuid();
    this.providerloadUid = loadProviderUid;
    let cnt = 100;

    // init provider all tables
    self.providerTables = [];
    self.providerTablesList = [];
    self.cuuidList.push(getTabListOfAdProviderReportPage(pageId, request, function (uuid, data) {
        if (self.cuuidList.includes(uuid)) {
            let json = JSON.parse(JSON.stringify(data.result));
            try {
                cnt = json.adproviderList.length;
            } catch (ex) {
                console.log(ex);
            }
            json.adproviderList.forEach(provider => {
                if (self.providerTables[provider.provider] == undefined) self.providerTables[provider.provider] = [];
                let request2 = {
                    sortItem: "",
                    sort: "",
                    adProviderId: provider.adproviderid,
                    token: user.auth0.token,
                    userUuid: user.userUuid,
                };
                request2.timeRequest = timeRange.getTimeRequest();
                request2.advertizerId = advertizerId;
                self.cuuidList.push(getAdProviderDynamicCategoryMonthlyReport(request2, function (uuid, data, stream_idx) {
                    if (data.error) {
                        console.error(data.error);
                        return;
                    }
                    if (self.cuuidList.includes(uuid) && data != null) {
                        let json = JSON.parse(JSON.stringify(data.result));
                        if (!!json.structure != false) {
                            let structure = JSON.parse(json.structure);
                            if (self.providerTables.structure == undefined) self.providerTables.structure = structure;
                        } else if (
                            Object.entries(json.categoryreportList).length != 0
                        ) {
                            let report = json.categoryreportList[0];
                            if (self.providerTables[provider.provider][report.name] == undefined) self.providerTables[provider.provider][report.name] = [];
                            if (self.providerTables[provider.provider][report.name]['monthly'] == undefined) self.providerTables[provider.provider][report.name]['monthly'] = [];
                            report.monthlyreportList
                                .forEach(table => self.providerTables[provider.provider][report.name]['monthly'].push(table));
                            self.providerTablesList.push({ provider: provider.provider, category: report.name, category_idx: stream_idx, range: 'monthly' });
                        }
                    }
                }, () => {
                    self.providerTablesLoaded.push({
                        providerName: provider.provider,
                        range: 'monthly'
                    });
                }, "consultant", self.currentPageStreams));
                self.cuuidList.push(getAdProviderDynamicCategoryWeeklyReport(request2, function (uuid, data, stream_idx) {
                    if (data.error) {
                        console.error(data.error);
                        return;
                    }
                    if (self.cuuidList.includes(uuid) && data != null) {
                        let json = JSON.parse(JSON.stringify(data.result));
                        if (!!json.structure != false) {
                            //let structure = JSON.parse(json.structure);
                            //if(self.providerTables.structure == undefined) self.providerTables.structure = structure; // Monthly の structureのみ利用する。
                        } else if (
                            Object.entries(json.categoryreportList).length != 0
                        ) {
                            let report = json.categoryreportList[0];
                            if (self.providerTables[provider.provider][report.name] == undefined) self.providerTables[provider.provider][report.name] = [];
                            if (self.providerTables[provider.provider][report.name]['weekly'] == undefined) self.providerTables[provider.provider][report.name]['weekly'] = [];
                            report.weeklyreportList
                                .forEach(table => self.providerTables[provider.provider][report.name]['weekly'].push(table));
                            self.providerTablesList.push({ provider: provider.provider, category: report.name, category_idx: stream_idx, range: 'weekly' });
                        }
                    }
                }, () => {
                    self.providerTablesLoaded.push({
                        providerName: provider.provider,
                        range: 'weekly'
                    });
                }, "consultant", self.currentPageStreams));
                self.cuuidList.push(getAdProviderDynamicCategoryDailyReport(request2, function (uuid, data, stream_idx) {
                    if (data.error) {
                        console.error(data.error);
                        return;
                    }
                    if (self.cuuidList.includes(uuid) && data != null) {
                        let json = JSON.parse(JSON.stringify(data.result));
                        if (!!json.structure != false) {
                            //let structure = JSON.parse(json.structure);
                            //if(self.providerTables.structure == undefined) self.providerTables.structure = structure;
                        } else if (
                            Object.entries(json.categoryreportList).length != 0
                        ) {
                            let report = json.categoryreportList[0];
                            if (self.providerTables[provider.provider][report.name] == undefined) self.providerTables[provider.provider][report.name] = [];
                            if (self.providerTables[provider.provider][report.name]['daily'] == undefined) self.providerTables[provider.provider][report.name]['daily'] = [];
                            report.dailyreportList
                                .forEach(table => self.providerTables[provider.provider][report.name]['daily'].push(table));
                            self.providerTablesList.push({ provider: provider.provider, category: report.name, category_idx: stream_idx, range: 'daily' });
                        }
                    }
                }, () => {
                    cnt--;
                    self.providerTablesLoaded.push({
                        providerName: provider.provider,
                        range: 'daily'
                    });
                    if (cnt == 0) {
                        if (self.providerloadUid == loadProviderUid) {
                            try {
                                self.providerLoadFinish();
                                if (callback) {
                                    callback();
                                }
                            } catch (ex) {
                                console.log(ex);
                            }
                        }
                    }
                }, "consultant", self.currentPageStreams));
            });
        }
    }));
};
SummaryPage.prototype.getProviderTableProviderList = function () {
    return this.providerTablesProviders;
}
SummaryPage.prototype.getProviderTableCategoryList = function (provider, range) {
    return this.providerTablesList?.filter(l => l.provider == provider && l.range == range)
        .sort((a, b) => a.category_idx - b.category_idx)
        .map(l => l.category);
}

function CellInBackendkey(data, dataType = "string", backendKey = "") {
    Cell.call(this, data, dataType);
    this.backendKey = backendKey;
}
tacklebox.extend(CellInBackendkey, Cell);
SummaryPage.prototype.getProviderTableHeader = function () {
    if (this.providerTables['structure'] != undefined) {
        return [new CellInBackendkey('種別', "string")].concat(this.providerTables.structure.chart.map(c => new CellInBackendkey(c.label, "string", c.backendKey)));
    }
}

SummaryPage.prototype.getProviderTable = function (providerName, category, range) { // 全マップするより、データアクセッサーとしてAdapterを被せた方がよかったかも。
    if (this.providerTables[providerName] != undefined &&
        this.providerTables[providerName][category] != undefined &&
        this.providerTables[providerName][category][range] != undefined &&
        this.providerTables.structure != undefined) {
        return this.providerTables[providerName][category][range]
            .filter(row => (range == 'monthly' && row.name.indexOf("20") == 0)
                || (range == 'daily')
                || (range == 'weekly')) // dailyデータは全スルーで通す。
            .sort((row1, row2) => {
                if (row1.name.includes("着地想定")) return row1.name.includes(row2.name) ? -1 : 1;
                if (row2.name.includes("着地想定")) return row2.name.includes(row1.name) ? 1 : -1;
                return row1.name > row2.name ? 1 : -1
            })
            .map(row => { // structureにバインドしていく。
                return [new Cell(row.name, "string")]
                    .concat(this.providerTables.structure.chart
                        .map(c => {
                            let a = row.tableList
                                .filter(t => t.key == c.backendKey)
                                .map(t => new Cell(t.value, c.type))
                                .concat([new Cell("null", "string")])[0]
                            if (c.backendKey.includes("roas")) return new Cell(a.data, "rate_roas");
                            return a;
                        }))
            });
    }
}

SummaryPage.prototype.getActualStatusCircleRecord = function () {
    var result = [];
    for (let i = 0; i < 3 && (this.actualStatusCard.cards.length > i); i++) {
        result.push(this.actualStatusCard.cards[i]);
    }
    return result;
};
SummaryPage.prototype.getActualStatusBarRecord = function () {
    var result = [];
    for (var i = 3; i < 7 && (this.actualStatusCard.cards.length > i); i++) {
        result.push(this.actualStatusCard.cards[i]);
    }
    return result;
};
SummaryPage.prototype.getProgressSummaryCellDesign = function (data, backendKey) {
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
    };
    // if (getAdvertizerValueSettings(backendKey, 1) == null) return ret; // no color
    // if (getAdvertizerValueSettings(backendKey, 1) == "colored_cpc") return ret;
    // if (getAdvertizerValueSettings(backendKey, 1) == "colored_cvr") return ret;
    let colortype = null;
    if (this.colorMaster) {
        if (backendKey == this.colorMaster.getKeyIndicator("cost")) {
            colortype = "colored_cost";
        }
        if (backendKey == this.colorMaster.getKeyIndicator("cv")) {
            colortype = "colored_cv";
        }
        if (backendKey == this.colorMaster.getKeyIndicator("cparoas")) {
            colortype = "colored_cpa";
            if (backendKey.indexOf("roas") > 0) {
                colortype = "colored_roas";
            }
        }
    }
    if (colortype == null) {
        return ret;
    }
    ret.isColored = true;
    // define color 
    switch (colortype) {
        case "colored_cost": {
            let rs3 = ratescale3Step('cost', data);
            if (rs3 > 0) {
                ret.cellclass = "gbarrow3step--" + rs3;
            }
            break;
        }
        case "colored_cv": {
            let rs3 = ratescale3Step('cv', data);
            if (rs3 > 0) {
                ret.cellclass = "gbarrow3step--" + rs3;
            }
            break;
        }
        case "colored_cpa": {
            let rs3 = ratescale3Step('cpa', data);
            if (rs3 > 0) {
                ret.cellclass = "gbarrow3step--" + rs3;
            }
            break;
        }
        case "colored_roas": {
            let rs3 = ratescale3Step('roas', data);
            if (rs3 > 0) {
                ret.cellclass = "gbarrow3step--" + rs3;
            }
            break;
        }
    }

    switch (ret.cellclass) {
        case "gbarrow3step--1":
            ret.color = "#088dff";
            break;
        case "gbarrow3step--2":
            ret.color = "#088dff";
            break;
        case "gbarrow3step--3":
            ret.color = "#088dff";
            break;
        case "gbarrow3step--4":
            ret.color = "#ff1744";
            break;
        case "gbarrow3step--5":
            ret.color = "#ff1744";
            break;
        case "gbarrow3step--6":
            ret.color = "#ff1744";
            break;
        case "gbarrow3step--11":
            ret.color = "#088dff";
            break;
        case "gbarrow3step--12":
            ret.color = "#088dff";
            break;
        case "gbarrow3step--13":
            ret.color = "#088dff";
            break;
        case "gbarrow3step--14":
            ret.color = "#ff1744";
            break;
        case "gbarrow3step--15":
            ret.color = "#ff1744";
            break;
        case "gbarrow3step--16":
            ret.color = "#ff1744";
            break;
    }

    return ret;
}

function SprintHolder(raws) {
    this.raws = raws;
    this.selectedId = "";
}
SprintHolder.prototype.selectedSprint = function () {
    for (let i in this.raws) {
        if (this.raws[i].id == this.selectedId) {
            return this.raws[i];
        }
    }

    return null;
};
SprintHolder.prototype.selectSprint = function (id) {
    this.selectedId = id;
};
SprintHolder.prototype.initialize = function () {
    this.raws = [];
};

SummaryPage.prototype.ninit = function () {
    let request = {
        advertizerId: this.advertizerId,
        actualMonth: this.timeRange.getTimeRequest().actualMonth,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    let self = this;
    this.sprintHolder.initialize();
    let cuuid2 = getAdvertizerSprints(request, function (uuid, data) {
        if (data != null && cuuid2 == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            // self.sprintHolder = new SprintHolder(json.sprintsList);
            for (let i in json.sprintsList) {
                self.sprintHolder.raws.push(json.sprintsList[i]);
            }
            if (self.selectedSprint == null) {
                if (self.sprintHolder.raws.length > 0) {
                    self.sprintHolder.selectSprint(self.sprintHolder.raws[0].id);
                }
            }
        }
    }, () => {
    }, "", this.currentPageStreams);

    // this.cancelAllAPICall();
    this.recordHolder.initialize();//structureなし
    let request2 = {
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };

    request2.advertizerId = this.advertizerId;
    request2.timeRequest = this.timeRange.getTimeRequest();
    request2.requestAdvertizerIds = [request2.advertizerId];

    const cuuid = getSummaryReportRequest(this.advertizerId, request2, function (uuid, data) {
        if (data != null && cuuid == uuid && self.cuuidmr == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                if (json.summaryreport.advertizerid == self.advertizerId) {
                    self.recordHolder.pushDataStream(json, json.requestId);
                }
            }
        }
    }, () => {
        // self.isManagerTableNetworking = false;
        //
    }, (e) => {
        console.log(e);
        // self.isManagerTableNetworking = false;
    }, "manager", this.currentPageStreams);
    this.cuuidmr = cuuid;
}
SummaryPage.prototype.getManagerTarget = function () {
    for (let i in this.recordHolder.records) {
        if (this.advertizerId == this.recordHolder.records[i].advertizerid) {
            return this.recordHolder.records[i];
        }
    }


    return [];
};
SummaryPage.prototype.toggleSprintPanel = function () {
    this.isSprintPanelOpen = !this.isSprintPanelOpen;
};
SummaryPage.prototype.getMainCostCvCpaBackendKey = function (target) {
    let hs = this.recordHolder.getTableColHeaders();
    for (let i in hs) {
        if (hs[i].label == target) {
            return hs[i].backendKey;
        }
        if (target == "CPA") {
            if (hs[i].label == "ROAS") {
                return hs[i].backendKey;
            }
        }
    }
    return "";
};
SummaryPage.prototype.getMainCpaLabel = function () {
    let hs = this.recordHolder.getTableColHeaders();
    for (let i in hs) {
        if (hs[i].label == "ROAS") {
            return "ROAS";
        }
    }
    return "CPA";
};




function SummaryRawRecord(structure = null, client = null, advertizerid = 0, graphList = null, tableList = null) {
    this.structure = JSON.parse(structure);
    this.client = client;
    this.advertizerid = advertizerid;
    this.graphList = graphList;
    this.tableList = tableList;

}
SummaryRawRecord.prototype.getName = function () {
    return new Cell(this.client, "string");
};
SummaryRawRecord.prototype.getTables = function (targetStructures) {
    let result = [];
    for (let i in targetStructures) {
        let target = targetStructures[i];
        let isHit = false;
        for (let k in this.structure.table) {
            if (this.structure.table[k].label == target.label) {
                for (let t in this.tableList) {
                    let table = this.tableList[t];
                    if (table.key == this.structure.table[k].backendKey) {
                        isHit = true;
                        result.push(new ManagerTableRawCol(this.structure.table[k], table.key, table.ratevalue, table.expectedvalue, table.dataList));
                        break;
                    }

                }

                break;
            }
        }
        if (!isHit) {
            result.push(new ManagerTableRawCol());
        }
    }

    result.forEach(table => {
        table.getType = () => {
            if (table.key == null) return null;
            if (table.key.includes("cost")) return "cost";
            if (table.key.includes("cv")) return "cv";
            if (table.key.includes("cpa")) return "cpa";
            if (table.key.includes("roas")) return "roas";
        }
    });
    return result;
};
SummaryRawRecord.prototype.getGraph = function () {
    //this.graphList = graphList;
    return [];
};
SummaryRawRecord.prototype.getGraphList = function () {
    return this.graphList;
};
SummaryRawRecord.prototype.getGraphTitle = function () {
    let titles = [];
    for (let i in this.graphList) {
        titles.push(this.graphList[i].month);
    }
    return titles;
};
SummaryRawRecord.prototype.getGraphs = function () {
    let self = this;
    try {
        let assumption = Array.from({ length: self.graphList.length - 1 }, () => null);
        if (assumption) {
            for (let i in self.graphList) {
                let ym = self.graphList[i]?.month?.split("/");
                if (ym && ym.length > 1) {
                    if (new Date().getFullYear() == Number(ym[0]) && (new Date().getMonth() + 1) == Number(ym[1])) {
                        assumption = assumption.concat(self.graphList[self.graphList.length - 1].tableList.filter(t => t.key.includes("cv")).filter(t => t.valuetype == "current")[0].expected);
                        break;
                    }
                }
            }
        }

        let ret = self.structure.graph.map(g => new Object({
            label: g.label,
            type: g.type,
            value: self.graphList.map(data => data.tableList.filter(t => t.key == g.backendKey && t.valuetype == g.valueType)[0].value),
            dataGroup: g.backendKey,
            color:
                g.backendKey.includes("cpa") || g.backendKey.includes("roas") ? "#00CDE0" :
                    g.backendKey.includes("cv") && g.valueType == "current" ? "#34b7fd" :
                        g.backendKey.includes("cv") && g.valueType == "simulated" ? "#a6e7ff" :
                            "",
            yAxisLabelPosition:
                g.backendKey.includes("cpa") || g.backendKey.includes("roas") ? "right" :
                    g.backendKey.includes("cv") && g.valueType == "current" ? "left" :
                        g.backendKey.includes("cv") && g.valueType == "simulated" ? null :
                            null,
            labelGenerator:
                g.backendKey.includes("cpa") ? v => '￥' + v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') :
                    v => v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            grid:
                g.backendKey.includes("cpa") || g.backendKey.includes("roas") ? true :
                    false,
        })).concat({
            label: self.structure.graph.map(g => g.label).filter(l => l.includes("実績"))[0].replace("実績", "着地想定"),
            type: "bar",
            value: assumption,
            dataGroup: self.structure.graph.filter(g => g.backendKey.includes("cv"))[0].backendKey,
            color: "#068DEF50",
            yAxisLabelPosition: null,
            labelGenerator: v => v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            legend: false,
            grid: false
        });
        return [ret[2], ret[0], ret[3], ret[1],];
    } catch (e) {
        console.error(e);
        return [];
    }

};


function SummaryRawRecordHolder(callback) {
    ContentRecordHolder.call(this);
    this.requestId = null;
    this.callback = callback;
}
tacklebox.extend(SummaryRawRecordHolder, ContentRecordHolder);
SummaryRawRecordHolder.prototype._onContentRecordCreateRecord = function (data) {
    return new SummaryRawRecord(data.structure, data.summaryreport.client, data.summaryreport.advertizerid, data.summaryreport.graphList, data.summaryreport.tableList);
};
SummaryRawRecordHolder.prototype._onContentRecordGetHeaderCell = function () {
    let result = [
        new Cell("クライアント", "string"),
        new Cell("月別推移", "string")
    ];
    let tc = this.getTableColHeaders();
    for (let i in tc) {
        result.push(new Cell(tc[i].label, "string"));
    }
    result.push(new Cell("memo", "string"));
    return result;
};
SummaryRawRecordHolder.prototype.getTableColHeaders = function () {
    if (this.records.length > 0) {
        if (this.records[0]) {
            return this.records[0].structure.table;
        }
    }
    return [];
};
SummaryRawRecordHolder.prototype.getTarget = function () {
    if (this.records.length > 0) {
        return this.records[0];
    }
    return [];
};

function ManagerTableRawCol(structure, key = null, ratevalue = "", expectedvalue = "", data = []) {
    this.structure = structure;
    this.key = key;
    this.ratevalue = ratevalue;
    this.expectedvalue = expectedvalue;
    this.data = data;
}
ManagerTableRawCol.prototype.getRateValue = function () {
    let c = new Cell(this.ratevalue, "rate");
    c.isRoundMode = true;
    return c;
};
ManagerTableRawCol.prototype.getTitles = function () {
    let results = [];

    for (let i in this.data) {
        let data = this.data[i];
        results.push(data.day);
    }

    return results;
};
ManagerTableRawCol.prototype.getGraphTitle = function () {
    return this?.structure?.label;
}
ManagerTableRawCol.prototype.getGraphs = function () {
    try {
        return [{
            label: this.structure.label + ' 目標',
            type: 'dash',
            value: this.data.map(() => this.expectedvalue),
            dataGroup: 'group',
            color: '#000000',
            yAxisLabelPosition: null,
            labelGenerator: null
        }, {
            label: this.structure.label + ' 実績',
            type: 'bar',
            value: this.data.map(d => d.value),
            dataGroup: 'group',
            color: '#D0E9FB',
            yAxisLabelPosition: 'left',
            labelGenerator:
                this.key.includes("cpa") || this.key.includes("cost") ? v => '￥ ' + v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') :
                    v => v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            grid: true,
        }];
    } catch (e) {
        console.error(e);
        return [];
    }
};

function CalBall(value = 0, type = 0) {
    this.value = value;
    this.type = type;
}
function CalBallBuilder(arg1 = null, arg2 = null) {
    this.calballs = [];
    if (arg1 != null && arg2 != null) {
        this.calballs.push(new CalBall(arg1, 1));
        this.calballs.push(new CalBall("/"));
        this.calballs.push(new CalBall(arg2, 1));
    }
}
CalBallBuilder.prototype.reset = function () {
    this.calballs = [];
}
CalBallBuilder.prototype.append = function (str) {
    this.calballs.push(new CalBall(str));

    return this;
};
CalBallBuilder.prototype.appendBackendKey = function (backendKey) {
    this.calballs.push(new CalBall(backendKey, 1));

    return this;
};
CalBallBuilder.prototype.getBalls = function () {
    return this.calballs;
};

function XlsAdxCell(headerraw, dataraw) {
    this.headerraw = headerraw;
    this.dataraw = dataraw;
}
XlsAdxCell.prototype.getType = function () {
    let label = this.headerraw.data;
    let backendKey = this.headerraw.backendKey;
    // console.log(this.headerraw);
    function _isTag(src, word) {
        if (src.indexOf('_' + word + '_') >= 0) {
            return true;
        }
        return false;
    }
    function _isTag1(src, word) {
        if (src.indexOf('_' + word) >= 0) {
            return true;
        }
        return false;
    }

    switch (label) {
        case "種別":
            return 1;
    }
    switch (backendKey) {
        case "imp":
        case "click":
        case "provider_purchase":
        case "provider_revenue":
        case "provider_install":
        case "analysis_install":
            return 0;
        case "cost_with_fee":
            return 2;
        case "ctr":
            return 10
        case "cpc":
            return 11;
        case "cpm":
            return 105;
    }
    if (_isTag(backendKey, "cv")) {
        return 5;
    }
    if (_isTag(backendKey, "sales")) {
        return 6;
    }
    if (_isTag(backendKey, "cpa")) {
        return 100;
    }
    if (_isTag(backendKey, "cvr")) {
        return 101;
    }
    if (_isTag1(backendKey, "cvr")) {
        return 101;
    }
    if (_isTag(backendKey, "roas")) {
        return 102;
    }
    if (_isTag(backendKey, "price")) {
        return 103;
    }
    if (_isTag(backendKey, "cpi")) {
        return 104;
    }
    if (_isTag1(backendKey, "cpi")) {
        return 104;
    }

    return 0;
};

XlsAdxCell.prototype.getCalBallBuilder = function () {
    function _getNumber(src = "", b = "", a = "") {
        let out = src.replaceAll(b, "");
        return out.replaceAll(a, "");
    }
    let backendKey = this.headerraw.backendKey;

    for (let i in gAdBindingKeys) {
        let ckey = gAdBindingKeys[i];
        if (ckey.target == backendKey) {
            if (ckey.isoverride) {
                return new CalBallBuilder(ckey.element1, ckey.element2);
            }
        }
    }

    switch (backendKey) {
        case "provider_cvr_all":
            return new CalBallBuilder("provider_cv_all", "click");
        case "provider_cvr_other":
            return new CalBallBuilder("provider_cv_other", "click");
        case "analysis_cvr_all":
            return new CalBallBuilder("analysis_cv_all", "click");
        case "analysis_cvr_other":
            return new CalBallBuilder("analysis_cv_other", "click");
        case "analysis_cvr_reculc":
            return new CalBallBuilder("analysis_cv_reculc", "click");
        case "analysis_install_cvr":
            return new CalBallBuilder("analysis_install", "click");
        case "analysis_in_app_cvr_all_click":
            return new CalBallBuilder("analysis_in_app_cv_all", "click");
        case "analysis_in_app_cvr_all_install":
            return new CalBallBuilder("analysis_in_app_cv_all", "analysis_install_all");
        case "analysis_in_app_cvr_other_click":
            break;
        case "analysis_in_app_cvr_other_install":
            break;
        case "business_cvr_all":
            return new CalBallBuilder("business_cv_all", "click");
        case "business_cvr_other":
            return new CalBallBuilder("business_cv_other", "click");
        case "business_cvr_all_analysiscv_all":
            break;
        case "business_cvr_other_by_analysis_cv":
            break;
        case "business_cvr_other_by_ad_provider_cv":
            break;
        case "provider_cpa_all":
            return new CalBallBuilder("cost_with_fee", "provider_cv_all");
        case "provider_cpa_other":
            return new CalBallBuilder("cost_with_fee", "provider_cv_other");
        case "analysis_cpa_all":
            return new CalBallBuilder("cost_with_fee", "analysis_cv_all");
        case "analysis_cpa_other":
            return new CalBallBuilder("cost_with_fee", "analysis_cv_other");
        case "analysis_cpa_reculc":
            break;
        case "analysis_in_app_cpa_all":
            break;
        case "analysis_in_app_cpa_other":
            break;
        case "business_cpa_all":
            return new CalBallBuilder("cost_with_fee", "business_cv_all");
        case "business_cpa_other":
            return new CalBallBuilder("cost_with_fee", "business_cv_other");
        case "provider_roas_all":
            return new CalBallBuilder("provider_sales_all", "cost_with_fee");
        case "provider_roas_other":
            return new CalBallBuilder("provider_sales_other", "cost_with_fee");
        case "analysis_roas_all":
            return new CalBallBuilder("analysis_sales_all", "cost_with_fee");
        case "analysis_roas_other":
            return new CalBallBuilder("analysis_sales_other", "cost_with_fee");
        case "business_roas_all":
            return new CalBallBuilder("business_sales_all", "cost_with_fee");
        case "business_roas_other":
            return new CalBallBuilder("business_sales_other", "cost_with_fee");
        case "provider_price_all":
            break;
        case "provider_price_other":
            break;
        case "analysis_price_all":
            break;
        case "analysis_price_other":
            break;
        case "business_price_all":
            break;
        case "business_price_other":
            break;
        case "analysis_install_cpi":
            return new CalBallBuilder("cost_with_fee", "analysis_install");
        case "cpm":
            return new CalBallBuilder("cost_with_fee", "imp");
    }
    //CVR
    if (backendKey.match(/provider_cvr_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "provider_cvr_")
        return new CalBallBuilder("provider_cv_" + num, "click");
    } else if (backendKey.match(/provider_in_app_cvr_([1-9]|[1-3][0-9])_click/u) != null) {
        let num = _getNumber(backendKey, "provider_in_app_cvr_", "_click");
        return new CalBallBuilder("provider_in_app_cv_" + num, "click");
    } else if (backendKey.match(/provider_in_app_cvr_([1-9]|[1-3][0-9])_install/u) != null) {
        let num = _getNumber(backendKey, "provider_in_app_cvr_", "_install");
        return new CalBallBuilder("provider_in_app_cv_" + num, "provider_install_" + num);
    } else if (backendKey.match(/analysis_cvr_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "analysis_cvr_");
        return new CalBallBuilder("analysis_cv_" + num, "click");
    } else if (backendKey.match(/analysis_in_app_cvr_([1-9]|[1-3][0-9])_click/u) != null) {
        let num = _getNumber(backendKey, "analysis_in_app_cvr_", "_click");
        return new CalBallBuilder("analysis_in_app_cv_" + num, "click");
    } else if (backendKey.match(/analysis_in_app_cvr_([1-9]|[1-3][0-9])_install/u) != null) {
        let num = _getNumber(backendKey, "analysis_in_app_cvr_", "_install");
        return new CalBallBuilder("analysis_in_app_cv_" + num, "analysis_install_" + num);
    } else if (backendKey.match(/business_cvr_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "business_cvr_");
        return new CalBallBuilder("business_cv_" + num, "click");
    } else if (backendKey.match(/business_cvr_([1-9]|[1-3][0-9])_by_analysis_cv/u) != null) {
        let num = _getNumber(backendKey, "business_cvr_", "_by_analysis_cv");
        return new CalBallBuilder("business_cv_" + num, "analysis_cv_" + num);
    } else if (backendKey.match(/business_cvr_([1-9]|[1-3][0-9])_by_ad_provider_cv/u) != null) {
        let num = _getNumber(backendKey, "business_cvr_", "_by_ad_provider_cv");
        return new CalBallBuilder("business_cv_" + num, "provider_cv_" + num);
        //CPA
    } else if (backendKey.match(/analysis_cpa_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "analysis_cpa_");
        return new CalBallBuilder("cost_with_fee", "analysis_cv_" + num);
    } else if (backendKey.match(/business_cpa_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "business_cpa_");
        return new CalBallBuilder("cost_with_fee", "business_cv_" + num);
    } else if (backendKey.match(/provider_cpa_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "provider_cpa_");
        return new CalBallBuilder("cost_with_fee", "provider_cv_" + num);
        //PRICE
    } else if (backendKey.match(/provider_price_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "provider_price_");
        return new CalBallBuilder("provider_sales_" + num, "provider_cv_" + num);
    } else if (backendKey.match(/analysis_price_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "analysis_price_");
        return new CalBallBuilder("analysis_sales_" + num, "analysis_cv_" + num);
        //ROAS
    } else if (backendKey.match(/provider_roas_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "provider_roas_");
        return new CalBallBuilder("provider_sales_" + num, "cost_with_fee");
    } else if (backendKey.match(/analysis_roas_[1-9]$|[1-3][0-9]$/u) != null) {
        let num = _getNumber(backendKey, "analysis_roas_");
        return new CalBallBuilder("analysis_sales_" + num, "cost_with_fee");
    }

    return new CalBallBuilder();
};


function XlsTableTarget(category, raw, headers) {
    this.category = category;
    this.raw = raw;
    this.headers = headers;
}
XlsTableTarget.prototype.getAdx31DayCells_ = function (year = null, month = null) {
    let list = [];
    for (let i in this.raw) {
        let row = this.raw[i];
        let columns = [];
        for (let j = 0; j < this.headers.length; j++) {
            if (row?.length >= j) {
                let axc = new XlsAdxCell(this.headers[j], row[j]);
                if (j == 0) {
                    if (axc.getType() == 1) {
                        if (axc.dataraw.data.split("/")[0] != year || axc.dataraw.data.split("/")[1] != month) {
                            break;
                        }
                    }
                }
                columns.push(axc);
            }
        }
        if (columns.length > 0) {
            list.push(columns);
        }
    }
    return list;
};
XlsTableTarget.prototype.getAdx31DayCells = function (year = null, month = null) {
    let list = [];
    for (let d = 1; d <= 31; d++) {
        let columns = [];

        for (let i in this.raw) {
            let row = this.raw[i];
            if (Number(row[0].data.split("/")[0]) == year && Number(row[0].data.split("/")[1]) == month && Number(row[0].data.split("/")[2]) == d) {
                for (let j = 0; j < this.headers.length; j++) {
                    if (row?.length >= j) {
                        let axc = new XlsAdxCell(this.headers[j], row[j]);
                        columns.push(axc);
                    } else {
                        // 空axc
                    }
                }
                break;
            }
        }

        if (columns.length == 0) {
            for (let j = 0; j < this.headers.length; j++) {
                let c = new Cell(0);
                if (j == 0) {
                    c = new Cell("" + year + "/" + ('00' + month).slice(-2) + "/" + ('00' + d).slice(-2));
                }
                let axc = new XlsAdxCell(this.headers[j], c);
                columns.push(axc);
            }
        }

        if (columns.length > 0) {
            list.push(columns);
        }
    }

    return list;
};

function XlsTableProviderHolder(provider, targets = []) {
    this.provider = provider;
    this.targets = targets;
}
XlsTableProviderHolder.prototype.addTarget = function (target) {
    this.targets.push(target);
};

function XlsCell(row, idx) {
    this.row = row;
    this.idx = idx;
}
XlsCell.prototype.relativeCell = function (row, idx) {
    return new XlsCell(this.row + row, this.idx + idx);
};
XlsCell.prototype.getCol2idx = function (c) {
    return c.split("").reduce((prev, c) => (prev * 26 + parseInt(c, 36) - 9), 0) - 1;
};
XlsCell.prototype.getIdx2col = function (n) {
    return ((n > 25 ? this.getIdx2col(Math.floor(n / 26 - 1)) : "") + (n % 26 + 10).toString(36).toUpperCase());
};
function Xls(tableHeader = [], targetHolders = [], year = 2022, month = 8) {
    this.talbleHeader = tableHeader;
    this.targetHolders = targetHolders;
    this.startcell = new XlsCell(1, 1);
    this.separaterowcell = 2;
    this.headerrowcell = 2;
    this.year = year;
    this.month = month;
}
Xls.prototype.getHeaderAdxCells = function () {
    let adxheader = [];
    if (this.talbleHeader?.length > 0) {
        for (let i in this.talbleHeader) {
            adxheader.push(new XlsAdxCell(this.talbleHeader[i], this.talbleHeader[i]));
        }
    } else {
        if (this.targetHolders?.length > 0) {
            if (this.targetHolders[0]?.length > 0) {
                for (let i in this.targetHolders[0][0].headers) {
                    adxheader.push(new XlsAdxCell(this.targetHolders[0][0].headers[i], this.targetHolders[0][0].headers[i]));
                }
            }
        }
    }

    return adxheader;
};
Xls.prototype.getTotalAreaStartCell = function () {
    return this.startcell.relativeCell(0, 0);
};
Xls.prototype.getCategoryAreaStartCell = function () {
    return this.getTotalAreaStartCell().relativeCell(this.headerrowcell + 31 + this.separaterowcell, 0);
};
Xls.prototype.addHolder = function (holder) {
    this.targetHolders.push(holder);
};
Xls.prototype.getBackendkey2CellType = function () {

};


function Styler() {

}
Styler.prototype.borderStyles = { top: { style: "thin" }, left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } };
Styler.prototype.headerFont = { name: 'Yu Gothic UI', color: { argb: 'FFFFFF' }, size: 11 };
Styler.prototype.headerFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '404040' }, bgColor: { argb: '404040' } };
Styler.prototype.dataFont = { name: 'Yu Gothic UI', color: { argb: '000000' }, size: 11 };
Styler.prototype.summaryFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF2CC' }, bgColor: { argb: 'FFF2CC' } };
Styler.prototype.headerAlignment = { vertical: 'middle', horizontal: 'center' };

function ExcelJSReport(xls) {
    this.xls = xls;
    this.workbook = new ExcelJS.Workbook();
    this.workbook.addWorksheet('report', { views: [{ zoomScale: 70 }] });
    this.worksheet = this.workbook.getWorksheet('report');

    this.styler = new Styler();
}
ExcelJSReport.prototype._draw_data_summary = function (targetrow, startrow, endrow, offset = 0) {
    let ahc = this.xls.getHeaderAdxCells();
    let woffset = 0;
    for (let i = 0; i < ahc.length; i++) {
        if (i == 1) {
            woffset = 1;
        }
        let ahtype = ahc[i].getType();

        let targetcell = targetrow.getCell(i + 1 + offset + woffset);
        targetcell.border = this.styler.borderStyles;
        targetcell.font = this.styler.dataFont;
        targetcell.fill = this.styler.summaryFill;
        if (i == 0) {
            targetrow.getCell(i + 1 + offset + 1).value = "";
            targetrow.getCell(i + 1 + offset + 1).border = this.styler.borderStyles;
            targetrow.getCell(i + 1 + offset + 1).fill = this.styler.summaryFill;
            targetrow.getCell(i + 1 + offset + 1).font = this.styler.dataFont;
        }

        if (ahtype == 1) {
            //
        } else if (ahtype >= 10 && ahtype <= 99) {
            let click = this._searchTagIndex("click");
            let imp = this._searchTagIndex("imp");
            let cost = this._searchTagIndex("cost_with_fee");
            let cc = targetrow.getCell(click + 1 + offset + woffset);
            let ic = targetrow.getCell(imp + 1 + offset + woffset);
            let sc = targetrow.getCell(cost + 1 + offset + woffset);

            switch (ahtype) {
                case 10:
                    targetcell.value = { formula: "IFERROR(" + cc.address + "/" + ic.address + ',"")' };
                    targetcell.numFmt = '0.00%';
                    break;
                case 11:
                    targetcell.value = { formula: "IFERROR(" + sc.address + "/" + cc.address + ',"")' };
                    targetcell.numFmt = '"¥"#,##0';
                    break;
                default:
                    targetcell.value = 0;
                    break;
            }
        } else if (ahtype >= 100 && ahtype <= 200) {
            let cbb = ahc[i].getCalBallBuilder().getBalls();
            let fml = "IFERROR(";
            for (let c in cbb) {
                let cb = cbb[c];
                if (cb.type == 0) {
                    fml += cb.value;
                } else {
                    fml += targetrow.getCell(this._searchIndexByBackendKey(cb.value) + 1 + offset + woffset).address;
                }
            }
            if (ahtype == 105) {
                fml += '*1000,"")';
            }else{
                fml += ',"")';
            }
            targetcell.value = { formula: fml };
            switch (ahtype) {
                case 100:
                case 103:
                case 104:
                case 105:
                    targetcell.numFmt = '"¥"#,##0';
                    break;
                default:
                    targetcell.numFmt = '0.00%';
                    break;
            }
        } else {
            targetcell.value = { formula: "SUM(" + startrow.getCell(i + 1 + offset + woffset).address + ":" + endrow.getCell(i + 1 + offset + woffset).address + ')' };
            switch (ahtype) {
                case 2:
                case 6:
                    targetcell.numFmt = '"¥"#,##0';
                    break;
                default:
                    targetcell.numFmt = '#,##0';
                    break;
            }
        }


    }
};
ExcelJSReport.prototype._draw_data_bar = function (startrow, endrow, offset = 0) {
    let ahc = this.xls.getHeaderAdxCells();

    let woffset = 0;
    for (let i = 0; i < ahc.length; i++) {
        if (i == 1) {
            woffset = 1;
        }
        let ahtype = ahc[i].getType();
        let cc = {
            ref: "D3:D33",
            rules: [
                {
                    type: "dataBar",
                    axisPosition: 'auto',
                    cfvo: [{ type: "num", value: 0 }, { type: "max" }],
                    color: { argb: "63C384" }
                }
            ]
        };

        let c3c = {
            ref: "I3:I33",
            rules: [
                {
                    type: "colorScale",
                    cfvo: [{ type: "min" }, { type: "percentile", value: 50 }, { type: "max" }],
                    color: [{ argb: "63BE7B" }, { argb: "FFEB84" }, { argb: "F8696B" }]
                }
            ]
        };

        switch (ahtype) {
            case 0:
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 13;
                break;
            case 1:
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 15;
                this.worksheet.getColumn(i + 1 + offset + woffset + 1).width = 5;
                break;
            case 2:
                cc.ref = startrow.getCell(i + 1 + offset + woffset).address + ":" + endrow.getCell(i + 1 + offset + woffset).address;
                cc.rules[0].color.argb = "FFB628";
                this.worksheet.addConditionalFormatting(cc);
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 13;
                break;
            case 5:
                cc.ref = startrow.getCell(i + 1 + offset + woffset).address + ":" + endrow.getCell(i + 1 + offset + woffset).address;
                cc.rules[0].color.argb = "FF555A";
                this.worksheet.addConditionalFormatting(cc);
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 13;
                break;
            case 10:
                cc.ref = startrow.getCell(i + 1 + offset + woffset).address + ":" + endrow.getCell(i + 1 + offset + woffset).address;
                this.worksheet.addConditionalFormatting(cc);
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 13;
                break;
            case 11:
                cc.ref = startrow.getCell(i + 1 + offset + woffset).address + ":" + endrow.getCell(i + 1 + offset + woffset).address;
                cc.rules[0].color.argb = "008AEF";
                this.worksheet.addConditionalFormatting(cc);
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 13;
                break;
            case 50:
                cc.ref = startrow.getCell(i + 1 + offset + woffset).address + ":" + endrow.getCell(i + 1 + offset + woffset).address;
                cc.rules[0].color.argb = "FF555A";
                this.worksheet.addConditionalFormatting(cc);
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 13;
                break;
            case 100:
                c3c.ref = startrow.getCell(i + 1 + offset + woffset).address + ":" + endrow.getCell(i + 1 + offset + woffset).address;
                this.worksheet.addConditionalFormatting(c3c);
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 13;
                break;
            case 101:
                cc.ref = startrow.getCell(i + 1 + offset + woffset).address + ":" + endrow.getCell(i + 1 + offset + woffset).address;
                cc.rules[0].color.argb = "638EC6";
                this.worksheet.addConditionalFormatting(cc);
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 13;
                break;
            case 102:
                c3c.ref = startrow.getCell(i + 1 + offset + woffset).address + ":" + endrow.getCell(i + 1 + offset + woffset).address;
                this.worksheet.addConditionalFormatting(c3c);
                this.worksheet.getColumn(i + 1 + offset + woffset).width = 13;
                break;

        }
    }

};
ExcelJSReport.prototype._maketitle = function (title = "") {
    let ts = title.split("_");

    if (ts.length > 1) {
        return ts[1];
    }

    return ts[0];
};
ExcelJSReport.prototype._draw_headerext = function (ahc, headerExStartr, offset = 0) {
    let tag = "";
    let expcnt = 0;
    let woffset = 0;
    for (let i = 0; i < ahc.length; i++) {
        if (i == 1) {
            woffset = 1;
        }
        let title = ahc[i].dataraw.getString();
        let ts = title.split("_");
        if (ts.length > 1) {
            if (tag.length > 0) {
                if (ts[0] != tag) {
                    //fix

                    let mcs = headerExStartr.getCell(i - expcnt + 1 + offset + woffset).address + ":" + headerExStartr.getCell(i + 1 - 1 + offset + woffset).address;
                    this.worksheet.mergeCells(mcs);
                    headerExStartr.getCell(i - expcnt + 1 + offset + woffset).value = tag;
                    headerExStartr.getCell(i - expcnt + 1 + offset + woffset).border = this.styler.borderStyles
                    headerExStartr.getCell(i - expcnt + 1 + offset + woffset).fill = this.styler.headerFill;
                    headerExStartr.getCell(i - expcnt + 1 + offset + woffset).font = this.styler.headerFont;
                    headerExStartr.getCell(i - expcnt + 1 + offset + woffset).alignment = this.styler.headerAlignment;

                    tag = "";
                    expcnt = 0;

                    tag = ts[0];
                    expcnt++;
                } else {
                    expcnt++;
                }
            } else {
                tag = ts[0];
                expcnt++;
            }
        } else {
            if (tag.length > 0) {
                // fix
                let mcs = headerExStartr.getCell(i - expcnt + 1 + offset + woffset).address + ":" + headerExStartr.getCell(i + 1 - 1 + offset + woffset).address;
                this.worksheet.mergeCells(mcs);
                headerExStartr.getCell(i - expcnt + 1 + offset + woffset).value = tag;
                headerExStartr.getCell(i - expcnt + 1 + offset + woffset).border = this.styler.borderStyles
                headerExStartr.getCell(i - expcnt + 1 + offset + woffset).fill = this.styler.headerFill;
                headerExStartr.getCell(i - expcnt + 1 + offset + woffset).font = this.styler.headerFont;
                headerExStartr.getCell(i - expcnt + 1 + offset + woffset).alignment = this.styler.headerAlignment;

                tag = "";
                expcnt = 0;
            }
        }
    }
    if (tag.length > 0) {
        // fix
        woffset = 1;
        let mcs = headerExStartr.getCell(ahc.length - (expcnt - 1) + offset + woffset).address + ":" + headerExStartr.getCell(ahc.length + offset + woffset).address;
        this.worksheet.mergeCells(mcs);
        headerExStartr.getCell(ahc.length - (expcnt - 1) + offset + woffset).value = tag;
        headerExStartr.getCell(ahc.length - (expcnt - 1) + offset + woffset).border = this.styler.borderStyles
        headerExStartr.getCell(ahc.length - (expcnt - 1) + offset + woffset).fill = this.styler.headerFill;
        headerExStartr.getCell(ahc.length - (expcnt - 1) + offset + woffset).font = this.styler.headerFont;
        headerExStartr.getCell(ahc.length - (expcnt - 1) + offset + woffset).alignment = this.styler.headerAlignment;

        tag = "";
        expcnt = 0;
    }
};
ExcelJSReport.prototype.draw_total = function () {
    // title
    let titleStartr = this.worksheet.getRow(this.xls.getTotalAreaStartCell().row);
    let titleCcnt = 1;
    titleStartr.getCell(titleCcnt).value = "▼TOTAL";
    titleStartr.getCell(titleCcnt).font = this.styler.dataFont;

    // header
    let headerStartr = this.worksheet.getRow(this.xls.getTotalAreaStartCell().relativeCell(1, 0).row);
    let ahc = this.xls.getHeaderAdxCells();
    let woffset = 0;
    for (let i = 0; i < ahc.length; i++) {
        if (i == 0) {
            headerStartr.getCell(i + 1 + 1).value = "";
            headerStartr.getCell(i + 1 + 1).border = this.styler.borderStyles;
            headerStartr.getCell(i + 1 + 1).fill = this.styler.headerFill;
            headerStartr.getCell(i + 1 + 1).font = this.styler.headerFont;
            headerStartr.getCell(i + 1 + 1).alignment = this.styler.headerAlignment;
        }
        if (i == 1) {
            woffset = 1;
        }
        headerStartr.getCell(i + 1 + woffset).value = this._maketitle(ahc[i].dataraw.getString());
        headerStartr.getCell(i + 1 + woffset).border = this.styler.borderStyles;
        headerStartr.getCell(i + 1 + woffset).fill = this.styler.headerFill;
        headerStartr.getCell(i + 1 + woffset).font = this.styler.headerFont;
        headerStartr.getCell(i + 1 + woffset).alignment = this.styler.headerAlignment;
    }
    let headerExStartr = this.worksheet.getRow(this.xls.getTotalAreaStartCell().relativeCell(0, 0).row);
    this._draw_headerext(ahc, headerExStartr);

    let datastartoffsetrow = 3;

    this._draw_data_summary(this.worksheet.getRow(this.xls.getTotalAreaStartCell().relativeCell(2, 0).row), this.worksheet.getRow(this.xls.getTotalAreaStartCell().relativeCell(datastartoffsetrow, 0).row), this.worksheet.getRow(this.xls.getTotalAreaStartCell().relativeCell(datastartoffsetrow + 30, 0).row));

    this._draw_data_bar(this.worksheet.getRow(this.xls.getTotalAreaStartCell().relativeCell(datastartoffsetrow, 0).row), this.worksheet.getRow(this.xls.getTotalAreaStartCell().relativeCell(datastartoffsetrow + 30, 0).row));
    for (let i = 0; i < 31; i++) {
        if (!isCheckDate(this.xls.year, this.xls.month, (i + 1))) {
            break;
        }
        let dayr = this.worksheet.getRow(this.xls.getTotalAreaStartCell().relativeCell(i + datastartoffsetrow, 0).row);

        let ahc = this.xls.getHeaderAdxCells();
        woffset = 0;
        for (let j = 0; j < ahc.length; j++) {
            if (j == 1) {
                woffset = 1;
            }
            let ahtype = ahc[j].getType();
            let targetcell = dayr.getCell(j + 1 + woffset);
            targetcell.border = this.styler.borderStyles;
            targetcell.font = this.styler.dataFont;

            if (ahtype == 1) {
                //日付
                targetcell.value = this.xls.year + "/" + this.xls.month + "/" + (i + 1);
                targetcell.numFmt = 'yyyy/mm/dd';
                let targetcell2 = dayr.getCell(j + 1 + woffset + 1);
                targetcell2.value = "";
                try {
                    targetcell2.value = datestr2w(this.xls.year + "/" + this.xls.month + "/" + (i + 1));
                } catch (ex) {
                    console.log(ex);
                }
                targetcell2.border = this.styler.borderStyles;
                targetcell2.font = this.styler.dataFont;

            } else if (ahtype >= 10 && ahtype <= 99) {
                let click = this._searchTagIndex("click");
                let imp = this._searchTagIndex("imp");
                let cost = this._searchTagIndex("cost_with_fee");
                let cc = dayr.getCell(click + 1 + woffset);
                let ic = dayr.getCell(imp + 1 + woffset);
                let sc = dayr.getCell(cost + 1 + woffset);

                switch (ahtype) {
                    case 10:
                        targetcell.value = { formula: "IFERROR(" + cc.address + "/" + ic.address + ',"")' };
                        targetcell.numFmt = '0.00%';
                        break;
                    case 11:
                        targetcell.value = { formula: "IFERROR(" + sc.address + "/" + cc.address + ',"")' };
                        targetcell.numFmt = '"¥"#,##0';
                        break;
                    default:
                        targetcell.value = 0;
                        break;
                }

            } else if (ahtype >= 100 && ahtype <= 200) {
                let cbb = ahc[j].getCalBallBuilder().getBalls();
                let fml = "IFERROR(";
                for (let c in cbb) {
                    let cb = cbb[c];
                    if (cb.type == 0) {
                        fml += cb.value;
                    } else {
                        fml += dayr.getCell(this._searchIndexByBackendKey(cb.value) + 1 + woffset).address;
                    }
                }
                if (ahtype == 105) {
                    fml += '*1000,"")';
                }else{
                    fml += ',"")';
                }
                targetcell.value = { formula: fml };
                switch (ahtype) {
                    case 100:
                    case 103:
                    case 104:
                    case 105:
                        targetcell.numFmt = '"¥"#,##0';
                        break;
                    default:
                        targetcell.numFmt = '0.00%';
                        break;
                }
            } else {
                let gcnt = this.xls.targetHolders.length;
                let casc = this.xls.getCategoryAreaStartCell();
                let c = "SUM(";
                for (let g = 0; g < gcnt; g++) {
                    let tr = this.worksheet.getRow(casc.relativeCell((i + (g * (31 + 4))) + datastartoffsetrow, 0).row);
                    c += tr.getCell(j + 1 + woffset).address;
                    if (g < gcnt - 1) {
                        c += "+";
                    }
                }
                c += ")";
                targetcell.value = { formula: c };
                switch (ahtype) {
                    case 2:
                    case 6:
                        targetcell.numFmt = '"¥"#,##0';
                        break;
                    default:
                        targetcell.numFmt = '#,##0';
                        break;
                }
            }
        }
    }
};
ExcelJSReport.prototype._draw_category_total = function (holder, cnt) {
    let offsetrow = this.xls.getCategoryAreaStartCell().row + (cnt * (31 + this.xls.separaterowcell + this.xls.headerrowcell));
    let categoryStartr = this.worksheet.getRow(offsetrow);
    let columnCcnt = 1;
    categoryStartr.getCell(columnCcnt).value = "▼" + holder.provider.label + " TOTAL";
    categoryStartr.getCell(columnCcnt).font = this.styler.dataFont;


    let headerStartr = this.worksheet.getRow(offsetrow + 1);
    let woffset = 0;
    for (let i = 0; i < this.xls.talbleHeader.length; i++) {
        if (i == 0) {
            headerStartr.getCell(i + 1 + 1).value = "";
            headerStartr.getCell(i + 1 + 1).border = this.styler.borderStyles;
            headerStartr.getCell(i + 1 + 1).fill = this.styler.headerFill;
            headerStartr.getCell(i + 1 + 1).font = this.styler.headerFont;
            headerStartr.getCell(i + 1 + 1).alignment = this.styler.headerAlignment;
        }
        if (i == 1) {
            woffset = 1;
        }
        headerStartr.getCell(i + 1 + woffset).value = this._maketitle(this.xls.talbleHeader[i].getString());
        headerStartr.getCell(i + 1 + woffset).border = this.styler.borderStyles;
        headerStartr.getCell(i + 1 + woffset).fill = this.styler.headerFill;
        headerStartr.getCell(i + 1 + woffset).font = this.styler.headerFont;
        headerStartr.getCell(i + 1 + woffset).alignment = this.styler.headerAlignment;
    }
    let headerExStartr = this.worksheet.getRow(offsetrow);
    this._draw_headerext(this.xls.getHeaderAdxCells(), headerExStartr);

    let datastartoffsetrow = 3;

    this._draw_data_summary(this.worksheet.getRow(offsetrow + 2), this.worksheet.getRow(offsetrow + datastartoffsetrow), this.worksheet.getRow(offsetrow + datastartoffsetrow + 30));
    this._draw_data_bar(this.worksheet.getRow(offsetrow + datastartoffsetrow), this.worksheet.getRow(offsetrow + datastartoffsetrow + 30));

    for (let d = 0; d < 31; d++) {
        if (!isCheckDate(this.xls.year, this.xls.month, (d + 1))) {
            break;
        }

        let ahc = this.xls.getHeaderAdxCells();
        let targetr = this.worksheet.getRow(offsetrow + datastartoffsetrow + d);
        let woffset = 0;
        for (let i = 0; i < ahc.length; i++) {
            if (i == 1) {
                woffset = 1;
            }
            let ahtype = ahc[i].getType();
            let targetcell = targetr.getCell(i + 1 + woffset);
            targetcell.border = this.styler.borderStyles;
            targetcell.font = this.styler.dataFont;

            if (ahtype == 1) {
                //日付
                targetcell.value = this.xls.year + "/" + this.xls.month + "/" + (d + 1);
                targetcell.numFmt = 'yyyy/mm/dd';
                let targetcell2 = targetr.getCell(i + 1 + woffset + 1);
                targetcell2.value = "";
                try {
                    targetcell2.value = datestr2w(this.xls.year + "/" + this.xls.month + "/" + (d + 1));
                } catch (ex) {
                    console.log(ex);
                }
                targetcell2.border = this.styler.borderStyles;
                targetcell2.font = this.styler.dataFont;
            } else if (ahtype >= 10 && ahtype <= 99) {
                let click = this._searchTagIndex("click");
                let imp = this._searchTagIndex("imp");
                let cost = this._searchTagIndex("cost_with_fee");
                let cc = targetr.getCell(click + 1 + woffset);
                let ic = targetr.getCell(imp + 1 + woffset);
                let sc = targetr.getCell(cost + 1 + woffset);

                switch (ahtype) {
                    case 10:
                        targetcell.value = { formula: "IFERROR(" + cc.address + "/" + ic.address + ',"")' };
                        targetcell.numFmt = '0.00%';
                        break;
                    case 11:
                        targetcell.value = { formula: "IFERROR(" + sc.address + "/" + cc.address + ',"")' };
                        targetcell.numFmt = '"¥"#,##0';
                        break;
                    default:
                        targetcell.value = 0;
                        break;
                }

            } else if (ahtype >= 100 && ahtype <= 200) {
                let cbb = ahc[i].getCalBallBuilder().getBalls();
                let fml = "IFERROR(";
                for (let j in cbb) {
                    let cb = cbb[j];
                    if (cb.type == 0) {
                        fml += cb.value;
                    } else {
                        fml += targetr.getCell(this._searchIndexByBackendKey(cb.value) + 1 + woffset).address;
                    }
                }
                if (ahtype == 105) {
                    fml += '*1000,"")';
                }else{
                    fml += ',"")';
                }
                targetcell.value = { formula: fml };
                switch (ahtype) {
                    case 100:
                    case 103:
                    case 104:
                    case 105:
                        targetcell.numFmt = '"¥"#,##0';
                        break;
                    default:
                        targetcell.numFmt = '0.00%';
                        break;
                }
            } else {
                let tlen = holder.targets.length;
                if (tlen > 0) {
                    let c = "SUM(";
                    for (let t = 0; t < tlen; t++) {
                        c += targetr.getCell(i + ((t + 1) * (ahc.length + 1)) + 1 + (t + 1) + 1).address;
                        if (t < tlen - 1) {
                            c += "+";
                        }
                    }
                    c += ")";
                    targetcell.value = { formula: c };
                } else {
                    targetcell.value = 0;
                }
                switch (ahtype) {
                    case 2:
                    case 6:
                        targetcell.numFmt = '"¥"#,##0';
                        break;
                    default:
                        targetcell.numFmt = '#,##0';
                        break;
                }

            }
        }
    }
};

ExcelJSReport.prototype._searchTagIndex = function (tag, srcBackendkey = "") {
    let sbs = srcBackendkey.split("_");
    let sbks = "";
    let sbktag = "";
    for (let i = 0; i < sbs.length; i++) {
        if (i == sbs.length - 1) {
            sbktag = sbs[i];
        } else {
            sbks += sbs[i];
        }
    }
    let ahc = this.xls.getHeaderAdxCells();
    for (let i = 0; i < ahc.length; i++) {
        if (sbks == "") {
            if (ahc[i].headerraw.backendKey == tag) {
                return i;
            }
        } else {
            //TBD
            // console.log(sbks, sbktag);
            if (sbks || sbktag) {
                //
            }
        }
    }

    return -1;
};
ExcelJSReport.prototype._searchIndexByBackendKey = function (backendKey) {
    let ahc = this.xls.getHeaderAdxCells();
    for (let i = 0; i < ahc.length; i++) {
        if (ahc[i].headerraw.backendKey == backendKey) {
            return i;
        }
    }

    return -1;
};


ExcelJSReport.prototype._draw_category_data = function (category, providercnt, cnt, providername = "") {
    let offsetrow = this.xls.getCategoryAreaStartCell().row + (providercnt * (31 + this.xls.separaterowcell + this.xls.headerrowcell));

    let ahc = this.xls.getHeaderAdxCells();

    let headertitler = this.worksheet.getRow(offsetrow);
    headertitler.getCell(1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1)).value = "▼" + providername + " " + category.category;
    headertitler.getCell(1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1)).font = this.styler.dataFont;

    this._draw_headerext(ahc, headertitler, ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1));

    let headerStartr = this.worksheet.getRow(offsetrow + 1);
    let woffset = 0;
    for (let i = 0; i < ahc.length; i++) {
        if (i == 0) {
            headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + 1).value = "";
            headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + 1).border = this.styler.borderStyles;
            headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + 1).fill = this.styler.headerFill;
            headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + 1).font = this.styler.headerFont;
            headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + 1).alignment = this.styler.headerAlignment;
        }
        if (i == 1) {
            woffset = 1;
        }
        headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + woffset).value = this._maketitle(ahc[i].dataraw.getString());
        headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + woffset).border = this.styler.borderStyles;
        headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + woffset).fill = this.styler.headerFill;
        headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + woffset).font = this.styler.headerFont;
        headerStartr.getCell(i + 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1) + woffset).alignment = this.styler.headerAlignment;
    }

    let datastartoffsetrow = 3;

    this._draw_data_summary(this.worksheet.getRow(offsetrow + 2), this.worksheet.getRow(offsetrow + datastartoffsetrow), this.worksheet.getRow(offsetrow + datastartoffsetrow + 30), ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1));
    this._draw_data_bar(this.worksheet.getRow(offsetrow + datastartoffsetrow), this.worksheet.getRow(offsetrow + datastartoffsetrow + 30), ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1));

    let axc = category.getAdx31DayCells(this.xls.year, this.xls.month);
    for (let d = 0; d < axc.length; d++) {
        if (!isCheckDate(this.xls.year, this.xls.month, (d + 1))) {
            break;
        }

        let targetr = this.worksheet.getRow(offsetrow + datastartoffsetrow + d);
        // console.log(axc[d]);
        let woffset = 0;
        for (let c = 0; c < axc[d].length; c++) {
            let offsetcell = 1 + ((cnt + 1) * ahc.length) + ((cnt + 1) * 1) + ((cnt + 1) * 1);
            if (c == 1) {
                woffset = 1;
            }

            let targetcell = targetr.getCell(c + offsetcell + woffset);
            let ahtype = axc[d][c].getType();
            targetcell.border = this.styler.borderStyles;
            targetcell.font = this.styler.dataFont;

            if (ahtype == 1) {
                targetcell.value = axc[d][c].dataraw.data;
                targetcell.numFmt = 'yyyy/mm/dd';
                let targetcell2 = targetr.getCell(c + offsetcell + woffset + 1);
                targetcell2.value = "";
                try {
                    targetcell2.value = datestr2w(axc[d][c].dataraw.data);
                } catch (ex) {
                    console.log(ex);
                }
                targetcell2.border = this.styler.borderStyles;
                targetcell2.font = this.styler.dataFont;
            } else if (ahtype >= 10 && ahtype <= 99) {
                let click = this._searchTagIndex("click");
                let imp = this._searchTagIndex("imp");
                let cost = this._searchTagIndex("cost_with_fee");
                let cc = targetr.getCell(click + offsetcell + woffset);
                let ic = targetr.getCell(imp + offsetcell + woffset);
                let sc = targetr.getCell(cost + offsetcell + woffset);

                switch (ahtype) {
                    case 10:
                        targetcell.value = { formula: "IFERROR(" + cc.address + "/" + ic.address + ',"")' };
                        targetcell.numFmt = '0.00%';
                        break;
                    case 11:
                        targetcell.value = { formula: "IFERROR(" + sc.address + "/" + cc.address + ',"")' };
                        targetcell.numFmt = '"¥"#,##0';
                        break;
                    default:
                        targetcell.value = axc[d][c].dataraw.data;
                        break;
                }

            } else if (ahtype >= 100 && ahtype <= 200) {
                let cbb = axc[d][c].getCalBallBuilder().getBalls();
                let fml = "IFERROR(";
                for (let i in cbb) {
                    let cb = cbb[i];
                    if (cb.type == 0) {
                        fml += cb.value;
                    } else {
                        fml += targetr.getCell(offsetcell + this._searchIndexByBackendKey(cb.value) + woffset).address;
                    }
                }
                if (ahtype == 105) {
                    fml += '*1000,"")';
                }else{
                    fml += ',"")';
                }
                targetcell.value = { formula: fml };
                switch (ahtype) {
                    case 100:
                    case 103:
                    case 104:
                    case 105:
                        targetcell.numFmt = '"¥"#,##0';
                        break;
                    default:
                        targetcell.numFmt = '0.00%';
                        break;
                }
            } else {
                targetcell.value = Number(axc[d][c].dataraw.data);
                switch (ahtype) {
                    case 2:
                    case 6:
                        targetcell.numFmt = '"¥"#,##0';
                        break;
                    default:
                        targetcell.numFmt = '#,##0';
                        break;
                }
            }
        }
    }
    // console.log("axc", axc);
};
ExcelJSReport.prototype.draw_category = function () {
    for (let g = 0; g < this.xls.targetHolders.length; g++) {
        // title
        let holder = this.xls.targetHolders[g];
        this._draw_category_total(holder, g);
        for (let c = 0; c < holder.targets.length; c++) {
            let category = holder.targets[c];
            this._draw_category_data(category, g, c, holder.provider.label);
        }
    }
};
ExcelJSReport.prototype.hide_cells = function () {
    let ahc = this.xls.getHeaderAdxCells();
    for (let i = 0 ; i < ahc.length; i++) {
        if (ahc[i].headerraw?.data.indexOf("(i)") >= 0) {
            let ahclen = ahc.length;
            for (let j = 0 ; j < 20 ; j++) {
                const dobCol = this.worksheet.getColumn((i + 1) + 1+(((ahclen + 1) + 1) * j));
                dobCol.hidden = true;
            }
        }
    }
};
ExcelJSReport.prototype.downdload = async function (name = "") {
    this.draw_total();
    this.draw_category();
    this.hide_cells();
    const uint8Array = await this.workbook.xlsx.writeBuffer(); // csvの場合
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const a = document.createElement('a');
    a.href = (window.URL || window.webkitURL).createObjectURL(blob);
    a.download = name + `_予算管理.xlsx`;
    a.click();
    a.remove()

};

SummaryPage.prototype.xlsx = async function () {
    //make target
    let cym = this.currentMonth.split("/");
    let xls = new Xls(this.getProviderTableHeader(), [], Number(cym[0]), Number(cym[1]));
    let ptpl = this.getProviderTableProviderList().filter((p) => p.name != 'ALL');
    for (let i in ptpl) {
        let holder = new XlsTableProviderHolder(ptpl[i]);
        let ptcl = this.getProviderTableCategoryList(ptpl[i].name, 'daily').filter((p) => p != 'ALL');
        for (let j in ptcl) {
            let pts = this.getProviderTable(ptpl[i].name, ptcl[j], 'daily');
            let target = new XlsTableTarget(ptcl[j], pts, this.getProviderTableHeader());
            holder.addTarget(target);
        }
        xls.addHolder(holder);
    }

    let report = new ExcelJSReport(xls);

    let name = "";
    for (let key in this.user.advertizers) {
        let adv = this.user.advertizers[key];
        if (adv.id == this.advertizerId) {
            name = adv.name;
            break;
        }
    }

    report.downdload(name);

};
SummaryPage.prototype.isCurrentMonth = function() {
    if (this.currentMonth) {
        let ym = this.currentMonth.split("/");
        if (new Date().getFullYear() == Number(ym[0]) && (new Date().getMonth() + 1) == Number(ym[1])) {
            return true
        }
    }
    return false
}


export { SummaryPage }
