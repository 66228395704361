<template>
  <div id="lay_header_body" class="header_layer">
    <div class="header_wrapper">
      <div class="header">
        <div class="header__logo" :class="[isSandBox() ? 'header__logo--sandbox' : '']"><img src="@/assets/icon/adxlogo.png" height="100" /></div>
        <div
          class="header__logo__line"
          v-on:click="devdoor"
          style="z-index: 100"
          v-show="isConsultant()"
        ></div>
        <div
          class="header__logo__line2"
          :class="[isAdvertizer() ? 'header__logo__line2--adv' : '']"
        >
          <div
            class="header_name_box"
            :class="[!isConsultant() ? 'header_name_box--advertizer' : '']"
          >
            <div v-if="!isManager() && !isAdvertizer() && false">{{ adx.user.name }}</div>
            <div class="selectermenu clickable" v-if="isManager() || isConsultant()" v-on:click="gotoManager">
              {{ enableAdvertizerSelector ? '全アカウントサマリー >' : '全アカウントサマリー' }}
            </div>
            <div v-if="enableAdvertizerSelector">
              <div
                v-on:click="showSelector()"
                :class="[!isConsultant() ? 'slt_adv_select--advertizer' : '']"
                style="display: flex; padding-right: 8px; cursor: pointer;"
              >
                <div style="margin-right: auto">
                  {{ selectedAdvertizerName() }}
                </div>
                <div style="width: 16px">
                  <img style="width: 16px; height: 16px" src="@/assets/icon/arrow_down_open.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="header__logo__line3"
          v-on:click="devdoor"
          style="z-index: 100"
          v-show="isConsultant()"
        ></div>
        <div class="header__body" :class="[isAdvertizer() ? 'header__body--adv' : '']">
          <router-link
            :to="
              ['consultant', 'root', 'manager'].includes(adx.user.nowRole())
                ? ('/consultant/summary'+'?'+makeStatusQuery())
                : ('/report'+'?'+makeStatusQuery())
            "
          >
            <div
              class="header__menu"
              v-bind:class="{ 'header__menu--active': screen == 'keyword' }"
            >
              <div class="header__menu_icon">
                <svg
                  v-if="!isAdvertizer()"
                  xmlns="http://www.w3.org/2000/svg"
                  height="32"
                  width="32"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#FFFFFF"
                    d="M14.2 34.15h3V23.9h-3Zm16.6 0h3v-21h-3Zm-8.3 0h3v-5.9h-3Zm0-10.25h3v-3h-3ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z"
                  />
                </svg>
                <svg
                  v-if="isAdvertizer()"
                  xmlns="http://www.w3.org/2000/svg"
                  height="32"
                  width="32"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#FFFFFF"
                    d="M15.45 16.95q.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45Zm0 8.55q.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45Zm0 8.55q.6 0 1.05-.45.45-.45.45-1.05 0-.6-.45-1.05-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05.45.45 1.05.45ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h23.1l9.9 9.9V39q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V17.55h-8.55V9H9v30ZM9 9v8.55V9v30V9Z"
                  />
                </svg>
              </div>
              <div class="header__menu_text" v-if="!isAdvertizer()">レポート</div>
              <div class="header__menu_text" v-if="isAdvertizer()">サマリー</div>
            </div>
          </router-link>
          <router-link :to="'/report/adprovider'+'?'+makeStatusQuery()" v-if="isAdvertizer()">
            <div
              class="header__menu"
              v-bind:class="{ 'header__menu--active': screen == 'adprovider' }"
            >
              <div class="header__menu_icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 48 48">
                  <path
                    fill="#FFFFFF"
                    d="M14.2 34.15h3V23.9h-3Zm16.6 0h3v-21h-3Zm-8.3 0h3v-5.9h-3Zm0-10.25h3v-3h-3ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z"
                  />
                </svg>
              </div>
              <div class="header__menu_text">媒体別レポート</div>
            </div>
          </router-link>

          <router-link :to="'/consultant/customreport'+'?'+makeStatusQuery()" v-if="(isAdvertizer() == true) && (adx.isExistCstCustomReport == true)">
            <div
              class="header__menu"
              v-bind:class="{ 'header__menu--active': screen == 'customreport' }"
            >
              <div class="header__menu_icon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 135.83 135.83" style="margin-top: 4px">
                <g id="カスタムレポートアイコン" clip-path="url(#clip-カスタムレポートアイコン)">
                  <rect id="長方形_1" data-name="長方形 1" width="136" height="136" fill="#3b424d"/>
                  <path id="analytics_FILL0_wght400_GRAD0_opsz48_2_" data-name="analytics_FILL0_wght400_GRAD0_opsz48 (2)" d="M150.94,322.214h11.319V283.539H150.94Zm62.634,0h11.319V242.978H213.574Zm-31.317,0h11.319V299.953H182.257Zm0-38.675h11.319V272.22H182.257Zm-50.937,68.294A11.606,11.606,0,0,1,120,340.514V227.319A11.606,11.606,0,0,1,131.319,216H244.514a11.606,11.606,0,0,1,11.319,11.319V340.514a11.606,11.606,0,0,1-11.319,11.319Zm0-11.319H244.514V227.319H131.319Zm0-113.194v0Z" transform="translate(-121.674 -216)" fill="#fff"/>
                  <circle id="楕円形_1" data-name="楕円形 1" cx="39" cy="39" r="39" transform="translate(69 67.956)" fill="#3b424d"/>
                  <path id="settings_FILL0_wght400_GRAD0_opsz48_1_" data-name="settings_FILL0_wght400_GRAD0_opsz48 (1)" d="M101.874,232.817l-1.42-8.949a19.036,19.036,0,0,1-2.841-1.349,19.524,19.524,0,0,1-2.628-1.776l-8.38,3.835L80,212.931l7.67-5.611a8.443,8.443,0,0,1-.178-1.456q-.035-.817-.036-1.456t.036-1.456a8.445,8.445,0,0,1,.178-1.456L80,195.886l6.6-11.647,8.38,3.835a19.518,19.518,0,0,1,2.628-1.775,14.7,14.7,0,0,1,2.841-1.278l1.42-9.02h13.068l1.42,8.949a23.03,23.03,0,0,1,2.876,1.314,12.225,12.225,0,0,1,2.592,1.811l8.38-3.835,6.6,11.647-7.67,5.469a10.006,10.006,0,0,1,.178,1.527q.035.817.035,1.527t-.035,1.491a9.89,9.89,0,0,1-.178,1.492l7.67,5.54-6.6,11.647-8.38-3.835a23.561,23.561,0,0,1-2.592,1.811,12.378,12.378,0,0,1-2.876,1.314l-1.42,8.949Zm6.534-19.176a9.214,9.214,0,1,0-6.534-2.7A8.9,8.9,0,0,0,108.408,213.641Zm0-4.261a4.971,4.971,0,1,1,3.516-1.456A4.79,4.79,0,0,1,108.408,209.38ZM108.408,204.408Zm-3.125,24.147h6.25l.994-7.954a16.75,16.75,0,0,0,4.439-1.776,18.2,18.2,0,0,0,3.8-2.912l7.528,3.267,2.841-5.114-6.676-4.9q.284-1.207.462-2.379a15.86,15.86,0,0,0,.178-2.379,19.754,19.754,0,0,0-.142-2.379,14.7,14.7,0,0,0-.5-2.379l6.676-4.9-2.841-5.114-7.528,3.267a15.831,15.831,0,0,0-3.693-3.089,11.979,11.979,0,0,0-4.545-1.6l-.994-7.954h-6.25l-.994,7.954a15.048,15.048,0,0,0-4.51,1.7,15.662,15.662,0,0,0-3.729,2.983l-7.528-3.267-2.841,5.114,6.676,4.9q-.284,1.207-.462,2.379a16.03,16.03,0,0,0,0,4.758q.178,1.172.462,2.379l-6.676,4.9,2.841,5.114,7.528-3.267a18.2,18.2,0,0,0,3.8,2.912,16.75,16.75,0,0,0,4.439,1.776Z" transform="translate(-1.518 -96.937)" fill="#fff"/>
                </g>
              </svg>
              </div>
              <div class="header__menu_text">カスタムレポート</div>
            </div>
          </router-link>

          <div
            class="header__menu"
            v-if="(isAdvertizer() == true) && (adx.isExistCstCustomReport == false)"
          >
            <div class="header__menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 135.83 135.83" style="margin-top: 4px">
                <g id="カスタムレポートアイコン" clip-path="url(#clip-カスタムレポートアイコン)">
                  <rect id="長方形_1" data-name="長方形 1" width="136" height="136" fill="#3b424d"/>
                  <path id="analytics_FILL0_wght400_GRAD0_opsz48_2_" data-name="analytics_FILL0_wght400_GRAD0_opsz48 (2)" d="M150.94,322.214h11.319V283.539H150.94Zm62.634,0h11.319V242.978H213.574Zm-31.317,0h11.319V299.953H182.257Zm0-38.675h11.319V272.22H182.257Zm-50.937,68.294A11.606,11.606,0,0,1,120,340.514V227.319A11.606,11.606,0,0,1,131.319,216H244.514a11.606,11.606,0,0,1,11.319,11.319V340.514a11.606,11.606,0,0,1-11.319,11.319Zm0-11.319H244.514V227.319H131.319Zm0-113.194v0Z" transform="translate(-121.674 -216)" fill="#888"/>
                  <circle id="楕円形_1" data-name="楕円形 1" cx="39" cy="39" r="39" transform="translate(69 67.956)" fill="#3b424d"/>
                  <path id="settings_FILL0_wght400_GRAD0_opsz48_1_" data-name="settings_FILL0_wght400_GRAD0_opsz48 (1)" d="M101.874,232.817l-1.42-8.949a19.036,19.036,0,0,1-2.841-1.349,19.524,19.524,0,0,1-2.628-1.776l-8.38,3.835L80,212.931l7.67-5.611a8.443,8.443,0,0,1-.178-1.456q-.035-.817-.036-1.456t.036-1.456a8.445,8.445,0,0,1,.178-1.456L80,195.886l6.6-11.647,8.38,3.835a19.518,19.518,0,0,1,2.628-1.775,14.7,14.7,0,0,1,2.841-1.278l1.42-9.02h13.068l1.42,8.949a23.03,23.03,0,0,1,2.876,1.314,12.225,12.225,0,0,1,2.592,1.811l8.38-3.835,6.6,11.647-7.67,5.469a10.006,10.006,0,0,1,.178,1.527q.035.817.035,1.527t-.035,1.491a9.89,9.89,0,0,1-.178,1.492l7.67,5.54-6.6,11.647-8.38-3.835a23.561,23.561,0,0,1-2.592,1.811,12.378,12.378,0,0,1-2.876,1.314l-1.42,8.949Zm6.534-19.176a9.214,9.214,0,1,0-6.534-2.7A8.9,8.9,0,0,0,108.408,213.641Zm0-4.261a4.971,4.971,0,1,1,3.516-1.456A4.79,4.79,0,0,1,108.408,209.38ZM108.408,204.408Zm-3.125,24.147h6.25l.994-7.954a16.75,16.75,0,0,0,4.439-1.776,18.2,18.2,0,0,0,3.8-2.912l7.528,3.267,2.841-5.114-6.676-4.9q.284-1.207.462-2.379a15.86,15.86,0,0,0,.178-2.379,19.754,19.754,0,0,0-.142-2.379,14.7,14.7,0,0,0-.5-2.379l6.676-4.9-2.841-5.114-7.528,3.267a15.831,15.831,0,0,0-3.693-3.089,11.979,11.979,0,0,0-4.545-1.6l-.994-7.954h-6.25l-.994,7.954a15.048,15.048,0,0,0-4.51,1.7,15.662,15.662,0,0,0-3.729,2.983l-7.528-3.267-2.841,5.114,6.676,4.9q-.284,1.207-.462,2.379a16.03,16.03,0,0,0,0,4.758q.178,1.172.462,2.379l-6.676,4.9,2.841,5.114,7.528-3.267a18.2,18.2,0,0,0,3.8,2.912,16.75,16.75,0,0,0,4.439,1.776Z" transform="translate(-1.518 -96.937)" fill="#888"/>
                </g>
              </svg>
            </div>
            <div class="header__menu_text" style="color:#888">カスタムレポート</div>
          </div>


          <router-link :to="'/consultant/sprint'+'?'+makeStatusQuery()" v-if="isConsultant()">
            <div class="header__menu" v-bind:class="{ 'header__menu--active': screen == 'sprint' }">
              <div class="header__menu_icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 48 48">
                  <path
                    fill="#FFFFFF"
                    d="M8.35 40v-3h6.5l-.75-.6q-3.2-2.55-4.65-5.55-1.45-3-1.45-6.7 0-5.3 3.125-9.525Q14.25 10.4 19.35 8.8v3.1q-3.75 1.45-6.05 4.825T11 24.15q0 3.15 1.175 5.475 1.175 2.325 3.175 4.025l1.5 1.05v-6.2h3V40Zm20.35-.75V36.1q3.8-1.45 6.05-4.825T37 23.85q0-2.4-1.175-4.875T32.75 14.6l-1.45-1.3v6.2h-3V8h11.5v3h-6.55l.75.7q3 2.8 4.5 6t1.5 6.15q0 5.3-3.1 9.55-3.1 4.25-8.2 5.85Z"
                  />
                </svg>
              </div>
              <div class="header__menu_text">スプリント</div>
            </div>
          </router-link>
          <router-link :to="'/log'+'?'+makeStatusQuery()">
            <div class="header__menu" v-bind:class="{ 'header__menu--active': screen == 'log' }">
              <div class="header__menu_icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 48 48">
                  <path
                    fill="#FFFFFF"
                    d="M24 42v-3.55l10.8-10.8 3.55 3.55L27.55 42ZM6 31.5v-3h15v3Zm34.5-2.45-3.55-3.55 1.45-1.45q.4-.4 1.05-.4t1.05.4l1.45 1.45q.4.4.4 1.05t-.4 1.05ZM6 23.25v-3h23.5v3ZM6 15v-3h23.5v3Z"
                  />
                </svg>
              </div>
              <div class="header__menu_text">施策ログ</div>
            </div>
          </router-link>
          <router-link :to="'/budget'+'?'+makeStatusQuery()" v-if="isConsultant()">
            <div class="header__menu" v-bind:class="{ 'header__menu--active': screen == 'budget' }">
              <div class="header__menu_icon">
                <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 48 48">
                  <path
                    fill="#FFFFFF"
                    d="M6 42v-3.8l3-3V42Zm8.25 0V30.2l3-3V42Zm8.25 0V27.2l3 3.05V42Zm8.25 0V30.25l3-3V42ZM39 42V22.2l3-3V42ZM6 30.2v-4.25l14-13.9 8 8L42 6v4.25L28 24.3l-8-8Z"
                  />
                </svg>
              </div>
              <div class="header__menu_text">予算決定</div>
            </div>
          </router-link>
          <router-link to="/news" v-if="false">
            <div class="header__menu" v-bind:class="{ 'header__menu--active': screen == 'news' }">
              <div class="header__menu_icon">
                <img width="32" height="32" src="@/assets/icon/highlight-24px.svg" />
              </div>
              <div class="header__menu_text">最新情報</div>
            </div>
          </router-link>
          <div v-if="!isConsultant()" style="margin-left: auto"></div>
          <div
            class="header__menu"
            v-if="adx.excelreport.isReady"
            style="margin-left: auto; cursor: pointer"
            v-on:click="excelreport()"
          >
            <div class="header__menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 24 24" width="32">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  fill="#fff"
                  d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"
                />
              </svg>
            </div>
            <div class="header__menu_text" v-if="isAdvertizer()">日別データ</div>
            <div class="header__menu_text" v-if="!isAdvertizer()">予算管理</div>
          </div>
          <div
            class="header__menu"
            v-if="!adx.excelreport.isReady"
            style="margin-left: auto; cursor: pointer"
          >
            <div class="header__menu_icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 24 24" width="32">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  fill="#888"
                  d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"
                />
              </svg>
            </div>
            <div class="header__menu_text" style="color: #888" v-if="isAdvertizer()">日別データ</div>
            <div class="header__menu_text" style="color: #888" v-if="!isAdvertizer()">予算管理</div>
          </div>
          <div class="header__menu" style="background-color:rgba(0,0,0,0)">
            <div class="header__menu_icon" v-if="adx.lastUpdateTime.length > 0">
              <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 48 48" width="32">
                <path
                  fill="#fff"
                  d="M24.15 42q-3.75 0-7.05-1.425T11.325 36.7q-2.475-2.45-3.9-5.75Q6 27.65 6 23.9t1.425-7q1.425-3.25 3.9-5.675Q13.8 8.8 17.1 7.4 20.4 6 24.15 6q4 0 7.575 1.75t6.175 4.8v-5.3h3v10.4H30.45v-3h5.25q-2.2-2.55-5.175-4.1Q27.55 9 24.15 9q-6.25 0-10.7 4.275Q9 17.55 9 23.75q0 6.35 4.4 10.8Q17.8 39 24.15 39q6.25 0 10.55-4.4Q39 30.2 39 23.95h3q0 7.5-5.2 12.775T24.15 42Zm6.1-9.85-7.7-7.6v-10.7h3v9.45L32.4 30Z"
                />
              </svg>
            </div>
            <div class="header__menu_icon" v-if="adx.lastUpdateTime.length == 0">
              <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 48 48" width="32">
                <path
                  fill="#888"
                  d="M24.15 42q-3.75 0-7.05-1.425T11.325 36.7q-2.475-2.45-3.9-5.75Q6 27.65 6 23.9t1.425-7q1.425-3.25 3.9-5.675Q13.8 8.8 17.1 7.4 20.4 6 24.15 6q4 0 7.575 1.75t6.175 4.8v-5.3h3v10.4H30.45v-3h5.25q-2.2-2.55-5.175-4.1Q27.55 9 24.15 9q-6.25 0-10.7 4.275Q9 17.55 9 23.75q0 6.35 4.4 10.8Q17.8 39 24.15 39q6.25 0 10.55-4.4Q39 30.2 39 23.95h3q0 7.5-5.2 12.775T24.15 42Zm6.1-9.85-7.7-7.6v-10.7h3v9.45L32.4 30Z"
                />
              </svg>
            </div>
            <div class="header__menu_text" v-if="adx.lastUpdateTime.length > 0">
              {{ adx.lastUpdateTime }}
            </div>
          </div>

          <router-link to="/news" v-if="false">
            <div class="header__menu" v-if="isConsultant()" style="cursor: pointer">
              <div class="header__menu_icon">
                <img width="32" height="32" src="@/assets/icon/notifications-24px.svg" />
              </div>
              <div class="header__menu_text">お知らせ</div>
            </div>
          </router-link>

          <div class="header__user">
            <div class="header__user_menu" v-if="false"></div>
            <!--
            <div class="header__user_menu clickable">
              <img width="36" height="36" src="@/assets/icon/help-24px.svg" />
            </div>
            -->
            <router-link to="/news" v-if="false">
              <div class="header__user_menu clickable">
                <img width="36" height="36" src="@/assets/icon/notifications-24px.svg" />
              </div>
            </router-link>
            <div class="header__user_menu" v-if="false"></div>

            <div class="header__user_login">
              <div
                v-if="false"
                style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                :title="adx.user.name"
              >
                {{ adx.user.name }}
              </div>
              <div
                v-on:click="menuClick($event)"
                style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; position: relative; padding-right: 20px; cursor: pointer;"
                :title="adx.user.email"
              >
                {{ adx.user.email }}<img style="position: absolute; top: 0; right: 0;" width="16" height="16" src="@/assets/icon/settingselect.png" />
              </div>
              <div></div>
              <div v-if="false">
                {{
                  adx.user.role == 'root'
                    ? 'ルート権限'
                    : adx.user.role == 'manager'
                    ? '監督者'
                    : adx.user.role == 'advertizer'
                    ? ''
                    : adx.user.role == 'consultant'
                    ? 'コンサルタント'
                    : adx.user.role
                }}
              </div>
              <div class="header__user_login_menu" v-if="false">
                <div v-on:click="logout()">ログアウト</div>
                <div v-if="adx.user.role != 'advertizer'" v-on:click="changeCustomer()">顧客画面</div>
                <div v-if="adx.user.role != 'advertizer'" v-on:click="changeConsultant()">コンサル画面</div>
              </div>
            </div>
            <div class="header__user_icon clickable" v-if="false">
              <img src="@/assets/person48px.png" v-on:click="logout()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="closer_adv_select_layer" v-if="isShowSelector" v-on:click="onClickEvent"></div>
  <div id="lay_adv_selector" class="adv_selector_layer">
    <div class="adv_selector_area">
      <div
        class="adv_selector_content"
        :class="[isAdvertizer() ? 'adv_selector_content--adv' : '']"
      >
        <div class="adv_selector_box" v-if="isShowSelector">
          <div class="adv_selector_box__search">
            <div class="adv_selector_box__search_icon_area">
              <img style="margin-top: 8px" src="@/assets/icon/search.png" />
            </div>
            <div class="adv_selector_box__search_input_area">
              <input
                class="adv_selector_box__search_input"
                placeholder="入力して検索"
                style="pointer-events: all"
                type="text"
                v-model="keyword"
              />
            </div>
          </div>
          <div class="adv_selector_box__itemlist">
            <template v-for="(item, index) in advertizerOptions()" :key="index">
              <div
                :class="[item.value == selectedAdvertizerId ? 'selector_item--selected' : '']"
                class="selector_item"
                v-on:click="onRecordSelect(item.value)"
                v-if="isShowRecord(item.text)"
              >
                {{ item.text }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="lay_message"
    style="position: fixed; top: 80px; left: 1350px; z-index: 100"
    v-if="isMessageShow"
  >
    <div
      style="
        background: white;
        width: 400px;
        height: 520px;
        border-radius: 10px;
        padding: 15px;
        box-shadow: 0px 2px 10px #00000027;
      "
    >
      <div>おしらせ</div>
    </div>
  </div>
  <div
    style="
      position: fixed;
      top: 100px;
      left: 0px;
      width: 300px;
      z-index: 100;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
    "
    v-if="isNetworkTest"
  >
    <div style="padding: 5px">
      <div>通信テスト</div>
      <div>{{ grpcTestHost }}</div>
      <div v-if="isGrpcTesting">GRPC TEST...</div>
      <div v-if="isHttpTesting">HTTP TEST...</div>
      <div v-if="!isGrpcTesting" style="padding: 5px">
        <div>GRPC</div>
        <div style="padding: 5px">
          <div v-bind:class="[grpcStatusCode == 0 ? 'success' : 'danger']">
            Status: {{ grpcStatusCode }}
          </div>
          <div>Detail: {{ grpcStatusDetail }}</div>
        </div>
      </div>

      <div v-if="!isHttpTesting" style="padding: 5px">
        <div>HTTP</div>
        <div style="padding: 5px">
          <div v-bind:class="[httpStatusCode == 'OK' ? 'success' : 'danger']">
            {{ httpStatusCode }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="settingMenuPositionY != 0 && settingMenuPositionX != 0" style="position: fixed; top:0; left: 0; right:0; bottom: 0; z-index: 200;" v-on:click="closeSettingMenu"></div>
  <div v-if="settingMenuPositionY != 0 && settingMenuPositionX != 0" class="setting_menu"
  :style="{ top: `${settingMenuPositionY}px`, left: `${settingMenuPositionX}px` }"
  >
    <img style="position: absolute; top: -7px; left: 70px;" width="16" height="8" src="@/assets/icon/fukidasi.png" />
    <div class="setting_menu__btn" v-on:click="logout()"><img width="12" height="12" src="@/assets/icon/logout.png" class="setting_menu__icon" />ログアウト</div>
    <div class="setting_menu__btn" v-if="adx.user.role != 'advertizer'" v-on:click="changeCustomer()"><img width="12" height="12" src="@/assets/icon/face_FILL0_wght400_GRAD0_opsz48.svg" class="setting_menu__icon" />顧客画面</div>
    <div class="setting_menu__btn" v-if="adx.user.role != 'advertizer'" v-on:click="changeConsultant()"><img width="12" height="12" src="@/assets/icon/home_FILL0_wght400_GRAD0_opsz48.svg" class="setting_menu__icon" />コンサル画面</div>
  </div>
  <transition name="fade">
    <div class="toastarea" v-if="istoast">
      <div class="toastmessage">予算管理ダウンロード中</div>
    </div>
  </transition>
</template>

<style scoped>

.header__logo--sandbox {
  background: #dc3545;
}

</style>

<style scoped>

.setting_menu {
  position: fixed;
  top:0;
  /* width: 82px; */
  z-index: 200;
  font-size: 12px;
  box-shadow: 0px 4px 10px #00000088;
  background: white;
  padding: 4px;
}

.setting_menu__btn {
  padding: 4px;
  padding-left: 20px;
  position: relative;
  cursor: pointer;
}

.setting_menu__btn:hover {
  background: rgba(200, 200, 200, 0.5);
}

.setting_menu__icon {
  position: absolute;
  left: 4px;
  top: 7px;
}
</style>

<style scoped>
.closer_adv_select_layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
}
.adv_selector_layer {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 201;
  overflow: hidden;
  pointer-events: none;
}
.adv_selector_area {
  min-width: 1920px;
  min-height: 1px;
}
.adv_selector_content {
  margin-left: 308px;
  width: 600px;
  background: #39434e;
  box-shadow: 0px 3px 6px #00000029;
}
.adv_selector_content--adv {
  margin-left: 290px;
}
.adv_selector_box {
  margin-bottom: 16px;
  box-shadow: 0px 3px 6px #00000029;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.adv_selector_box__search {
  display: flex;
  padding-top: 8px;
}
.adv_selector_box__search_icon_area {
  width: 24px;
  margin-left: 8px;
}
.adv_selector_box__search_input_area {
  width: calc(600px - 24px - 8px - 8px);
}
.adv_selector_box__search_input {
  width: 100%;
}
.adv_selector_box__itemlist {
  max-height: 320px;
  overflow: auto;
  pointer-events: all;
  margin-top: 4px;
  padding-bottom: 8px;
}

.selector_item {
  /* background: #fff; */
  padding: 2px 8px;
  color: #fff;
  font-weight: 300;
  cursor: pointer;
}

.selector_item--selected {
  background: rgb(30, 144, 255);
}

.selector_item:hover {
  background: rgba(30, 144, 255, 0.5);
}
</style>

<style scoped>
.toastarea {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 32px;
}

.toastmessage {
  width: 200px;
  margin: auto;
  background: #39434e;
  padding: 16px;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<style scoped>
.success {
  color: #0f0;
}
.danger {
  color: #f00;
}
</style>

<style scoped>
.header__body {
  width: calc(100% - 30px);
  min-width: 1020px;
  margin-left: 900px;
}

.header__body--adv {
  min-width: calc(1020px + 40px);
  margin-left: calc(900px - 40px);
}

.header__logo__line2 {
  position: absolute;
  top: 10px;
  width: 900px;
  height: 80px;
  padding-top: 15px;
  padding-left: 320px;
}

.header__logo__line2--adv {
  width: calc(900px - 40px);
  padding-left: calc(320px - 40px);
}

.header_name_box {
  font-size: 16px;
}

.header_name_box--advertizer {
  margin-top: 8px;
}

.slt_adv_select {
  text-overflow: ellipsis;
  width: 100%;
  background: #39434e;
  color: #fff;
  font-size: 16px;
}

.slt_adv_select--advertizer {
  padding: 8px 16px;
  border: 1px solid #fff;
  border-radius: 4px;
}

.selectermenu:hover {
  background-color: rgba(212, 212, 212, 0.3);
}

.header__logo {
  padding-top: 0px;
  padding-left: 0px;
  height: 100px;
  left: -20px;
}

.header__logo__line {
  top: 25px;
  height: 50px;
}

.header__logo__line3 {
  position: absolute;
  top: 25px;
  width: 900px;
  height: 50px;
  padding-top: 0px;
  padding-left: 320px;
  border-right: solid 2px #ccc;
  pointer-events: none;
}
</style>

<style>
.header__user {
  margin-left: initial;
}
</style>

<script>
// @ is an alias to /src

import { SideMenuClient } from '@/js/com/grpc/protos_gen/side_menu_grpc_web_pb.js';
import { url } from '@/js/setting.js';
import { uuid } from '@/js/tacklebox/core/uuid.js';
import { AdvertizerListRequest } from '@/js/com/grpc/protos_gen/side_menu_pb.js';
/* eslint-disable */

export default {
  name: 'Header',
  data() {
    return {
      name: 'Header',
      kani: 40,
      isMessageShow: false,
      //card: this.card,
      isNetworkTest: false,
      isGrpcTesting: false,
      isHttpTesting: false,
      grpcTestHost: url.grpc,
      grpcStatusCode: '-',
      grpcStatusDetail: '-',
      httpStatusCode: '-',
      selectedAdvertizerId: -1,
      istoast: false,
      keyword: '',
      isShowSelector: false,
      settingMenuPositionY: 0,
      settingMenuPositionX: 0,
    };
  },
  watch: {
    adx: {
      handler: function (val) {
        if (this.selectedAdvertizerId == -1) {
          if (val.getScreenStructureById('report')) {
            if (val.getScreenStructureById('report').advertizerId) {
              this.selectedAdvertizerId = val.getScreenStructureById('report').advertizerId;
            }
          }
        }
      },
      deep: true,
    },
  },
  mounted() {},
  beforeUnmount() {},
  methods: {
    isSandBox() {
      let envstr = "";
      try {
        envstr = process.env.VUE_APP_BASE_URL;
        if (envstr) {
          if (envstr.indexOf("stg") > 0) {
            return true;
          }
        }
      }catch(ex) {
        console.log(ex);
      }
      return false;
    },
    makeStatusQuery() {
      let isAdvRoll = true;
      let timeQuery = '';
      switch (this.adx.user.nowRole()) {
        case 'root':
        case 'manager':
        case 'consultant':
          isAdvRoll = false;
          break;
      }
      if (isAdvRoll) {
        let dateRangeSelector = this.adx.getScreenStructureById('report').dateRangeSelector;
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      } else {
        let dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      }
      return 'advertizer_id=' + this.selectedAdvertizerId + timeQuery;
    },
    closeSettingMenu() {
      this.settingMenuPositionX = 0;
      this.settingMenuPositionY = 0;
    },
    menuClick($e) {
      this.settingMenuPositionX = $e.x - 100;
      this.settingMenuPositionY = $e.y + 16;
    },
    test() {
      this.$router.push('/consultant/customreport');
      // this.$router.push('/consultant/customreport');
    },
    onClickEvent() {
      this.isShowSelector = false;
    },
    showSelector() {
      document.getElementById('lay_adv_selector').scrollTo(window.scrollX, 0);
      this.isShowSelector = true;
    },
    onRecordSelect(id) {
      this.selectedAdvertizerId = id;
      this.selectclick();
    },
    isShowRecord(title) {
      if (this.keyword == '') {
        return true;
      }

      if (title.indexOf(this.keyword) >= 0) {
        return true;
      }

      return false;
    },
    excelreport() {
      let self = this;
      this.istoast = true;
      setTimeout(function () {
        self.istoast = false;
      }, 1000 * 5);
      this.adx.excelreport.report();
    },
    gotoManager() {
      this.$router.push('/manager/summary');
    },
    isManager() {
      if (this.adx.user.nowRole() == 'manager') {
        return true;
      }
      if (this.adx.user.nowRole() == 'root') {
        return true;
      }
      return false;
    },
    isConsultant() {
      if (this.adx.user.nowRole() == 'advertizer') {
        return false;
      }
      return true;
    },
    isAdvertizer() {
      if (this.adx.user.nowRole() == 'advertizer') {
        return true;
      }
      return false;
    },
    selectclick($event) {
      console.log(this.selectedAdvertizerId);

      // location.href =
      //   process.env.VUE_APP_BASE_URL + 'index.html?advertizer_id=' + this.selectedAdvertizerId;

      let isAdvRoll = true;
      let timeQuery = '';
      switch (this.adx.user.nowRole()) {
        case 'root':
        case 'manager':
        case 'consultant':
          isAdvRoll = false;
          break;
      }
      if (isAdvRoll) {
        let dateRangeSelector = this.adx.getScreenStructureById('report').dateRangeSelector;
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      } else {
        let dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      }
      location.href = this.$route.path + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
    },
    message() {
      this.isMessageShow = !this.isMessageShow;
    },
    selectedAdvertizerName() {
      if (this.adx.getScreenStructureById('report')) {
        for (let key in this.adx.user.advertizers) {
          if (
            this.adx.user.advertizers[key].id ==
            this.adx.getScreenStructureById('report').advertizerId
          ) {
            return this.adx.user.advertizers[key].name;
          }
        }
      }
    },
    advertizerOptions() {
      let options = [];
      if (this.adx.getScreenStructureById('report')) {
        for (let key in this.adx.user.advertizers) {
          options.push({
            text: this.adx.user.advertizers[key].name,
            value: this.adx.user.advertizers[key].id,
          });
        }
      }
      return options;
    },
    devdoor() {
      // for developers
      try {
        if (event.ctrlKey && !event.shiftKey) {
          let url = location.href;
          let query = '';
          if (url.includes('?')) {
            query = '?' + url.split('?')[1];
          } else {
            query = '';
          }
          let jump = url.includes('/consultant/summary')
            ? '/report/summary'
            : '/consultant/summary';
          this.$router.push(jump + query);
        }
        if (event.ctrlKey && event.shiftKey) {
          this.networktest();
        }
      } catch (e) {
        // eventは非推奨らしいので、非対応のブラウザでエラーになった場合何も起きないようにしておく。
        console.log(e);
      }
    },
    networktest() {
      let self = this;

      this.isNetworkTest = !this.isNetworkTest;

      if (this.isNetworkTest) {
        this.isGrpcTesting = true;

        function getAdvertizerList(id) {
          let uuidStr = uuid();
          let request = new AdvertizerListRequest();
          request.setAduseruuid(id);

          let client = new SideMenuClient(url.grpc, null, null);
          const stream = client.getAdvertizerList(request);
          //TBD エラー
          stream.on('status', function (status) {
            console.log(status);
            self.grpcStatusCode = status.code;
            self.grpcStatusDetail = status.details;
            self.isGrpcTesting = false;
          });
          stream.on('error', (e) => {
            console.log('Error returned from BFF.');
            throw e;
          });
          return uuidStr;
        }

        function testHttp() {
          self.isHttpTesting = true;
          var header = new Headers();
          header.append('X-API-Version', '2');
          header.append('Content-Type', 'application/json;charset=UTF-8');
          var requestOptions = {
            method: 'GET',
            headers: header,
          };

          fetch(
            process.env.VUE_APP_RESTFUL_HOST +
              '/api/information?size=10&advertizerId=2&isDisplayAdX=true',
            requestOptions
          )
            .then((response) => response.json())
            .then(function (json) {
              console.log(json);
              self.httpStatusCode = 'OK';
              self.isHttpTesting = false;
            })
            .catch(function (err) {
              console.log(err);
              self.httpStatusCode = 'NG';
              self.isHttpTesting = false;
            });
        }

        getAdvertizerList(2);
        testHttp();
      }
    },
    logout() {
      console.log('logout');

      createAuth0Client({
        domain: process.env.VUE_APP_AUTH0_DOMAIN, //"pignus-stg.jp.auth0.com",
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID, //"wXCOwTnRNJWqQgRtEmqdc9IByYA878xR",
      }).then(function (auth0) {
        auth0.logout({
          returnTo: process.env.VUE_APP_BASE_URL + 'index.html',
        });
      });
    },
    changeCustomer() {
      let isAdvRoll = true;
      let timeQuery = '';
      switch (this.adx.user.nowRole()) {
        case 'root':
        case 'manager':
        case 'consultant':
          isAdvRoll = false;
          break;
      }
      this.adx.user.setDummyAdvMode(true);
      if (isAdvRoll) {
        let dateRangeSelector = this.adx.getScreenStructureById('report').dateRangeSelector;
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      } else {
        let dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      }

      // location.href = this.$route.path + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
      if (location.href.indexOf('/consultant/summary') > 0) {
          location.href = process.env.VUE_APP_BASE_URL + "report/summary" + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
          return;
      }
      if (location.href.indexOf('/log') > 0) {
          location.href = process.env.VUE_APP_BASE_URL + "log" + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
          return;
      }
      if (location.href.indexOf('/consultant/adprovider') > 0) {
          location.href = process.env.VUE_APP_BASE_URL + "report/adprovider" + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
          return;
      }

      location.href = window.location.origin + "/index.html" + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
    },
    changeConsultant() {      
      let isAdvRoll = true;
      let timeQuery = '';
      switch (this.adx.user.nowRole()) {
        case 'root':
        case 'manager':
        case 'consultant':
          isAdvRoll = false;
          break;
      }
      this.adx.user.setDummyAdvMode(false);
      if (isAdvRoll) {
        let dateRangeSelector = this.adx.getScreenStructureById('report').dateRangeSelector;
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      } else {
        let dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      }

      // location.href = this.$route.path + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
      if (location.href.indexOf('/report/summary') > 0) {
          location.href = process.env.VUE_APP_BASE_URL + "consultant/summary" + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
          return;
      }
      if (location.href.indexOf('/log') > 0) {
          location.href = process.env.VUE_APP_BASE_URL + "log" + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
          return;
      }
      if (location.href.indexOf('/report/adprovider') > 0) {
          location.href = process.env.VUE_APP_BASE_URL + "consultant/adprovider" + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
          return;
      }

      location.href = window.location.origin + "/index.html" + '?advertizer_id=' + this.selectedAdvertizerId + timeQuery;
    },
  },
  components: {},
  props: {
    screen: String,
    adx: Object,
    enableAdvertizerSelector: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
