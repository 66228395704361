import {
  // getCampaignSimulatedBudgetInitialBuild,
  // getCampaignSimulatedBudgetRebuild,
  // getDynamicCategorySimulatedBudgetInitialBuild,
  // getDynamicCategorySimulatedBudgetRebuild,
  // getMonthlySimulatedBudgetOverallInitialBuild,
  // getMonthlySimulatedBudgetOverallRebuild,
  // getMonthlySimulatedVersion,
  // insertBasePeriod,
  // insertCampaignSimulatedBudget,
  // upsertBasePeriod,
  getSimulationFormatFileUrl,
  getSimulationDataFileUrl
} from '../../com/grpc/budget_simulation.js';

import { getInformationLatestUpdate } from "../../com/grpc/information";
import {data2dateandweek} from '../../tacklebox/date/time.js';

function BudgetSimulationPage() {
  this.advertizerId = -1;
  this.timeRange = null;
  this.user = null;
  this.fileformatprogress = false;
  this.fileformatprogressPercent = 0;
  this.filedataprogress = false;
  this.fileuploadprogressPercent = 0;
  this.interval = null;
  this.intervalData = null;
  this.isFileuploadError = false;
}
BudgetSimulationPage.prototype.initialize = function (advertizerId, timeRange, user) {
  this.advertizerId = advertizerId;
  this.timeRange = timeRange;
  this.user = user;
};
BudgetSimulationPage.prototype.fileformatupload = function (file) {
  const formData = new FormData();
  formData.append('advertizerId', this.advertizerId);
  formData.append('file', file);

  let header = {
    'X-API-Version': '2',
    'Authorization': 'Bearer ' + this.user.auth0.token,
    'X-AdvertizerId': this.advertizerId,
    'X-Auth0-UUID': this.user.auth0.userUuid,
    'X-Timezone': 'Asia/Tokyo',
  };
  let requestOptions = {
    method: 'POST',
    body: formData,
    headers: header,
  };

  //  fetch(process.env.VUE_APP_RESTFUL_HOST + '/api/budget-simulation/format/file', requestOptions)
  // fetch('http://localhost/adxtest/test/file', requestOptions)
  //   .then((response) => response.json())
  //   .then(function (json) {
  //     console.log(json);
  //   });

  if (this.interval) {
    clearInterval(this.interval);
    this.interval = null;
  }
  this.fileformatprogress = true;
  this.fileformatprogressPercent = 1;
  this.fileuploadprogressPercent = 1;
  this.isFileuploadError = false;
  const self = this;
  var request = new XMLHttpRequest();
  // request.open("POST", process.env.VUE_APP_RESTFUL_HOST + '/api/budget-simulation/format/file');
  request.open("POST", process.env.VUE_APP_RESTFUL_HOST + '/api/budget-simulation/format/download/file');
  for (let name in requestOptions.headers) {
    request.setRequestHeader(name, requestOptions.headers[name]);
  }
  // アップロード関連イベント
  request.upload.addEventListener('loadstart', (evt) => {
    // アップロード開始
    console.log("loadstart", evt);
  });

  request.upload.addEventListener('progress', (evt) => {
    // アップロード進行パーセント
    const percent = (evt.loaded / evt.total * 100).toFixed(1);
    console.log(`++ xhr.upload: progress ${percent}%`);
  });

  request.upload.addEventListener('abort', (evt) => {
    // アップロード中断
    console.log("abort", evt);
    console.log('++ xhr.upload: abort (Upload aborted)');
    self.fileformatprogressPercent = 100;
  });

  request.upload.addEventListener('error', (evt) => {
    // アップロードエラー
    console.log("error", evt);
    console.log('++ xhr.upload: error (Upload failed)');
    self.fileformatprogressPercent = 100;
  });

  request.upload.addEventListener('load', (evt) => {
    // アップロード正常終了
    console.log("load", evt);
    console.log('++ xhr.upload: load (Upload Completed Successfully)');
    self.fileformatprogressPercent = 40;

    self.interval = setInterval(function () {
      self.fileformatprogressPercent++;
      if (self.fileformatprogressPercent >= 100) {
        clearInterval(self.interval);
      }
    }, 1000);
  });

  request.upload.addEventListener('timeout', (evt) => {
    // アップロードタイムアウト
    console.log("timeout", evt);
    console.log('++ xhr.upload: timeout');
    self.fileformatprogressPercent = 100;
  });

  request.upload.addEventListener('loadend', (evt) => {
    // アップロード終了 (エラー・正常終了両方)
    console.log("loadend", evt);
    console.log('++ xhr.upload: loadend (Upload Finished)');
  });
  request.send(formData);

};
BudgetSimulationPage.prototype.progressCounterTimerReset = function () {
  if (this.intervalData) {
    try {
      clearTimeout(this.intervalData);
    } catch (ex) {
      console.log(ex);
    }
  }
};
BudgetSimulationPage.prototype.progressCounter = function () {
  const self = this;

  this.fileuploadprogressPercent++;
  if (this.fileuploadprogressPercent >= 100) {
    return;
  }

  this.getSimulationDataFileUrl(function (json) {
    let isInprogress = true;
    if (json) {

      if (json?.inprogressfilesList.length == 0) {
        isInprogress = false;
        if (json.failurefilesList.length > 0) {
          let fd = new Date(json.failurefilesList[json.failurefilesList.length - 1].uploaded);

          if (json.successfilesList.length > 0) {
            let sd = new Date(json.successfilesList[json.successfilesList.length - 1].uploaded);
            if (fd.getTime() - sd.getTime() > 0) {
              self.isFileuploadError = true;
            }
          } else {
            self.isFileuploadError = true;
          }
        }
      }
    }
    if (self.fileuploadprogressPercent < 100 && isInprogress === true) {
      self.progressCounterTimerReset();
      self.intervalData = setTimeout(function () {
        self.progressCounter();
      }, 1000);
    } else {
      self.progressCounterTimerReset();
      self.fileuploadprogressPercent = 100;
    }

  });
};
BudgetSimulationPage.prototype.fileupload = function (file, actualMonth) {
  const self = this;
  const formData = new FormData();
  formData.append('advertizerId', this.advertizerId);
  formData.append('file', file);
  formData.append('actualMonth', actualMonth);

  let header = {
    'X-API-Version': '2',
    'Authorization': 'Bearer ' + this.user.auth0.token,
    'X-AdvertizerId': this.advertizerId,
    'X-Auth0-UUID': this.user.auth0.userUuid,
    'X-Timezone': 'Asia/Tokyo',
  };
  let requestOptions = {
    method: 'POST',
    body: formData,
    headers: header,
  };

  this.isFileuploadError = false;
  this.filedataprogress = true;
  fetch(process.env.VUE_APP_RESTFUL_HOST + '/api/budget-simulation/data/file', requestOptions)
    .then((response) => response.json())
    .then(function (json) {
      if (json) {
        //
      }

      self.progressCounterTimerReset();
      self.fileuploadprogressPercent = 30;
      self.progressCounter();

    }).catch(function (ex) {
      console.log(ex);
      self.progressCounterTimerReset();
      self.isFileuploadError = true;
      self.fileuploadprogressPercent = 100;
    });
};


BudgetSimulationPage.prototype.getSimulationDataFileUrl = function (callback) {
  let request = {
    token: this.user.auth0.token,
    userUuid: this.user.userUuid,
  };

  request.advertizerId = this.advertizerId;
  request.timeRequest = this.timeRange.getTimeRequest();

  const cuuid = getSimulationDataFileUrl(
    this.advertizerId,
    request,
    function (uuid, data) {
      if (data != null && cuuid == uuid) {
        let json = JSON.parse(JSON.stringify(data.result));
        callback(json);
      }
    },
    () => {
      console.log('done');
    },
    (e) => {
      console.log('Error returned from BFF.');
      console.log(e);
      callback(null);
    },
    'manager',
    []
  );

};

BudgetSimulationPage.prototype.download = function () {
  let request = {
    token: this.user.auth0.token,
    userUuid: this.user.userUuid,
  };

  request.advertizerId = this.advertizerId;
  const cuuid = getSimulationFormatFileUrl(
    this.advertizerId,
    request,
    function (uuid, data) {
      if (data != null && cuuid == uuid) {
        let json = JSON.parse(JSON.stringify(data.result));
        let anchor = document.createElement("a");
        anchor.href = json["signedurl"];
        anchor.click();

      }
    },
    () => {
      console.log('done');
    },
    (e) => {
      console.log('Error returned from BFF.');
      console.log(e);
    },
    'manager',
    []
  );

};

BudgetSimulationPage.prototype.updateload = function (callback) {
  let request = {
      sortItem: "",
      sort: "",
      token: this.user.auth0.token,
      userUuid: this.user.userUuid,
  };
  const cuuid = getInformationLatestUpdate(this.advertizerId, request, function (uuid, data) {
      if (cuuid == uuid) {
          try {
              let json = JSON.parse(JSON.stringify(data.result));
              if (json?.latestupdate?.date) {
                  if (callback) {
                      callback(data2dateandweek(json.latestupdate.date));
                  }
              }
          } catch (ex) {
              console.log(ex);
          }
      }
  });
};

export { BudgetSimulationPage };
