<template>
  <div style="position: relative">
    <div v-bind:style="wrapperstyle">
      <canvas ref="cnv"></canvas>
    </div>
    <div
      style="
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding-top: 10px;
        text-align: center;
        font-size: 20px;
      "
      v-if="chartdata.yAxes.length == 0"
    >
      NoData
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/*global Chart*/

export default {
  name: 'ChartJs',
  data() {
    return {
      name: 'ChartJs',
      chartdata: {
        labels: [],
        datasets: [],
        yAxes: [],
      },
      isReady: false,
      viewer: null,
      wrapperstyle: {
        position: 'relative',
        width: this.wrapper_width,
      },
    };
  },
  mounted() {
    this.chartdata.labels = [];
    this.chartdata.datasets = [];
    this.chartdata.yAxes = [];

    if (this.graph.records.length > 0) {
      let axes = this.graph.getActiveAxes();
      let items = this.graph.getItems();

      for (let i = 0; i < axes.length; i++) {
        let max = 1;
        for (let key in items) {
          if (max < items[key].datas[i]) {
            max = items[key].datas[i];
          }
        }
        this.addyAxes(axes[i].key, axes[i].label, axes[i].type, 0, max, Math.floor(max / 5));
      }
      let cnt = 0;
      for (let ikey in items) {
        let name = items[ikey].name;
        if (this.titleskip > 1) {
          cnt++;
          if (cnt % this.titleskip != 1) {
            name = '';
          }
        }
        this.addData(name, items[ikey].datas, items[ikey].isAlert);
      }
      if (this.chartdata.labels.length > 0) {
        this.draw();
      }
    }
  },
  watch: {
    graph: {
      handler: function (newValue) {
        if (newValue) {
          this.chartdata.labels = [];
          this.chartdata.datasets = [];
          this.chartdata.yAxes = [];

          if (this.graph.records.length > 0) {
            let axes = this.graph.getActiveAxes();
            let items = this.graph.getItems();

            for (let i = 0; i < axes.length; i++) {
              let max = 1;
              for (let key in items) {
                if (max < items[key].datas[i]) {
                  max = items[key].datas[i];
                }
              }
              this.addyAxes(axes[i].key, axes[i].label, axes[i].type, 0, max, Math.floor(max / 5));
            }
            let cnt = 0;
            for (let ikey in items) {
              let name = items[ikey].name;
              if (this.titleskip > 1) {
                cnt++;
                if (cnt % this.titleskip != 1) {
                  name = '';
                }
              }
              this.addData(name, items[ikey].datas, items[ikey].isAlert);
            }
            if (this.chartdata.labels.length > 0) {
              this.draw();
            }
          } else {
            this.draw();
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    addyAxes(id, label, type, min = 0, max = 100, step = 1) {
      let axes = {
        id: id,
        type: 'linear',
        position: 'left',
        ticks: {
          max: max,
          min: min,
          stepSize: step,
        },
      };

      let dataset = {
        yAxisID: id,
        label: label,
        data: [],
        backgroundColor: [],
      };

      if (this.chartdata.yAxes.length > 0) {
        axes.position = 'right';
      }

      if (type == 'line') {
        axes.gridLines = {
          drawOnChartArea: false,
        };
        dataset.type = 'line';
        dataset.borderColor = 'rgba(84,216,255,1.0)';
        dataset.fill = false;
        dataset.pointRadius = 1;
        dataset.pointHoverRadius = 1;
      } else {
        dataset.type = 'bar';
        dataset.borderColor = 'rgba(163,161,251,1.0)';
        //dataset.backgroundColor = 'rgba(163,161,251,1.0)';
      }

      this.chartdata.yAxes.push(axes);
      this.chartdata.datasets.push(dataset);
    },
    addData(label, datas, isAlert) {
      this.chartdata.labels.push(label);
      for (let i = 0; i < this.chartdata.datasets.length; i++) {
        let val = 0;
        if (datas) {
          if (datas.length > i) {
            val = datas[i];
          }
        }

        if (isAlert) {
          this.chartdata.datasets[i].backgroundColor.push('rgba(255,101,101,1.0)');
        } else {
          this.chartdata.datasets[i].backgroundColor.push('rgba(163,161,251,1.0)');
        }
        this.chartdata.datasets[i].data.push(val);
      }
    },
    draw() {
      let self = this;
      if (this.viewer) {
        //this.viewer.destroy();
        //this.viewer = null;
      }
      if (!this.viewer) {
        this.viewer = new Chart(this.$refs.cnv, {
          type: 'bar',
          data: {
            labels: this.chartdata.labels,
            datasets: this.chartdata.datasets,
          },
          options: {
            responsive: true,
            scales: {
              yAxes: this.chartdata.yAxes,
            },
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,

              custom: function (tooltipModel) {
                var tooltipEl = document.getElementById('chartjs-tooltip');

                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = ''; //'<table></table>';
                  document.body.appendChild(tooltipEl);
                }

                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                if (tooltipModel.dataPoints) {
                  let target = self.graph.records[tooltipModel.dataPoints[0].index];

                  let body = '';

                  for (let i in target.items) {
                    let item = target.items[i];
                    body += '<div class="chartjs_popup__item">';
                    body += item.title + '　' + item.getValue();
                    body += '</div>';
                  }

                  let innerHtml = '';
                  innerHtml += '<div class="chartjs_popup">';
                  innerHtml += '<div class="chartjs_popup__header">' + target.name + '</div>';
                  innerHtml += '<div class="chartjs_popup__body">' + body + '</div>';
                  innerHtml += '</div>';
                  tooltipEl.innerHTML = innerHtml;

                  var position = this._chart.canvas.getBoundingClientRect();

                  let leftOffset = 0;
                  if (self.graph.records) {
                    if (self.graph.records.length > 2) {
                      if (self.graph.records.length / 2 < tooltipModel.dataPoints[0].index) {
                        leftOffset = -300;
                      }
                    }
                  }

                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = 'absolute';
                  tooltipEl.style.left =
                    position.left + window.pageXOffset + tooltipModel.caretX + leftOffset + 'px';
                  tooltipEl.style.top =
                    position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                  tooltipEl.style.fontSize = tooltipModel.bodyFontSize;
                  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                  tooltipEl.style.padding =
                    tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                  tooltipEl.style.pointerEvents = 'none';
                }
              },
            },
          },
        });
      } else {
        this.viewer.data.labels = this.chartdata.labels;
        this.viewer.data.datasets = this.chartdata.datasets;
        this.viewer.options.scales.yAxes = this.chartdata.yAxes;
        this.viewer.update();
      }
    },
  },
  components: {},
  props: {
    graph: Object,
    wrapper_width: {
      type: String,
      default: '100%',
    },
    titleskip: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style>
.chartjs_popup {
  width: 300px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 10px #00000027;
}

.chartjs_popup__header {
  background: rgba(163, 161, 251, 0.3);
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.chartjs_popup__body {
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
}

.chartjs_popup__item {
  display: flex;
  width: 135px;

  vertical-align: text-bottom;
}
</style>