<template>
  <div>
    <div class="overlay_layer" v-if="selectedColum != null" style="pointer-events: none">
      <div
        class="summary_rangecalendar_background"
        v-on:click="rangecalendarevent"
        v-if="false"
      ></div>
      <div
        class="summary_rangecalendar_layer"
        :style="{ top: `${rangePositionY}px`, left: `${rangePositionX}px`, zIndex: 100 }"
      >
        <div class="summary_rangecalendar2">
          <div style="width: 100%; height: 100%">
            <M2ChartJs
              :labels="selectedColum.getTitles()"
              :graphs="selectedColum.getGraphs()"
              :title="selectedColum.getGraphTitle()"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="main_content_wrapper">
      <div class="adx_report_keyword">
        <div class="left_main_content">
          <div style="background: white">
            <div class="left_main_content__header" style="position: relative">
              <ContentHeader
                title="全アカウントサマリー"
                :timeRange="dateRangeSelector"
                :adx="adx"
                @timerangeevent="timerangeevent"
              />
              <div style="position: absolute; top: 76px; left: 0px; right: 0">
                <ScreenBarProgress2 :process="page.process" />
              </div>
            </div>
          </div>

          <div
            class="left_main_content__body"
            :class="[isDragMode ? 'left_main_content__body--dragmode' : '']"
          >
            <div class="box">
              <div class="frame">
                <div class="box">
                  <div style="width: 100%">
                    <div class="frame__body" v-if="showChild">
                      <table
                        class="floater_table floater_table--full manager_table"
                        v-if="!page.isManagerTableStreamStart"
                      >
                        <thead class="floater_table__header floater_table__header--sticky">
                          <tr>
                            <th style="width: 152px">クライアント</th>
                            <th style="width: 700px">月別推移</th>
                            <th style="width: 136px">COST</th>
                            <th style="width: 136px">CV</th>
                            <th style="width: 136px">CPA</th>
                            <th style="">memo</th>
                          </tr>
                        </thead>
                        <tbody class="floater_table__body manager_table__body">
                          <template v-for="(item, index) in dds" :key="index">
                            <tr>
                              <td style="width: 152px; padding: 8px">
                                <BoxSpinner style="height: 20px; margin-bottom: 8px" />
                                <BoxSpinner style="height: 20px" />
                              </td>
                              <td style="width: 700px; padding: 8px">
                                <BoxSpinner style="height: 100%; width: 100%" />
                              </td>
                              <td style="width: 136px; padding: 8px; position: relative">
                                <BoxSpinner style="margin-top: 30px; height: 144px; width: 100%" />
                                <BoxSpinner
                                  style="
                                    height: 24px;
                                    width: 80px;
                                    position: absolute;
                                    top: 8px;
                                    left: 8px;
                                  "
                                />
                              </td>
                              <td style="width: 136px; padding: 8px; position: relative">
                                <BoxSpinner style="margin-top: 30px; height: 144px; width: 100%" />
                                <BoxSpinner
                                  style="
                                    height: 24px;
                                    width: 80px;
                                    position: absolute;
                                    top: 8px;
                                    left: 8px;
                                  "
                                />
                              </td>
                              <td style="width: 136px; padding: 8px; position: relative">
                                <BoxSpinner style="margin-top: 30px; height: 144px; width: 100%" />
                                <BoxSpinner
                                  style="
                                    height: 24px;
                                    width: 80px;
                                    position: absolute;
                                    top: 8px;
                                    left: 8px;
                                  "
                                />
                              </td>
                              <td style="padding: 8px; position: relative">
                                <BoxSpinner style="margin-top: 30px; height: 144px; width: 100%" />
                                <BoxSpinner
                                  style="
                                    height: 24px;
                                    width: 180px;
                                    position: absolute;
                                    top: 8px;
                                    right: 8px;
                                  "
                                />
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <table
                        class="floater_table floater_table--full manager_table"
                        v-if="
                          (isLoad && !page.isManagerTableNetworking) ||
                          page.isManagerTableStreamStart
                        "
                      >
                        <thead class="floater_table__header floater_table__header--sticky" v-if="page.isFirstLoad">
                          <tr>
                            <template
                              v-for="(item, index) in page.recordHolder.getHeaderCell()"
                              :key="index"
                            >
                              <th
                                :style="{
                                  width:
                                    index == 0
                                      ? '152px'
                                      : index == 1
                                      ? '700px'
                                      : index == 2
                                      ? '136px'
                                      : index == 3
                                      ? '136px'
                                      : index == 4
                                      ? '136px'
                                      : index == 6
                                      ? ''
                                      : '',
                                }"
                              >
                                <div v-on:click="onClickTheader(index)">{{ item.getString() }}</div>
                              </th>
                            </template>
                          </tr>
                        </thead>
                        <tbody class="floater_table__body manager_table__body">
                          <template
                            v-for="(item, index) in page.recordHolder.getSortedRecord()"
                            :key="index"
                          >
                            <tr v-bind:class="[dragCandidate == item ? 'tr--dragcandidate' : '']">
                              <td
                                class="client_select"
                                style="
                                  position: relative;
                                  white-space: break-spaces;
                                  font: normal normal normal 12px/17px Noto Sans JP;
                                  vertical-align: middle;
                                  padding: 20px;
                                "
                                v-on:mouseover="dragmouseover(item, index)"
                                v-on:mouseleave="dragmouseleave(item)"
                              >
                                <div
                                  style="
                                    position: absolute;
                                    top: 0;
                                    left: -30px;
                                    height: 100%;
                                    width: 30px;
                                  "
                                  class="dragarea"
                                  v-on:mouseover="dragmouseover(item, index)"
                                  v-on:mouseleave="dragmouseleave(item)"
                                >
                                  <div
                                    v-on:mousedown="dragstart(item, index)"
                                    class="cmark yubi"
                                    style="
                                      margin-top: 75px;
                                      margin-left: 7px;
                                      background-image: url('/img/drag_indicator_black_24dp.36fe02e5.svg');
                                    "
                                  ></div>
                                </div>
                                <div
                                  class="clickable"
                                  style="text-decoration: underline; color: #2680eb"
                                  v-on:click="gotoConsultant(item.advertizerid)"
                                >
                                  {{ item.getName().getString() }}
                                </div>
                              </td>
                              <td>
                                <M2ChartJs
                                  :labels="item.getGraphTitle()"
                                  :graphs="item.getGraphs()"
                                  :option="{
                                    categoryPercentage: 0.6,
                                    barPercentage: 1.6,
                                  }"
                                />
                              </td>
                              <template
                                v-for="(table, index2) in item.getTables(
                                  page.recordHolder.getTableColHeaders()
                                )"
                                :key="index2"
                              >
                                <td
                                  class="manager_table__dyc"
                                  v-on:mouseover="mouseOverAction($event, table, index2)"
                                  v-on:mouseleave="mouseLeaveAction($event, table)"
                                >
                                  <div class="manager_table__dycell">
                                    <!--  div>{{ table.structure.alias }}</div  -->
                                    <div style="position: relative">
                                      <div
                                        style="
                                          font: normal normal bold 30px/36px Noto Sans JP;
                                          width: fit-content;
                                          margin: auto;
                                        "
                                        :class="[
                                          ratescaleArrawStyleWrapper(
                                            table.getType(),
                                            table.getRateValue().data
                                          ),
                                        ]"
                                      >
                                        {{ table.getRateValue().getString() }}
                                        <div style="position: absolute; top: 0">
                                          <svg
                                            v-bind:class="[
                                              ratescaleArrawClass(
                                                table.getType(),
                                                table.getRateValue().data
                                              ),
                                            ]"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.268 13.002"
                                          >
                                            <path
                                              id="合体_22"
                                              data-name="合体 22"
                                              d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                              transform="translate(-10732.001 -12773.001)"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="manager_table__dyc--alias">
                                      {{ aliasName(table?.structure?.alias) }}
                                    </div>
                                    <div class="manager_table__dyc--alias2">
                                      {{ aliasName2(table?.structure?.alias) }}
                                    </div>
                                  </div>
                                </td>
                              </template>
                              <td
                                v-on:click="page.recordHolder.edit(item)"
                                style="vertical-align: top"
                              >
                                <div
                                  class="manager"
                                  style="width: 100%; height: 168px"
                                  v-if="item.getMemoBall().isEdit()"
                                >
                                  <EditItem2 :memoball="item.getMemoBall()" />
                                </div>
                                <p
                                  style="text-align: right; margin: 0; padding: 8px; color: #aaa"
                                  v-if="!item.getMemoBall().isEdit()"
                                >
                                  {{ item.getMemoBall().getYmd() }}
                                </p>
                                <div
                                  class="manager"
                                  style="
                                    white-space: break-spaces;
                                    line-height: initial;
                                    padding: 8px;
                                    width: 100%;
                                    height: 149px;
                                    overflow: auto;
                                  "
                                  v-if="!item.getMemoBall().isEdit()"
                                >
                                  {{ item.getMemoBall().memo }}
                                </div>
                              </td>
                            </tr>
                          </template>
                          <template v-for="(mph, index) in page.mphs" :key="index">
                            <ManagerPlaceHolder :mph=mph />
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <Spinner v-if="page.isManagerTableNetworking && false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sub_content_layer">
          <div class="sub_content_wrapper">
            <div id="lay_right_sidemenu" class="right_sidemenu_layer">
              <SideMenu :adx="adx" selectId="summary" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dragmouse_layer" v-if="isDragMode">
      <div class="dragmouse_title" :style="{ top: dragTitle.y + 'px', left: dragTitle.x + 'px' }">
        {{ dragTitle.title }}
      </div>
    </div>
  </div>
</template>

<style>
.client_select {
  text-decoration: underline;
  color: #2680eb;
}

.floater_table__body div.manager {
  height: initial;
}
</style>

<style scoped>
.manager_table {
  max-width: none;
}
.manager_table__body td {
  height: 177px;
}
.manager_table__dyc {
  width: 130px;
  text-align: center;
  position: relative;
}

.manager_table__dyc--first {
  line-height: 20px;
  padding: 8px;
}

.manager_table__dyc--second {
}

.manager_table__dyc--first a {
  color: #2680eb;
}

.manager_table__dycell {
  font-size: 32px;
}

.summary_rangecalendar2 {
  width: 620px;
  height: 320px;
  background-color: white;
  /* padding: 10px; */
}

.manager_table__dyc--alias {
  position: absolute;
  top: 8px;
  bottom: 0;
  left: 16px;
  font-size: 14px;
  max-width: 108px;
  overflow: hidden;
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
}

.manager_table__dyc--alias2 {
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 16px;
  font-size: 14px;
  max-width: 108px;
  overflow: hidden;
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
}

.gbarrow3step--1wrapper {
  padding-left: 24px;
}
.gbarrow3step--2wrapper {
  padding-left: 20px;
}
.gbarrow3step--3wrapper {
  padding-left: 13px;
}
.gbarrow3step--4wrapper {
  padding-left: 18px;
}
.gbarrow3step--5wrapper {
  padding-left: 20px;
}
.gbarrow3step--6wrapper {
  padding-left: 24px;
}

.gbarrow3step--1 {
  width: 20px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: -24px;
  fill: #088dff;
}
.gbarrow3step--2 {
  width: 16px;
  height: 19px;
  position: absolute;
  top: 13px;
  left: -20px;
  fill: #088dff;
}
.gbarrow3step--3 {
  width: 13px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: -18px;
  fill: #088dff;
}
.gbarrow3step--4 {
  width: 13px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: -18px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--5 {
  width: 16px;
  height: 20px;
  position: absolute;
  top: 12px;
  left: -20px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--6 {
  width: 20px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: -24px;
  transform: rotate(180deg);
  fill: #ff1744;
}

.gbarrow3step--11 {
  width: 20px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: -24px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--12 {
  width: 16px;
  height: 20px;
  position: absolute;
  top: 12px;
  left: -20px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--13 {
  width: 13px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: -18px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--14 {
  width: 13px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: -18px;
  fill: #ff1744;
}
.gbarrow3step--15 {
  width: 16px;
  height: 19px;
  position: absolute;
  top: 13px;
  left: -20px;
  fill: #ff1744;
}
.gbarrow3step--16 {
  width: 20px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: -24px;
  fill: #ff1744;
}
</style>

<style scoped>
.left_main_content__body--dragmode {
  user-select: none;
}

.tr--dragcandidate {
  border-top: 8px solid rgba(52, 183, 254, 0.5);
}

.dragmouse_layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.dragmouse_title {
  position: absolute;
  padding: 8px;
  color: rgb(38, 128, 235);
  background: rgba(255, 255, 255, 0.8);
}

.yubi {
  cursor: pointer;
}

.dragarea {
  user-select: none;
}

.dragarea:hover .cmark {
  display: block;
}
.dragarea .cmark {
  display: none;
}
</style>

<script>
// @ is an alias to /src
import SideMenu from '@/components/manager/SideMenu.vue';
import ContentHeader from '@/components/report/ContentHeader.vue';
import Spinner from '@/components/layout/Spinner.vue';
import ScreenBarProgress2 from '@/components/ScreenBarProgress2.vue';
import BoxSpinner from '@/components/layout/BoxSpinner.vue';
// import RangeCalendar from '@/components/RangeCalendar.vue';
import { SummaryPage } from '@/js/page/manager/SummaryPage.js';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';
import M2ChartJs from '@/components/element/M2ChartJs.vue';
import EditItem2 from '@/components/EditItem2.vue';
import { ratescale3Step } from '@/js/setting.js';
import ManagerPlaceHolder from '@/components/ManagerPlaceHolder.vue';


export default {
  name: 'Summary',
  data() {
    return {
      name: '全アカウントサマリー',
      page: new SummaryPage(),
      isLoad: false,
      dateRangeSelector: null,
      isShowRange: false,
      isShowDailyAlert: false,
      isShowDailyAlertHover: false,
      isShowWeeklyAlert: false,
      isShowWeeklyAlertHover: false,
      rangePositionX: 0,
      rangePositionY: 0,
      selectedColum: null,
      isDragMode: false,
      dragCandidate: null,
      dragTitle: {
        x: 0,
        y: 0,
        title: 'test',
        idx: 0,
        targetIdx: 0,
        targetAdvId: 0,
      },
      showChild: true,
      dds: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  emits: ['screenevent'],
  mounted() {
    this.$emit('screenevent', 'manager');
    let self = this;
    this.page.process.start();
    this.dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
    let p = this.adx.getScreenStructureById('manager').getPageById(1);
    if (p) {
      this.initialize();
    } else {
      this.adx.getScreenStructureById('manager').subject.addObserver(function () {
        let p = self.adx.getScreenStructureById('manager').getPageById(1);
        if (p) {
          self.dateRangeSelector = self.adx.getScreenStructureById('consultant').dateRangeSelector;
          self.initialize();
        }
      });
    }
    document.addEventListener('mouseleave', this.onMouseLeave);
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('mousemove', this.onMouseMove);
  },
  methods: {
    aliasName(alias) {
      if (alias) {
        let as = alias.split('_');
        if (as.length > 0) {
          return as[0];
        }
      }
      return alias;
    },
    aliasName2(alias) {
      if (alias) {
        let as = alias.split('_');
        if (as.length > 1) {
          return as[1];
        }
      }
      return '';
    },
    onClickTheader(index) {
      if (index == 0) {
        this.page.recordHolder.orderInitialize();
        alert('並び順をクリアしました');
        document.location.reload();
      }
    },
    dragstart(item, idx) {
      //[ORDER停止]
      if (item || idx) {
        //
      }
      // if (this.page.isManagerTableNetworking) {
      //   return;
      // }
      // this.dragCandidate = item;
      // this.isDragMode = true;
      // this.dragTitle.title = item.client;
      // this.dragTitle.idx = idx;
      // this.dragTitle.targetIdx = idx;
      // this.dragTitle.targetAdvId = item.advertizerid;
      // this.page.recordHolder.fixOrder();
    },
    dragmouseover(item, idx) {
      //[ORDER停止]
      if (item || idx) {
        //
      }
      // if (this.isDragMode) {
      //   this.dragCandidate = item;
      //   this.dragTitle.targetIdx = idx;
      // }
    },
    dragmouseleave(item) {
      //[ORDER停止]
      if (item) {
        //
      }
      // if (this.dragCandidate == item) {
      //   this.dragCandidate = null;
      // }
    },
    onMouseLeave() {
      //[ORDER停止]
      // this.isDragMode = false;
      // this.dragCandidate = null;
    },
    onMouseUp() {
      //[ORDER停止]
      // if (this.dragCandidate) {
      //   if (this.isDragMode) {
      //     // ORDER
      //     let tg = this.dragTitle.targetIdx;
      //     if (this.dragTitle.targetIdx - this.dragTitle.idx > 0) {
      //       tg = tg - 1;
      //     }
      //     this.page.recordHolder.rorder.change(tg, this.dragTitle.targetAdvId);
      //     this.page.recordHolder.fixOrder(true);
      //     this.showChild = false;
      //     this.$nextTick(() => (this.showChild = true));
      //   }
      // }
      // this.dragCandidate = null;
      // this.isDragMode = false;
    },
    onMouseMove(e) {
      //[ORDER停止]
      if (e) {
        //
      }
      // this.dragTitle.x = e.clientX;
      // this.dragTitle.y = e.clientY;
    },
    ratescaleArrawClass(type, data) {
      let cln = 'gbarrow3step';

      let step = ratescale3Step(type, data);
      switch (step) {
        case 1:
          return 'gbarrow3step--1';
        case 2:
          return 'gbarrow3step--2';
        case 3:
          return 'gbarrow3step--3';
        case 4:
          return 'gbarrow3step--4';
        case 5:
          return 'gbarrow3step--5';
        case 6:
          return 'gbarrow3step--6';
        case 11:
          return 'gbarrow3step--11';
        case 12:
          return 'gbarrow3step--12';
        case 13:
          return 'gbarrow3step--13';
        case 14:
          return 'gbarrow3step--14';
        case 15:
          return 'gbarrow3step--15';
        case 16:
          return 'gbarrow3step--16';
      }

      return cln;
    },
    ratescaleArrawStyleWrapper(type, data) {
      let cln = 'gbarrow3stepwrapper';

      let step = ratescale3Step(type, data);
      switch (step) {
        case 1:
        case 16:
          return 'gbarrow3step--1wrapper';
        case 2:
        case 15:
          return 'gbarrow3step--2wrapper';
        case 3:
        case 14:
          return 'gbarrow3step--3wrapper';
        case 4:
        case 13:
          return 'gbarrow3step--4wrapper';
        case 5:
        case 12:
          return 'gbarrow3step--5wrapper';
        case 6:
        case 11:
          return 'gbarrow3step--6wrapper';
      }

      return cln;
    },
    gotoConsultant(id) {
      location.href =
        process.env.VUE_APP_BASE_URL +
        'consultant/summary?advertizer_id=' +
        id +
        '&rdate=' +
        this.dateRangeSelector.timeYmdRange.startYmd.year +
        '-' +
        this.dateRangeSelector.timeYmdRange.startYmd.month;
    },
    ratescale(type, data) {
      let colScale2 = [
        '#37803A', // good
        '#449E48',
        '#5CB860',
        '#9BD39E',
        '#FAAEAA',
        '#F66D65',
        '#F2382C',
        '#DA1B0E', // bad
      ];
      if (data == '') {
        return '#ffffff';
      }
      if (type == 'cost') {
        if (data >= 130) return colScale2[7];
        else if (data >= 120) return colScale2[6];
        else if (data >= 110) return colScale2[5];
        else if (data >= 105) return colScale2[4];
        else if (data >= 103) return colScale2[3];
        else if (data >= 102) return colScale2[2];
        else if (data >= 101) return colScale2[1];
        else if (data >= 100) return colScale2[0];
        else if (data >= 99) return colScale2[1];
        else if (data >= 98) return colScale2[2];
        else if (data >= 96) return colScale2[3];
        else if (data >= 91) return colScale2[4];
        else if (data >= 81) return colScale2[5];
        else if (data >= 71) return colScale2[6];
        else return colScale2[7];
      }
      if (type == 'cv') {
        if (data >= 130) return colScale2[0];
        else if (data >= 120) return colScale2[1];
        else if (data >= 110) return colScale2[2];
        else if (data >= 100) return colScale2[3];
        else if (data >= 91) return colScale2[4];
        else if (data >= 81) return colScale2[5];
        else if (data >= 71) return colScale2[6];
        else return colScale2[7];
      }
      if (type == 'cpa') {
        if (data >= 130) return colScale2[7];
        else if (data >= 120) return colScale2[6];
        else if (data >= 110) return colScale2[5];
        else if (data >= 101) return colScale2[4];
        else if (data >= 91) return colScale2[3];
        else if (data >= 81) return colScale2[2];
        else if (data >= 71) return colScale2[1];
        else return colScale2[0];
      }
      if (type == 'roas') {
        if (data >= 130) return colScale2[0];
        else if (data >= 120) return colScale2[1];
        else if (data >= 110) return colScale2[2];
        else if (data >= 101) return colScale2[3];
        else if (data >= 91) return colScale2[4];
        else if (data >= 81) return colScale2[5];
        else if (data >= 71) return colScale2[6];
        else return colScale2[7];
      }

      return '#ffffff';
    },
    mouseleave() {
      this.isShowRange = false;
    },
    //eslint-disable-next-line
    mouseLeaveAction(e, table) {
      this.selectedColum = null;
    },
    mouseOverAction(e, table, index2) {
      let moffset = 200;
      if (index2 == 0) {
        moffset = 50;
      } else if (index2 == 1) {
        moffset = 100;
      }
      this.rangePositionX = e.currentTarget.offsetLeft - moffset;
      this.rangePositionY = e.currentTarget.offsetTop + 120;
      if (document.documentElement.clientHeight - e.currentTarget.offsetTop < 450) {
        this.rangePositionY -= 400;
      }
      this.selectedColum = table;
    },
    selectTableCol(e, table, mode = 1) {
      console.log(mode);

      this.rangePositionY = e.layerY;
      this.rangePositionX = e.layerX;
      this.isShowRange = true;

      this.selectedColum = table;
    },

    initialize() {
      this.isLoad = true;
      this.dateRangeSelector.timeYmdRange.subject.removeAll();

      let oldorders = [];
      try {
        let s = localStorage.getItem('adxmanagerorder');
        if (s) {
          oldorders = JSON.parse(s);
        }
      } catch (ex) {
        console.log(ex);
      }
      let self = this;
      if (oldorders.length > 0) {
        this.page.saveOrders(this.adx.user, oldorders, function () {
          localStorage.removeItem('adxmanagerorder');

          self.page.initialize(
            self.adx.getScreenStructureById('manager').advertizerId,
            self.dateRangeSelector,
            self.adx.user
          );
        });
      } else {
        this.page.initialize(
          this.adx.getScreenStructureById('manager').advertizerId,
          this.dateRangeSelector,
          this.adx.user
        );
      }

      this.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
        self.initialize();
      });
    },
    getTodayYmd() {
      let time = this.adx.getNowTime();
      return new TimeYmd(time.ymd.year, time.ymd.month, time.ymd.day);
    },
    mouseOverDaily() {
      this.isShowDailyAlertHover = true;
    },
    mouseLeaveDaily() {
      this.isShowDailyAlertHover = false;
    },
    mouseOverWeekly() {
      this.isShowWeeklyAlertHover = true;
    },
    mouseLeaveWeekly() {
      this.isShowWeeklyAlertHover = false;
    },
    rangecalendarevent: function (event, arg) {
      this.selectedColum = null;
      if (event == 'apply') {
        this.dateRangeSelector.setRangeYmd(
          arg.startYmd.year,
          arg.startYmd.month,
          arg.startYmd.day,
          arg.endYmd.year,
          arg.endYmd.month,
          arg.endYmd.day
        );
      }
      this.isShowRange = false;
    },
    timerangeevent: function (event) {
      console.log(event);
      this.isShowRange = true;
    },
  },
  components: {
    SideMenu,
    ContentHeader,
    Spinner,
    BoxSpinner,
    ScreenBarProgress2,
    // RangeCalendar,
    M2ChartJs,
    EditItem2,
    ManagerPlaceHolder,
  },
  props: {
    adx: Object,
  },
  beforeUnmount() {
    document.removeEventListener('mouseleave', this.onMouseLeave);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('onmousemove', this.onMouseMove);
    this.page.cancelAllAPICall();
  },
};
</script>
