
const _VERSION = "1.0";

function _getNowTimeHmss() {
    var date = new Date();
    var h = ("00" + date.getHours()).slice(-2);
    var m = ("00" + date.getMinutes()).slice(-2);
    var s = ("00" + date.getSeconds()).slice(-2);
    var sss = ("000" + date.getMilliseconds()).slice(-3);
    return h + ":" + m + ":" + s + "." + sss;
}

function _printConsoleLog(log) {
    console.log(log.timestamp + " " + log.getTag(), log.getMessage());
}

function _printConsoleError(log) {
    console.error(log.timestamp + " " + log.getTag(), log.getMessage());
}

function Log(param1, param2) {
    this.timestamp = _getNowTimeHmss();
    this.param1 = param1;
    this.param2 = param2;
}
Log.prototype.getTag = function () {
    if (this.param2) {
        return this.param1;
    }
    return "";
};
Log.prototype.getMessage = function () {
    if (this.param2) {
        return this.param2;
    }

    return this.param1;
};


let _logs = [];
function _log(param1, param2) {
    let log = new Log(param1, param2);
    _logs.push(log);
    _printConsoleLog(log);
}

function _tacklelog(message) {
    _log("('-' )y <", message)
}

function _dbg(tag, message) {
    let log = new Log("[" + tag + "]", message);
    _logs.push(log);
    _printConsoleLog(log);
}

function _err(tag, message) {
    let log = new Log("[" + tag + "]", message);
    _logs.push(log);
    _printConsoleError(log);
}

function _extend(target, parent) {
    function inherit(prototype) {
        if (Object.create) {
            return Object.create(prototype);
        }

        function f() { }
        f.prototype = prototype;
        return new f();
    }
    target.prototype = inherit(parent.prototype);
    target.prototype.constructor = target;
}


export { _VERSION as VERSION };
export { _log as log }
export { _tacklelog as tacklelog }
export { _dbg as dbg }
export { _err as err }
export { _extend as extend }

