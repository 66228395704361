import {
    InsertSummaryMemoRequest,
    SummaryReportRequest,
    SaveSummaryReportOrderRequest,
    ReportOrder,
} from './protos_gen/manager_pb.js';
import { ManagerClient } from './protos_gen/manager_grpc_web_pb.js'
import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function insertSummaryMemo(id, dto, month, memo, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    // let month = dto.month;
    // let memo = dto.memo;

    let request = new InsertSummaryMemoRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(id);
    request.setMonth(month);
    request.setMemo(memo);
    // request.setAdvertizerid(dto.advertizerId);   //TBD なんかやってるっぽいので後で聞く

    let client = new ManagerClient(url.grpc, null, null);
    const stream = client.insertSummaryMemo(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message['array'].length > 0) callback(uuidStr, message.toObject());
            else callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

function getSummaryReportRequest(id,
    dto,
    callback,
    callback_end = () => { },
    callback_error = (e) => {
        console.log('Error returned from BFF.');
        throw e;
    },
    user_role = "",
    currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new SummaryReportRequest();
    request.setRequestid(uuidStr);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }
    request.setAdvertizeridsList(dto.requestAdvertizerIds);
    

    let client = new ManagerClient(url.grpc, null, null);
    const stream = client.getSummaryReport(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', function (e) {
        callback_error(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function saveSummaryReportOrder(dto, callback, callback_end = () => { }, callback_error = (e) => { console.log('Error returned from BFF.'); throw e; }) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new SaveSummaryReportOrderRequest();
    request.setRequestid(uuidStr);
    for (let i in dto?.aors) {
        let aor = dto?.aors[i];
        let ro = new ReportOrder();
        ro.setAdvertizerid(aor.advertizerId);
        ro.setOrder(aor.order);
        request.addReportorder(ro);
    }

    let client = new ManagerClient(url.grpc, null, null);
    const stream = client.saveSummaryReportOrder(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message['array'].length > 0) callback(uuidStr, message.toObject());
            else callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        callback_error(uuidStr, e);
    });

    // TBD エラー

    return uuidStr;
}

export {
    insertSummaryMemo,
    getSummaryReportRequest,
    saveSummaryReportOrder,
}
