/**
 * @fileoverview gRPC-Web generated client stub for adx.information
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.information = require('./information_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.information.InformationClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.information.InformationPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.CreateInformationRequest,
 *   !proto.adx.information.CreateInformationResponse>}
 */
const methodDescriptor_Information_CreateInformation = new grpc.web.MethodDescriptor(
  '/adx.information.Information/CreateInformation',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.CreateInformationRequest,
  proto.adx.information.CreateInformationResponse,
  /**
   * @param {!proto.adx.information.CreateInformationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.CreateInformationResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.CreateInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.CreateInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.createInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/CreateInformation',
      request,
      metadata || {},
      methodDescriptor_Information_CreateInformation);
};


/**
 * @param {!proto.adx.information.CreateInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.CreateInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.createInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/CreateInformation',
      request,
      metadata || {},
      methodDescriptor_Information_CreateInformation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.DeleteInformationRequest,
 *   !proto.adx.information.DeleteInformationResponse>}
 */
const methodDescriptor_Information_DeleteInformation = new grpc.web.MethodDescriptor(
  '/adx.information.Information/DeleteInformation',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.DeleteInformationRequest,
  proto.adx.information.DeleteInformationResponse,
  /**
   * @param {!proto.adx.information.DeleteInformationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.DeleteInformationResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.DeleteInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.DeleteInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.deleteInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/DeleteInformation',
      request,
      metadata || {},
      methodDescriptor_Information_DeleteInformation);
};


/**
 * @param {!proto.adx.information.DeleteInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.DeleteInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.deleteInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/DeleteInformation',
      request,
      metadata || {},
      methodDescriptor_Information_DeleteInformation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.GetInformationRequest,
 *   !proto.adx.information.GetInformationResponse>}
 */
const methodDescriptor_Information_GetInformation = new grpc.web.MethodDescriptor(
  '/adx.information.Information/GetInformation',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.GetInformationRequest,
  proto.adx.information.GetInformationResponse,
  /**
   * @param {!proto.adx.information.GetInformationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.GetInformationResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.GetInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.getInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformation',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformation);
};


/**
 * @param {!proto.adx.information.GetInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.getInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformation',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.GetInformationSearchRequest,
 *   !proto.adx.information.GetInformationSearchResponse>}
 */
const methodDescriptor_Information_GetInformationSearch = new grpc.web.MethodDescriptor(
  '/adx.information.Information/GetInformationSearch',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.GetInformationSearchRequest,
  proto.adx.information.GetInformationSearchResponse,
  /**
   * @param {!proto.adx.information.GetInformationSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.GetInformationSearchResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.GetInformationSearchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationSearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.getInformationSearch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformationSearch',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformationSearch);
};


/**
 * @param {!proto.adx.information.GetInformationSearchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationSearchResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.getInformationSearch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformationSearch',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformationSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.ResetInformationRequest,
 *   !proto.adx.information.ResetInformationResponse>}
 */
const methodDescriptor_Information_ResetInformation = new grpc.web.MethodDescriptor(
  '/adx.information.Information/ResetInformation',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.ResetInformationRequest,
  proto.adx.information.ResetInformationResponse,
  /**
   * @param {!proto.adx.information.ResetInformationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.ResetInformationResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.ResetInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.ResetInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.resetInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/ResetInformation',
      request,
      metadata || {},
      methodDescriptor_Information_ResetInformation);
};


/**
 * @param {!proto.adx.information.ResetInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.ResetInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.resetInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/ResetInformation',
      request,
      metadata || {},
      methodDescriptor_Information_ResetInformation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.UpdateInformationRequest,
 *   !proto.adx.information.UpdateInformationResponse>}
 */
const methodDescriptor_Information_UpdateInformation = new grpc.web.MethodDescriptor(
  '/adx.information.Information/UpdateInformation',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.UpdateInformationRequest,
  proto.adx.information.UpdateInformationResponse,
  /**
   * @param {!proto.adx.information.UpdateInformationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.UpdateInformationResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.UpdateInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.UpdateInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.updateInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/UpdateInformation',
      request,
      metadata || {},
      methodDescriptor_Information_UpdateInformation);
};


/**
 * @param {!proto.adx.information.UpdateInformationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.UpdateInformationResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.updateInformation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/UpdateInformation',
      request,
      metadata || {},
      methodDescriptor_Information_UpdateInformation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.GetInformationNoticesCountRequest,
 *   !proto.adx.information.GetInformationNoticesCountResponse>}
 */
const methodDescriptor_Information_GetInformationNoticesCount = new grpc.web.MethodDescriptor(
  '/adx.information.Information/GetInformationNoticesCount',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.GetInformationNoticesCountRequest,
  proto.adx.information.GetInformationNoticesCountResponse,
  /**
   * @param {!proto.adx.information.GetInformationNoticesCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.GetInformationNoticesCountResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.GetInformationNoticesCountRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationNoticesCountResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.getInformationNoticesCount =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformationNoticesCount',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformationNoticesCount);
};


/**
 * @param {!proto.adx.information.GetInformationNoticesCountRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationNoticesCountResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.getInformationNoticesCount =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformationNoticesCount',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformationNoticesCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.GetInformationNoticesRequest,
 *   !proto.adx.information.GetInformationNoticesResponse>}
 */
const methodDescriptor_Information_GetInformationNotices = new grpc.web.MethodDescriptor(
  '/adx.information.Information/GetInformationNotices',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.GetInformationNoticesRequest,
  proto.adx.information.GetInformationNoticesResponse,
  /**
   * @param {!proto.adx.information.GetInformationNoticesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.GetInformationNoticesResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.GetInformationNoticesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationNoticesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.getInformationNotices =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformationNotices',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformationNotices);
};


/**
 * @param {!proto.adx.information.GetInformationNoticesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationNoticesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.getInformationNotices =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformationNotices',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformationNotices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.UpdateInformationNoticesReadRequest,
 *   !proto.adx.information.UpdateInformationNoticesReadResponse>}
 */
const methodDescriptor_Information_UpdateInformationNoticesRead = new grpc.web.MethodDescriptor(
  '/adx.information.Information/UpdateInformationNoticesRead',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.UpdateInformationNoticesReadRequest,
  proto.adx.information.UpdateInformationNoticesReadResponse,
  /**
   * @param {!proto.adx.information.UpdateInformationNoticesReadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.UpdateInformationNoticesReadResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.UpdateInformationNoticesReadRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.UpdateInformationNoticesReadResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.updateInformationNoticesRead =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/UpdateInformationNoticesRead',
      request,
      metadata || {},
      methodDescriptor_Information_UpdateInformationNoticesRead);
};


/**
 * @param {!proto.adx.information.UpdateInformationNoticesReadRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.UpdateInformationNoticesReadResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.updateInformationNoticesRead =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/UpdateInformationNoticesRead',
      request,
      metadata || {},
      methodDescriptor_Information_UpdateInformationNoticesRead);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.information.GetInformationLatestUpdateRequest,
 *   !proto.adx.information.GetInformationLatestUpdateResponse>}
 */
const methodDescriptor_Information_GetInformationLatestUpdate = new grpc.web.MethodDescriptor(
  '/adx.information.Information/GetInformationLatestUpdate',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.information.GetInformationLatestUpdateRequest,
  proto.adx.information.GetInformationLatestUpdateResponse,
  /**
   * @param {!proto.adx.information.GetInformationLatestUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.information.GetInformationLatestUpdateResponse.deserializeBinary
);


/**
 * @param {!proto.adx.information.GetInformationLatestUpdateRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationLatestUpdateResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationClient.prototype.getInformationLatestUpdate =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformationLatestUpdate',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformationLatestUpdate);
};


/**
 * @param {!proto.adx.information.GetInformationLatestUpdateRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.information.GetInformationLatestUpdateResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.information.InformationPromiseClient.prototype.getInformationLatestUpdate =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.information.Information/GetInformationLatestUpdate',
      request,
      metadata || {},
      methodDescriptor_Information_GetInformationLatestUpdate);
};


module.exports = proto.adx.information;

