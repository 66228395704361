// source: report_page/summary_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.adx.summary_report.ActualStatusReportRequest', null, global);
goog.exportSymbol('proto.adx.summary_report.ActualStatusReportResponse', null, global);
goog.exportSymbol('proto.adx.summary_report.ActualStatusReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport', null, global);
goog.exportSymbol('proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table', null, global);
goog.exportSymbol('proto.adx.summary_report.ActualStatusReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.summary_report.DailyResultGraphReportRequest', null, global);
goog.exportSymbol('proto.adx.summary_report.DailyResultGraphReportResponse', null, global);
goog.exportSymbol('proto.adx.summary_report.DailyResultGraphReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport', null, global);
goog.exportSymbol('proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table', null, global);
goog.exportSymbol('proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert', null, global);
goog.exportSymbol('proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item', null, global);
goog.exportSymbol('proto.adx.summary_report.DailyResultGraphReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.summary_report.DimensionCategoryResultReportRequest', null, global);
goog.exportSymbol('proto.adx.summary_report.DimensionCategoryResultReportResponse', null, global);
goog.exportSymbol('proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport', null, global);
goog.exportSymbol('proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory', null, global);
goog.exportSymbol('proto.adx.summary_report.DimensionCategoryResultReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows', null, global);
goog.exportSymbol('proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table', null, global);
goog.exportSymbol('proto.adx.summary_report.DimensionCategoryResultReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables', null, global);
goog.exportSymbol('proto.adx.summary_report.GetDimensionCategoryAndComponentRequest', null, global);
goog.exportSymbol('proto.adx.summary_report.GetDimensionCategoryAndComponentResponse', null, global);
goog.exportSymbol('proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId', null, global);
goog.exportSymbol('proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result', null, global);
goog.exportSymbol('proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.summary_report.Graph', null, global);
goog.exportSymbol('proto.adx.summary_report.Graph.TypeCase', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceAdGroupReportRequest', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceAdGroupReportResponse', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceAdGroupReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceCampaignReportRequest', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceCampaignReportResponse', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table', null, global);
goog.exportSymbol('proto.adx.summary_report.LowPerformanceCampaignReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.summary_report.MonthlyResultReportRequest', null, global);
goog.exportSymbol('proto.adx.summary_report.MonthlyResultReportResponse', null, global);
goog.exportSymbol('proto.adx.summary_report.MonthlyResultReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport', null, global);
goog.exportSymbol('proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table', null, global);
goog.exportSymbol('proto.adx.summary_report.MonthlyResultReportResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.summary_report.TabListOfSummaryReportPageRequest', null, global);
goog.exportSymbol('proto.adx.summary_report.TabListOfSummaryReportPageResponse', null, global);
goog.exportSymbol('proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result', null, global);
goog.exportSymbol('proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider', null, global);
goog.exportSymbol('proto.adx.summary_report.TabListOfSummaryReportPageResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.summary_report.WeeklyResultReportRequest', null, global);
goog.exportSymbol('proto.adx.summary_report.WeeklyResultReportResponse', null, global);
goog.exportSymbol('proto.adx.summary_report.WeeklyResultReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert', null, global);
goog.exportSymbol('proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item', null, global);
goog.exportSymbol('proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport', null, global);
goog.exportSymbol('proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table', null, global);
goog.exportSymbol('proto.adx.summary_report.WeeklyResultReportResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.TabListOfSummaryReportPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.TabListOfSummaryReportPageRequest.displayName = 'proto.adx.summary_report.TabListOfSummaryReportPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.ActualStatusReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.ActualStatusReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.ActualStatusReportRequest.displayName = 'proto.adx.summary_report.ActualStatusReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.MonthlyResultReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.MonthlyResultReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.MonthlyResultReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.MonthlyResultReportRequest.displayName = 'proto.adx.summary_report.MonthlyResultReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.WeeklyResultReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.WeeklyResultReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.WeeklyResultReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.WeeklyResultReportRequest.displayName = 'proto.adx.summary_report.WeeklyResultReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DailyResultGraphReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.DailyResultGraphReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DailyResultGraphReportRequest.displayName = 'proto.adx.summary_report.DailyResultGraphReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.DimensionCategoryResultReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.DimensionCategoryResultReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DimensionCategoryResultReportRequest.displayName = 'proto.adx.summary_report.DimensionCategoryResultReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceCampaignReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceCampaignReportRequest.displayName = 'proto.adx.summary_report.LowPerformanceCampaignReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceAdGroupReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceAdGroupReportRequest.displayName = 'proto.adx.summary_report.LowPerformanceAdGroupReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.TabListOfSummaryReportPageResponse.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.TabListOfSummaryReportPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.TabListOfSummaryReportPageResponse.displayName = 'proto.adx.summary_report.TabListOfSummaryReportPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.displayName = 'proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.displayName = 'proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.WeeklyResultReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.WeeklyResultReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.WeeklyResultReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.WeeklyResultReportResponse.displayName = 'proto.adx.summary_report.WeeklyResultReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.WeeklyResultReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.WeeklyResultReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.WeeklyResultReportResponse.Result.displayName = 'proto.adx.summary_report.WeeklyResultReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.displayName = 'proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.displayName = 'proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.displayName = 'proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.displayName = 'proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.Graph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.Graph.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.Graph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.Graph.displayName = 'proto.adx.summary_report.Graph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DailyResultGraphReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.DailyResultGraphReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.DailyResultGraphReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DailyResultGraphReportResponse.displayName = 'proto.adx.summary_report.DailyResultGraphReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.DailyResultGraphReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.DailyResultGraphReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.displayName = 'proto.adx.summary_report.DailyResultGraphReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.displayName = 'proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.displayName = 'proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.displayName = 'proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.displayName = 'proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.DimensionCategoryResultReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.DimensionCategoryResultReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DimensionCategoryResultReportResponse.displayName = 'proto.adx.summary_report.DimensionCategoryResultReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.displayName = 'proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.displayName = 'proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.displayName = 'proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.displayName = 'proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.displayName = 'proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.DimensionCategoryResultReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.displayName = 'proto.adx.summary_report.DimensionCategoryResultReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.LowPerformanceCampaignReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.LowPerformanceCampaignReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.displayName = 'proto.adx.summary_report.LowPerformanceCampaignReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.displayName = 'proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.displayName = 'proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.displayName = 'proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.displayName = 'proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.displayName = 'proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.displayName = 'proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.LowPerformanceAdGroupReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.displayName = 'proto.adx.summary_report.LowPerformanceAdGroupReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.displayName = 'proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.displayName = 'proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.displayName = 'proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.displayName = 'proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.displayName = 'proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.displayName = 'proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.MonthlyResultReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.MonthlyResultReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.MonthlyResultReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.MonthlyResultReportResponse.displayName = 'proto.adx.summary_report.MonthlyResultReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.MonthlyResultReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.MonthlyResultReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.MonthlyResultReportResponse.Result.displayName = 'proto.adx.summary_report.MonthlyResultReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.displayName = 'proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.displayName = 'proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.ActualStatusReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.ActualStatusReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.ActualStatusReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.ActualStatusReportResponse.displayName = 'proto.adx.summary_report.ActualStatusReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.ActualStatusReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.ActualStatusReportResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.ActualStatusReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.ActualStatusReportResponse.Result.displayName = 'proto.adx.summary_report.ActualStatusReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.displayName = 'proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.displayName = 'proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.summary_report.GetDimensionCategoryAndComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.displayName = 'proto.adx.summary_report.GetDimensionCategoryAndComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.oneofGroups_);
};
goog.inherits(proto.adx.summary_report.GetDimensionCategoryAndComponentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.displayName = 'proto.adx.summary_report.GetDimensionCategoryAndComponentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.displayName = 'proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.displayName = 'proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.TabListOfSummaryReportPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adpageid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    requestid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageRequest}
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.TabListOfSummaryReportPageRequest;
  return proto.adx.summary_report.TabListOfSummaryReportPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageRequest}
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.TabListOfSummaryReportPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 adPageId = 1;
 * @return {number}
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageRequest} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageRequest} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string requestId = 3;
 * @return {string}
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageRequest} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.ActualStatusReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.ActualStatusReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.ActualStatusReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.ActualStatusReportRequest}
 */
proto.adx.summary_report.ActualStatusReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.ActualStatusReportRequest;
  return proto.adx.summary_report.ActualStatusReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.ActualStatusReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.ActualStatusReportRequest}
 */
proto.adx.summary_report.ActualStatusReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.ActualStatusReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.ActualStatusReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.ActualStatusReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.ActualStatusReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.ActualStatusReportRequest} returns this
 */
proto.adx.summary_report.ActualStatusReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string actualMonth = 2;
 * @return {string}
 */
proto.adx.summary_report.ActualStatusReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.ActualStatusReportRequest} returns this
 */
proto.adx.summary_report.ActualStatusReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.summary_report.ActualStatusReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.ActualStatusReportRequest} returns this
 */
proto.adx.summary_report.ActualStatusReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.MonthlyResultReportRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.MonthlyResultReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.MonthlyResultReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest}
 */
proto.adx.summary_report.MonthlyResultReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.MonthlyResultReportRequest;
  return proto.adx.summary_report.MonthlyResultReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.MonthlyResultReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest}
 */
proto.adx.summary_report.MonthlyResultReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.MonthlyResultReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.MonthlyResultReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest} returns this
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest} returns this
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest} returns this
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest} returns this
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest} returns this
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 limit = 6;
 * @return {number}
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest} returns this
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string sort = 7;
 * @return {!Array<string>}
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest} returns this
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest} returns this
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.MonthlyResultReportRequest} returns this
 */
proto.adx.summary_report.MonthlyResultReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.WeeklyResultReportRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.WeeklyResultReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.WeeklyResultReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest}
 */
proto.adx.summary_report.WeeklyResultReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.WeeklyResultReportRequest;
  return proto.adx.summary_report.WeeklyResultReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.WeeklyResultReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest}
 */
proto.adx.summary_report.WeeklyResultReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.WeeklyResultReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.WeeklyResultReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest} returns this
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest} returns this
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest} returns this
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest} returns this
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest} returns this
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 limit = 6;
 * @return {number}
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest} returns this
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string sort = 7;
 * @return {!Array<string>}
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest} returns this
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest} returns this
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.WeeklyResultReportRequest} returns this
 */
proto.adx.summary_report.WeeklyResultReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DailyResultGraphReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DailyResultGraphReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DailyResultGraphReportRequest}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DailyResultGraphReportRequest;
  return proto.adx.summary_report.DailyResultGraphReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DailyResultGraphReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DailyResultGraphReportRequest}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DailyResultGraphReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DailyResultGraphReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportRequest} returns this
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportRequest} returns this
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportRequest} returns this
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportRequest} returns this
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportRequest} returns this
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 limit = 6;
 * @return {number}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportRequest} returns this
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.DailyResultGraphReportRequest} returns this
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.DailyResultGraphReportRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DimensionCategoryResultReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DimensionCategoryResultReportRequest;
  return proto.adx.summary_report.DimensionCategoryResultReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DimensionCategoryResultReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 adPageComponentId = 3;
 * @return {number}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string sort = 8;
 * @return {!Array<string>}
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportRequest} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceCampaignReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportRequest}
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceCampaignReportRequest;
  return proto.adx.summary_report.LowPerformanceCampaignReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportRequest}
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceCampaignReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 adPageComponentId = 4;
 * @return {number}
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceAdGroupReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    campaign: jspb.Message.getFieldWithDefault(msg, 4, ""),
    backendkey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceAdGroupReportRequest;
  return proto.adx.summary_report.LowPerformanceAdGroupReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackendkey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceAdGroupReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBackendkey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 adProviderId = 3;
 * @return {number}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string campaign = 4;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string backendKey = 5;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.getBackendkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.setBackendkey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string actualMonth = 6;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 adPageComponentId = 7;
 * @return {number}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportRequest} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.summary_report.TabListOfSummaryReportPageResponse.TypeCase}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.TabListOfSummaryReportPageResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.TabListOfSummaryReportPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.TabListOfSummaryReportPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.TabListOfSummaryReportPageResponse;
  return proto.adx.summary_report.TabListOfSummaryReportPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result;
      reader.readMessage(value,proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.TabListOfSummaryReportPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    adpagesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adproviderList: jspb.Message.toObjectList(msg.getAdproviderList(),
    proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result;
  return proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagesid(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider;
      reader.readMessage(value,proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.deserializeBinaryFromReader);
      msg.addAdprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdpagesid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adproviderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tab: jspb.Message.getFieldWithDefault(msg, 2, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 3, ""),
    providerforshort: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider;
  return proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTab(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderforshort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTab();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProviderforshort();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 adProviderId = 1;
 * @return {number}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tab = 2;
 * @return {string}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.getTab = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.setTab = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string provider = 3;
 * @return {string}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string providerForShort = 4;
 * @return {string}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.getProviderforshort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider.prototype.setProviderforshort = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 adPagesId = 1;
 * @return {number}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.prototype.getAdpagesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.prototype.setAdpagesid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated AdProvider adProvider = 2;
 * @return {!Array<!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider>}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.prototype.getAdproviderList = function() {
  return /** @type{!Array<!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider>} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result} returns this
*/
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.prototype.setAdproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.prototype.addAdprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.AdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result.prototype.clearAdproviderList = function() {
  return this.setAdproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result, 1));
};


/**
 * @param {?proto.adx.summary_report.TabListOfSummaryReportPageResponse.Result|undefined} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse} returns this
*/
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.summary_report.TabListOfSummaryReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.TabListOfSummaryReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.TabListOfSummaryReportPageResponse} returns this
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.TabListOfSummaryReportPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.TabListOfSummaryReportPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.WeeklyResultReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.WeeklyResultReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.summary_report.WeeklyResultReportResponse.TypeCase}
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.WeeklyResultReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.WeeklyResultReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.WeeklyResultReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.summary_report.WeeklyResultReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse}
 */
proto.adx.summary_report.WeeklyResultReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.WeeklyResultReportResponse;
  return proto.adx.summary_report.WeeklyResultReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse}
 */
proto.adx.summary_report.WeeklyResultReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.summary_report.WeeklyResultReportResponse.Result;
      reader.readMessage(value,proto.adx.summary_report.WeeklyResultReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.WeeklyResultReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.summary_report.WeeklyResultReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    showalertList: jspb.Message.toObjectList(msg.getShowalertList(),
    proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.toObject, includeInstance),
    weeklyresultreportList: jspb.Message.toObjectList(msg.getWeeklyresultreportList(),
    proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.WeeklyResultReportResponse.Result;
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert;
      reader.readMessage(value,proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.deserializeBinaryFromReader);
      msg.addShowalert(value);
      break;
    case 4:
      var value = new proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport;
      reader.readMessage(value,proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.deserializeBinaryFromReader);
      msg.addWeeklyresultreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.WeeklyResultReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShowalertList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.serializeBinaryToWriter
    );
  }
  f = message.getWeeklyresultreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
    week: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemList: jspb.Message.toObjectList(msg.getItemList(),
    proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert;
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeek(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item;
      reader.readMessage(value,proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.deserializeBinaryFromReader);
      msg.addItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeek();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item;
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rate = 2;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string week = 1;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.prototype.getWeek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.prototype.setWeek = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Item item = 2;
 * @return {!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item>}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.prototype.getItemList = function() {
  return /** @type{!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item>} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert} returns this
*/
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.prototype.setItemList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.prototype.addItem = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert.prototype.clearItemList = function() {
  return this.setItemList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isalert: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport;
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsalert(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table;
      reader.readMessage(value,proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsalert();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table;
  return proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string isAlert = 2;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.getIsalert = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.setIsalert = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table>}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table>} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport} returns this
*/
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ShowAlert showAlert = 3;
 * @return {!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert>}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.getShowalertList = function() {
  return /** @type{!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert>} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result} returns this
*/
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.setShowalertList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.addShowalert = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.WeeklyResultReportResponse.Result.ShowAlert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.clearShowalertList = function() {
  return this.setShowalertList([]);
};


/**
 * repeated WeeklyResultReport weeklyResultReport = 4;
 * @return {!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport>}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.getWeeklyresultreportList = function() {
  return /** @type{!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport, 4));
};


/**
 * @param {!Array<!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport>} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result} returns this
*/
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.setWeeklyresultreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport}
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.addWeeklyresultreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.summary_report.WeeklyResultReportResponse.Result.WeeklyResultReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse.Result} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.Result.prototype.clearWeeklyresultreportList = function() {
  return this.setWeeklyresultreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.summary_report.WeeklyResultReportResponse.Result}
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.summary_report.WeeklyResultReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.summary_report.WeeklyResultReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.summary_report.WeeklyResultReportResponse.Result|undefined} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse} returns this
*/
proto.adx.summary_report.WeeklyResultReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.summary_report.WeeklyResultReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.WeeklyResultReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.WeeklyResultReportResponse} returns this
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.WeeklyResultReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.WeeklyResultReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.Graph.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.Graph.TypeCase = {
  TYPE_NOT_SET: 0,
  BAR: 1,
  LINE: 2
};

/**
 * @return {proto.adx.summary_report.Graph.TypeCase}
 */
proto.adx.summary_report.Graph.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.Graph.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.Graph.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.Graph.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.Graph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.Graph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.Graph.toObject = function(includeInstance, msg) {
  var f, obj = {
    bar: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.Graph}
 */
proto.adx.summary_report.Graph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.Graph;
  return proto.adx.summary_report.Graph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.Graph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.Graph}
 */
proto.adx.summary_report.Graph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBar(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.Graph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.Graph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.Graph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.Graph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string bar = 1;
 * @return {string}
 */
proto.adx.summary_report.Graph.prototype.getBar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.Graph} returns this
 */
proto.adx.summary_report.Graph.prototype.setBar = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.adx.summary_report.Graph.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.Graph} returns this
 */
proto.adx.summary_report.Graph.prototype.clearBar = function() {
  return jspb.Message.setOneofField(this, 1, proto.adx.summary_report.Graph.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.Graph.prototype.hasBar = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string line = 2;
 * @return {string}
 */
proto.adx.summary_report.Graph.prototype.getLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.Graph} returns this
 */
proto.adx.summary_report.Graph.prototype.setLine = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.Graph.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.Graph} returns this
 */
proto.adx.summary_report.Graph.prototype.clearLine = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.Graph.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.Graph.prototype.hasLine = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.DailyResultGraphReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.summary_report.DailyResultGraphReportResponse.TypeCase}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.DailyResultGraphReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.DailyResultGraphReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DailyResultGraphReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.summary_report.DailyResultGraphReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DailyResultGraphReportResponse;
  return proto.adx.summary_report.DailyResultGraphReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.summary_report.DailyResultGraphReportResponse.Result;
      reader.readMessage(value,proto.adx.summary_report.DailyResultGraphReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DailyResultGraphReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.summary_report.DailyResultGraphReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.summary_report.Graph.toObject, includeInstance),
    showalertList: jspb.Message.toObjectList(msg.getShowalertList(),
    proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.toObject, includeInstance),
    dailyresultgraphreportList: jspb.Message.toObjectList(msg.getDailyresultgraphreportList(),
    proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DailyResultGraphReportResponse.Result;
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.Graph;
      reader.readMessage(value,proto.adx.summary_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 4:
      var value = new proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert;
      reader.readMessage(value,proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.deserializeBinaryFromReader);
      msg.addShowalert(value);
      break;
    case 5:
      var value = new proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport;
      reader.readMessage(value,proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.deserializeBinaryFromReader);
      msg.addDailyresultgraphreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getShowalertList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.serializeBinaryToWriter
    );
  }
  f = message.getDailyresultgraphreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemList: jspb.Message.toObjectList(msg.getItemList(),
    proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert;
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item;
      reader.readMessage(value,proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.deserializeBinaryFromReader);
      msg.addItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item;
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rate = 2;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Item item = 2;
 * @return {!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item>}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.prototype.getItemList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item>} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert} returns this
*/
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.prototype.setItemList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.prototype.addItem = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert.prototype.clearItemList = function() {
  return this.setItemList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dayofweek: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isalert: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport;
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDayofweek(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsalert(value);
      break;
    case 4:
      var value = new proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table;
      reader.readMessage(value,proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayofweek();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsalert();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table;
  return proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dayOfWeek = 2;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.getDayofweek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.setDayofweek = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string isAlert = 3;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.getIsalert = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.setIsalert = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Table table = 4;
 * @return {!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table>}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table, 4));
};


/**
 * @param {!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table>} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport} returns this
*/
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Graph graph = 3;
 * @return {!Array<!proto.adx.summary_report.Graph>}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.summary_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.Graph, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.Graph>} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} returns this
*/
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.Graph}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated ShowAlert showAlert = 4;
 * @return {!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert>}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.getShowalertList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert, 4));
};


/**
 * @param {!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert>} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} returns this
*/
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.setShowalertList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.addShowalert = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.summary_report.DailyResultGraphReportResponse.Result.ShowAlert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.clearShowalertList = function() {
  return this.setShowalertList([]);
};


/**
 * repeated DailyResultGraphReport dailyResultGraphReport = 5;
 * @return {!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport>}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.getDailyresultgraphreportList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport, 5));
};


/**
 * @param {!Array<!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport>} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} returns this
*/
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.setDailyresultgraphreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.addDailyresultgraphreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.summary_report.DailyResultGraphReportResponse.Result.DailyResultGraphReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse.Result} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.Result.prototype.clearDailyresultgraphreportList = function() {
  return this.setDailyresultgraphreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.summary_report.DailyResultGraphReportResponse.Result}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.summary_report.DailyResultGraphReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.summary_report.DailyResultGraphReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.summary_report.DailyResultGraphReportResponse.Result|undefined} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse} returns this
*/
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.summary_report.DailyResultGraphReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.DailyResultGraphReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.DailyResultGraphReportResponse} returns this
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.DailyResultGraphReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.DailyResultGraphReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.summary_report.DimensionCategoryResultReportResponse.TypeCase}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.DimensionCategoryResultReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DimensionCategoryResultReportResponse;
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Result;
      reader.readMessage(value,proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DimensionCategoryResultReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rate: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables;
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rate = 3;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows;
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table;
      reader.readMessage(value,proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table;
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table>}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows} returns this
*/
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.repeatedFields_ = [2,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.toObject, includeInstance),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory;
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables;
      reader.readMessage(value,proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows;
      reader.readMessage(value,proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdproviderid(value);
      break;
    case 5:
      var value = new proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory;
      reader.readMessage(value,proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.serializeBinaryToWriter
    );
  }
  f = message.getAdproviderid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Variables variables = 2;
 * @return {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables>}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} returns this
*/
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows>}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} returns this
*/
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string adProviderId = 4;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.getAdproviderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated NestCategory nestCategory = 5;
 * @return {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory>}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory, 5));
};


/**
 * @param {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} returns this
*/
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.repeatedFields_ = [2,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.toObject, includeInstance),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport;
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables;
      reader.readMessage(value,proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows;
      reader.readMessage(value,proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdproviderid(value);
      break;
    case 5:
      var value = new proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory;
      reader.readMessage(value,proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows.serializeBinaryToWriter
    );
  }
  f = message.getAdproviderid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Variables variables = 2;
 * @return {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables>}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} returns this
*/
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.DimensionCategoryResultReportResponse.Variables, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows>}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} returns this
*/
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.DimensionCategoryResultReportResponse.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string adProviderId = 4;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.getAdproviderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated NestCategory nestCategory = 5;
 * @return {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory>}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory, 5));
};


/**
 * @param {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} returns this
*/
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.summary_report.DimensionCategoryResultReportResponse.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categorytypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dimensioncategoryresultreportList: jspb.Message.toObjectList(msg.getDimensioncategoryresultreportList(),
    proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.DimensionCategoryResultReportResponse.Result;
  return proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorytype(value);
      break;
    case 4:
      var value = new proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport;
      reader.readMessage(value,proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.deserializeBinaryFromReader);
      msg.addDimensioncategoryresultreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategorytypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDimensioncategoryresultreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryType = 3;
 * @return {!Array<string>}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.getCategorytypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.setCategorytypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.addCategorytype = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.clearCategorytypeList = function() {
  return this.setCategorytypeList([]);
};


/**
 * repeated DimensionCategoryResultReport dimensionCategoryResultReport = 4;
 * @return {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport>}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.getDimensioncategoryresultreportList = function() {
  return /** @type{!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport, 4));
};


/**
 * @param {!Array<!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport>} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} returns this
*/
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.setDimensioncategoryresultreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.addDimensioncategoryresultreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.summary_report.DimensionCategoryResultReportResponse.DimensionCategoryResultReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.Result.prototype.clearDimensioncategoryresultreportList = function() {
  return this.setDimensioncategoryresultreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.summary_report.DimensionCategoryResultReportResponse.Result}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.summary_report.DimensionCategoryResultReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.summary_report.DimensionCategoryResultReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.summary_report.DimensionCategoryResultReportResponse.Result|undefined} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse} returns this
*/
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.summary_report.DimensionCategoryResultReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.DimensionCategoryResultReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.DimensionCategoryResultReportResponse} returns this
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.DimensionCategoryResultReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.DimensionCategoryResultReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.summary_report.LowPerformanceCampaignReportResponse.TypeCase}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.LowPerformanceCampaignReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.LowPerformanceCampaignReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceCampaignReportResponse;
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.summary_report.Graph.toObject, includeInstance),
    deteriorationList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    lowperformancecampaignreportList: jspb.Message.toObjectList(msg.getLowperformancecampaignreportList(),
    proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result;
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.adx.summary_report.Graph;
      reader.readMessage(value,proto.adx.summary_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeterioration(value);
      break;
    case 6:
      var value = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.deserializeBinaryFromReader);
      msg.addLowperformancecampaignreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.summary_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getDeteriorationList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getLowperformancecampaignreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    deterioration: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthlyList: jspb.Message.toObjectList(msg.getMonthlyList(),
    proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.toObject, includeInstance),
    weeklyList: jspb.Message.toObjectList(msg.getWeeklyList(),
    proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport;
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeterioration(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.deserializeBinaryFromReader);
      msg.addMonthly(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.deserializeBinaryFromReader);
      msg.addWeekly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeterioration();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthlyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.serializeBinaryToWriter
    );
  }
  f = message.getWeeklyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly;
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table;
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table>}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table>} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly} returns this
*/
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly;
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table;
  return proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table>}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table>} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly} returns this
*/
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string deterioration = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.getDeterioration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.setDeterioration = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Monthly monthly = 2;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly>}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.getMonthlyList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly>} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport} returns this
*/
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.setMonthlyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.addMonthly = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Monthly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.clearMonthlyList = function() {
  return this.setMonthlyList([]);
};


/**
 * repeated Weekly weekly = 3;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly>}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.getWeeklyList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly>} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport} returns this
*/
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.setWeeklyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.addWeekly = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.Weekly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport.prototype.clearWeeklyList = function() {
  return this.setWeeklyList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Graph graph = 4;
 * @return {!Array<!proto.adx.summary_report.Graph>}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.summary_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.Graph, 4));
};


/**
 * @param {!Array<!proto.adx.summary_report.Graph>} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
*/
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.summary_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.Graph}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.summary_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated string deterioration = 5;
 * @return {!Array<string>}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.getDeteriorationList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.setDeteriorationList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.addDeterioration = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.clearDeteriorationList = function() {
  return this.setDeteriorationList([]);
};


/**
 * repeated LowPerformanceCampaignReport lowPerformanceCampaignReport = 6;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport>}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.getLowperformancecampaignreportList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport, 6));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport>} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
*/
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.setLowperformancecampaignreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.addLowperformancecampaignreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.LowPerformanceCampaignReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result.prototype.clearLowperformancecampaignreportList = function() {
  return this.setLowperformancecampaignreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.summary_report.LowPerformanceCampaignReportResponse.Result|undefined} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse} returns this
*/
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.summary_report.LowPerformanceCampaignReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.LowPerformanceCampaignReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.LowPerformanceCampaignReportResponse} returns this
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.LowPerformanceCampaignReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.LowPerformanceCampaignReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.summary_report.LowPerformanceAdGroupReportResponse.TypeCase}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.LowPerformanceAdGroupReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse;
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.summary_report.Graph.toObject, includeInstance),
    adgroupList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    lowperformanceadgroupreportList: jspb.Message.toObjectList(msg.getLowperformanceadgroupreportList(),
    proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result;
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.adx.summary_report.Graph;
      reader.readMessage(value,proto.adx.summary_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdgroup(value);
      break;
    case 6:
      var value = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.deserializeBinaryFromReader);
      msg.addLowperformanceadgroupreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.summary_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getAdgroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getLowperformanceadgroupreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    adgroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthlyList: jspb.Message.toObjectList(msg.getMonthlyList(),
    proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.toObject, includeInstance),
    weeklyList: jspb.Message.toObjectList(msg.getWeeklyList(),
    proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport;
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdgroup(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.deserializeBinaryFromReader);
      msg.addMonthly(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.deserializeBinaryFromReader);
      msg.addWeekly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdgroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthlyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.serializeBinaryToWriter
    );
  }
  f = message.getWeeklyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly;
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table;
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table>}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table>} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly} returns this
*/
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly;
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table;
      reader.readMessage(value,proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table;
  return proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table>}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table>} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly} returns this
*/
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string adGroup = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.getAdgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.setAdgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Monthly monthly = 2;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly>}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.getMonthlyList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly>} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport} returns this
*/
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.setMonthlyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.addMonthly = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Monthly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.clearMonthlyList = function() {
  return this.setMonthlyList([]);
};


/**
 * repeated Weekly weekly = 3;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly>}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.getWeeklyList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly>} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport} returns this
*/
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.setWeeklyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.addWeekly = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.Weekly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport.prototype.clearWeeklyList = function() {
  return this.setWeeklyList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Graph graph = 4;
 * @return {!Array<!proto.adx.summary_report.Graph>}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.summary_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.Graph, 4));
};


/**
 * @param {!Array<!proto.adx.summary_report.Graph>} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
*/
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.summary_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.Graph}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.summary_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated string adGroup = 5;
 * @return {!Array<string>}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.getAdgroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.setAdgroupList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.addAdgroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.clearAdgroupList = function() {
  return this.setAdgroupList([]);
};


/**
 * repeated LowPerformanceAdGroupReport lowPerformanceAdGroupReport = 6;
 * @return {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport>}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.getLowperformanceadgroupreportList = function() {
  return /** @type{!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport, 6));
};


/**
 * @param {!Array<!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport>} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
*/
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.setLowperformanceadgroupreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.addLowperformanceadgroupreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.LowPerformanceAdGroupReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result.prototype.clearLowperformanceadgroupreportList = function() {
  return this.setLowperformanceadgroupreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.summary_report.LowPerformanceAdGroupReportResponse.Result|undefined} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse} returns this
*/
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.LowPerformanceAdGroupReportResponse} returns this
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.LowPerformanceAdGroupReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.LowPerformanceAdGroupReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.MonthlyResultReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.MonthlyResultReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.summary_report.MonthlyResultReportResponse.TypeCase}
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.MonthlyResultReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.MonthlyResultReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.MonthlyResultReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.summary_report.MonthlyResultReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse}
 */
proto.adx.summary_report.MonthlyResultReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.MonthlyResultReportResponse;
  return proto.adx.summary_report.MonthlyResultReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse}
 */
proto.adx.summary_report.MonthlyResultReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.summary_report.MonthlyResultReportResponse.Result;
      reader.readMessage(value,proto.adx.summary_report.MonthlyResultReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.MonthlyResultReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.summary_report.MonthlyResultReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.MonthlyResultReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    monthlyresultreportList: jspb.Message.toObjectList(msg.getMonthlyresultreportList(),
    proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.MonthlyResultReportResponse.Result;
  return proto.adx.summary_report.MonthlyResultReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport;
      reader.readMessage(value,proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.deserializeBinaryFromReader);
      msg.addMonthlyresultreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.MonthlyResultReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonthlyresultreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport;
  return proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table;
      reader.readMessage(value,proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table;
  return proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table>}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table>} value
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport} returns this
*/
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MonthlyResultReport monthlyResultReport = 3;
 * @return {!Array<!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport>}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.getMonthlyresultreportList = function() {
  return /** @type{!Array<!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport>} value
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result} returns this
*/
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.setMonthlyresultreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport}
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.addMonthlyresultreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.MonthlyResultReportResponse.Result.MonthlyResultReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse.Result} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.Result.prototype.clearMonthlyresultreportList = function() {
  return this.setMonthlyresultreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.summary_report.MonthlyResultReportResponse.Result}
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.summary_report.MonthlyResultReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.summary_report.MonthlyResultReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.summary_report.MonthlyResultReportResponse.Result|undefined} value
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse} returns this
*/
proto.adx.summary_report.MonthlyResultReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.summary_report.MonthlyResultReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.MonthlyResultReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.MonthlyResultReportResponse} returns this
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.MonthlyResultReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.MonthlyResultReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.ActualStatusReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.ActualStatusReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.summary_report.ActualStatusReportResponse.TypeCase}
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.ActualStatusReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.ActualStatusReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.ActualStatusReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.ActualStatusReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.summary_report.ActualStatusReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse}
 */
proto.adx.summary_report.ActualStatusReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.ActualStatusReportResponse;
  return proto.adx.summary_report.ActualStatusReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.ActualStatusReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse}
 */
proto.adx.summary_report.ActualStatusReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.summary_report.ActualStatusReportResponse.Result;
      reader.readMessage(value,proto.adx.summary_report.ActualStatusReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.ActualStatusReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.ActualStatusReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.summary_report.ActualStatusReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.ActualStatusReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actualstatusreportList: jspb.Message.toObjectList(msg.getActualstatusreportList(),
    proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.ActualStatusReportResponse.Result;
  return proto.adx.summary_report.ActualStatusReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport;
      reader.readMessage(value,proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.deserializeBinaryFromReader);
      msg.addActualstatusreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.ActualStatusReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActualstatusreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    graph: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport;
  return proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGraph(value);
      break;
    case 2:
      var value = new proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table;
      reader.readMessage(value,proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraph();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table;
  return proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string graph = 1;
 * @return {string}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.prototype.getGraph = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.prototype.setGraph = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table>}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table, 2));
};


/**
 * @param {!Array<!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table>} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport} returns this
*/
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ActualStatusReport actualStatusReport = 3;
 * @return {!Array<!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport>}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.getActualstatusreportList = function() {
  return /** @type{!Array<!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport>} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result} returns this
*/
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.setActualstatusreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport}
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.addActualstatusreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.ActualStatusReportResponse.Result.ActualStatusReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse.Result} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.Result.prototype.clearActualstatusreportList = function() {
  return this.setActualstatusreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.summary_report.ActualStatusReportResponse.Result}
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.summary_report.ActualStatusReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.summary_report.ActualStatusReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.summary_report.ActualStatusReportResponse.Result|undefined} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse} returns this
*/
proto.adx.summary_report.ActualStatusReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.summary_report.ActualStatusReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.ActualStatusReportResponse} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.ActualStatusReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.ActualStatusReportResponse} returns this
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.ActualStatusReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.ActualStatusReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.GetDimensionCategoryAndComponentRequest;
  return proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentRequest} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.TypeCase}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.GetDimensionCategoryAndComponentResponse;
  return proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result;
      reader.readMessage(value,proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    categoryList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId;
  return proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCategoryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string category = 2;
 * @return {!Array<string>}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.prototype.getCategoryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.prototype.setCategoryList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.prototype.addCategory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.prototype.clearCategoryList = function() {
  return this.setCategoryList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    adpagecomponentidList: jspb.Message.toObjectList(msg.getAdpagecomponentidList(),
    proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result;
  return proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategory(value);
      break;
    case 3:
      var value = new proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId;
      reader.readMessage(value,proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.deserializeBinaryFromReader);
      msg.addAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getAdpagecomponentidList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string category = 2;
 * @return {!Array<string>}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.getCategoryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.setCategoryList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.addCategory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.clearCategoryList = function() {
  return this.setCategoryList([]);
};


/**
 * repeated AdPageComponentId adPageComponentId = 3;
 * @return {!Array<!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId>}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.getAdpagecomponentidList = function() {
  return /** @type{!Array<!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId, 3));
};


/**
 * @param {!Array<!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId>} value
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} returns this
*/
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.setAdpagecomponentidList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.addAdpagecomponentid = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.AdPageComponentId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result.prototype.clearAdpagecomponentidList = function() {
  return this.setAdpagecomponentidList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result, 1));
};


/**
 * @param {?proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.Result|undefined} value
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse} returns this
*/
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.summary_report.GetDimensionCategoryAndComponentResponse} returns this
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.summary_report.GetDimensionCategoryAndComponentResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.summary_report);
