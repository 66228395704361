import {
  AllocatedDailyBudgetRequest,
  CampaignAllocatedBudgetInitialBuildRequest,
  CampaignAllocatedBudgetRebuildRequest,
  ListValue,
} from './protos_gen/budget_allocation_pb.js';
import { BudgetAllocationClient } from './protos_gen/budget_allocation_grpc_web_pb.js';
import { url } from '../../setting.js';
import { uuid } from '../../tacklebox/core/uuid.js';
/* eslint-disable */

function getCampaignAllocatedBudgetInitialBuild(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new CampaignAllocatedBudgetInitialBuildRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);
  request.setContenttype('application/json');

  var client = new BudgetAllocationClient(url.grpc, null, null);
  const stream = client.getCampaignAllocatedBudgetInitialBuild(request);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getCampaignAllocatedBudgetRebuild(id, callback) {
  let uuidStr = uuid();
  let actualMonth = '2021-02';

  let request = new CampaignAllocatedBudgetRebuildRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  request.setActualmonth(actualMonth);
  request.setContenttype('application/json');

  var client = new BudgetAllocationClient(url.grpc, null, null);
  const stream = client.getCampaignAllocatedBudgetRebuild(request);

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

function updateAllocatedDailyBudget(id, callback) {
  let uuidStr = uuid();
  let request = new AllocatedDailyBudgetRequest();
  request.setBaseperiodforforecastid(id);
  let value = new ListValue();
  value.setSimulatedAt('');
  value.setAllocatedAt('');
  value.setCampaign('');
  value.setAdGroup('');
  value.setDailyBudget('');
  value.setActualDailyBudget('');
  value.setBracket1('');
  value.setBracket2('');
  value.setBracket3('');
  value.setBracket4('');
  value.setBracket5('');
  value.setBracket6('');
  value.setBracket7('');
  value.setBracket8('');
  value.setBracket9('');
  request.setValues(value);

  var client = new BudgetAllocationClient(url.grpc, null, null);
  const stream = client.updateAllocatedDailyBudget(request);

  stream.on('data', message => {
    if (callback) {
      if (!!message != false) callback(uuidStr, message);
      else callback(uuidStr, null);
    }
  });
  //TBD エラー

  return uuidStr;
}

export {
  getCampaignAllocatedBudgetInitialBuild,
  getCampaignAllocatedBudgetRebuild,
  updateAllocatedDailyBudget,
};
