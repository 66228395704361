<template>
  <div
    :style="{
      width: 'calc(100% + 20px)',
      height: 'calc(100% + 20px)',
      boxShadow: '0px 3px 20px #00000029',
      backgroundColor: '#ffffff',
      position: 'relative',
      top: '-10px',
      bottom: '-10px',
      left: '-10px',
      right: '-10px',
    }"
    style="font: 14px / 20px 'Noto Sans JP'; border-radius: 8px;"
    class="ei1"
  >
    <input
      class="ei1"
      type="text"
      id="txt_item"
      style="position: absolute; top: 6px; left: 6px; font: 14px / 20px 'Noto Sans JP'"
      :style="{
        resize: 'none',
        width: 'calc(100% - 12px)',
        height: 'calc(100% - 12px)',
        outline: 'none',
        boxShadow: '0 0 0', // よくわからないけど、この行を入れないと枠線がでちゃう。
      }"
      v-model="inputData"
      v-on:blur="decide()"
      v-if="mode != 1"
      @keydown.prevent.tab.exact="decide(0)"
      @keydown.prevent.shift.tab="decide(0)"
      @keydown.enter="decide()"
    />
    <div
      v-show="true"

      v-if="mode != 2"
      style="
        width: 100%;
        font: 14px / 20px 'Noto Sans JP';
        height: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: calc(100% - 2px);
        height: 100%;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        letter-spacing: 0.1px;
      "
    >
      {{ inputData }}
    </div>
    <textarea
    v-show="true"

      class="ei1"
      id="txt_item"
      v-model="inputData"
      style="position: absolute; top: 5px; left: 5px; background: rgba(255,255,255, 1);letter-spacing: 0.1px;"
      :style="{
        resize: 'none',
        width: 'calc(100% - 12px)',
        height: 'calc(100% - 10px)',
        outline: 'none',
        boxShadow: '0 0 0', // よくわからないけど、この行を入れないと枠線がでちゃう。
      }"
      @keydown.prevent.tab.exact="decide(0)"
      @keydown.prevent.shift.tab="decide(0)"
      @keydown.enter.shift="yyy()"
      @keydown.enter.ctrl="xxx()"
      @keydown.enter.alt="xxx()"
      @keydown.enter.exact="decide()"
      v-on:blur="decide()"
      v-if="mode == 1"
    />
  </div>
</template>

<style scoped>
.ei1 {
  font: 14px / 20px 'Noto Sans JP';
}
</style>

<script>
export default {
  name: 'EditItem3',
  data() {
    return {
      name: 'EditItem3',
      inputData: '',
      isSave: false,
    };
  },
  computed: {},
  mounted() {
    this.isSave = false;
    this.inputData = this.item.value;
    if (document.getElementById('txt_item')) {
      document.getElementById('txt_item').focus();
    }
  },
  methods: {
    inputText(item) {
      item.edit();
    },
    yyy() {},
    xxx() {
      this.inputData = this.inputData + '\n';
    },
    decide(mode) {
      if (this.isSave) {
        return;
      }
      this.isSave = true;

      this.item.setInputValue(this.inputData);
      this.item.decide();
      if (mode == 1) {
        this.next.edit();
      }
      if (mode == 2) {
        this.prev.edit();
      }
    },
  },
  components: {},
  props: {
    mode: Number,
    item: Object,
    next: Object,
    prev: Object,
  },
};
</script>
