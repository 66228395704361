import {
  getCustomReports,
  deleteCustomReport,
  createCustomReport,
  getCustomAvailableKeys,
  getSegmentName,
  getSegmentValue,
  getCustomReportPages,
  createCustomReportPage,
  deleteCustomReportPage,
  copyCustomReportPage,
  updateCustomReport,
  updateCustomReportPage,
  reorderCustomReportPages,
  reorderCustomReports
} from '../../com/grpc/custom_report';
import * as tacklebox from '../../tacklebox/core/index.js';
import { Cell } from '../data.js';
import { getInformationLatestUpdate } from '../../com/grpc/information';
import { /*ratescale3Step, getColorKeyCodeByBackendKey,*/ cpa11StepColor } from '../../setting.js';
// import { kmaster } from '../kmaster';
import kmaster from "@/assets/json/kmaster.json"

function isHideColKey2ByName(name) {
  if (name) {
    if (name.length > 0) {
      if (name.indexOf("(i)") >= 0) {
        return true;
      }
    }
  }
  return false;
}

let colScale3 = cpa11StepColor();

function CrCell(data, dataType = 'string') {
  Cell.call(this, data, dataType);
  this.cells = [];
  this.isShow = true;
  this.isColor = true;
}
tacklebox.extend(CrCell, Cell);
CrCell.prototype.getCells = function () {
  return this.cells;
};

const ScreenMode = {
  Read: 0,
  Create0: 1,
  Create1: 10,
  Create2: 20,
  PageCreate: 100,
  PageUpdate: 110,
};

function getCustomReportBarValueSettings(key) {
  if (key.indexOf('imp') >= 0) {
    return 'colored_imp';
  }
  if (key.indexOf('click') >= 0) {
    return 'colored_click';
  }
  if (key.indexOf('cpc') >= 0) {
    return 'colored_cpc';
  }
  if (key.indexOf('ctr') >= 0) {
    return 'colored_ctr';
  }
  if (key.indexOf('cost_with_fee') >= 0) {
    return 'colored_cost';
  }
  if (key.indexOf('_cvr') >= 0) {
    return 'colored_cvr';
  }
  if (key.indexOf('_cv') >= 0) {
    return 'colored_cv';
  }
  if (key.indexOf('_cpa') >= 0) {
    return 'colored_cpa';
  }
  if (key.indexOf('_roas') >= 0) {
    return 'colored_roas';
  }

  return '';
}

function CustomReportStructureHolder(structure, accountType) {
  this.structure = JSON.parse(JSON.stringify(structure));
  this.accountType = accountType;
}
CustomReportStructureHolder.prototype.getTypeByBackendKey = function (backendKey) {
  if (this.structure?.table) {
    for (let i in this.structure.table) {
      if (this.structure.table[i].backendKey == backendKey) {
        return this.structure.table[i].type;
      }
    }
  }

  return 'string';
};
CustomReportStructureHolder.prototype.getShowHides = function () {
  let showHides = [];
  if (this.structure?.table) {
    for (let i in this.structure.table) {
      showHides.push(
        new ShowHide(this.structure.table[i].backendKey, !this.structure.table[i].display)
      );
    }
  }

  return showHides;
};
CustomReportStructureHolder.prototype.getColorHides = function () {
  let colorHides = [];
  if (this.structure?.table) {
    for (let i in this.structure.table) {
      colorHides.push(
        new ShowHide(this.structure.table[i].backendKey, !this.structure.table[i].color)
      );
    }
  }

  return colorHides;
};

CustomReportStructureHolder.prototype.getShowHideSegs = function () {
  let showHideSegs = [];

  if (this.structure?.segmentsList) {
    for (let i in this.structure.segmentsList) {
      showHideSegs.push(
        new ShowHide(this.structure.segmentsList[i].name, !this.structure.segmentsList[i].display)
      );
    }
  }

  return showHideSegs;
};

function ShowHide(key, isHide = false) {
  this.key = key;
  this.isHide = isHide;
}

function ShowHideItem(key, label) {
  this.key = key;
  this.label = label;
}

function DragSizing(index, size) {
  this.index = index;
  this.size = size;
}

function WidthSizing(index, size) {
  this.index = index;
  this.size = size;
}

function CustomReport(
  id,
  master,
  structureHolder = null,
  raw = null,
  callback = function () {},
  order = 1,
  title = ''
) {
  this.type = -1;
  this.id = id;
  this.master = master;
  this.structureHolder = structureHolder;
  this.raw = raw;
  this._currentPageStreams = [];
  this.callback = callback;
  this.order = order;
  this.selectedIndex = -1;
  this.selectedVIndex = -1;
  this.showHideSegs = [];
  this.showHides = [];
  this.colorHides = [];
  this.dragsizings = [];
  this.widthsizings = [];
  this.isEditTitle = false;
  this.title = title;
  this.showHides = structureHolder.getShowHides();
  this.colorHides = structureHolder.getColorHides();

  this.showHideSegs = [new ShowHide('媒体', false)];
  this.showHideSegs = structureHolder.getShowHideSegs();

  this.segmentRaw = [];
  if (this.structureHolder) {
    this.segmentRaw = this.structureHolder.structure.segmentsList;
  }
  this.customAvailableKeysRaw = [];
  if (this.structureHolder) {
    this.customAvailableKeysRaw = this.structureHolder.structure.table;
  }

  try {
    let jsntxt = localStorage.getItem('cstr_' + this.id);
    let jsnp = JSON.parse(jsntxt);
    for (let i in jsnp) {
      this.widthsizings.push(new WidthSizing(jsnp[i].index, jsnp[i].size));
    }
  } catch (ex) {
    console.log(ex);
  }
}
CustomReport.prototype.delete = function (callback) {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master.user.auth0.token,
    userUuid: this.master.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;
  const cuid = deleteCustomReport(
    this.id,
    [this.master?.pageId],
    false,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      callback();
    },
    null,
    this._currentPageStreams
  );
};
CustomReport.prototype.update = function (callback = null) {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      //
      if (callback) {
        callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReport.prototype.notify = function (callback) {
  callback();
};
CustomReport.prototype.initialize = function () {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master.user.auth0.token,
    userUuid: this.master.user.userUuid,
  };

  request.advertizerId = this.master.advertizerId;
  const self = this;
  const cuid = getCustomReportPages(
    [this.id],
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          if (data?.result) {
            let result = data?.result;
            // self.load();
            self.structureHolder = new CustomReportStructureHolder(
              JSON.parse(result.structure),
              'all'
            );
            self.type = result.reporttype;
            switch (self.type) {
              case 1:
                self.raw = result.reportdata1List;
                break;
              case 2:
                self.raw = result.reportdata2List;
                break;
              case 3:
                self.raw = result.reportdata3List;
                break;
            }
          }
        }
      }
    },
    function () {},
    null,
    this._currentPageStreams
  );
};
CustomReport.prototype.getHeaderCell = function () {
  let res = [];
  return res;
};
CustomReport.prototype.getTitle = function () {
  if (this.title == null) {
    this.title = '無題';
  }
  if (this.title.length == 0) {
    this.title = '無題';
  }
  return this.title;
};
CustomReport.prototype.setTitle = function (title) {
  this.title = title;
};
CustomReport.prototype.enterEditTitle = function () {
  if (this.title == '無題') {
    this.title = '';
  }
  this.isEditTitle = true;
};
CustomReport.prototype.closeEditTitle = function () {
  this.update();
  this.isEditTitle = false;
};
CustomReport.prototype.getRecords = function () {
  return [];
};
CustomReport.prototype.getSizeByIndex = function (index) {
  let dragsize = 0;
  for (let i in this.dragsizings) {
    let ds = this.dragsizings[i];
    if (ds.index == index) {
      dragsize = ds.size;
      break;
    }
  }

  let size = 120;
  for (let i in this.widthsizings) {
    let ws = this.widthsizings[i];
    if (ws.index == index) {
      size = ws.size;
      break;
    }
  }

  return size + dragsize;
};

CustomReport.prototype.dragSizeByIndex = function (index, size) {
  for (let i in this.dragsizings) {
    let ds = this.dragsizings[i];
    if (ds.index == index) {
      ds.size = size;
      return;
    }
  }
  this.dragsizings.push(new DragSizing(index, size));
};
CustomReport.prototype.dragChangeSizeByIndex = function (index) {
  for (let i in this.widthsizings) {
    let ds = this.widthsizings[i];
    if (ds.index == index) {
      ds.size = this.getSizeByIndex(index);
      for (let i in this.dragsizings) {
        let ds = this.dragsizings[i];
        if (ds.index == index) {
          ds.size = 0;
          break;
        }
      }

      try {
        let jsn = JSON.stringify(this.widthsizings);
        localStorage.setItem('cstr_' + this.id, jsn);
      } catch (ex) {
        console.log(ex);
      }
      return;
    }
  }
  this.widthsizings.push(new WidthSizing(index, this.getSizeByIndex(index)));
  for (let i in this.dragsizings) {
    let ds = this.dragsizings[i];
    if (ds.index == index) {
      ds.size = 0;
      break;
    }
  }

  try {
    let jsn = JSON.stringify(this.widthsizings);
    localStorage.setItem('cstr_' + this.id, jsn);
  } catch (ex) {
    console.log(ex);
  }
};

CustomReport.prototype._onSetShowHidesByKey = function (key, isHide) {
  console.log('_onSetShowHidesByKey', key + ':' + isHide);
};

CustomReport.prototype.setShowHidesByKey = function (key, isHide) {
  this._onSetShowHidesByKey(key, isHide);
};

CustomReport.prototype._onSetShowHidesByIndex = function (index, isHide) {
  console.log('showHidesByIndex', index + ':' + isHide);
};

CustomReport.prototype.setShowHidesByIndex = function (index, isHide) {
  this._onSetShowHidesByIndex(index, isHide);
};
CustomReport.prototype._onColorHidesByIndex = function (index) {
  console.log('colorHidesByIndex', index);
  return false;
};
CustomReport.prototype.colorHidesByIndex = function (index) {
  return this._onColorHidesByIndex(index);
};
CustomReport.prototype._onSetColorHidesByIndex = function (index, isHide) {
  console.log(index, isHide);
};
CustomReport.prototype.setColorHidesByIndex = function (index, isHide) {
  this._onSetColorHidesByIndex(index, isHide);
};
CustomReport.prototype._onGetShowHideHeaderItems = function () {
  // let res = [];
  // res = this.showHides;

  return this.showHides;
};
CustomReport.prototype.getShowHideHeaderItems = function () {
  return this._onGetShowHideHeaderItems();
};
CustomReport.prototype.getShowHideItems = function (number, type = '') {
  let res = [];
  if (number || type) {
    //
  }
  return res;
};
CustomReport.prototype.resetShowHide = function (type = 0) {
  if (type == 0 || type == 1) {
    for (let i in this.showHideSegs) {
      this.showHideSegs[i].isHide = false;
    }
  }

  if (type == 0 || type == 2) {
    for (let i in this.showHides) {
      this.showHides[i].isHide = false;
    }
  }
  // this.showHides = [];
  this.updateShowHide();
};
CustomReport.prototype.resetColorHide = function () {
  for (let i in this.colorHides) {
    this.colorHides[i].isHide = false;
  }
  // this.colorHides = [];
  this.updateColorHide();
};
CustomReport.prototype.toggleShowHide = function (key) {
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    if (key == s.name) {
      for (let j in this.showHideSegs) {
        let sh = this.showHideSegs[j];
        if (sh.key == key) {
          sh.isHide = !sh.isHide;
          this.updateShowHide();
          return;
        }
      }
      this.showHideSegs.push(new ShowHide(key, true));
      this.updateShowHide();
      return;
    }
  }

  for (let i in this.showHides) {
    let sh = this.showHides[i];
    if (sh.key == key) {
      sh.isHide = !sh.isHide;
      this.updateShowHide();
      return;
    }
  }
  this.showHides.push(new ShowHide(key, true));
  this.updateShowHide();
};
CustomReport.prototype.setShowHideHide = function (key) {
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    if (key == s.name) {
      for (let j in this.showHideSegs) {
        let sh = this.showHideSegs[j];
        if (sh.key == key) {
          sh.isHide = true;
          this.updateShowHide();
          return;
        }
      }
      this.showHideSegs.push(new ShowHide(key, true));
      this.updateShowHide();
      return;
    }
  }

  for (let i in this.showHides) {
    let sh = this.showHides[i];
    if (sh.key == key) {
      sh.isHide = true;
      this.updateShowHide();
      return;
    }
  }
  this.showHides.push(new ShowHide(key, true));
  this.updateShowHide();
};
CustomReport.prototype.toggleColorHide = function (key) {
  for (let i in this.colorHides) {
    let ch = this.colorHides[i];
    if (ch.key == key) {
      ch.isHide = !ch.isHide;
      this.updateColorHide();
      return;
    }
  }
  this.colorHides.push(new ShowHide(key, true));
  this.updateColorHide();
};
CustomReport.prototype.setColorHideHide = function (key) {
  for (let i in this.colorHides) {
    let sh = this.colorHides[i];
    if (sh.key == key) {
      sh.isHide = true;
      this.updateColorHide();
      return;
    }
  }
  this.colorHides.push(new ShowHide(key, true));
  this.updateColorHide();
};
CustomReport.prototype.colorHidesByKey = function (key) {
  for (let i in this.colorHides) {
    if (this.colorHides[i].key == key) {
      return this.colorHides[i].isHide;
    }
  }
  return false;
};

CustomReport.prototype._onCreateNewRecord = function () {};
CustomReport.prototype.createNewRecord = function () {
  this._onCreateNewRecord();
};
//eslint-disable-next-line
CustomReport.prototype._onInsertRecord = function (type, item, number, isReverse = false) {
  if (type || item || number || isReverse) {
    //
  }
};
CustomReport.prototype.insertRecord = function (type, item, number, isReverse = false) {
  this._onInsertRecord(type, item, number, isReverse);
};
CustomReport.prototype._onCopyRecord = function (number) {
  if (number) {
    //
  }
};
CustomReport.prototype.copyRecord = function (number) {
  this._onCopyRecord(number);
};
CustomReport.prototype._onCopy = function () {};
CustomReport.prototype.copy = function () {
  this._onCopy();
};
CustomReport.prototype._onDownload = function () {
  let crdl = new CustomReportDownloader();
  crdl.addReport(this);
  crdl.download(this.title);
};
CustomReport.prototype.download = function () {
  this._onDownload();
};

//eslint-disable-next-line
CustomReport.prototype._onDeleteRecord = function (number) {};
CustomReport.prototype.deleteRecord = function (number) {
  this._onDeleteRecord(number);
  this.selectedIndex = -1;
  this.selectedVIndex = -1;
};
//eslint-disable-next-line
CustomReport.prototype.selectmenu = function (type, item, number) {
  this.selectedIndex = number;
};
CustomReport.prototype.cancelselectmenu = function () {
  this.selectedIndex = -1;
  this.selectedVIndex = -1;
};
CustomReport.prototype.selectIndex = function (number) {
  this.selectedIndex = number;
};
CustomReport.prototype.selectVIndex = function (number) {
  this.selectedVIndex = number;
};
CustomReport.prototype._onUpdateShowHide = function () {};
CustomReport.prototype.updateShowHide = function () {
  this._onUpdateShowHide();
};
CustomReport.prototype.updateColorHide = function () {
  this._onUpdateColorHide();
};
CustomReport.prototype.isSegment = function (key) {
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    if (key == s.name) {
      return true;
    }
  }

  return false;
};

function CustomReportType1(
  id,
  master,
  structureHolder = null,
  raw = null,
  callback = function () {},
  order,
  title = ''
) {
  CustomReport.call(this, id, master, structureHolder, raw, callback, order, title);
  this.type = 1;
  this.koukanMaster = {
    key: 'analysis_cpa_1',
    cpas: [
      {
        commodity: 'Brand',
        cpa: 1842,
      },
      {
        commodity: '食洗機',
        cpa: 4344,
      },
      {
        commodity: 'ガスコンロ',
        cpa: 4348,
      },
      {
        commodity: 'ガスオーブン',
        cpa: 0,
      },
      {
        commodity: 'IHヒーター',
        cpa: 3267,
      },
      {
        commodity: '電気オーブン',
        cpa: 0,
      },
      {
        commodity: 'レンジフード',
        cpa: 4300,
      },
      {
        commodity: 'トイレ',
        cpa: 5985,
      },
      {
        commodity: 'ディスポーザー',
        cpa: 1966,
      },
      {
        commodity: '浴室乾燥機',
        cpa: 3240,
      },
      {
        commodity: '天井エアコン',
        cpa: 6603,
      },
      {
        commodity: '給湯器',
        cpa: 5500,
      },
      {
        commodity: '蛇口',
        cpa: 1521,
      },
      {
        commodity: 'カップボード',
        cpa: 0,
      },
      {
        commodity: '洗面台',
        cpa: 0,
      },
      {
        commodity: '宅配ボックス',
        cpa: 0,
      },
      {
        commodity: '乾太くん',
        cpa: 0,
      },
      {
        commodity: '壁掛けエアコン',
        cpa: 2273,
      },
      {
        commodity: 'エコキュート',
        cpa: 3431,
      },
      {
        commodity: 'DSA',
        cpa: 4401,
      },
      {
        commodity: 'Criteo',
        cpa: 4401,
      },
      {
        commodity: 'P-MAX',
        cpa: 0,
      },
    ],
  };
}
tacklebox.extend(CustomReportType1, CustomReport);
CustomReportType1.prototype.isKoukanDekiruMode = function () {
  if ((this.title == '限界CPA付き商材別実績') || (this.title.indexOf('商材別') >= 0)) {
    if (this.master?.advertizerId == 19) {
      return true;
    }
  }

  return false;
};
CustomReportType1.prototype.countHideSeg = function () {
  let cnt = 0;
  for (let i in this.segmentRaw) {
    let sg = this.segmentRaw[i];
    for (let j in this.showHideSegs) {
      if (this.showHideSegs[j].key == sg.name) {
        if (this.showHideSegs[j].isHide) {
          cnt++;
        }
      }
    }
  }

  return cnt;
};
CustomReportType1.prototype.getHeaderCell = function () {
  let res = [];
  res.push(new CrCell('期間'));

  for (let i in this.segmentRaw) {
    let isShow = true;
    let sg = this.segmentRaw[i];
    for (let j in this.showHideSegs) {
      if (this.showHideSegs[j].key == sg.name) {
        // if isShow false
        if (this.showHideSegs[j].isHide) {
          isShow = false;
        }
      }
    }

    if (isShow) {
      const c = new CrCell(sg.name);
      c.key = sg.name;
      res.push(c);
    }
  }

  for (let i in this.customAvailableKeysRaw) {
    let cak = this.customAvailableKeysRaw[i];

    let isShow = true;
    let shs = this.getShowHideHeaderItems();
    for (let j in shs) {
      let sh = shs[j];
      if (sh.key == cak.backendKey) {
        if (sh.isHide == true) {
          isShow = false;
        }
      }
    }
    if (isShow) {
      const crc = new CrCell(cak.label);
      crc.key = cak.backendKey;
      res.push(crc);
    }
  }

  if (this.isKoukanDekiruMode()) {
    const excpa = new CrCell('限界CPA');
    excpa.key = 'exgcpa';
    res.push(excpa);
    const excpar = new CrCell('GACPA乖離');
    excpar.key = 'exgcpar';
    res.push(excpar);
  }

  return res;
};
CustomReportType1.prototype._onDeleteRecord = function (number) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let tmprows = [];
  for (let i = 0; i < this.structureHolder.structure.rows.length; i++) {
    if (i != number) {
      tmprows.push(this.structureHolder.structure.rows[i]);
    }
  }

  this.structureHolder.structure.rows = tmprows;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType1.prototype._onInsertRecord = function (type, item, number, isReverse = false) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;
  let startDateObj = new Date();
  let endDateObj = new Date();
  let startDate =
    startDateObj.getFullYear() + '-' + (startDateObj.getMonth() + 1) + '-' + startDateObj.getDate();
  let endDate =
    endDateObj.getFullYear() + '-' + (endDateObj.getMonth() + 1) + '-' + endDateObj.getDate();

  let row = {
    date: {
      dateFormat: 'range',
      startDate: startDate,
      endDate: endDate,
    },
    segmentGroups: [
      {
        segmentItems: [
          // { "type": "category", "name": "媒体", "value": ["GDN"] }
        ],
      },
    ],
  };
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    row.segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
  }
  row.segmentGroups = this.structureHolder.structure.rows[number].segmentGroups;

  let tmprows = [];
  for (let i = 0; i < this.structureHolder.structure.rows.length; i++) {
    if (isReverse == true) {
      if (i == number) {
        tmprows.push(row);
      }
    }
    tmprows.push(this.structureHolder.structure.rows[i]);
    if (isReverse == false) {
      if (i == number) {
        tmprows.push(row);
      }
    }
  }

  this.structureHolder.structure.rows = tmprows;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType1.prototype._onCopyRecord = function (number) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let row = {
    date: {
      dateFormat: 'range',
      startDate: this.structureHolder.structure.rows[number].date.startDate,
      endDate: this.structureHolder.structure.rows[number].date.endDate,
    },
    segmentGroups: [
      {
        segmentItems: [
          // { "type": "category", "name": "媒体", "value": ["GDN"] }
        ],
      },
    ],
  };
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    row.segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
  }
  row.segmentGroups = this.structureHolder.structure.rows[number].segmentGroups;

  let tmprows = [];
  for (let i = 0; i < this.structureHolder.structure.rows.length; i++) {
    tmprows.push(this.structureHolder.structure.rows[i]);
    if (i == number) {
      tmprows.push(row);
    }
  }

  this.structureHolder.structure.rows = tmprows;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType1.prototype._onCopy = function () {
  const self = this;
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = createCustomReport(
    'cp',
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    99999,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType1.prototype._onColorHidesByIndex = function (index) {
  let keyList = this.structureHolder.structure.table.map((c) => c.backendKey);
  for (let i in this.colorHides) {
    if (this.colorHides[i].key == keyList[index]) {
      if (this.colorHides[i].isHide) {
        return true;
      }
    }
  }

  return false;
};
CustomReportType1.prototype._onSetShowHidesByIndex = function (index, isHide) {
  let keyList = this.structureHolder.structure.table.map((c) => c.backendKey);
  let isHit = false;
  for (let i in this.showHides) {
    if (this.showHides[i].key == keyList[index]) {
      this.showHides[i].isHide = isHide;
      isHit = true;
      break;
    }
  }

  if (!isHit) {
    this.showHides.push(new ShowHide(keyList[index], isHide));
  }
};
CustomReportType1.prototype._onSetColorHidesByIndex = function (index, isHide) {
  let keyList = this.structureHolder.structure.table.map((c) => c.backendKey);
  let isHit = false;
  for (let i in this.colorHides) {
    if (this.colorHides[i].key == keyList[index]) {
      this.colorHides[i].isHide = isHide;
      isHit = true;
      break;
    }
  }

  if (!isHit) {
    this.colorHides.push(new ShowHide(keyList[index], isHide));
  }
};

CustomReportType1.prototype.koukandekiruTitle = function (str) {
  for (let i in this.koukanMaster.cpas) {
    if (str.indexOf(this.koukanMaster.cpas[i].commodity) >= 0) {
      return this.koukanMaster.cpas[i].commodity;
    }
  }

  return str;
};
CustomReportType1.prototype.getKoukandekiruDataByKey = function (key) {
  for (let i in this.koukanMaster.cpas) {
    if (this.koukanMaster.cpas[i].commodity == key) {
      return this.koukanMaster.cpas[i].cpa;
    }
  }

  return 0;
};
CustomReportType1.prototype.getKoukandekiruDataByKeyTargetMonth = function (key, targetMonth) {
  if (targetMonth) {
    for (let i in kmaster.cpas) {
      try {
        if (kmaster.cpas[i].period?.month) {
          let cd = new Date(kmaster.cpas[i].period?.month);
          if (targetMonth.getTime() == cd.getTime()) {
            for (let j in kmaster.cpas[i].commodities) {
              if (kmaster.cpas[i].commodities[j].commodity == key) {
                return kmaster.cpas[i].commodities[j].cpa;
              }
            }
          }
        }
      } catch(ex) {
        console.log(ex);
      }
    }
  }

  return null;
};
CustomReportType1.prototype.getKoukandekiruTotalDataTargetMonth = function (targetMonth) {
  if (targetMonth) {
    for (let i in kmaster.cpas) {
      try {
        if (kmaster.cpas[i].period?.month) {
          let cd = new Date(kmaster.cpas[i].period?.month);
          if (targetMonth.getTime() == cd.getTime()) {
            return kmaster.cpas[i].total.cpa;
          }
        }
      } catch(ex) {
        console.log(ex);
      }
    }
  }

  return null;
};
CustomReportType1.prototype.getKoukandekiruTargetByKey = function (key) {
  for (let i in this.koukanMaster.cpas) {
    if (this.koukanMaster.cpas[i].commodity == key) {
      return this.koukanMaster.cpas[i].cpa;
    }
  }

  return 0;
};

CustomReportType1.prototype._onCreateNewRecord = function () {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;
  let startDateObj = new Date();
  let endDateObj = new Date();
  let startDate =
    startDateObj.getFullYear() + '-' + (startDateObj.getMonth() + 1) + '-' + startDateObj.getDate();
  let endDate =
    endDateObj.getFullYear() + '-' + (endDateObj.getMonth() + 1) + '-' + endDateObj.getDate();

  let row = {
    date: {
      dateFormat: 'range',
      startDate: startDate,
      endDate: endDate,
    },
    segmentGroups: [
      {
        segmentItems: [
          // { "type": "category", "name": "媒体", "value": ["GDN"] }
        ],
      },
    ],
  };
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    row.segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
  }

  this.structureHolder.structure.rows.push(row);

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType1.prototype.getRecords = function () {
  let records = [];

  try {
    for (let l in this.raw) {
      let res = [];
      let r = this.raw[l];
      res.push(new CrCell(r.date));
  
      let commodity = '';
      for (let i = 0; i < this.segmentRaw.length; i++) {
        let tag = this.segmentRaw[i].name;
        let name = '';
        let excells = [];
  
        let isShow = true;
        for (let j in this.showHideSegs) {
          if (this.segmentRaw[i].name == this.showHideSegs[j].key) {
            //
            if (this.showHideSegs[j].isHide) {
              isShow = false;
            }
          }
        }
  
        if (isShow) {
          for (let j = 0; j < r.segmentgroupsList.length; j++) {
            for (let x in r.segmentgroupsList[j]) {
              let isHit = false;
              for (let y in r.segmentgroupsList[j][x]) {
                if (r.segmentgroupsList[j][x][y].name == tag) {
                  for (let ii in r.segmentgroupsList[j][x][y].valueList) {
                    if (name.length > 0) {
                      name = name + '\n';
                    }
                    name = name + r.segmentgroupsList[j][x][y].valueList[ii];
                    excells.push(new Cell(r.segmentgroupsList[j][x][y].valueList[ii]));
                    isHit = true;
                  }
                }
              }
              if (!isHit) {
                excells.push(new Cell('-'));
              }
            }
          }
          let c = new CrCell(name);
          if (this.isKoukanDekiruMode()) {
            if (tag == '広告グループ') {
              c.name = name;
              commodity = this.koukandekiruTitle(name);
              excells = [new Cell(commodity)];
            } else {
              //excells = [new Cell(name)];
            }
          }
          c.cells = excells;
          res.push(c);
        }
      }
  
      for (let i in r.tableList) {
        let c = r.tableList[i];
        let t = this.structureHolder.getTypeByBackendKey(c.key);
  
        let isShow = true;
        let shs = this.getShowHideHeaderItems();
        for (let i in shs) {
          let sh = shs[i];
          if (sh.key == c.key) {
            if (sh.isHide == true) {
              isShow = false;
            }
          }
        }
        if (isShow) {
          let oc = new CrCell(c.value, t);
          oc.key = c.key;
          res.push(oc);
        }
      }

      if (this.isKoukanDekiruMode()) {
        let targetMonth = null;
        try {
          if (r.date) {
            const ft = r.date.split("~");
            if (ft.length > 1) {
              let fymds = ft[0].split("-");
              if (fymds.length > 2) {
                targetMonth = new Date(fymds[0]+"/"+fymds[1]);
              }
              let tymds = ft[1].split("-");
              let targetFtTMonth = null;
              if (tymds.length > 2) {
                targetFtTMonth = new Date(tymds[0]+"/"+tymds[1]);
              }
              if (targetMonth.getTime() == targetFtTMonth.getTime()) {
                // console.log("same");
              }else{
                targetMonth = null;
              }
            }
          }
        } catch (ex) {
          console.log(ex);
        }

        let gcpa = 0;
        if (commodity) {
          gcpa = this.getKoukandekiruDataByKeyTargetMonth(commodity, targetMonth);
        }
        try {
          if ((this.title.indexOf("カテゴリ×商材別実績") == 0) || (this.title.indexOf("商材別実績") == 0)) {
            if (l == 0) {
              gcpa = this.getKoukandekiruTotalDataTargetMonth(targetMonth);
            }
          }
        } catch (ex) {
          console.log("ex", ex);
        }

        if (gcpa) {
          const exgcpa = new CrCell(gcpa, 'currency');
          exgcpa.key = 'exgcpa';
          res.push(exgcpa);
        }else{
          const exgcpa = new CrCell("-");
          exgcpa.key = 'exgcpa';
          res.push(exgcpa);
        }

        let srcv = 0;
        if (gcpa) {
          try {
            for (let i in r.tableList) {
              let c = r.tableList[i];
              if (c.key == kmaster.key) {
                if (Number(gcpa) > 0) {
                  srcv = Math.round((Number(c.value) / (Number(gcpa))) * 100);
                }
              }
            }
          } catch (ex) {
            console.log(ex);
          }
        }

        if (gcpa) {
          const exgcpar = new CrCell(srcv, 'rate');
          exgcpar.key = 'exgcpar';
          exgcpar.isRoundMode = true;
          res.push(exgcpar);
        }else{
          const exgcpar = new CrCell("-");
          exgcpar.key = 'exgcpar';
          res.push(exgcpar);
        }
      }
  
      records.push(res);
    }
  
  } catch (ex) {
    console.log(ex);
  }
  return records;
};
CustomReportType1.prototype.createRecordFromDate = function (index, startDateEndDateStrs) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  for (let i in startDateEndDateStrs) {
    let startDate = startDateEndDateStrs[i].start;
    let endDate = startDateEndDateStrs[i].end;

    let row = {
      date: {
        dateFormat: 'range',
        startDate: startDate,
        endDate: endDate,
      },
      segmentGroups: [
        {
          segmentItems: [
            // { "type": "category", "name": "媒体", "value": ["GDN"] }
          ],
        },
      ],
    };
    for (let i in this.segmentRaw) {
      let s = this.segmentRaw[i];
      row.segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
    }

    row.segmentGroups = this.structureHolder.structure.rows[index].segmentGroups;

    this.structureHolder.structure.rows.push(row);
  }

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};

CustomReportType1.prototype.changeDate = function (index, format, startDate, endDate) {
  const self = this;
  this.structureHolder.structure.rows[index].date.dateFormat = format;
  this.structureHolder.structure.rows[index].date.startDate = startDate;
  this.structureHolder.structure.rows[index].date.endDate = endDate;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType1.prototype._onUpdateShowHide = function () {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  if (this.showHideSegs.length > 0) {
    let baseSegCnt = this.segmentRaw.length;
    let hideCnt = 0;
    for (let i in this.showHideSegs) {
      if (this.showHideSegs[i].isHide == true) {
        hideCnt++;
      }
    }
    if (hideCnt == baseSegCnt) {
      this.showHideSegs[0].isHide = false;
    }
  }

  for (let i in this.structureHolder.structure.segmentsList) {
    for (let x in this.showHideSegs) {
      let sh = this.showHideSegs[x];
      if (this.structureHolder.structure.segmentsList[i].name == sh.key) {
        try {
          this.structureHolder.structure.segmentsList[i].display = !sh.isHide;
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }

  if (this.showHides.length > 0) {
    let baseKeyCnt = this.customAvailableKeysRaw.length;
    let hideCnt = 0;
    for (let i in this.showHides) {
      if (this.showHides[i].isHide == true) {
        hideCnt++;
      }
    }
    if (hideCnt == baseKeyCnt) {
      this.showHides[0].isHide = false;
    }
  }

  for (let i in this.structureHolder.structure.table) {
    for (let x in this.showHides) {
      let sh = this.showHides[x];
      if (this.structureHolder.structure.table[i].backendKey == sh.key) {
        try {
          this.structureHolder.structure.table[i].display = !sh.isHide;
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }


  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      //
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType1.prototype._onUpdateColorHide = function () {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  for (let i in this.structureHolder.structure.table) {
    for (let x in this.colorHides) {
      let sh = this.colorHides[x];
      if (this.structureHolder.structure.table[i].backendKey == sh.key) {
        try {
          this.structureHolder.structure.table[i].color = !sh.isHide;
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }
  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      //
    },
    null,
    this.currentPageStreams
  );
};

CustomReportType1.prototype.changeSegmentGroups = function (segmentgroups, index) {
  const self = this;
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  this.structureHolder.structure.rows[index].segmentGroups = segmentgroups;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType1.prototype.getShowHideItems = function (number, type = 0) {
  let res = [];
  if (number) {
    //
  }

  if (type == 1) {
    for (let i in this.segmentRaw) {
      let sg = this.segmentRaw[i];
      res.push(new ShowHideItem(sg.name, sg.name));
    }
    return res;
  }

  for (let i in this.customAvailableKeysRaw) {
    let cak = this.customAvailableKeysRaw[i];
    res.push(new ShowHideItem(cak.backendKey, cak.label));
  }
  return res;
};
CustomReportType1.prototype._onGetShowHideHeaderItems = function () {
  // this.showHides = this.structureHolder.getShowHides();

  return this.showHides;
};

function CustomReportType2(
  id,
  master,
  structureHolder = null,
  raw = null,
  callback = function () {},
  order,
  title = ''
) {
  CustomReport.call(this, id, master, structureHolder, raw, callback, order, title);
  this.type = 2;
}
tacklebox.extend(CustomReportType2, CustomReport);
CustomReportType2.prototype.getHeaderDateCell = function () {
  let res = [];
  try {
    this.segmentRaw.forEach((x) => {
      let isShow = true;
      for (let j in this.showHideSegs) {
        if (this.showHideSegs[j].key == x.name) {
          if (this.showHideSegs[j].isHide) {
            isShow = false;
            break;
          }
        }
      }
      if (isShow) {
        let c = new CrCell('');
        c.colspan = 1;
        c.isHide = true;
        res.push(c);
      }
    });
    let loopcnt = this.structureHolder.structure.periods.length;
    for (let l = 0; l < loopcnt; l++) {
      let c = new CrCell(
        this.structureHolder.structure.periods[l].startDate +
          '～' +
          this.structureHolder.structure.periods[l].endDate
      );
      let span = 0;
      for (let i in this.customAvailableKeysRaw) {
        let cak = this.customAvailableKeysRaw[i];
        let isShow = true;
        let shs = this.getShowHideHeaderItems();
        for (let i in shs) {
          let sh = shs[i];
          if (sh.key == cak.backendKey) {
            if (sh.isHide == true) {
              isShow = false;
            }
          }
        }
        if (isShow) {
          span++;
        }
      }
      c.colspan = span;
      c.isHide = false;
      res.push(c);
    }
  } catch (ex) {
    console.log(ex);
  }

  return res;
};
CustomReportType2.prototype.getHeaderCell = function () {
  let res = [];
  for (let i in this.segmentRaw) {
    let isShow = true;
    for (let j in this.showHideSegs) {
      if (this.showHideSegs[j].key == this.segmentRaw[i].name) {
        if (this.showHideSegs[j].isHide) {
          isShow = false;
          break;
        }
      }
    }

    if (isShow) {
      let sg = this.segmentRaw[i];
      let c = new CrCell(sg.name);
      c.key = sg.name;
      res.push(c);
    }
  }
  let loopcnt = this.structureHolder.structure.periods.length;
  for (let l = 0; l < loopcnt; l++) {
    for (let i in this.customAvailableKeysRaw) {
      let cak = this.customAvailableKeysRaw[i];

      let isShow = true;

      let shs = this.getShowHideHeaderItems();
      for (let i in shs) {
        let sh = shs[i];
        if (sh.key == cak.backendKey) {
          if (sh.isHide == true) {
            isShow = false;
          }
        }
      }
      if (isShow) {
        let c = new CrCell(cak.label);
        c.key = cak.backendKey;
        res.push(c);
      }
    }
  }
  return res;
};
CustomReportType2.prototype.getPeriodsIndexByHeaderCellIndex = function (ci) {
  let offsetsh = 0;
  for (let i in this.showHideSegs) {
    if (this.showHideSegs[i].isHide == true) {
      offsetsh++;
    }
  }
  let srcnt = this.segmentRaw.length - offsetsh;
  if (ci < srcnt) {
    return -1;
  }

  let bi = ci - (this.segmentRaw.length - offsetsh);

  return bi;
};
// CustomReportType2.prototype.getTitle = function () {
//     return "パターンB";
// };
CustomReportType2.prototype.getRecords = function () {
  let records = [];

  for (let l in this.raw) {
    let res = [];
    let r = this.raw[l];
    for (let i = 0; i < this.segmentRaw.length; i++) {
      let tag = this.segmentRaw[i].name;
      let name = '';
      let excells = [];

      let isShow = true;
      for (let y in this.showHideSegs) {
        if (this.showHideSegs[y].key == tag) {
          if (this.showHideSegs[y].isHide) {
            isShow = false;
            break;
          }
        }
      }

      if (isShow) {
        for (let j = 0; j < r.segmentgroupsList.length; j++) {
          for (let x in r.segmentgroupsList[j]) {
            let isHit = false;
            for (let y in r.segmentgroupsList[j][x]) {
              if (r.segmentgroupsList[j][x][y].name == tag) {
                for (let ii in r.segmentgroupsList[j][x][y].valueList) {
                  if (name.length > 0) {
                    name = name + '\n';
                  }
                  name = name + r.segmentgroupsList[j][x][y].valueList[ii];
                  excells.push(new Cell(r.segmentgroupsList[j][x][y].valueList[ii]));
                  isHit = true;
                }
              }
            }
            if (!isHit) {
              excells.push(new Cell('-'));
            }
          }
        }
        let c = new CrCell(name);
        c.cells = excells;
        res.push(c);
      }
    }
    for (let x in r.periodsList) {
      for (let y in r.periodsList[x].tableList) {
        let c = r.periodsList[x].tableList[y];
        let t = this.structureHolder.getTypeByBackendKey(c.key);

        let isShow = true;
        let shs = this.getShowHideHeaderItems();
        for (let i in shs) {
          let sh = shs[i];
          if (sh.key == c.key) {
            if (sh.isHide == true) {
              isShow = false;
            }
          }
        }
        if (isShow) {
          let oc = new CrCell(c.value, t);
          oc.key = c.key;
          res.push(oc);
        }
      }
    }

    records.push(res);
  }
  return records;
};
CustomReportType2.prototype._onDeleteRecord = function (number) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let tmprows = [];
  for (let i = 0; i < this.structureHolder.structure.rows.length; i++) {
    if (i != number) {
      tmprows.push(this.structureHolder.structure.rows[i]);
    }
  }

  this.structureHolder.structure.rows = tmprows;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype._onInsertRecord = function (type, item, number, isReverse = false) {
  if (isReverse) {
    //
  }
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let sgs = {
    segmentGroups: [
      {
        segmentItems: [],
      },
    ],
  };
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    sgs.segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
  }
  sgs.segmentGroups = this.structureHolder.structure.rows[number].segmentGroups;

  let tmprows = [];
  for (let i = 0; i < this.structureHolder.structure.rows.length; i++) {
    tmprows.push(this.structureHolder.structure.rows[i]);
    if (i == number) {
      tmprows.push(sgs);
    }
  }

  this.structureHolder.structure.rows = tmprows;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype._onCopyRecord = function (number) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let sgs = {
    segmentGroups: [
      {
        segmentItems: [],
      },
    ],
  };
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    sgs.segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
  }
  sgs.segmentGroups = this.structureHolder.structure.rows[number].segmentGroups;

  let tmprows = [];
  for (let i = 0; i < this.structureHolder.structure.rows.length; i++) {
    tmprows.push(this.structureHolder.structure.rows[i]);
    if (i == number) {
      tmprows.push(sgs);
    }
  }

  this.structureHolder.structure.rows = tmprows;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype._onCopy = function () {
  const self = this;
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = createCustomReport(
    'cp',
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    99999,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype._onColorHidesByIndex = function (index) {
  let keyList = this.structureHolder.structure.table.map((c) => c.backendKey);
  for (let i in this.colorHides) {
    if (this.colorHides[i].key == keyList[index % keyList.length]) {
      if (this.colorHides[i].isHide) {
        return true;
      }
    }
  }

  return false;
};
CustomReportType2.prototype._onSetShowHidesByIndex = function (index, isHide) {
  let keyList = this.structureHolder.structure.table.map((c) => c.backendKey);
  let isHit = false;
  for (let i in this.showHides) {
    if (this.showHides[i].key == keyList[index % keyList.length]) {
      this.showHides[i].isHide = isHide;
      isHit = true;
      break;
    }
  }

  if (!isHit) {
    this.showHides.push(new ShowHide(keyList[index % keyList.length], isHide));
  }
};
CustomReportType2.prototype._onSetColorHidesByIndex = function (index, isHide) {
  let keyList = this.structureHolder.structure.table.map((c) => c.backendKey);
  let isHit = false;
  for (let i in this.colorHides) {
    if (this.colorHides[i].key == keyList[index % keyList.length]) {
      this.colorHides[i].isHide = isHide;
      isHit = true;
      break;
    }
  }

  if (!isHit) {
    this.colorHides.push(new ShowHide(keyList[index % keyList.length], isHide));
  }
};

CustomReportType2.prototype._onCreateNewRecord = function () {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let sgs = {
    segmentGroups: [
      {
        segmentItems: [],
      },
    ],
  };
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    sgs.segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
  }

  this.structureHolder.structure.rows.push(sgs);

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype.createRecordFromDate = function (index, startDateEndDateStrs) {
  const self = this;

  for (let i in startDateEndDateStrs) {
    this.structureHolder.structure.periods.push({
      dateFormat: 'range',
      startDate: startDateEndDateStrs[i].start,
      endDate: startDateEndDateStrs[i].end,
    });
  }

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype.changeDate = function (index, format, startDate, endDate) {
  const self = this;

  this.structureHolder.structure.periods[index].dateFormat = format;
  this.structureHolder.structure.periods[index].startDate = startDate;
  this.structureHolder.structure.periods[index].endDate = endDate;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype._onUpdateShowHide = function () {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  if (this.showHideSegs.length > 0) {
    let baseSegCnt = this.segmentRaw.length;
    let hideCnt = 0;
    for (let i in this.showHideSegs) {
      if (this.showHideSegs[i].isHide == true) {
        hideCnt++;
      }
    }
    if (hideCnt == baseSegCnt) {
      this.showHideSegs[0].isHide = false;
    }
  }

  for (let i in this.structureHolder.structure.segmentsList) {
    for (let x in this.showHideSegs) {
      let sh = this.showHideSegs[x];
      if (this.structureHolder.structure.segmentsList[i].name == sh.key) {
        try {
          this.structureHolder.structure.segmentsList[i].display = !sh.isHide;
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }

  if (this.showHides.length > 0) {
    let baseKeyCnt = this.customAvailableKeysRaw.length;
    let hideCnt = 0;
    for (let i in this.showHides) {
      if (this.showHides[i].isHide == true) {
        hideCnt++;
      }
    }
    if (hideCnt == baseKeyCnt) {
      this.showHides[0].isHide = false;
    }
  }

  for (let i in this.structureHolder.structure.table) {
    for (let x in this.showHides) {
      let sh = this.showHides[x];
      if (this.structureHolder.structure.table[i].backendKey == sh.key) {
        try {
          this.structureHolder.structure.table[i].display = !sh.isHide;
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      //
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype._onUpdateColorHide = function () {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  for (let i in this.structureHolder.structure.table) {
    for (let x in this.colorHides) {
      let sh = this.colorHides[x];
      if (this.structureHolder.structure.table[i].backendKey == sh.key) {
        try {
          this.structureHolder.structure.table[i].color = !sh.isHide;
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }
  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      //
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype.addDate = function () {
  const self = this;

  let todayDateObj = new Date();
  let today =
    todayDateObj.getFullYear() + '-' + (todayDateObj.getMonth() + 1) + '-' + todayDateObj.getDate();

  let ni = this.structureHolder.structure.periods.length;
  this.structureHolder.structure.periods[ni] = {};
  this.structureHolder.structure.periods[ni].dateFormat = 'range';
  this.structureHolder.structure.periods[ni].startDate = today;
  this.structureHolder.structure.periods[ni].endDate = today;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype.removeDate = function (index) {
  const self = this;

  this.structureHolder.structure.periods.splice(index, 1);

  if (this.structureHolder.structure.periods.length == 0) {
    self.callback(true);
    return;
  }

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback(true);
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype.deleteCol = function (index) {
  this.removeDate(index);
};

CustomReportType2.prototype.changeSegmentGroups = function (segmentgroups, index) {
  const self = this;
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  this.structureHolder.structure.rows[index].segmentGroups = segmentgroups;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType2.prototype.getShowHideItems = function (number, type = 0) {
  let res = [];
  if (number) {
    //
  }

  if (type == 1) {
    for (let i in this.segmentRaw) {
      let sg = this.segmentRaw[i];
      res.push(new ShowHideItem(sg.name, sg.name));
    }

    return res;
  }

  for (let i in this.customAvailableKeysRaw) {
    let cak = this.customAvailableKeysRaw[i];
    res.push(new ShowHideItem(cak.backendKey, cak.label));
  }
  return res;
};

CustomReportType2.prototype.countHideSeg = function () {
  let cnt = 0;
  for (let i in this.segmentRaw) {
    let sg = this.segmentRaw[i];
    for (let j in this.showHideSegs) {
      if (this.showHideSegs[j].key == sg.name) {
        if (this.showHideSegs[j].isHide) {
          cnt++;
        }
      }
    }
  }

  return cnt;
};

function CustomReportType3(
  id,
  master,
  structureHolder = null,
  raw = null,
  callback = function () {},
  order,
  title = ''
) {
  CustomReport.call(this, id, master, structureHolder, raw, callback, order, title);
  this.type = 3;
}
tacklebox.extend(CustomReportType3, CustomReport);
CustomReportType3.prototype.getHeaderCell = function () {
  let res = [];
  let loopcnt = this.structureHolder.structure.segments.length;
  res.push(new CrCell('期間'));
  for (let l = 0; l < loopcnt; l++) {
    for (let i in this.customAvailableKeysRaw) {
      let cak = this.customAvailableKeysRaw[i];

      let isShow = true;
      let shs = this.getShowHideHeaderItems();
      for (let i in shs) {
        let sh = shs[i];
        if (sh.key == cak.backendKey) {
          if (sh.isHide == true) {
            isShow = false;
          }
        }
      }
      if (isShow) {
        let c = new CrCell(cak.label);
        c.key = cak.backendKey;
        res.push(c);
      }
    }
  }
  return res;
};
CustomReportType3.prototype.getProviderDateCell = function () {
  let res = [];
  let loopcnt = this.structureHolder.structure.segments.length;
  let c = new CrCell('');
  c.colspan = 1;
  c.isHide = true;
  res.push(c);

  for (let l = 0; l < loopcnt; l++) {
    let name = '';
    try {
      for (let j in this.structureHolder.structure.segments[l].segmentGroups) {
        for (let i in this.structureHolder.structure.segments[l].segmentGroups[j].segmentItems) {
          if (name.length == 0) {
            //
          } else if (
            !(
              name.length > 0 &&
              this.structureHolder.structure.segments[l].segmentGroups[j].segmentItems.length >
                i + 1
            )
          ) {
            name = name + '/';
          } else {
            name = name + '　';
          }
          if (
            this.structureHolder.structure.segments[l].segmentGroups[j].segmentItems[i].value[0]
          ) {
            name =
              name +
              this.structureHolder.structure.segments[l].segmentGroups[j].segmentItems[i].value[0];
          }
        }
      }
    } catch (ex) {
      //
    }
    let c = new CrCell(name);

    let span = 0;
    for (let i in this.customAvailableKeysRaw) {
      let cak = this.customAvailableKeysRaw[i];

      let isShow = true;
      let shs = this.getShowHideHeaderItems();
      for (let i in shs) {
        let sh = shs[i];
        if (sh.key == cak.backendKey) {
          if (sh.isHide == true) {
            isShow = false;
          }
        }
      }
      if (isShow) {
        span++;
      }
    }

    c.colspan = span;
    c.isHide = false;
    res.push(c);
  }
  return res;
};
CustomReportType3.prototype.getSegmentIndexByHeaderCellIndex = function (ci) {
  let ret = 0;
  ret = ci - 1;
  if (ret < 0) {
    ret = 0;
  }
  return ret;
};
// CustomReportType3.prototype.getTitle = function () {
//     return "パターンC";
// };
CustomReportType3.prototype.getRecords = function () {
  let records = [];
  for (let l in this.raw) {
    let res = [];
    let r = this.raw[l];
    res.push(new CrCell(r.date));

    for (let si in r.segmentsList) {
      let s = r.segmentsList[si];
      for (let i in s.tableList) {
        let c = s.tableList[i];
        let t = this.structureHolder.getTypeByBackendKey(c.key);

        let isShow = true;
        let shs = this.getShowHideHeaderItems();
        for (let i in shs) {
          let sh = shs[i];
          if (sh.key == c.key) {
            if (sh.isHide == true) {
              isShow = false;
            }
          }
        }
        if (isShow) {
          let oc = new CrCell(c.value, t);
          oc.key = c.key;
          res.push(oc);
        }
      }
    }
    records.push(res);
  }
  return records;
};
CustomReportType3.prototype._onDeleteRecord = function (number) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let tmprows = [];
  for (let i = 0; i < this.structureHolder.structure.rows.length; i++) {
    if (i != number) {
      tmprows.push(this.structureHolder.structure.rows[i]);
    }
  }

  this.structureHolder.structure.rows = tmprows;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType3.prototype._onInsertRecord = function (type, item, number, isReverse = false) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;
  let startDateObj = new Date();
  let endDateObj = new Date();
  let startDate =
    startDateObj.getFullYear() + '-' + (startDateObj.getMonth() + 1) + '-' + startDateObj.getDate();
  let endDate =
    endDateObj.getFullYear() + '-' + (endDateObj.getMonth() + 1) + '-' + endDateObj.getDate();

  let row = {
    dateFormat: 'range',
    startDate: startDate,
    endDate: endDate,
  };

  let tmprows = [];
  for (let i = 0; i < this.structureHolder.structure.rows.length; i++) {
    if (isReverse == true) {
      if (i == number) {
        tmprows.push(row);
      }
    }
    tmprows.push(this.structureHolder.structure.rows[i]);
    if (isReverse == false) {
      if (i == number) {
        tmprows.push(row);
      }
    }
  }

  this.structureHolder.structure.rows = tmprows;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};

CustomReportType3.prototype._onCopyRecord = function (number) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let row = {
    dateFormat: 'range',
    startDate: this.structureHolder.structure.rows[number].startDate,
    endDate: this.structureHolder.structure.rows[number].endDate,
  };

  let tmprows = [];
  for (let i = 0; i < this.structureHolder.structure.rows.length; i++) {
    tmprows.push(this.structureHolder.structure.rows[i]);
    if (i == number) {
      tmprows.push(row);
    }
  }

  this.structureHolder.structure.rows = tmprows;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};

CustomReportType3.prototype._onCopy = function () {
  const self = this;
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = createCustomReport(
    'cp',
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    99999,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};

CustomReportType3.prototype._onColorHidesByIndex = function (index) {
  let keyList = this.structureHolder.structure.table.map((c) => c.backendKey);
  for (let i in this.colorHides) {
    if (this.colorHides[i].key == keyList[index % keyList.length]) {
      if (this.colorHides[i].isHide) {
        return true;
      }
    }
  }

  return false;
};
CustomReportType3.prototype._onSetShowHidesByIndex = function (index, isHide) {
  let keyList = this.structureHolder.structure.table.map((c) => c.backendKey);
  let isHit = false;
  for (let i in this.showHides) {
    if (this.showHides[i].key == keyList[index % keyList.length]) {
      this.showHides[i].isHide = isHide;
      isHit = true;
      break;
    }
  }

  if (!isHit) {
    this.showHides.push(new ShowHide(keyList[index % keyList.length], isHide));
  }
};
CustomReportType3.prototype._onSetColorHidesByIndex = function (index, isHide) {
  let keyList = this.structureHolder.structure.table.map((c) => c.backendKey);
  let isHit = false;
  for (let i in this.colorHides) {
    if (this.colorHides[i].key == keyList[index % keyList.length]) {
      this.colorHides[i].isHide = isHide;
      isHit = true;
      break;
    }
  }

  if (!isHit) {
    this.colorHides.push(new ShowHide(keyList[index % keyList.length], isHide));
  }
};

CustomReportType3.prototype._onCreateNewRecord = function () {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;
  let startDateObj = new Date();
  let endDateObj = new Date();
  let startDate =
    startDateObj.getFullYear() + '-' + (startDateObj.getMonth() + 1) + '-' + startDateObj.getDate();
  let endDate =
    endDateObj.getFullYear() + '-' + (endDateObj.getMonth() + 1) + '-' + endDateObj.getDate();

  let row = {
    dateFormat: 'range',
    startDate: startDate,
    endDate: endDate,
  };

  this.structureHolder.structure.rows.push(row);

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType3.prototype.createRecordFromDate = function (index, startDateEndDateStrs) {
  let self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  for (let i in startDateEndDateStrs) {
    let startDate = startDateEndDateStrs[i].start;
    let endDate = startDateEndDateStrs[i].end;

    let row = {
      dateFormat: 'range',
      startDate: startDate,
      endDate: endDate,
    };

    this.structureHolder.structure.rows.push(row);
  }

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};

CustomReportType3.prototype.changeDate = function (index, format, startDate, endDate) {
  const self = this;

  this.structureHolder.structure.rows[index].dateFormat = format;
  this.structureHolder.structure.rows[index].startDate = startDate;
  this.structureHolder.structure.rows[index].endDate = endDate;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType3.prototype._onUpdateShowHide = function () {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  if (this.showHides.length > 0) {
    let baseKeyCnt = this.customAvailableKeysRaw.length;
    let hideCnt = 0;
    for (let i in this.showHides) {
      if (this.showHides[i].isHide == true) {
        hideCnt++;
      }
    }
    if (hideCnt == baseKeyCnt) {
      this.showHides[0].isHide = false;
    }
  }

  for (let i in this.structureHolder.structure.table) {
    for (let x in this.showHides) {
      let sh = this.showHides[x];
      if (this.structureHolder.structure.table[i].backendKey == sh.key) {
        try {
          this.structureHolder.structure.table[i].display = !sh.isHide;
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      //
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType3.prototype._onUpdateColorHide = function () {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  for (let i in this.structureHolder.structure.table) {
    for (let x in this.colorHides) {
      let sh = this.colorHides[x];
      if (this.structureHolder.structure.table[i].backendKey == sh.key) {
        try {
          this.structureHolder.structure.table[i].color = !sh.isHide;
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }
  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      //
    },
    null,
    this.currentPageStreams
  );
};

CustomReportType3.prototype.changeSegmentGroups = function (segmentgroups, index) {
  const self = this;
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  this.structureHolder.structure.segments[index].segmentGroups = segmentgroups;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback();
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType3.prototype.getShowHideItems = function (number, type = 0) {
  let res = [];
  if (number || type) {
    //
  }
  
  for (let i in this.customAvailableKeysRaw) {
    let cak = this.customAvailableKeysRaw[i];
    res.push(new ShowHideItem(cak.backendKey, cak.label));
  }
  return res;
};

CustomReportType3.prototype.addProvider = function () {
  let si = this.structureHolder.structure.segments.length;
  this.structureHolder.structure.segments[si] = {
    segmentGroups: [
      {
        segmentItems: [],
      },
    ],
  };

  let base = this.structureHolder.structure.segments[si];
  for (let i in this.segmentRaw) {
    let s = this.segmentRaw[i];
    base.segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
  }
};

CustomReportType3.prototype.removeProvider = function (index) {
  const self = this;

  this.structureHolder.structure.segments.splice(index, 1);

  if (this.structureHolder.structure.segments.length == 0) {
    self.callback(true);
    return;
  }

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let cuid = updateCustomReport(
    this.id,
    this.title,
    JSON.stringify(this.structureHolder.structure),
    this.master?.pageId,
    this.order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      if (self.callback) {
        self.callback(true);
      }
    },
    null,
    this.currentPageStreams
  );
};
CustomReportType3.prototype.deleteCol = function (index) {
  this.removeProvider(index);
};
function SegmentItem(type, name, values = []) {
  this.type = type;
  this.name = name;
  this.values = values;
  this.filterv = '';
}
SegmentItem.prototype.setValue = function (value) {
  this.values = [];
  this.values.push(value);
};
SegmentItem.prototype.getFilterValues = function () {
  let vs = [];
  if (this.filterv?.length > 0) {
    for (let i in this.values) {
      let v = this.values[i];
      if (v.indexOf(this.filterv) >= 0) {
        vs.push(v);
      }
    }
  } else {
    return this.values;
  }
  return vs;
};
SegmentItem.prototype.setFilterv = function (filterv) {
  this.filterv = filterv;
};

function SegmentReq(type, name, filtervalues = []) {
  this.type = type;
  this.name = name;
  this.filtervalues = filtervalues;
}

function SegmentItemFilter(type, name, filter) {
  this.type = type;
  this.name = name;
  this.filter = filter;
}

function SegmentItemBox(master, startDate, endDate, selectedSegmentItems = [], isNoCheck = false) {
  this.master = master;
  this.startDate = startDate;
  this.endDate = endDate;
  this._selectedSegmentItems = selectedSegmentItems;
  this.activeSegmentItems = [];
  this.isloading = true;
  this.filterv = '';
  this.filters = [];
  this.isNoCheck = isNoCheck;
}
SegmentItemBox.prototype.changeDateTime = function (date) {
  let ymd = date.split('-');

  return (
    ('0000' + ymd[0]).slice(-4) + '-' + ('00' + ymd[1]).slice(-2) + '-' + ('00' + ymd[2]).slice(-2)
  );
};
SegmentItemBox.prototype.getSelectedSegmentItems = function () {
  let sis = [];
  for (let i in this.master.segmentRaw) {
    let isHit = false;
    for (let j in this._selectedSegmentItems) {
      if (
        this._selectedSegmentItems[j].type == this.master.segmentRaw[i].type &&
        this._selectedSegmentItems[j].name == this.master.segmentRaw[i].name
      ) {
        isHit = true;
        let vs = [];
        for (let x in this._selectedSegmentItems[j].values) {
          vs.push(this._selectedSegmentItems[j].values[x]);
        }
        sis.push(
          new SegmentItem(this.master.segmentRaw[i].type, this.master.segmentRaw[i].name, vs)
        );
      }
    }
    if (!isHit) {
      sis.push(new SegmentItem(this.master.segmentRaw[i].type, this.master.segmentRaw[i].name, []));
    }
  }

  return sis;
};
SegmentItemBox.prototype.isSelectedByTNV = function (type, name, value) {
  let sis = this.getSelectedSegmentItems();
  for (let i in sis) {
    let si = sis[i];
    if (si.type == type && si.name == name) {
      if (si.values.length > 0) {
        if (si.values[0] == value) {
          return true;
        }
      }
    }
  }

  return false;
};
SegmentItemBox.prototype.getSegmentItems = function () {
  let sis = [];
  for (let i in this.master.segmentRaw) {
    let isHit = false;
    for (let j in this.activeSegmentItems) {
      if (
        this.activeSegmentItems[j].type == this.master.segmentRaw[i].type &&
        this.activeSegmentItems[j].name == this.master.segmentRaw[i].name
      ) {
        isHit = true;
        let vs = [];
        for (let z in this.activeSegmentItems[j].values) {
          vs.push(this.activeSegmentItems[j].values[z]);
        }
        let si = new SegmentItem(
          this.master.segmentRaw[i].type,
          this.master.segmentRaw[i].name,
          vs
        );
        si.filterv = this.getFilter(this.master.segmentRaw[i].type, this.master.segmentRaw[i].name);
        sis.push(si);
      }
    }
    if (!isHit) {
      let si = new SegmentItem(this.master.segmentRaw[i].type, this.master.segmentRaw[i].name, []);
      si.filterv = this.getFilter(this.master.segmentRaw[i].type, this.master.segmentRaw[i].name);
      sis.push(si);
    }
  }
  return sis;
};

SegmentItemBox.prototype.refresh = function (callback = () => {}, isMarge = false) {
  if (this.isNoCheck) {
    this.refreshKoukanMode2(callback);
    return;
  }
  const self = this;
  this.isloading = true;
  //create segmentitems
  let request = {
    sortItem: '',
    sort: '',
    token: this.master.user.auth0.token,
    userUuid: this.master.user.userUuid,
  };

  if (isMarge) {
    ///
  } else {
    this.activeSegmentItems = [];
  }
  let ssis = this.getSelectedSegmentItems();

  let luchcnt = 0;

  for (let i = 0; i < ssis.length; i++) {
    let reqItems = [];
    let rssis = this.getSelectedSegmentItems();
    for (let j = 0; j < rssis.length; j++) {
      if (j < i) {
        reqItems.push(new SegmentReq(rssis[j].type, rssis[j].name, rssis[j].values));
      } else {
        reqItems.push(new SegmentReq(rssis[j].type, rssis[j].name, []));
      }
    }
    request.advertizerId = this.master.advertizerId;
    luchcnt++;
    const cuid = getSegmentValue(
      reqItems,
      this.changeDateTime(this.startDate),
      this.changeDateTime(this.endDate),
      request,
      function (uid, data) {
        if (cuid == uid) {
          luchcnt--;
          if (data) {
            if (data?.result) {
              try {
                if (data.result?.segmentList.length > 0) {
                  let vs = [];
                  if (data.result.segmentList[i].valuesList) {
                    for (let x in data.result.segmentList[i].valuesList) {
                      vs.push(data.result.segmentList[i].valuesList[x]);
                    }
                  }

                  let isHit = false;
                  if (isMarge) {
                    for (let j in self.activeSegmentItems) {
                      if (
                        self.activeSegmentItems[j].type == data.result.segmentList[i].type &&
                        self.activeSegmentItems[j].name == data.result.segmentList[i].name
                      ) {
                        self.activeSegmentItems[j].values = vs;
                        isHit = true;
                        break;
                      }
                    }
                  }

                  if (!isHit) {
                    self.activeSegmentItems.push(
                      new SegmentItem(
                        data.result.segmentList[i].type,
                        data.result.segmentList[i].name,
                        vs
                      )
                    );
                  }
                }
              } catch (ex) {
                console.log(ex);
              }
            }
          }
          if (luchcnt == 0) {
            self.isloading = false;
            callback();
          }
        }
      },
      function () {
        //
      },
      null,
      this._currentPageStreams
    );
  }
};
SegmentItemBox.prototype.selectKoukanMode = function (type, name, value) {
  if (this._selectedSegmentItems.length > 0) {
    if (this._selectedSegmentItems[0].values.length > 0) {
      if (this._selectedSegmentItems[0].values[0] == value) {
        this._selectedSegmentItems = [];
        return;
      }
    }
  }
  this._selectedSegmentItems = [];
  let s = new SegmentItem(type, name);
  s.setValue(value);
  this._selectedSegmentItems.push(s);
};
SegmentItemBox.prototype.selectKoukanMode2 = function (type, name, value) {
  const self = this;
  function _changeitem() {
    for (let i in self._selectedSegmentItems) {
      if (self._selectedSegmentItems[i].name == name && self._selectedSegmentItems[i].type == type) {
        if (self._selectedSegmentItems[i].values[0] == value) {
          self._selectedSegmentItems[i] = [];
          return;
        }else{
          self._selectedSegmentItems[i].setValue(value);
          return;
        }
      }
    }
    let s = new SegmentItem(type, name);
    s.setValue(value);
    self._selectedSegmentItems.push(s);
  }
  _changeitem();

  // this.isloading = true;
  this.refresh();

};
SegmentItemBox.prototype.refreshKoukanMode2 = function (callback = null) {
  const self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master.user.auth0.token,
    userUuid: this.master.user.userUuid,
  };

  this.activeSegmentItems = [];

  let reqItems = [];
  let rssis = this.getSelectedSegmentItems();
  for (let j = 0; j < rssis.length; j++) {
    reqItems.push(new SegmentReq(rssis[j].type, rssis[j].name, rssis[j].values));
  }
  request.advertizerId = this.master.advertizerId;
  const cuid = getSegmentValue(
    reqItems,
    this.changeDateTime(this.startDate),
    this.changeDateTime(this.endDate),
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          if (data?.result) {
            try {
              if (data.result?.segmentList.length > 0) {                
                for (let i in data.result.segmentList) {
                  let vs = [];
                  for (let x in data.result.segmentList[i].valuesList) {
                    vs.push(data.result.segmentList[i].valuesList[x]);
                  }
                  self.activeSegmentItems.push(
                    new SegmentItem(
                      data.result.segmentList[i].type,
                      data.result.segmentList[i].name,
                      vs
                    )
                  );
                }
              }
            } catch (ex) {
              console.log(ex);
            }
          }
        }
      }
    },
    function () {
      callback();
    },
    null,
    this._currentPageStreams
  );
};
SegmentItemBox.prototype.select = function (type, name, value) {
  if (this.isNoCheck) {
    this.selectKoukanMode2(type, name, value);
    return;
  }
  const self = this;
  let sis = this.getSegmentItems();
  function _isrcheck(index) {
    if (sis.length < index) {
      return false;
    }
    for (let i = index - 1; i >= 0; i--) {
      if (sis[i].values.length > 0) {
        for (let si in self._selectedSegmentItems) {
          if (self._selectedSegmentItems[si].name == sis[i].name) {
            return self._selectedSegmentItems[si].values.length > 0;
          }
        }
        break;
      }
    }
    return false;
  }

  let isHit = false;
  for (let i = 0; i < sis.length; i++) {
    if (sis[i].type == type && sis[i].name == name) {
      for (let x = 0; x < this._selectedSegmentItems.length; x++) {
        if (
          this._selectedSegmentItems[x].type == type &&
          this._selectedSegmentItems[x].name == name
        ) {
          let isDeleteMode = false;
          if (
            this._selectedSegmentItems[x].values.length > 0 &&
            this._selectedSegmentItems[x].values[0] == value
          ) {
            isDeleteMode = true;
          } else {
            let isBHit = false;
            if (x > 0 && i > 0) {
              if (sis[i - 1].values.length == 0) {
                isBHit = _isrcheck(i);
              } else {
                for (let y in this._selectedSegmentItems) {
                  if (this._selectedSegmentItems[y].name == sis[i - 1].name) {
                    if (this._selectedSegmentItems[y].values.length > 0) {
                      isBHit = true;
                      break;
                    }
                  }
                }
              }
              if (!isBHit) {
                isDeleteMode = true;
              }
            }
          }
          if (isDeleteMode) {
            this._selectedSegmentItems[x].values = [];
          } else {
            this._selectedSegmentItems[x].setValue(value);
          }
          isHit = true;
        }
      }
      if (!isHit) {
        if (this._selectedSegmentItems.length > 0) {
          if (sis[i - 1].values.length == 0) {
            let rrr = _isrcheck(i);
            if (rrr) {
              let s = new SegmentItem(type, name);
              s.setValue(value);
              this._selectedSegmentItems.push(s);
            }
          } else {
            for (let y in this._selectedSegmentItems) {
              if (this._selectedSegmentItems[y].name == sis[i - 1].name) {
                if (this._selectedSegmentItems[y].values.length > 0) {
                  let s = new SegmentItem(type, name);
                  s.setValue(value);
                  this._selectedSegmentItems.push(s);
                  break;
                }
              }
            }
          }
        } else {
          let s = new SegmentItem(type, name);
          s.setValue(value);
          this._selectedSegmentItems.push(s);
        }
      }
    } else {
      if (isHit) {
        for (let x in this._selectedSegmentItems) {
          if (
            this._selectedSegmentItems[x].type == sis[i].type &&
            this._selectedSegmentItems[x].name == sis[i].name
          ) {
            this._selectedSegmentItems[x].values = [];
          }
        }
      } else {
        // ガード
      }
    }
  }

  this.refresh(function () {}, true);
};
SegmentItemBox.prototype.setFilter = function (type, name, filter) {
  for (let i in this.filters) {
    if (this.filters[i].type == type && this.filters[i].name == name) {
      this.filters[i].filter = filter;
      return;
    }
  }
  this.filters.push(new SegmentItemFilter(type, name, filter));
};
SegmentItemBox.prototype.getFilter = function (type, name) {
  for (let i in this.filters) {
    if (this.filters[i].type == type && this.filters[i].name == name) {
      return this.filters[i].filter;
    }
  }
  return '';
};

function SegmentGroup(segmentItems = []) {
  this.segmentItems = segmentItems;
}
SegmentGroup.prototype.add = function (segmentitem) {
  for (let i in this.segmentItems) {
    if (
      this.segmentItems[i].type == segmentitem.type &&
      this.segmentItems[i].name == segmentitem.name
    ) {
      return;
    }
  }
  this.segmentItems.push(segmentitem);
};
SegmentGroup.prototype.remove = function (segmentitem) {
  for (let i = 0; i < this.segmentItems.length; i++) {
    if (
      this.segmentItems[i].type == segmentitem.type &&
      this.segmentItems[i].name == segmentitem.name
    ) {
      this.segmentItems.splice(i, 1);
      return;
    }
  }
};

function SegmentSelector(master) {
  this.master = master;
  this.startDate = '';
  this.endDate = '';
  this.segmentItemBoxes = [];
  this._currentPageStreams = [];
  this.isloading = false;
  this.isNoCheck = false;
}
SegmentSelector.prototype.load = function (segmentGroupsRaw = null) {
  if (segmentGroupsRaw) {
    //
  }
};
SegmentSelector.prototype.initialize = function (
  startDate,
  endDate,
  segmentGroupsRaw = null,
  isNoCheck = false
) {
  this.startDate = startDate;
  this.endDate = endDate;
  this.segmentItemBoxes = [];
  this.isloading = true;
  this.isNoCheck = isNoCheck;
  const self = this;
  let luchcnt = 0;

  if (segmentGroupsRaw) {
    for (let i in segmentGroupsRaw) {
      let selectedSegmentItems = [];
      for (let x in segmentGroupsRaw[i].segmentItems) {
        let item = segmentGroupsRaw[i].segmentItems[x];
        let vs = [];
        for (let z in item.value) {
          vs.push(item.value[z]);
        }
        selectedSegmentItems.push(new SegmentItem(item.type, item.name, vs));
      }
      const sib = new SegmentItemBox(
        this.master,
        startDate,
        endDate,
        selectedSegmentItems,
        isNoCheck
      );
      luchcnt++;
      sib.refresh(function () {
        luchcnt--;
        if (luchcnt == 0) {
          self.isloading = false;
        }
      });
      this.segmentItemBoxes.push(sib);
    }
  }
  if (this.segmentItemBoxes.length == 0) {
    const sib = new SegmentItemBox(this.master, startDate, endDate, [], isNoCheck);
    sib.refresh(function () {
      self.isloading = false;
    });
    this.segmentItemBoxes.push(sib);
  }
};

SegmentSelector.prototype.addSegmentItemBox = function () {
  const self = this;
  this.isloading = true;
  const sib = new SegmentItemBox(this.master, this.startDate, this.endDate, [], this.isNoCheck);
  sib.refresh(function () {
    self.isloading = false;
  });
  this.segmentItemBoxes.push(sib);
};

SegmentSelector.prototype.destroy = function () {};

function CustomPageMaster(pageId, title, advertizerId, user, customAvailableKeysRaw, segmentRaw) {
  this.pageId = pageId;
  this.title = title;
  this.advertizerId = advertizerId;
  this.user = user;
  this.customAvailableKeysRaw = customAvailableKeysRaw;
  this.segmentRaw = segmentRaw;
}
CustomPageMaster.prototype.getSegments = function () {
  let ss = [];
  if (this.segmentRaw) {
    for (let i in this.segmentRaw) {
      ss.push(this.segmentRaw[i].name);
    }
  }
  return ss;
};

function PageInfo(id, title, order, reportIds = []) {
  this.id = id;
  this.title = title;
  this.order = order;
  this.reportIds = reportIds;
}

function CustomReportPage() {
  this.currentPageStreams = [];
  this.cuuidList = [];

  this.screenMode = ScreenMode.Read;
  this.reports = [];
  this.master = null;

  this.pages = [];
  this.pageexittext = '';

  this.pageMenuId = null;
  this.pageTitleEditId = null;
  this.segmentSelector = new SegmentSelector();
}
CustomReportPage.prototype.updateload = function (id, user, callback) {
  let request = {
    sortItem: '',
    sort: '',
    token: user.auth0.token,
    userUuid: user.userUuid,
  };
  const cuuid = getInformationLatestUpdate(id, request, function (uuid, data) {
    if (cuuid == uuid) {
      try {
        let json = JSON.parse(JSON.stringify(data.result));
        if (json?.latestupdate?.date) {
          if (callback) {
            callback(json.latestupdate.date);
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    }
  });
};
CustomReportPage.prototype.delete = function (id) {
  const self = this;
  for (let i in this.reports) {
    let report = this.reports[i];
    if (report.id == id) {
      report.delete(function () {
        self.load(true);
      });
    }
  }
};
CustomReportPage.prototype.copy = function (id) {
  const self = this;
  for (let i in this.reports) {
    let report = this.reports[i];
    if (report.id == id) {
      report.copy(function () {
        self.load();
      });
    }
  }
};
CustomReportPage.prototype.download = function (id) {
  for (let i in this.reports) {
    let report = this.reports[i];
    if (report.id == id) {
      report.download();
    }
  }
};
CustomReportPage.prototype.load = function (isf = false) {
  this.currentPageStreams = [];
  this.segmentSelector.destroy();
  this.segmentSelector = new SegmentSelector(this.master);

  let request = {
    sortItem: '',
    sort: '',
    token: this.master.user.auth0.token,
    userUuid: this.master.user.userUuid,
  };

  request.advertizerId = this.master.advertizerId;
  let self = this;

  // self.reports = [];
  if (isf) {
    self.reports = [];
  }

  const c1uid = getCustomReports(
    this.master?.pageId,
    [],
    true,
    request,
    function (uid, data) {
      if (c1uid == uid) {
        let result = data?.result;
        if (result) {
          switch (result.reporttype) {
            case 1:
              for (let i in self.reports) {
                let r = self.reports[i];
                if (result.reportid == r.id) {
                  self.reports[i] = new CustomReportType1(
                    result.reportid,
                    self.master,
                    new CustomReportStructureHolder(JSON.parse(result.structure), 'all'),
                    result.reportdata1List,
                    function () {
                      self.load();
                    },
                    result.order,
                    result.title
                  );
                  return;
                }
              }
              self.reports.push(
                new CustomReportType1(
                  result.reportid,
                  self.master,
                  new CustomReportStructureHolder(JSON.parse(result.structure), 'all'),
                  result.reportdata1List,
                  function () {
                    self.load();
                  },
                  result.order,
                  result.title
                )
              );
              break;
            case 2:
              for (let i in self.reports) {
                let r = self.reports[i];
                if (result.reportid == r.id) {
                  self.reports[i] = new CustomReportType2(
                    result.reportid,
                    self.master,
                    new CustomReportStructureHolder(JSON.parse(result.structure), 'all'),
                    result.reportdata2List,
                    function () {
                      self.load();
                    },
                    result.order,
                    result.title
                  );
                  return;
                }
              }

              self.reports.push(
                new CustomReportType2(
                  result.reportid,
                  self.master,
                  new CustomReportStructureHolder(JSON.parse(result.structure), 'all'),
                  result.reportdata2List,
                  function () {
                    self.load();
                  },
                  result.order,
                  result.title
                )
              );
              break;
            case 3:
              for (let i in self.reports) {
                let r = self.reports[i];
                if (result.reportid == r.id) {
                  self.reports[i] = new CustomReportType3(
                    result.reportid,
                    self.master,
                    new CustomReportStructureHolder(JSON.parse(result.structure), 'all'),
                    result.reportdata3List,
                    function () {
                      self.load();
                    },
                    result.order,
                    result.title
                  );
                  return;
                }
              }

              self.reports.push(
                new CustomReportType3(
                  result.reportid,
                  self.master,
                  new CustomReportStructureHolder(JSON.parse(result.structure), 'all'),
                  result.reportdata3List,
                  function () {
                    self.load();
                  },
                  result.order,
                  result.title
                )
              );
              break;
          }
        }
      }
    },
    function () {
      // TBD ローディングマーク終了
    },
    '',
    this.currentPageStreams
  );
};
CustomReportPage.prototype.initialize = function (pageId, advertizerId, user) {
  this.currentPageStreams = [];

  let request = {
    sortItem: '',
    sort: '',
    token: user.auth0.token,
    userUuid: user.userUuid,
  };

  request.advertizerId = advertizerId;

  const self = this;
  let customAvailableKeysRaw = [];
  let segmentRaw = [];
  this.reports = [];
  const c2uid = getCustomAvailableKeys(
    request,
    function (uid, data) {
      if (c2uid == uid) {
        if (data?.result?.keysList) {
          customAvailableKeysRaw = data?.result?.keysList;
        }
      }
    },
    function () {
      const c3uid = getSegmentName(
        request,
        function (uid, data) {
          if (c3uid == uid) {
            if (data?.result?.segmentList) {
              segmentRaw = data?.result?.segmentList;
            }
          }
        },
        function () {
          self.pages = [];
          const c4uid = getCustomReportPages(
            [],
            request,
            function (uid, data) {
              if (c4uid == uid) {
                //
                if (data?.result?.pagesList) {
                  for (let i in data?.result?.pagesList) {
                    let p = data?.result?.pagesList[i];
                    self.pages.push(new PageInfo(p.pageid, p.title, p.order, p.reportidsList));
                  }
                }
              }
            },
            function () {
              //
              self.pages.sort(function (f, s) {
                if (f.order > s.order) {
                  return 1;
                } else if (f.order < s.order) {
                  return -1;
                } else {
                  return 0;
                }
              });

              if (user.nowRole() == 'advertizer') {
                for (let i in self.pages) {
                  let p = self.pages[i];
                  if (p.order == -1) {
                    self.master = new CustomPageMaster(
                      p.id,
                      p.title,
                      advertizerId,
                      user,
                      customAvailableKeysRaw,
                      segmentRaw
                    );
                    self.load();
                    return;
                  }
                }
                return;
              }

              for (let i in self.pages) {
                let p = self.pages[i];
                if (p.id == pageId) {
                  self.master = new CustomPageMaster(
                    pageId,
                    p.title,
                    advertizerId,
                    user,
                    customAvailableKeysRaw,
                    segmentRaw
                  );
                  self.load();
                  return;
                }
              }
              if (self.pages.length > 0) {
                self.master = new CustomPageMaster(
                  self.pages[0].id,
                  self.pages[0].title,
                  advertizerId,
                  user,
                  customAvailableKeysRaw,
                  segmentRaw
                );
                self.load();
                return;
              } else {
                self.master = new CustomPageMaster(
                  null,
                  '',
                  advertizerId,
                  user,
                  customAvailableKeysRaw,
                  segmentRaw
                );
                self.load();
                return;
              }
            },
            self.currentPageStreams
          );
        },
        null,
        self.currentPageStreams
      );
    },
    null,
    this.currentPageStreams
  );
};
CustomReportPage.prototype.cancelAllAPICall = function () {
  this.currentPageStreams.forEach((stream) => stream.cancel());
};
CustomReportPage.prototype.createReport = function () {
  this.screenMode = ScreenMode.Create0;
};

CustomReportPage.prototype.isShowCreateType = function () {
  return this.screenMode == ScreenMode.Create0;
};
CustomReportPage.prototype.selectCreateType = function (type = 0) {
  const self = this;

  if (type == 0) {
    this.screenMode = ScreenMode.Read;
    return;
  }

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let base = { reportType: 0, periods: [], rows: [], segments: [], table: [] };

  let startDateObj = new Date();
  let endDateObj = new Date();
  let startDate =
    startDateObj.getFullYear() + '-' + (startDateObj.getMonth() + 1) + '-' + startDateObj.getDate();
  let endDate =
    endDateObj.getFullYear() + '-' + (endDateObj.getMonth() + 1) + '-' + endDateObj.getDate();

  let title = '';
  switch (type) {
    case 1:
      title = '無題';
      base.reportType = 1;

      base.rows.push({
        date: {
          dateFormat: 'range',
          startDate: startDate,
          endDate: endDate,
        },
        segmentGroups: [
          {
            segmentItems: [
              // { "type": "category", "name": "媒体", "value": ["GDN"] }
            ],
          },
        ],
      });
      for (let i in this.master.segmentRaw) {
        let s = this.master.segmentRaw[i];
        base.rows[0].segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
      }

      base.segmentsList = [];
      for (let i in this.master.segmentRaw) {
        let s = this.master.segmentRaw[i];
        base.segmentsList.push({ type: s.type, name: s.name, display: true });
      }

      for (let i in this.master.customAvailableKeysRaw) {
        let r = this.master.customAvailableKeysRaw[i];
        let isDisplay = !isHideColKey2ByName(r.label);
        base.table.push({
          backendKey: r.backendkey,
          keyFormat: r.keyformat,
          label: r.label,
          type: r.type,
          colorCode: '',
          colorStyle: '',
          display: isDisplay,
          color: false,
        });
      }
      break;
    case 2:
      title = '無題';

      base.reportType = 2;
      base.rows.push({
        segmentGroups: [
          {
            segmentItems: [
              // { "type": "category", "name": "媒体", "value": ["GDN"] },
            ],
          },
        ],
      });
      for (let i in this.master.segmentRaw) {
        let s = this.master.segmentRaw[i];
        base.rows[0].segmentGroups[0].segmentItems.push({ type: s.type, name: s.name, value: [] });
      }

      base.segmentsList = [];
      for (let i in this.master.segmentRaw) {
        let s = this.master.segmentRaw[i];
        base.segmentsList.push({ type: s.type, name: s.name, display: true });
      }

      base.periods.push({
        dateFormat: 'range',
        startDate: startDate,
        endDate: endDate,
      });

      for (let i in this.master.customAvailableKeysRaw) {
        let r = this.master.customAvailableKeysRaw[i];
        let isDisplay = !isHideColKey2ByName(r.label);
        base.table.push({
          backendKey: r.backendkey,
          keyFormat: r.keyformat,
          label: r.label,
          type: r.type,
          colorCode: '',
          colorStyle: '',
          display: isDisplay,
          color: false,
        });
      }

      break;
    case 3:
      title = '無題';

      base.reportType = 3;
      base.rows.push({
        dateFormat: 'range',
        startDate: startDate,
        endDate: endDate,
      });
      base.segments.push({
        segmentGroups: [
          {
            segmentItems: [
              // { "type": "category", "name": "媒体", "value": [] },
            ],
          },
        ],
      });
      for (let i in this.master.segmentRaw) {
        let s = this.master.segmentRaw[i];
        base.segments[0].segmentGroups[0].segmentItems.push({
          type: s.type,
          name: s.name,
          value: [],
        });
      }

      base.segmentsList = [];
      for (let i in this.master.segmentRaw) {
        let s = this.master.segmentRaw[i];
        base.segmentsList.push({ type: s.type, name: s.name, display: true });
      }

      for (let i in this.master.customAvailableKeysRaw) {
        let r = this.master.customAvailableKeysRaw[i];
        let isDisplay = !isHideColKey2ByName(r.label);
        base.table.push({
          backendKey: r.backendkey,
          keyFormat: r.keyformat,
          label: r.label,
          type: r.type,
          colorCode: '',
          colorStyle: '',
          display: isDisplay,
          color: false,
        });
      }
      break;
  }

  let cuid = createCustomReport(
    title,
    JSON.stringify(base),
    this.master?.pageId,
    99999,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      self.load();
      self.reorder();
    },
    null,
    this.currentPageStreams
  );
  //TBD
  this.screenMode = ScreenMode.Read;
};
CustomReportPage.prototype.isCreate1 = function () {
  return this.screenMode == ScreenMode.Create1;
};
CustomReportPage.prototype.destroy = function () {
  this.currentPageStreams.forEach((stream) => stream.cancel());
  this.reports.forEach((r) => r.destroy());
  this.masterFactory.destroy();
};
CustomReportPage.prototype.createPage = function () {
  this.pageexittext = '';
  this.screenMode = ScreenMode.PageCreate;
};
CustomReportPage.prototype.editPage = function (item) {
  if (item) {
    //
  }
  this.pageTitleEditId = item;
  this.pageexittext = '';
  this.screenMode = ScreenMode.PageUpdate;
};
CustomReportPage.prototype.isPageEdit = function () {
  if (this.screenMode == ScreenMode.PageCreate || this.screenMode == ScreenMode.PageUpdate) {
    return true;
  }
  return false;
};
CustomReportPage.prototype.isPageCreateEdit = function () {
  if (this.screenMode == ScreenMode.PageCreate) {
    return true;
  }
  return false;
};
CustomReportPage.prototype.isPageUpdateEdit = function () {
  if (this.screenMode == ScreenMode.PageUpdate) {
    return true;
  }
  return false;
};
CustomReportPage.prototype.pageNameEditDone = function () {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  const self = this;
  if (this.screenMode == ScreenMode.PageCreate) {
    this.createPageByName(this.pageexittext);
  }
  if (this.screenMode == ScreenMode.PageUpdate) {
    //
    let order = 9999;
    for (let i in this.pages) {
      if (this.pages[i].id == this.pageTitleEditId) {
        order = this.pages[i].order;
      }
    }
    let cuid = updateCustomReportPage(
      this.pageTitleEditId,
      this.pageexittext,
      order,
      request,
      function (uid, data) {
        if (cuid == uid) {
          if (data) {
            //
          }
        }
      },
      function () {
        self.initialize(self.master.pageId, self.master.advertizerId, self.master.user);
      },
      null,
      this.currentPageStreams
    );
  }

  this.pageNameEditClose();
};

CustomReportPage.prototype.createPageByName = function (name) {
  const self = this;

  let request = {
    sortItem: '',
    sort: '',
    token: this.master?.user.auth0.token,
    userUuid: this.master?.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;

  let order = 1;
  if (this.pages.length > 0) {
    order = this.pages.length + 1;
  }

  let cuid = createCustomReportPage(
    name,
    order,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      self.initialize(self.master.pageId, self.master.advertizerId, self.master.user);
    },
    null,
    this.currentPageStreams
  );
};

CustomReportPage.prototype.pageNameEditClose = function () {
  this.screenMode = ScreenMode.Read;
  this.pageTitleEditId = null;
};

CustomReportPage.prototype.showPageMenu = function (id) {
  this.pageMenuId = id;
};
CustomReportPage.prototype.closePageMenu = function () {
  this.pageMenuId = null;
};
CustomReportPage.prototype.pageDelete = function () {
  if (this.pageMenuId) {
    //
    const self = this;
    let request = {
      sortItem: '',
      sort: '',
      token: this.master?.user.auth0.token,
      userUuid: this.master?.user.userUuid,
    };

    request.advertizerId = this.master?.advertizerId;

    let cuid = deleteCustomReportPage(
      this.pageMenuId,
      request,
      function (uid, data) {
        if (cuid == uid) {
          if (data) {
            //
          }
        }
      },
      function () {
        self.initialize(self.master.pageId, self.master.advertizerId, self.master.user);
        self.closePageMenu();
      },
      null,
      this.currentPageStreams
    );
  }
};
CustomReportPage.prototype.pageCopy = function () {
  if (this.getReportCount() == 0) {
    return;
  }
  if (this.pageMenuId) {
    //
    const self = this;
    let request = {
      sortItem: '',
      sort: '',
      token: this.master?.user.auth0.token,
      userUuid: this.master?.user.userUuid,
    };

    request.advertizerId = this.master?.advertizerId;

    let cuid = copyCustomReportPage(
      this.pageMenuId,
      request,
      function (uid, data) {
        if (cuid == uid) {
          if (data) {
            //
          }
        }
      },
      function () {
        self.initialize(self.master.pageId, self.master.advertizerId, self.master.user);
        self.closePageMenu();
      },
      null,
      this.currentPageStreams
    );
  }
};

CustomReportPage.prototype.pageNameEdit = function () {
  let title = '-';
  if (this.pageMenuId) {
    for (let i in this.pages) {
      if (this.pages[i].id == this.pageMenuId) {
        title = this.pages[i].title;
      }
    }
    this.editPage(this.pageMenuId);
  }

  return title;
};

CustomReportPage.prototype.pageDownload = function () {
  if (this.pageMenuId) {
    //
  }
};

CustomReportPage.prototype.getProviderTableCellDesign = function (
  data,
  table,
  index_col,
  targetTable = this.providerTables
) {
  let ret = {
    isColored: false,
    color: null,
    percent: null,
    design: null, // box, bar, bar_gradation
    border: null,
  };

  // if (index_col == 0) return ret; // 種別
  ret.isColored = true;
  let keyList = targetTable.structureHolder.structure.table.map((c) => c.backendKey);

  let numList = [];
  let target_col = index_col;
  if (targetTable.type == 1) {
    numList = table.map((r) => Number(r.tableList[index_col].value));
  }

  if (targetTable.type == 2) {
    let sindex = Math.floor(index_col / targetTable.customAvailableKeysRaw.length);
    target_col = index_col % targetTable.customAvailableKeysRaw.length;
    numList = table.map((r) => Number(r.periodsList[sindex].tableList[target_col].value));
  }

  if (targetTable.type == 3) {
    let sindex = Math.floor(index_col / targetTable.customAvailableKeysRaw.length);
    target_col = index_col % targetTable.customAvailableKeysRaw.length;
    numList = table.map((r) => Number(r.segmentsList[sindex].tableList[target_col].value));
  }

  if (keyList[target_col].includes('cpa')) numList = numList.filter((val) => val != 0);
  if (keyList[target_col].includes('roas')) numList = numList.filter((val) => val != 0);
  let max = numList.length != 0 ? numList.reduce((a, b) => (a > b ? a : b)) : 0;
  let min = numList.length != 0 ? numList.reduce((a, b) => (a < b ? a : b)) : 0;

  switch (getCustomReportBarValueSettings(keyList[target_col])) {
    case 'colored_imp':
      ret.color = '#9BCE77';
      break;
    case 'colored_click':
      ret.color = '#FFC140';
      break;
    case 'colored_ctr':
      ret.color = '#FFE2A7';
      break;
    case 'colored_cpc':
      ret.color = '#FF8940';
      break;
    case 'colored_cost':
      ret.color = '#FF579B';
      break;
    case 'colored_cv':
      ret.color = '#34B7FD';
      break;
    case 'colored_cvr':
      ret.color = '#A6E7FF';
      break;
    case 'colored_cpa': {
      let colScale = Array.from(colScale3);
      ret.color = colScale
        .map((col, i) => [col, min + ((max - min) * i) / (colScale.length - 1)])
        .reduce((colVal1, colVal2) =>
          Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ? colVal2 : colVal1
        )[0];
      if (data == 0) ret.color = '';
      break;
    }
    case 'colored_roas': {
      let colScale = Array.from(colScale3);
      ret.color = colScale
        .reverse()
        .map((col, i) => [col, min + ((max - min) * i) / (colScale.length - 1)])
        .reduce((colVal1, colVal2) =>
          Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ? colVal2 : colVal1
        )[0];
      if (data == 0) ret.color = '';
      break;
    }
  }

  // define percent
  let r = (data / max) * 100;
  r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
  if (r > 100) {
    r = 100;
  }
  switch (getCustomReportBarValueSettings(keyList[target_col])) {
    case 'colored_imp':
      ret.percent = r;
      break;
    case 'colored_click':
      ret.percent = r;
      break;
    case 'colored_cpc':
      ret.percent = r;
      break;
    case 'colored_ctr':
      ret.percent = r;
      break;
    case 'colored_cost':
      ret.percent = r;
      break;
    case 'colored_cvr':
      ret.percent = r;
      break;
    case 'colored_cv':
      ret.percent = r;
      break;
    case 'colored_cpa':
      ret.percent = 100;
      break;
    case 'colored_roas':
      ret.percent = 100;
      break;
  }

  switch (getCustomReportBarValueSettings(keyList[target_col])) {
    case 'colored_imp':
      ret.design = 'bar';
      break;
    case 'colored_click':
      ret.design = 'bar';
      break;
    case 'colored_cpc':
      ret.design = 'bar';
      break;
    case 'colored_ctr':
      ret.design = 'bar';
      break;
    case 'colored_cost':
      ret.design = 'bar_gradation';
      break;
    case 'colored_cv':
      ret.design = 'bar_gradation';
      break;
    case 'colored_cvr':
      ret.design = 'bar';
      break;
    case 'colored_cpa':
      ret.design = 'box';
      break;
    case 'colored_roas':
      ret.design = 'box';
      break;
  }

  return ret;
};
CustomReportPage.prototype.getProviderTableCellDesignByAll = function (
  data,
  table,
  index_col,
  targetTable = this.providerTables,
  all,
  pindex,
  index
) {
  let ret = {
    isColored: false,
    color: null,
    percent: null,
    design: null, // box, bar, bar_gradation
    border: null,
  };
  // console.log("alls", all);
  // console.log("alls", pindex, index);
  // console.log("target", all[pindex][index]);
  // console.log("targetkey", all[pindex][index].key);

  // if (index_col == 0) return ret; // 種別
  ret.isColored = true;

  let targetKey = all[pindex][index].key;

  let numList = [];
  if (targetTable.type == 1) {
    numList = all.map((r) => {
      try {
        return Number(r[index].data);
      } catch (ex) {
        return 0;
      }
    });
  }

  if (targetTable.type == 2) {
    // let sindex = Math.floor(index_col / targetTable.master.customAvailableKeysRaw.length);
    // target_col = index_col % targetTable.master.customAvailableKeysRaw.length;
    // numList = table.map(r => Number(r.periodsList[sindex].tableList[target_col].value));

    numList = all.map((r) => {
      try {
        return Number(r[index].data);
      } catch (ex) {
        return 0;
      }
    });
  }

  if (targetTable.type == 3) {
    numList = all.map((r) => {
      try {
        return Number(r[index].data);
      } catch (ex) {
        return 0;
      }
    });
  }

  if (targetKey.includes('cpa')) numList = numList.filter((val) => val != 0);
  if (targetKey.includes('roas')) numList = numList.filter((val) => val != 0);
  let max = numList.length != 0 ? numList.reduce((a, b) => (a > b ? a : b)) : 0;
  let min = numList.length != 0 ? numList.reduce((a, b) => (a < b ? a : b)) : 0;

  switch (getCustomReportBarValueSettings(targetKey)) {
    case 'colored_imp':
      ret.color = '#9BCE77';
      break;
    case 'colored_click':
      ret.color = '#FFC140';
      break;
    case 'colored_ctr':
      ret.color = '#FFE2A7';
      break;
    case 'colored_cpc':
      ret.color = '#FF8940';
      break;
    case 'colored_cost':
      ret.color = '#FF579B';
      break;
    case 'colored_cv':
      ret.color = '#34B7FD';
      break;
    case 'colored_cvr':
      ret.color = '#A6E7FF';
      break;
    case 'colored_cpa': {
      let colScale = Array.from(colScale3);
      ret.color = colScale
        .map((col, i) => [col, min + ((max - min) * i) / (colScale.length - 1)])
        .reduce((colVal1, colVal2) =>
          Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ? colVal2 : colVal1
        )[0];
      if (data == 0) ret.color = '';
      break;
    }
    case 'colored_roas': {
      let colScale = Array.from(colScale3);
      ret.color = colScale
        .reverse()
        .map((col, i) => [col, min + ((max - min) * i) / (colScale.length - 1)])
        .reduce((colVal1, colVal2) =>
          Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ? colVal2 : colVal1
        )[0];
      if (data == 0) ret.color = '';
      break;
    }
  }

  // define percent
  let r = (data / max) * 100;
  r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
  if (r > 100) {
    r = 100;
  }
  switch (getCustomReportBarValueSettings(targetKey)) {
    case 'colored_imp':
      ret.percent = r;
      break;
    case 'colored_click':
      ret.percent = r;
      break;
    case 'colored_cpc':
      ret.percent = r;
      break;
    case 'colored_ctr':
      ret.percent = r;
      break;
    case 'colored_cost':
      ret.percent = r;
      break;
    case 'colored_cvr':
      ret.percent = r;
      break;
    case 'colored_cv':
      ret.percent = r;
      break;
    case 'colored_cpa':
      ret.percent = 100;
      break;
    case 'colored_roas':
      ret.percent = 100;
      break;
  }

  switch (getCustomReportBarValueSettings(targetKey)) {
    case 'colored_imp':
      ret.design = 'bar';
      break;
    case 'colored_click':
      ret.design = 'bar';
      break;
    case 'colored_cpc':
      ret.design = 'bar';
      break;
    case 'colored_ctr':
      ret.design = 'bar';
      break;
    case 'colored_cost':
      ret.design = 'bar_gradation';
      break;
    case 'colored_cv':
      ret.design = 'bar_gradation';
      break;
    case 'colored_cvr':
      ret.design = 'bar';
      break;
    case 'colored_cpa':
      ret.design = 'box';
      break;
    case 'colored_roas':
      ret.design = 'box';
      break;
  }

  return ret;
};

CustomReportPage.prototype.getReportCount = function (pageId = null) {
  if (pageId == null) {
    pageId = this.pageMenuId;
  }
  for (let i in this.pages) {
    let p = this.pages[i];
    if (p.id == pageId) {
      return p.reportIds?.length;
    }
  }

  return 0;
};

CustomReportPage.prototype.getSegmentCount = function () {
  if (this.master?.segmentRaw) {
    return this.master?.segmentRaw?.length;
  }
  return 0;
};

CustomReportPage.prototype.pageReorder = function () {
//wani
  let request = {
    sortItem: '',
    sort: '',
    token: this.master.user.auth0.token,
    userUuid: this.master.user.userUuid,
  };

  let ps = [];
  for (let i in this.pages) {
    ps.push({
      id: this.pages[i].id,
      order: this.pages[i].order
    });
  }

  request.advertizerId = this.master?.advertizerId;
  const cuid = reorderCustomReportPages(
    ps,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      //
    },
    null,
    this._currentPageStreams
  );

};

CustomReportPage.prototype.pagePublic = function() {
  if (this.pageMenuId) {

    for (let i in this.pages) {
      if (this.pages[i].order < 0) {
        this.pages[i].order = 1;
      }
    }

    for (let i in this.pages) {
      if (this.pages[i].id == this.pageMenuId) {
        this.pages[i].order = -1;
        break;
      }
    }

    const pages = this.pages;
    pages.sort((a, b) => {
      return a.order - b.order;
    });

    let cnt = 0;
    for (let i = 0 ; i < pages.length ; i++) {
      let p = pages[i];
      if (p.order > 0) {
        cnt++;
        for (let j in this.pages) {
          if (this.pages[j].id == p.id) {
            this.pages[j].order = cnt;
            break;
          }
        }

      }
    }

    this.pageReorder();

  }
};
CustomReportPage.prototype.pagePrivate = function() {
  if (this.pageMenuId) {
    for (let i in this.pages) {
      if (this.pages[i].order < 0) {
        this.pages[i].order = 1;
      }
    }

    const pages = this.pages;
    pages.sort((a, b) => {
      return a.order - b.order;
    });

    let cnt = 0;
    for (let i = 0 ; i < pages.length ; i++) {
      let p = pages[i];
      if (p.order > 0) {
        cnt++;
        for (let j in this.pages) {
          if (this.pages[j].id == p.id) {
            this.pages[j].order = cnt;
            break;
          }
        }

      }
    }

    this.pageReorder();

  }
};
CustomReportPage.prototype.reorder = function () {
  let request = {
    sortItem: '',
    sort: '',
    token: this.master.user.auth0.token,
    userUuid: this.master.user.userUuid,
  };

  request.advertizerId = this.master?.advertizerId;
  request.reports = [];
  for (let i = 0 ; i < this.reports.length ; i++) {
    request.reports.push({
      id: this.reports[i].id,
      order: i + 1,
    });
  }
  const cuid = reorderCustomReports(
    this.master?.pageId,
    request,
    function (uid, data) {
      if (cuid == uid) {
        if (data) {
          //
        }
      }
    },
    function () {
      //
    },
    null,
    this._currentPageStreams
  );

};

function Styler() {}
Styler.prototype.borderStyles = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' },
};
Styler.prototype.headerFont = { name: 'Yu Gothic UI', color: { argb: 'FFFFFF' }, size: 11 };
Styler.prototype.headerFill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: '404040' },
  bgColor: { argb: '404040' },
};
Styler.prototype.dataFont = { name: 'Yu Gothic UI', color: { argb: '000000' }, size: 11 };
Styler.prototype.summaryFill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFF2CC' },
  bgColor: { argb: 'FFF2CC' },
};
Styler.prototype.headerAlignment = { vertical: 'middle', horizontal: 'center' };
Styler.prototype.dataAlignment = { vertical: 'middle' };
Styler.prototype.dataTextAlignment = { vertical: 'middle', wrapText: true };

function ExcelDrawer(report, worksheet, offsetX = 1, offsetY = 1) {
  this.report = report;
  this.worksheet = worksheet;
  this.offsetX = offsetX;
  this.offsetY = offsetY;
  this.TITLEPADDING = 1;
  this.styler = new Styler();
}
ExcelDrawer.prototype.countTitleRecord = function () {
  return 1;
};
ExcelDrawer.prototype._onCountHeaderRecord = function () {
  return 1;
};
ExcelDrawer.prototype.countHeaderRecord = function () {
  return this._onCountHeaderRecord();
};
ExcelDrawer.prototype._onCountBodyRecord = function () {
  return 0;
};
ExcelDrawer.prototype.countBodyRecord = function () {
  return this._onCountBodyRecord();
};
ExcelDrawer.prototype.countTotalRecord = function () {
  let count = this.countTitleRecord() + this.countHeaderRecord() + this.countBodyRecord();
  if (count > 0) {
    return count + this.TITLEPADDING + this.offsetY;
  }
  return 0;
};
ExcelDrawer.prototype._drawTitle = function (titleOffsetX, titleOffsetY) {
  this.worksheet.getRow(titleOffsetY).getCell(titleOffsetX).value = this.report.title;
};
//eslint-disable-next-line
ExcelDrawer.prototype._onDrawHeader = function (headerOffsetX, headerOffsetY) {};
//eslint-disable-next-line
ExcelDrawer.prototype._onDrawBody = function (bodyOffsetX, bodyOffsetY) {};
ExcelDrawer.prototype.draw = function () {
  this._drawTitle(this.offsetX + 0, this.offsetY + 0);
  this._onDrawHeader(this.offsetX + 0, this.offsetY + this.TITLEPADDING + this.countTitleRecord());
  this._onDrawBody(
    this.offsetX + 0,
    this.offsetY + this.TITLEPADDING + this.countTitleRecord() + this.countHeaderRecord()
  );
};
ExcelDrawer.prototype.fmt = function (datatype) {
  // targetcell.numFmt = '"¥"#,##0';
  // targetcell.numFmt = '0.00%';
  // targetcell.numFmt = '#,##0';

  switch (datatype) {
    case 'rate':
      return '0.00%';
    case 'currency':
      return '"¥"#,##0';
    case 'number':
      return '#,##0';
    case 'count':
      return '#,##0';
    case 'rate_roas':
      return '0.00%';
  }

  return null;
};
ExcelDrawer.prototype.ymdformat = function (ymd) {
  let ymdstr = ymd;
  ymdstr = ymdstr.replaceAll('-', '/');

  try {
    let ys = ymdstr.split('~');
    if (ys.length > 1) {
      if (ys[0] == ys[1]) {
        return ys[0];
      }

      let ymd1 = ys[0].split('/');
      let ymd2 = ys[1].split('/');
      if (ymd1.length == 3 && ymd2.length == 3) {
        if (ymd1[0] == ymd2[0]) {
          return ymd1[0] + '/' + ymd1[1] + '/' + ymd1[2] + ' ～ ' + ymd2[1] + '/' + ymd2[2];
        }
      }
    }

    let y2s = ymdstr.split('～');
    if (y2s.length > 1) {
      if (y2s[0] == y2s[1]) {
        return y2s[0];
      }
      let ymd21 = y2s[0].split('/');
      let ymd22 = y2s[1].split('/');
      if (ymd21.length == 3 && ymd22.length == 3) {
        if (ymd21[0] == ymd22[0]) {
          return ymd21[0] + '/' + ymd21[1] + '/' + ymd21[2] + ' ～ ' + ymd22[1] + '/' + ymd22[2];
        }
      }
    }
  } catch (ex) {
    console.log(ex);
  }

  return ymdstr;
};

function ExcelDrawerType1(report, worksheet, offsetX = 1, offsetY = 1) {
  ExcelDrawer.call(this, report, worksheet, offsetX, offsetY);
}
tacklebox.extend(ExcelDrawerType1, ExcelDrawer);
ExcelDrawerType1.prototype._onDrawHeader = function (headerOffsetX, headerOffsetY) {
  let hcs = this.report.getHeaderCell();
  for (let i = 0; i < hcs.length; i++) {
    this.worksheet.getRow(headerOffsetY).getCell(headerOffsetX + i).value = hcs[i].getString();

    this.worksheet.getRow(headerOffsetY).getCell(headerOffsetX + i).border =
      this.styler.borderStyles;
    this.worksheet.getRow(headerOffsetY).getCell(headerOffsetX + i).fill = this.styler.headerFill;
    this.worksheet.getRow(headerOffsetY).getCell(headerOffsetX + i).font = this.styler.headerFont;
    this.worksheet.getRow(headerOffsetY).getCell(headerOffsetX + i).alignment =
      this.styler.headerAlignment;
  }
};
ExcelDrawerType1.prototype._onDrawBody = function (bodyOffsetX, bodyOffsetY) {
  let rs = this.report.getRecords();
  for (let y = 0; y < rs.length; y++) {
    for (let x = 0; x < rs[y].length; x++) {
      if (rs[y][x]?.cells?.length > 0) {
        let title = '';
        for (let i = 0; i < rs[y][x]?.cells.length; i++) {
          if (i > 0) {
            title += '\r\n';
          }
          title += rs[y][x]?.cells[i].getString();
        }
        this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value = title;
      } else {
        if (rs[y][x].dataType == 'string') {
          if (x == 0) {
            this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value = this.ymdformat(
              rs[y][x].getString()
            );
          } else {
            this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value =
              rs[y][x].getString();
          }
        } else {
          let v = Number(rs[y][x].data);
          if (rs[y][x].dataType == 'rate') {
            v = v / 100;
          }
          this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value = v;
          this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).numFmt = this.fmt(
            rs[y][x].dataType
          );
        }
      }

      this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).border =
        this.styler.borderStyles;
      if (rs[y][x].dataType == 'string') {
        this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).alignment =
          this.styler.dataTextAlignment;
      } else {
        this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).alignment =
          this.styler.dataAlignment;
      }

      if (rs.length == y + 1) {
        if (rs[y][x].dataType == 'string') {
          this.worksheet.getColumn(bodyOffsetX + x).width = 23;
        } else {
          this.worksheet.getColumn(bodyOffsetX + x).width = 13;
        }

        let cc = {
          ref: 'E5:E16',
          rules: [
            {
              type: 'dataBar',
              axisPosition: 'auto',
              cfvo: [{ type: 'num', value: 0 }, { type: 'max' }],
              color: { argb: '63C384' },
            },
          ],
        };
        let c3c = {
          ref: 'I3:I33',
          rules: [
            {
              type: 'colorScale',
              cfvo: [{ type: 'min' }, { type: 'percentile', value: 50 }, { type: 'max' }],
              color: [{ argb: 'E6FAFE' }, { argb: '9DEBF3' }, { argb: '27CCDE' }],
            },
          ],
        };

        if (rs[y][x].key) {
          let ct = getCustomReportBarValueSettings(rs[y][x].key);
          let mcs = '';
          switch (ct) {
            case 'colored_imp':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = '9BCE77';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_click':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FFC140';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_ctr':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FFE2A7';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cpc':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FF8940';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cost':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FF579B';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cv':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = '34B7FD';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cvr':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'A6E7FF';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cpa': {
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              c3c.ref = mcs;
              this.worksheet.addConditionalFormatting(c3c);
              break;
            }
            case 'colored_roas': {
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              c3c.ref = mcs;
              this.worksheet.addConditionalFormatting(c3c);
              break;
            }
          }
        }
      }
    }
  }

  // this.worksheet.addConditionalFormatting(cc);
};
ExcelDrawerType1.prototype._onCountBodyRecord = function () {
  let rs = this.report.getRecords();
  if (rs) {
    return rs.length;
  }
  return 0;
};

function ExcelDrawerType2(report, worksheet, offsetX = 1, offsetY = 1) {
  ExcelDrawer.call(this, report, worksheet, offsetX, offsetY);
}
tacklebox.extend(ExcelDrawerType2, ExcelDrawer);
ExcelDrawerType2.prototype._onCountHeaderRecord = function () {
  return 2;
};
ExcelDrawerType2.prototype._onDrawHeader = function (headerOffsetX, headerOffsetY) {
  let hdatecs = this.report.getHeaderDateCell();
  let xoffset = headerOffsetX;
  for (let i = 0; i < hdatecs.length; i++) {
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).value = hdatecs[i].getString();
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).border = this.styler.borderStyles;
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).fill = this.styler.headerFill;
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).font = this.styler.headerFont;
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).alignment = this.styler.headerAlignment;
    if (hdatecs[i].colspan > 1) {
      let mcs =
        this.worksheet.getRow(headerOffsetY).getCell(xoffset).address +
        ':' +
        this.worksheet.getRow(headerOffsetY).getCell(xoffset + hdatecs[i].colspan - 1).address;
      this.worksheet.mergeCells(mcs);
    }

    xoffset += hdatecs[i].colspan;
  }

  let hcs = this.report.getHeaderCell();
  for (let i = 0; i < hcs.length; i++) {
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).value = hcs[i].getString();
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).border =
      this.styler.borderStyles;
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).fill =
      this.styler.headerFill;
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).font =
      this.styler.headerFont;
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).alignment =
      this.styler.headerAlignment;
  }
};
ExcelDrawerType2.prototype._onDrawBody = function (bodyOffsetX, bodyOffsetY) {
  let rs = this.report.getRecords();
  for (let y = 0; y < rs.length; y++) {
    for (let x = 0; x < rs[y].length; x++) {
      if (rs[y][x]?.cells?.length > 0) {
        let title = '';
        for (let i = 0; i < rs[y][x]?.cells.length; i++) {
          if (i > 0) {
            title += '\r\n';
          }
          title += rs[y][x]?.cells[i].getString();
        }
        this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value = title;
      } else {
        if (rs[y][x].dataType == 'string') {
          this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value =
            rs[y][x].getString();
        } else {
          let v = Number(rs[y][x].data);
          if (rs[y][x].dataType == 'rate') {
            v = v / 100;
          }
          this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value = v;
          this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).numFmt = this.fmt(
            rs[y][x].dataType
          );
        }
      }

      this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).border =
        this.styler.borderStyles;
      if (rs[y][x].dataType == 'string') {
        this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).alignment =
          this.styler.dataTextAlignment;
      } else {
        this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).alignment =
          this.styler.dataAlignment;
      }

      if (rs.length == y + 1) {
        if (rs[y][x].dataType == 'string') {
          this.worksheet.getColumn(bodyOffsetX + x).width = 23;
        } else {
          this.worksheet.getColumn(bodyOffsetX + x).width = 13;
        }

        let cc = {
          ref: 'E5:E16',
          rules: [
            {
              type: 'dataBar',
              axisPosition: 'auto',
              cfvo: [{ type: 'num', value: 0 }, { type: 'max' }],
              color: { argb: '63C384' },
            },
          ],
        };
        let c3c = {
          ref: 'I3:I33',
          rules: [
            {
              type: 'colorScale',
              cfvo: [{ type: 'min' }, { type: 'percentile', value: 50 }, { type: 'max' }],
              color: [{ argb: 'E6FAFE' }, { argb: '9DEBF3' }, { argb: '27CCDE' }],
            },
          ],
        };

        if (rs[y][x].key) {
          let ct = getCustomReportBarValueSettings(rs[y][x].key);
          let mcs = '';
          switch (ct) {
            case 'colored_imp':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = '9BCE77';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_click':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FFC140';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_ctr':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FFE2A7';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cpc':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FF8940';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cost':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FF579B';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cv':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = '34B7FD';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cvr':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'A6E7FF';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cpa': {
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              c3c.ref = mcs;
              this.worksheet.addConditionalFormatting(c3c);
              break;
            }
            case 'colored_roas': {
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              c3c.ref = mcs;
              this.worksheet.addConditionalFormatting(c3c);
              break;
            }
          }
        }
      }
    }
  }
};
ExcelDrawerType2.prototype._onCountBodyRecord = function () {
  let rs = this.report.getRecords();
  if (rs) {
    return rs.length;
  }
  return 0;
};

function ExcelDrawerType3(report, worksheet, offsetX = 1, offsetY = 1) {
  ExcelDrawer.call(this, report, worksheet, offsetX, offsetY);
}
tacklebox.extend(ExcelDrawerType3, ExcelDrawer);
ExcelDrawerType3.prototype._onCountHeaderRecord = function () {
  return 2;
};
ExcelDrawerType3.prototype._onDrawHeader = function (headerOffsetX, headerOffsetY) {
  let hprovcs = this.report.getProviderDateCell();
  let xoffset = headerOffsetX;
  for (let i = 0; i < hprovcs.length; i++) {
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).value = hprovcs[i].getString();
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).border = this.styler.borderStyles;
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).fill = this.styler.headerFill;
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).font = this.styler.headerFont;
    this.worksheet.getRow(headerOffsetY).getCell(xoffset).alignment = this.styler.headerAlignment;
    if (hprovcs[i].colspan > 1) {
      let mcs =
        this.worksheet.getRow(headerOffsetY).getCell(xoffset).address +
        ':' +
        this.worksheet.getRow(headerOffsetY).getCell(xoffset + hprovcs[i].colspan - 1).address;
      this.worksheet.mergeCells(mcs);
    }

    xoffset += hprovcs[i].colspan;
  }

  let hcs = this.report.getHeaderCell();
  for (let i = 0; i < hcs.length; i++) {
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).value = hcs[i].getString();
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).border =
      this.styler.borderStyles;
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).fill =
      this.styler.headerFill;
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).font =
      this.styler.headerFont;
    this.worksheet.getRow(headerOffsetY + 1).getCell(headerOffsetX + i).alignment =
      this.styler.headerAlignment;
  }
};
ExcelDrawerType3.prototype._onDrawBody = function (bodyOffsetX, bodyOffsetY) {
  let rs = this.report.getRecords();
  for (let y = 0; y < rs.length; y++) {
    for (let x = 0; x < rs[y].length; x++) {
      if (rs[y][x]?.cells?.length > 0) {
        let title = '';
        for (let i in rs[y][x]?.cells) {
          title += rs[y][x]?.cells[i].getString() + '\r\n';
        }
        this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value = title;
      } else {
        if (rs[y][x].dataType == 'string') {
          if (x == 0) {
            this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value = this.ymdformat(
              rs[y][x].getString()
            );
          } else {
            this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value =
              rs[y][x].getString();
          }
        } else {
          let v = Number(rs[y][x].data);
          if (rs[y][x].dataType == 'rate') {
            v = v / 100;
          }
          this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).value = v;
          this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).numFmt = this.fmt(
            rs[y][x].dataType
          );
        }
      }

      this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).border =
        this.styler.borderStyles;

      this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).alignment =
        this.styler.dataAlignment;

      if (rs.length == y + 1) {
        if (rs[y][x].dataType == 'string') {
          this.worksheet.getColumn(bodyOffsetX + x).width = 23;
        } else {
          this.worksheet.getColumn(bodyOffsetX + x).width = 13;
        }

        let cc = {
          ref: 'E5:E16',
          rules: [
            {
              type: 'dataBar',
              axisPosition: 'auto',
              cfvo: [{ type: 'num', value: 0 }, { type: 'max' }],
              color: { argb: '63C384' },
            },
          ],
        };
        let c3c = {
          ref: 'I3:I33',
          rules: [
            {
              type: 'colorScale',
              cfvo: [{ type: 'min' }, { type: 'percentile', value: 50 }, { type: 'max' }],
              color: [{ argb: 'E6FAFE' }, { argb: '9DEBF3' }, { argb: '27CCDE' }],
            },
          ],
        };

        if (rs[y][x].key) {
          let ct = getCustomReportBarValueSettings(rs[y][x].key);
          let mcs = '';
          switch (ct) {
            case 'colored_imp':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = '9BCE77';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_click':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FFC140';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_ctr':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FFE2A7';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cpc':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FF8940';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cost':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'FF579B';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cv':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = '34B7FD';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cvr':
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              cc.ref = mcs;
              cc.rules[0].color.argb = 'A6E7FF';
              this.worksheet.addConditionalFormatting(cc);
              break;
            case 'colored_cpa': {
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              c3c.ref = mcs;
              this.worksheet.addConditionalFormatting(c3c);
              break;
            }
            case 'colored_roas': {
              mcs =
                this.worksheet.getRow(bodyOffsetY).getCell(bodyOffsetX + x).address +
                ':' +
                this.worksheet.getRow(bodyOffsetY + y).getCell(bodyOffsetX + x).address;

              c3c.ref = mcs;
              this.worksheet.addConditionalFormatting(c3c);
              break;
            }
          }
        }
      }
    }
  }
};
ExcelDrawerType3.prototype._onCountBodyRecord = function () {
  let rs = this.report.getRecords();
  if (rs) {
    return rs.length;
  }
  return 0;
};

/* global ExcelJS */
function CustomReportDownloader() {
  this.reports = [];
}
CustomReportDownloader.prototype.addReport = function (report) {
  this.reports.push(report);
};

CustomReportDownloader.prototype.download = async function (name) {
  let workbook = new ExcelJS.Workbook();
  workbook.addWorksheet('report', { views: [{ zoomScale: 70 }] });
  let worksheet = workbook.getWorksheet('report');

  let offsetX = 2;
  let offsetY = 2;
  for (let i in this.reports) {
    let edwer = null;
    switch (this.reports[i].type) {
      case 1:
        edwer = new ExcelDrawerType1(this.reports[i], worksheet, offsetX, offsetY);
        break;
      case 2:
        edwer = new ExcelDrawerType2(this.reports[i], worksheet, offsetX, offsetY);
        break;
      case 3:
        edwer = new ExcelDrawerType3(this.reports[i], worksheet, offsetX, offsetY);
        break;
    }
    if (edwer) {
      edwer.draw();
      offsetY += edwer.countTotalRecord() + 1;
    }
  }
  const uint8Array = await workbook.xlsx.writeBuffer(); // csvの場合
  const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
  const a = document.createElement('a');
  a.href = (window.URL || window.webkitURL).createObjectURL(blob);
  a.download = name + `_カスタムレポート.xlsx`;
  a.click();
  a.remove();
};

export { CustomReportPage, ScreenMode };
