<template>
  <div class="side_menu">
    <ul class="side-nav">
      <li class="side-nav__item_wrapper">
        <router-link to="/report/summary">
          <div
            class="side-nav__item"
            v-bind:class="{
              'side-nav__item--selected': selectedId == 'summary',
            }"
          >
            <i class="circle_icon"></i>サマリー
          </div>
        </router-link>
        <router-link to="/report/adprovider">
          <div
            style="display: none;"
            class="side-nav__item"
            v-bind:class="{
              'side-nav__item--selected': selectedId == 'adprovider',
            }"
          >
            <i class="circle_icon"></i>媒体別実績
          </div>
        </router-link>

        <template v-for="(item, index) in menus" :key="index">
          <div class="side-nav__item" style="display: none;">
            <i class="circle_icon"></i>{{ item.title }}
          </div>
          <template v-for="(subitem, index) in item.menus" :key="index">
            <router-link
              :to="{
                name: 'ReportDimension',
                params: { id: subitem.id, cid: subitem.categoryId },
              }"
            >
              <li
                style="display: none;"
                class="side-nav__sub_item"
                v-bind:class="{
                  'side-nav__sub_item--active': selectId == subitem.id,
                }"
              >
                L {{ subitem.title }}
              </li>
            </router-link>
          </template>
        </template>

        <template v-for="(item, index) in keywordmenus" :key="index">
          <div class="side-nav__item" style="display: none;">
            <i class="circle_icon"></i>{{ item.title }}
          </div>
          <template v-for="(subitem, index) in item.menus" :key="index">
            <router-link
              :to="{
                name: 'ReportKeyword',
                params: { id: subitem.id, cid: subitem.categoryId },
              }"
            >
              <li
                style="display: none;"
                class="side-nav__sub_item"
                v-bind:class="{
                  'side-nav__sub_item--active': selectId == subitem.id,
                }"
              >
                L {{ subitem.title }}
              </li>
            </router-link>
          </template>
        </template>

        <router-link to="/report/keyword" v-if="false">
          <div
            style="display: none;"
            class="side-nav__item"
            v-bind:class="{
              'side-nav__item--selected': selectedId == 'keyword',
            }"
          >
            <i class="circle_icon"></i>キーワード
          </div>
        </router-link>

      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  props: {
    selectId: String,
    adx: Object,
    submenu: Object,
  },
  data() {
    return {
      selectedId: "",
      menus: [],
      keywordmenus: [],
    };
  },
  mounted() {
    let self = this;
    this.selectedId = this.selectId;

    this.adx.getScreenStructureById("report").subject.addObserver(function () {
      self.loadmenu();
    });
    this.loadmenu();
  },
  methods: {
    loadmenu() {
      let sideMenus = this.adx
        .getScreenStructureById("report")
        .getDynamicSideMenus();

      this.menus = [];
      this.menus = sideMenus;

      this.keywordmenus = [];
      this.keywordmenus = this.adx
        .getScreenStructureById("report")
        .getKeywordSideMenus();

      // for (let key in sideMenus) {
      //   let page = sideMenus[key];
      //   let isHit = false;
      //   for (let key in this.menus) {
      //     let menu = this.menus[key];
      //     if (menu.id == page.id) {
      //       isHit = true;
      //       break;
      //     }
      //   }
      //   if (!isHit) {
      //     this.menus.push(page);
      //   }
      // }
    },
  },
};
</script>
