<template>
  <div class="backlash_line">
    <div class="backlash_line__left">{{ title }}</div>
    <div class="backlash_line__center"></div>
    <div class="backlash_line__right">
      <div class="date_fromto_selector">
        <div>
          <div
            style="margin-top: 6px"
            class="date_fromto_selector__thismonth clickable"
            v-on:click="thisMonth()"
          >
            今月
          </div>
        </div>
        <img
          class="date_fromto_selector__minusmonth clickable"
          src="@/assets/icon/keyboard_arrow_left-24px.svg"
          v-on:click="timeRange.decMonth()"
        />
        <img
          class="date_fromto_selector__plusmonth clickable"
          src="@/assets/icon/keyboard_arrow_right-24px.svg"
          v-on:click="timeRange.incMonth()"
        />
        <div class="date_fromto_selector__date">
          {{ timeRange != null ? timeRange.getSelectedDateLabel() : '' }}
        </div>
        <div style="
            width: 8px;
        ">
        </div>
        <!-- カレンダーマークは無効にしているので非表示
        <div class="button clickable" 
          :style="{
          display: 'flex',
          height: '21px',
          border: '1px solid #39434E',
          borderRadius: '5px',
          marginTop: '10px'
        }"
        v-on:click="onTimeRange"
        >
          <img
            class="date_fromto_selector__range"
            src="@/assets/icon/date_range-24px.svg"
          />
          <div style="
              font: normal normal normal 7px Noto Sans JP;
              padding-right: 4px;
              padding-left: 8px;
              margin-top: 0.5px;
          ">▼</div>
        </div>
        -->
        <div style="
            width: 20px;
        ">
        </div>
      </div>
      <!-- ダウンロードマークは無効にしているので非表示
      <img class="report_download clickable" src="@/assets/icon/save_alt-24px.svg" />
      -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentHeader',
  props: {
    title: String,
    timeRange: Object,
    adx: Object,
  },
  mounted() {},
  emits: ['timerangeevent'],
  methods: {
    thisMonth() {
      let t = this.adx.getNowTime();
      this.timeRange.setStartEndYmd(t.ymd.year, t.ymd.month, t.ymd.day);
    },
    onTimeRange() {
      // MVPで使うには整備が必要なのでカレンダー操作の機能は一旦停止
      // this.$emit('timerangeevent', 'open');
    },
  },
};
</script>

<style scoped>
.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /*transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;*/
}

.btn-outline-dark {
  border: 1px solid #39434e;
}
</style>
