// source: page.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.adx.page.AdPageComponentsRequest', null, global);
goog.exportSymbol('proto.adx.page.AdPageComponentsResponse', null, global);
goog.exportSymbol('proto.adx.page.AdPageComponentsResponse.Result', null, global);
goog.exportSymbol('proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents', null, global);
goog.exportSymbol('proto.adx.page.AdPageComponentsResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.page.AdPagesListRequest', null, global);
goog.exportSymbol('proto.adx.page.AdPagesListResponse', null, global);
goog.exportSymbol('proto.adx.page.AdPagesListResponse.Result', null, global);
goog.exportSymbol('proto.adx.page.AdPagesListResponse.Result.Pages', null, global);
goog.exportSymbol('proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown', null, global);
goog.exportSymbol('proto.adx.page.AdPagesListResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.page.AdPageComponentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.page.AdPageComponentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.page.AdPageComponentsRequest.displayName = 'proto.adx.page.AdPageComponentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.page.AdPagesListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.page.AdPagesListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.page.AdPagesListRequest.displayName = 'proto.adx.page.AdPagesListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.page.AdPageComponentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.page.AdPageComponentsResponse.oneofGroups_);
};
goog.inherits(proto.adx.page.AdPageComponentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.page.AdPageComponentsResponse.displayName = 'proto.adx.page.AdPageComponentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.page.AdPageComponentsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.page.AdPageComponentsResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.page.AdPageComponentsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.page.AdPageComponentsResponse.Result.displayName = 'proto.adx.page.AdPageComponentsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.displayName = 'proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.page.AdPagesListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.page.AdPagesListResponse.oneofGroups_);
};
goog.inherits(proto.adx.page.AdPagesListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.page.AdPagesListResponse.displayName = 'proto.adx.page.AdPagesListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.page.AdPagesListResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.page.AdPagesListResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.page.AdPagesListResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.page.AdPagesListResponse.Result.displayName = 'proto.adx.page.AdPagesListResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.page.AdPagesListResponse.Result.Pages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.page.AdPagesListResponse.Result.Pages.repeatedFields_, null);
};
goog.inherits(proto.adx.page.AdPagesListResponse.Result.Pages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.page.AdPagesListResponse.Result.Pages.displayName = 'proto.adx.page.AdPagesListResponse.Result.Pages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.displayName = 'proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.page.AdPageComponentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.page.AdPageComponentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.page.AdPageComponentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPageComponentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adpageid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    component: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.page.AdPageComponentsRequest}
 */
proto.adx.page.AdPageComponentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.page.AdPageComponentsRequest;
  return proto.adx.page.AdPageComponentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.page.AdPageComponentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.page.AdPageComponentsRequest}
 */
proto.adx.page.AdPageComponentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.page.AdPageComponentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.page.AdPageComponentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.page.AdPageComponentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPageComponentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getComponent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 adPageId = 1;
 * @return {number}
 */
proto.adx.page.AdPageComponentsRequest.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.page.AdPageComponentsRequest} returns this
 */
proto.adx.page.AdPageComponentsRequest.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string component = 2;
 * @return {string}
 */
proto.adx.page.AdPageComponentsRequest.prototype.getComponent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPageComponentsRequest} returns this
 */
proto.adx.page.AdPageComponentsRequest.prototype.setComponent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.page.AdPageComponentsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.page.AdPageComponentsRequest} returns this
 */
proto.adx.page.AdPageComponentsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.page.AdPagesListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.page.AdPagesListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.page.AdPagesListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayorder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    global: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.page.AdPagesListRequest}
 */
proto.adx.page.AdPagesListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.page.AdPagesListRequest;
  return proto.adx.page.AdPagesListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.page.AdPagesListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.page.AdPagesListRequest}
 */
proto.adx.page.AdPagesListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDisplayorder(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.page.AdPagesListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.page.AdPagesListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.page.AdPagesListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayorder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGlobal();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string page = 1;
 * @return {string}
 */
proto.adx.page.AdPagesListRequest.prototype.getPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPagesListRequest} returns this
 */
proto.adx.page.AdPagesListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.page.AdPagesListRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.page.AdPagesListRequest} returns this
 */
proto.adx.page.AdPagesListRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string categoryId = 3;
 * @return {string}
 */
proto.adx.page.AdPagesListRequest.prototype.getCategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPagesListRequest} returns this
 */
proto.adx.page.AdPagesListRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 displayOrder = 4;
 * @return {number}
 */
proto.adx.page.AdPagesListRequest.prototype.getDisplayorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.page.AdPagesListRequest} returns this
 */
proto.adx.page.AdPagesListRequest.prototype.setDisplayorder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string global = 5;
 * @return {string}
 */
proto.adx.page.AdPagesListRequest.prototype.getGlobal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPagesListRequest} returns this
 */
proto.adx.page.AdPagesListRequest.prototype.setGlobal = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.page.AdPageComponentsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.page.AdPageComponentsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.page.AdPageComponentsResponse.TypeCase}
 */
proto.adx.page.AdPageComponentsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.page.AdPageComponentsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.page.AdPageComponentsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.page.AdPageComponentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.page.AdPageComponentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.page.AdPageComponentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPageComponentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.page.AdPageComponentsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.page.AdPageComponentsResponse}
 */
proto.adx.page.AdPageComponentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.page.AdPageComponentsResponse;
  return proto.adx.page.AdPageComponentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.page.AdPageComponentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.page.AdPageComponentsResponse}
 */
proto.adx.page.AdPageComponentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.page.AdPageComponentsResponse.Result;
      reader.readMessage(value,proto.adx.page.AdPageComponentsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.page.AdPageComponentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.page.AdPageComponentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.page.AdPageComponentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPageComponentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.page.AdPageComponentsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.page.AdPageComponentsResponse.Result.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.page.AdPageComponentsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.page.AdPageComponentsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.page.AdPageComponentsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPageComponentsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    adpagecomponentsList: jspb.Message.toObjectList(msg.getAdpagecomponentsList(),
    proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.page.AdPageComponentsResponse.Result}
 */
proto.adx.page.AdPageComponentsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.page.AdPageComponentsResponse.Result;
  return proto.adx.page.AdPageComponentsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.page.AdPageComponentsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.page.AdPageComponentsResponse.Result}
 */
proto.adx.page.AdPageComponentsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents;
      reader.readMessage(value,proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.deserializeBinaryFromReader);
      msg.addAdpagecomponents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.page.AdPageComponentsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.page.AdPageComponentsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.page.AdPageComponentsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPageComponentsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdpagecomponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kindoftab: jspb.Message.getFieldWithDefault(msg, 2, ""),
    budgettab: jspb.Message.getFieldWithDefault(msg, 3, ""),
    component: jspb.Message.getFieldWithDefault(msg, 4, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 5, ""),
    destination: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adpageid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents;
  return proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKindoftab(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBudgettab(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestination(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKindoftab();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBudgettab();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getComponent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDestination();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} returns this
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string kindOfTab = 2;
 * @return {string}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.getKindoftab = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} returns this
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.setKindoftab = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string budgetTab = 3;
 * @return {string}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.getBudgettab = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} returns this
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.setBudgettab = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string component = 4;
 * @return {string}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.getComponent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} returns this
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.setComponent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string structure = 5;
 * @return {string}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} returns this
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string destination = 6;
 * @return {string}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.getDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} returns this
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.setDestination = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 adPageId = 7;
 * @return {number}
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents} returns this
 */
proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated AdPageComponents adPageComponents = 1;
 * @return {!Array<!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents>}
 */
proto.adx.page.AdPageComponentsResponse.Result.prototype.getAdpagecomponentsList = function() {
  return /** @type{!Array<!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents, 1));
};


/**
 * @param {!Array<!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents>} value
 * @return {!proto.adx.page.AdPageComponentsResponse.Result} returns this
*/
proto.adx.page.AdPageComponentsResponse.Result.prototype.setAdpagecomponentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents}
 */
proto.adx.page.AdPageComponentsResponse.Result.prototype.addAdpagecomponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.adx.page.AdPageComponentsResponse.Result.AdPageComponents, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.page.AdPageComponentsResponse.Result} returns this
 */
proto.adx.page.AdPageComponentsResponse.Result.prototype.clearAdpagecomponentsList = function() {
  return this.setAdpagecomponentsList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.page.AdPageComponentsResponse.Result}
 */
proto.adx.page.AdPageComponentsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.page.AdPageComponentsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.page.AdPageComponentsResponse.Result, 1));
};


/**
 * @param {?proto.adx.page.AdPageComponentsResponse.Result|undefined} value
 * @return {!proto.adx.page.AdPageComponentsResponse} returns this
*/
proto.adx.page.AdPageComponentsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.page.AdPageComponentsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.page.AdPageComponentsResponse} returns this
 */
proto.adx.page.AdPageComponentsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.page.AdPageComponentsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.page.AdPageComponentsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPageComponentsResponse} returns this
 */
proto.adx.page.AdPageComponentsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.page.AdPageComponentsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.page.AdPageComponentsResponse} returns this
 */
proto.adx.page.AdPageComponentsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.page.AdPageComponentsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.page.AdPageComponentsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.page.AdPagesListResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.page.AdPagesListResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.page.AdPagesListResponse.TypeCase}
 */
proto.adx.page.AdPagesListResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.page.AdPagesListResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.page.AdPagesListResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.page.AdPagesListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.page.AdPagesListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.page.AdPagesListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.page.AdPagesListResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.page.AdPagesListResponse}
 */
proto.adx.page.AdPagesListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.page.AdPagesListResponse;
  return proto.adx.page.AdPagesListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.page.AdPagesListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.page.AdPagesListResponse}
 */
proto.adx.page.AdPagesListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.page.AdPagesListResponse.Result;
      reader.readMessage(value,proto.adx.page.AdPagesListResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.page.AdPagesListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.page.AdPagesListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.page.AdPagesListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.page.AdPagesListResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.page.AdPagesListResponse.Result.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.page.AdPagesListResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.page.AdPagesListResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.page.AdPagesListResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagesList: jspb.Message.toObjectList(msg.getPagesList(),
    proto.adx.page.AdPagesListResponse.Result.Pages.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.page.AdPagesListResponse.Result}
 */
proto.adx.page.AdPagesListResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.page.AdPagesListResponse.Result;
  return proto.adx.page.AdPagesListResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.page.AdPagesListResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.page.AdPagesListResponse.Result}
 */
proto.adx.page.AdPagesListResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.page.AdPagesListResponse.Result.Pages;
      reader.readMessage(value,proto.adx.page.AdPagesListResponse.Result.Pages.deserializeBinaryFromReader);
      msg.addPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.page.AdPagesListResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.page.AdPagesListResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.page.AdPagesListResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.adx.page.AdPagesListResponse.Result.Pages.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.page.AdPagesListResponse.Result.Pages.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.page.AdPagesListResponse.Result.Pages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.page.AdPagesListResponse.Result.Pages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListResponse.Result.Pages.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: jspb.Message.getFieldWithDefault(msg, 2, ""),
    breakdownList: jspb.Message.toObjectList(msg.getBreakdownList(),
    proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.page.AdPagesListResponse.Result.Pages;
  return proto.adx.page.AdPagesListResponse.Result.Pages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.page.AdPagesListResponse.Result.Pages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPage(value);
      break;
    case 3:
      var value = new proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown;
      reader.readMessage(value,proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.deserializeBinaryFromReader);
      msg.addBreakdown(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.page.AdPagesListResponse.Result.Pages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.page.AdPagesListResponse.Result.Pages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListResponse.Result.Pages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBreakdownList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryabbreviation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown;
  return proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryabbreviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryabbreviation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 pageId = 1;
 * @return {number}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.prototype.getPageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown} returns this
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.prototype.setPageid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 categoryId = 2;
 * @return {number}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown} returns this
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string categoryAbbreviation = 3;
 * @return {string}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.prototype.getCategoryabbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown} returns this
 */
proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown.prototype.setCategoryabbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 pageId = 1;
 * @return {number}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.getPageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages} returns this
 */
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.setPageid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page = 2;
 * @return {string}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.getPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages} returns this
 */
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.setPage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Breakdown breakdown = 3;
 * @return {!Array<!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown>}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.getBreakdownList = function() {
  return /** @type{!Array<!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown, 3));
};


/**
 * @param {!Array<!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown>} value
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages} returns this
*/
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.setBreakdownList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown}
 */
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.addBreakdown = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.page.AdPagesListResponse.Result.Pages.Breakdown, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages} returns this
 */
proto.adx.page.AdPagesListResponse.Result.Pages.prototype.clearBreakdownList = function() {
  return this.setBreakdownList([]);
};


/**
 * repeated Pages pages = 1;
 * @return {!Array<!proto.adx.page.AdPagesListResponse.Result.Pages>}
 */
proto.adx.page.AdPagesListResponse.Result.prototype.getPagesList = function() {
  return /** @type{!Array<!proto.adx.page.AdPagesListResponse.Result.Pages>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.page.AdPagesListResponse.Result.Pages, 1));
};


/**
 * @param {!Array<!proto.adx.page.AdPagesListResponse.Result.Pages>} value
 * @return {!proto.adx.page.AdPagesListResponse.Result} returns this
*/
proto.adx.page.AdPagesListResponse.Result.prototype.setPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.adx.page.AdPagesListResponse.Result.Pages=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.page.AdPagesListResponse.Result.Pages}
 */
proto.adx.page.AdPagesListResponse.Result.prototype.addPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.adx.page.AdPagesListResponse.Result.Pages, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.page.AdPagesListResponse.Result} returns this
 */
proto.adx.page.AdPagesListResponse.Result.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.page.AdPagesListResponse.Result}
 */
proto.adx.page.AdPagesListResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.page.AdPagesListResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.page.AdPagesListResponse.Result, 1));
};


/**
 * @param {?proto.adx.page.AdPagesListResponse.Result|undefined} value
 * @return {!proto.adx.page.AdPagesListResponse} returns this
*/
proto.adx.page.AdPagesListResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.page.AdPagesListResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.page.AdPagesListResponse} returns this
 */
proto.adx.page.AdPagesListResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.page.AdPagesListResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.page.AdPagesListResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.page.AdPagesListResponse} returns this
 */
proto.adx.page.AdPagesListResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.page.AdPagesListResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.page.AdPagesListResponse} returns this
 */
proto.adx.page.AdPagesListResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.page.AdPagesListResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.page.AdPagesListResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.page);
