/**
 * @fileoverview gRPC-Web generated client stub for adx.side_menu
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.side_menu = require('./side_menu_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.side_menu.SideMenuClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.side_menu.SideMenuPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.side_menu.AdvertizerListRequest,
 *   !proto.adx.side_menu.AdvertizerListResponse>}
 */
const methodDescriptor_SideMenu_GetAdvertizerList = new grpc.web.MethodDescriptor(
  '/adx.side_menu.SideMenu/GetAdvertizerList',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.side_menu.AdvertizerListRequest,
  proto.adx.side_menu.AdvertizerListResponse,
  /**
   * @param {!proto.adx.side_menu.AdvertizerListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.side_menu.AdvertizerListResponse.deserializeBinary
);


/**
 * @param {!proto.adx.side_menu.AdvertizerListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.side_menu.AdvertizerListResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.side_menu.SideMenuClient.prototype.getAdvertizerList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.side_menu.SideMenu/GetAdvertizerList',
      request,
      metadata || {},
      methodDescriptor_SideMenu_GetAdvertizerList);
};


/**
 * @param {!proto.adx.side_menu.AdvertizerListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.side_menu.AdvertizerListResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.side_menu.SideMenuPromiseClient.prototype.getAdvertizerList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.side_menu.SideMenu/GetAdvertizerList',
      request,
      metadata || {},
      methodDescriptor_SideMenu_GetAdvertizerList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.side_menu.SideMenuCategoryListRequest,
 *   !proto.adx.side_menu.SideMenuCategoryListResponse>}
 */
const methodDescriptor_SideMenu_GetSideMenuCategoryList = new grpc.web.MethodDescriptor(
  '/adx.side_menu.SideMenu/GetSideMenuCategoryList',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.side_menu.SideMenuCategoryListRequest,
  proto.adx.side_menu.SideMenuCategoryListResponse,
  /**
   * @param {!proto.adx.side_menu.SideMenuCategoryListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.side_menu.SideMenuCategoryListResponse.deserializeBinary
);


/**
 * @param {!proto.adx.side_menu.SideMenuCategoryListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.side_menu.SideMenuCategoryListResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.side_menu.SideMenuClient.prototype.getSideMenuCategoryList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.side_menu.SideMenu/GetSideMenuCategoryList',
      request,
      metadata || {},
      methodDescriptor_SideMenu_GetSideMenuCategoryList);
};


/**
 * @param {!proto.adx.side_menu.SideMenuCategoryListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.side_menu.SideMenuCategoryListResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.side_menu.SideMenuPromiseClient.prototype.getSideMenuCategoryList =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.side_menu.SideMenu/GetSideMenuCategoryList',
      request,
      metadata || {},
      methodDescriptor_SideMenu_GetSideMenuCategoryList);
};


module.exports = proto.adx.side_menu;

