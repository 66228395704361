import {
    CreateCustomReportRequest,
    GetCustomReportsRequest,
    UpdateCustomReportRequest,
    DeleteCustomReportRequest,
    GetCustomAvailableKeysRequest,
    CreateCustomReportPageRequest,
    GetCustomReportPagesRequest,
    UpdateCustomReportPageRequest,
    DeleteCustomReportPageRequest,
    CopyCustomReportPageRequest,
    GetSegmentNameRequest,
    GetSegmentValueRequest,
    ReorderCustomReportPagesRequest,
    ReorderCustomReportPage,
    ReorderCustomReport,
    ReorderCustomReportsRequest,
} from './protos_gen/custom_report_pb.js'
import { CustomReportClient } from './protos_gen/custom_report_grpc_web_pb.js'

import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function createCustomReport(title, structure, pageid, order, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new CreateCustomReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setTitle(title);
    request.setStructure(structure);
    request.setPageid(pageid);
    request.setOrder(order);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.createCustomReport(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getCustomReports(pageid, reportids, isWithData, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetCustomReportsRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setPageid(pageid);
    for (let i in reportids) {
        request.addReportids(reportids[i]);
    }
    request.setWithdata(isWithData);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.getCustomReports(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function updateCustomReport(reportid, title, structure, pageid, order, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new UpdateCustomReportRequest();
    request.setRequestid(uuidStr);
    request.setReportid(reportid);
    request.setAdvertizerid(dto.advertizerId);
    request.setTitle(title);
    request.setStructure(structure);
    request.setPageid(pageid);
    request.setOrder(order);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.updateCustomReport(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function deleteCustomReport(id, pageids, isFromAllPages, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new DeleteCustomReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setReportid(id);
    for (let i in pageids) {
        request.addPageids(pageids[i]);
    }
    request.setFromallpages(isFromAllPages);
    console.log(id, pageids);
    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.deleteCustomReport(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function createCustomReportPage(title, order, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new CreateCustomReportPageRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setTitle(title);
    request.setOrder(order);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.createCustomReportPage(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getCustomReportPages(ids, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetCustomReportPagesRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    for (let i in ids) {
        request.addPageids(ids[i]);
    }

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.getCustomReportPages(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function updateCustomReportPage(id, title, order, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new UpdateCustomReportPageRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setPageid(id);
    request.setTitle(title);
    request.setOrder(order);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.updateCustomReportPage(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function deleteCustomReportPage(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new DeleteCustomReportPageRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setPageid(id);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.deleteCustomReportPage(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function copyCustomReportPage(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new CopyCustomReportPageRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setPageid(id);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.copyCustomReportPage(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getCustomAvailableKeys(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetCustomAvailableKeysRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.getCustomAvailableKeys(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getSegmentName(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetSegmentNameRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.getSegmentName(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getSegmentValue(segments, startdate, enddate, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetSegmentValueRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    for (let i in segments) {
        let s = new GetSegmentValueRequest.Segment()
        s.setType(segments[i].type);
        s.setName(segments[i].name);
        s.setFiltervaluesList(segments[i].filtervalues);
        request.addSegment(s);
    }
    request.setStartdate(startdate);
    request.setEnddate(enddate);

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.getSegmentValue(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function reorderCustomReportPages(pages, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new ReorderCustomReportPagesRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    for (let i in pages) {
        let p = pages[i];
        let rp = new ReorderCustomReportPage();
        rp.setPageid(p.id);
        rp.setOrder(p.order);
        request.addPages(rp);
    }

    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.reorderCustomReportPages(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function reorderCustomReports(page_id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new ReorderCustomReportsRequest();
    request.setRequestid(uuidStr);
    request.setPageid(page_id);
    request.setAdvertizerid(dto.advertizerId);
    for (let i in dto.reports) {
        let r =  dto.reports[i];
        let rp = new ReorderCustomReport();
        rp.setReportid(r.id);
        rp.setOrder(r.order);
        request.addReports(rp);
    }
    let client = new CustomReportClient(url.grpc, null, null);
    const stream = client.reorderCustomReports(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー
    return uuidStr;
}


export {
    createCustomReport,
    getCustomReports,
    updateCustomReport,
    deleteCustomReport,
    createCustomReportPage,
    getCustomReportPages,
    updateCustomReportPage,
    deleteCustomReportPage,
    copyCustomReportPage,
    getCustomAvailableKeys,
    getSegmentName,
    getSegmentValue,
    reorderCustomReportPages,
    reorderCustomReports,
}