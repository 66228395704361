<template>
  <div
    ref="spdmark"
    tabindex="0"
    v-on:click="holder.deleteCstfSprint(id)"
    style="padding: 8px 8px; background: #fff; box-shadow: 0px 3px 20px #00000029; outline: none"
    class="deleteitem"
    v-on:blur="lostFocus()"
  >
    <div style="padding: 0px 8px" class="activemark">
      <img
        style="margin-top: 1px"
        height="12"
        src="@/assets/icon/delete_outline_black_24dp.svg"
      />delete
    </div>
  </div>
</template>

<style scoped>
.activemark:hover {
  background: rgb(200, 200, 200);
}
</style>

<script>
export default {
  name: 'SprintCstfDeleteMark',
  data() {
    return {
      name: 'SprintCstfDeleteMark',
    };
  },
  computed: {},
  mounted() {
    this.$refs.spdmark.focus();
  },
  methods: {
    lostFocus() {
      this.holder.selectCancel();
    },
  },
  components: {},
  props: {
    holder: Object,
    id: String,
  },
};
</script>
