// source: budget_control/budget_allocation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.adx.budget_allocation.AllocatedDailyBudgetRequest', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild', null, global);
goog.exportSymbol('proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_allocation.ListValue', null, global);
goog.exportSymbol('proto.adx.budget_allocation.ListValue.Budget', null, global);
goog.exportSymbol('proto.adx.budget_allocation.NestCategory', null, global);
goog.exportSymbol('proto.adx.budget_allocation.NestCategory.NestCampaign', null, global);
goog.exportSymbol('proto.adx.budget_allocation.NestCategory.NestCampaign.Budget', null, global);
goog.exportSymbol('proto.adx.budget_allocation.NestCategory.NestCampaign.Rows', null, global);
goog.exportSymbol('proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table', null, global);
goog.exportSymbol('proto.adx.budget_allocation.Response', null, global);
goog.exportSymbol('proto.adx.budget_allocation.Response.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.displayName = 'proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.displayName = 'proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_allocation.AllocatedDailyBudgetRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_allocation.AllocatedDailyBudgetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.AllocatedDailyBudgetRequest.displayName = 'proto.adx.budget_allocation.AllocatedDailyBudgetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.ListValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_allocation.ListValue.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_allocation.ListValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.ListValue.displayName = 'proto.adx.budget_allocation.ListValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.ListValue.Budget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_allocation.ListValue.Budget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.ListValue.Budget.displayName = 'proto.adx.budget_allocation.ListValue.Budget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_allocation.Response.oneofGroups_);
};
goog.inherits(proto.adx.budget_allocation.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.Response.displayName = 'proto.adx.budget_allocation.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.displayName = 'proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.displayName = 'proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.displayName = 'proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.NestCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_allocation.NestCategory.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_allocation.NestCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.NestCategory.displayName = 'proto.adx.budget_allocation.NestCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.NestCategory.NestCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_allocation.NestCategory.NestCampaign.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_allocation.NestCategory.NestCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.NestCategory.NestCampaign.displayName = 'proto.adx.budget_allocation.NestCategory.NestCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_allocation.NestCategory.NestCampaign.Budget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.displayName = 'proto.adx.budget_allocation.NestCategory.NestCampaign.Budget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_allocation.NestCategory.NestCampaign.Rows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.displayName = 'proto.adx.budget_allocation.NestCategory.NestCampaign.Rows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.displayName = 'proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.displayName = 'proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.displayName = 'proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.displayName = 'proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest;
  return proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 adPageComponentId = 4;
 * @return {number}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest;
  return proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 adPageComponentId = 4;
 * @return {number}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.AllocatedDailyBudgetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.adx.budget_allocation.ListValue.toObject, includeInstance),
    requestid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.AllocatedDailyBudgetRequest;
  return proto.adx.budget_allocation.AllocatedDailyBudgetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 2:
      var value = new proto.adx.budget_allocation.ListValue;
      reader.readMessage(value,proto.adx.budget_allocation.ListValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.AllocatedDailyBudgetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseperiodforforecastid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_allocation.ListValue.serializeBinaryToWriter
    );
  }
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string basePeriodForForecastId = 1;
 * @return {string}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} returns this
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ListValue values = 2;
 * @return {!Array<!proto.adx.budget_allocation.ListValue>}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.ListValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.ListValue, 2));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.ListValue>} value
 * @return {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} returns this
*/
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_allocation.ListValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.ListValue}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_allocation.ListValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} returns this
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string requestId = 3;
 * @return {string}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} returns this
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 advertizerId = 4;
 * @return {number}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} returns this
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string actualMonth = 5;
 * @return {string}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} returns this
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 adPageComponentId = 6;
 * @return {number}
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} returns this
 */
proto.adx.budget_allocation.AllocatedDailyBudgetRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_allocation.ListValue.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.ListValue.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.ListValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.ListValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.ListValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    budgetList: jspb.Message.toObjectList(msg.getBudgetList(),
    proto.adx.budget_allocation.ListValue.Budget.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.ListValue}
 */
proto.adx.budget_allocation.ListValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.ListValue;
  return proto.adx.budget_allocation.ListValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.ListValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.ListValue}
 */
proto.adx.budget_allocation.ListValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = new proto.adx.budget_allocation.ListValue.Budget;
      reader.readMessage(value,proto.adx.budget_allocation.ListValue.Budget.deserializeBinaryFromReader);
      msg.addBudget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.ListValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.ListValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.ListValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.ListValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBudgetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_allocation.ListValue.Budget.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.ListValue.Budget.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.ListValue.Budget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.ListValue.Budget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.ListValue.Budget.toObject = function(includeInstance, msg) {
  var f, obj = {
    dailyBudget: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actualDailyBudget: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.ListValue.Budget}
 */
proto.adx.budget_allocation.ListValue.Budget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.ListValue.Budget;
  return proto.adx.budget_allocation.ListValue.Budget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.ListValue.Budget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.ListValue.Budget}
 */
proto.adx.budget_allocation.ListValue.Budget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDailyBudget(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualDailyBudget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.ListValue.Budget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.ListValue.Budget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.ListValue.Budget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.ListValue.Budget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDailyBudget();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActualDailyBudget();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string daily_budget = 1;
 * @return {string}
 */
proto.adx.budget_allocation.ListValue.Budget.prototype.getDailyBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.ListValue.Budget} returns this
 */
proto.adx.budget_allocation.ListValue.Budget.prototype.setDailyBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string actual_daily_budget = 2;
 * @return {string}
 */
proto.adx.budget_allocation.ListValue.Budget.prototype.getActualDailyBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.ListValue.Budget} returns this
 */
proto.adx.budget_allocation.ListValue.Budget.prototype.setActualDailyBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.budget_allocation.ListValue.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.ListValue} returns this
 */
proto.adx.budget_allocation.ListValue.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Budget budget = 2;
 * @return {!Array<!proto.adx.budget_allocation.ListValue.Budget>}
 */
proto.adx.budget_allocation.ListValue.prototype.getBudgetList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.ListValue.Budget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.ListValue.Budget, 2));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.ListValue.Budget>} value
 * @return {!proto.adx.budget_allocation.ListValue} returns this
*/
proto.adx.budget_allocation.ListValue.prototype.setBudgetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_allocation.ListValue.Budget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.ListValue.Budget}
 */
proto.adx.budget_allocation.ListValue.prototype.addBudget = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_allocation.ListValue.Budget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.ListValue} returns this
 */
proto.adx.budget_allocation.ListValue.prototype.clearBudgetList = function() {
  return this.setBudgetList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_allocation.Response.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_allocation.Response.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_allocation.Response.TypeCase}
 */
proto.adx.budget_allocation.Response.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_allocation.Response.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_allocation.Response.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.Response}
 */
proto.adx.budget_allocation.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.Response;
  return proto.adx.budget_allocation.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.Response}
 */
proto.adx.budget_allocation.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.adx.budget_allocation.Response.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.Response} returns this
 */
proto.adx.budget_allocation.Response.prototype.setResult = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.adx.budget_allocation.Response.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_allocation.Response} returns this
 */
proto.adx.budget_allocation.Response.prototype.clearResult = function() {
  return jspb.Message.setOneofField(this, 1, proto.adx.budget_allocation.Response.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_allocation.Response.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_allocation.Response.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.Response} returns this
 */
proto.adx.budget_allocation.Response.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_allocation.Response.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_allocation.Response} returns this
 */
proto.adx.budget_allocation.Response.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_allocation.Response.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_allocation.Response.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.TypeCase}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse;
  return proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result;
      reader.readMessage(value,proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categorytypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    campaignallocatedbudgetinitialbuildList: jspb.Message.toObjectList(msg.getCampaignallocatedbudgetinitialbuildList(),
    proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result;
  return proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorytype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 5:
      var value = new proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild;
      reader.readMessage(value,proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.deserializeBinaryFromReader);
      msg.addCampaignallocatedbudgetinitialbuild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategorytypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getBaseperiodforforecastid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCampaignallocatedbudgetinitialbuildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.budget_allocation.NestCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild;
  return proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_allocation.NestCategory;
      reader.readMessage(value,proto.adx.budget_allocation.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_allocation.NestCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCategory nestCategory = 2;
 * @return {!Array<!proto.adx.budget_allocation.NestCategory>}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.NestCategory, 2));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.NestCategory>} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild} returns this
*/
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_allocation.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.NestCategory}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_allocation.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryType = 3;
 * @return {!Array<string>}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.getCategorytypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.setCategorytypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.addCategorytype = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.clearCategorytypeList = function() {
  return this.setCategorytypeList([]);
};


/**
 * optional string basePeriodForForecastId = 4;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated CampaignAllocatedBudgetInitialBuild campaignAllocatedBudgetInitialBuild = 5;
 * @return {!Array<!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild>}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.getCampaignallocatedbudgetinitialbuildList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild, 5));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild>} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} returns this
*/
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.setCampaignallocatedbudgetinitialbuildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.addCampaignallocatedbudgetinitialbuild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.CampaignAllocatedBudgetInitialBuild, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result.prototype.clearCampaignallocatedbudgetinitialbuildList = function() {
  return this.setCampaignallocatedbudgetinitialbuildList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.Result|undefined} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse} returns this
*/
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_allocation.NestCategory.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.NestCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.NestCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.NestCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcampaignList: jspb.Message.toObjectList(msg.getNestcampaignList(),
    proto.adx.budget_allocation.NestCategory.NestCampaign.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.NestCategory}
 */
proto.adx.budget_allocation.NestCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.NestCategory;
  return proto.adx.budget_allocation.NestCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.NestCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.NestCategory}
 */
proto.adx.budget_allocation.NestCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_allocation.NestCategory.NestCampaign;
      reader.readMessage(value,proto.adx.budget_allocation.NestCategory.NestCampaign.deserializeBinaryFromReader);
      msg.addNestcampaign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.NestCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.NestCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.NestCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcampaignList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_allocation.NestCategory.NestCampaign.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.NestCategory.NestCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    budgetList: jspb.Message.toObjectList(msg.getBudgetList(),
    proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.NestCategory.NestCampaign;
  return proto.adx.budget_allocation.NestCategory.NestCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = new proto.adx.budget_allocation.NestCategory.NestCampaign.Budget;
      reader.readMessage(value,proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.deserializeBinaryFromReader);
      msg.addBudget(value);
      break;
    case 3:
      var value = new proto.adx.budget_allocation.NestCategory.NestCampaign.Rows;
      reader.readMessage(value,proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.NestCategory.NestCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBudgetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.toObject = function(includeInstance, msg) {
  var f, obj = {
    dailybudget: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actualdailybudget: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.NestCategory.NestCampaign.Budget;
  return proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDailybudget(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualdailybudget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDailybudget();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActualdailybudget();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dailyBudget = 1;
 * @return {string}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.prototype.getDailybudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget} returns this
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.prototype.setDailybudget = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string actualDailyBudget = 2;
 * @return {string}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.prototype.getActualdailybudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget} returns this
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Budget.prototype.setActualdailybudget = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.NestCategory.NestCampaign.Rows;
  return proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table;
      reader.readMessage(value,proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table;
  return proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table} returns this
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table} returns this
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows} returns this
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table>}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table>} value
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows} returns this
*/
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows} returns this
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.Rows.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign} returns this
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Budget budget = 2;
 * @return {!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget>}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.getBudgetList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.NestCategory.NestCampaign.Budget, 2));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget>} value
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign} returns this
*/
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.setBudgetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Budget}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.addBudget = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_allocation.NestCategory.NestCampaign.Budget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign} returns this
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.clearBudgetList = function() {
  return this.setBudgetList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows>}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.NestCategory.NestCampaign.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows>} value
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign} returns this
*/
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign.Rows}
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_allocation.NestCategory.NestCampaign.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign} returns this
 */
proto.adx.budget_allocation.NestCategory.NestCampaign.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_allocation.NestCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.NestCategory} returns this
 */
proto.adx.budget_allocation.NestCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCampaign nestCampaign = 2;
 * @return {!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign>}
 */
proto.adx.budget_allocation.NestCategory.prototype.getNestcampaignList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.NestCategory.NestCampaign, 2));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.NestCategory.NestCampaign>} value
 * @return {!proto.adx.budget_allocation.NestCategory} returns this
*/
proto.adx.budget_allocation.NestCategory.prototype.setNestcampaignList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_allocation.NestCategory.NestCampaign=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.NestCategory.NestCampaign}
 */
proto.adx.budget_allocation.NestCategory.prototype.addNestcampaign = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_allocation.NestCategory.NestCampaign, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.NestCategory} returns this
 */
proto.adx.budget_allocation.NestCategory.prototype.clearNestcampaignList = function() {
  return this.setNestcampaignList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.TypeCase}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse;
  return proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result;
      reader.readMessage(value,proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categorytypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    campaignallocatedbudgetrebuildList: jspb.Message.toObjectList(msg.getCampaignallocatedbudgetrebuildList(),
    proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result;
  return proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorytype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 5:
      var value = new proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild;
      reader.readMessage(value,proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.deserializeBinaryFromReader);
      msg.addCampaignallocatedbudgetrebuild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategorytypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getBaseperiodforforecastid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCampaignallocatedbudgetrebuildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.budget_allocation.NestCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild;
  return proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_allocation.NestCategory;
      reader.readMessage(value,proto.adx.budget_allocation.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_allocation.NestCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCategory nestCategory = 2;
 * @return {!Array<!proto.adx.budget_allocation.NestCategory>}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.NestCategory, 2));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.NestCategory>} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild} returns this
*/
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_allocation.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.NestCategory}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_allocation.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryType = 3;
 * @return {!Array<string>}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.getCategorytypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.setCategorytypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.addCategorytype = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.clearCategorytypeList = function() {
  return this.setCategorytypeList([]);
};


/**
 * optional string basePeriodForForecastId = 4;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated CampaignAllocatedBudgetRebuild campaignAllocatedBudgetRebuild = 5;
 * @return {!Array<!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild>}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.getCampaignallocatedbudgetrebuildList = function() {
  return /** @type{!Array<!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild, 5));
};


/**
 * @param {!Array<!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild>} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} returns this
*/
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.setCampaignallocatedbudgetrebuildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.addCampaignallocatedbudgetrebuild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.CampaignAllocatedBudgetRebuild, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result.prototype.clearCampaignallocatedbudgetrebuildList = function() {
  return this.setCampaignallocatedbudgetrebuildList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.Result|undefined} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse} returns this
*/
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse} returns this
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.budget_allocation);
