import { getSummaryReportRequest, insertSummaryMemo, saveSummaryReportOrder } from "../../com/grpc/manager.js";
import { Cell, ContentRecordHolder } from '../data.js'
import * as tacklebox from "../../tacklebox/core";
import { ScreenBarValue2 } from '@/js/components/ScreenBarValue2.js';
import {uuid as tuuid} from "../../tacklebox/core/uuid";


function ManagerTableRawCol(structure, key = null, ratevalue = "", expectedvalue = "", data = [], simulatedvalue = "") {
    this.structure = structure;
    this.key = key;
    this.ratevalue = ratevalue;
    this.expectedvalue = expectedvalue;
    this.data = data;
    this.simulatedvalue = simulatedvalue;
}
ManagerTableRawCol.prototype.getRateValue = function () {
    let c = new Cell(this.ratevalue, "rate");
    c.isRoundMode = true;
    return c;
};
ManagerTableRawCol.prototype.getTitles = function () {
    let results = [];

    for (let i in this.data) {
        let data = this.data[i];
        results.push(data.day);
    }

    return results;
};
ManagerTableRawCol.prototype.getGraphTitle = function () {
    return this?.structure?.alias + " (" + this?.structure?.label + ")";
}
ManagerTableRawCol.prototype.getGraphs = function () {
    try {
        return [{
            label: this.structure.label + ' 目標',
            type: 'dash',
            value: this.data.map(() => this.simulatedvalue),
            dataGroup: 'group',
            color: '#000000',
            yAxisLabelPosition: null,
            labelGenerator: null
        }, {
            label: this.structure.label + ' 実績',
            type: 'bar',
            value: this.data.map(d => d.value),
            dataGroup: 'group',
            color: '#D0E9FB',
            yAxisLabelPosition: 'left',
            labelGenerator:
                this.key.includes("cpa") || this.key.includes("cost") ? v => '￥ ' + v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') :
                    this.key.includes("roas") ? v => v.toString() + "%" :
                        v => v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            grid: true,
        }];
    } catch (e) {
        console.log("Error catch: generate graph");
        console.error(e);
        return [];
    }
};

var EMemoBallStatus = {
    NONE: 0,
    LOAD: 1,
    READY: 2,
    EDIT: 3,
    UPDATE: 4,
    ERROR: 5,
};
function MemoBall(memo = "", memoupdated = null, callback = {}, status = EMemoBallStatus.READY) {
    this.status = status;
    this.memo = memo;
    this.edit = "";
    this.memoupdated = memoupdated;
    this.callback = callback;
}
MemoBall.prototype.update = function () {

};
MemoBall.prototype.startEdit = function () {
    this.edit = this.memo;
    this.status = EMemoBallStatus.EDIT;
};
MemoBall.prototype.cancelEdit = function () {
    this.status = EMemoBallStatus.READY;
};
MemoBall.prototype.isEdit = function () {
    switch (this.status) {
        case EMemoBallStatus.EDIT:
            return true;
    }

    return false;
};
MemoBall.prototype.save = function (text) {
    this.memo = text;
    this.status = EMemoBallStatus.READY;

    this.callback(this.memo);
};
MemoBall.prototype.getYmd = function () {
    if (this.memoupdated) {
        let mdyhmss = this.memoupdated.split(" ");
        if (mdyhmss.length > 1) {
            let mdys = mdyhmss[0].split("/");
            return mdys[0] + "年" + mdys[1] + "月" + mdys[2] + "日更新";
        }
    }
    return "";
};

function SummaryRawRecord(structure = null, client = null, advertizerid = 0, graphList = null, tableList = null, memo = null, memoupdated = null, callback = {}) {
    this.structure = JSON.parse(structure);
    this.client = client;
    this.advertizerid = advertizerid;
    this.graphList = graphList;
    this.tableList = tableList;
    this.memoball = new MemoBall(memo, memoupdated, function (m) {
        callback(advertizerid, m);
    });
    this.sorttag = 1000;
}
SummaryRawRecord.prototype.getName = function () {
    return new Cell(this.client, "string");
};
SummaryRawRecord.prototype.getMemoBall = function () {
    return this.memoball;
};
SummaryRawRecord.prototype.getTables = function (targetStructures) {
    let result = [];

    for (let i = 0; i < targetStructures.length; i++) {
        let isHit = false;
        if (this.structure.table.length >= i) {
            let st = this.structure.table[i];

            for (let k in this.tableList) {
                let table = this.tableList[k];
                if (table.key == st.backendKey) {
                    isHit = true;
                    result.push(new ManagerTableRawCol(st, table.key, table.ratevalue, table.expectedvalue, table.dataList, table.simulatedvalue));
                }
            }
        }
        if (!isHit) {
            result.push(new ManagerTableRawCol());
        }
    }

    result.forEach(table => {
        table.getType = () => {
            if (table.key == null) return null;
            if (table.key.includes("cost")) return "cost";
            if (table.key.includes("cv")) return "cv";
            if (table.key.includes("cpa")) return "cpa";
            if (table.key.includes("roas")) return "roas";
        }
    });
    return result;
};
SummaryRawRecord.prototype.getGraph = function () {
    //this.graphList = graphList;
    return [];
};
SummaryRawRecord.prototype.getGraphList = function () {
    return this.graphList;
};
SummaryRawRecord.prototype.getGraphTitle = function () {
    let titles = [];
    for (let i in this.graphList) {
        titles.push(this.graphList[i].month);
    }
    return titles;
};
SummaryRawRecord.prototype.getGraphs = function () {
    let self = this;
    try {
        let assumption = Array.from({ length: self.graphList.length - 1 }, () => null);
        if (assumption) {
            for (let i in self.graphList) {
                let ym = self.graphList[i]?.month?.split("/");
                if (ym && ym.length > 1) {
                    if (new Date().getFullYear() == Number(ym[0]) && (new Date().getMonth() + 1) == Number(ym[1])) {
                        assumption = assumption.concat(self.graphList[self.graphList.length - 1].tableList.filter(t => t.key.includes("cv")).filter(t => t.valuetype == "current")[0].expected);
                        break;
                    }
                }
            }
        }


        let ret = self.structure.graph.map(g => new Object({
            label: g.label,
            type: g.type,
            value: self.graphList.map(data => data.tableList.filter(t => t.key == g.backendKey && t.valuetype == g.valueType)[0].value),
            dataGroup: g.backendKey,
            color:
                g.backendKey.includes("cpa") || g.backendKey.includes("roas") ? "#00CDE0" :
                    g.backendKey.includes("cv") && g.valueType == "current" ? "#34b7fd" :
                        g.backendKey.includes("cv") && g.valueType == "simulated" ? "#a6e7ff" :
                            "",
            yAxisLabelPosition:
                g.backendKey.includes("cpa") || g.backendKey.includes("roas") ? "right" :
                    g.backendKey.includes("cv") && g.valueType == "current" ? "left" :
                        g.backendKey.includes("cv") && g.valueType == "simulated" ? null :
                            null,
            labelGenerator:
                g.backendKey.includes("cpa") ? v => '￥' + v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') :
                    g.backendKey.includes("roas") ? v => v.toString() + "%" :
                        v => v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            grid:
                g.backendKey.includes("cpa") || g.backendKey.includes("roas") ? true :
                    false,
        })).concat({
            label: self.structure.graph.map(g => g.label).filter(l => l.includes("実績"))[0].replace("実績", "着地想定"),
            type: "bar",
            value: assumption,
            dataGroup: self.structure.graph.filter(g => g.backendKey.includes("cv"))[0].backendKey,
            color: "#068DEF50",
            yAxisLabelPosition: null,
            labelGenerator: v => v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            legend: false,
            grid: false
        });

        return [ret[2], ret[0], ret[3], ret[1],];
    } catch (e) {
        console.log("Error catch: generate graph");
        console.error(e);
        return [];
    }

};

function ROrder(callback, saveordercallback) {
    this.advertizerIds = [];
    this.callback = callback;
    this.saveordercallback = saveordercallback;
}
ROrder.prototype.initialize = function () {
    this.advertizerIds = [];
    this.save();
};
ROrder.prototype.save = function () {
    // [LocalStrageVer]
    // try {
    //     let s = JSON.stringify(this.advertizerIds);
    //     console.log(s);
    //     localStorage.setItem('adxmanagerorder', s);
    // } catch (ex) {
    //     console.log(ex);
    // }

    if (this.advertizerIds.length > 0) {
        this.saveordercallback(this.advertizerIds);
    }

};
ROrder.prototype.load = function () {
    // [LocalStrageVer]
    // try {
    //     let s = localStorage.getItem('adxmanagerorder');
    //     let js = JSON.parse(s);
    //     this.advertizerIds = [];
    //     for (let i in js) {
    //         this.advertizerIds.push(js[i]);
    //     }
    // } catch (ex) {
    //     console.log(ex);
    // }
    // console.log("load", this.advertizerIds);
};
ROrder.prototype.reset = function () {
    this.advertizerIds = [];
};
ROrder.prototype.change = function (idx, advertizerId) {
    this.advertizerIds = this.advertizerIds.filter(item => item != advertizerId);
    this.advertizerIds.splice(idx, 0, advertizerId);
    this.callback();
};

function SummaryRawRecordHolder(callback, saveordercallback) {
    ContentRecordHolder.call(this);
    this.requestId = null;
    this.callback = callback;
    let self = this;
    this.rorder = new ROrder(function () {
        self.refreshOrder();
    },
        function (advids) {
            saveordercallback(advids);
        });
    this.rorder.load();
}
tacklebox.extend(SummaryRawRecordHolder, ContentRecordHolder);
SummaryRawRecordHolder.prototype._onContentRecordCreateRecord = function (data) {
    let r = new SummaryRawRecord(data.structure, data.summaryreport.client, data.summaryreport.advertizerid, data.summaryreport.graphList, data.summaryreport.tableList, data.summaryreport.memo, data.summaryreport.memoupdated, this.callback);
    let isHit = false;
    for (let i = 0; i < this.rorder.advertizerIds.length; i++) {
        if (r.advertizerid == this.rorder.advertizerIds[i]) {
            isHit = true;
            r.sorttag = i;
            break;
        }
    }
    if (!isHit) {
        r.sorttag = 1000;
    }
    return r;
};
SummaryRawRecordHolder.prototype._onContentRecordGetHeaderCell = function () {
    let result = [
        new Cell("クライアント", "string"),
        new Cell("月別推移", "string")
    ];
    let tc = this.getTableColHeaders();
    for (let i in tc) {
        result.push(new Cell(tc[i].label, "string"));
    }
    result.push(new Cell("memo", "string"));
    return result;
};
SummaryRawRecordHolder.prototype.getTableColHeaders = function () {
    if (this.records.length > 0) {
        if (this.records[0]) {
            return this.records[0].structure.table;
        }
    }
    return [];
};
SummaryRawRecordHolder.prototype.edit = function (item) {
    for (let i in this.records) {
        if (this.records[i] == item) {
            this.records[i].getMemoBall().startEdit()
        } else {
            this.records[i].getMemoBall().cancelEdit();
        }
    }
};
SummaryRawRecordHolder.prototype.getSortedRecord = function () {
    return this.records.sort((a, b) => {
        return a.advertizerid - b.advertizerid;
    });
};
SummaryRawRecordHolder.prototype.refreshOrder = function () {
    for (let i = 0; i < this.records.length; i++) {
        let isHit = false;
        for (let j = 0; j < this.rorder.advertizerIds.length; j++) {
            if (this.rorder.advertizerIds[j] == this.records[i].advertizerid) {
                isHit = true;
                this.records[i].sorttag = j;
                break;
            }
        }
        if (!isHit) {
            this.records[i].sorttag = 1000;
        }
    }
};
SummaryRawRecordHolder.prototype.fixOrder = function (isSave = false) {
    this.rorder.reset();
    let ors = this.getSortedRecord();
    for (let i = 0; i < ors.length; i++) {
        this.rorder.change(i, ors[i].advertizerid);
    }
    if (isSave) {
        this.rorder.save();
    }
};
SummaryRawRecordHolder.prototype.orderInitialize = function () {
    this.rorder.reset();
    this.rorder.initialize();
};

function Mph(advertizerId, callback = null) {
  this.advertizerId = advertizerId;
  this.callback = callback;
  this.is_shown = false;
  this.isShow = true;
  this.loadState = 0;
}
Mph.prototype.showcallback = function () {
  if (!this.is_shown) {
    this.is_shown = true;
    if (this.callback) {
      this.callback(this.advertizerId);
    }
  }
};


function SummaryPage() {
    this.currentPageStreams = [];
    this.advertizerId = 2;
    this.timeRange = null;
    this.user = null;
    this.cuuid = null;
    let self = this;
    this.resetKey = 0;
    this.recordHolder = new SummaryRawRecordHolder(function (advertizerId, memo) {

        let request = {
            token: self.user.auth0.token,
            userUuid: self.user.userUuid,
        };

        request.advertizerId = self.advertizerId;
        request.timeRequest = self.timeRange.getTimeRequest();

        const cuuid = insertSummaryMemo(advertizerId, request, request.timeRequest.actualMonth, memo, function (uuid, data) {
            if (data != null && cuuid == uuid) {
                let json = JSON.parse(JSON.stringify(data.result));
                self.recordHolder.records
                    .filter(raw => raw.advertizerid == json.advertizerid)
                    .map(record => record.memoball.memoupdated = json.memoupdated);
            }
        }, () => {
            //self.isManagerTableNetworking = false;
            //
        }, (e) => {
            console.log(e);
        }, "manager", {});
        //this.activeUid = cuuid;


    }, function (advertizerIds) {
        self.saveOrders(self.user, advertizerIds);
    });
    this.isManagerTableNetworking = false;
    this.process = new ScreenBarValue2();
    this.isManagerTableStreamStart = false;
    this.isFirstLoad = false;
    this.mphs = [];
    this.activeUid = tuuid();
}
SummaryPage.prototype.saveOrders = function (user, advertizerIds, callback = () => { }) {
    let request = {
        token: user.auth0.token,
        userUuid: user.userUuid,
    };
    request.aors = [];
    for (let i = 0; i < advertizerIds.length; i++) {
        request.aors.push({
            advertizerId: advertizerIds[i],
            order: i + 1
        });
    }

    const cuuid = saveSummaryReportOrder(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            //
        }
    }, () => {
        callback();
    }, (e) => {
        console.log(e);
        callback();
    }, "manager", {});
};
SummaryPage.prototype.loader = function (uid) {
    const self = this;
    if (this.activeUid != uid) {
        return;
    }
    for (let x = 0 ; x < this.mphs.length; x++) {
        if (this.mphs[x].is_shown == true) {
            for (let i = 0 ; i <= x; i ++) {
                if(this.mphs[i].loadState == 1) {
                    return;
                }
                if(this.mphs[i].loadState == 0) {
                    const mph = this.mphs[i];
                    mph.loadState = 1;
                    let request = {
                        token: this.user.auth0.token,
                        userUuid: this.user.userUuid,
                    };
                    request.advertizerId = mph.advertizerId;
                    request.timeRequest = this.timeRange.getTimeRequest();
                    request.requestAdvertizerIds = [mph.advertizerId];
                    
                    const cuuid = getSummaryReportRequest(
                        mph.advertizerId,
                        request,
                        function (uuid, data) {
                            if (self.activeUid != uid) {
                                return;
                            }

                            try {
                                if (data != null && cuuid == uuid) {
                                    let json = JSON.parse(JSON.stringify(data.result));
                                    if (!!json.structure != false) {
                                        self.recordHolder.pushDataStream(json, json.requestId);
                                    }
                                }
                            } catch (ex) {
                                console.log(ex);
                            }
                        },
                        (uuid) => {
                            self.resetKey = self.resetKey + 1;
                            if (self.activeUid != uid) {
                                return;
                            }

                            if (cuuid == uuid) {
                                self.isFirstLoad = true;
                                
                                mph.loadState = 2
                                mph.isShow = false;
                                self.loader(uid);
                            }
                        },
                        (uuid, e) => {
                            console.log(uuid, e)
                        },
                        'manager',
                        self.currentPageStreams
                    );
                    return;
                }
            }

        }
    }
}
SummaryPage.prototype.initialize = function (advertizerId, timeRange, user) {
    this.cancelAllAPICall();

    this.cuuid = null;
    this.currentPageStreams = [];
    this.advertizerId = advertizerId;
    this.timeRange = timeRange;
    this.user = user;
    let self = this;
    let request = {
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };

    request.advertizerId = this.advertizerId;
    request.timeRequest = this.timeRange.getTimeRequest();

    this.process.stop();
    this.isManagerTableNetworking = false;
    this.isManagerTableStreamStart = true;
    this.recordHolder.initialize();//structureなし

    this.mphs = [];
    this.isFirstLoad = false;
    this.activeUid = tuuid();
    const auid = this.activeUid;
    setTimeout(function(){
      for (let i in self.user.advertizers) {
        self.mphs.push(
          new Mph(self.user.advertizers[i].id, function (advertizerId) {
            if (advertizerId) {
                //
            }
            self.loader(auid);
          })
        );
      }
    
    }, 300);
    // const cuuid = getSummaryReportRequest(this.advertizerId, request, function (uuid, data) {
    //     try {
    //         if (data != null && cuuid == uuid && data.result.requestid == self.activeUid) {
    //             let json = JSON.parse(JSON.stringify(data.result));
    //             if (!!(json.structure) != false) {
    //                 self.recordHolder.pushDataStream(json, json.requestId);
    //                 self.isManagerTableStreamStart = true;
    //             }
    //         }
    //     } catch (ex) {
    //         console.log(ex);
    //     }
    // }, (uuid) => {
    //     self.isManagerTableNetworking = false;
    //     if (uuid == self.activeUid) {
    //         self.process.stop();
    //     }
    // }, (uuid, e) => {
    //     if (uuid == self.activeUid) {
    //         self.process.stop();
    //     }
    //     console.log(e);
    //     self.isManagerTableNetworking = false;
    // }, "manager", this.currentPageStreams);
    // this.activeUid = cuuid;
};
SummaryPage.prototype.cancelAllAPICall = function () {
    this.currentPageStreams.forEach(stream => stream.cancel());
}
// init providerTables
export { SummaryPage }
