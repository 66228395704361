import { ObserverSubject } from '../event/ObserverSubject'
import { TimeYmd } from './TimeYmd'

function TimeYmdRangeSe(y = 2021, m = 5, d = 1, ey = 2021, em = 5, ed = 1) {
    this.startYmd = new TimeYmd(y, m, d);
    this.endYmd = new TimeYmd(ey, em, ed);
    this.subject = new ObserverSubject();
}
TimeYmdRangeSe.prototype.getYm = function () {
    return this.startYmd.getYm();
};
TimeYmdRangeSe.prototype.incMonth = function () {
    this.startYmd.incMonth();
    this.endYmd.incMonth();
    this.subject.notify();
};
TimeYmdRangeSe.prototype.decMonth = function () {
    this.startYmd.decMonth();
    this.endYmd.decMonth();
    this.subject.notify();
};
TimeYmdRangeSe.prototype.setStartYmd = function (y, m, d) {
    this.startYmd.year = y;
    this.startYmd.month = m;
    this.startYmd.day = d;
    // this.subject.notify();
};
TimeYmdRangeSe.prototype.setEndYmd = function (y, m, d) {
    this.endYmd.year = y;
    this.endYmd.month = m;
    this.endYmd.day = d;
    this.subject.notify();
};
TimeYmdRangeSe.prototype.setRangeYmd = function (sy, sm, sd, ey, em, ed) {
    this.startYmd.year = sy;
    this.startYmd.month = sm;
    this.startYmd.day = sd;
    this.endYmd.year = ey;
    this.endYmd.month = em;
    this.endYmd.day = ed;
    this.subject.notify();
};
TimeYmdRangeSe.prototype.setStartEndYmd = function (y, m, d) {
    this.startYmd.year = y;
    this.startYmd.month = m;
    this.startYmd.day = d;
    this.endYmd.year = y;
    this.endYmd.month = m;
    this.endYmd.day = d;
    this.subject.notify();
};


export { TimeYmdRangeSe }
