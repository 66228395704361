<template>
  <div class="edittext">
    <template v-for="(item, index) in before()" :key="index">
      <div>
        <div class="title">{{ type2title(item.type) }}</div>
        <div class="body">{{ data2str(item.data) }}</div>
      </div>
    </template>
    <div>
      <div class="title">{{ type2title(inputmode.type) }}</div>
      <div style="position: relative;">
        <div
          style="
            font: 12px / 17px 'Noto Sans JP';
            padding: 4px;
            padding-left: 16px;
            padding-right: 32px;
            padding-bottom: 32px;
          "
        >
          {{ inputData }}
        </div>
        <textarea
          class="mytextarea"
          ref="txta_main"
          :style="{
            resize: 'none',
            width: '100%',
            outline: 'none',
            boxShadow: '0px 3px 20px #00000029',
          }"
          style="
            outline: none;
            height: 100%;
            position: absolute;
            padding: 4px;
            padding-left: 16px;
            padding-right: 40px;
            top: 0;
            left: 0px;
            right: 0;
            font: 12px / 17px 'Noto Sans JP';
          "
          v-model="inputData"
          @keydown.enter.exact="decide()"
          v-on:blur="decide()"
        />
      </div>
    </div>
    <template v-for="(item, index) in after()" :key="index">
      <div>
        <div class="title">{{ type2title(item.type) }}</div>
        <div class="body">{{ data2str(item.data) }}</div>
      </div>
    </template>
  </div>
</template>


<style scoped>
.title {
  padding: 4px 16px;
  border-radius: 4px;
  background: rgb(238, 238, 238);
  margin: 4px;
  width: fit-content;
}
.body {
  padding: 4px 16px;
}
.edittext {
  height: 100%;
  white-space: break-spaces;
  font: 12px / 17px 'Noto Sans JP';
}
</style>

<style scoped>
textarea:focus {
  outline: none;
  box-shadow: 0 0 0;
}
</style>
<script>
import { sprintApps } from '@/js/setting.js';
import { uuid } from '@/js/tacklebox/core/uuid.js';

export default {
  name: 'SprintEditText',
  data() {
    return {
      name: 'SprintEditText',
      inputData: '',
      dataJson: [],
      isShowAddList: false,
      isSave: false,
    };
  },
  computed: {},
  mounted() {
    // this.inputData = this.editball.raw;
    try {
      if (this.editball.raw) {
        this.dataJson = JSON.parse(this.editball.raw);
      }
    } catch (ex) {
      console.log(ex);
    }
    this.inputData = '';
    for (let i in this.dataJson) {
      let dj = this.dataJson[i];
      try {
        if (dj.id == this.inputmode.id) {
          this.inputData = dj.data;
          break;
        }
      } catch (ex) {
        console.log(ex);
      }
    }
    this.isSave = false;
    this.$refs.txta_main.focus();
  },
  methods: {
    before() {
      let rs = [];
      for (let i in this.dataJson) {
        let json = this.dataJson[i];
        if (json.id == this.inputmode.id) {
          break;
        }
        rs.push(json);
      }
      return rs;
    },
    after() {
      let rs = [];
      let isHit = false;
      for (let i in this.dataJson) {
        let json = this.dataJson[i];
        if (isHit) {
          rs.push(json);
        }
        if (json.id == this.inputmode.id) {
          isHit = true;
        }
      }
      return rs;
    },
    data2str(str) {
      if (str) {
        if (str.length > 0) {
          return str;
        }
      }
      return '-';
    },
    type2title(type) {
      for (let i in sprintApps) {
        let sp = sprintApps[i];
        if (sp.id == type) {
          return sp.data;
        }
      }
      return '-';
    },
    decide() {
      if (this.isSave) {
        return;
      }
      this.isSave = true;
      let isHit = false;
      for (let i in this.dataJson) {
        let dj = this.dataJson[i];
        try {
          if (dj.id == this.inputmode.id) {
            isHit = true;
            dj.data = this.inputData;
            break;
          }
        } catch (ex) {
          console.log(ex);
        }
      }

      if (!isHit) {
        this.dataJson.push({
          id: uuid(),
          type: this.inputmode.type,
          data: this.inputData,
        });
      }
      let st = JSON.stringify(this.dataJson);
      this.editball.save(st);
    },
  },
  components: {},
  props: {
    inputmode: String,
    editball: Object,
  },
};
</script>
