<template>
        <tr ref="holder" style="height: 177px;" v-if="mph.isShow">
            <td style="width: 152px; padding: 8px">
            <BoxSpinner style="height: 20px; margin-bottom: 8px" />
            <BoxSpinner style="height: 20px" />
            </td>
            <td style="width: 700px; padding: 8px">
            <BoxSpinner style="height: 100%; width: 100%" />
            </td>
            <td style="width: 136px; padding: 8px; position: relative">
            <BoxSpinner style="margin-top: 30px; height: 144px; width: 100%" />
            <BoxSpinner
                style="
                height: 24px;
                width: 80px;
                position: absolute;
                top: 8px;
                left: 8px;
                "
            />
            </td>
            <td style="width: 136px; padding: 8px; position: relative">
            <BoxSpinner style="margin-top: 30px; height: 144px; width: 100%" />
            <BoxSpinner
                style="
                height: 24px;
                width: 80px;
                position: absolute;
                top: 8px;
                left: 8px;
                "
            />
            </td>
            <td style="width: 136px; padding: 8px; position: relative">
            <BoxSpinner style="margin-top: 30px; height: 144px; width: 100%" />
            <BoxSpinner
                style="
                height: 24px;
                width: 80px;
                position: absolute;
                top: 8px;
                left: 8px;
                "
            />
            </td>
            <td style="padding: 8px; position: relative">
            <BoxSpinner style="margin-top: 30px; height: 144px; width: 100%" />
            <BoxSpinner
                style="
                height: 24px;
                width: 180px;
                position: absolute;
                top: 8px;
                right: 8px;
                "
            />
            </td>
        </tr>
</template>
  

<script>

import BoxSpinner from '@/components/layout/BoxSpinner.vue';

export default {
    name: 'ManagerPlaceHolder',
    data() {
        return {
            name: 'ManagerPlaceHolder',
            isShow: true,
        };
    },
    mounted() {
        const self = this;
        // console.log("mounted");
        const observer = new IntersectionObserver(
            (entries) => {
                const firstEntry = entries[0];
                if (firstEntry.isIntersecting) {
                    self.mph.showcallback();
                    // self.isShow = false;
                }
            },
            // {
            //     root: document,
            //     threshold: 0,
            //     rootMargin: "0px",
            // }
        );

        if (this.$refs) {
            if (this.$refs.holder) {
                observer.observe(this.$refs.holder)

            }
        }

    },
    unmounted() {
        // console.log("unmounted");
    },
    methods: {

    },
    components: {
        BoxSpinner
    },
    props: {
        mph: Object,
    },
};
</script>
  