// source: budget_simulation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row', null, global);
goog.exportSymbol('proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.GetSimulationDataFileUrlRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.GetSimulationDataFileUrlResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File', null, global);
goog.exportSymbol('proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.InsertBasePeriodRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.InsertBasePeriodResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.InsertBasePeriodResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.InsertBasePeriodResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedVersionRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedVersionResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion', null, global);
goog.exportSymbol('proto.adx.budget_simulation.MonthlySimulatedVersionResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.OverallSimulatedBudgetRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue', null, global);
goog.exportSymbol('proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table', null, global);
goog.exportSymbol('proto.adx.budget_simulation.Response', null, global);
goog.exportSymbol('proto.adx.budget_simulation.Response.TypeCase', null, global);
goog.exportSymbol('proto.adx.budget_simulation.UpsertBasePeriodRequest', null, global);
goog.exportSymbol('proto.adx.budget_simulation.UpsertBasePeriodResponse', null, global);
goog.exportSymbol('proto.adx.budget_simulation.UpsertBasePeriodResponse.Result', null, global);
goog.exportSymbol('proto.adx.budget_simulation.UpsertBasePeriodResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.displayName = 'proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.displayName = 'proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.displayName = 'proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.GetSimulationDataFileUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.displayName = 'proto.adx.budget_simulation.GetSimulationDataFileUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.GetSimulationDataFileUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.displayName = 'proto.adx.budget_simulation.GetSimulationDataFileUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.displayName = 'proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.displayName = 'proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedVersionRequest.displayName = 'proto.adx.budget_simulation.MonthlySimulatedVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.InsertBasePeriodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.InsertBasePeriodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.InsertBasePeriodRequest.displayName = 'proto.adx.budget_simulation.InsertBasePeriodRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.displayName = 'proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.displayName = 'proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.displayName = 'proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.displayName = 'proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.displayName = 'proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.displayName = 'proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.displayName = 'proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.displayName = 'proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.displayName = 'proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.displayName = 'proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.OverallSimulatedBudgetRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.OverallSimulatedBudgetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.OverallSimulatedBudgetRequest.displayName = 'proto.adx.budget_simulation.OverallSimulatedBudgetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.displayName = 'proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.displayName = 'proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.UpsertBasePeriodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.UpsertBasePeriodRequest.displayName = 'proto.adx.budget_simulation.UpsertBasePeriodRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.Response.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.Response.displayName = 'proto.adx.budget_simulation.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.displayName = 'proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.displayName = 'proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.UpsertBasePeriodResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.UpsertBasePeriodResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.UpsertBasePeriodResponse.displayName = 'proto.adx.budget_simulation.UpsertBasePeriodResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.UpsertBasePeriodResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.displayName = 'proto.adx.budget_simulation.UpsertBasePeriodResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.InsertBasePeriodResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.InsertBasePeriodResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.InsertBasePeriodResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.InsertBasePeriodResponse.displayName = 'proto.adx.budget_simulation.InsertBasePeriodResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.InsertBasePeriodResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.InsertBasePeriodResponse.Result.displayName = 'proto.adx.budget_simulation.InsertBasePeriodResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.MonthlySimulatedVersionResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedVersionResponse.displayName = 'proto.adx.budget_simulation.MonthlySimulatedVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.displayName = 'proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.displayName = 'proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.oneofGroups_);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.repeatedFields_, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.displayName = 'proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest;
  return proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest} returns this
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest} returns this
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.TypeCase}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse;
  return proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    signedurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result;
  return proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSignedurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result} returns this
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result} returns this
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string signedUrl = 3;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.prototype.getSignedurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result} returns this
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result.prototype.setSignedurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse} returns this
*/
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse} returns this
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse} returns this
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse} returns this
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.GetSimulationDataFileUrlRequest;
  return proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.TypeCase}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.GetSimulationDataFileUrlResponse;
  return proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploaded: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    signedurl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File;
  return proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploaded(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploaded();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSignedurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string uploaded = 1;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.prototype.getUploaded = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.prototype.setUploaded = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string signedUrl = 3;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.prototype.getSignedurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.prototype.setSignedurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inprogressfilesList: jspb.Message.toObjectList(msg.getInprogressfilesList(),
    proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.toObject, includeInstance),
    successfilesList: jspb.Message.toObjectList(msg.getSuccessfilesList(),
    proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.toObject, includeInstance),
    failurefilesList: jspb.Message.toObjectList(msg.getFailurefilesList(),
    proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result;
  return proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = new proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File;
      reader.readMessage(value,proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.deserializeBinaryFromReader);
      msg.addInprogressfiles(value);
      break;
    case 5:
      var value = new proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File;
      reader.readMessage(value,proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.deserializeBinaryFromReader);
      msg.addSuccessfiles(value);
      break;
    case 6:
      var value = new proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File;
      reader.readMessage(value,proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.deserializeBinaryFromReader);
      msg.addFailurefiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInprogressfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.serializeBinaryToWriter
    );
  }
  f = message.getSuccessfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.serializeBinaryToWriter
    );
  }
  f = message.getFailurefilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated File inprogressFiles = 4;
 * @return {!Array<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File>}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.getInprogressfilesList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File, 4));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File>} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} returns this
*/
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.setInprogressfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.addInprogressfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.clearInprogressfilesList = function() {
  return this.setInprogressfilesList([]);
};


/**
 * repeated File successFiles = 5;
 * @return {!Array<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File>}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.getSuccessfilesList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File, 5));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File>} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} returns this
*/
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.setSuccessfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.addSuccessfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.clearSuccessfilesList = function() {
  return this.setSuccessfilesList([]);
};


/**
 * repeated File failureFiles = 6;
 * @return {!Array<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File>}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.getFailurefilesList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File, 6));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File>} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} returns this
*/
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.setFailurefilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.addFailurefiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result.prototype.clearFailurefilesList = function() {
  return this.setFailurefilesList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse} returns this
*/
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse} returns this
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action = 4;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 adPageComponentId = 5;
 * @return {number}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action = 4;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 adPageComponentId = 5;
 * @return {number}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action = 4;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 adPageComponentId = 5;
 * @return {number}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action = 4;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 adPageComponentId = 5;
 * @return {number}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action = 4;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 adPageComponentId = 5;
 * @return {number}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action = 4;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 adPageComponentId = 5;
 * @return {number}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedVersionRequest;
  return proto.adx.budget_simulation.MonthlySimulatedVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.InsertBasePeriodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.InsertBasePeriodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertizerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: jspb.Message.getFieldWithDefault(msg, 2, ""),
    end: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    version: jspb.Message.getFieldWithDefault(msg, 5, ""),
    revision: jspb.Message.getFieldWithDefault(msg, 6, 0),
    adpageid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.InsertBasePeriodRequest}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.InsertBasePeriodRequest;
  return proto.adx.budget_simulation.InsertBasePeriodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.InsertBasePeriodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.InsertBasePeriodRequest}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRevision(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.InsertBasePeriodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.InsertBasePeriodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRevision();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 advertizerId = 1;
 * @return {number}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string start = 2;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end = 3;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.getEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.setEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string version = 5;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 revision = 6;
 * @return {number}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.setRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 adPageId = 7;
 * @return {number}
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodRequest.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.toObject, includeInstance),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRequest;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseperiodforforecastid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.serializeBinaryToWriter
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Row rows = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.Row, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string basePeriodForForecastId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ListValue values = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.ListValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional int32 adPageComponentId = 3;
 * @return {number}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.toObject, includeInstance),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest;
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseperiodforforecastid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.serializeBinaryToWriter
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue;
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows;
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table;
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table>}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table>} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows} returns this
*/
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory;
  return proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Rows rows = 2;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows>}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows>} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory} returns this
*/
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCategory nestCategory = 2;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory>}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory>} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue} returns this
*/
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows>}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows>} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue} returns this
*/
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string basePeriodForForecastId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ListValue values = 2;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue>}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue>} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} returns this
*/
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.ListValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional int32 adPageComponentId = 3;
 * @return {number}
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.toObject, includeInstance),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest;
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseperiodforforecastid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.serializeBinaryToWriter
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue;
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows;
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table;
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table>}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table>} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows} returns this
*/
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory;
  return proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Rows rows = 2;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows>}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows>} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory} returns this
*/
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCategory nestCategory = 2;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory>}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory>} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue} returns this
*/
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows>}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows>} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue} returns this
*/
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string basePeriodForForecastId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ListValue values = 2;
 * @return {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue>}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue>} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} returns this
*/
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.ListValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional int32 adPageComponentId = 3;
 * @return {number}
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.OverallSimulatedBudgetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.toObject, includeInstance),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.OverallSimulatedBudgetRequest;
  return proto.adx.budget_simulation.OverallSimulatedBudgetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue;
      reader.readMessage(value,proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.OverallSimulatedBudgetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseperiodforforecastid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.serializeBinaryToWriter
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue;
  return proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table;
      reader.readMessage(value,proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table;
  return proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table} returns this
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table} returns this
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table>}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table>} value
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue} returns this
*/
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue} returns this
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string basePeriodForForecastId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ListValue values = 2;
 * @return {!Array<!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue>}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue>} value
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} returns this
*/
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.OverallSimulatedBudgetRequest.ListValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional int32 adPageComponentId = 3;
 * @return {number}
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} returns this
 */
proto.adx.budget_simulation.OverallSimulatedBudgetRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.UpsertBasePeriodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertizerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: jspb.Message.getFieldWithDefault(msg, 2, ""),
    end: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    version: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adpageid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodRequest}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.UpsertBasePeriodRequest;
  return proto.adx.budget_simulation.UpsertBasePeriodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodRequest}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.UpsertBasePeriodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 advertizerId = 1;
 * @return {number}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string start = 2;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end = 3;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.getEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.setEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string version = 5;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 adPageId = 6;
 * @return {number}
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodRequest} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodRequest.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.Response.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.Response.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.Response.TypeCase}
 */
proto.adx.budget_simulation.Response.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.Response.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.Response.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.Response}
 */
proto.adx.budget_simulation.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.Response;
  return proto.adx.budget_simulation.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.Response}
 */
proto.adx.budget_simulation.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string result = 1;
 * @return {string}
 */
proto.adx.budget_simulation.Response.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.Response} returns this
 */
proto.adx.budget_simulation.Response.prototype.setResult = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.adx.budget_simulation.Response.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.Response} returns this
 */
proto.adx.budget_simulation.Response.prototype.clearResult = function() {
  return jspb.Message.setOneofField(this, 1, proto.adx.budget_simulation.Response.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.Response.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.Response.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.Response} returns this
 */
proto.adx.budget_simulation.Response.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.Response.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.Response} returns this
 */
proto.adx.budget_simulation.Response.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.Response.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.Response.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.TypeCase}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.repeatedFields_ = [3,4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categorytypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    editableList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    campaignsimulatedbudgetinitialbuildList: jspb.Message.toObjectList(msg.getCampaignsimulatedbudgetinitialbuildList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorytype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEditable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 6:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.deserializeBinaryFromReader);
      msg.addCampaignsimulatedbudgetinitialbuild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategorytypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getEditableList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCampaignsimulatedbudgetinitialbuildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.toObject, includeInstance),
    nestcampaignList: jspb.Message.toObjectList(msg.getNestcampaignList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.deserializeBinaryFromReader);
      msg.addNestcampaign(value);
      break;
    case 4:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.serializeBinaryToWriter
    );
  }
  f = message.getNestcampaignList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.serializeBinaryToWriter
    );
  }
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Rows rows = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcampaignList: jspb.Message.toObjectList(msg.getNestcampaignList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.deserializeBinaryFromReader);
      msg.addNestcampaign(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcampaignList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCampaign nestCampaign = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.getNestcampaignList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.setNestcampaignList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.addNestcampaign = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.clearNestcampaignList = function() {
  return this.setNestcampaignList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCategory nestCategory = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};


/**
 * repeated NestCampaign nestCampaign = 3;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.getNestcampaignList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.setNestcampaignList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.addNestcampaign = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.NestCampaign, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.clearNestcampaignList = function() {
  return this.setNestcampaignList([]);
};


/**
 * repeated Rows rows = 4;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows, 4));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryType = 3;
 * @return {!Array<string>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.getCategorytypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.setCategorytypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.addCategorytype = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.clearCategorytypeList = function() {
  return this.setCategorytypeList([]);
};


/**
 * repeated string editable = 4;
 * @return {!Array<string>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.getEditableList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.setEditableList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.addEditable = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.clearEditableList = function() {
  return this.setEditableList([]);
};


/**
 * optional string basePeriodForForecastId = 5;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.clearBaseperiodforforecastid = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.hasBaseperiodforforecastid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated CampaignSimulatedBudgetInitialBuild campaignSimulatedBudgetInitialBuild = 6;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.getCampaignsimulatedbudgetinitialbuildList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild, 6));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.setCampaignsimulatedbudgetinitialbuildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.addCampaignsimulatedbudgetinitialbuild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.CampaignSimulatedBudgetInitialBuild, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result.prototype.clearCampaignsimulatedbudgetinitialbuildList = function() {
  return this.setCampaignsimulatedbudgetinitialbuildList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.TypeCase}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.repeatedFields_ = [3,4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categorytypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    editableList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    campaignsimulatedbudgetrebuildList: jspb.Message.toObjectList(msg.getCampaignsimulatedbudgetrebuildList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorytype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEditable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 6:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.deserializeBinaryFromReader);
      msg.addCampaignsimulatedbudgetrebuild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategorytypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getEditableList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCampaignsimulatedbudgetrebuildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.toObject, includeInstance),
    nestcampaignList: jspb.Message.toObjectList(msg.getNestcampaignList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.deserializeBinaryFromReader);
      msg.addNestcampaign(value);
      break;
    case 4:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.serializeBinaryToWriter
    );
  }
  f = message.getNestcampaignList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.serializeBinaryToWriter
    );
  }
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Rows rows = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcampaignList: jspb.Message.toObjectList(msg.getNestcampaignList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory;
  return proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.deserializeBinaryFromReader);
      msg.addNestcampaign(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcampaignList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCampaign nestCampaign = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.getNestcampaignList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.setNestcampaignList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.addNestcampaign = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.clearNestcampaignList = function() {
  return this.setNestcampaignList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCategory nestCategory = 2;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};


/**
 * repeated NestCampaign nestCampaign = 3;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.getNestcampaignList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.setNestcampaignList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.addNestcampaign = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.NestCampaign, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.clearNestcampaignList = function() {
  return this.setNestcampaignList([]);
};


/**
 * repeated Rows rows = 4;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows, 4));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryType = 3;
 * @return {!Array<string>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.getCategorytypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.setCategorytypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.addCategorytype = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.clearCategorytypeList = function() {
  return this.setCategorytypeList([]);
};


/**
 * repeated string editable = 4;
 * @return {!Array<string>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.getEditableList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.setEditableList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.addEditable = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.clearEditableList = function() {
  return this.setEditableList([]);
};


/**
 * optional string basePeriodForForecastId = 5;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.clearBaseperiodforforecastid = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.hasBaseperiodforforecastid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated CampaignSimulatedBudgetRebuild campaignSimulatedBudgetRebuild = 6;
 * @return {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild>}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.getCampaignsimulatedbudgetrebuildList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild, 6));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild>} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.setCampaignsimulatedbudgetrebuildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.addCampaignsimulatedbudgetrebuild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.CampaignSimulatedBudgetRebuild, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result.prototype.clearCampaignsimulatedbudgetrebuildList = function() {
  return this.setCampaignsimulatedbudgetrebuildList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse} returns this
*/
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse} returns this
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.TypeCase}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categorytypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dynamiccategorysimulatedbudgetinitialbuildList: jspb.Message.toObjectList(msg.getDynamiccategorysimulatedbudgetinitialbuildList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorytype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 5:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.deserializeBinaryFromReader);
      msg.addDynamiccategorysimulatedbudgetinitialbuild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategorytypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDynamiccategorysimulatedbudgetinitialbuildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Rows rows = 2;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCategory nestCategory = 2;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryType = 3;
 * @return {!Array<string>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.getCategorytypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.setCategorytypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.addCategorytype = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.clearCategorytypeList = function() {
  return this.setCategorytypeList([]);
};


/**
 * optional string basePeriodForForecastId = 4;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.clearBaseperiodforforecastid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.hasBaseperiodforforecastid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated DynamicCategorySimulatedBudgetInitialBuild dynamicCategorySimulatedBudgetInitialBuild = 5;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.getDynamiccategorysimulatedbudgetinitialbuildList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild, 5));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.setDynamiccategorysimulatedbudgetinitialbuildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.addDynamiccategorysimulatedbudgetinitialbuild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.DynamicCategorySimulatedBudgetInitialBuild, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result.prototype.clearDynamiccategorysimulatedbudgetinitialbuildList = function() {
  return this.setDynamiccategorysimulatedbudgetinitialbuildList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.TypeCase}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categorytypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dynamiccategorysimulatedbudgetrebuildList: jspb.Message.toObjectList(msg.getDynamiccategorysimulatedbudgetrebuildList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorytype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 5:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.deserializeBinaryFromReader);
      msg.addDynamiccategorysimulatedbudgetrebuild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategorytypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDynamiccategorysimulatedbudgetrebuildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.prototype.clearTableList = function() {
  return this.setTableList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory;
  return proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows;
      reader.readMessage(value,proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Rows rows = 2;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NestCategory nestCategory = 2;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryType = 3;
 * @return {!Array<string>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.getCategorytypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.setCategorytypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.addCategorytype = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.clearCategorytypeList = function() {
  return this.setCategorytypeList([]);
};


/**
 * optional string basePeriodForForecastId = 4;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.clearBaseperiodforforecastid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.hasBaseperiodforforecastid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated DynamicCategorySimulatedBudgetRebuild dynamicCategorySimulatedBudgetRebuild = 5;
 * @return {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild>}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.getDynamiccategorysimulatedbudgetrebuildList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild, 5));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild>} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.setDynamiccategorysimulatedbudgetrebuildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.addDynamiccategorysimulatedbudgetrebuild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.DynamicCategorySimulatedBudgetRebuild, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result.prototype.clearDynamiccategorysimulatedbudgetrebuildList = function() {
  return this.setDynamiccategorysimulatedbudgetrebuildList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse} returns this
*/
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse} returns this
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.UpsertBasePeriodResponse.TypeCase}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.UpsertBasePeriodResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.UpsertBasePeriodResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.UpsertBasePeriodResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.UpsertBasePeriodResponse;
  return proto.adx.budget_simulation.UpsertBasePeriodResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.UpsertBasePeriodResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.UpsertBasePeriodResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    end: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 5, ""),
    version: jspb.Message.getFieldWithDefault(msg, 6, ""),
    revision: jspb.Message.getFieldWithDefault(msg, 7, 0),
    adpageid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.UpsertBasePeriodResponse.Result;
  return proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnd(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRevision(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRevision();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string start = 3;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end = 4;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.getEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.setEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actualMonth = 5;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string version = 6;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 revision = 7;
 * @return {number}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.setRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 adPageId = 8;
 * @return {number}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.Result.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.UpsertBasePeriodResponse.Result}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.UpsertBasePeriodResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.UpsertBasePeriodResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.UpsertBasePeriodResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse} returns this
*/
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.UpsertBasePeriodResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.UpsertBasePeriodResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.UpsertBasePeriodResponse} returns this
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.UpsertBasePeriodResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.UpsertBasePeriodResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.InsertBasePeriodResponse.TypeCase}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.InsertBasePeriodResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.InsertBasePeriodResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.InsertBasePeriodResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.InsertBasePeriodResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.InsertBasePeriodResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.InsertBasePeriodResponse;
  return proto.adx.budget_simulation.InsertBasePeriodResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.InsertBasePeriodResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.InsertBasePeriodResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.InsertBasePeriodResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.InsertBasePeriodResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.InsertBasePeriodResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.InsertBasePeriodResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.InsertBasePeriodResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    end: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 5, ""),
    version: jspb.Message.getFieldWithDefault(msg, 6, ""),
    revision: jspb.Message.getFieldWithDefault(msg, 7, 0),
    adpageid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.InsertBasePeriodResponse.Result;
  return proto.adx.budget_simulation.InsertBasePeriodResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnd(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRevision(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.InsertBasePeriodResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRevision();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string start = 3;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end = 4;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.getEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.setEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actualMonth = 5;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string version = 6;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 revision = 7;
 * @return {number}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.setRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 adPageId = 8;
 * @return {number}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse.Result} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.Result.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.InsertBasePeriodResponse.Result}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.InsertBasePeriodResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.InsertBasePeriodResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.InsertBasePeriodResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse} returns this
*/
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.InsertBasePeriodResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.InsertBasePeriodResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.InsertBasePeriodResponse} returns this
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.InsertBasePeriodResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.InsertBasePeriodResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.MonthlySimulatedVersionResponse.TypeCase}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.MonthlySimulatedVersionResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.MonthlySimulatedVersionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedVersionResponse;
  return proto.adx.budget_simulation.MonthlySimulatedVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthlysimulatedversionList: jspb.Message.toObjectList(msg.getMonthlysimulatedversionList(),
    proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result;
  return proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion;
      reader.readMessage(value,proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.deserializeBinaryFromReader);
      msg.addMonthlysimulatedversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthlysimulatedversionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseperiodforforecastid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    revision: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adpageid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion;
  return proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseperiodforforecastid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevision(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdpageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseperiodforforecastid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRevision();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdpageid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string basePeriodForForecastId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.getBaseperiodforforecastid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.setBaseperiodforforecastid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string revision = 3;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.getRevision = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.setRevision = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string adPageId = 4;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.getAdpageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MonthlySimulatedVersion monthlySimulatedVersion = 2;
 * @return {!Array<!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion>}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.prototype.getMonthlysimulatedversionList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion>} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result} returns this
*/
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.prototype.setMonthlysimulatedversionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.prototype.addMonthlysimulatedversion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.MonthlySimulatedVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result.prototype.clearMonthlysimulatedversionList = function() {
  return this.setMonthlysimulatedversionList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.MonthlySimulatedVersionResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse} returns this
*/
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.MonthlySimulatedVersionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.MonthlySimulatedVersionResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedVersionResponse} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.MonthlySimulatedVersionResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.MonthlySimulatedVersionResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.TypeCase}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    monthlysimulatedbudgetoverallinitialbuildList: jspb.Message.toObjectList(msg.getMonthlysimulatedbudgetoverallinitialbuildList(),
    proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild;
      reader.readMessage(value,proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.deserializeBinaryFromReader);
      msg.addMonthlysimulatedbudgetoverallinitialbuild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonthlysimulatedbudgetoverallinitialbuildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table;
      reader.readMessage(value,proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table>}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table>} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild} returns this
*/
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MonthlySimulatedBudgetOverallInitialBuild monthlySimulatedBudgetOverallInitialBuild = 3;
 * @return {!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild>}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.getMonthlysimulatedbudgetoverallinitialbuildList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild>} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result} returns this
*/
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.setMonthlysimulatedbudgetoverallinitialbuildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.addMonthlysimulatedbudgetoverallinitialbuild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.MonthlySimulatedBudgetOverallInitialBuild, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result.prototype.clearMonthlysimulatedbudgetoverallinitialbuildList = function() {
  return this.setMonthlysimulatedbudgetoverallinitialbuildList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse} returns this
*/
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.TypeCase}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result;
      reader.readMessage(value,proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    monthlysimulatedbudgetoverallrebuildList: jspb.Message.toObjectList(msg.getMonthlysimulatedbudgetoverallrebuildList(),
    proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild;
      reader.readMessage(value,proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.deserializeBinaryFromReader);
      msg.addMonthlysimulatedbudgetoverallrebuild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonthlysimulatedbudgetoverallrebuildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table;
      reader.readMessage(value,proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table;
  return proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table>}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table, 2));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table>} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild} returns this
*/
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MonthlySimulatedBudgetOverallRebuild monthlySimulatedBudgetOverallRebuild = 3;
 * @return {!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild>}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.getMonthlysimulatedbudgetoverallrebuildList = function() {
  return /** @type{!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild, 3));
};


/**
 * @param {!Array<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild>} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result} returns this
*/
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.setMonthlysimulatedbudgetoverallrebuildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.addMonthlysimulatedbudgetoverallrebuild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.MonthlySimulatedBudgetOverallRebuild, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result.prototype.clearMonthlysimulatedbudgetoverallrebuildList = function() {
  return this.setMonthlysimulatedbudgetoverallrebuildList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result, 1));
};


/**
 * @param {?proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.Result|undefined} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse} returns this
*/
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse} returns this
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.budget_simulation);
