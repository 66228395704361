import {
    createActionLog,
    deleteActionLog,
    deleteActionLogFile,
    getActionLogs,
    // getActionLogFiles,
    updateActionLog,
    saveActionLogsOrder,
} from '../../com/grpc/seamless_action_log.js';

import { getAdPagesList } from '../../com/grpc/ad_pages.js';
import { getTabListOfAdProviderReportPage } from '../../com/grpc/ad_provider.js';
// -- data -----------------------------
import * as tacklebox from '../../tacklebox/core/index.js';
import { TimeYmdRange } from '../../tacklebox/date/TimeYmdRange.js';
import { TimeYmd } from '../../tacklebox/date/TimeYmd.js';

import { AutoFileUploader } from '../../components/AutoFileUploader.js';

import { getInformationLatestUpdate } from '../../com/grpc/information.js';
import { data2dateandweek } from '../../tacklebox/date/time.js';
import { getCustomReportPages } from '../../com/grpc/custom_report.js';

const FilterValueType = {
    text: 0,
    select: 1,
    date: 2,
};

const FilterType = {
    is: 0,
    isNot: 1,
    contains: 2,
    doesNotContain: 3,
    startsWith: 4,
    endsWith: 5,
    isEmpty: 6,
    isNotEmpty: 7,
    FromTo: 8,
};


function FilterSelectValue(id, title) {
    this.id = id;
    this.title = title;
}

function FilterBetweenDateValue(start, end) {
    this.start = start;
    this.end = end;
}

function FilterValueCandidate(id, title) {
    this.id = id;
    this.title = title;
}

function FilterItem(id, title) {
    this.id = id;
    this.title = title;
}

function Filter(
    id,
    title,
    type = FilterValueType,
    value = null,
    filterTypeCandidates = [],
    selectedFilterType = null,
    valueCandidates = []
) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.value = value;
    this.selectedFilterType = selectedFilterType;
    this.filterTypeCandidates = filterTypeCandidates;
    this.valueCandidates = valueCandidates;
}
Filter.prototype.setFilterType = function (filter) {
    for (let i in this.candidates) {
        let f = this.candidates[i];
        if (f == filter) {
            this.selectedFilter = filter;
        }
    }
};
Filter.prototype.getFilterType2Title = function (filterType) {
    let title = '';

    switch (filterType) {
        case FilterType.is:
            title = '等しい';
            break;
        case FilterType.isNot:
            title = '等しくない';
            break;
        case FilterType.contains:
            title = '含む';
            break;
        case FilterType.doesNotContain:
            title = '含まれない';
            break;
        case FilterType.startsWith:
            title = 'で始まる';
            break;
        case FilterType.endsWith:
            title = 'で終わる';
            break;
        case FilterType.isEmpty:
            title = '空白';
            break;
        case FilterType.isNotEmpty:
            title = '空白ではない';
            break;
        case FilterType.FromTo:
            title = '期間';
            break;
    }

    return title;
};
Filter.prototype.getFilterItems = function () {
    let items = [];

    for (let i in this.filterTypeCandidates) {
        let c = this.filterTypeCandidates[i];
        items.push(new FilterItem(c, this.getFilterType2Title(c)));
    }
    return items;
};
Filter.prototype.getSelectedFilterItem = function () {
    let items = this.getFilterItems();
    if (this.selectedFilterType >= 0) {
        for (let i in items) {
            if (items[i].id == this.selectedFilterType) {
                return items[i];
            }
        }
    }

    if (items.length > 0) {
        return items[0];
    }

    return null;
};
Filter.prototype.selectFilterItem = function (filtertype) {
    let items = this.getFilterItems();
    for (let i in items) {
        if (items[i].id == filtertype) {
            this.selectedFilterType = filtertype;
            return;
        }
    }
};
Filter.prototype.selectValue = function (id) {
    for (let i in this.valueCandidates) {
        if (this.valueCandidates[i].id == id) {
            this.value = this.valueCandidates[i];
        }
    }
};

function Sort(title, direction, key = '') {
    this.title = title;
    this.direction = direction;
    this.key = key;
}

function FilterMasterRecord(
    id,
    title,
    key = '',
    type = FilterValueType.text,
    filterTypeCandidates = [],
    valueCandidates = []
) {
    this.id = id;
    this.title = title;
    this.key = key;
    this.type = type;
    this.filterTypeCandidates = filterTypeCandidates;
    this.valueCandidates = valueCandidates;
}

function ActionLogAutoFileUploader(id, advertizerId, user) {
    AutoFileUploader.call(this);
    this.id = id;
    this.advertizerId = advertizerId;
    this.user = user;
}
tacklebox.extend(ActionLogAutoFileUploader, AutoFileUploader);
ActionLogAutoFileUploader.prototype._onUpload = function (file) {
    let self = this;

    const formData = new FormData();
    formData.append('file', file);

    let header = {
        'X-API-Version': '2',
        Authorization: 'Bearer ' + this.user.auth0.token,
        'X-AdvertizerId': this.advertizerId,
        'X-Auth0-UUID': this.user.auth0.userUuid,
        'X-Timezone': 'Asia/Tokyo',
    };
    let requestOptions = {
        method: 'POST',
        body: formData,
        headers: header,
    };

    fetch(
        process.env.VUE_APP_RESTFUL_HOST +
            '/api/upstream-process-action-logs/' +
            this.id +
            '/files',
        requestOptions
    )
        .then((response) => response.json())
        .then(function (json) {
            if (json) {
                //
            }
            self.onUploaded();
        })
        .catch(function (ex) {
            console.log(ex);
            self.onUploaded();
        });
};

function LogFileInfo(id, advertizerId, user, callback, deletecallback, raw) {
    this.id = id;
    this.advertizerId = advertizerId;
    this.user = user;
    this.raw = null;
    this.callback = callback;
    this.deletecallback = deletecallback;
    if (raw) {
        this.raw = raw;
    }
}
LogFileInfo.prototype.getFileName = function () {
    if (this.raw) {
        return this.raw['filename'];
    }

    return;
};
LogFileInfo.prototype.getFileUrl = function () {
    if (this.raw) {
        return this.raw['signedurl'];
    }

    return;
};
LogFileInfo.prototype.delete = function () {
    let request = {};
    request.advertizerId = this.advertizerId;
    request.token = this.user.auth0.token;
    let self = this;
    let cuuid = deleteActionLogFile(
        this.id,
        request,
        function (uuid, data) {
            if (data != null && cuuid == uuid) {
                self.raw = data.result.data;
                if (self.deletecallback) {
                    self.deletecallback();
                }
            }
        },
        () => {},
        '',
        this.currentPageStreams
    );
};
LogFileInfo.prototype.load = function () {
    //機能をつぶす
};

function todayYmd() {
    return new TimeYmdRange(2021, 5, 1, 2021, 5, 1);
    //return "2021/05/01";
}

function ActionLog(
    id = null,
    status = 'todo',
    implementationStart = '2021-01-01',
    implementationEnd = '2021-01-05',
    adProvider = '',
    purpose = '',
    detail = '',
    validationStart = '2021-02-02',
    validationEnd = '2021-02-02',
    result = 'n/a',
    detailOfResult = '',
    nextAction = '',
    attachments = [],
    title = '',
    advertizerId,
    tags = [],
    note = ''
) {
    this.id = id;
    this.status = status;
    this.implementationStart = implementationStart;
    this.implementationEnd = implementationEnd;
    this.adProvider = adProvider;
    this.purpose = purpose;
    this.detail = detail;
    this.validationStart = validationStart;
    this.validationEnd = validationEnd;
    this.result = result;
    this.detailOfResult = detailOfResult;
    this.nextAction = nextAction;
    this.attachments = attachments;
    this.title = title;
    this.advertizerId = advertizerId;
    this.tags = tags;
    this.note = note;
}

function EditableItem(id, value, callback) {
    this.mode = 0;
    this.id = id;
    this.value = value;
    this.callback = callback;
    this.editValue = value;
}
EditableItem.prototype.isEdit = function () {
    return this.mode == 1;
};
EditableItem.prototype.edit = function () {
    if (this.mode == 0) {
        this.mode = 1;
    }
};
EditableItem.prototype._onDecide = function () {};
EditableItem.prototype.decide = function () {
    this.mode = 0;
    this._onDecide();
    this.callback(this);
};
EditableItem.prototype.label = function () {
    if (this.mode == 0) {
        this.mode = 1;
    }
};
EditableItem.prototype.setValue = function (value) {
    this.value = value;
    this.mode = 0;
};
EditableItem.prototype.setValueNoModeChange = function (value) {
    this.value = value;
};

function EditableStringItem(id, value, callback) {
    EditableItem.call(this, id, value, callback);
    this.inputData = this.value;
}
tacklebox.extend(EditableStringItem, EditableItem);
EditableStringItem.prototype.getString = function () {
    //return this.value.replace('\r\n', '\r').replace('\r', '\n').replace('\n', '<br>');
    return this.value;
};
EditableStringItem.prototype._onDecide = function () {
    this.value = this.inputData;
};
EditableItem.prototype.setInputValue = function (value) {
    this.inputData = value;
};

function EditableStatusSelectItem(id, value, callback, options) {
    EditableItem.call(this, id, value, callback);
    this.inputData = this.value;
    this.options = options;
}
tacklebox.extend(EditableStatusSelectItem, EditableItem);
EditableStatusSelectItem.prototype.getStatusTitle = function () {
    let result = '-';
    try {
        for (let key in this.options) {
            if (this.options[key].id == this.value) {
                return this.options[key].name;
            }
        }
    } catch (ex) {
        console.log(ex);
    }

    return result;
};

function ActionLogRecord(
    id = null,
    status = 'todo',
    implementationDate = todayYmd(),
    adProvider = '',
    purpose = '',
    detail = '',
    validationDate = todayYmd(),
    result = 'n/a',
    detailOfResult = '',
    nextAction = '',
    attachments = [],
    title = '',
    advertizerId,
    user,
    tags,
    note
) {
    let self = this;
    function update() {
        self.refresh(
            id,
            self.status.value,
            self.implementationDate,
            self.adProvider.value,
            self.purpose.value,
            self.detail.value,
            self.validationDate,
            self.result.value,
            self.detailOfResult.value,
            self.nextAction.value,
            self.attachments,
            self.title.value,
            self.tagSltr.value,
            self.note.value
        );
    }
    this.id = id;
    this.deleter = new EditableStatusSelectItem(
        'deleter',
        '',
        function () {
            if (this.value == 'delete') {
                self.refresh(
                    self.id,
                    this.value,
                    self.implementationDate,
                    self.adProvider.value,
                    self.purpose.value,
                    self.detail.value,
                    self.validationDate,
                    self.result.value,
                    self.detailOfResult.value,
                    self.nextAction.value,
                    self.attachments,
                    self.title.value,
                    self.tagSltr.value,
                    self.note.value
                );
                self.status.value = 'delete';
            }
            if (this.value == 'copy') {
                self.clipboardCopyLog();
            }
        },
        [
            {
                id: 'delete',
                name: '削除',
            },
            {
                id: 'copy',
                name: 'コピー',
            },
        ]
    );

    this.status = new EditableStatusSelectItem('status', status, update, [
        {
            id: 'todo',
            name: '実施予定',
        },
        {
            id: 'in-progress',
            name: '実施中',
        },
        {
            id: 'done',
            name: '実施済み',
        },
        // {
        //     id: "delete",
        //     name: "削除"
        // },
    ]);
    if (user.nowRole() == 'advertizer') {
        this.status.mode = 99;
    }
    this.implementationDate = new TimeYmdRange(
        implementationDate.startYmd.year,
        implementationDate.startYmd.month,
        implementationDate.startYmd.day,
        implementationDate.endYmd.year,
        implementationDate.endYmd.month,
        implementationDate.endYmd.day
    );
    this.implementationDate.subject.addObserver(function () {
        update();
    });
    // this.adProvider = new EditableStringItem("adProvider", adProvider, update);
    this.adProvider = new EditableStatusSelectItem(
        'adProvider',
        adProvider,
        update,
        this.adProviders
    );
    if (user.nowRole() == 'advertizer') {
        this.adProvider.mode = 99;
    }

    function _onChangePurpose() {
        self.onChange(self.id, 'purpose');
        update();
    }
    this.purpose = new EditableStringItem('purpose', purpose, _onChangePurpose);
    if (user.nowRole() == 'advertizer') {
        this.purpose.mode = 99;
    }

    function _onChangeDetail() {
        self.onChange(self.id, 'detail');
        update();
    }
    this.detail = new EditableStringItem('detail', detail, _onChangeDetail);
    if (user.nowRole() == 'advertizer') {
        this.detail.mode = 99;
    }

    this.validationDate = new TimeYmdRange(
        validationDate.startYmd.year,
        validationDate.startYmd.month,
        validationDate.startYmd.day,
        validationDate.endYmd.year,
        validationDate.endYmd.month,
        validationDate.endYmd.day
    );
    this.validationDate.subject.addObserver(function () {
        update();
    });

    this.result = new EditableStatusSelectItem('result', result, update, [
        {
            id: 'n/a',
            name: '―',
        },
        {
            id: 'good',
            name: '〇',
        },
        {
            id: 'poor',
            name: '△',
        },
        {
            id: 'bad',
            name: '×',
        },
    ]);
    if (user.nowRole() == 'advertizer') {
        this.result.mode = 99;
    }

    function _onChangeDetailOfResult() {
        self.onChange(self.id, 'detailOfResult');
        update();
    }
    this.detailOfResult = new EditableStringItem(
        'detailOfResult',
        detailOfResult,
        _onChangeDetailOfResult
    );
    if (user.nowRole() == 'advertizer') {
        this.detailOfResult.mode = 99;
    }

    function _onChangeNextAction() {
        self.onChange(self.id, 'nextAction');
        update();
    }
    this.nextAction = new EditableStringItem('nextAction', nextAction, _onChangeNextAction);
    if (user.nowRole() == 'advertizer') {
        this.nextAction.mode = 99;
    }

    function _onChangeTitle() {
        self.onChange(self.id, 'title');
        update();
    }
    this.title = new EditableStringItem('title', title, _onChangeTitle);
    if (user.nowRole() == 'advertizer') {
        this.title.mode = 99;
    }

    this.attachments = attachments;
    this.advertizerId = advertizerId;
    this.user = user;
    this.type = 1;

    this.files = [];
    this.filenames = [];

    for (let i in attachments) {
        this.files.push(
            this._onCreateLogFileInfo(
                attachments[i].id,
                advertizerId,
                user,
                function () {
                    self.onFileLoad();
                },
                function () {
                    self.onUploaded();
                },
                attachments[i]
            )
        );
    }
    this.uploader = this._onCreateAutoFileUploader(id, advertizerId, user);
    this.uploader.onUploaded = function () {
        self.onUploaded();
    };

    this.tagSltr = new EditableStatusSelectItem('tags', tags, update, [
        {
            id: '各論運用施策',
            name: '各論運用施策',
        },
        {
            id: '上流行程施策',
            name: '上流行程施策',
        },
        {
            id: 'コミュニケーション施策',
            name: 'コミュニケーション施策',
        },
        {
            id: '媒体',
            name: '媒体',
        },
        {
            id: 'ターゲット',
            name: 'ターゲット',
        },
        {
            id: '広告',
            name: '広告',
        },
        {
            id: 'LP',
            name: 'LP',
        },
        {
            id: '入札',
            name: '入札',
        },
        {
            id: 'KPI',
            name: 'KPI',
        },
        {
            id: 'その他',
            name: 'その他',
        },
    ]);
    function _onChangeNote() {
        self.onChange(self.id, 'note');
        update();
    }
    this.note = new EditableStringItem('note', note, _onChangeNote);
    if (user.nowRole() == 'advertizer') {
        this.note.mode = 99;
    }
}
ActionLogRecord.prototype._onCreateLogFileInfo = function (
    id,
    advertizerId,
    user,
    callback,
    deletecallback,
    raw
) {
    return new LogFileInfo(id, advertizerId, user, callback, deletecallback, raw);
};
ActionLogRecord.prototype._onCreateAutoFileUploader = function (id, advertizerId, user) {
    return new ActionLogAutoFileUploader(id, advertizerId, user);
};
ActionLogRecord.prototype.onFileLoad = function () {};
ActionLogRecord.prototype.onUploaded = function () {};
ActionLogRecord.prototype.onChange = function (id, key) {
    if (id || key) {
        //
    }
};
ActionLogRecord.prototype.clipboardCopyLog = function () {
    function result2Str(value) {
        let retstr = '';
        switch (value) {
            case 'good':
                retstr = '〇';
                break;
            case 'poor':
                retstr = '△';
                break;
            case 'bad':
                retstr = '×';
                break;
            default:
                retstr = '-';
                break;
        }
        return retstr;
    }

    // let text = (this.status.value);
    // text += ("\r\n\r\n" + this.implementationDate.startYmd.year + "/" + this.implementationDate.startYmd.month + "/" + this.implementationDate.startYmd.day);
    let text =
        this.implementationDate.startYmd.year +
        '/' +
        this.implementationDate.startYmd.month +
        '/' +
        this.implementationDate.startYmd.day;
    text += '\r\n\r\n' + this.adProvider.value;
    text += '\r\n\r\n' + this.purpose.value;
    text += '\r\n\r\n' + this.title.value;
    text += '\r\n\r\n' + this.detail.value;
    text +=
        '\r\n\r\n' +
        this.validationDate.startYmd.year +
        '/' +
        this.validationDate.startYmd.month +
        '/' +
        this.validationDate.startYmd.day;
    text += '\r\n\r\n' + result2Str(this.result.value);
    text += '\r\n\r\n' + this.detailOfResult.value;
    text += '\r\n\r\n' + this.nextAction.value;
    // self.implementationDate, self.adProvider.value, self.purpose.value, self.detail.value, self.validationDate, self.result.value, self.detailOfResult.value, self.nextAction.value, self.attachments, self.title.value

    let type = 'text/plain';
    let blob = new Blob([text], { type });
    let data = [new window.ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(
        function () {
            /* success */
        },
        function () {
            /* failure */
        }
    );
};
ActionLogRecord.prototype.refresh = function (
    id,
    status = null,
    implementationDate = todayYmd(),
    adProvider = null,
    purpose = '',
    detail = '',
    validationDate = todayYmd(),
    result = null,
    detailOfResult = null,
    nextAction = null,
    attachments = [],
    title,
    tags = [],
    note
) {
    if (id) {
        if (this.id == id) {
            if (status == 'delete') {
                let request = new ActionLog(
                    id,
                    status,
                    implementationDate.startYmd.getYmd(),
                    implementationDate.endYmd.getYmd(),
                    adProvider,
                    purpose,
                    detail,
                    validationDate.startYmd.getYmd(),
                    validationDate.endYmd.getYmd(),
                    result,
                    detailOfResult,
                    nextAction,
                    attachments,
                    title,
                    this.advertizerId,
                    tags,
                    note
                );
                this.currentPageStreams = [];
                request.advertizerId = this.advertizerId;
                request.token = this.user.auth0.token;
                let cuuid = deleteActionLog(
                    request,
                    function (uuid, data) {
                        if (data != null && cuuid == uuid) {
                            // let json = JSON.parse(JSON.stringify(data.result));
                            // if (!!(json.structure) != false) {
                            //     self.recordHolder.pushDataStream(json, json.requestId);
                            // }
                        }
                    },
                    () => {},
                    '',
                    this.currentPageStreams
                );
            } else {
                let request = new ActionLog(
                    id,
                    status,
                    implementationDate.startYmd.getYmd(),
                    implementationDate.endYmd.getYmd(),
                    adProvider,
                    purpose,
                    detail,
                    validationDate.startYmd.getYmd(),
                    validationDate.endYmd.getYmd(),
                    result,
                    detailOfResult,
                    nextAction,
                    attachments,
                    title,
                    this.advertizerId,
                    tags,
                    note
                );
                this.currentPageStreams = [];
                request.advertizerId = this.advertizerId;
                request.token = this.user.auth0.token;
                let cuuid = updateActionLog(
                    this.advertizerId,
                    request,
                    function (uuid, data) {
                        if (data != null && cuuid == uuid) {
                            // let json = JSON.parse(JSON.stringify(data.result));
                            // if (!!(json.structure) != false) {
                            //     self.recordHolder.pushDataStream(json, json.requestId);
                            // }
                        }
                    },
                    () => {},
                    '',
                    this.currentPageStreams
                );
            }
        }
    }
};
ActionLogRecord.prototype.adProviders = [];
ActionLogRecord.prototype.loadFiles = function () {
    for (let i in this.files) {
        this.files[i].load();
    }
};
ActionLogRecord.prototype.uploadFile = function (file) {
    if (file) {
        //
    }
};

function SelectedCell(id, key) {
    this.id = id;
    this.key = key;
}

function SeamlessActionLogPage() {
    this.upstreams = [];
    this.isNetworkingUpstream = false;

    this.selectedMode = 0;

    this.advertizerId = 0;

    this.nowYmd = new TimeYmd();

    this.user = null;

    this.selectedCells = [
        /**/
    ];

    this.isSelectRevers = false;

    this.keyEnterCell = null;

    this.adProviders = [];
    this.sorts = [];
    this.filters = [];
    this.filterandor = "and";
    this.isSummaryFilter = false;
    this.isSortFilter = false;
    this.currentPageStreams = [];
    this.filtermaster = [
        new FilterMasterRecord(
            10,
            'ステータス',
            'status',
            FilterValueType.select,
            [FilterType.is, FilterType.isNot],
            [
                new FilterValueCandidate('todo', '実施予定'),
                new FilterValueCandidate('in-progress', '実施中'),
                new FilterValueCandidate('done', '実施済み'),
            ]
        ),
        new FilterMasterRecord(
            20,
            'タグ',
            'tag',
            FilterValueType.select,
            [
                FilterType.contains,
                FilterType.doesNotContain,
                FilterType.isEmpty,
                FilterType.isNotEmpty,
            ],
            [
                new FilterValueCandidate('媒体', '媒体'),
                new FilterValueCandidate('ターゲット', 'ターゲット'),
                new FilterValueCandidate('広告', '広告'),
                new FilterValueCandidate('LP', 'LP'),
                new FilterValueCandidate('入札', '入札'),
                new FilterValueCandidate('KPI', 'KPI'),
                new FilterValueCandidate('その他', 'その他'),
            ]
        ),
        new FilterMasterRecord(30, '開始日', 'implementation_end', FilterValueType.date, [
            FilterType.FromTo,
        ]),
        new FilterMasterRecord(40, '媒体', 'ad_provider', FilterValueType.select, [
            FilterType.contains,
            FilterType.doesNotContain,
            FilterType.is,
            FilterType.isNot,
            FilterType.isEmpty,
            FilterType.isNotEmpty,
        ],
        []),
        new FilterMasterRecord(50, '検証仮説', 'purpose', FilterValueType.text, [
            FilterType.contains,
            FilterType.doesNotContain,
            FilterType.is,
            FilterType.isNot,
            FilterType.startsWith,
            FilterType.endsWith,
            FilterType.isEmpty,
            FilterType.isNotEmpty,
        ]),
        new FilterMasterRecord(60, '施策内容', 'title', FilterValueType.text, [
            FilterType.contains,
            FilterType.doesNotContain,
            FilterType.is,
            FilterType.isNot,
            FilterType.startsWith,
            FilterType.endsWith,
            FilterType.isEmpty,
            FilterType.isNotEmpty,
        ]),
        new FilterMasterRecord(70, '検証日', 'validation_end', FilterValueType.date, [
            FilterType.FromTo,
        ]),
        new FilterMasterRecord(80, '結果', 'result', FilterValueType.select, [
            FilterType.is,
            FilterType.isNot,
        ],
        [
            new FilterValueCandidate('n/a', '―'),
            new FilterValueCandidate('good', '〇'),
            new FilterValueCandidate('poor', '△'),
            new FilterValueCandidate('bad', '×'),
        ]),
        new FilterMasterRecord(90, '結果詳細', 'detail_of_result', FilterValueType.text, [
            FilterType.contains,
            FilterType.doesNotContain,
            FilterType.is,
            FilterType.isNot,
            FilterType.startsWith,
            FilterType.endsWith,
            FilterType.isEmpty,
            FilterType.isNotEmpty,
        ]),
        new FilterMasterRecord(
            100,
            'ファインディングス・ネクストアクション',
            'next_action',
            FilterValueType.text,
            [
                FilterType.contains,
                FilterType.doesNotContain,
                FilterType.is,
                FilterType.isNot,
                FilterType.startsWith,
                FilterType.endsWith,
                FilterType.isEmpty,
                FilterType.isNotEmpty,
            ]
        ),
        new FilterMasterRecord(120, '社内メモ', 'note', FilterValueType.text, [
            FilterType.contains,
            FilterType.doesNotContain,
            FilterType.is,
            FilterType.isNot,
            FilterType.startsWith,
            FilterType.endsWith,
            FilterType.isEmpty,
            FilterType.isNotEmpty,
        ]),
        new FilterMasterRecord(110, '参照データ', 'filename', FilterValueType.text, []),
    ];
}
SeamlessActionLogPage.prototype.onUpstreamCallback = function () {};
SeamlessActionLogPage.prototype.onMicroCallback = function () {};
SeamlessActionLogPage.prototype.onCommunicationCallback = function () {};
SeamlessActionLogPage.prototype.copyClipboradSelectedCell = function () {
    let text = '';

    /*
    function _key2title(key) {
        let title = "";
        switch (key) {
            case "implementationDate":
                title = "【開始日】";
                break;
            case "adProvider":
                title = "【媒体】";
                break;
            case "purpose":
                title = "【検証仮説】";
                break;
            case "title":
                title = "【施策内容(タイトル)】";
                break;
            case "detail":
                title = "【施策内容(本文)】";
                break;
            case "validationDate":
                title = "【検証日】";
                break;
            case "result":
                title = "【結果】";
                break;
            case "detailOfResult":
                title = "【結果詳細】";
                break;
            case "nextAction":
                title = "【ファインディングス・ネクストアクション】";
                break;
        }
        return title;
    }
    */

    function result2Str(value) {
        let retstr = '';
        switch (value) {
            case 'good':
                retstr = '〇';
                break;
            case 'poor':
                retstr = '△';
                break;
            case 'bad':
                retstr = '×';
                break;
            default:
                retstr = '-';
                break;
        }
        return retstr;
    }

    function _key2value(key, item) {
        let value = '';

        switch (key) {
            case 'implementationDate':
                value = item[key].endYmd.getYyyymmddString();
                break;
            case 'validationDate':
                value = item[key].endYmd.getYyyymmddString();
                break;
            case 'result':
                value = result2Str(item[key].value);
                break;
            default:
                value = item[key].value;
                break;
        }

        return value;
    }

    if (this.selectedCells.length == 0) {
        return;
    }

    let scs = [];
    if (this.isSelectRevers) {
        if (this.selectedCells.length > 0) {
            for (let i = this.selectedCells.length - 1; i >= 0; i--) {
                scs.push(this.selectedCells[i]);
            }
        }
    } else {
        for (let i in this.selectedCells) {
            scs.push(this.selectedCells[i]);
        }
    }

    for (let i in scs) {
        let c = scs[i];

        if (c) {
            //
            for (let j in this.micros) {
                let mic = this.micros[j];
                if (mic.id == c.id) {
                    text += ''; //(_key2title(c.key) + "\r\n");
                    text += _key2value(c.key, mic) + '\r\n\r\n';
                }
            }

            for (let u in this.upstreams) {
                let upstream = this.upstreams[u];
                if (upstream.id == c.id) {
                    text += ''; //(_key2title(c.key) + "\r\n");
                    text += _key2value(c.key, upstream) + '\r\n\r\n';
                }
            }

            for (let ci in this.communications) {
                let communication = this.communications[ci];
                if (communication.id == c.id) {
                    text += ''; //(_key2title(c.key) + "\r\n");
                    text += _key2value(c.key, communication) + '\r\n\r\n';
                }
            }
        }
    }

    let type = 'text/plain';
    let blob = new Blob([text], { type });
    let data = [new window.ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(
        function () {
            /* success */
        },
        function () {
            /* failure */
        }
    );
};

SeamlessActionLogPage.prototype.getKeys = function () {
    let keys = [];

    keys.push('implementationDate');
    keys.push('adProvider');
    keys.push('purpose');
    keys.push('title');
    keys.push('detail');
    keys.push('validationDate');
    keys.push('result');
    keys.push('detailOfResult');
    keys.push('nextAction');

    return keys;
};
SeamlessActionLogPage.prototype.selectCell = function (id, key) {
    this.selectedCells = [];
    this.selectedCells.push(new SelectedCell(id, key));
};
SeamlessActionLogPage.prototype.resetSelectCell = function () {
    this.selectedCells = [];
    this.keyEnterCell = null;
};
SeamlessActionLogPage.prototype.saveKeyEnterCell = function (id, key) {
    this.keyEnterCell = new SelectedCell(id, key);
};
SeamlessActionLogPage.prototype.keyEntry = function (id, key) {
    if (this.selectedCells.length > 0) {
        let keys = [];

        keys.push('purpose');
        keys.push('title');
        keys.push('detail');
        keys.push('detailOfResult');
        keys.push('nextAction');

        let isHit = false;
        for (let i in keys) {
            if (keys[i] == key) {
                isHit = true;
                break;
            }
        }
        if (!isHit) {
            return;
        }

        if (
            this.keyEnterCell == null ||
            !(
                this.keyEnterCell.id == this.selectedCells[0].id &&
                this.keyEnterCell.key == this.selectedCells[0].key
            )
        ) {
            this.keyEnterCell = new SelectedCell(id, key);
            for (let i in this.micros) {
                let mic = this.micros[i];
                if (mic.id == id) {
                    this.selectedCells = [];
                    mic[key].edit();
                    break;
                }
            }

            for (let i in this.upstreams) {
                let upstream = this.upstreams[i];
                if (upstream.id == id) {
                    this.selectedCells = [];
                    upstream[key].edit();
                    break;
                }
            }

            for (let i in this.communications) {
                let communication = this.communications[i];
                if (communication.id == id) {
                    this.selectedCells = [];
                    communication[key].edit();
                    break;
                }
            }
        }
    }
};
SeamlessActionLogPage.prototype.keydown = function () {
    if (this.selectedCells.length > 0) {
        this.isSelectRevers = false;
        this.keyEnterCell = null;

        let sctarget = this.selectedCells[0];
        for (let i = 0; i < this.micros.length; i++) {
            let micro = this.micros[i];
            if (sctarget.id == micro.id) {
                if (this.selectedMode == 0) {
                    for (let j = i + 1; j < this.micros.length; j++) {
                        if (this.micros[j].status.value != 'done') {
                            this.selectedCells = [];
                            this.selectedCells.push(
                                new SelectedCell(this.micros[j].id, sctarget.key)
                            );
                            break;
                        }
                    }
                } else {
                    for (let j = i + 1; j < this.micros.length; j++) {
                        if (this.micros[j].status.value == 'done') {
                            this.selectedCells = [];
                            this.selectedCells.push(
                                new SelectedCell(this.micros[j].id, sctarget.key)
                            );
                            break;
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.upstreams.length; i++) {
            let upstream = this.upstreams[i];
            if (sctarget.id == upstream.id) {
                if (this.selectedMode == 0) {
                    for (let j = i + 1; j < this.upstreams.length; j++) {
                        if (this.upstreams[j].status.value != 'done') {
                            this.selectedCells = [];
                            this.selectedCells.push(
                                new SelectedCell(this.upstreams[j].id, sctarget.key)
                            );
                            break;
                        }
                    }
                } else {
                    for (let j = i + 1; j < this.upstreams.length; j++) {
                        if (this.upstreams[j].status.value == 'done') {
                            this.selectedCells = [];
                            this.selectedCells.push(
                                new SelectedCell(this.upstreams[j].id, sctarget.key)
                            );
                            break;
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.communications.length; i++) {
            let communication = this.communications[i];
            if (sctarget.id == communication.id) {
                if (this.selectedMode == 0) {
                    for (let j = i + 1; j < this.communications.length; j++) {
                        if (this.communications[j].status.value != 'done') {
                            this.selectedCells = [];
                            this.selectedCells.push(
                                new SelectedCell(this.communications[j].id, sctarget.key)
                            );
                            break;
                        }
                    }
                } else {
                    for (let j = i + 1; j < this.communications.length; j++) {
                        if (this.communications[j].status.value == 'done') {
                            this.selectedCells = [];
                            this.selectedCells.push(
                                new SelectedCell(this.communications[j].id, sctarget.key)
                            );
                            break;
                        }
                    }
                }
                break;
            }
        }
    }
};
SeamlessActionLogPage.prototype.keyup = function () {
    if (this.selectedCells.length > 0) {
        this.isSelectRevers = false;
        this.keyEnterCell = null;

        let sctarget = this.selectedCells[0];

        for (let i = 0; i < this.micros.length; i++) {
            let micro = this.micros[i];
            if (sctarget.id == micro.id) {
                if (i > 0) {
                    if (this.selectedMode == 0) {
                        for (let j = i - 1; j >= 0; j--) {
                            if (this.micros[j].status.value != 'done') {
                                this.selectedCells = [];
                                this.selectedCells.push(
                                    new SelectedCell(this.micros[j].id, sctarget.key)
                                );
                                break;
                            }
                        }
                    } else {
                        for (let j = i - 1; j >= 0; j--) {
                            if (this.micros[j].status.value == 'done') {
                                this.selectedCells = [];
                                this.selectedCells.push(
                                    new SelectedCell(this.micros[j].id, sctarget.key)
                                );
                                break;
                            }
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.upstreams.length; i++) {
            let upstream = this.upstreams[i];
            if (sctarget.id == upstream.id) {
                if (i > 0) {
                    if (this.selectedMode == 0) {
                        for (let j = i - 1; j >= 0; j--) {
                            if (this.upstreams[j].status.value != 'done') {
                                this.selectedCells = [];
                                this.selectedCells.push(
                                    new SelectedCell(this.upstreams[j].id, sctarget.key)
                                );
                                break;
                            }
                        }
                    } else {
                        for (let j = i - 1; j >= 0; j--) {
                            if (this.upstreams[j].status.value == 'done') {
                                this.selectedCells = [];
                                this.selectedCells.push(
                                    new SelectedCell(this.upstreams[j].id, sctarget.key)
                                );
                                break;
                            }
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.communications.length; i++) {
            let communication = this.communications[i];
            if (sctarget.id == communication.id) {
                if (i > 0) {
                    if (this.selectedMode == 0) {
                        for (let j = i - 1; j >= 0; j--) {
                            if (this.communications[j].status.value != 'done') {
                                this.selectedCells = [];
                                this.selectedCells.push(
                                    new SelectedCell(this.communications[j].id, sctarget.key)
                                );
                                break;
                            }
                        }
                    } else {
                        for (let j = i - 1; j >= 0; j--) {
                            if (this.communications[j].status.value == 'done') {
                                this.selectedCells = [];
                                this.selectedCells.push(
                                    new SelectedCell(this.communications[j].id, sctarget.key)
                                );
                                break;
                            }
                        }
                    }
                }

                break;
            }
        }
    }
};
SeamlessActionLogPage.prototype.keyleft = function () {
    if (this.selectedCells.length > 0) {
        //
        this.isSelectRevers = false;
        this.keyEnterCell = null;
        let sctarget = this.selectedCells[0];

        for (let i = 0; i < this.micros.length; i++) {
            let micro = this.micros[i];
            if (sctarget.id == micro.id) {
                let keys = this.getKeys();
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j] == sctarget.key) {
                        if (j > 0) {
                            this.selectedCells = [];
                            this.selectedCells.push(new SelectedCell(micro.id, keys[j - 1]));
                            break;
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.upstreams.length; i++) {
            let upstream = this.upstreams[i];
            if (sctarget.id == upstream.id) {
                let keys = this.getKeys();
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j] == sctarget.key) {
                        if (j > 0) {
                            this.selectedCells = [];
                            this.selectedCells.push(new SelectedCell(upstream.id, keys[j - 1]));
                            break;
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.communications.length; i++) {
            let communication = this.communications[i];
            if (sctarget.id == communication.id) {
                let keys = this.getKeys();
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j] == sctarget.key) {
                        if (j > 0) {
                            this.selectedCells = [];
                            this.selectedCells.push(
                                new SelectedCell(communication.id, keys[j - 1])
                            );
                            break;
                        }
                    }
                }
                break;
            }
        }
    }
};
SeamlessActionLogPage.prototype.keyleftShift = function () {
    if (this.selectedCells.length > 0) {
        this.keyEnterCell = null;
        if (this.selectedCells.length == 1) {
            this.isSelectRevers = true;
        }
        if (this.isSelectRevers) {
            let sctarget = this.selectedCells[this.selectedCells.length - 1];

            for (let i = 0; i < this.micros.length; i++) {
                let micro = this.micros[i];
                if (sctarget.id == micro.id) {
                    let keys = this.getKeys();
                    for (let j = 0; j < keys.length; j++) {
                        if (keys[j] == sctarget.key) {
                            if (j > 0) {
                                this.selectedCells.push(new SelectedCell(micro.id, keys[j - 1]));
                                break;
                            }
                        }
                    }
                    break;
                }
            }

            for (let i = 0; i < this.upstreams.length; i++) {
                let upstream = this.upstreams[i];
                if (sctarget.id == upstream.id) {
                    let keys = this.getKeys();
                    for (let j = 0; j < keys.length; j++) {
                        if (keys[j] == sctarget.key) {
                            if (j > 0) {
                                this.selectedCells.push(new SelectedCell(upstream.id, keys[j - 1]));
                                break;
                            }
                        }
                    }
                    break;
                }
            }

            for (let i = 0; i < this.communications.length; i++) {
                let communication = this.communications[i];
                if (sctarget.id == communication.id) {
                    let keys = this.getKeys();
                    for (let j = 0; j < keys.length; j++) {
                        if (keys[j] == sctarget.key) {
                            if (j > 0) {
                                this.selectedCells.push(
                                    new SelectedCell(communication.id, keys[j - 1])
                                );
                                break;
                            }
                        }
                    }
                    break;
                }
            }

            return;
        }

        if (this.selectedCells.length > 1) {
            this.selectedCells.pop();
        }
    }
};
SeamlessActionLogPage.prototype.keyright = function () {
    if (this.selectedCells.length > 0) {
        //
        this.isSelectRevers = false;
        this.keyEnterCell = null;

        let sctarget = this.selectedCells[0];

        for (let i = 0; i < this.micros.length; i++) {
            let micro = this.micros[i];
            if (sctarget.id == micro.id) {
                let keys = this.getKeys();
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j] == sctarget.key) {
                        if (j < keys.length - 1) {
                            this.selectedCells = [];
                            this.selectedCells.push(new SelectedCell(micro.id, keys[j + 1]));
                            break;
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.upstreams.length; i++) {
            let upstream = this.upstreams[i];
            if (sctarget.id == upstream.id) {
                let keys = this.getKeys();
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j] == sctarget.key) {
                        if (j < keys.length - 1) {
                            this.selectedCells = [];
                            this.selectedCells.push(new SelectedCell(upstream.id, keys[j + 1]));
                            break;
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.communications.length; i++) {
            let communication = this.communications[i];
            if (sctarget.id == communication.id) {
                let keys = this.getKeys();
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j] == sctarget.key) {
                        if (j < keys.length - 1) {
                            this.selectedCells = [];
                            this.selectedCells.push(
                                new SelectedCell(communication.id, keys[j + 1])
                            );
                            break;
                        }
                    }
                }
                break;
            }
        }
    }
};
SeamlessActionLogPage.prototype.keyrightShift = function () {
    if (this.selectedCells.length > 0) {
        this.keyEnterCell = null;
        if (this.selectedCells.length == 1) {
            this.isSelectRevers = false;
        }

        if (this.isSelectRevers) {
            this.selectedCells.pop();
            return;
        }

        let sctarget = this.selectedCells[this.selectedCells.length - 1];

        for (let i = 0; i < this.micros.length; i++) {
            let micro = this.micros[i];
            if (sctarget.id == micro.id) {
                let keys = this.getKeys();
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j] == sctarget.key) {
                        if (j < keys.length - 1) {
                            this.selectedCells.push(new SelectedCell(micro.id, keys[j + 1]));
                            break;
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.upstreams.length; i++) {
            let upstream = this.upstreams[i];
            if (sctarget.id == upstream.id) {
                let keys = this.getKeys();
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j] == sctarget.key) {
                        if (j < keys.length - 1) {
                            this.selectedCells.push(new SelectedCell(upstream.id, keys[j + 1]));
                            break;
                        }
                    }
                }
                break;
            }
        }

        for (let i = 0; i < this.communications.length; i++) {
            let communication = this.communications[i];
            if (sctarget.id == communication.id) {
                let keys = this.getKeys();
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j] == sctarget.key) {
                        if (j < keys.length - 1) {
                            this.selectedCells.push(
                                new SelectedCell(communication.id, keys[j + 1])
                            );
                            break;
                        }
                    }
                }
                break;
            }
        }
    }
};

SeamlessActionLogPage.prototype.updateDate = function (id, range) {
    for (let i in this.upstreams) {
        if (this.upstreams[i].id == id) {
            switch (range.mode) {
                case 1:
                    this.upstreams[i].implementationDate.setRangeYmd(
                        range.startYmd.year,
                        range.startYmd.month,
                        range.startYmd.day,
                        range.endYmd.year,
                        range.endYmd.month,
                        range.endYmd.day
                    );
                    break;
                case 2:
                    this.upstreams[i].validationDate.setRangeYmd(
                        range.startYmd.year,
                        range.startYmd.month,
                        range.startYmd.day,
                        range.endYmd.year,
                        range.endYmd.month,
                        range.endYmd.day
                    );
                    break;
            }
            break;
        }
    }
    for (let i in this.communications) {
        if (this.communications[i].id == id) {
            switch (range.mode) {
                case 1:
                    this.communications[i].implementationDate.setRangeYmd(
                        range.startYmd.year,
                        range.startYmd.month,
                        range.startYmd.day,
                        range.endYmd.year,
                        range.endYmd.month,
                        range.endYmd.day
                    );
                    break;
                case 2:
                    this.communications[i].validationDate.setRangeYmd(
                        range.startYmd.year,
                        range.startYmd.month,
                        range.startYmd.day,
                        range.endYmd.year,
                        range.endYmd.month,
                        range.endYmd.day
                    );
                    break;
            }
            break;
        }
    }
    for (let i in this.micros) {
        if (this.micros[i].id == id) {
            switch (range.mode) {
                case 1:
                    this.micros[i].implementationDate.setRangeYmd(
                        range.startYmd.year,
                        range.startYmd.month,
                        range.startYmd.day,
                        range.endYmd.year,
                        range.endYmd.month,
                        range.endYmd.day
                    );
                    break;
                case 2:
                    this.micros[i].validationDate.setRangeYmd(
                        range.startYmd.year,
                        range.startYmd.month,
                        range.startYmd.day,
                        range.endYmd.year,
                        range.endYmd.month,
                        range.endYmd.day
                    );
                    break;
            }
            break;
        }
    }
};
SeamlessActionLogPage.prototype.initialize = function (advertizerId, todayYmd, user) {
    this.advertizerId = Number(advertizerId);
    this.user = user;
    this.nowYmd = todayYmd;
    this.reloadAdproviders();
};
SeamlessActionLogPage.prototype.onfileLoad = function (item) {
    if (item) {
        //
    }
};
SeamlessActionLogPage.prototype.grpcLoad = function () {
    this.currentPageStreams1 = [];
    this.currentPageStreams2 = [];
    this.currentPageStreams3 = [];
    this.currentPageStreams4 = [];
    this.currentPageStreams5 = [];
    this.currentPageStreams6 = [];
    this.currentPageStreams10 = [];
    this.currentPageStreams11 = [];
    this.currentPageStreams12 = [];

    this.isLoadMicroActionLog = true;
    this.isLoadUpstreamProcessActionLog = true;
    this.isLoadCommunicationActionLog = true;

    this.keyEnterCell = null;

    this.cancelAllAPICall();
};

SeamlessActionLogPage.prototype.cancelAllAPICall = function () {
    this.currentPageStreams1.forEach((stream) => stream.cancel());
    this.currentPageStreams2.forEach((stream) => stream.cancel());
    this.currentPageStreams3.forEach((stream) => stream.cancel());
    this.currentPageStreams4.forEach((stream) => stream.cancel());
    this.currentPageStreams5.forEach((stream) => stream.cancel());
    this.currentPageStreams6.forEach((stream) => stream.cancel());
};
SeamlessActionLogPage.prototype.registActionLog = function () {
    let self = this;
    let request = new ActionLog(
        null,
        'todo',
        this.nowYmd.getYmd(),
        this.nowYmd.getYmd(),
        '',
        '',
        '',
        this.nowYmd.getYmd(),
        this.nowYmd.getYmd(),
        'n/a',
        '',
        '',
        [],
        '',
        this.advertizerId,
        [],
        ''
    );
    this.currentPageStreams = [];
    request.advertizerId = this.advertizerId;
    request.token = this.user.auth0.token;
    delete request.id;
    let cuuid = createActionLog(
        this.advertizerId,
        request,
        function (uuid, data) {
            if (data != null && cuuid == uuid) {
                // let json = JSON.parse(JSON.stringify(data.result));
                // if (!!(json.structure) != false) {
                //     self.recordHolder.pushDataStream(json, json.requestId);
                // }
                self.upstreams = [];
                self.reloadLogDatas();
            }
        },
        () => {},
        '',
        this.currentPageStreams
    );
};

SeamlessActionLogPage.prototype.getInprogressActionLogs = function () {
    let upstreams = [];

    for (var key in this.upstreams) {
        let upstream = this.upstreams[key];
        if (this.selectedMode == 0) {
            if (upstream.status.value != 'done' && upstream.status.value != 'delete') {
                upstreams.push(upstream);
            }
        } else {
            if (upstream.status.value == 'done') {
                upstreams.push(upstream);
            }
        }
    }

    return upstreams;
};
SeamlessActionLogPage.prototype.tabselect = function (id) {
    this.selectedMode = id;
};

SeamlessActionLogPage.prototype.sortActionLog = function (target, src) {
    if (this.upstreams) {
        let isHit = false;
        let isCntUp = false;
        for (let i = 0; i < this.upstreams.length; i++) {
            let up = this.upstreams[i];
            if (up != src) {
                if (up == target) {
                    if (target.order > src.order) {
                        isCntUp = true;
                    }
                    src.order = target.order;
                    isHit = true;

                    if (!isCntUp) {
                        up.order++;
                    } else {
                        // src.order++;
                    }
                } else {
                    if (isHit) {
                        up.order++;
                    }
                }
            }
        }

        if (isHit) {
            this.refreshOrderUpstream();
            this.fixOrderUpstream();
        }
    }
};

SeamlessActionLogPage.prototype.refreshOrderActionLog = function () {
    if (this.upstreams) {
        this.upstreams.sort(function (a, b) {
            return a.order - b.order;
        });
    }
};

SeamlessActionLogPage.prototype.fixOrderActionLog = function () {
    if (this.upstreams) {
        let arr = [];
        for (let i = 0; i < this.upstreams.length; i++) {
            this.upstreams[i].order = i + 1;
            arr.push({
                id: this.upstreams[i].id,
                order: this.upstreams[i].order,
            });
        }

        let request = {
            token: this.user.auth0.token,
            userUuid: this.user.userUuid,
        };
        request.advertizerId = this.advertizerId;
        request.idors = arr;

        saveActionLogsOrder(
            this.advertizerId,
            request,
            function (uuid, data) {
                console.log(uuid, data);
            },
            () => {},
            '',
            this.currentPageStreams11
        );
    }
};

SeamlessActionLogPage.prototype.updateload = function (callback) {
    let request = {
        sortItem: '',
        sort: '',
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    const cuuid = getInformationLatestUpdate(this.advertizerId, request, function (uuid, data) {
        if (cuuid == uuid) {
            try {
                let json = JSON.parse(JSON.stringify(data.result));
                if (json?.latestupdate?.date) {
                    if (callback) {
                        callback(data2dateandweek(json.latestupdate.date));
                    }
                }
            } catch (ex) {
                console.log(ex);
            }
        }
    });
};
SeamlessActionLogPage.prototype.updatecstrp = function (callback) {
    let request = {
        sortItem: '',
        sort: '',
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };

    request.advertizerId = this.advertizerId;
    const cuid = getCustomReportPages(
        [],
        request,
        function (uid, data) {
            if (cuid == uid) {
                if (data) {
                    if (data?.result) {
                        let result = data?.result;
                        if (result?.pagesList) {
                            for (let i in result?.pagesList) {
                                if (result?.pagesList[i].order < 0) {
                                    callback(true);
                                    return;
                                }
                            }
                        }
                    }
                }
            }
        },
        function () {
            // if (callback) {
            //     callback();
            // }
        },
        null,
        this._currentPageStreams
    );
};

SeamlessActionLogPage.prototype.setSort = function (sort) {
    this.sorts.push(new Sort(sort, 1));
};
SeamlessActionLogPage.prototype.order = function (item, ad) {
    this.sorts = [];
    for (let i in this.filtermaster) {
        if (this.filtermaster[i].id == item) {
            this.sorts.push(new Sort(this.filtermaster[i].title, ad, this.filtermaster[i].key));
            this.reloadLogDatas();
            return;
        }
    }
};

SeamlessActionLogPage.prototype.addFilter = function (item) {
    for (let i in this.filtermaster) {
        if (this.filtermaster[i].id == item) {
            let fv = null;
            if (this.filtermaster[i].type == FilterValueType.select) {
                fv = new FilterSelectValue(this.filtermaster[i].valueCandidates[0].id, this.filtermaster[i].valueCandidates[0].title);
            }
            if (this.filtermaster[i].type == FilterValueType.text) {
                fv = "";
            }
            if (this.filtermaster[i].type == FilterValueType.date) {
                let sdate = new Date();
                sdate.setDate(1);
                let sy = sdate.getFullYear();
                let sm = sdate.getMonth() + 1;
                let sd = sdate.getDate();
                let symd = sy + "-" + sm + "-" + sd

                let edate = new Date();
                edate.setMonth(edate.getMonth() + 1);
                edate.setDate(0);
                let ey = edate.getFullYear();
                let em = edate.getMonth() + 1;
                let ed = edate.getDate();
                let eymd = ey + "-" + em + "-" + ed

                fv = new FilterBetweenDateValue(symd, eymd);
            }
            
            this.filters.push(
                new Filter(
                    this.filtermaster[i].id,
                    this.filtermaster[i].title,
                    this.filtermaster[i].type,
                    fv,
                    this.filtermaster[i].filterTypeCandidates,
                    this.filtermaster[i].filterTypeCandidates[0],
                    this.filtermaster[i].valueCandidates
                )
            );
            this.reloadLogDatas();
            break;
        }
    }
};
SeamlessActionLogPage.prototype.removeFilter = function (item) {
    let isDelete = false;
    for (let i = 0 ; i < this.filters.length ; i++) {
        if (item == this.filters[i]) {
            this.filters.splice(i, 1);
            isDelete = true;
        }
    }
    if (isDelete) {
        this.reloadLogDatas();
    }
};
SeamlessActionLogPage.prototype.changefilter = function (src, dst_id) {
    for (let x = 0 ; x < this.filters.length ; x++) {
        if (src == this.filters[x]) {
            for (let i in this.filtermaster) {
                if (this.filtermaster[i].id == dst_id) {
                    let fv = null;
                    if (this.filtermaster[i].type == FilterValueType.select) {
                        fv = new FilterSelectValue(this.filtermaster[i].valueCandidates[0].id, this.filtermaster[i].valueCandidates[0].title);
                    }
                    if (this.filtermaster[i].type == FilterValueType.text) {
                        fv = "";
                    }
                    if (this.filtermaster[i].type == FilterValueType.date) {
                        let sdate = new Date();
                        sdate.setDate(1);
                        let sy = sdate.getFullYear();
                        let sm = sdate.getMonth() + 1;
                        let sd = sdate.getDate();
                        let symd = sy + "-" + sm + "-" + sd
        
                        let edate = new Date();
                        edate.setMonth(edate.getMonth() + 1);
                        edate.setDate(0);
                        let ey = edate.getFullYear();
                        let em = edate.getMonth() + 1;
                        let ed = edate.getDate();
                        let eymd = ey + "-" + em + "-" + ed
        
                        fv = new FilterBetweenDateValue(symd, eymd);
                    }
                    
                    let ft = new Filter(
                            this.filtermaster[i].id,
                            this.filtermaster[i].title,
                            this.filtermaster[i].type,
                            fv,
                            this.filtermaster[i].filterTypeCandidates,
                            this.filtermaster[i].filterTypeCandidates[0],
                            this.filtermaster[i].valueCandidates
                        );

                    this.filters[x] = ft;

                    this.reloadLogDatas();
                    return ft;
                }
            }


            break;
        }
    }

    return null;
}
SeamlessActionLogPage.prototype.openSummaryFilter = function () {
    this.isSummaryFilter = true;
};
SeamlessActionLogPage.prototype.openSortFilter = function () {
    this.isSortFilter = true;
};
SeamlessActionLogPage.prototype.closeSummaryFilter = function () {
    this.isSummaryFilter = false;
};
SeamlessActionLogPage.prototype.closeSortFilter = function () {
    this.isSortFilter = false;
};
SeamlessActionLogPage.prototype.resetSort = function () {
    this.sorts = [];
};
SeamlessActionLogPage.prototype.resetFilter = function () {
    this.filters = [];
    this.reloadLogDatas();
};

SeamlessActionLogPage.prototype.reloadLogDatas = function () {
    const self = this;
    self.upstreams = [];
    let request = {};
    request.advertizerId = this.advertizerId;
    request.token = this.user.auth0.token;
    // let self = this;

    this.isNetworkingUpstream = true;

    if (this.sorts.length > 0) {
        request.sorts = [];
        let name = '+';
        if (this.sorts[0]?.direction) {
            if (this.sorts[0].direction == 1) {
                name = '-';
            }
        }
        name = name + this.sorts[0].key;
        request.sorts.push(name);
    }

    if (this.filters.length > 0) {
        request.filter = {};
        request.filter.operator = this.filterandor;
        request.filter.conditions = [];
        for (let i in this.filters) {
            let prop = "";
            let vl = "";
            let mth = "";
            switch(this.filters[i].id) {
                case 10:
                    prop = 'status';
                    vl = this.filters[i].value.id;
                    break;
                case 20:
                    prop = 'tag';
                    vl = this.filters[i].value.id;
                    break;
                case 30:
                    prop = 'implementationEnd';
                    vl = this.filters[i].value.start+","+this.filters[i].value.end
                    break;
                case 40:
                    prop = 'adProvider';
                    vl = this.filters[i].value.id;
                    break;
                case 50:
                    prop = 'purpose';
                    vl = this.filters[i].value;
                    break;
                case 60:
                    prop = 'title';
                    vl = this.filters[i].value;
                    break;
                case 70:
                    prop = 'validationEnd';
                    vl = this.filters[i].value.start+","+this.filters[i].value.end
                    break;
                case 80:
                    prop = 'result';
                    vl = this.filters[i].value.id;
                    break;
                case 90:
                    prop = 'detailOfResult';
                    vl = this.filters[i].value;
                    break;
                case 100:
                    prop = 'nextAction';
                    vl = this.filters[i].value;
                    break;
                case 120:
                    prop = 'note';
                    vl = this.filters[i].value;
                    break;
            }

            switch (this.filters[i].selectedFilterType) {
                case FilterType.is:
                    mth = 'is';
                    break;
                case FilterType.isNot:
                    mth = 'isNot';
                    break;
                case FilterType.contains:
                    mth = 'contains';
                    break;
                case FilterType.doesNotContain:
                    mth = 'doesNotContain';
                    break;
                case FilterType.startsWith:
                    mth = 'startsWith';
                    break;
                case FilterType.endsWith:
                    mth = 'endsWith';
                    break;
                case FilterType.isEmpty:
                    mth = 'isEmpty';
                    vl = " ";
                    break;
                case FilterType.isNotEmpty:
                    mth = 'isNotEmpty';
                    vl = " ";
                    break;
                case FilterType.FromTo:
                    mth = 'isBetween';
                    break;
            }
            if (prop.length > 0 && mth.length > 0 && vl != null && vl.length > 0) {
                request.filter.conditions.push(
                    {
                        property: prop,
                        value: vl,
                        match: mth
                    }
                );    
            }
        }

        if (request.filter.conditions.length == 0) {
            delete request.filter;
        }
    }
    let cuuid = getActionLogs(
        this.advertizerId,
        request,
        function (uuid, data) {
            if (data != null && cuuid == uuid) {
                if (data?.result) {
                    let json = JSON.parse(JSON.stringify(data.result));
                    for (let i in json.dataList) {
                        let data = json.dataList[i];
                        let is = new Date(data.implementationstart);
                        let ie = new Date(data.implementationend);
                        let imd = new TimeYmdRange(
                            is.getFullYear(),
                            is.getMonth() + 1,
                            is.getDate(),
                            ie.getFullYear(),
                            ie.getMonth() + 1,
                            ie.getDate()
                        );

                        let vs = new Date(data.validationstart);
                        let ve = new Date(data.validationend);
                        let vld = new TimeYmdRange(
                            vs.getFullYear(),
                            vs.getMonth() + 1,
                            vs.getDate(),
                            ve.getFullYear(),
                            ve.getMonth() + 1,
                            ve.getDate()
                        );

                        let a = new ActionLogRecord(
                            data.id,
                            data.status,
                            imd,
                            data.adprovider,
                            data.purpose,
                            data.detail,
                            vld,
                            data.result,
                            data.detailofresult,
                            data.nextaction,
                            data.attachmentsList,
                            data.title,
                            self.advertizerId,
                            self.user,
                            data.tagsList,
                            data.note
                        );
                        a.onChange = function (id, key) {
                            self.selectCell(id, key);
                        };
                        a.onFileLoad = function () {
                            if (self.onUpstreamCallback) {
                                self.onUpstreamCallback();
                            }
                        };
                        a.onUploaded = function () {
                            self.reloadLogDatas();
                        };
                        a.loadFiles();

                        try {
                            a.order = data.order;
                        } catch (ex) {
                            a.order = 99999;
                        }

                        self.upstreams.push(a);
                    }
                }
            }
        },
        () => {
            self.isNetworkingUpstream = false;
        },
        '',
        this.currentPageStreams
    );
};
SeamlessActionLogPage.prototype.reloadAdproviders = function () {
    const self = this;

    let request = {
        sortItem: '',
        sort: '',
        token: this.user.auth0.token,
    };

    request.advertizerId = this.advertizerId;
    getAdPagesList(this.advertizerId, request, function (uuid, data) {
        if (uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            for (let i in json.pagesList) {
                let page = json.pagesList[i];
                if (page.page == '媒体別実績') {
                    let arequest = {
                        sortItem: '',
                        sort: '',
                        token: self.user.auth0.token,
                        userUuid: self.user.userUuid,
                    };
                    arequest.advertizerId = self.advertizerId;
                    let cuuid = getTabListOfAdProviderReportPage(
                        page.pageid,
                        arequest,
                        function (uuid, data) {
                            if (cuuid == uuid) {
                                let json = JSON.parse(JSON.stringify(data.result));
                                self.adProviders = [];
                                for (let x in json.adproviderList) {
                                    let ad = json.adproviderList[x];
                                    self.adProviders.push({
                                        id: ad.provider,
                                        name: ad.provider,
                                    });
                                }
                                ActionLogRecord.prototype.adProviders = [];
                                for (let x in json.adproviderList) {
                                    let ad = json.adproviderList[x];
                                    ActionLogRecord.prototype.adProviders.push({ "id": ad.provider, "name": ad.provider });
                                }
                                for (let i in self.filtermaster) {
                                    let fm = self.filtermaster[i];
                                    if (fm.id == 40) {
                                        for (let x in json.adproviderList) {
                                            let ad = json.adproviderList[x];
                                            fm.valueCandidates.push(new FilterValueCandidate(ad.provider, ad.provider));
                                        }
                                        break;
                                    }

                                }
                                
                            }
                        },
                        function () {
                            self.reloadLogDatas();
                        }
                    );
                    return;
                }
            }
        }
    });
};
SeamlessActionLogPage.prototype.sortOrderUp = function (
) {
    if (this.sorts) {
        if (this.sorts.length > 0) {
            this.sorts[0].direction = 0;
            this.reloadLogDatas();
        }
    }
};
SeamlessActionLogPage.prototype.sortOrderDown = function () {
    if (this.sorts) {
        if (this.sorts.length > 0) {
            this.sorts[0].direction = 1;
            this.reloadLogDatas();
        }
    }
};
SeamlessActionLogPage.prototype.getSortDirection = function () {
    if (this.sorts) {
        if (this.sorts.length > 0) {
            return this.sorts[0].direction;
        }
    }
    return 0;
};


export { SeamlessActionLogPage };
