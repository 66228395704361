<template>
  <div class="side_menu">
    <ul class="side-nav">
      <li class="side-nav__item_wrapper">
        <router-link to="/manager/summary">
          <div
            class="side-nav__item"
            v-bind:class="{
              'side-nav__item--selected': selectedId == 'summary',
            }"
          >
            <i class="circle_icon"></i>全アカウントサマリー
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  props: {
    selectId: String,
    adx: Object,
    submenu: Object,
  },
  data() {
    return {
      selectedId: '',
      menus: [],
      keywordmenus: [],
    };
  },
  mounted() {
    this.selectedId = this.selectId;
  },
  methods: {},
};
</script>
