// source: report_page/ad_provider_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.Graph', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.Graph.TypeCase', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider', null, global);
goog.exportSymbol('proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.displayName = 'proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.displayName = 'proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.displayName = 'proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.displayName = 'proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.displayName = 'proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.displayName = 'proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.displayName = 'proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.displayName = 'proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.displayName = 'proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.displayName = 'proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.displayName = 'proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.displayName = 'proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.Graph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.Graph.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.Graph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.Graph.displayName = 'proto.adx.ad_provider_report.Graph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.displayName = 'proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.displayName = 'proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.displayName = 'proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.displayName = 'proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.displayName = 'proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.displayName = 'proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.displayName = 'proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaign: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string campaign = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 categoryId = 7;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaign: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string campaign = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 categoryId = 7;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaign: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string campaign = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 categoryId = 7;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest;
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 categoryId = 6;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string sort = 8;
 * @return {!Array<string>}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 9;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest;
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 categoryId = 6;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest;
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 categoryId = 6;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 categoryId = 6;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string sort = 8;
 * @return {!Array<string>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 9;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 categoryId = 6;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string actualMonth = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 categoryId = 6;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adpageid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest;
  return proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adPageId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.TypeCase}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse;
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.ad_provider_report.Graph.toObject, includeInstance),
    adproviderweeklyresultgraphreportbyadproviderList: jspb.Message.toObjectList(msg.getAdproviderweeklyresultgraphreportbyadproviderList(),
    proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result;
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.Graph;
      reader.readMessage(value,proto.adx.ad_provider_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 4:
      var value = new proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.deserializeBinaryFromReader);
      msg.addAdproviderweeklyresultgraphreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getAdproviderweeklyresultgraphreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isalert: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider;
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsalert(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsalert();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table;
  return proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string isAlert = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.getIsalert = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.setIsalert = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table>}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table>} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider} returns this
*/
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Graph graph = 3;
 * @return {!Array<!proto.adx.ad_provider_report.Graph>}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.Graph, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.Graph>} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.Graph}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated AdProviderWeeklyResultGraphReportByAdProvider adProviderWeeklyResultGraphReportByAdProvider = 4;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider>}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.getAdproviderweeklyresultgraphreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider>} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.setAdproviderweeklyresultgraphreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.addAdproviderweeklyresultgraphreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.AdProviderWeeklyResultGraphReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result.prototype.clearAdproviderweeklyresultgraphreportbyadproviderList = function() {
  return this.setAdproviderweeklyresultgraphreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse} returns this
*/
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.TypeCase}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse;
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.ad_provider_report.Graph.toObject, includeInstance),
    adproviderdailyresultgraphreportbyadproviderList: jspb.Message.toObjectList(msg.getAdproviderdailyresultgraphreportbyadproviderList(),
    proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result;
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.Graph;
      reader.readMessage(value,proto.adx.ad_provider_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 4:
      var value = new proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.deserializeBinaryFromReader);
      msg.addAdproviderdailyresultgraphreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getAdproviderdailyresultgraphreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isalert: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider;
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsalert(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsalert();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table;
  return proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string isAlert = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.getIsalert = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.setIsalert = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table>}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider} returns this
*/
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Graph graph = 3;
 * @return {!Array<!proto.adx.ad_provider_report.Graph>}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.Graph, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.Graph>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.Graph}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated AdProviderDailyResultGraphReportByAdProvider adProviderDailyResultGraphReportByAdProvider = 4;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider>}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.getAdproviderdailyresultgraphreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.setAdproviderdailyresultgraphreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.addAdproviderdailyresultgraphreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.AdProviderDailyResultGraphReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result.prototype.clearAdproviderdailyresultgraphreportbyadproviderList = function() {
  return this.setAdproviderdailyresultgraphreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse} returns this
*/
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.Graph.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.Graph.TypeCase = {
  TYPE_NOT_SET: 0,
  BAR: 1,
  LINE: 2
};

/**
 * @return {proto.adx.ad_provider_report.Graph.TypeCase}
 */
proto.adx.ad_provider_report.Graph.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.Graph.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.Graph.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.Graph.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.Graph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.Graph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.Graph.toObject = function(includeInstance, msg) {
  var f, obj = {
    bar: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.Graph}
 */
proto.adx.ad_provider_report.Graph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.Graph;
  return proto.adx.ad_provider_report.Graph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.Graph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.Graph}
 */
proto.adx.ad_provider_report.Graph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBar(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.Graph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.Graph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.Graph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.Graph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string bar = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.Graph.prototype.getBar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.Graph} returns this
 */
proto.adx.ad_provider_report.Graph.prototype.setBar = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.adx.ad_provider_report.Graph.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.Graph} returns this
 */
proto.adx.ad_provider_report.Graph.prototype.clearBar = function() {
  return jspb.Message.setOneofField(this, 1, proto.adx.ad_provider_report.Graph.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.Graph.prototype.hasBar = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string line = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.Graph.prototype.getLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.Graph} returns this
 */
proto.adx.ad_provider_report.Graph.prototype.setLine = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.Graph.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.Graph} returns this
 */
proto.adx.ad_provider_report.Graph.prototype.clearLine = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.Graph.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.Graph.prototype.hasLine = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    adproviderdimensioncategorymonthlyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getAdproviderdimensioncategorymonthlyresultreportbyadproviderList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCampaign(value);
      break;
    case 4:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addAdproviderdimensioncategorymonthlyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAdproviderdimensioncategorymonthlyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string campaign = 3;
 * @return {!Array<string>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getCampaignList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setCampaignList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.addCampaign = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.clearCampaignList = function() {
  return this.setCampaignList([]);
};


/**
 * repeated AdProviderDimensionCategoryMonthlyResultReportByAdProvider adProviderDimensionCategoryMonthlyResultReportByAdProvider = 4;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getAdproviderdimensioncategorymonthlyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setAdproviderdimensioncategorymonthlyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.addAdproviderdimensioncategorymonthlyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryMonthlyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.clearAdproviderdimensioncategorymonthlyresultreportbyadproviderList = function() {
  return this.setAdproviderdimensioncategorymonthlyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    adproviderdimensioncategoryweeklyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getAdproviderdimensioncategoryweeklyresultreportbyadproviderList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCampaign(value);
      break;
    case 4:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addAdproviderdimensioncategoryweeklyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAdproviderdimensioncategoryweeklyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string campaign = 3;
 * @return {!Array<string>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.getCampaignList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.setCampaignList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.addCampaign = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.clearCampaignList = function() {
  return this.setCampaignList([]);
};


/**
 * repeated AdProviderDimensionCategoryWeeklyResultReportByAdProvider adProviderDimensionCategoryWeeklyResultReportByAdProvider = 4;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.getAdproviderdimensioncategoryweeklyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.setAdproviderdimensioncategoryweeklyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.addAdproviderdimensioncategoryweeklyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryWeeklyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.clearAdproviderdimensioncategoryweeklyresultreportbyadproviderList = function() {
  return this.setAdproviderdimensioncategoryweeklyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.ad_provider_report.Graph.toObject, includeInstance),
    adproviderdimensioncategorydailyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getAdproviderdimensioncategorydailyresultreportbyadproviderList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCampaign(value);
      break;
    case 4:
      var value = new proto.adx.ad_provider_report.Graph;
      reader.readMessage(value,proto.adx.ad_provider_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 5:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addAdproviderdimensioncategorydailyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.ad_provider_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getAdproviderdimensioncategorydailyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string campaign = 3;
 * @return {!Array<string>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getCampaignList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setCampaignList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.addCampaign = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.clearCampaignList = function() {
  return this.setCampaignList([]);
};


/**
 * repeated Graph graph = 4;
 * @return {!Array<!proto.adx.ad_provider_report.Graph>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.Graph, 4));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.Graph>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.ad_provider_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.Graph}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.ad_provider_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated AdProviderDimensionCategoryDailyResultReportByAdProvider adProviderDimensionCategoryDailyResultReportByAdProvider = 5;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getAdproviderdimensioncategorydailyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider, 5));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setAdproviderdimensioncategorydailyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.addAdproviderdimensioncategorydailyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryDailyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.clearAdproviderdimensioncategorydailyresultreportbyadproviderList = function() {
  return this.setAdproviderdimensioncategorydailyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adgroupList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    adproviderdimensioncategoryadgroupmonthlyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getAdproviderdimensioncategoryadgroupmonthlyresultreportbyadproviderList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdgroup(value);
      break;
    case 4:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addAdproviderdimensioncategoryadgroupmonthlyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdgroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAdproviderdimensioncategoryadgroupmonthlyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adgroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdgroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdgroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adGroup = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.getAdgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.setAdgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string adGroup = 3;
 * @return {!Array<string>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.getAdgroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.setAdgroupList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.addAdgroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.clearAdgroupList = function() {
  return this.setAdgroupList([]);
};


/**
 * repeated AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider adProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider = 4;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.getAdproviderdimensioncategoryadgroupmonthlyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.setAdproviderdimensioncategoryadgroupmonthlyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.addAdproviderdimensioncategoryadgroupmonthlyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.clearAdproviderdimensioncategoryadgroupmonthlyresultreportbyadproviderList = function() {
  return this.setAdproviderdimensioncategoryadgroupmonthlyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adgroupList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    adproviderdimensioncategoryadgroupweeklyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getAdproviderdimensioncategoryadgroupweeklyresultreportbyadproviderList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdgroup(value);
      break;
    case 4:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addAdproviderdimensioncategoryadgroupweeklyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdgroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAdproviderdimensioncategoryadgroupweeklyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adgroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdgroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdgroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adGroup = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.getAdgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.setAdgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string adGroup = 3;
 * @return {!Array<string>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.getAdgroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.setAdgroupList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.addAdgroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.clearAdgroupList = function() {
  return this.setAdgroupList([]);
};


/**
 * repeated AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider adProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider = 4;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.getAdproviderdimensioncategoryadgroupweeklyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.setAdproviderdimensioncategoryadgroupweeklyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.addAdproviderdimensioncategoryadgroupweeklyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.clearAdproviderdimensioncategoryadgroupweeklyresultreportbyadproviderList = function() {
  return this.setAdproviderdimensioncategoryadgroupweeklyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adgroupList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.ad_provider_report.Graph.toObject, includeInstance),
    adproviderdimensioncategoryadgroupdailyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getAdproviderdimensioncategoryadgroupdailyresultreportbyadproviderList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdgroup(value);
      break;
    case 4:
      var value = new proto.adx.ad_provider_report.Graph;
      reader.readMessage(value,proto.adx.ad_provider_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 5:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addAdproviderdimensioncategoryadgroupdailyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdgroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.ad_provider_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getAdproviderdimensioncategoryadgroupdailyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adgroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdgroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdgroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table;
  return proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adGroup = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.getAdgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.setAdgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string adGroup = 3;
 * @return {!Array<string>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getAdgroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setAdgroupList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.addAdgroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.clearAdgroupList = function() {
  return this.setAdgroupList([]);
};


/**
 * repeated Graph graph = 4;
 * @return {!Array<!proto.adx.ad_provider_report.Graph>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.Graph, 4));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.Graph>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.ad_provider_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.Graph}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.ad_provider_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider adProviderDimensionCategoryAdGroupDailyResultReportByAdProvider = 5;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider>}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getAdproviderdimensioncategoryadgroupdailyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider, 5));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider>} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setAdproviderdimensioncategoryadgroupdailyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.addAdproviderdimensioncategoryadgroupdailyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.clearAdproviderdimensioncategoryadgroupdailyresultreportbyadproviderList = function() {
  return this.setAdproviderdimensioncategoryadgroupdailyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} returns this
*/
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.TypeCase}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse;
  return proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adpagesid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    adproviderList: jspb.Message.toObjectList(msg.getAdproviderList(),
    proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result;
  return proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagesid(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.deserializeBinaryFromReader);
      msg.addAdprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdpagesid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adproviderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tab: jspb.Message.getFieldWithDefault(msg, 2, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 3, ""),
    providerforshort: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider;
  return proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTab(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderforshort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTab();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProviderforshort();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 adProviderId = 1;
 * @return {number}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tab = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.getTab = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.setTab = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string provider = 3;
 * @return {string}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string providerForShort = 4;
 * @return {string}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.getProviderforshort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider.prototype.setProviderforshort = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adPagesId = 2;
 * @return {number}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.getAdpagesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.setAdpagesid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AdProvider adProvider = 3;
 * @return {!Array<!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider>}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.getAdproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider>} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result} returns this
*/
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.setAdproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.addAdprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.AdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result.prototype.clearAdproviderList = function() {
  return this.setAdproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse} returns this
*/
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse} returns this
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse;
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adprovidermonthlyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getAdprovidermonthlyresultreportbyadproviderList(),
    proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result;
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addAdprovidermonthlyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdprovidermonthlyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider;
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table;
  return proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table>}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table, 2));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider} returns this
*/
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.clearStructure = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.hasStructure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated AdProviderMonthlyResultReportByAdProvider adProviderMonthlyResultReportByAdProvider = 3;
 * @return {!Array<!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider>}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.getAdprovidermonthlyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider, 3));
};


/**
 * @param {!Array<!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider>} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.setAdprovidermonthlyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.addAdprovidermonthlyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.AdProviderMonthlyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result.prototype.clearAdprovidermonthlyresultreportbyadproviderList = function() {
  return this.setAdprovidermonthlyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse} returns this
*/
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.ad_provider_report);
