import { getTabListOfDynamicDimensionCategoryReportPage, getDynamicDimensionCategoryMonthlyResultReportByAdProvider, getDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider, getDynamicDimensionCategoryDailyResultGraphReportByAdProvider, getDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider, getDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider } from "../../com/grpc/dimension";
import { AdProviderTab, NameChartStructureTable, Graph, DimensionHolder } from '../data.js'


function DimensionPage() {
    this.tab = new AdProviderTab();
    this.table = new NameChartStructureTable(null);
    this.campainStructure = null;
    this.campaignTables = [];//new NameChartStructureTable(null);
    this.selectedId = 0;
    this.loadingUuid = null;
    this.isAdProviderMonthlyNetworking = false;
    this.isLoadCampaignNetworking = false;
    this.mode = 0;
    this.weeklyGraph = new Graph();
    this.dailyGraph = new Graph();
    this.pageId = 0;
    this.categoryId = 0;
    this.timeRange = null;
    this.isLoadDynamicPerCategoryNetworking = false;
    this.pageComponents = [];
    this.advertizerId = null;
    this.dimensionHolder = new DimensionHolder();
    this.user = null;
}
DimensionPage.prototype.initialize = function (pageId, pageComponents, categoryId, advertizerId, timeRange, user) {
    this.pageId = pageId;
    this.categoryId = categoryId;
    this.advertizerId = advertizerId;
    this.timeRange = timeRange;
    this.pageComponents = pageComponents;
    this.tabs = [];
    this.tab.cells = [];
    this.user = user;
    let self = this;
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.advertizerId = this.advertizerId;

    let cuuid = getTabListOfDynamicDimensionCategoryReportPage(this.pageId, request, function (uuid, data) {
        if (cuuid == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            for (let a of json.adproviderList) {
                self.tab.addAdProvider(a);
                if (self.tab.cells.length == 1) {
                    self.selectTabByAdProviderId(a.adproviderid);
                }
            }
        }
    });

};
DimensionPage.prototype.selectTabByAdProviderId = function (id) {
    this.selectedId = id;
    let self = this;
    self.table.reset();
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.timeRequest = this.timeRange.getTimeRequest();
    request.advertizerId = this.advertizerId;

    this.isAdProviderMonthlyNetworking = true;
    this.isAdProviderMonthlyNoData = false;
    this.table.reset();
    let cuuid = getDynamicDimensionCategoryMonthlyResultReportByAdProvider(id, this.categoryId, request, function (uuid, data) {
        if ((cuuid == uuid) && (uuid == self.loadingUuid) && data != null) {
            if (data.error) {
                if (data.error.length > 0) {
                    self.isAdProviderMonthlyNetworking = false;
                    self.isAdProviderMonthlyNoData = true;
                }
            }
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                let structure = JSON.parse(json.structure);
                self.table.initialize(structure, "all");
            } else if (Object.entries(json.dynamicdimensioncategorymonthlyresultreportbyadproviderList).length != 0) {
                for (let i in json.dynamicdimensioncategorymonthlyresultreportbyadproviderList) {
                    let report = json.dynamicdimensioncategorymonthlyresultreportbyadproviderList[i];
                    // let report = json.dynamicdimensioncategorymonthlyresultreportbyadprovider;
                    self.table.addNameAndRow(report.name, report.tableList);
                }
            }
        }
        self.isAdProviderMonthlyNetworking = false;

    });
    this.loadingUuid = cuuid;
    this.loadCampaign(id, this.advertizerId);

    // if (this.weeklyGraph) {
    //     this.weeklyGraph.reset();
    // }
    // this.weeklyGraph = new Graph();
    //
    this.weeklyGraph.reset();
    getDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider(id, this.categoryId, request, function (uuid, data) {
        if (data != null) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                let structure = JSON.parse(json.structure);
                self.weeklyGraph.initialize(structure, "all");
            } else if (Object.entries(json.dynamicdimensioncategoryweeklyresultgraphreportbyadprovider).length != 0) {
                self.weeklyGraph.setDatas(json.dynamicdimensioncategoryweeklyresultgraphreportbyadprovider);
            }
        }
    });

    this.dailyGraph.reset();
    getDynamicDimensionCategoryDailyResultGraphReportByAdProvider(id, this.categoryId, request, function (uuid, data) {
        if (data != null) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                let structure = JSON.parse(json.structure);
                self.dailyGraph.initialize(structure, "all");
            } else if (Object.entries(json.dynamicdimensioncategorydailyresultgraphreportbyadproviderList).length != 0) {
                for (let a of json.dynamicdimensioncategorydailyresultgraphreportbyadproviderList) {
                    self.dailyGraph.setDatas(a);
                }
            }
        }
    });

    this.isLoadDynamicPerCategoryNetworking = true;
    this.dimensionHolder.initialize();
    function _c(tabname, component) {
        if (component.kindOfTab == "ALL") {
            if (tabname == "ALL") {
                return true;
            }
        } else {
            if (tabname != "ALL") {
                return true;
            }
        }
        return false;
    }
    for (let i in this.pageComponents) {
        let pageComponent = this.pageComponents[i];
        if (pageComponent.destination == "MONTHLY_DIMENSION_CATEGORY_RESULT_IN_DYNAMIC_CATEGORY" && _c(this.getSelectTabByName(), pageComponent)) {
            getDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider(id, this.categoryId, pageComponent.id, request, function (uuid, data) {
                let json = JSON.parse(JSON.stringify(data.result));
                if (!!(json.structure) != false) {
                    let structure = JSON.parse(json.structure);
                    self.dimensionHolder.pushInitStream(structure, "all", uuid);
                } else if (Object.entries(json.dimensioncategoryresultreportList).length != 0) {
                    self.dimensionHolder.pushDataStream(json.categorytypeList, json.dimensioncategoryresultreportList, uuid);
                }
                self.isLoadDynamicPerCategoryNetworking = false;
            });


        }
    }
};
DimensionPage.prototype.loadCampaign = function (id, advertizerId) {
    let self = this;
    self.campaignTables = [];
    this.isLoadCampaignNetworking = true;
    this.isLoadCampaignNoData = false;
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.timeRequest = this.timeRange.getTimeRequest();
    request.advertizerId = advertizerId;

    switch (this.mode) {
        case 0:
            {
                let cuuid = getDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider(id, this.categoryId, request, function (uuid, data) {
                    if ((cuuid == uuid) && data != null) {
                        if (data.error) {
                            if (data.error.length > 0) {
                                self.isLoadCampaignNetworking = false;
                                self.isLoadCampaignNoData = true;
                            }
                        }
                        let json = JSON.parse(JSON.stringify(data.result));

                        if (!!(json.structure) != false) {
                            let structure = JSON.parse(json.structure);
                            //self.table.initialize(structure, "all");
                            self.campainStructure = structure;
                            //console.log(json.structure);
                        } else if (Object.entries(json.dynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList).length != 0) {
                            for (let i in json.dynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList) {
                                let item = json.dynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList[i];
                                // let item = json.dynamicdimensioncategorydimensioncategorymonthlyresultreportbyadprovider;
                                if ('campaign' in item) {
                                    let table = null;
                                    for (let i in self.campaignTables) {
                                        if (self.campaignTables[i].title == item.campaign) {
                                            table = self.campaignTables[i];
                                        }
                                    }
                                    if (table) {
                                        table.addNameAndRow(item.name, item.tableList);
                                    } else {
                                        let s = new NameChartStructureTable();
                                        s.initialize(self.campainStructure, "all");
                                        s.addNameAndRow(item.name, item.tableList);
                                        s.title = item.campaign;
                                        self.campaignTables.push(s);
                                    }

                                }
                            }
                        } else {
                            self.isLoadCampaignNoData = true;
                        }
                    }
                    self.isLoadCampaignNetworking = false;
                });
            }
            break;
    }
};
DimensionPage.prototype.isCategoryResult = function () {
    if (this.pageComponents) {
        for (let i in this.pageComponents) {
            if (this.pageComponents[i].destination == "MONTHLY_DIMENSION_CATEGORY_RESULT_IN_DYNAMIC_CATEGORY") {
                return true;
            }
        }
    }
    return false;
}
DimensionPage.prototype.getSelectTabByName = function () {
    for (let i in this.tab.cells) {
        if (this.selectedId == this.tab.cells[i].getId()) {
            return this.tab.cells[i].getName();
        }
    }
    return "";
};

export { DimensionPage }