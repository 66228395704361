/**
 * @fileoverview gRPC-Web generated client stub for adx.manager
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.manager = require('./manager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.manager.ManagerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.manager.ManagerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.manager.SummaryReportRequest,
 *   !proto.adx.manager.SummaryReportResponse>}
 */
const methodDescriptor_Manager_GetSummaryReport = new grpc.web.MethodDescriptor(
  '/adx.manager.Manager/GetSummaryReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.manager.SummaryReportRequest,
  proto.adx.manager.SummaryReportResponse,
  /**
   * @param {!proto.adx.manager.SummaryReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.manager.SummaryReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.manager.SummaryReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.manager.SummaryReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.manager.ManagerClient.prototype.getSummaryReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.manager.Manager/GetSummaryReport',
      request,
      metadata || {},
      methodDescriptor_Manager_GetSummaryReport);
};


/**
 * @param {!proto.adx.manager.SummaryReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.manager.SummaryReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.manager.ManagerPromiseClient.prototype.getSummaryReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.manager.Manager/GetSummaryReport',
      request,
      metadata || {},
      methodDescriptor_Manager_GetSummaryReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.manager.SaveSummaryReportOrderRequest,
 *   !proto.adx.manager.SaveSummaryReportOrderResponse>}
 */
const methodDescriptor_Manager_SaveSummaryReportOrder = new grpc.web.MethodDescriptor(
  '/adx.manager.Manager/SaveSummaryReportOrder',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.manager.SaveSummaryReportOrderRequest,
  proto.adx.manager.SaveSummaryReportOrderResponse,
  /**
   * @param {!proto.adx.manager.SaveSummaryReportOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.manager.SaveSummaryReportOrderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.manager.SaveSummaryReportOrderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.manager.SaveSummaryReportOrderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.manager.ManagerClient.prototype.saveSummaryReportOrder =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.manager.Manager/SaveSummaryReportOrder',
      request,
      metadata || {},
      methodDescriptor_Manager_SaveSummaryReportOrder);
};


/**
 * @param {!proto.adx.manager.SaveSummaryReportOrderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.manager.SaveSummaryReportOrderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.manager.ManagerPromiseClient.prototype.saveSummaryReportOrder =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.manager.Manager/SaveSummaryReportOrder',
      request,
      metadata || {},
      methodDescriptor_Manager_SaveSummaryReportOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.manager.InsertSummaryMemoRequest,
 *   !proto.adx.manager.InsertSummaryMemoResponse>}
 */
const methodDescriptor_Manager_InsertSummaryMemo = new grpc.web.MethodDescriptor(
  '/adx.manager.Manager/InsertSummaryMemo',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.manager.InsertSummaryMemoRequest,
  proto.adx.manager.InsertSummaryMemoResponse,
  /**
   * @param {!proto.adx.manager.InsertSummaryMemoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.manager.InsertSummaryMemoResponse.deserializeBinary
);


/**
 * @param {!proto.adx.manager.InsertSummaryMemoRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.manager.InsertSummaryMemoResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.manager.ManagerClient.prototype.insertSummaryMemo =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.manager.Manager/InsertSummaryMemo',
      request,
      metadata || {},
      methodDescriptor_Manager_InsertSummaryMemo);
};


/**
 * @param {!proto.adx.manager.InsertSummaryMemoRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.manager.InsertSummaryMemoResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.manager.ManagerPromiseClient.prototype.insertSummaryMemo =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.manager.Manager/InsertSummaryMemo',
      request,
      metadata || {},
      methodDescriptor_Manager_InsertSummaryMemo);
};


module.exports = proto.adx.manager;

