// source: action_log.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.adx.action_log.CreateActionLogsRequest', null, global);
goog.exportSymbol('proto.adx.action_log.CreateActionLogsResponse', null, global);
goog.exportSymbol('proto.adx.action_log.CreateActionLogsResponse.Data', null, global);
goog.exportSymbol('proto.adx.action_log.CreateActionLogsResponse.Result', null, global);
goog.exportSymbol('proto.adx.action_log.CreateActionLogsResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsFilesRequest', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsFilesResponse', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsFilesResponse.Data', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsFilesResponse.Result', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsFilesResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsRequest', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsResponse', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsResponse.Data', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsResponse.Result', null, global);
goog.exportSymbol('proto.adx.action_log.DeleteActionLogsResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsFilesRequest', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsFilesResponse', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsFilesResponse.Data', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsFilesResponse.Result', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsFilesResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsRequest', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsRequest.Filter', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsRequest.Filter.Condition', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsResponse', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsResponse.Attachment', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsResponse.Data', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsResponse.Result', null, global);
goog.exportSymbol('proto.adx.action_log.GetActionLogsResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.action_log.SaveActionLogsOrderRequest', null, global);
goog.exportSymbol('proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder', null, global);
goog.exportSymbol('proto.adx.action_log.SaveActionLogsOrderResponse', null, global);
goog.exportSymbol('proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder', null, global);
goog.exportSymbol('proto.adx.action_log.SaveActionLogsOrderResponse.Result', null, global);
goog.exportSymbol('proto.adx.action_log.SaveActionLogsOrderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.action_log.UpdateActionLogsRequest', null, global);
goog.exportSymbol('proto.adx.action_log.UpdateActionLogsResponse', null, global);
goog.exportSymbol('proto.adx.action_log.UpdateActionLogsResponse.Data', null, global);
goog.exportSymbol('proto.adx.action_log.UpdateActionLogsResponse.Result', null, global);
goog.exportSymbol('proto.adx.action_log.UpdateActionLogsResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.CreateActionLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.CreateActionLogsRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.CreateActionLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.CreateActionLogsRequest.displayName = 'proto.adx.action_log.CreateActionLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.CreateActionLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.action_log.CreateActionLogsResponse.oneofGroups_);
};
goog.inherits(proto.adx.action_log.CreateActionLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.CreateActionLogsResponse.displayName = 'proto.adx.action_log.CreateActionLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.CreateActionLogsResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.CreateActionLogsResponse.Data.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.CreateActionLogsResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.CreateActionLogsResponse.Data.displayName = 'proto.adx.action_log.CreateActionLogsResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.CreateActionLogsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.CreateActionLogsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.CreateActionLogsResponse.Result.displayName = 'proto.adx.action_log.CreateActionLogsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.DeleteActionLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.DeleteActionLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.DeleteActionLogsRequest.displayName = 'proto.adx.action_log.DeleteActionLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.DeleteActionLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.action_log.DeleteActionLogsResponse.oneofGroups_);
};
goog.inherits(proto.adx.action_log.DeleteActionLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.DeleteActionLogsResponse.displayName = 'proto.adx.action_log.DeleteActionLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.DeleteActionLogsResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.DeleteActionLogsResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.DeleteActionLogsResponse.Data.displayName = 'proto.adx.action_log.DeleteActionLogsResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.DeleteActionLogsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.DeleteActionLogsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.DeleteActionLogsResponse.Result.displayName = 'proto.adx.action_log.DeleteActionLogsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.DeleteActionLogsFilesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.DeleteActionLogsFilesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.DeleteActionLogsFilesRequest.displayName = 'proto.adx.action_log.DeleteActionLogsFilesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.DeleteActionLogsFilesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.action_log.DeleteActionLogsFilesResponse.oneofGroups_);
};
goog.inherits(proto.adx.action_log.DeleteActionLogsFilesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.DeleteActionLogsFilesResponse.displayName = 'proto.adx.action_log.DeleteActionLogsFilesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.DeleteActionLogsFilesResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.DeleteActionLogsFilesResponse.Data.displayName = 'proto.adx.action_log.DeleteActionLogsFilesResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.DeleteActionLogsFilesResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.DeleteActionLogsFilesResponse.Result.displayName = 'proto.adx.action_log.DeleteActionLogsFilesResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.GetActionLogsRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.GetActionLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsRequest.displayName = 'proto.adx.action_log.GetActionLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsRequest.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.GetActionLogsRequest.Filter.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.GetActionLogsRequest.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsRequest.Filter.displayName = 'proto.adx.action_log.GetActionLogsRequest.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.GetActionLogsRequest.Filter.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsRequest.Filter.Condition.displayName = 'proto.adx.action_log.GetActionLogsRequest.Filter.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.action_log.GetActionLogsResponse.oneofGroups_);
};
goog.inherits(proto.adx.action_log.GetActionLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsResponse.displayName = 'proto.adx.action_log.GetActionLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsResponse.Attachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.GetActionLogsResponse.Attachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsResponse.Attachment.displayName = 'proto.adx.action_log.GetActionLogsResponse.Attachment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.GetActionLogsResponse.Data.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.GetActionLogsResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsResponse.Data.displayName = 'proto.adx.action_log.GetActionLogsResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.GetActionLogsResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.GetActionLogsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsResponse.Result.displayName = 'proto.adx.action_log.GetActionLogsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsFilesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.GetActionLogsFilesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsFilesRequest.displayName = 'proto.adx.action_log.GetActionLogsFilesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsFilesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.action_log.GetActionLogsFilesResponse.oneofGroups_);
};
goog.inherits(proto.adx.action_log.GetActionLogsFilesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsFilesResponse.displayName = 'proto.adx.action_log.GetActionLogsFilesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.GetActionLogsFilesResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsFilesResponse.Data.displayName = 'proto.adx.action_log.GetActionLogsFilesResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.GetActionLogsFilesResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.GetActionLogsFilesResponse.Result.displayName = 'proto.adx.action_log.GetActionLogsFilesResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.UpdateActionLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.UpdateActionLogsRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.UpdateActionLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.UpdateActionLogsRequest.displayName = 'proto.adx.action_log.UpdateActionLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.UpdateActionLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.action_log.UpdateActionLogsResponse.oneofGroups_);
};
goog.inherits(proto.adx.action_log.UpdateActionLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.UpdateActionLogsResponse.displayName = 'proto.adx.action_log.UpdateActionLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.UpdateActionLogsResponse.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.UpdateActionLogsResponse.Data.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.UpdateActionLogsResponse.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.UpdateActionLogsResponse.Data.displayName = 'proto.adx.action_log.UpdateActionLogsResponse.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.UpdateActionLogsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.UpdateActionLogsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.UpdateActionLogsResponse.Result.displayName = 'proto.adx.action_log.UpdateActionLogsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.SaveActionLogsOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.SaveActionLogsOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.SaveActionLogsOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.SaveActionLogsOrderRequest.displayName = 'proto.adx.action_log.SaveActionLogsOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.displayName = 'proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.SaveActionLogsOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.action_log.SaveActionLogsOrderResponse.oneofGroups_);
};
goog.inherits(proto.adx.action_log.SaveActionLogsOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.SaveActionLogsOrderResponse.displayName = 'proto.adx.action_log.SaveActionLogsOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.action_log.SaveActionLogsOrderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.action_log.SaveActionLogsOrderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.SaveActionLogsOrderResponse.Result.displayName = 'proto.adx.action_log.SaveActionLogsOrderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.displayName = 'proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.CreateActionLogsRequest.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.CreateActionLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.CreateActionLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.CreateActionLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    implementationstart: jspb.Message.getFieldWithDefault(msg, 3, ""),
    implementationend: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adprovider: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    purpose: jspb.Message.getFieldWithDefault(msg, 7, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    validationstart: jspb.Message.getFieldWithDefault(msg, 9, ""),
    validationend: jspb.Message.getFieldWithDefault(msg, 10, ""),
    result: jspb.Message.getFieldWithDefault(msg, 11, ""),
    detailofresult: jspb.Message.getFieldWithDefault(msg, 12, ""),
    nextaction: jspb.Message.getFieldWithDefault(msg, 13, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    note: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.CreateActionLogsRequest}
 */
proto.adx.action_log.CreateActionLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.CreateActionLogsRequest;
  return proto.adx.action_log.CreateActionLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.CreateActionLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.CreateActionLogsRequest}
 */
proto.adx.action_log.CreateActionLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationstart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationend(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdprovider(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationstart(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationend(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailofresult(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextaction(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.CreateActionLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.CreateActionLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.CreateActionLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string implementationStart = 3;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getImplementationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setImplementationstart = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearImplementationstart = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasImplementationstart = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string implementationEnd = 4;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getImplementationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setImplementationend = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearImplementationend = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasImplementationend = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string adProvider = 5;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getAdprovider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setAdprovider = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearAdprovider = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasAdprovider = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string purpose = 7;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setPurpose = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearPurpose = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasPurpose = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string detail = 8;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setDetail = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearDetail = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string validationStart = 9;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getValidationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setValidationstart = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearValidationstart = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasValidationstart = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string validationEnd = 10;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getValidationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setValidationend = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearValidationend = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasValidationend = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string result = 11;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setResult = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearResult = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasResult = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string detailOfResult = 12;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getDetailofresult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setDetailofresult = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearDetailofresult = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasDetailofresult = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string nextAction = 13;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getNextaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setNextaction = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearNextaction = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasNextaction = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 advertizerId = 14;
 * @return {number}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated string tags = 15;
 * @return {!Array<string>}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string note = 16;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.setNote = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsRequest} returns this
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.clearNote = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsRequest.prototype.hasNote = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.action_log.CreateActionLogsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.action_log.CreateActionLogsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.action_log.CreateActionLogsResponse.TypeCase}
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.action_log.CreateActionLogsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.action_log.CreateActionLogsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.CreateActionLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.CreateActionLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.CreateActionLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.action_log.CreateActionLogsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.CreateActionLogsResponse}
 */
proto.adx.action_log.CreateActionLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.CreateActionLogsResponse;
  return proto.adx.action_log.CreateActionLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.CreateActionLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.CreateActionLogsResponse}
 */
proto.adx.action_log.CreateActionLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.action_log.CreateActionLogsResponse.Result;
      reader.readMessage(value,proto.adx.action_log.CreateActionLogsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.CreateActionLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.CreateActionLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.CreateActionLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.action_log.CreateActionLogsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.CreateActionLogsResponse.Data.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.CreateActionLogsResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.CreateActionLogsResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.CreateActionLogsResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    implementationstart: jspb.Message.getFieldWithDefault(msg, 3, ""),
    implementationend: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adprovider: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    purpose: jspb.Message.getFieldWithDefault(msg, 7, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    validationstart: jspb.Message.getFieldWithDefault(msg, 9, ""),
    validationend: jspb.Message.getFieldWithDefault(msg, 10, ""),
    result: jspb.Message.getFieldWithDefault(msg, 11, ""),
    detailofresult: jspb.Message.getFieldWithDefault(msg, 12, ""),
    nextaction: jspb.Message.getFieldWithDefault(msg, 13, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    note: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.CreateActionLogsResponse.Data;
  return proto.adx.action_log.CreateActionLogsResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.CreateActionLogsResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationstart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationend(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdprovider(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationstart(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationend(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailofresult(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextaction(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.CreateActionLogsResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.CreateActionLogsResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.CreateActionLogsResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImplementationstart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImplementationend();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdprovider();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getValidationstart();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getValidationend();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDetailofresult();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNextaction();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string implementationStart = 3;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getImplementationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setImplementationstart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string implementationEnd = 4;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getImplementationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setImplementationend = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string adProvider = 5;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getAdprovider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setAdprovider = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string purpose = 7;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string detail = 8;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string validationStart = 9;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getValidationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setValidationstart = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string validationEnd = 10;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getValidationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setValidationend = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string result = 11;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string detailOfResult = 12;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getDetailofresult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setDetailofresult = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string nextAction = 13;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getNextaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setNextaction = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 advertizerId = 14;
 * @return {number}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated string tags = 15;
 * @return {!Array<string>}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string note = 16;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Data.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.CreateActionLogsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.CreateActionLogsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.CreateActionLogsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.CreateActionLogsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.adx.action_log.CreateActionLogsResponse.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Result}
 */
proto.adx.action_log.CreateActionLogsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.CreateActionLogsResponse.Result;
  return proto.adx.action_log.CreateActionLogsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.CreateActionLogsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Result}
 */
proto.adx.action_log.CreateActionLogsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.action_log.CreateActionLogsResponse.Data;
      reader.readMessage(value,proto.adx.action_log.CreateActionLogsResponse.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.CreateActionLogsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.CreateActionLogsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.CreateActionLogsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.CreateActionLogsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.action_log.CreateActionLogsResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Result} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Data data = 2;
 * @return {?proto.adx.action_log.CreateActionLogsResponse.Data}
 */
proto.adx.action_log.CreateActionLogsResponse.Result.prototype.getData = function() {
  return /** @type{?proto.adx.action_log.CreateActionLogsResponse.Data} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.CreateActionLogsResponse.Data, 2));
};


/**
 * @param {?proto.adx.action_log.CreateActionLogsResponse.Data|undefined} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Result} returns this
*/
proto.adx.action_log.CreateActionLogsResponse.Result.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsResponse.Result} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.Result.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsResponse.Result.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.action_log.CreateActionLogsResponse.Result}
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.action_log.CreateActionLogsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.CreateActionLogsResponse.Result, 1));
};


/**
 * @param {?proto.adx.action_log.CreateActionLogsResponse.Result|undefined} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse} returns this
*/
proto.adx.action_log.CreateActionLogsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.action_log.CreateActionLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsResponse} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.CreateActionLogsResponse} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.CreateActionLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.CreateActionLogsResponse} returns this
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.CreateActionLogsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.CreateActionLogsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.DeleteActionLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.DeleteActionLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.DeleteActionLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.DeleteActionLogsRequest}
 */
proto.adx.action_log.DeleteActionLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.DeleteActionLogsRequest;
  return proto.adx.action_log.DeleteActionLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.DeleteActionLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.DeleteActionLogsRequest}
 */
proto.adx.action_log.DeleteActionLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.DeleteActionLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.DeleteActionLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.DeleteActionLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsRequest} returns this
 */
proto.adx.action_log.DeleteActionLogsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsRequest} returns this
 */
proto.adx.action_log.DeleteActionLogsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.action_log.DeleteActionLogsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.action_log.DeleteActionLogsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.action_log.DeleteActionLogsResponse.TypeCase}
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.action_log.DeleteActionLogsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.action_log.DeleteActionLogsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.DeleteActionLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.DeleteActionLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.action_log.DeleteActionLogsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.DeleteActionLogsResponse}
 */
proto.adx.action_log.DeleteActionLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.DeleteActionLogsResponse;
  return proto.adx.action_log.DeleteActionLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.DeleteActionLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.DeleteActionLogsResponse}
 */
proto.adx.action_log.DeleteActionLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.action_log.DeleteActionLogsResponse.Result;
      reader.readMessage(value,proto.adx.action_log.DeleteActionLogsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.DeleteActionLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.DeleteActionLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.action_log.DeleteActionLogsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.DeleteActionLogsResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.DeleteActionLogsResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.DeleteActionLogsResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.DeleteActionLogsResponse.Data}
 */
proto.adx.action_log.DeleteActionLogsResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.DeleteActionLogsResponse.Data;
  return proto.adx.action_log.DeleteActionLogsResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.DeleteActionLogsResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.DeleteActionLogsResponse.Data}
 */
proto.adx.action_log.DeleteActionLogsResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.DeleteActionLogsResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.DeleteActionLogsResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.DeleteActionLogsResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsResponse.Data} returns this
 */
proto.adx.action_log.DeleteActionLogsResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.DeleteActionLogsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.DeleteActionLogsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.adx.action_log.DeleteActionLogsResponse.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.DeleteActionLogsResponse.Result}
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.DeleteActionLogsResponse.Result;
  return proto.adx.action_log.DeleteActionLogsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.DeleteActionLogsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.DeleteActionLogsResponse.Result}
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.action_log.DeleteActionLogsResponse.Data;
      reader.readMessage(value,proto.adx.action_log.DeleteActionLogsResponse.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.DeleteActionLogsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.DeleteActionLogsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.action_log.DeleteActionLogsResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsResponse.Result} returns this
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Data data = 2;
 * @return {?proto.adx.action_log.DeleteActionLogsResponse.Data}
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.prototype.getData = function() {
  return /** @type{?proto.adx.action_log.DeleteActionLogsResponse.Data} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.DeleteActionLogsResponse.Data, 2));
};


/**
 * @param {?proto.adx.action_log.DeleteActionLogsResponse.Data|undefined} value
 * @return {!proto.adx.action_log.DeleteActionLogsResponse.Result} returns this
*/
proto.adx.action_log.DeleteActionLogsResponse.Result.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.DeleteActionLogsResponse.Result} returns this
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.DeleteActionLogsResponse.Result.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.action_log.DeleteActionLogsResponse.Result}
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.action_log.DeleteActionLogsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.DeleteActionLogsResponse.Result, 1));
};


/**
 * @param {?proto.adx.action_log.DeleteActionLogsResponse.Result|undefined} value
 * @return {!proto.adx.action_log.DeleteActionLogsResponse} returns this
*/
proto.adx.action_log.DeleteActionLogsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.action_log.DeleteActionLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.DeleteActionLogsResponse} returns this
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsResponse} returns this
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.DeleteActionLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.DeleteActionLogsResponse} returns this
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.DeleteActionLogsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.DeleteActionLogsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.DeleteActionLogsFilesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.DeleteActionLogsFilesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesRequest}
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.DeleteActionLogsFilesRequest;
  return proto.adx.action_log.DeleteActionLogsFilesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.DeleteActionLogsFilesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesRequest}
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.DeleteActionLogsFilesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.DeleteActionLogsFilesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsFilesRequest} returns this
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fileId = 2;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.prototype.getFileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsFilesRequest} returns this
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.prototype.setFileid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.DeleteActionLogsFilesRequest} returns this
 */
proto.adx.action_log.DeleteActionLogsFilesRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.action_log.DeleteActionLogsFilesResponse.TypeCase}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.action_log.DeleteActionLogsFilesResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.action_log.DeleteActionLogsFilesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.DeleteActionLogsFilesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.DeleteActionLogsFilesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.action_log.DeleteActionLogsFilesResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.DeleteActionLogsFilesResponse;
  return proto.adx.action_log.DeleteActionLogsFilesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.DeleteActionLogsFilesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.action_log.DeleteActionLogsFilesResponse.Result;
      reader.readMessage(value,proto.adx.action_log.DeleteActionLogsFilesResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.DeleteActionLogsFilesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.DeleteActionLogsFilesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.action_log.DeleteActionLogsFilesResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.DeleteActionLogsFilesResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.DeleteActionLogsFilesResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse.Data}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.DeleteActionLogsFilesResponse.Data;
  return proto.adx.action_log.DeleteActionLogsFilesResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.DeleteActionLogsFilesResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse.Data}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.DeleteActionLogsFilesResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.DeleteActionLogsFilesResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse.Data} returns this
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.DeleteActionLogsFilesResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.DeleteActionLogsFilesResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.adx.action_log.DeleteActionLogsFilesResponse.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse.Result}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.DeleteActionLogsFilesResponse.Result;
  return proto.adx.action_log.DeleteActionLogsFilesResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.DeleteActionLogsFilesResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse.Result}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.action_log.DeleteActionLogsFilesResponse.Data;
      reader.readMessage(value,proto.adx.action_log.DeleteActionLogsFilesResponse.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.DeleteActionLogsFilesResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.DeleteActionLogsFilesResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.action_log.DeleteActionLogsFilesResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse.Result} returns this
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Data data = 2;
 * @return {?proto.adx.action_log.DeleteActionLogsFilesResponse.Data}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.prototype.getData = function() {
  return /** @type{?proto.adx.action_log.DeleteActionLogsFilesResponse.Data} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.DeleteActionLogsFilesResponse.Data, 2));
};


/**
 * @param {?proto.adx.action_log.DeleteActionLogsFilesResponse.Data|undefined} value
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse.Result} returns this
*/
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse.Result} returns this
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.Result.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.action_log.DeleteActionLogsFilesResponse.Result}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.action_log.DeleteActionLogsFilesResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.DeleteActionLogsFilesResponse.Result, 1));
};


/**
 * @param {?proto.adx.action_log.DeleteActionLogsFilesResponse.Result|undefined} value
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse} returns this
*/
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.action_log.DeleteActionLogsFilesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse} returns this
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse} returns this
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.DeleteActionLogsFilesResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.DeleteActionLogsFilesResponse} returns this
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.DeleteActionLogsFilesResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.DeleteActionLogsFilesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.GetActionLogsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    filter: (f = msg.getFilter()) && proto.adx.action_log.GetActionLogsRequest.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsRequest}
 */
proto.adx.action_log.GetActionLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsRequest;
  return proto.adx.action_log.GetActionLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsRequest}
 */
proto.adx.action_log.GetActionLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 7:
      var value = new proto.adx.action_log.GetActionLogsRequest.Filter;
      reader.readMessage(value,proto.adx.action_log.GetActionLogsRequest.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.adx.action_log.GetActionLogsRequest.Filter.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.GetActionLogsRequest.Filter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsRequest.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsRequest.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsRequest.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    operator: jspb.Message.getFieldWithDefault(msg, 1, ""),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.adx.action_log.GetActionLogsRequest.Filter.Condition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsRequest.Filter;
  return proto.adx.action_log.GetActionLogsRequest.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsRequest.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 2:
      var value = new proto.adx.action_log.GetActionLogsRequest.Filter.Condition;
      reader.readMessage(value,proto.adx.action_log.GetActionLogsRequest.Filter.Condition.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsRequest.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsRequest.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsRequest.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.action_log.GetActionLogsRequest.Filter.Condition.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsRequest.Filter.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    match: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsRequest.Filter.Condition;
  return proto.adx.action_log.GetActionLogsRequest.Filter.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsRequest.Filter.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMatch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string property = 1;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition} returns this
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition} returns this
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string match = 3;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.prototype.getMatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition} returns this
 */
proto.adx.action_log.GetActionLogsRequest.Filter.Condition.prototype.setMatch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string operator = 1;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter} returns this
 */
proto.adx.action_log.GetActionLogsRequest.Filter.prototype.setOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Condition conditions = 2;
 * @return {!Array<!proto.adx.action_log.GetActionLogsRequest.Filter.Condition>}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.adx.action_log.GetActionLogsRequest.Filter.Condition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.action_log.GetActionLogsRequest.Filter.Condition, 2));
};


/**
 * @param {!Array<!proto.adx.action_log.GetActionLogsRequest.Filter.Condition>} value
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter} returns this
*/
proto.adx.action_log.GetActionLogsRequest.Filter.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter.Condition}
 */
proto.adx.action_log.GetActionLogsRequest.Filter.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.action_log.GetActionLogsRequest.Filter.Condition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.GetActionLogsRequest.Filter} returns this
 */
proto.adx.action_log.GetActionLogsRequest.Filter.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
 */
proto.adx.action_log.GetActionLogsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 size = 2;
 * @return {number}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
 */
proto.adx.action_log.GetActionLogsRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
 */
proto.adx.action_log.GetActionLogsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string sort = 4;
 * @return {!Array<string>}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
 */
proto.adx.action_log.GetActionLogsRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
 */
proto.adx.action_log.GetActionLogsRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
 */
proto.adx.action_log.GetActionLogsRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
 */
proto.adx.action_log.GetActionLogsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 advertizerId = 6;
 * @return {number}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
 */
proto.adx.action_log.GetActionLogsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Filter filter = 7;
 * @return {?proto.adx.action_log.GetActionLogsRequest.Filter}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.getFilter = function() {
  return /** @type{?proto.adx.action_log.GetActionLogsRequest.Filter} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.GetActionLogsRequest.Filter, 7));
};


/**
 * @param {?proto.adx.action_log.GetActionLogsRequest.Filter|undefined} value
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
*/
proto.adx.action_log.GetActionLogsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.GetActionLogsRequest} returns this
 */
proto.adx.action_log.GetActionLogsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.GetActionLogsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.action_log.GetActionLogsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.action_log.GetActionLogsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.action_log.GetActionLogsResponse.TypeCase}
 */
proto.adx.action_log.GetActionLogsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.action_log.GetActionLogsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.action_log.GetActionLogsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.action_log.GetActionLogsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsResponse}
 */
proto.adx.action_log.GetActionLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsResponse;
  return proto.adx.action_log.GetActionLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsResponse}
 */
proto.adx.action_log.GetActionLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.action_log.GetActionLogsResponse.Result;
      reader.readMessage(value,proto.adx.action_log.GetActionLogsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.action_log.GetActionLogsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsResponse.Attachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsResponse.Attachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filetype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    signedurl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsResponse.Attachment}
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsResponse.Attachment;
  return proto.adx.action_log.GetActionLogsResponse.Attachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsResponse.Attachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsResponse.Attachment}
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiletype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsResponse.Attachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsResponse.Attachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiletype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSignedurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Attachment} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fileType = 2;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.getFiletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Attachment} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.setFiletype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fileName = 3;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Attachment} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string signedUrl = 4;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.getSignedurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Attachment} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Attachment.prototype.setSignedurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.GetActionLogsResponse.Data.repeatedFields_ = [15,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    implementationstart: jspb.Message.getFieldWithDefault(msg, 3, ""),
    implementationend: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adprovider: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    purpose: jspb.Message.getFieldWithDefault(msg, 7, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    validationstart: jspb.Message.getFieldWithDefault(msg, 9, ""),
    validationend: jspb.Message.getFieldWithDefault(msg, 10, ""),
    result: jspb.Message.getFieldWithDefault(msg, 11, ""),
    detailofresult: jspb.Message.getFieldWithDefault(msg, 12, ""),
    nextaction: jspb.Message.getFieldWithDefault(msg, 13, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.adx.action_log.GetActionLogsResponse.Attachment.toObject, includeInstance),
    order: jspb.Message.getFieldWithDefault(msg, 16, 0),
    modifiedat: jspb.Message.getFieldWithDefault(msg, 17, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    note: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data}
 */
proto.adx.action_log.GetActionLogsResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsResponse.Data;
  return proto.adx.action_log.GetActionLogsResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data}
 */
proto.adx.action_log.GetActionLogsResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationstart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationend(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdprovider(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationstart(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationend(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailofresult(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextaction(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 15:
      var value = new proto.adx.action_log.GetActionLogsResponse.Attachment;
      reader.readMessage(value,proto.adx.action_log.GetActionLogsResponse.Attachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedat(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImplementationstart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImplementationend();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdprovider();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getValidationstart();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getValidationend();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDetailofresult();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNextaction();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.adx.action_log.GetActionLogsResponse.Attachment.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getModifiedat();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string implementationStart = 3;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getImplementationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setImplementationstart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string implementationEnd = 4;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getImplementationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setImplementationend = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string adProvider = 5;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getAdprovider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setAdprovider = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string purpose = 7;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string detail = 8;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string validationStart = 9;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getValidationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setValidationstart = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string validationEnd = 10;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getValidationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setValidationend = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string result = 11;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string detailOfResult = 12;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getDetailofresult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setDetailofresult = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string nextAction = 13;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getNextaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setNextaction = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 advertizerId = 14;
 * @return {number}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated Attachment attachments = 15;
 * @return {!Array<!proto.adx.action_log.GetActionLogsResponse.Attachment>}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.adx.action_log.GetActionLogsResponse.Attachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.action_log.GetActionLogsResponse.Attachment, 15));
};


/**
 * @param {!Array<!proto.adx.action_log.GetActionLogsResponse.Attachment>} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
*/
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.adx.action_log.GetActionLogsResponse.Attachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.GetActionLogsResponse.Attachment}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.adx.action_log.GetActionLogsResponse.Attachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * optional int32 order = 16;
 * @return {number}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string modifiedAt = 17;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getModifiedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setModifiedat = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated string tags = 18;
 * @return {!Array<string>}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string note = 19;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Data.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.GetActionLogsResponse.Result.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    found: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currentpage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastpage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.adx.action_log.GetActionLogsResponse.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsResponse.Result}
 */
proto.adx.action_log.GetActionLogsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsResponse.Result;
  return proto.adx.action_log.GetActionLogsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsResponse.Result}
 */
proto.adx.action_log.GetActionLogsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFound(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentpage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastpage(value);
      break;
    case 5:
      var value = new proto.adx.action_log.GetActionLogsResponse.Data;
      reader.readMessage(value,proto.adx.action_log.GetActionLogsResponse.Data.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFound();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCurrentpage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastpage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.action_log.GetActionLogsResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Result} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 found = 2;
 * @return {number}
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.getFound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Result} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.setFound = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 currentPage = 3;
 * @return {number}
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.getCurrentpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Result} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.setCurrentpage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 lastPage = 4;
 * @return {number}
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.getLastpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Result} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.setLastpage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Data data = 5;
 * @return {!Array<!proto.adx.action_log.GetActionLogsResponse.Data>}
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.getDataList = function() {
  return /** @type{!Array<!proto.adx.action_log.GetActionLogsResponse.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.action_log.GetActionLogsResponse.Data, 5));
};


/**
 * @param {!Array<!proto.adx.action_log.GetActionLogsResponse.Data>} value
 * @return {!proto.adx.action_log.GetActionLogsResponse.Result} returns this
*/
proto.adx.action_log.GetActionLogsResponse.Result.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.action_log.GetActionLogsResponse.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.GetActionLogsResponse.Data}
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.action_log.GetActionLogsResponse.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.GetActionLogsResponse.Result} returns this
 */
proto.adx.action_log.GetActionLogsResponse.Result.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.action_log.GetActionLogsResponse.Result}
 */
proto.adx.action_log.GetActionLogsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.action_log.GetActionLogsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.GetActionLogsResponse.Result, 1));
};


/**
 * @param {?proto.adx.action_log.GetActionLogsResponse.Result|undefined} value
 * @return {!proto.adx.action_log.GetActionLogsResponse} returns this
*/
proto.adx.action_log.GetActionLogsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.action_log.GetActionLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.GetActionLogsResponse} returns this
 */
proto.adx.action_log.GetActionLogsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.GetActionLogsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsResponse} returns this
 */
proto.adx.action_log.GetActionLogsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.GetActionLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.GetActionLogsResponse} returns this
 */
proto.adx.action_log.GetActionLogsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.GetActionLogsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.GetActionLogsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsFilesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsFilesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsFilesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsFilesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsFilesRequest}
 */
proto.adx.action_log.GetActionLogsFilesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsFilesRequest;
  return proto.adx.action_log.GetActionLogsFilesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsFilesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsFilesRequest}
 */
proto.adx.action_log.GetActionLogsFilesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsFilesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsFilesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsFilesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsFilesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsFilesRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsFilesRequest} returns this
 */
proto.adx.action_log.GetActionLogsFilesRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fileId = 2;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsFilesRequest.prototype.getFileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsFilesRequest} returns this
 */
proto.adx.action_log.GetActionLogsFilesRequest.prototype.setFileid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.action_log.GetActionLogsFilesRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.GetActionLogsFilesRequest} returns this
 */
proto.adx.action_log.GetActionLogsFilesRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.action_log.GetActionLogsFilesResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.action_log.GetActionLogsFilesResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.action_log.GetActionLogsFilesResponse.TypeCase}
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.action_log.GetActionLogsFilesResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.action_log.GetActionLogsFilesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsFilesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsFilesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsFilesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.action_log.GetActionLogsFilesResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse}
 */
proto.adx.action_log.GetActionLogsFilesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsFilesResponse;
  return proto.adx.action_log.GetActionLogsFilesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsFilesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse}
 */
proto.adx.action_log.GetActionLogsFilesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.action_log.GetActionLogsFilesResponse.Result;
      reader.readMessage(value,proto.adx.action_log.GetActionLogsFilesResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsFilesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsFilesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsFilesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.action_log.GetActionLogsFilesResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsFilesResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsFilesResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filetype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    signedurl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Data}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsFilesResponse.Data;
  return proto.adx.action_log.GetActionLogsFilesResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsFilesResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Data}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiletype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsFilesResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsFilesResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiletype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSignedurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fileType = 2;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.getFiletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.setFiletype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fileName = 3;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string signedUrl = 4;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.getSignedurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Data} returns this
 */
proto.adx.action_log.GetActionLogsFilesResponse.Data.prototype.setSignedurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.GetActionLogsFilesResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.GetActionLogsFilesResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.adx.action_log.GetActionLogsFilesResponse.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Result}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.GetActionLogsFilesResponse.Result;
  return proto.adx.action_log.GetActionLogsFilesResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.GetActionLogsFilesResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Result}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.action_log.GetActionLogsFilesResponse.Data;
      reader.readMessage(value,proto.adx.action_log.GetActionLogsFilesResponse.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.GetActionLogsFilesResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.GetActionLogsFilesResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.action_log.GetActionLogsFilesResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Result} returns this
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Data data = 2;
 * @return {?proto.adx.action_log.GetActionLogsFilesResponse.Data}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.prototype.getData = function() {
  return /** @type{?proto.adx.action_log.GetActionLogsFilesResponse.Data} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.GetActionLogsFilesResponse.Data, 2));
};


/**
 * @param {?proto.adx.action_log.GetActionLogsFilesResponse.Data|undefined} value
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Result} returns this
*/
proto.adx.action_log.GetActionLogsFilesResponse.Result.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse.Result} returns this
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.GetActionLogsFilesResponse.Result.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.action_log.GetActionLogsFilesResponse.Result}
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.action_log.GetActionLogsFilesResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.GetActionLogsFilesResponse.Result, 1));
};


/**
 * @param {?proto.adx.action_log.GetActionLogsFilesResponse.Result|undefined} value
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse} returns this
*/
proto.adx.action_log.GetActionLogsFilesResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.action_log.GetActionLogsFilesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse} returns this
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse} returns this
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.GetActionLogsFilesResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.GetActionLogsFilesResponse} returns this
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.GetActionLogsFilesResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.GetActionLogsFilesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.UpdateActionLogsRequest.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.UpdateActionLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.UpdateActionLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.UpdateActionLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    implementationstart: jspb.Message.getFieldWithDefault(msg, 4, ""),
    implementationend: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adprovider: jspb.Message.getFieldWithDefault(msg, 6, ""),
    title: jspb.Message.getFieldWithDefault(msg, 7, ""),
    purpose: jspb.Message.getFieldWithDefault(msg, 8, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 9, ""),
    validationstart: jspb.Message.getFieldWithDefault(msg, 10, ""),
    validationend: jspb.Message.getFieldWithDefault(msg, 11, ""),
    result: jspb.Message.getFieldWithDefault(msg, 12, ""),
    detailofresult: jspb.Message.getFieldWithDefault(msg, 13, ""),
    nextaction: jspb.Message.getFieldWithDefault(msg, 14, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 15, 0),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    note: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest}
 */
proto.adx.action_log.UpdateActionLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.UpdateActionLogsRequest;
  return proto.adx.action_log.UpdateActionLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.UpdateActionLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest}
 */
proto.adx.action_log.UpdateActionLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationstart(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationend(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdprovider(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationstart(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationend(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailofresult(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextaction(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.UpdateActionLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.UpdateActionLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.UpdateActionLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string implementationStart = 4;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getImplementationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setImplementationstart = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearImplementationstart = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasImplementationstart = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string implementationEnd = 5;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getImplementationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setImplementationend = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearImplementationend = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasImplementationend = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string adProvider = 6;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getAdprovider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setAdprovider = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearAdprovider = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasAdprovider = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string title = 7;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string purpose = 8;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setPurpose = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearPurpose = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasPurpose = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string detail = 9;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setDetail = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearDetail = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string validationStart = 10;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getValidationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setValidationstart = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearValidationstart = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasValidationstart = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string validationEnd = 11;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getValidationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setValidationend = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearValidationend = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasValidationend = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string result = 12;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setResult = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearResult = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasResult = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string detailOfResult = 13;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getDetailofresult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setDetailofresult = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearDetailofresult = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasDetailofresult = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string nextAction = 14;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getNextaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setNextaction = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearNextaction = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasNextaction = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int32 advertizerId = 15;
 * @return {number}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated string tags = 16;
 * @return {!Array<string>}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string note = 17;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.setNote = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsRequest} returns this
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.clearNote = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsRequest.prototype.hasNote = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.action_log.UpdateActionLogsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.action_log.UpdateActionLogsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.action_log.UpdateActionLogsResponse.TypeCase}
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.action_log.UpdateActionLogsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.action_log.UpdateActionLogsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.UpdateActionLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.UpdateActionLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.UpdateActionLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.action_log.UpdateActionLogsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse}
 */
proto.adx.action_log.UpdateActionLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.UpdateActionLogsResponse;
  return proto.adx.action_log.UpdateActionLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.UpdateActionLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse}
 */
proto.adx.action_log.UpdateActionLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.action_log.UpdateActionLogsResponse.Result;
      reader.readMessage(value,proto.adx.action_log.UpdateActionLogsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.UpdateActionLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.UpdateActionLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.UpdateActionLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.action_log.UpdateActionLogsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.UpdateActionLogsResponse.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.UpdateActionLogsResponse.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    implementationstart: jspb.Message.getFieldWithDefault(msg, 3, ""),
    implementationend: jspb.Message.getFieldWithDefault(msg, 4, ""),
    adprovider: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    purpose: jspb.Message.getFieldWithDefault(msg, 7, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    validationstart: jspb.Message.getFieldWithDefault(msg, 9, ""),
    validationend: jspb.Message.getFieldWithDefault(msg, 10, ""),
    result: jspb.Message.getFieldWithDefault(msg, 11, ""),
    detailofresult: jspb.Message.getFieldWithDefault(msg, 12, ""),
    nextaction: jspb.Message.getFieldWithDefault(msg, 13, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    note: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.UpdateActionLogsResponse.Data;
  return proto.adx.action_log.UpdateActionLogsResponse.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.UpdateActionLogsResponse.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationstart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationend(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdprovider(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationstart(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationend(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailofresult(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextaction(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.UpdateActionLogsResponse.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.UpdateActionLogsResponse.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImplementationstart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImplementationend();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdprovider();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getValidationstart();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getValidationend();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDetailofresult();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNextaction();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string implementationStart = 3;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getImplementationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setImplementationstart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string implementationEnd = 4;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getImplementationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setImplementationend = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string adProvider = 5;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getAdprovider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setAdprovider = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string purpose = 7;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string detail = 8;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string validationStart = 9;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getValidationstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setValidationstart = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string validationEnd = 10;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getValidationend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setValidationend = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string result = 11;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string detailOfResult = 12;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getDetailofresult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setDetailofresult = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string nextAction = 13;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getNextaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setNextaction = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 advertizerId = 14;
 * @return {number}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated string tags = 15;
 * @return {!Array<string>}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string note = 16;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Data} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Data.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.UpdateActionLogsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.UpdateActionLogsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: (f = msg.getData()) && proto.adx.action_log.UpdateActionLogsResponse.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Result}
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.UpdateActionLogsResponse.Result;
  return proto.adx.action_log.UpdateActionLogsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.UpdateActionLogsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Result}
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.action_log.UpdateActionLogsResponse.Data;
      reader.readMessage(value,proto.adx.action_log.UpdateActionLogsResponse.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.UpdateActionLogsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.UpdateActionLogsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.action_log.UpdateActionLogsResponse.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Result} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Data data = 2;
 * @return {?proto.adx.action_log.UpdateActionLogsResponse.Data}
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.prototype.getData = function() {
  return /** @type{?proto.adx.action_log.UpdateActionLogsResponse.Data} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.UpdateActionLogsResponse.Data, 2));
};


/**
 * @param {?proto.adx.action_log.UpdateActionLogsResponse.Data|undefined} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Result} returns this
*/
proto.adx.action_log.UpdateActionLogsResponse.Result.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse.Result} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsResponse.Result.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.action_log.UpdateActionLogsResponse.Result}
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.action_log.UpdateActionLogsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.UpdateActionLogsResponse.Result, 1));
};


/**
 * @param {?proto.adx.action_log.UpdateActionLogsResponse.Result|undefined} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse} returns this
*/
proto.adx.action_log.UpdateActionLogsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.action_log.UpdateActionLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.UpdateActionLogsResponse} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.UpdateActionLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.UpdateActionLogsResponse} returns this
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.UpdateActionLogsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.UpdateActionLogsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.SaveActionLogsOrderRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.SaveActionLogsOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.SaveActionLogsOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionlogsorderList: jspb.Message.toObjectList(msg.getActionlogsorderList(),
    proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.SaveActionLogsOrderRequest;
  return proto.adx.action_log.SaveActionLogsOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.SaveActionLogsOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder;
      reader.readMessage(value,proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.deserializeBinaryFromReader);
      msg.addActionlogsorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.SaveActionLogsOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.SaveActionLogsOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionlogsorderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder;
  return proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder} returns this
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder} returns this
 */
proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest} returns this
 */
proto.adx.action_log.SaveActionLogsOrderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ActionLogOrder actionLogsOrder = 2;
 * @return {!Array<!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder>}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.prototype.getActionlogsorderList = function() {
  return /** @type{!Array<!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder, 2));
};


/**
 * @param {!Array<!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder>} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest} returns this
*/
proto.adx.action_log.SaveActionLogsOrderRequest.prototype.setActionlogsorderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder}
 */
proto.adx.action_log.SaveActionLogsOrderRequest.prototype.addActionlogsorder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.action_log.SaveActionLogsOrderRequest.ActionLogOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.SaveActionLogsOrderRequest} returns this
 */
proto.adx.action_log.SaveActionLogsOrderRequest.prototype.clearActionlogsorderList = function() {
  return this.setActionlogsorderList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.action_log.SaveActionLogsOrderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.action_log.SaveActionLogsOrderResponse.TypeCase}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.action_log.SaveActionLogsOrderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.action_log.SaveActionLogsOrderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.SaveActionLogsOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.action_log.SaveActionLogsOrderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.SaveActionLogsOrderResponse;
  return proto.adx.action_log.SaveActionLogsOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.action_log.SaveActionLogsOrderResponse.Result;
      reader.readMessage(value,proto.adx.action_log.SaveActionLogsOrderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.SaveActionLogsOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.action_log.SaveActionLogsOrderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.SaveActionLogsOrderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionlogsorderList: jspb.Message.toObjectList(msg.getActionlogsorderList(),
    proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.Result}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.SaveActionLogsOrderResponse.Result;
  return proto.adx.action_log.SaveActionLogsOrderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.Result}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder;
      reader.readMessage(value,proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.deserializeBinaryFromReader);
      msg.addActionlogsorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.SaveActionLogsOrderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionlogsorderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.Result} returns this
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ActionLogOrder actionLogsOrder = 2;
 * @return {!Array<!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder>}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.prototype.getActionlogsorderList = function() {
  return /** @type{!Array<!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder, 2));
};


/**
 * @param {!Array<!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder>} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.Result} returns this
*/
proto.adx.action_log.SaveActionLogsOrderResponse.Result.prototype.setActionlogsorderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.prototype.addActionlogsorder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.Result} returns this
 */
proto.adx.action_log.SaveActionLogsOrderResponse.Result.prototype.clearActionlogsorderList = function() {
  return this.setActionlogsorderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder;
  return proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder} returns this
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder} returns this
 */
proto.adx.action_log.SaveActionLogsOrderResponse.ActionLogOrder.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.action_log.SaveActionLogsOrderResponse.Result}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.action_log.SaveActionLogsOrderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.action_log.SaveActionLogsOrderResponse.Result, 1));
};


/**
 * @param {?proto.adx.action_log.SaveActionLogsOrderResponse.Result|undefined} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse} returns this
*/
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.action_log.SaveActionLogsOrderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse} returns this
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse} returns this
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.SaveActionLogsOrderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.action_log.SaveActionLogsOrderResponse} returns this
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.action_log.SaveActionLogsOrderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.action_log.SaveActionLogsOrderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.action_log);
