import {
    generateNewSprint,
    createSprint,
    deleteSprint,
    getConsultantSprints,
    createCustomField,
    updateCustomField,
    deleteCustomField,
    getCustomFields
} from "../../com/grpc/sprint.js";
// import {getTabListOfAdProviderReportPage} from "../../com/grpc/ad_provider";
import { uuid } from "../../tacklebox/core/uuid";

import { sprintApps } from '@/js/setting.js';

import { getInformationLatestUpdate } from "../../com/grpc/information";
import {data2dateandweek} from '../../tacklebox/date/time.js';


function EditBall(raw, callback = function () { }, scallback = function () { }) {
    this.raw = raw;
    this.callback = callback;
    this.scallback = scallback;
}
EditBall.prototype.save = function (edit) {
    try {
        this.callback(edit);
    } catch (ex) {
        console.log(ex);
    }
};
EditBall.prototype.start = function () {
    this.scallback();
};

function SprintBall(raw = {}, callback = function () { }, scallback = function () { }) {
    this.raw = raw;
    this.callback = callback;
    this.scallback = scallback;
    this.isSelected = false;
}
SprintBall.prototype.getWeek = function () {
    if (this.raw?.startdate) {
        return this.raw.startdate.replaceAll("-", "/");
    }
    return "";
};
SprintBall.prototype.getDataByKey = function (key) {
    try {
        if (this.raw[key]) {
            return this.raw[key];
        }
    } catch (ex) {
        console.log(ex);
    }
    return "";
};
SprintBall.prototype.getCstfDataByKey = function (key) {
    try {
        for (let i in this.raw.customfieldsList) {
            let csf = this.raw.customfieldsList[i];
            if (csf.field == key) {
                return csf.value;
            }
        }
    } catch (ex) {
        console.log(ex);
    }
    return "";
}

SprintBall.prototype.getEditBallByKey = function (key) {
    try {
        // console.log(key, this.raw);
        let self = this;
        let keys = Object.keys(this.raw);
        for (let k in keys) {
            if (keys[k] == key) {
                return new EditBall(this.raw[key], function (edit) {
                    self.callback(key, edit);
                }, function () {
                    self.scallback();
                });
            }
        }
    } catch (ex) {
        console.log(ex);
    }
    return "";
};
SprintBall.prototype.getEditBallCstFByKey = function (key) {
    try {
        let self = this;
        for (let k in this.raw.customfieldsList) {
            if (this.raw.customfieldsList[k].field == key) {
                return new EditBall(this.raw.customfieldsList[k].value, function (edit) {
                    self.callback(key, edit);
                }, function () {
                    self.scallback();
                });
            }
        }
    } catch (ex) {
        console.log(ex);
    }
    return "";
};
function SelectedEditKey(id, key) {
    this.id = id;
    this.key = key;
}
function SelectedCell(id, key) {
    this.id = id;
    this.key = key;
}

function Holder(raws = [], advertizerId, user, actualMonth) {
    this.raws = raws;
    this.creates = [];
    this.selectedEditKey = null;
    this.advertizerId = advertizerId;
    this.user = user;
    this.actualMonth = actualMonth;
    this.currentPageStreams = [];
    this.selectedDeleteId = "";
    this.customs = [];
    this.selectedCells = [];
    this.isSelectRevers = false;
    this.keyEnterCell = null;
    this.isDataLoad = false;
}
Holder.prototype.keyEnter = function () {
    if (this.selectedCells) {
        if (this.selectedCells.length > 0) {
            if (this.keyEnterCell == null || !(this.keyEnterCell.id == this.selectedCells[0].id) && (this.keyEnterCell.key == this.selectedCells[0].key)) {
                this.startEdit(this.selectedCells[0].id, this.selectedCells[0].key);
            }
        }
    }
};
Holder.prototype.copyClipboradSelectedCell = function () {
    let text = "";
    const self = this;
    function _type2str(ap) {
        let type = 0;
        try {
            type = ap.type;
        } catch (ex) {
            console.log(ex);
        }
        for (let i in sprintApps) {
            let sa = sprintApps[i];
            if (sa.id == type) {
                return sa.data;
            }
        }
        return '-';
    }
    function _(targetkey, raw) {
        let value = null;
        let txt = "";
        try {
            value = raw[targetkey];
        } catch (ex) {
            console.log(ex);
        }
        switch (targetkey) {
            case "mayorpolicy":
                // txt += "【大方針】\r\n";
                txt += value;
                break;
            case "monthlygoalachieve":
                // txt += "【単月目標達成可否】\r\n";
                txt += value;
                break;
            case "monthlypolicy":
                // txt += "【月内方針】\r\n";
                txt += value;
                break;
            case "monthlystatustext":
                // txt += "【月内進捗状況】\r\n";
                try {
                    let sjs = JSON.parse(value);
                    let cnt = 0;
                    for (let i in sjs) {
                        cnt++;
                        if (cnt > 1) {
                            txt += "\r\n";
                        }
                        txt += (sjs[i].label + " : " + self.vtunit(sjs[i].value, sjs[i].type) + "(" + sjs[i].rate + "%)");
                    }
                } catch (ex) {
                    console.log(ex);
                }

                break;
            case "overview":
                // txt += "【概況】\r\n";
                txt += value;
                break;
            case "problempoints":
                // txt += "【課題箇所】\r\n";
                txt += value;
                break;
            case "problemfactors":
                // txt += "【課題要因】\r\n";
                txt += value;
                break;
            case "approachpolicy":
                // txt += "【対策方針】\r\n";
                try {
                    let sjs2 = JSON.parse(value);
                    let cnt = 0;
                    for (let i in sjs2) {
                        cnt++;
                        if (cnt > 1) {
                            txt += "\r\n\r\n";
                        }
                        txt += ("(" + _type2str(sjs2[i]) + ")\r\n");
                        txt += ("" + sjs2[i].data);
                    }
                } catch (ex) {
                    console.log(ex);
                }
                break;
            case "todoactionitemsList":
                // txt += "【改善施策】\r\n";
                {
                    let cnt2 = 0;
                    for (let i in value) {
                        cnt2++;
                        if (cnt2 > 1) {
                            txt += "\r\n";
                        }
                        let todoactionitems = value[i];
                        txt += (todoactionitems.provider + " - " + todoactionitems.title + todoactionitems?.implementationdate + "-" + todoactionitems?.validationdate + "");
                    }
                }
                break;
            case "inprogressactionitemsList":
                // txt += "【進行中施策】\r\n";
                {
                    let cnt1 = 0;
                    for (let i in value) {
                        cnt1++;
                        if (cnt1 > 1) {
                            txt += "\r\n";
                        }
                        let inprogressactionitems = value[i];
                        txt += (inprogressactionitems.provider + " - " + inprogressactionitems.title + inprogressactionitems?.implementationdate + "" + inprogressactionitems?.validationdate + "");
                    }
                }
                break;
            case "mdfeedback":
                // txt += "【MDフィードバック】\r\n";
                txt += value;
                break;

            default:
                break;
        }

        return txt + "\r\n\r\n";
    }

    function _cstf(targetkey, raw) {
        let txt = "";
        if (raw?.customfieldsList) {
            for (let i in raw.customfieldsList) {
                let cstf = raw.customfieldsList[i];
                if (cstf.field == targetkey) {
                    return cstf.value;
                }
            }
        }

        return txt;
    }

    if (this.isSelectRevers) {
        for (let i = this.selectedCells.length - 1; i >= 0; i--) {
            let cell = this.selectedCells[i];
            for (let i in this.raws) {
                let raw = this.raws[i];
                if (raw.id == cell.id) {
                    text += _(cell.key, raw);
                    text += _cstf(cell.key, raw);
                    break;
                }
            }
        }
    } else {
        for (let i in this.selectedCells) {
            let cell = this.selectedCells[i];
            for (let i in this.raws) {
                let raw = this.raws[i];
                if (raw.id == cell.id) {
                    text += _(cell.key, raw);
                    text += _cstf(cell.key, raw);
                    break;
                }
            }
        }

    }

    if (this.selectedCells.length == 0) {
        return;
    }

    let type = 'text/plain';
    let blob = new Blob([text], { type });
    let data = [new window.ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(
        function () {
            /* success */
        },
        function () {
            /* failure */
        }
    );

};
Holder.prototype.getKeys = function () {
    let keys = [];

    keys.push('mayorpolicy');
    keys.push('monthlygoalachieve');
    keys.push('monthlypolicy');
    keys.push('monthlystatustext');
    keys.push('overview');
    keys.push('problempoints');
    keys.push('problemfactors');
    keys.push('approachpolicy');
    keys.push('todoactionitemsList');
    keys.push('inprogressactionitemsList');
    keys.push('mdfeedback');

    return keys;
};
Holder.prototype.keyleft = function () {
    if (this.selectedCells.length > 0) {
        this.keyEnterCell = null;
        let now = this.selectedCells[this.selectedCells.length - 1];
        for (let i = 0; i < this.raws.length; i++) {
            let raw = this.raws[i];
            if (raw.id == now.id) {
                if (i > 0) {
                    let targetId = this.raws[i - 1].id;
                    this.selectedCells = [];
                    this.selectedCells.push(new SelectedCell(targetId, now.key));
                    return;
                }
            }
        }
    }
};
Holder.prototype.keyright = function () {
    if (this.selectedCells.length > 0) {
        this.keyEnterCell = null;
        let now = this.selectedCells[this.selectedCells.length - 1];
        for (let i = 0; i < this.raws.length; i++) {
            let raw = this.raws[i];
            if (raw.id == now.id) {
                if (i < this.raws.length - 1) {
                    let targetId = this.raws[i + 1].id;
                    this.selectedCells = [];
                    this.selectedCells.push(new SelectedCell(targetId, now.key));
                    return;
                }
            }
        }
    }

};
Holder.prototype.keydown = function () {
    if (this.selectedCells.length > 0) {
        this.keyEnterCell = null;
        let keys = this.getKeys();
        let csts = this.customs;
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] == this.selectedCells[0].key) {
                if (keys.length > i + 1) {
                    let targetId = this.selectedCells[0].id;
                    let targetKey = keys[i + 1];
                    this.isSelectRevers = false;
                    this.selectedCells = [];
                    this.selectedCells.push(new SelectedCell(targetId, targetKey));
                    return;
                } else {
                    if (csts.length > 0) {
                        let targetId = this.selectedCells[0].id;
                        let targetKey = csts[0].id;
                        this.isSelectRevers = false;
                        this.selectedCells = [];
                        this.selectedCells.push(new SelectedCell(targetId, targetKey));
                        return;
                    }
                }
            }
        }

        for (let i = 0; i < csts.length; i++) {
            if (csts[i].id == this.selectedCells[0].key) {
                if (csts.length > i) {
                    let targetId = this.selectedCells[0].id;
                    let targetKey = csts[i + 1].id;
                    this.isSelectRevers = false;
                    this.selectedCells = [];
                    this.selectedCells.push(new SelectedCell(targetId, targetKey));
                    return;
                }
            }
        }
    }
};
Holder.prototype.keydownShift = function () {
    if (this.selectedCells.length > 0) {
        this.keyEnterCell = null;
        if (this.isSelectRevers) {
            if (this.selectedCells.length > 1) {
                this.selectedCells.pop();
            } else {
                this.isSelectRevers = false;
            }
        }
        if (this.isSelectRevers) {
            return;
        }

        let keys = this.getKeys();
        let csts = this.customs;
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] == this.selectedCells[this.selectedCells.length - 1].key) {
                if (keys.length > i + 1) {
                    let targetId = this.selectedCells[this.selectedCells.length - 1].id;
                    let targetKey = keys[i + 1];
                    this.selectedCells.push(new SelectedCell(targetId, targetKey));
                    return;
                } else {
                    if (csts.length > 0) {
                        let targetId = this.selectedCells[this.selectedCells.length - 1].id;
                        let targetKey = csts[0].id;
                        this.selectedCells.push(new SelectedCell(targetId, targetKey));
                        return;
                    }
                }
            }
        }

        for (let j = 0; j < csts.length; j++) {
            if (csts[j].id == this.selectedCells[this.selectedCells.length - 1].key) {
                if (csts.length > j) {
                    let targetIdJ = this.selectedCells[this.selectedCells.length - 1].id;
                    let targetKeyJ = csts[j + 1].id;
                    this.selectedCells.push(new SelectedCell(targetIdJ, targetKeyJ));
                    return;
                }
            }
        }
    }
};
Holder.prototype.keyup = function () {
    if (this.selectedCells.length > 0) {
        this.keyEnterCell = null;
        let keys = this.getKeys();
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] == this.selectedCells[0].key) {
                if (i > 0) {
                    let targetId = this.selectedCells[0].id;
                    let targetKey = keys[i - 1];
                    this.isSelectRevers = false;
                    this.selectedCells = [];
                    this.selectedCells.push(new SelectedCell(targetId, targetKey));
                    return;
                }
            }
        }
        let csts = this.customs;
        for (let i = 0; i < csts.length; i++) {
            if (csts[i].id == this.selectedCells[0].key) {
                if (i > 0) {
                    let targetId = this.selectedCells[0].id;
                    let targetKey = csts[i - 1].id;
                    this.isSelectRevers = false;
                    this.selectedCells = [];
                    this.selectedCells.push(new SelectedCell(targetId, targetKey));
                    return;
                } else {
                    let targetId = this.selectedCells[0].id;
                    let kkks = this.getKeys();
                    let targetKey = kkks[kkks.length - 1];
                    this.isSelectRevers = false;
                    this.selectedCells = [];
                    this.selectedCells.push(new SelectedCell(targetId, targetKey));
                    return;
                }
            }
        }

    }
};
Holder.prototype.keyupShift = function () {
    if (this.selectedCells.length >= 1) {
        this.keyEnterCell = null;

        if (this.selectedCells.length == 1) {
            this.isSelectRevers = true;
        }

        let keys = this.getKeys();
        if (this.isSelectRevers) {
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] == this.selectedCells[this.selectedCells.length - 1].key) {
                    if (i > 0) {
                        let targetId = this.selectedCells[0].id;
                        let targetKey = keys[i - 1];
                        this.selectedCells.push(new SelectedCell(targetId, targetKey));
                    }
                }
            }
            let csts = this.customs;
            for (let i = 0; i < csts.length; i++) {
                if (csts[i].id == this.selectedCells[this.selectedCells.length - 1].key) {
                    if (i > 0) {
                        let targetId = this.selectedCells[0].id;
                        let targetKey = csts[i - 1].id;
                        this.selectedCells.push(new SelectedCell(targetId, targetKey));
                        return;
                    } else {
                        let targetId = this.selectedCells[0].id;
                        let kkks = this.getKeys();
                        let targetKey = kkks[kkks.length - 1];
                        this.selectedCells.push(new SelectedCell(targetId, targetKey));
                        return;
                    }
                }
            }
            return;
        }
        this.selectedCells.pop();
        // for (let i = 0; i < keys.length; i++) {
        //     if (keys[i] == this.selectedCells[0].key) {
        //         if (this.isSelectRevers) {
        //             if (i > 0) {
        //                 let targetId = this.selectedCells[0].id;
        //                 let targetKey = keys[i - 1];
        //                 this.selectedCells.push(new SelectedCell(targetId, targetKey));
        //             }
        //         } else {
        //             if (i > 0) {
        //                 this.selectedCells.pop();
        //                 return;
        //             }

        //         }
        //     }
        // }
    }
};


Holder.prototype.addraw = function (raw) {
    this.raws.push(raw);
};
Holder.prototype.getSprintBalls = function () {
    let balls = [];
    for (let i in this.raws) {
        let raw = this.raws[i];
        balls.push(new SprintBall(raw));
    }
    return balls;
};
Holder.prototype.getDataSprintBalls = function (key) {
    let self = this;
    let balls = [];
    for (let i in this.raws) {
        let raw = this.raws[i];
        let isHit = false;
        let keys = Object.keys(raw);
        for (let k in keys) {
            if (keys[k] == key) {
                isHit = true;
                balls.push(new SprintBall(raw, function (key, edit) {
                    try {
                        this.raw[key] = edit;
                    } catch (ex) {
                        console.log(ex);
                    }
                    self.updateSprint(self.advertizerId, self.user, this.raw);
                    self.selectedCells = [];
                    self.selectedCells.push(new SelectedCell(raw.id, key));
                    self.reset();
                }));
                break;
            }
        }
        if (!isHit) {
            balls.push(new SprintBall(null));
        }
    }
    return balls;
}
Holder.prototype.getDataSprintBallsCustomField = function (key) {
    let self = this;
    let balls = [];

    for (let i in this.raws) {
        let raw = this.raws[i];
        let isHit = false;
        for (let k in raw.customfieldsList) {
            if (raw.customfieldsList[k].field == key) {
                isHit = true;
                balls.push(new SprintBall(raw, function (key, edit) {
                    try {
                        for (let k in this.raw.customfieldsList) {
                            if (this.raw.customfieldsList[k].field == key) {
                                this.raw.customfieldsList[k].value = edit;
                                break;
                            }
                        }
                    } catch (ex) {
                        console.log(ex);
                    }
                    self.updateSprint(self.advertizerId, self.user, this.raw);
                    self.selectedCells = [];
                    self.selectedCells.push(new SelectedCell(raw.id, key));

                    self.reset();
                }));
                break;
            }
        }
        if (!isHit) {
            balls.push(new SprintBall(null));
        }
    }
    return balls;
}

Holder.prototype.startEdit = function (id, selectedKey) {
    this.keyEnterCell = new SelectedCell(id, selectedKey);
    this.selectedCells = [];
    this.selectedEditKey = new SelectedEditKey(id, selectedKey);
}
Holder.prototype.isEdit = function (id, selectedKey) {
    if (this.selectedEditKey) {
        return this.selectedEditKey.id == id && this.selectedEditKey.key == selectedKey;
    }
    return false;
};
Holder.prototype.reset = function () {
    this.selectedEditKey = null;
};
Holder.prototype.resetSelectCell = function () {
    this.selectedCells = [];
    this.selectedEditKey = null;
};
Holder.prototype.getNewDateRange = function () {
    let self = this;
    let dt = null;
    function _(dt = null) {
        if (dt == null) {
            let ss = self.actualMonth.split("-");

            dt = new Date(ss[0], Number(ss[1]) - 1, 1);
        }
        /*
        dt.setDate(dt.getDate() - (dt.getDay()));
        let edt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
        edt.setDate(edt.getDate() - (edt.getDay()) + 6);
        return { start: "" + dt.getFullYear() + "-" + (('00' + (dt.getMonth() + 1)).slice(-2)) + "-" + (('00' + (dt.getDate())).slice(-2)), end: "" + edt.getFullYear() + "-" + (('00' + (edt.getMonth() + 1)).slice(-2)) + "-" + (('00' + (edt.getDate())).slice(-2)) };
        */
        return { start: "" + dt.getFullYear() + "-" + (('00' + (dt.getMonth() + 1)).slice(-2)) + "-" + (('00' + (dt.getDate())).slice(-2)), end: "" + dt.getFullYear() + "-" + (('00' + (dt.getMonth() + 1)).slice(-2)) + "-" + (('00' + (dt.getDate())).slice(-2)) };
    }
    if (this.raws) {
        if (this.raws.length > 0) {
            let eds = this.raws[this.raws.length - 1].enddate;
            let edstmp = eds.replaceAll("-", "/");
            let edss = edstmp.split("/");
            dt = new Date(edss[0], Number(edss[1]) - 1, edss[2]);
            dt.setDate(dt.getDate() + 1);
        }
    }
    return _(dt);
};
Holder.prototype.load = function () {
    let self = this;

    // self.raws = [];
    // self.customs = [];

    this.isDataLoad = true;

    let request = {
        advertizerId: this.advertizerId,
        actualMonth: this.actualMonth,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    let cuuid3 = getCustomFields(request, function (uuid, data) {
        if (data != null && cuuid3 == uuid) {
            // let json = JSON.parse(JSON.stringify(data.result));
            // if (!!(json.structure) != false) {
            //     self.recordHolder.pushDataStream(json, json.requestId);
            // }
            let json = JSON.parse(JSON.stringify(data.result));
            self.customs = json.fieldsList;
            const stocks = [];
            let cuuid1 = getConsultantSprints(request, function (uuid, data) {
                if (data != null && cuuid1 == uuid) {
                    let json = JSON.parse(JSON.stringify(data.result));
                    // if (!!(json.structure) != false) {
                    //     self.recordHolder.pushDataStream(json, json.requestId);
                    // }
                    for (let i in json.sprintsList) {
                        let sl = json.sprintsList[i];
                        stocks.push(sl.id);
                        for (let j in self.customs) {
                            let isHit = false;
                            let cs = self.customs[j];
                            for (let k in sl.customfieldsList) {
                                if (cs.id == sl.customfieldsList[k].field) {
                                    isHit = true;
                                }
                            }
                            if (!isHit) {
                                sl.customfieldsList.push({ field: cs.id, value: "-" });
                            }
                        }
                        let isUpdate = false;
                        for (let i in self.raws) {
                            let raw = self.raws[i];
                            if (raw.id == sl.id) {
                                isUpdate = true;
                                self.raws[i] = sl;
                                break;
                            }
                        }
                        if (!isUpdate) {
                            self.addraw(sl);
                        }
                    }

                }
            }, () => {
                if (self.raws.length > 0) {
                    for (let i = self.raws.length - 1 ; i >= 0 ; i--) {
                        let isDelete = true;
                        for (let j in stocks) {
                            let sp = stocks[j];
                            if (self.raws[i].id == sp) {
                                isDelete = false;
                            }
                        }
                        if (isDelete) {
                            self.raws.splice(i, 1);
                        }
                    }
                }
                self.isDataLoad = false;
            }, "", self.currentPageStreams1);

        } else {
            if (data) {
                //
            }
        }
    }, () => { }, "", this.currentPageStreams3);



};
Holder.prototype.createSprint = function (advertizerId, user, sprint) {
    let self = this;

    this.advertizerId = advertizerId;
    this.user = user;
    this.cancelAllAPICall();

    this.currentPageStreams = [];

    sprint.startdate = sprint.startdate.replaceAll("/", "-");
    sprint.enddate = sprint.enddate.replaceAll("/", "-");

    let request = {
        advertizerId: this.advertizerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        sprint: sprint,
    };
    let cuuid = createSprint(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            // let json = JSON.parse(JSON.stringify(data.result));
            // if (!!(json.structure) != false) {
            //     self.recordHolder.pushDataStream(json, json.requestId);
            // }
            self.load();
        }
    }, () => { }, "", this.currentPageStreams);
};
Holder.prototype.updateSprint = function (advertizerId, user, sprint) {
    // let self = this;

    this.advertizerId = advertizerId;
    this.user = user;
    this.cancelAllAPICall();

    this.currentPageStreams = [];

    sprint.startdate = sprint.startdate.replaceAll("/", "-");
    sprint.enddate = sprint.enddate.replaceAll("/", "-");
    let request = {
        advertizerId: this.advertizerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        sprint: sprint,
    };
    let cuuid = createSprint(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            // let json = JSON.parse(JSON.stringify(data.result));
            // if (!!(json.structure) != false) {
            //     self.recordHolder.pushDataStream(json, json.requestId);
            // }
        }
    }, () => { }, "", this.currentPageStreams);
};
Holder.prototype.cancelAllAPICall = function () {
    this.currentPageStreams.forEach(stream => stream.cancel());
}
Holder.prototype.deleteSprint = function (id) {
    let self = this;
    this.currentPageStreams = [];

    let request = {
        advertizerId: this.advertizerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        id: id,
    };
    let cuuid = deleteSprint(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            // let json = JSON.parse(JSON.stringify(data.result));
            // if (!!(json.structure) != false) {
            //     self.recordHolder.pushDataStream(json, json.requestId);
            // }
            self.load();
        }
    }, () => { }, "", this.currentPageStreams);
};
Holder.prototype.selectDeleteId = function (id) {
    this.selectedDeleteId = id;
};
Holder.prototype.selectCancel = function () {
    this.selectedDeleteId = "";
}
Holder.prototype.deleteCstfSprint = function (id) {
    let self = this;
    let currentPageStreams = [];

    let request = {
        advertizerId: this.advertizerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        id: id,
    };
    let cuuid = deleteCustomField(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            // let json = JSON.parse(JSON.stringify(data.result));
            // if (!!(json.structure) != false) {
            //     self.recordHolder.pushDataStream(json, json.requestId);
            // }
            self.load();
        }
    }, () => { }, "", currentPageStreams);
};
Holder.prototype.renameCstfSprint = function (id, label) {
    let self = this;

    this.currentPageStreams = [];

    let request = {
        advertizerId: this.advertizerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        field: { id: id, label: label },
    };

    let cuuid = updateCustomField(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (json) {
                //
            }
            self.load();
        }
    }, () => { }, "", this.currentPageStreams);

};
Holder.prototype.getcstfEditText = function (id, value) {
    let self = this;
    return new EditBall(
        value,
        function (edit) {
            self.renameCstfSprint(id, edit);
            self.reset();
        },
        function () {
            self.reset();
        }
    );
}
Holder.prototype.createUuid = function () {
    return uuid();
};
Holder.prototype.vtunit = function (value, dataType) {
    if (value) {
        console.log(value);
    } else {
        return 'None';
    }
    function comma(num) {
        return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    let result = value;
    let ret = null;
    switch (dataType) {
        case 'rate':
            ret = comma(value);
            if (Number(value) < 10) {
                // 小数点以下2位まで表示
                if (ret.includes('.')) {
                    if (ret.split('.')[1].length == 1) ret = ret + '0';
                } else {
                    ret = ret + '.00';
                }
            } else {
                // 小数点以下1位まで表示
                if (!ret.includes('.')) {
                    ret = ret + '.0';
                }
                if (ret.split('.')[1].length == 2) ret = ret.slice(0, -1);
            }
            return ret + '%';
        case 'currency':
            return '¥' + comma(value);
        case 'number':
            return comma(value);
        case 'count':
            return comma(value);
        case 'rate_roas':
            return comma(value) + '%';
    }

    return result;
};
Holder.prototype.clipboardCopySprint = function (id) {
    let src = null;
    for (let i in this.raws) {
        let raw = this.raws[i];
        if (raw.id == id) {
            src = raw;
        }
    }


    let text = ("【項目】\r\n" + src.startdate + "週");
    text += ("\r\n\r\n【大方針】\r\n" + src.mayorpolicy);
    text += ("\r\n\r\n【単月目標達成】\r\n" + src.monthlygoalachieve);
    text += ("\r\n\r\n【月内方針】\r\n" + src.monthlypolicy);
    text += ("\r\n\r\n【月内進捗状況】\r\n");
    try {

        let sjs = JSON.parse(src.monthlystatustext);
        let cnt = 0;
        for (let i in sjs) {
            cnt++;
            if (cnt > 1) {
                text += "\r\n";
            }
            text += (sjs[i].label + " : " + this.vtunit(sjs[i].value, sjs[i].type) + "(" + sjs[i].rate + "%)");
        }
    } catch (ex) {
        console.log(ex);
    }
    text += ("\r\n\r\n【概況】\r\n" + src.overview);
    text += ("\r\n\r\n【課題箇所】\r\n" + src.problempoints);
    text += ("\r\n\r\n【課題要因】\r\n" + src.problemfactors);
    text += ("\r\n\r\n【対策方針】\r\n");
    function type2str(ap) {
        let type = 0;
        try {
            type = ap.type;
        } catch (ex) {
            console.log(ex);
        }
        for (let i in sprintApps) {
            let sa = sprintApps[i];
            if (sa.id == type) {
                return sa.data;
            }
        }
        return '-';
    }
    try {
        let sjs2 = JSON.parse(src.approachpolicy);
        let cnt = 0;
        for (let i in sjs2) {
            cnt++;
            if (cnt > 1) {
                text += "\r\n\r\n";
            }
            text += ("(" + type2str(sjs2[i]) + ")\r\n");
            text += ("" + sjs2[i].data);
        }
    } catch (ex) {
        console.log(ex);
    }
    text += ("\r\n\r\n【改善施策】\r\n");
    let cnt2 = 0;
    for (let i in src.todoactionitemsList) {
        cnt2++;
        if (cnt2 > 1) {
            text += "\r\n";
        }
        let todoactionitems = src.todoactionitemsList[i];
        text += (todoactionitems.provider + " - " + todoactionitems.title + todoactionitems?.implementationdate + "-" + todoactionitems?.validationdate + "");
    }

    text += ("\r\n\r\n【進行中施策】\r\n");
    let cnt1 = 0;
    for (let i in src.inprogressactionitemsList) {
        cnt1++;
        if (cnt1 > 1) {
            text += "\r\n";
        }
        let inprogressactionitems = src.inprogressactionitemsList[i];
        text += (inprogressactionitems.provider + " - " + inprogressactionitems.title + inprogressactionitems?.implementationdate + "-" + inprogressactionitems?.validationdate + "");
    }

    text += ("\r\n\r\n【MDフィードバック】\r\n" + src.mdfeedback);
    for (let i in src.customfieldsList) {
        let cf = src.customfieldsList[i];
        text += ("\r\n\r\n" + cf.value);
    }
    let type = "text/plain";
    let blob = new Blob([text], { type });
    let data = [new window.ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(
        function () {
            /* success */
        },
        function () {
            /* failure */
        }
    );

};
Holder.prototype.clipboardCopySprintRange = function (start, range) {
    if (start || range) {
        //
    }
};
Holder.prototype.copySprint = function (id) {
    let self = this;
    let src = null;
    for (let i in this.raws) {
        let raw = this.raws[i];
        if (raw.id == id) {
            src = raw;
        }
    }

    // this.advertizerId = advertizerId;
    // this.user = user;
    this.cancelAllAPICall();
    this.currentPageStreams = [];
    let range = this.getNewDateRange();
    let request = {
        advertizerId: this.advertizerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        startDate: range.start,
        endDate: range.end,
    };
    let cuuid = generateNewSprint(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            // if (!!(json.structure) != false) {
            //     self.recordHolder.pushDataStream(json, json.requestId);
            // }
            json.sprint.id = self.createUuid();

            json.sprint.mayorpolicy = src?.mayorpolicy;
            json.sprint.monthlygoalachieve = src?.monthlygoalachieve;
            json.sprint.monthlypolicy = src?.monthlypolicy;
            json.sprint.overview = src?.overview;
            // json.sprint.monthlystatusList= src?.monthlystatusList;
            json.sprint.problempoints = src?.problempoints;
            json.sprint.problemfactors = src?.problemfactors;
            json.sprint.approachpolicy = src?.approachpolicy;
            // json.sprint.todoactionitemsList= [];
            // json.sprint.inprogressactionitemsList= [];
            json.sprint.mdconfirm = false,
                json.sprint.mdfeedback = src?.mdfeedback;
            json.sprint.monthlystatustext = src?.monthlystatustext;
            json.sprint.customfieldsList = src?.customfieldsList;

            // json.sprint.customfieldsList.push({ field: '5ea2c6c3-5055-4306-aad2-1efaaaed6019', value: 'aaa' });
            self.createSprint(self.advertizerId, self.user, json.sprint);
            //self.holder.addraw(json.sprint);            
        }
    }, () => { }, "", this.currentPageStreams);
};

function SprintPage() {
    this.currentPageStreams = [];
    this.pageId = 0;
    this.advertizerId = 2;
    this.timeRange = null;
    this.pageComponents = [];
    this.user = null;
    this.cuuidList = [];

    this.startDate = null;
    this.endDate = null;
    this.sprint = {
        id: uuid(),
        updatedBy: '', //空データでいいか確認
        updatedAt: '', //空データでいいか確認
        startDate: "2022-01-02",
        endDate: "2022-01-08",
        mayorPolicy: 'abc',
        monthlyGoalAchieve: '',
        monthlyPolicy: '',
        monthlyStatus: [{ label: 'Cost', value: '3293842', type: 'currency', rate: '61' }],
        problemPoints: '',
        problemFactors: '',
        approachPolicy: '',
        todoActionItems: [{ provider: 'Google', title: '既存TD精査', date: '11/29' }],
        inProgressActionItems: [{ provider: 'Google', title: 'レスポンシブ広告のTD検証', date: '12/29' }],
        mdConfirm: true,
        mdFeedback: '',
        customFields: [{ field: uuid(), value: 'test' }]
    };
    this.label = null;
    this.field = { id: null, label: null };
    this.id = null;
    // let test = {
    //     id: uuid(),
    //     updatedby: '', //空データでいいか確認
    //     updatedat: '', //空データでいいか確認
    //     startdate: "2022-01-02",
    //     enddate: "2022-01-08",
    //     mayorpolicy: 'abc',
    //     monthlygoalachieve: '',
    //     monthlypolicy: '',
    //     monthlystatusList: [{ label: 'Cost', value: '3293842', type: 'currency', rate: '61' }, { label: 'Cost', value: '3293842', type: 'currency', rate: '61' }],
    //     problempoints: '',
    //     problemfactors: '',
    //     approachpolicy: '',
    //     todoactionitemsList: [{ provider: 'Google', title: '既存TD精査', date: '11/29' }, { provider: 'Google', title: '既存TD精査', date: '11/29' }],
    //     inprogressactionitemsList: [{ provider: 'Google', title: 'レスポンシブ広告のTD検証', date: '12/29' }, { provider: 'Google', title: 'レスポンシブ広告のTD検証', date: '12/29' }],
    //     mdconfirm: true,
    //     mdfeedback: '',
    //     customfieldsList: [{ field: uuid(), value: 'test' }]
    // };

    this.holder = new Holder();
}
SprintPage.prototype.addMonthlyStatus = function () {
    this.sprint.monthlyStatus.push({ label: 'B-dashCV', value: '427', type: 'currency', rate: '65' });
}
SprintPage.prototype.addTodoActionItems = function () {
    this.sprint.todoActionItems.push({ provider: 'Google', title: '既存TD精査', date: '11/29' });
}
SprintPage.prototype.addInProgressActionItems = function () {
    this.sprint.inProgressActionItems.push({ provider: 'Google', title: 'レスポンシブ広告のTD検証', date: '12/29' });
}
SprintPage.prototype.addCustomFields = function () {
    this.sprint.customFields.push({ field: uuid(), value: 'test' });
}

SprintPage.prototype.initialize = function (pageId, pageComponents, advertizerId, timeRange, user) {
    // let self = this;

    this.pageId = pageId;
    this.pageComponents = pageComponents;
    this.advertizerId = advertizerId;
    this.timeRange = timeRange;
    this.actualMonth = timeRange.timeYmdRange.getYm();
    this.user = user;
    this.cancelAllAPICall();

    this.currentPageStreams1 = [];
    this.currentPageStreams2 = [];
    this.currentPageStreams3 = [];

    this.holder = new Holder([], this.advertizerId, this.user, this.actualMonth);

    if (this.user.role === 'consultant' || this.user.role === 'manager' || this.user.role === 'root') {
        this.holder.load();
    }

    // console.log("getCustomFields");
    // let cuuid3 = getCustomFields(request, function (uuid, data) {
    //     console.log("getCustomFields data", data);
    //     if (data != null && cuuid3 == uuid) {
    //     }
    // }, () => { }, "", this.currentPageStreams3);

};
SprintPage.prototype.initializeProviderTab = function (pageId) {
    if (pageId) {
        //
    }
    // let self = this;
    // let request = {
    //     sortItem: "",
    //     sort: "",
    //     token: this.user.auth0.token,
    //     userUuid: this.user.userUuid,
    // };
    // request.advertizerId = this.advertizerId;
    // this.providerTablesProviders = [];
    // self.cuuidList.push(getTabListOfAdProviderReportPage(pageId, request, function (uuid, data) {
    //     if (self.cuuidList.includes(uuid)) {
    //         let json = JSON.parse(JSON.stringify(data.result));
    //         self.providerTablesProviders.push({label:"ALL", id:-1, name:"ALL"});
    //         for (let a of json.adproviderList) {
    //             self.providerTablesProviders.push({label:a.tab, id:a.adproviderid, name:a.provider});
    //         }
    //     }
    // }));
}
SprintPage.prototype.cancelAllAPICall = function () {
    this.currentPageStreams.forEach(stream => stream.cancel());
}
SprintPage.prototype.uuid = function () {
    return uuid();
};
SprintPage.prototype.generateNewSprint = function (advertizerId, user) {
    let self = this;

    this.advertizerId = advertizerId;
    this.user = user;
    this.cancelAllAPICall();
    this.currentPageStreams = [];
    let range = this.holder.getNewDateRange();
    let request = {
        advertizerId: this.advertizerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        startDate: range.start,
        endDate: range.end,
    };
    let cuuid = generateNewSprint(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            // if (!!(json.structure) != false) {
            //     self.recordHolder.pushDataStream(json, json.requestId);
            // }
            json.sprint.id = self.uuid();
            // json.sprint.customfieldsList.push({ field: '5ea2c6c3-5055-4306-aad2-1efaaaed6019', value: 'aaa' });
            self.holder.createSprint(self.advertizerId, self.user, json.sprint);
            //self.holder.addraw(json.sprint);            
        }
    }, () => { }, "", this.currentPageStreams);
};

SprintPage.prototype.createCustomField = function (advertizerId, user) {
    let self = this;
    this.advertizerId = advertizerId;
    this.user = user;
    this.cancelAllAPICall();

    this.currentPageStreams = [];

    let request = {
        advertizerId: this.advertizerId,
        actualMonth: this.currentMonth,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        label: this.label,
    };
    request.label = " ";
    let cuuid = createCustomField(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            // if (!!(json.structure) != false) {
            //     self.recordHolder.pushDataStream(json, json.requestId);
            // }
            if (json) {
                //
            }
            self.holder.load();
        }
    }, () => { }, "", this.currentPageStreams);
};
SprintPage.prototype.updateCustomField = function (advertizerId, user) {

    this.advertizerId = advertizerId;
    this.user = user;
    this.cancelAllAPICall();

    this.currentPageStreams = [];

    let request = {
        advertizerId: this.advertizerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        field: this.field,
    };
    let cuuid = updateCustomField(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                self.recordHolder.pushDataStream(json, json.requestId);
            }
        }
    }, () => { }, "", this.currentPageStreams);
};
SprintPage.prototype.deleteCustomField = function (advertizerId, user, id) {

    this.advertizerId = advertizerId;
    this.user = user;
    this.cancelAllAPICall();

    this.currentPageStreams = [];

    let request = {
        advertizerId: this.advertizerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
        id: id,
    };
    let cuuid = deleteCustomField(request, function (uuid, data) {
        if (data != null && cuuid == uuid) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!(json.structure) != false) {
                self.recordHolder.pushDataStream(json, json.requestId);
            }
        }
    }, () => { }, "", this.currentPageStreams);
};

SprintPage.prototype.updateload = function (callback) {
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    const cuuid = getInformationLatestUpdate(this.advertizerId, request, function (uuid, data) {
        if (cuuid == uuid) {
            try {
                let json = JSON.parse(JSON.stringify(data.result));
                if (json?.latestupdate?.date) {
                    if (callback) {
                        callback(data2dateandweek(json.latestupdate.date));
                    }
                }
            } catch (ex) {
                console.log(ex);
            }
        }
    });
};

export { SprintPage }
