/**
 * @fileoverview gRPC-Web generated client stub for adx.budget_simulation
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.budget_simulation = require('./budget_simulation_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.budget_simulation.BudgetSimulationClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest,
 *   !proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse>}
 */
const methodDescriptor_BudgetSimulation_GetSimulationFormatFileUrl = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/GetSimulationFormatFileUrl',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest,
  proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse,
  /**
   * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.getSimulationFormatFileUrl =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetSimulationFormatFileUrl',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetSimulationFormatFileUrl);
};


/**
 * @param {!proto.adx.budget_simulation.GetSimulationFormatFileUrlRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.GetSimulationFormatFileUrlResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.getSimulationFormatFileUrl =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetSimulationFormatFileUrl',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetSimulationFormatFileUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.GetSimulationDataFileUrlRequest,
 *   !proto.adx.budget_simulation.GetSimulationDataFileUrlResponse>}
 */
const methodDescriptor_BudgetSimulation_GetSimulationDataFileUrl = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/GetSimulationDataFileUrl',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.GetSimulationDataFileUrlRequest,
  proto.adx.budget_simulation.GetSimulationDataFileUrlResponse,
  /**
   * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.GetSimulationDataFileUrlResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.getSimulationDataFileUrl =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetSimulationDataFileUrl',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetSimulationDataFileUrl);
};


/**
 * @param {!proto.adx.budget_simulation.GetSimulationDataFileUrlRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.GetSimulationDataFileUrlResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.getSimulationDataFileUrl =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetSimulationDataFileUrl',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetSimulationDataFileUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest,
 *   !proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse>}
 */
const methodDescriptor_BudgetSimulation_GetCampaignSimulatedBudgetInitialBuild = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/GetCampaignSimulatedBudgetInitialBuild',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest,
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse,
  /**
   * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.getCampaignSimulatedBudgetInitialBuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetCampaignSimulatedBudgetInitialBuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetCampaignSimulatedBudgetInitialBuild);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.CampaignSimulatedBudgetInitialBuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.getCampaignSimulatedBudgetInitialBuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetCampaignSimulatedBudgetInitialBuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetCampaignSimulatedBudgetInitialBuild);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest,
 *   !proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse>}
 */
const methodDescriptor_BudgetSimulation_GetCampaignSimulatedBudgetRebuild = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/GetCampaignSimulatedBudgetRebuild',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest,
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse,
  /**
   * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.getCampaignSimulatedBudgetRebuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetCampaignSimulatedBudgetRebuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetCampaignSimulatedBudgetRebuild);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.CampaignSimulatedBudgetRebuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.getCampaignSimulatedBudgetRebuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetCampaignSimulatedBudgetRebuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetCampaignSimulatedBudgetRebuild);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest,
 *   !proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse>}
 */
const methodDescriptor_BudgetSimulation_GetDynamicCategorySimulatedBudgetInitialBuild = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/GetDynamicCategorySimulatedBudgetInitialBuild',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest,
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse,
  /**
   * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.getDynamicCategorySimulatedBudgetInitialBuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetDynamicCategorySimulatedBudgetInitialBuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetDynamicCategorySimulatedBudgetInitialBuild);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetInitialBuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.getDynamicCategorySimulatedBudgetInitialBuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetDynamicCategorySimulatedBudgetInitialBuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetDynamicCategorySimulatedBudgetInitialBuild);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest,
 *   !proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse>}
 */
const methodDescriptor_BudgetSimulation_GetDynamicCategorySimulatedBudgetRebuild = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/GetDynamicCategorySimulatedBudgetRebuild',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest,
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse,
  /**
   * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.getDynamicCategorySimulatedBudgetRebuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetDynamicCategorySimulatedBudgetRebuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetDynamicCategorySimulatedBudgetRebuild);
};


/**
 * @param {!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.DynamicCategorySimulatedBudgetRebuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.getDynamicCategorySimulatedBudgetRebuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetDynamicCategorySimulatedBudgetRebuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetDynamicCategorySimulatedBudgetRebuild);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest,
 *   !proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse>}
 */
const methodDescriptor_BudgetSimulation_GetMonthlySimulatedBudgetOverallInitialBuild = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/GetMonthlySimulatedBudgetOverallInitialBuild',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest,
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse,
  /**
   * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.getMonthlySimulatedBudgetOverallInitialBuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetMonthlySimulatedBudgetOverallInitialBuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetMonthlySimulatedBudgetOverallInitialBuild);
};


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallInitialBuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.getMonthlySimulatedBudgetOverallInitialBuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetMonthlySimulatedBudgetOverallInitialBuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetMonthlySimulatedBudgetOverallInitialBuild);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest,
 *   !proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse>}
 */
const methodDescriptor_BudgetSimulation_GetMonthlySimulatedBudgetOverallRebuild = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/GetMonthlySimulatedBudgetOverallRebuild',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest,
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse,
  /**
   * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.getMonthlySimulatedBudgetOverallRebuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetMonthlySimulatedBudgetOverallRebuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetMonthlySimulatedBudgetOverallRebuild);
};


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.MonthlySimulatedBudgetOverallRebuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.getMonthlySimulatedBudgetOverallRebuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetMonthlySimulatedBudgetOverallRebuild',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetMonthlySimulatedBudgetOverallRebuild);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.MonthlySimulatedVersionRequest,
 *   !proto.adx.budget_simulation.MonthlySimulatedVersionResponse>}
 */
const methodDescriptor_BudgetSimulation_GetMonthlySimulatedVersion = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/GetMonthlySimulatedVersion',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.MonthlySimulatedVersionRequest,
  proto.adx.budget_simulation.MonthlySimulatedVersionResponse,
  /**
   * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.MonthlySimulatedVersionResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.MonthlySimulatedVersionResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.getMonthlySimulatedVersion =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetMonthlySimulatedVersion',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetMonthlySimulatedVersion);
};


/**
 * @param {!proto.adx.budget_simulation.MonthlySimulatedVersionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.MonthlySimulatedVersionResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.getMonthlySimulatedVersion =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/GetMonthlySimulatedVersion',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_GetMonthlySimulatedVersion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.InsertBasePeriodRequest,
 *   !proto.adx.budget_simulation.InsertBasePeriodResponse>}
 */
const methodDescriptor_BudgetSimulation_InsertBasePeriod = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/InsertBasePeriod',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.InsertBasePeriodRequest,
  proto.adx.budget_simulation.InsertBasePeriodResponse,
  /**
   * @param {!proto.adx.budget_simulation.InsertBasePeriodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.InsertBasePeriodResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.InsertBasePeriodRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.InsertBasePeriodResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.insertBasePeriod =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertBasePeriod',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertBasePeriod);
};


/**
 * @param {!proto.adx.budget_simulation.InsertBasePeriodRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.InsertBasePeriodResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.insertBasePeriod =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertBasePeriod',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertBasePeriod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.CampaignSimulatedBudgetRequest,
 *   !proto.adx.budget_simulation.Response>}
 */
const methodDescriptor_BudgetSimulation_InsertCampaignSimulatedBudget = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/InsertCampaignSimulatedBudget',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.CampaignSimulatedBudgetRequest,
  proto.adx.budget_simulation.Response,
  /**
   * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.Response.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.insertCampaignSimulatedBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertCampaignSimulatedBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertCampaignSimulatedBudget);
};


/**
 * @param {!proto.adx.budget_simulation.CampaignSimulatedBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.insertCampaignSimulatedBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertCampaignSimulatedBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertCampaignSimulatedBudget);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest,
 *   !proto.adx.budget_simulation.Response>}
 */
const methodDescriptor_BudgetSimulation_InsertDimensionCategoryResultSimulatedBudget = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/InsertDimensionCategoryResultSimulatedBudget',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest,
  proto.adx.budget_simulation.Response,
  /**
   * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.Response.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.insertDimensionCategoryResultSimulatedBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertDimensionCategoryResultSimulatedBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertDimensionCategoryResultSimulatedBudget);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategoryResultSimulatedBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.insertDimensionCategoryResultSimulatedBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertDimensionCategoryResultSimulatedBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertDimensionCategoryResultSimulatedBudget);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest,
 *   !proto.adx.budget_simulation.Response>}
 */
const methodDescriptor_BudgetSimulation_InsertDimensionCategorySimulatedBudget = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/InsertDimensionCategorySimulatedBudget',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest,
  proto.adx.budget_simulation.Response,
  /**
   * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.Response.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.insertDimensionCategorySimulatedBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertDimensionCategorySimulatedBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertDimensionCategorySimulatedBudget);
};


/**
 * @param {!proto.adx.budget_simulation.DimensionCategorySimulatedBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.insertDimensionCategorySimulatedBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertDimensionCategorySimulatedBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertDimensionCategorySimulatedBudget);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.OverallSimulatedBudgetRequest,
 *   !proto.adx.budget_simulation.Response>}
 */
const methodDescriptor_BudgetSimulation_InsertOverallSimulatedBudget = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/InsertOverallSimulatedBudget',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.OverallSimulatedBudgetRequest,
  proto.adx.budget_simulation.Response,
  /**
   * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.Response.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.insertOverallSimulatedBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertOverallSimulatedBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertOverallSimulatedBudget);
};


/**
 * @param {!proto.adx.budget_simulation.OverallSimulatedBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.insertOverallSimulatedBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/InsertOverallSimulatedBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_InsertOverallSimulatedBudget);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_simulation.UpsertBasePeriodRequest,
 *   !proto.adx.budget_simulation.UpsertBasePeriodResponse>}
 */
const methodDescriptor_BudgetSimulation_UpsertBasePeriod = new grpc.web.MethodDescriptor(
  '/adx.budget_simulation.BudgetSimulation/UpsertBasePeriod',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_simulation.UpsertBasePeriodRequest,
  proto.adx.budget_simulation.UpsertBasePeriodResponse,
  /**
   * @param {!proto.adx.budget_simulation.UpsertBasePeriodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_simulation.UpsertBasePeriodResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.UpsertBasePeriodResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationClient.prototype.upsertBasePeriod =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/UpsertBasePeriod',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_UpsertBasePeriod);
};


/**
 * @param {!proto.adx.budget_simulation.UpsertBasePeriodRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_simulation.UpsertBasePeriodResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_simulation.BudgetSimulationPromiseClient.prototype.upsertBasePeriod =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_simulation.BudgetSimulation/UpsertBasePeriod',
      request,
      metadata || {},
      methodDescriptor_BudgetSimulation_UpsertBasePeriod);
};


module.exports = proto.adx.budget_simulation;

