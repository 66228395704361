import { ObserverSubject } from '../tacklebox/event/ObserverSubject'

import { TimeYmdRange } from "@/js/tacklebox/date/TimeYmdRange.js";

function CustomReportRangeSelector(mode, range = new TimeYmdRange(), ymdMode = 0) {
    this.mode = mode;
    this.range = range;
    this.subject = new ObserverSubject();
    this.ymdMode = ymdMode;
}
CustomReportRangeSelector.prototype.setRangeYmd = function (sy, sm, sd, ey, em, ed) {
    const self = this;
    if (this.range) {
        //
    } else {
        this.range = new TimeYmdRange();
    }
    this.range.initRangeYmd(sy, sm, sd, ey, em, ed);
    this.range.subject.removeAll()
    this.range.subject.addObserver(function () {
        self.subject.notify();
    });
};
CustomReportRangeSelector.prototype.setYmdMode = function (ymdMode) {
    if (ymdMode == 0 || ymdMode == 1 || ymdMode == 2 || ymdMode == 3) {
        this.ymdMode = ymdMode;
    }
};

function CustomReportRangeCalendarJs(selector, callback = function () { }) {
    this.selector = selector;
    this.callback = callback;
}

export { CustomReportRangeCalendarJs, CustomReportRangeSelector }
