<template>
  <div>
    <div>
      <div
    v-if="true"
      style="
        width: 100%;
        font: 12px / 17px 'Noto Sans JP';
        top: 0;
        left: 0;
        /* padding-bottom: 8px; */
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
      "
    >
      {{ xxx }}
    </div>
    </div>
    <div
    :style="{
      width: 'calc(100% + 8px)',
      boxShadow: '0px 3px 20px #00000029',
      backgroundColor: 'rgba(255,255,255,1)',
      position: 'absolute',
      borderRadius: '4px',
      top: '-2px',
      left: '-2px',
      height: '100%',
      padding: '10px',
      zIndex: 10,
    }"
  >

    <textarea
      class="mytextarea"
      id="xxxx"
      ref="txta_main"
      :style="{
        resize: 'none',
        position: 'absolute',
        borderRadius: '4px',
        width: 'calc(100% - 5px)',
        height: '100%',
        outline: 'none',
        boxShadow: '0 0 0', // よくわからないけど、この行を入れないと枠線がでちゃう。
      }"
      style="
        outline: none;
        color: #39434e;
        font: 12px / 17px 'Noto Sans JP';
        top: 0;
        left: 0;
        background: rgba(255,255,255,1);
        padding: 10px;
        min-height: 22px;
        opacity: 1;
      "
      v-model="inputData"
      @keydown.enter.exact="decide()"
      v-on:blur="decide()"
    />
    <div
    v-if="true"
      style="
        width: calc(100% - 5px);
        font: 12px / 17px 'Noto Sans JP';
        top: 0;
        left: 0;
        min-height: 22px;
        color: #fff;
        /* padding-bottom: 8px; */
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
      "
    >
      {{ xxx }}
    </div>
  </div>
  </div>

</template>

<style scoped>

.mytextarea {
    scrollbar-width: none; /*Firefox対応のスクロールバー非表示コード*/  
    -ms-overflow-style: none;/*Internet Explore対応のスクロールバー非表示コード*/   
}

.mytextarea::-webkit-scrollbar {  
  display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
}

</style>

<style scoped>
textarea:focus {
  outline: none;
  box-shadow: 0 0 0;
}
</style>
<script>
export default {
  name: 'SelectEditItem',
  data() {
    return {
      name: 'SelectEditItem',
      inputData: '',
    };
  },
  computed: {
    xxx() {
       return this.inputData + '\u200b';
    }
  },
  mounted() {
    this.inputData = this.editball.raw;
    this.$refs.txta_main.focus();

    //textareaの要素を取得
    //textareaのデフォルトの要素の高さを取得
    let clientHeight = this.$refs.txta_main.clientHeight;
    let scrollHeight = this.$refs.txta_main.scrollHeight;
    if (scrollHeight) {
      //
    }

    // //textareaのinputイベント
    this.$refs.txta_main.addEventListener('input', ()=>{
        //textareaの要素の高さを設定（rows属性で行を指定するなら「px」ではなく「auto」で良いかも！）
        this.$refs.txta_main.style.height = clientHeight + 'px';
        //textareaの入力内容の高さを取得
        let scrollHeight = this.$refs.txta_main.scrollHeight;
        //textareaの高さに入力内容の高さを設定
        this.$refs.txta_main.style.height = scrollHeight + 'px';
    });
    const self = this;
    setTimeout(function() {
      self.$refs.txta_main.style.height = clientHeight + 'px';
        //textareaの入力内容の高さを取得
        let scrollHeight = self.$refs.txta_main.scrollHeight;
        //textareaの高さに入力内容の高さを設定
        self.$refs.txta_main.style.height = scrollHeight + 'px';
    }, 1)
  },
  methods: {
    decide() {
      //this.memoball.cancelEdit();
      this.editball.save(this.inputData);
    },
  },
  components: {},
  props: {
    editball: Object,
  },
};
</script>
