import {
  TabListOfAdProviderReportPageRequest,
  AdProviderMonthlyResultReportByAdProviderRequest,
  AdProviderWeeklyResultGraphReportByAdProviderRequest,
  AdProviderDailyResultGraphReportByAdProviderRequest,
  AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest,
  AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest,
  AdProviderDimensionCategoryDailyResultReportByAdProviderRequest,
  AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest,
  AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest,
  AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest,
} from './protos_gen/ad_provider_report_pb.js';
import { AdProviderReportClient } from './protos_gen/ad_provider_report_grpc_web_pb.js';
import {
  GetConsultantCategoryMonthlyReportRequest,
} from './protos_gen/consultant_report_pb.js';
import { ConsultantReportClient } from './protos_gen/consultant_report_grpc_web_pb.js';


import { url } from '../../setting.js';
import { uuid } from '../../tacklebox/core/uuid.js';

/* eslint-disable */

function getTabListOfAdProviderReportPage(id, dto, callback, callback_end = () => { }) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new TabListOfAdProviderReportPageRequest();
  request.setRequestid(uuidStr);
  request.setAdpageid(id);
  request.setAdvertizerid(dto.advertizerId);

  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getTabListOfAdProviderReportPage(request, meta);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    // throw e;
    callback_end(uuidStr, e);
  });
  //TBD エラー

  return uuidStr;
}

function getAdProviderMonthlyResultReportByAdProvider(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new AdProviderMonthlyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setAdvertizerid(dto.advertizerId);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }
  if (user_role == "consultant") request.setLimit(27);
  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getAdProviderMonthlyResultReportByAdProvider(request, meta);
  currentPageStreams.push(stream);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    // throw e;
    callback_end(uuidStr, e);
  });
  //TBD エラー

  return uuidStr;
}

function getAdProviderWeeklyResultGraphReportByAdProvider(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new AdProviderWeeklyResultGraphReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setAdvertizerid(dto.advertizerId);
  if (user_role == "consultant") request.setLimit(30);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  }

  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getAdProviderWeeklyResultGraphReportByAdProvider(request, meta);
  currentPageStreams.push(stream);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    // throw e;
    callback_end(uuidStr, e);
  });
  //TBD エラー

  return uuidStr;
}

function getAdProviderDailyResultGraphReportByAdProvider(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new AdProviderDailyResultGraphReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setAdvertizerid(dto.advertizerId);
  if (user_role == "consultant") request.setLimit(60);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }

  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getAdProviderDailyResultGraphReportByAdProvider(request, meta);
  currentPageStreams.push(stream);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    // throw e;
    callback_end(uuidStr, e);
  });
  //TBD エラー

  return uuidStr;
}

function getAdProviderDimensionCategoryMonthlyResultReportByAdProvider(id, dto, callback) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setAdvertizerid(dto.advertizerId);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }

  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getAdProviderDimensionCategoryMonthlyResultReportByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    // throw e;
    if (callback) {
      callback(uuidStr, e);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getAdProviderDimensionCategoryWeeklyResultReportByAdProvider(id, dto, callback) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let actualMonth = dto.actualMonth;
  let starDate = dto.starDate;
  let endDate = dto.endDate;
  const request = new AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setActualmonth(actualMonth);
  request.setStartdate(starDate);
  request.setEnddate(endDate);
  request.setAdvertizerid(dto.advertizerId);
  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getAdProviderDimensionCategoryWeeklyResultReportByAdProvider(request, meta);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    // throw e;
    if (callback) {
      callback(uuidStr, e);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getAdProviderDimensionCategoryDailyResultReportByAdProvider(id, dto, callback) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let actualMonth = dto.actualMonth;
  let starDate = dto.starDate;
  let endDate = dto.endDate;
  let request = new AdProviderDimensionCategoryDailyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setActualmonth(actualMonth);
  request.setStartdate(starDate);
  request.setEnddate(endDate);
  request.setAdvertizerid(dto.advertizerId);

  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getAdProviderDimensionCategoryDailyResultReportByAdProvider(request, meta);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    // throw e;
    if (callback) {
      callback(uuidStr, e);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider(id, dto, callback) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setAdvertizerid(dto.advertizerId);
  // request.setCampaign(dto.campaign);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }

  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    // throw e;
    if (callback) {
      callback(uuidStr, e);
    }
  });
  //TBD エラー

  return uuidStr;
}

function getAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider(id, dto, callback) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let actualMonth = dto.actualMonth;
  let starDate = dto.starDate;
  let endDate = dto.endDate;
  let request = new AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCampaign(dto.campaign);
  request.setActualmonth(actualMonth);
  request.setStartdate(starDate);
  request.setEnddate(endDate);
  request.setAdvertizerid(dto.advertizerId);

  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider(
    request,
    meta
  );

  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider(id, dto, callback) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setAdvertizerid(dto.advertizerId);
  // request.setCampaign(dto.campaign);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }
  let client = new AdProviderReportClient(url.grpc, null, null);
  const stream = client.getAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getConsultantCategoryMonthlyReport(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new GetConsultantCategoryMonthlyReportRequest();
  request.setRequestid(uuidStr);
  request.setAdvertizerid(id);
  // request.setCampaign(dto.campaign);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }
  let client = new ConsultantReportClient(url.grpc, null, null);
  const stream = client.getConsultantCategoryMonthlyReport(request, meta);
  currentPageStreams.push(stream);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    // throw e;
    callback_end(uuidStr, e);
  });
  //TBD エラー

  return uuidStr;
}




export {
  getTabListOfAdProviderReportPage,
  getAdProviderMonthlyResultReportByAdProvider,
  getAdProviderWeeklyResultGraphReportByAdProvider,
  getAdProviderDailyResultGraphReportByAdProvider,
  getAdProviderDimensionCategoryMonthlyResultReportByAdProvider,
  getAdProviderDimensionCategoryWeeklyResultReportByAdProvider,
  getAdProviderDimensionCategoryDailyResultReportByAdProvider,
  getAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider,
  getAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider,
  getAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider,
  getConsultantCategoryMonthlyReport,
};
