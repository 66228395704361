<template>
  <div>
    <template v-for="(ap, index) in before()" :key="index">
      <div class="monthlystatusline">
        <div>{{ data2str(ap.label) }}</div>
        <div class="monthlystatusline__line">-</div>
        <div class="monthlystatusline__value">{{ vtunit(ap.value, ap.type) }}</div>
        <div>({{ data2str(ap.rate) }}%)</div>
      </div>
    </template>
    <div class="monthlystatusline">
      <div v-if="keyparam != 'label'">{{ inputmode.label }}</div>
      <div class="monthlystatusline__line" v-if="keyparam != 'label'">-</div>
      <div class="monthlystatusline__value" v-if="keyparam != 'value' && keyparam != 'label'">
        {{ inputmode.value }}
      </div>
      <div
        :style="{
          boxShadow: '0px 3px 20px #00000029',
          backgroundColor: 'white',
        }"
        style="width: 70px; padding-left: 8x; padding-right: 8x"
      >
        <input
          type="text"
          class="mytextarea"
          ref="txta_main"
          :style="{
            resize: 'none',
            outline: 'none',
            boxShadow: '0 0 0', // よくわからないけど、この行を入れないと枠線がでちゃう。
          }"
          style="outline: none; color: #39434e; font: 12px / 17px 'Noto Sans JP'; width: 70px"
          v-model="inputData"
          @keydown.enter.exact="decide()"
          v-on:blur="decide()"
        />
      </div>
      <div class="monthlystatusline__line" v-if="keyparam == 'label'">-</div>
      <div v-if="keyparam != 'value' && keyparam != 'rate'">{{ inputmode.value }}</div>
      <div class="monthlystatusline__value" v-if="keyparam != 'rate'">({{ inputmode.rate }}%)</div>
      <div v-if="keyparam == 'rate'">%</div>
    </div>
    <template v-for="(ap, index) in after()" :key="index">
      <div class="monthlystatusline">
        <div>{{ data2str(ap.label) }}</div>
        <div class="monthlystatusline__line">-</div>
        <div class="monthlystatusline__value">{{ vtunit(ap.value, ap.type) }}</div>
        <div>({{ data2str(ap.rate) }}%)</div>
      </div>
    </template>
  </div>
</template>
<style scoped>
textarea:focus {
  outline: none;
  box-shadow: 0 0 0;
}
</style>

<style scoped>
.monthlystatusline {
  display: flex;
  width: fit-content;
  background: rgb(238, 238, 238);
  padding: 4px 16px;
  border-radius: 4px;
  margin: 4px;
}

.monthlystatusline__value {
  font-weight: bold;
  margin-left: 8px;
  margin-right: 8px;
}

.monthlystatusline__line {
  margin-left: 8px;
  margin-right: 8px;
}
</style>

<script>
export default {
  name: 'SprintEditMonthlyStatusText',
  data() {
    return {
      name: 'SprintEditMonthlyStatusText',
      inputData: '',
      target: null,
      isSave: false,
      dataJson: [],
    };
  },
  computed: {},
  mounted() {
    this.inputData = '';
    try {
      if (this.editball.raw) {
        this.dataJson = JSON.parse(this.editball.raw);
      }
    } catch (ex) {
      console.log(ex);
    }

    for (let i in this.dataJson) {
      let j = this.dataJson[i];
      if (j.id == this.inputmode.id) {
        this.target = j;
        if (this.keyparam == 'label') {
          this.inputData = j.label;
        } else if (this.keyparam == 'value') {
          this.inputData = j.value;
        } else if (this.keyparam == 'rate') {
          this.inputData = j.rate;
        }
        break;
      }
    }

    this.$refs.txta_main.focus();
  },
  methods: {
    vtunit(value, dataType) {
      if (value) {
        console.log(value);
      } else {
        return 'None';
      }
      function comma(num) {
        return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }
      let result = value;
      let ret = null;
      switch (dataType) {
        case 'rate':
          ret = comma(value);
          if (Number(value) < 10) {
            // 小数点以下2位まで表示
            if (ret.includes('.')) {
              if (ret.split('.')[1].length == 1) ret = ret + '0';
            } else {
              ret = ret + '.00';
            }
          } else {
            // 小数点以下1位まで表示
            if (!ret.includes('.')) {
              ret = ret + '.0';
            }
            if (ret.split('.')[1].length == 2) ret = ret.slice(0, -1);
          }
          return ret + '%';
        case 'currency':
          return '¥' + comma(value);
        case 'number':
          return comma(value);
        case 'count':
          return comma(value);
        case 'rate_roas':
          return comma(value) + '%';
      }

      return result;
    },
    before() {
      let rs = [];
      for (let i in this.dataJson) {
        let json = this.dataJson[i];
        if (json.id == this.inputmode.id) {
          break;
        }
        rs.push(json);
      }
      return rs;
    },
    after() {
      let rs = [];
      let isHit = false;
      for (let i in this.dataJson) {
        let json = this.dataJson[i];
        if (isHit) {
          rs.push(json);
        }
        if (json.id == this.inputmode.id) {
          isHit = true;
        }
      }
      return rs;
    },
    data2str(str) {
      if (str) {
        if (str.length > 0) {
          return str;
        }
      }
      return 'None';
    },
    decide() {
      if (this.isSave) {
        return;
      }
      this.isSave = true;

      for (let i in this.dataJson) {
        let j = this.dataJson[i];
        if (j.id == this.inputmode.id) {
          if (this.keyparam == 'label') {
            j.label = this.inputData;
          } else if (this.keyparam == 'value') {
            j.value = this.inputData;
          } else if (this.keyparam == 'rate') {
            j.rate = this.inputData;
          }
          break;
        }
      }
      let st = JSON.stringify(this.dataJson);
      this.editball.save(st);
    },
  },
  components: {},
  props: {
    editball: Object,
    inputmode: String,
    keyparam: String,
  },
};
</script>
