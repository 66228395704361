<template>
  <div
    ref="main"
    tabindex="0"
    v-on:blur="decide()"
    :style="{
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 8px)',
      fontSize: '12px',
      boxShadow: '0px 3px 20px #00000029',
      backgroundColor: 'white',
      position: 'relative',
      top: '-4px',
      left: '-4px',
      padding: '8px',
      outline: 'none',
    }"
  >
    <template v-for="(ap, index) in dataJson" :key="index">
      <div>
        <div style="position: relative">
          <div class="title" style="display: flex">
            <div v-on:click="test(ap.id)">{{ type2str(ap.type) }}</div>
            <div style="margin-left: 8px" v-on:click="ondelete(ap)">×</div>
          </div>
          <div style="position: absolute; z-index: 1000">
            <div
              v-if="isShowAddList == ap.id"
              :style="{
                width: 'calc(100% + 8px)',
                height: 'calc(100% + 8px)',
                fontSize: '12px',
                boxShadow: '0px 3px 20px #00000029',
                backgroundColor: 'white',
                position: 'relative',
                top: '0px',
                left: '16px',
                padding: '4px',
                outline: 'none',
              }"
            >
              <div v-for="(item, index) in getAddTitle()" :key="index">
                <div class="title" v-on:click="titlechange(ap.id, item.id)">{{ item.data }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="body" v-on:click="modechangeEdit(ap)">{{ data2str(ap.data) }}</div>
      </div>
    </template>

    <div style="position: relative">
      <div class="title" v-on:click="test('new')">+NEW</div>
      <div style="position: absolute; z-index: 1000">
        <div
          v-if="isShowAddList == 'new'"
          :style="{
            width: 'calc(100% + 8px)',
            height: 'calc(100% + 8px)',
            fontSize: '12px',
            boxShadow: '0px 3px 20px #00000029',
            backgroundColor: 'white',
            position: 'relative',
            top: '0px',
            left: '16px',
            padding: '4px',
            outline: 'none',
          }"
        >
          <div v-for="(item, index) in getAddTitle()" :key="index">
            <div class="title" v-on:click="modechange(item.id)">{{ item.data }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.title {
  user-select: none;
  padding: 4px 16px;
  border-radius: 4px;
  background: rgb(238, 238, 238);
  margin: 4px;
  width: fit-content;
}
.body {
  padding: 4px 16px;
}
</style>

<style scoped>
textarea:focus {
  /* outline: none;
  box-shadow: 0 0 0; */
}
</style>
<script>
import { sprintApps } from '@/js/setting.js';

export default {
  name: 'SprintEditSelect',
  data() {
    return {
      name: 'SprintEditSelect',
      inputData: '',
      dataJson: [],
      isShowAddList: '',
      titles: sprintApps, //['現状施策継続', '全体施策実施', '個別課題施策実施', '問題的解決', 'ステイ'],
      isModeChange: false,
      isDelete: false,
    };
  },
  computed: {},
  mounted() {
    try {
      if (this.editball.raw) {
        this.dataJson = JSON.parse(this.editball.raw);
      }
    } catch (ex) {
      console.log(ex);
    }

    this.inputData = this.editball.raw;

    this.$refs.main.focus();
  },
  methods: {
    ondelete(ap) {
      this.isDelete = true;
      let arr = [];
      for (let i in this.dataJson) {
        let j = this.dataJson[i];
        try {
          if (j.id != ap.id) {
            arr.push(j);
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      let st = JSON.stringify(arr);
      this.editball.save(st);
    },
    data2str(str) {
      if (str) {
        if (str.length > 0) {
          return str;
        }
      }
      return '-';
    },
    type2str(type) {
      for (let i in sprintApps) {
        let sa = sprintApps[i];
        if (sa.id == type) {
          return sa.data;
        }
      }
      return '-';
    },
    title2mode(title) {
      for (let i in sprintApps) {
        let sa = sprintApps[i];
        if (sa.data == title) {
          return sa.id;
        }
      }

      return 0;
    },
    modechange(type) {
      this.isModeChange = true;
      let ap = { type: type };
      this.callbackObj(ap);
    },
    modechangeEdit(ap) {
      this.isModeChange = true;
      this.callbackObj(ap);
    },
    titlechange(id, type) {
      this.isDelete = true;
      let arr = [];
      for (let i in this.dataJson) {
        let j = this.dataJson[i];
        try {
          if (j.id == id) {
            j.type = type;
          }
          arr.push(j);
        } catch (ex) {
          console.log(ex);
        }
      }
      let st = JSON.stringify(arr);
      this.editball.save(st);
    },
    getAddTitle() {
      let rs = [];
      for (let i in this.titles) {
        let to = this.titles[i];
        let isHit = false;
        for (let j in this.dataJson) {
          let js = this.dataJson[j];
          if (to.id == js.type) {
            isHit = true;
            break;
          }
        }
        if (!isHit) {
          rs.push(to);
        }
      }
      return this.titles;
    },
    _test(param) {
      console.log(param);
      // this.isShowAddList = param;
    },
    test(param) {
      this.isShowAddList = param;
    },
    decide() {
      if (!this.isModeChange && !this.isDelete) {
        this.editball.save(this.inputData);
        this.callbackObj();
      }
    },
  },
  components: {},
  props: {
    editball: Object,
    holder: Object,
    callbackObj: Object,
  },
};
</script>
