/**
 * @fileoverview gRPC-Web generated client stub for adx.ad_provider_report
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.ad_provider_report = require('./ad_provider_report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.ad_provider_report.AdProviderReportClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest,
 *   !proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse>}
 */
const methodDescriptor_AdProviderReport_GetTabListOfAdProviderReportPage = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetTabListOfAdProviderReportPage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest,
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse,
  /**
   * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getTabListOfAdProviderReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetTabListOfAdProviderReportPage',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetTabListOfAdProviderReportPage);
};


/**
 * @param {!proto.adx.ad_provider_report.TabListOfAdProviderReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.TabListOfAdProviderReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getTabListOfAdProviderReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetTabListOfAdProviderReportPage',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetTabListOfAdProviderReportPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest,
 *   !proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse>}
 */
const methodDescriptor_AdProviderReport_GetAdProviderMonthlyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetAdProviderMonthlyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest,
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getAdProviderMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderMonthlyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getAdProviderMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderMonthlyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest,
 *   !proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse>}
 */
const methodDescriptor_AdProviderReport_GetAdProviderWeeklyResultGraphReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetAdProviderWeeklyResultGraphReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest,
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse,
  /**
   * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getAdProviderWeeklyResultGraphReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderWeeklyResultGraphReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderWeeklyResultGraphReportByAdProvider);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderWeeklyResultGraphReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getAdProviderWeeklyResultGraphReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderWeeklyResultGraphReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderWeeklyResultGraphReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest,
 *   !proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse>}
 */
const methodDescriptor_AdProviderReport_GetAdProviderDailyResultGraphReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetAdProviderDailyResultGraphReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest,
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse,
  /**
   * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getAdProviderDailyResultGraphReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDailyResultGraphReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDailyResultGraphReportByAdProvider);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDailyResultGraphReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getAdProviderDailyResultGraphReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDailyResultGraphReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDailyResultGraphReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest,
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse>}
 */
const methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryMonthlyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryMonthlyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getAdProviderDimensionCategoryMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryMonthlyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getAdProviderDimensionCategoryMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryMonthlyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest,
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse>}
 */
const methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryWeeklyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryWeeklyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getAdProviderDimensionCategoryWeeklyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryWeeklyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryWeeklyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryWeeklyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getAdProviderDimensionCategoryWeeklyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryWeeklyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryWeeklyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest,
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse>}
 */
const methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryDailyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryDailyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getAdProviderDimensionCategoryDailyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryDailyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryDailyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryDailyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getAdProviderDimensionCategoryDailyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryDailyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryDailyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest,
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse>}
 */
const methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryAdGroupMonthlyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest,
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse>}
 */
const methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryAdGroupWeeklyResultReportByAdProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest,
 *   !proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse>}
 */
const methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider = new grpc.web.MethodDescriptor(
  '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest,
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse,
  /**
   * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.deserializeBinary
);


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportClient.prototype.getAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider);
};


/**
 * @param {!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.ad_provider_report.AdProviderDimensionCategoryAdGroupDailyResultReportByAdProviderResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.ad_provider_report.AdProviderReportPromiseClient.prototype.getAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.ad_provider_report.AdProviderReport/GetAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider',
      request,
      metadata || {},
      methodDescriptor_AdProviderReport_GetAdProviderDimensionCategoryAdGroupDailyResultReportByAdProvider);
};


module.exports = proto.adx.ad_provider_report;

