<template>
  <div style="display: flex; pointer-events: all">
    <div style="width: 340px; margin-right: 20px">
      <div
        v-bind:class="[selectmode == 'start' ? 'timeselector--active' : '']"
        style="display: flex; border: 1px solid #d2d7dd; padding: 1px; border-radius: 5px"
        v-on:click="selectStartYmd"
      >
        <img
          src="@/assets/icon/date_range-24px.svg"
          style="margin-left: 20px; margin-right: 20px; margin-top: 4px; width: 18px; height: 18px"
        />
        <div style="font: 18px Noto Sans JP; letter-spacing: 0px; color: #4d4f5c">
          {{ selectRange.startYmd.year }}/{{ ('00' + selectRange.startYmd.month).slice(-2) }}/{{
            ('00' + selectRange.startYmd.day).slice(-2)
          }}
        </div>
      </div>
      <div style="display: flex; margin-top: 10px; margin-bottom: 10px">
        <img
          src="@/assets/icon/keyboard_arrow_left-24px.svg"
          class="date_fromto_selector__minusmonth"
          v-on:click="minusMonth()"
        />
        <div style="width: 100%; text-align: center">
          {{ ('00' + showYmd.month).slice(-2) }} {{ showYmd.year }}
        </div>
      </div>
      <div style="padding-left: 10px; width: 100%">
        <Calendar
          ref="bcalendar"
          :mode="selectmode"
          :year="showYmd.year"
          :month="showYmd.month"
          :range="selectRange"
        />
      </div>
    </div>
    <div style="width: 340px; margin-left: 20px">
      <div
        style="display: flex; border: 1px solid #d2d7dd; padding: 1px; border-radius: 5px"
        v-bind:class="[selectmode == 'end' ? 'timeselector--active' : '']"
        v-on:click="selectEndYmd"
      >
        <img
          src="@/assets/icon/date_range-24px.svg"
          style="margin-left: 20px; margin-right: 20px; margin-top: 4px; width: 18px; height: 18px"
        />
        <div style="font: 18px Noto Sans JP; letter-spacing: 0px; color: #4d4f5c">
          {{ selectRange.endYmd.year }}/{{ ('00' + selectRange.endYmd.month).slice(-2) }}/{{
            ('00' + selectRange.endYmd.day).slice(-2)
          }}
        </div>
      </div>
      <div style="display: flex; margin-top: 10px; margin-bottom: 10px">
        <div style="width: 100%; text-align: center">
          {{ monthFromNextMonth() }} {{ yearFromNextMonth() }}
        </div>
        <img
          src="@/assets/icon/keyboard_arrow_right-24px.svg"
          class="date_fromto_selector__plusmonth"
          v-on:click="plusMonth()"
        />
      </div>
      <div style="padding-right: 10px; width: 100%">
        <Calendar
          ref="acalendar"
          :mode="selectmode"
          :year="yearFromNextMonth()"
          :month="monthFromNextMonth()"
          :range="selectRange"
        />
      </div>
    </div>
    <div style="width: 210px; margin-left: 20px">
      <button
        class="date_fromto_btn"
        v-on:click="selectToday"
        v-bind:class="[rangeMode == 'today' ? 'date_fromto_btn--active' : '']"
      >
        今日
      </button>
      <button
        class="date_fromto_btn"
        v-on:click="before(-1)"
        v-bind:class="[rangeMode == '-1' ? 'date_fromto_btn--active' : '']"
      >
        昨日
      </button>
      <button
        class="date_fromto_btn"
        v-on:click="before(-7)"
        v-bind:class="[rangeMode == '-7' ? 'date_fromto_btn--active' : '']"
      >
        過去7日
      </button>
      <button
        class="date_fromto_btn"
        v-on:click="before(-14)"
        v-bind:class="[rangeMode == '-14' ? 'date_fromto_btn--active' : '']"
      >
        過去14日
      </button>
      <button
        class="date_fromto_btn"
        v-on:click="before(-30)"
        v-bind:class="[rangeMode == '-30' ? 'date_fromto_btn--active' : '']"
      >
        過去30日
      </button>
      <button
        class="date_fromto_btn"
        v-on:click="all"
        v-bind:class="[rangeMode == 'all' ? 'date_fromto_btn--active' : '']"
      >
        全期間
      </button>
      <button
        class="date_fromto_btn"
        v-on:click="custom"
        v-bind:class="[rangeMode == 'custom' ? 'date_fromto_btn--active' : '']"
      >
        カスタム
      </button>
      <div style="display: flex; margin-top: 15px">
        <button class="date_fromto_decide_btn" v-on:click="apply">適用</button>
        <button class="date_fromto_decide_btn" v-on:click="cancel">キャンセル</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.timeselector {
  box-shadow: 5px 5px 5px #0000ff;
}

.timeselector--active {
  box-shadow: 0 0 2px 0 rgb(0, 153, 255);
  outline: 0;
}

.date_fromto_btn:hover {
  background: #39434ee0;
  color: #fff;
}

.date_fromto_decide_btn:hover {
  background: #39434ee0;
  color: #fff;
}
</style>

<script>
import Calendar from '@/components/Calendar.vue';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';
import { TimeYmdRange } from '@/js/tacklebox/date/TimeYmdRange.js';

export default {
  name: 'RangeCalendar',
  data() {
    return {
      name: 'RangeCalendar',
      inputData: '',
      showYmd: new TimeYmd(),
      selectRange: new TimeYmdRange(),
      selectmode: '',
      rangeMode: 'all',
      today: null,
    };
  },
  computed: {},
  emits: ['rangecalendarevent', 'draw'],
  mounted() {
    let self = this;
    if (this.range) {
      this.showYmd = new TimeYmd();
      this.selectRange = new TimeYmdRange(
        this.range.startYmd.year,
        this.range.startYmd.month,
        this.range.startYmd.day,
        this.range.endYmd.year,
        this.range.endYmd.month,
        this.range.endYmd.day
      );
    }
    this.selectRange.subject.addObserver(function () {
      if (self.rangeMode == 'custom') {
        if (self.selectmode == 'end') {
          self.selectStartYmd();
        } else {
          self.selectEndYmd();
        }
      }
    });

    if (this.todayYmd) {
      this.today = new TimeYmd(this.todayYmd.year, this.todayYmd.month, this.todayYmd.day);
    } else {
      let nd = new Date();

      this.today = new TimeYmd(nd.getFullYear(), nd.getMonth() - 1, nd.getDay());
    }
    this.showYmd = new TimeYmd(this.selectRange.endYmd.year, this.selectRange.endYmd.month);
    this.showYmd.decMonth();

    //list
    if (
      this.selectRange.startYmd.year == this.selectRange.endYmd.year &&
      this.selectRange.startYmd.month == this.selectRange.endYmd.month &&
      this.selectRange.startYmd.day == this.selectRange.endYmd.day
    ) {
      this.rangeMode = 'all';
      if (
        this.selectRange.startYmd.year == this.today.year &&
        this.selectRange.startYmd.month == this.today.month &&
        this.selectRange.startYmd.day == this.today.day
      ) {
        this.rangeMode = 'today';
      } else {
        let yd = new TimeYmd(this.today.year, this.today.month, this.today.day);
        yd.calcDay(-1);
        if (
          this.selectRange.startYmd.year == yd.year &&
          this.selectRange.startYmd.month == yd.month &&
          this.selectRange.startYmd.day == yd.day
        ) {
          this.rangeMode = '-1';
        }
      }
    } else {
      this.selectStartYmd();
      let yd = new TimeYmd(this.today.year, this.today.month, this.today.day);
      yd.calcDay(-1);
      if (
        yd.year == this.selectRange.endYmd.year &&
        yd.month == this.selectRange.endYmd.month &&
        yd.day == this.selectRange.endYmd.day
      ) {
        let diffDay =
          (new Date(yd.year, yd.month, yd.day) -
            new Date(
              this.selectRange.startYmd.year,
              this.selectRange.startYmd.month,
              this.selectRange.startYmd.day
            )) /
          86400000;

        switch (diffDay) {
          case 1:
            this.rangeMode = '-1';
            this.selectmode = '';
            break;
          case 6:
            this.rangeMode = '-7';
            this.selectmode = '';
            break;
          case 13:
            this.rangeMode = '-14';
            this.selectmode = '';
            break;
          case 29:
            this.rangeMode = '-30';
            this.selectmode = '';
            break;
        }
      }
    }
  },
  methods: {
    yearFromNextMonth() {
      let t = new TimeYmd(this.showYmd.year, this.showYmd.month);
      t.incMonth();
      return t.year;
    },
    monthFromNextMonth() {
      let t = new TimeYmd(this.showYmd.year, this.showYmd.month);
      t.incMonth();
      return t.month;
    },
    apply() {
      this.$emit('rangecalendarevent', 'apply', this.selectRange);
    },
    cancel() {
      this.$emit('rangecalendarevent', 'close');
    },
    selectStartYmd() {
      this.selectmode = 'start';
      this.rangeMode = 'custom';
    },
    selectEndYmd() {
      this.selectmode = 'end';
      this.rangeMode = 'custom';
    },
    plusMonth() {
      this.$refs.bcalendar.$emit('draw');
      this.$refs.acalendar.$emit('draw');
      this.showYmd.incMonth();
    },
    minusMonth() {
      this.$refs.bcalendar.$emit('draw');
      this.$refs.acalendar.$emit('draw');
      this.showYmd.decMonth();
    },
    selectToday() {
      this.selectmode = '';
      this.rangeMode = 'today';
      this.selectRange.setStartYmd(this.today.year, this.today.month, this.today.day);
      this.selectRange.setEndYmd(this.today.year, this.today.month, this.today.day);
    },
    before(day) {
      this.selectmode = '';
      this.rangeMode = day;

      let s = new TimeYmd(this.today.year, this.today.month, this.today.day);
      s.calcDay(day);
      let e = new TimeYmd(this.today.year, this.today.month, this.today.day);
      e.calcDay(-1);
      this.selectRange.setStartYmd(s.year, s.month, s.day);
      this.selectRange.setEndYmd(e.year, e.month, e.day);
    },
    all() {
      this.selectmode = '';
      this.rangeMode = 'all';
    },
    custom() {
      this.selectStartYmd();
      //this.selectmode = 'start';
      //this.rangeMode = 'custom';
    },
  },
  components: {
    Calendar,
  },
  props: {
    mode: String,
    year: Number,
    month: Number,
    range: Object,
    todayYmd: Object,
  },
};
</script>
