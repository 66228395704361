function ColumnInfo(index, name) {
    this.index = index;
    this.name = name;
}

function ColumnShowHideProxy() {
    this.top = 100;
    this.left = 100;
    this.isEditMode = false;
    this.stocker = null;
    this.select = -1;
    this.columinfos = null;
    this.exclusions = [];
}

ColumnShowHideProxy.prototype.setTop = function (top) {
    this.top = top;
};

ColumnShowHideProxy.prototype.setLeft = function (left) {
    this.left = left;
};

ColumnShowHideProxy.prototype.setStocker = function (stocker, select) {
    this.stocker = stocker;
    this.select = select;
    this.isEditMode = false;
};

ColumnShowHideProxy.prototype.clear = function () {
    this.stocker = null;
    this.select = -1;
};

ColumnShowHideProxy.prototype.changeEditMode = function () {
    this.isEditMode = true;
};

ColumnShowHideProxy.prototype.setColumnInfos = function (columinfos) {
    this.columinfos = columinfos;
};

export { ColumnShowHideProxy, ColumnInfo }