<template>
  <div class="overlay_layer" v-if="isShowRange">
    <div class="summary_rangecalendar_background" v-on:click="rangecalendarevent"></div>
    <div class="summary_rangecalendar_layer">
      <div class="summary_rangecalendar">
        <div class="summary_rangecalendar__wrapper">
          <div v-if="dateRangeSelector">
            <RangeCalendar
              :range="dateRangeSelector.timeYmdRange"
              :todayYmd="getTodayYmd()"
              @rangecalendarevent="rangecalendarevent"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main_content_wrapper">
    <div class="adx_report_keyword">
      <div class="left_main_content">
        <div style="background: white; width: calc(100% - 20px)">
          <div class="left_main_content__header">
            <ContentHeader
              title="キーワード"
              :timeRange="dateRangeSelector"
              :adx="adx"
              @timerangeevent="timerangeevent"
            />
          </div>

          <div style="padding: 10px; width: 100%; display: flex">
            <div
              v-for="(cell, index) in page.tab.cells"
              :key="index"
              v-bind:class="{
                backlash_tab: true,
                'backlash_tab--active': cell.getId() == page.selectedId,
              }"
              v-on:click="tabselect(cell.getId())"
            >
              <div>{{ cell.getName() }}</div>
            </div>
          </div>
        </div>
        <div class="left_main_content__body">
          <div class="box">
            <div class="frame">
              <div class="box">
                <div style="width: 100%">
                  <div class="frame__header">キーワード別実績</div>
                  <div class="frame__body">
                    <Spinner v-if="page.isNetworking" />
                    <div class="nodataline" v-if="!page.isNetworking && page.isNoData">NoData</div>
                    <table
                      class="floater_table floater_table--full"
                      v-if="!page.isNetworking && !page.isNoData"
                    >
                      <thead class="floater_table__header">
                        <tr>
                          <template
                            v-for="(item, index) in page.table.getHeaderCell()"
                            :key="index"
                          >
                            <th
                              class="floater_table__keyword"
                              v-bind:class="[index == 0 ? 'floater_table__keyword--left' : '']"
                            >
                              {{ item.getString() }}
                            </th>
                          </template>
                        </tr>
                      </thead>

                      <tbody class="floater_table__body">
                        <template v-for="(cellColms, index) in page.table.cellRows" :key="index">
                          <tr>
                            <template v-for="(cell, cindex) in cellColms" :key="cindex">
                              <td
                                class="floater_table__keyword"
                                v-bind:class="[cindex == 0 ? 'floater_table__keyword--left' : '']"
                              >
                                {{ cell.getString() }}
                              </td>
                            </template>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub_content_layer">
        <div class="sub_content_wrapper">
          <div id="lay_right_sidemenu" class="right_sidemenu_layer">
            <SideMenu :adx="adx" :selectId="getSelectedPageId()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.floater_table__keyword--left {
  text-align: left;
}
</style>

<script>
// @ is an alias to /src
import SideMenu from '@/components/report/SideMenu.vue';
import ContentHeader from '@/components/report/ContentHeader.vue';
import Spinner from '@/components/layout/Spinner.vue';

import { KeywordPage } from '@/js/page/report/KeywordPage.js';

import RangeCalendar from '@/components/RangeCalendar.vue';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';

export default {
  name: 'Keyword',
  data() {
    return {
      name: 'キーワード',
      page: new KeywordPage(),
      isLoad: false,
      selectedPageId: 0,
      dateRangeSelector: null,
      isShowRange: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.isLoad = false;
    this.initialize(to.params['id'], to.params['cid'], this.dateRangeSelector);
  },
  emits: ['screenevent'],
  mounted() {
    let self = this;
    this.$emit('screenevent', 'keyword');
    let p = this.adx.getScreenStructureById('report').getPageById(this.$route.params['id']);

    if (p) {
      this.dateRangeSelector = this.adx.getScreenStructureById('report').dateRangeSelector;
      this.dateRangeSelector.timeYmdRange.subject.removeAll();
      this.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
        self.isLoad = false;
        self.initialize(
          self.$route.params['id'],
          self.$route.params['cid'],
          self.dateRangeSelector
        );
      });

      this.initialize(this.$route.params['id'], this.$route.params['cid'], this.dateRangeSelector);
    } else {
      this.adx.getScreenStructureById('report').subject.addObserver(function () {
        let p = self.adx.getScreenStructureById('report').getPageById(self.$route.params['id']);
        if (p) {
          self.dateRangeSelector = self.adx.getScreenStructureById('report').dateRangeSelector;
          self.dateRangeSelector.timeYmdRange.subject.removeAll();
          self.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
            self.isLoad = false;
            self.initialize(
              self.$route.params['id'],
              self.$route.params['cid'],
              self.dateRangeSelector
            );
          });

          self.initialize(
            self.$route.params['id'],
            self.$route.params['cid'],
            self.dateRangeSelector
          );
        }
      });
    }
  },
  methods: {
    tabselect(id) {
      this.page.selectTabByAdProviderId(id);
    },
    initialize(id, cid, timeRange) {
      if (this.isLoad) {
        return;
      }
      this.isLoad = true;
      this.selectedPageId = id;
      this.page.initialize(
        id,
        cid,
        this.adx.getScreenStructureById('report').advertizerId,
        timeRange,
        this.adx.user
      );
    },
    getTodayYmd() {
      let time = this.adx.getNowTime();
      return new TimeYmd(time.ymd.year, time.ymd.month, time.ymd.day);
    },
    getSelectedPageId() {
      return String(this.selectedPageId);
    },
    rangecalendarevent: function (event, arg) {
      if (event == 'apply') {
        this.dateRangeSelector.setRangeYmd(
          arg.startYmd.year,
          arg.startYmd.month,
          arg.startYmd.day,
          arg.endYmd.year,
          arg.endYmd.month,
          arg.endYmd.day
        );
      }
      this.isShowRange = false;
    },
    timerangeevent: function (event) {
      console.log(event);
      this.isShowRange = true;
    },
  },
  components: {
    SideMenu,
    ContentHeader,
    Spinner,
    RangeCalendar,
  },
  props: {
    adx: Object,
  },
};
</script>

