import { getTabListOfKeywordReportPage, getKeywordReportByAdProvider } from "../../com/grpc/keyword.js";
import { AdProviderTab, ChartStructureTable } from '../data.js'

function KeywordPage() {
    this.tab = new AdProviderTab();
    this.table = new ChartStructureTable(null);
    this.selectedId = 0;
    this.loadingUuid = null;
    this.isNetworking = false;
    this.isNoData = false;
    this.pageId = 0;
    this.categoryId = 0;
    this.advertizerId = 2;
    this.timeRange = null;
    this.user = null;
}
KeywordPage.prototype.initialize = function (pageId, categoryId, advertizerId, timeRange, user) {
    this.pageId = pageId;
    this.advertizerId = advertizerId;
    this.categoryId = categoryId;
    this.timeRange = timeRange;
    this.tabs = [];
    this.user = user;
    let self = this;
    let initialDisplay = true;
    self.tab.cells = [];
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.advertizerId = this.advertizerId;
    let cuuid = getTabListOfKeywordReportPage(pageId, request, function (uuid, data) {
        if (cuuid == uuid && data != null) {
            let json = JSON.parse(JSON.stringify(data.result));
            self.tab.addAdProvider(json.adprovider);
            if (initialDisplay) {
                self.selectTabByAdProviderId(json.adprovider.adproviderid);
                initialDisplay = false;
            }
        }
    });
};
KeywordPage.prototype.selectTabByAdProviderId = function (id) {
    this.selectedId = id;
    let self = this;
    self.table.reset();
    this.isNetworking = true;
    this.isNoData = false;
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.timeRequest = this.timeRange.getTimeRequest();
    request.advertizerId = this.advertizerId;


    let cuuid = getKeywordReportByAdProvider(id, this.categoryId, request, function (uuid, data) {
        if ((cuuid == uuid) && (uuid == self.loadingUuid) && data != null) {
            if (data.error) {
                if (data.error.length > 0) {
                    self.isNetworking = false;
                    self.isNoData = true;
                }
            }
            let json = data.result;
            if (!!(json.structure) != false) {
                let structure = JSON.parse(json.structure);
                self.table.initialize(structure, "all");
            } else if (Object.entries(json.keywordsMap).length != 0) {
                // for (let i in json.keywords) {
                // let keyword = json.keywords[i];
                let keyword = json.keywordsMap;
                self.table.addRow(keyword);
                // }
            }

        }
        if (self.loadingUuid == cuuid) {
            self.isNetworking = false;
        }

    });
    this.loadingUuid = cuuid;
}

export { KeywordPage }