<template>
  <div
  :style="{
            width: 'calc(100% + 8px)',
            height: 'calc(100% + 8px)',
            fontSize: '18px',
            boxShadow: '0px 3px 20px #00000029',
            backgroundColor: 'white',
            position: 'relative',
            top: '-4px',
            left: '-4px',
            padding: '10px',
            borderRadius: '8px'
    }"
  >
    <textarea
      class="mytextarea"
      ref="txta_main"
      :style="{
        resize: 'none',
        width: '100%',
        height: '100%',
        outline: 'none',
        boxShadow: '0 0 0' // よくわからないけど、この行を入れないと枠線がでちゃう。
      }"
      style="outline: none;"
      v-model="inputData"
      @keydown.enter.exact="decide(0)"
      v-on:blur="decide(0)"
    />
  </div>
</template>
<style scoped>
textarea:focus {
    outline: none;
    box-shadow: 0 0 0;
}
</style>
<script>
export default {
  name: "EditItem2",
  data() {
    return {
      name: "EditItem2",
      inputData: "",
    };
  },
  computed: {},
  mounted() {
    this.inputData = this.memoball.edit;
    this.$refs.txta_main.focus();
  },
  methods: {
    inputText(item) {
      item.edit();
    },
    yyy(){

    },
    xxx(){
        this.inputData = (this.inputData+('\n'));
    },
    decide(mode) {
      if (mode) {
        //
      }
      //this.memoball.cancelEdit();
      this.memoball.save(this.inputData);
    },
  },
  components: {},
  props: {
    mode: Number,
    item: Object,
    next: Object,
    prev: Object,
    memoball: Object,
  },
};
</script>
