<template>
  <div class="backlash_line">
    <div class="backlash_line__left">{{ title }}</div>
    <div class="backlash_line__center"></div>
    <div class="backlash_line__right">
      <div class="date_fromto_selector">
        <img
          class="date_fromto_selector__minusmonth"
          src="@/assets/icon/keyboard_arrow_left-24px.svg"
        />
        <img
          class="date_fromto_selector__plusmonth"
          src="@/assets/icon/keyboard_arrow_right-24px.svg"
        />
        <div class="date_fromto_selector__date">2021年05月</div>
        <div>
          <div
            style="margin-top: 6px; margin-left: 20px; width: 260px;"
            class="date_fromto_selector__thismonth"
          >
            バジェット設定額を取得する
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllocationContentHeader",
  props: {
    title: String,
  },
};
</script>

<style scoped>
.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /*transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;*/
}

.btn-outline-dark {
  border: 1px solid #39434e;
}
</style>
