import { AdPagesListRequest, AdPageComponentsRequest } from './protos_gen/page_pb.js'
import { PageClient } from './protos_gen/page_grpc_web_pb.js'
import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function getAdPagesList(id, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new AdPagesListRequest();
    // request.setPage(dto.page);
    request.setAdvertizerid(dto.advertizerId);

    let client = new PageClient(url.grpc, null, null);
    const stream = client.getAdPagesList(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

function getAdPageComponents(id, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new AdPageComponentsRequest();
    request.setAdpageid(id);
    request.setAdvertizerid(dto.advertizerId);

    let client = new PageClient(url.grpc, null, null);
    let stream = client.getAdPageComponents(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

export { getAdPagesList, getAdPageComponents }
