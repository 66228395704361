<template>
  <div>
    <div class="overlay_layer" v-if="isShowRange">
      <div class="summary_rangecalendar_background" v-on:click="rangecalendarevent"></div>
      <div class="simple_rangecalendar_layer"
        :style="{ top: `${rangePositionY}px`, left: `${rangePositionX}px`, width: `380px` }">
        <div class="simple_rangecalendar" style="width: 380px">
          <div class="summary_rangecalendar__wrapper">
            <div v-if="dateRangeSelector">
              <DateSelectCalendarSimple year="2022" month="3" :range="dateRangeSelector" :todayYmd="getTodayYmd()"
                :startYmd="startYmd()" @rangecalendarevent="rangecalendarevent" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_content_wrapper">
      <div class="adx_log">
        <div class="actionlog_stattab">
          <div style="padding: 10px; width: 100%; display: flex">
            <div class="backlash_tab yubi" v-on:click="page.tabselect(0)"
              v-bind:class="{ 'backlash_tab--active': page.selectedMode == 0 }">
              <div>実施中・実施予定</div>
            </div>
            <div class="backlash_tab yubi" v-on:click="page.tabselect(1)"
              v-bind:class="{ 'backlash_tab--active': page.selectedMode == 1 }">
              <div>実施済み</div>
            </div>
          </div>
        </div>
        <div class="full_main_content">
          <div class="box">
            <div class="frame">
              <div class="box">
                <div>
                  <div class="frame__header" v-if="false">上流行程施策</div>
                  <div class="frame__body">
                    <div class="filterbox">
                      <div class="filterbox__sorts">
                        <div class="filterbox__sort_btn" v-if="page.sorts.length > 0" v-on:click="page.openSortFilter()">
                          <div class="filterbox__sort_btn_icon">
                            <img width="16" height="16" src="@/assets/icon/arrow_upward_FILL0_wght400_GRAD0_opsz48.svg"
                              v-if="page.sorts[0].direction == 0" />
                            <img width="16" height="16" src="@/assets/icon/arrow_downward_FILL0_wght400_GRAD0_opsz48.svg"
                              v-if="page.sorts[0].direction == 1" />
                          </div>
                          <div class="filterbox__sort_btn_ctrl">
                            <img width="16" height="16"
                              src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                          </div>
                          {{ page.sorts[0].title }}
                          <div class="sortbox_box_background" v-on:click.stop="closeSortFilter()" v-if="page.isSortFilter">
                          </div>
                          <div class="sortbox" v-if="page.isSortFilter">
                            <div class="sort_condition">
                              <div class="sort_condition__target" v-on:click.stop="startSortCandidates()">{{ page.sorts[0].title }}
                                <div class="filter_selected_item_ctrl">
                                  <img width="16" height="16"
                                    src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                                </div>
                                <div class="sort_condition_candidates__background" v-on:click.stop="closeSortCandidates()" v-if="isStartSortCandidates"></div>
                                <div class="sort_condition_candidates" v-if="isStartSortCandidates">
                                    <template v-for="(item, index) in sortCandidates()" :key="index">
                                      <div class="sort_condition_candidate" v-on:click.stop="selectOrder(item.id, page.getSortDirection())">
                                      {{ item.title }}</div>
                                    </template>
                                </div>
                              </div>
                              <div style="margin: auto; padding-left: 8px; padding-right: 8px;">が</div>
                              <div class="sort_condition__order" v-on:click.stop="showSortorder()">
                                <div class="filter_selected_item"><div v-if="page.sorts[0].direction == 0">昇順</div><div v-if="page.sorts[0].direction == 1">降順</div>
                                  <div class="filter_selected_item_ctrl">
                                    <img width="16" height="16"
                                      src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                                  </div>
                                  <div class="filter_sort_items__background" v-if="isShowSortorder" v-on:click.stop="closeSortorder()"></div>
                                  <div class="filter_sort_items" v-if="isShowSortorder">
                                    <div class="filter_sort_item" v-on:click="page.sortOrderUp()">昇順</div>
                                    <div class="filter_sort_item" v-on:click="page.sortOrderDown()">降順</div>
                                  </div>

                                </div>
                              </div>
                              <div class="sort_condition__ctrl" v-on:click="showSortControl()">
                                ...
                                <div class="sort_condition__ctrl_items" v-if="isShowSortControl">
                                  <div class="sort_condition__ctrl_item" v-on:click.stop="sortreset">削除</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="filterbox__filters">
                        <div class="filterbox__filter_summary">
                          <div class="filterbox_summary_btn" v-if="page.filters.length > 0" v-on:click="openSummaryFilter">
                            <div class="filterbox_summary_btn__icon">
                              <img width="16" height="16"
                                src="@/assets/icon/filter_list_FILL0_wght400_GRAD0_opsz48.svg" />
                            </div>
                            <div class="filterbox_summary_btn__ctrl">
                              <img width="16" height="16"
                                src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                            </div>
                            {{ page.filters.length }}件のフィルタ
                            <div class="summary_filter_box_background" v-on:click.stop="closeSummaryFilter()" v-if="page.isSummaryFilter">
                            </div>
                            <div class="summary_filter_box" v-if="page.isSummaryFilter">
                              <div class="summary_filter_box__conditions">
                                <template v-for="(item, index) in page.filters" :key="index">
                                  <div class="filter_condition">
                                    <div class="filter_condition__andor" v-if="index == 0">条件
                                    </div>
                                    <div class="filter_condition__andor" v-on:click="onStartAndOr(index)"
                                      :class="[index == 1 ? 'filter_condition__andor--waku' : '']" v-if="index != 0">{{getAndOrText()}}
                                      <div class="filter_selected_item_ctrl" v-if="index == 1">
                                        <img width="16" height="16"
                                          src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                                      </div>
                                      <div class="filter_condition__andorlist__background"  v-if="index == 1 && isFilterAndOr == true" v-on:click.stop="closeFilterAndOr()"></div>
                                      <div class="filter_condition__andorlist" v-if="index == 1 && isFilterAndOr == true">
                                        <div class="filter_condition__andorlist_item" v-on:click.stop="selectAndOr('and')">AND</div>
                                        <div class="filter_condition__andorlist_item" v-on:click.stop="selectAndOr('or')">OR</div>
                                      </div>
                                    </div>
                                    <div v-on:click.stop="changeTarge(item)" class="filter_condition__target">{{ item.title }}
                                      <div class="filter_condition__target_ctrl">
                                        <img width="16" height="16" v-if="true"
                                          src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                                      </div>
                                      <div class="filter_condition__change_targets_background" v-on:click.stop="closeChangeTarge()" v-if="changeTarget != null && item == changeTarget"></div>
                                      <div class="filter_condition__change_targets" v-if="changeTarget != null && item == changeTarget">
                                        <template v-for="(fm, findex) in page.filtermaster" :key="findex">
                                          <div class="filter_item" v-on:click.stop="changefilter(fm.id)">{{ fm.title }}</div>
                                        </template>
                                      </div>
                                    </div>
                                    <div style="margin: auto; padding-left: 8px; padding-right: 8px;">が</div>
                                    <div class="filter_condition__value" v-if="item.type == 0">
                                      <input type="text" style="width: 100%;margin-top: 4px; margin-left: 8px;" v-model="item.value" v-on:blur="onFilterChangeText" @keydown.enter="onFliterTextEnter" @compositionstart="composing=true" @compositionend="composing=false" />
                                    </div>
                                    <div class="filter_condition__value_time" v-if="item.type == 2" v-on:click="startTimeRange(item)" style="width: 290px; margin-left:15px; cursor: pointer;">

                                      <div style="display: flex;padding-top: 4px;">
                                        <div style="position: relative;border-radius: 4px;border: 0.5px solid #DDDFE2;padding: 4px;width: 100px;">
                                          <div style="padding-left: 20px;">{{ item.value.start }}
                                          </div>
                                          <div style="position: absolute;top: 4px;"><img width="16" height="16" src="@/assets/icon/date_range-24px.svg" /></div>
                                        </div>
                                        <div style="padding-top: 4px; padding-left: 8px; padding-right: 8px;">から</div>
                                        <div style="position: relative;border-radius: 4px;border: 0.5px solid #DDDFE2;padding: 4px;width: 100px;">
                                          <div style="padding-left: 20px;">{{ item.value.end }}
                                          </div>
                                          <div style="position: absolute;top: 4px;"><img width="16" height="16" src="@/assets/icon/date_range-24px.svg" /></div>
                                        </div>
                                      </div>

                                    </div>
                                    <div class="filter_condition__value" v-if="item.type == 1">
                                      <div class="filter_condition__target" v-on:click="startFilterConditionSelect(item)">{{ item.value.title }}
                                        <div class="filter_condition__target_ctrl">
                                          <img width="16" height="16"
                                            src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                                        </div>
                                        <div class="filter_condition__candidateitems_background" v-on:click.stop="closeFilterConditionSelect()" v-if="filterConditionCandidateitems == item"></div>
                                        <div class="filter_condition__candidateitems" v-if="filterConditionCandidateitems == item">
                                          <template v-for="(vitem, index) in item.valueCandidates" :key="index">
                                            <div class="filter_item" v-on:click.stop="selectValue(item, vitem.id)">{{ vitem.title }}</div>
                                          </template>
                                          </div>

                                      </div>
                                    </div>
                                    <div class="filter_condition__order" v-if="item.type != 2">
                                      <div class="filter_selected_item" v-on:click="startFilterOrder(item)">{{ item.getSelectedFilterItem()?.title }}
                                        <div class="filter_selected_item_ctrl">
                                          <img width="16" height="16"
                                            src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                                        </div>
                                      </div>
                                      <div class="filter_condition__order__background" v-if="filterOrders == item" v-on:click.stop="closeFilterOrder()"></div>
                                      <div class="filter_condition_order__items" v-if="filterOrders == item">
                                        <template v-for="(fitem, index) in item.getFilterItems()" :key="index">
                                          <div class="filter_item" v-on:click="selectFilterItem(item, fitem.id)">{{ fitem.title }}</div>
                                        </template>
                                      </div>
                                    </div>
                                    <div class="filter_condition__ctrl" v-on:click.stop="summaryfilterDeleteTarget(item)">
                                      ...
                                      <div class="filter_condition__ctrl_background"
                                        v-if="deleteTargetSummaryFitlerItem != null && deleteTargetSummaryFitlerItem == item"
                                        v-on:click.stop="summaryFilterDelete()"></div>
                                      <div class="filter_condition_crlbox"
                                        v-if="deleteTargetSummaryFitlerItem != null && deleteTargetSummaryFitlerItem == item">
                                        <div class="filter_condition_crlbox__item" v-on:click.stop="filterdelete(item)">削除</div>
                                      </div>
                                    </div>
                                  </div>
                                </template>

                              </div>
                              <div class="summary_filter_box__separator"></div>
                              <div class="summary_filter_box__add" v-on:click="newFilter">+NEW</div>
                              <div class="summary_filter_box__ctrl">
                                <div class="summary_filter_box__ctrl_btn" v-on:click="filterClear()">フィルタをリセット</div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div class="filterbox__filter_order">
                          <template v-for="(item, index) in page.filters" :key="index">
                            <div class="filterbox__filter_order_btn" v-on:click="ttt(item, $event)">
                              <div class="filterbox_filter_order_btn__icon">
                              </div>
                              <div class="filterbox_filter_order_btn__ctrl">
                                <img width="16" height="16"
                                  src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                              </div>
                              {{ item.title }}
                              <div class="item_filter_box" v-if="false">
                                <div class="filter_condition">
                                  <div class="filter_condition__andor">条件</div>
                                  <div class="filter_condition__target"></div>
                                  <div>が</div>
                                  <div class="filter_condition__value"></div>
                                  <div class="filter_condition__order">
                                    <div class="filter_selected_item">含む</div>
                                    <div class="filter_items" v-if="false">
                                      <div class="filter_item">昇順</div>
                                      <div class="filter_item">降順</div>
                                    </div>
                                  </div>
                                  <div class="filter_condition__ctrl">...</div>
                                </div>
                              </div>



                            </div>
                          </template>

                        </div>
                        <div class="filterbox__filter_add" v-if="false">
                          <img src="@/assets/icon/add_black_24dp_fblack.svg" v-on:click="newFilter" />
                        </div>
                      </div>
                    </div>
                    <div class="logarea">
                      <table class="floater_table">
                        <thead class="floater_table__header">
                          <tr>
                            <th class="markertd"></th>
                            <th v-on:click="singlefilter(10, $event)" style="
                                  width: 125px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                  cursor: pointer;
                                ">
                              ステータス
                            </th>
                            <th style="min-width: 160px;" v-on:click="singlefilter(20, $event)">タグ</th>
                            <th v-on:click="singlefilter(30, $event)" style="
                                  width: 160px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                  cursor: pointer;
                                ">
                              開始日
                            </th>
                            <th v-on:click="singlefilter(40, $event)" style="
                                  width: 160px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                  cursor: pointer;
                                ">
                              媒体
                            </th>
                            <th v-on:click="singlefilter(50, $event)" style="
                                  width: 200px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                  cursor: pointer;
                                ">
                              検証仮説
                            </th>
                            <th v-on:click="singlefilter(60, $event)" style="
                                  width: 350px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                  cursor: pointer;
                                ">
                              施策内容
                            </th>
                            <th v-on:click="singlefilter(70, $event)" style="
                                  width: 160px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                  cursor: pointer;
                                ">
                              検証日
                            </th>
                            <th v-on:click="singlefilter(80, $event)" style="
                                  width: 80px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: center;
                                  cursor: pointer;
                                ">
                              結果
                            </th>
                            <th v-on:click="singlefilter(90, $event)" style="
                                  width: 340px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                  cursor: pointer;
                                ">
                              結果詳細
                            </th>
                            <th v-on:click="singlefilter(100, $event)" style="
                                  width: 340px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                  cursor: pointer;
                                ">
                              ファインディングス・ネクストアクション
                            </th>
                            <th v-if="!isAdvertizer()" v-on:click="singlefilter(120, $event)" style="
                                  width: 340px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                  cursor: pointer;
                                ">社内メモ</th>
                            <th style="
                                  width: 95px;
                                  padding: 20px;
                                  font: normal normal 300 12px/12px Noto Sans JP;
                                  text-align: left;
                                " v-if="true">
                              参照データ
                            </th>
                          </tr>
                        </thead>
                        <transition-group name="slide-fade" tag="tbody" class="floater_table__body">
                          <tr v-for="(item, index) in page.getInprogressActionLogs()" :key="item.id">
                            <td class="markertd" v-on:mouseover="dragmouseover(item, index)"
                              v-on:mouseleave="dragmouseleave(item)">
                              <div style="display: flex; width: 32px" v-if="!isAdvertizer()">
                                <div class="mark yubi" v-on:click="addActionLog()">
                                  <img class="cmark" src="@/assets/icon/add_black_24dp.svg" />
                                </div>
                                <div class="mark" v-on:click="inputText(item.deleter)"
                                  v-on:mousedown="longpressPress(item, index)">
                                  <!-- <img
                                      class="cmark yubi"
                                      src="@/assets/icon/drag_indicator_black_24dp.svg"
                                    /> -->
                                  <div class="cmark yubi dmark"></div>
                                </div>
                                <div class="mark" style="position: relative">
                                  <div style="position: absolute" v-if="item.deleter.isEdit()">
                                    <EditCstSelectItem :item="item.deleter" />
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td v-on:mouseover="dragmouseover(item, index)" v-on:mouseleave="dragmouseleave(item)"
                              v-on:click="inputText(item.status)"
                              v-bind:class="[dragCandidate == item ? 'tr--dragcandidate' : '']" style="width: 125px"
                              class="floater_table__keyword logstep2 floater_table__keyword--first">
                              <div style="display: flex">
                                <div v-bind:class="{
                                    success_circle: item.status.value == 'todo',
                                    active_circle: item.status.value == 'in-progress',
                                    secondary_circle: item.status.value == 'done',
                                  }" style="margin-right: 10px; margin-top: 6px;margin-left: 8px;"></div>
                                <div>{{ item.status.getStatusTitle() }}</div>
                              </div>
                              <div style="position: relative">
                                <div style="position: absolute" v-if="item.status.isEdit()">
                                  <EditCstSelectItem :item="item.status" />
                                </div>
                              </div>
                            </td>
                            <td class="floater_table__keyword logstep2" v-on:click="inputText(item.tagSltr)">
                              <div style="position: relative">
                                <div style="position: absolute" v-if="item.tagSltr.isEdit()">
                                  <EditCstMultiSelectItem :item="item.tagSltr" />
                                </div>
                              </div>
                              <div>
                                <template v-for="(item, index) in item.tagSltr.value" :key="index">
                                  <div style="font-size: 12px; float: left; padding: 4px; border-radius: 4px; background: rgb(200, 200, 200); margin-right: 4px; margin-bottom: 4px;">{{ item }}</div>
                                </template>
                              </div>
                            </td>
                            <td class="floater_table__keyword logstep2" style="text-align: left; width: 160px"
                              v-bind:class="[
                                  isSelectedCell(item.id, 'implementationDate')
                                    ? 'cell--selected'
                                    : '',
                                  dragCandidate == item ? 'tr--dragcandidate' : '',
                                ]" v-on:click="selectDateCol($event, item, 1)">
                              <div style="display: flex">
                                <img v-if="true" width="14px" height="14px" src="@/assets/icon/date_range-24px.svg" style="
                                      margin-left: 0px;
                                      margin-right: 8px;
                                      margin-top: 8px;
                                      width: 14px;
                                      height: 14px;
                                    " />
                                <div style="margin-top: 4px">
                                  {{ item.implementationDate.endYmd.getYyyymmddString() }}
                                </div>
                              </div>
                            </td>
                            <td v-bind:class="[
                              isSelectedCell(item.id, 'adProvider') ? 'cell--selected' : '',
                              dragCandidate == item ? 'tr--dragcandidate' : '',
                            ]" class="floater_table__keyword logstep2" style="text-align: left" v-on:click="inputText(item.adProvider)">
                              <div style="position: relative">
                                <div style="position: absolute" v-if="item.adProvider.isEdit()">
                                  <EditMultiSelectItem :item="item.adProvider" />
                                </div>
                              </div>
                              <div class="txtitem" style="min-height: 10px">
                                {{ adproString(item.adProvider.value) }}
                              </div>
                            </td>
                            <td class="floater_table__keyword logstep2" style="text-align: left;" v-bind:class="[
                                isSelectedCell(item.id, 'purpose') && !item.purpose.isEdit()
                                  ? 'cell--selected'
                                  : '',
                                dragCandidate == item ? 'tr--dragcandidate' : '',
                              ]" v-on:click="inputTextCell(item, item.purpose)">
                              <div class="txtitem" v-if="!item.purpose.isEdit()">
                                {{ item.purpose.getString() }}
                              </div>
                              <EditItem3 mode="1" :item="item.purpose" :next="item.title" :prev="item.adProvider"
                                v-if="item.purpose.isEdit()" />
                            </td>
                            <td v-bind:class="[dragCandidate == item ? 'tr--dragcandidate' : '']"
                              class="floater_table__keyword"
                              style="text-align: left; vertical-align: top; position: relative">
                              <div style="height: 100%">
                                <div v-bind:class="[
                                    isSelectedCell(item.id, 'title') && !item.title.isEdit()
                                      ? 'cell--selected'
                                      : '',
                                  ]" v-on:click="inputTextCell(item, item.title)">
                                  <div class="txtitem txtitem_title" v-bind:class="[
                                      isSelectedCell(item.id, 'title') && !item.title.isEdit()
                                        ? 'cell--selected'
                                        : '',
                                    ]" v-if="!item.title.isEdit()"
                                    style="padding: 4px; border-radius: 4px; width: fit-content">
                                    {{
                                      item.title.getString().length > 0
                                      ? item.title.getString()
                                      : ' '
                                    }}
                                  </div>
                                  <EditItem3 mode="0" :item="item.title"
                                    :prev="item.purpose" :next="item.detail" v-if="item.title.isEdit()" />
                                </div>
                                <div v-bind:class="[
                                      isSelectedCell(item.id, 'detail') && !item.detail.isEdit()
                                        ? 'cell--selected'
                                        : '',
                                    ]" v-on:click="inputTextCell(item, item.detail)" style="height: calc(100% - 32px)">
                                  <div class="txtitem" v-if="!item.detail.isEdit()">
                                    {{ item.detail.getString() }}
                                  </div>
                                  <EditItem3 mode="1" :item="item.detail" :prev="item.title" :next="item.detailOfResult"
                                    v-if="item.detail.isEdit()" />
                                </div>
                              </div>
                            </td>
                            <td class="floater_table__keyword logstep2" style="text-align: left; width: 160px"
                              v-bind:class="[
                                  dragCandidate == item ? 'tr--dragcandidate' : '',
                                  isSelectedCell(item.id, 'validationDate') ? 'cell--selected' : '',
                                ]" v-on:click="selectDateCol($event, item, 2)">
                              <div style="display: flex">
                                <img v-if="true" width="14px" height="14px" src="@/assets/icon/date_range-24px.svg" style="
                                      margin-left: 0px;
                                      margin-right: 8px;
                                      margin-top: 8px;
                                      width: 14px;
                                      height: 14px;
                                    " />
                                <div style="margin-top: 4px">
                                  {{ item.validationDate.endYmd.getYyyymmddString() }}
                                </div>
                              </div>
                            </td>
                            <td v-bind:class="[
                              dragCandidate == item ? 'tr--dragcandidate' : '',
                              isSelectedCell(item.id, 'result') ? 'cell--selected' : '',
                            ]" class="floater_table__keyword logstep2" style="text-align: center" v-on:click="inputText(item.result)">
                              <div>
                                {{ item.result.getStatusTitle() }}
                              </div>
                              <div style="position: relative; width: 100%">
                                <div style="position: absolute; width: 100%" v-if="item.result.isEdit()">
                                  <EditCstSelectItem :item="item.result" />
                                </div>
                              </div>
                            </td>
                            <td class="floater_table__keyword logstep2" style="text-align: left" v-bind:class="[
                                dragCandidate == item ? 'tr--dragcandidate' : '',
                                isSelectedCell(item.id, 'detailOfResult') &&
                                  !item.detailOfResult.isEdit()
                                  ? 'cell--selected'
                                  : '',
                              ]" v-on:click="inputTextCell(item, item.detailOfResult)">
                              <div v-if="!item.detailOfResult.isEdit()" class="txtitem">
                                {{ item.detailOfResult.getString() }}
                              </div>
                              <EditItem3 mode="1" :item="item.detailOfResult" :prev="item.detail" :next="item.nextAction"
                                v-if="item.detailOfResult.isEdit()" />
                            </td>
                            <td class="floater_table__keyword logstep2" style="text-align: left" v-bind:class="[
                                dragCandidate == item ? 'tr--dragcandidate' : '',
                                isSelectedCell(item.id, 'nextAction') && !item.nextAction.isEdit()
                                  ? 'cell--selected'
                                  : '',
                              ]" v-on:click="inputTextCell(item, item.nextAction)">
                              <div v-if="!item.nextAction.isEdit()" class="txtitem">
                                {{ item.nextAction.getString() }}
                              </div>
                              <EditItem3 mode="1" :item="item.nextAction" :prev="item.detailOfResult"
                                v-if="item.nextAction.isEdit()" />
                            </td>
                            <td v-if="!isAdvertizer()" class="floater_table__keyword logstep2" style="text-align: left" v-bind:class="[
                                dragCandidate == item ? 'tr--dragcandidate' : '',
                                isSelectedCell(item.id, 'note') && !item.note.isEdit()
                                  ? 'cell--selected'
                                  : '',
                              ]" v-on:click="inputTextCell(item, item.note)">
                              <div v-if="!item.note.isEdit()" class="txtitem">
                                {{ item.note.getString() }}
                              </div>
                              <EditItem3 mode="1" :item="item.note" :prev="item.detailOfResult"
                                v-if="item.note.isEdit()" />
                            </td>
                            <td class="floater_table__keyword logstep2"
                              style="text-align: left; width: 120px; position: relative" v-show="isShowUpstreamFileList"
                              v-bind:class="[dragCandidate == item ? 'tr--dragcandidate' : '']">
                              <div v-on:click="selectFileUploder(item.id)" style="width: 100%; height: 100%">
                                <template v-for="(file, index) in item.files" :key="index">
                                  <div class="upfilebox">{{ file.getFileName() }}</div>
                                </template>
                              </div>
                              <div v-if="item.id == selectFileUploaderId">
                                <div v-on:click="releaseFileUploder()" style="
                                      position: fixed;
                                      top: 0;
                                      bottom: 0;
                                      right: 0;
                                      left: 0;
                                      z-index: 1;
                                    "></div>
                                <div style="position: absolute; top: 16px; z-index: 2">
                                  <div class="saupbox">
                                    <template v-for="(file, index) in item.files" :key="index">
                                      <div class="saupbox__line" v-on:click="openFileMenu(file.id)"
                                        style="position: relative">
                                        <div class="saupbox__line_i">
                                          <img class="saupbox__icon" style="width: 10px; height: 10px; margin-left: 4px"
                                            src="@/assets/icon/save_alt-24px.svg" />
                                        </div>
                                        <div class="saupbox__line_body">
                                          {{ file.getFileName() }}
                                        </div>
                                        <div class="saupbox__line_mark"></div>

                                        <div v-if="selectOpenFileMenuId == file.id">
                                          <div style="
                                                position: fixed;
                                                top: 0;
                                                bottom: 0;
                                                right: 0;
                                                left: 0;
                                                z-index: 3;
                                              " v-on:click.stop="releaseFileMenu()"></div>
                                          <div style="
                                                position: absolute;
                                                top: 10px;
                                                left: 0;
                                                z-index: 4;
                                              ">
                                            <div class="saupbox" style="width: 80px; margin-left: 40px">
                                              <div class="saupbox__line">
                                                <div class="saupbox__line_i">
                                                  <img class="saupbox__icon" style="
                                                        width: 10px;
                                                        height: 10px;
                                                        margin-left: 4px;
                                                      " src="@/assets/icon/save_alt-24px.svg" />
                                                </div>
                                                <div class="saupbox__line_body" v-on:click.stop="filedownload(file)">
                                                  download
                                                </div>
                                              </div>
                                              <div class="saupbox__line" v-if="!isAdvertizer()">
                                                <div class="saupbox__line_i">
                                                  <img class="saupbox__icon" style="
                                                        width: 10px;
                                                        height: 10px;
                                                        margin-left: 4px;
                                                      " src="@/assets/icon/save_alt-24px.svg" />
                                                </div>
                                                <div class="saupbox__line_body" v-on:click.stop="filedelete(file)">
                                                  delete
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                    <div class="saupbox__addline" style="border-top: 1px solid #ddd">
                                      <SelectAutoUploader class="saup1" :uploader="item.uploader" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="!page.isNetworkingUpstream && !isAdvertizer()">
                            <td class="markertd"></td>
                            <td class="yubi" style="padding: 20px; color: #39434eaa; border-right: initial"
                              v-on:click="addUpstream">
                              + New
                            </td>
                            <td style="border-left: initial; border-right: initial"></td>
                            <td style="border-left: initial; border-right: initial"></td>
                            <td style="border-left: initial; border-right: initial"></td>
                            <td style="border-left: initial; border-right: initial"></td>
                            <td style="border-left: initial; border-right: initial"></td>
                            <td style="border-left: initial; border-right: initial"></td>
                            <td style="border-left: initial; border-right: initial"></td>
                            <td style="border-left: initial; border-right: initial"></td>
                            <td style="border-left: initial; border-right: initial"></td>
                            <td style="border-left: initial"></td>
                            <td v-if="true"></td>
                          </tr>
                        </transition-group>
                      </table>
                      <div style="padding: 8px">
                        <Spinner v-if="page.isNetworkingUpstream == true" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="logfilter_content__background" v-on:click.stop="closeLogFilter" v-if="filter_xy.item != null"></div>
        <div class="logfilter_content" v-if="filter_xy.item != null"
          :style="{ top: `${filter_xy.y}px`, left: `${filter_xy.x}px` }">
          <div class="logfilter_items">
            <div class="logfilter_item" v-on:click="selectOrder(filter_xy.item, 0)" v-if="filter_xy.item != 20">
              <div class="logfilter_item__icon">
                <img width="16" height="16" src="@/assets/icon/arrow_upward_FILL0_wght400_GRAD0_opsz48.svg" />
              </div>
              昇順で並べ替え
            </div>
            <div class="logfilter_item" v-if="filter_xy.item == 20" style="color: #aaa;">
              <div class="logfilter_item__icon">
                <img width="16" height="16" src="@/assets/icon/arrow_upward_FILL0_wght400_GRAD0_opsz48.svg" />
              </div>
              昇順で並べ替え
            </div>
            <div class="logfilter_item" v-on:click="selectOrder(filter_xy.item, 1)" v-if="filter_xy.item != 20">
              <div class="logfilter_item__icon"><img width="16" height="16"
                  src="@/assets/icon/arrow_downward_FILL0_wght400_GRAD0_opsz48.svg" /></div>
              降順で並べ替え
            </div>
            <div class="logfilter_item" v-if="filter_xy.item == 20" style="color: #aaa;">
              <div class="logfilter_item__icon"><img width="16" height="16"
                  src="@/assets/icon/arrow_downward_FILL0_wght400_GRAD0_opsz48.svg" /></div>
              降順で並べ替え
            </div>
            <div class="logfilter_item" v-on:click="addFilter(filter_xy.item)">
              <div class="logfilter_item__icon">
                <img width="16" height="16" src="@/assets/icon/filter_list_FILL0_wght400_GRAD0_opsz48.svg" />
              </div>
              フィルター
            </div>
          </div>
        </div>
        <div class="logfilter_content__background" v-on:click.stop="closeSingleFilter"
          v-if="singlefilter_xy.item != null"></div>
        <div class="singlefilter_content" v-if="singlefilter_xy.item != null"
          :style="{ top: `${singlefilter_xy.y}px`, left: `${singlefilter_xy.x}px` }">
          <div class="filter_condition">
            <div class="filter_condition__andor" v-if="index == 0">条件
            </div>
            <div v-on:click="changeSingleTarge(singlefilter_xy.item)" class="filter_condition__target filter_condition__target--free">{{ singlefilter_xy.item.title }}
              <div class="filter_condition__target_ctrl">
                <img width="16" height="16" src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" v-if="true" />
              </div>
              <div class="filter_condition__change_targets_background" v-on:click.stop="closeChangeSingleTarge()" v-if="changeSingleTarget != null"></div>
              <div class="filter_condition__change_targets" v-if="changeSingleTarget != null">
                <template v-for="(fm, findex) in page.filtermaster" :key="findex">
                  <div class="filter_item" v-on:click.stop="changesinglefilter(fm.id)">{{ fm.title }}</div>
                </template>
              </div>
            </div>
            <div style="margin:auto; padding-left: 8px; padding-right: 8px;">が</div>
            <div class="filter_condition__value" v-if="singlefilter_xy.item.type == 0">
              <input type="text" style="width: 100%;margin-top: 4px;" v-model="singlefilter_xy.item.value" v-on:blur="onFilterChangeText" @keydown.enter="onFliterTextEnter" @compositionstart="composing=true" @compositionend="composing=false" />
            </div>
            <div class="filter_condition__value filter_condition__value--free" v-if="singlefilter_xy.item.type == 2" v-on:click.stop="startTimeRange(singlefilter_xy.item)">
              <div style="display: flex;padding-top: 4px;">
                <div style="position: relative;border-radius: 4px;border: 0.5px solid #DDDFE2;padding: 4px;width: 100px;">
                  <div style="padding-left: 20px;">{{ singlefilter_xy.item.value.start }}
                  </div>
                  <div style="position: absolute;top: 4px;"><img width="16" height="16" src="@/assets/icon/date_range-24px.svg" /></div>
                </div>
                <div style="padding-top: 4px; padding-left: 8px; padding-right: 8px;">から</div>
                <div style="position: relative;border-radius: 4px;border: 0.5px solid #DDDFE2;padding: 4px;width: 100px;">
                  <div style="padding-left: 20px;">{{ singlefilter_xy.item.value.end }}
                  </div>
                  <div style="position: absolute;top: 4px;"><img width="16" height="16" src="@/assets/icon/date_range-24px.svg" /></div>
                </div>
              </div>
            </div>
            <div class="filter_condition__value" v-if="singlefilter_xy.item.type == 1" v-on:click.stop="startSingleFilterConditionSelect()">
              <div class="filter_condition__target">{{ singlefilter_xy.item.value.title }}
                <div class="filter_condition__target_ctrl">
                  <img width="16" height="16"
                    src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                </div>
                <div class="filter_condition__candidateitems__background" v-if="isSingleFilterConditionCandidateitems" v-on:click.stop="closeSingleFilterConditionSelect()"></div>
                <div class="filter_condition__candidateitems" v-if="isSingleFilterConditionCandidateitems">
                  <template v-for="(vitem, index) in singlefilter_xy.item.valueCandidates" :key="index">
                    <div class="filter_item" v-on:click.stop="selectValue(singlefilter_xy.item, vitem.id)">{{ vitem.title }}</div>
                  </template>
                </div>
              </div>
            </div>
            <div class="filter_condition__order" v-if="singlefilter_xy.item.type != 2">
              <div class="filter_selected_item" v-on:click="startSingleFilterOrder()">{{ singlefilter_xy?.item?.getSelectedFilterItem()?.title }}
                <div class="filter_selected_item_ctrl">
                  <img width="16" height="16" src="@/assets/icon/keyboard_arrow_down_FILL0_wght400_GRAD0_opsz48.svg" />
                </div>
              </div>
              <div class="filter_condition__order__background" v-if="isSingleFilterOrders" v-on:click.stop="closeSingleFilterOrder()"></div>
              <div class="filter_condition_order__items" v-if="isSingleFilterOrders">
                <template v-for="(item, index) in singlefilter_xy.item.getFilterItems()" :key="index">
                  <div class="filter_item" v-on:click.stop="selectFilterItem(singlefilter_xy.item, item.id)"><div style="padding-left: 8px; padding-right: 8px;">{{ item.title }}</div></div>
                </template>
              </div>
            </div>
            <div class="filter_condition__ctrl" v-on:click.stop="singlefilterDeleteTarget(singlefilter_xy.item)">...
              <div class="filter_condition__ctrl_background"
                v-if="deleteTargetsingleFilterItem != null"
                v-on:click.stop="closeSingleDeletefilter()"></div>
              <div class="filter_condition_crlbox"
                v-if="deleteTargetsingleFilterItem != null">
                <div class="filter_condition_crlbox__item" v-on:click.stop="singlefilterdelete(singlefilter_xy.item)">削除</div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="dragmouse_layer" v-if="isDragMode">
    <div class="dragmouse_title" :style="{ top: dragTitle.y + 'px', left: dragTitle.x + 'px' }">
      {{ dragTitle.title }}
    </div>
  </div>
  <div class="customreport_rangecalendar" v-if="changeDateRangeItem != null">
    <div class="customreport_rangecalendar__background" v-on:click="onSelectTime()" v-if="changeDateRangeItem != null"></div>
    <CustomReportRangeCalendar style="pointer-events: all" class="customreport_rangecalendar__body" v-if="changeDateRangeItem != null"
      :crrc="crrc" />
  </div>

</template>

<style scoped>

.filter_condition__change_targets_background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: initial;  
}

.filter_condition__change_targets {
  position: absolute;
  top: 24px;
  left: 0;
  width: 160px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  z-index: 2;
  text-align: left;
  padding: 8px;
}

.filter_condition__andorlist__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: initial;
}

.filter_condition__andorlist {
  position: absolute;
  top: 24px;
  left: 0;
  width: 80px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  z-index: 2;
  text-align: left;
}

.filter_condition__andorlist_item {
  padding: 4px 8px;
  text-align: left;
}
.filter_condition__andorlist_item:hover {
  background: #d7dae2;
}


</style>

<style scoped>
.summary_filter_box__add {
  cursor: pointer;
}

.customreport_rangecalendar {
  position: absolute;
  top: 200px;
  left: 360px;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9;
}

.customreport_rangecalendar__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  z-index: 1;
}
.filter_condition__order__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.filter_condition__candidateitems_background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.customreport_rangecalendar__body {
  position: relative;
  z-index: 2;
}
</style>

<style scoped>
.filter_condition__ctrl {
  position: relative;
  width: 25px;
  text-align: right;
  margin-top: 4px;
  cursor: pointer;
}

.filter_condition__ctrl_background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.filter_condition_crlbox {
  position: absolute;
  left: 12px;
  top: 24px;
  padding: 8px;
  width: 80px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  z-index: 2;
  text-align: left;
}

.filter_condition_crlbox__item {
  padding-left: 8px;

}
.filter_condition_crlbox__item:hover {
  background: #DFE1E7;
}
</style>

<style scoped>
.logstep2 {
  vertical-align: top;
  text-align: left;
}
</style>

<style scoped>
.filter_condition__andor {
  position: relative;
  padding: 8px;
  width: 80px;
  text-align: right;
}

.filter_condition__andor--waku {
  border: 0.5px solid #DDDFE2;
  border-radius: 2px;
  text-align: left;
  cursor: pointer;
}


.summary_filter_box__ctrl_btn {
  margin-left: auto;
  width: fit-content;
  background: #E4E4E4;
  border-radius: 4px;
  padding: 4px;
}
</style>

<style scoped>
.filter_condition {
  padding-top: 4px;
  padding-bottom: 4px;
}

.filter_condition__target {
  position: relative;
  padding: 8px;
  border: 0.5px solid #DDDFE2;
  border-radius: 2px;
  padding-right: 32px;
  margin: auto;
  min-width: 160px;
  max-width: 160px;
  cursor: pointer;
  margin-left: 8px;
}

.filter_condition__target--free {
  max-width: initial;
}

.filter_condition__value {
  /* position: relative;
  padding: 8px;
  border: 0.5px solid #DDDFE2;
  border-radius: 2px;
  padding-right: 32px;
  margin: auto; */
  margin-left: 8px;
  margin-right: 8px;
  min-width: 160px;
  max-width: 160px;
}
.filter_condition__value--free {
  max-width: initial;
}

.filter_condition__target_ctrl {
  position: absolute;
  top: 9px;
  right: 8px;
}

.filter_selected_item_ctrl {
  position: absolute;
  top: 9px;
  right: 8px;
}

.filter_selected_item {
  position: relative;
  padding: 8px;
  border: 0.5px solid #DDDFE2;
  border-radius: 2px;
  padding-right: 32px;
  width: 120px;
  cursor: pointer;
}
</style>

<style scoped>
.logfilter_items {
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
}

.logfilter_item {
  padding: 4px;
  padding-left: 32px;
  padding-right: 16px;
  font-size: 13px;
  position: relative;
}

.logfilter_item:hover {
  background: #d7dae2;
}

.logfilter_item__icon {
  position: absolute;
  top: 6px;
  left: 12px;
}
</style>

<style scoped>
.logfilter_content__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.logfilter_content {
  position: absolute;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D7DAE2;
  border-radius: 3px;
}

.singlefilter_content {
  position: absolute;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D7DAE2;
  border-radius: 3px;
  font-size: 12px;
  padding: 16px;
}
</style>

<style scoped>
.floater_table_th {
  position: relative;
  padding: 4px;
}

.floater_table_header {
  overflow: hidden;
  padding: 16px;
}

.floater_table_header_wrapper {
  position: relative;
  overflow: hidden;
}

.floater_table__header_menu {
  position: absolute;
  top: 16px;
  color: #000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D7DAE2;
  border-radius: 3px;

}
</style>

<style scoped>
.logarea {
  margin-top: 16px;
}

.filterbox__filter_add {
  margin-left: 8px;
  cursor: pointer;
}
</style>

<style scoped>
.filterbox {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #39434E;
}

.summary_filter_box {
  position: absolute;
  padding: 16px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  z-index: 2;
  cursor: initial;
}

.sortbox_box_background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(255, 0,0,.5); */
  z-index: 0;
}

.summary_filter_box_background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: initial;
}

.summary_filter_box__conditions {
  z-index: 1;
}

.item_filter_box {
  position: absolute;
  padding: 16px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  width: 343px;
}

.filter_condition {
  display: flex;
}
</style>

<style scoped>
.filterbox__sort_btn {
  position: relative;
  padding: 4px;
  padding-left: 24px;
  padding-right: 27px;
  min-width: 97px;
  border: 0.5px solid var(--unnamed-color-39434e);
  border: 0.5px solid #39434E;
  border-radius: 2px;
  color: #39434E;
  margin-right: 16px;
  cursor: pointer;
}

.filterbox_summary_btn {
  position: relative;
  padding: 4px;
  padding-left: 24px;
  padding-right: 27px;
  min-width: 97px;
  border: 0.5px solid var(--unnamed-color-39434e);
  border: 0.5px solid #39434E;
  border-radius: 2px;
  color: #39434E;
  cursor: pointer;
}

.filterbox__filter_order {
  max-width: 1580px;
  overflow: auto;
  display: flex;
}

.filterbox__filter_order_btn {
  position: relative;
  padding: 4px;
  padding-left: 24px;
  padding-right: 27px;
  min-width: 150px;
  border: 0.5px solid var(--unnamed-color-39434e);
  border: 0.5px solid #39434E;
  border-radius: 2px;
  color: #39434E;
  margin-left: 8px;
  cursor: pointer;
}

.filterbox__sort_btn_icon {
  position: absolute;
  top: 6px;
  left: 4px;
}

.filterbox_summary_btn__icon {
  position: absolute;
  top: 5px;
  left: 5px;
}

.filterbox_filter_order_btn__icon {
  position: absolute;
  left: 6px;
}

.filterbox__sort_btn_ctrl {
  position: absolute;
  top: 6px;
  right: 4px;
}

.filterbox_summary_btn__ctrl {
  position: absolute;
  top: 6px;
  right: 4px;
}

.filterbox_filter_order_btn__ctrl {
  position: absolute;
  top: 6px;
  right: 4px;
}

.sortbox {
  position: absolute;
  left: -8px;
  top: 32px;
  padding: 16px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  z-index: 1;
}

.sort_condition {
  display: flex;
}

.sort_condition__target {
  position: relative;
  padding: 8px;
  border: 0.5px solid #DDDFE2;
  border-radius: 2px;
  padding-right: 32px;
  margin: auto;
  min-width: 170px;
}

.sort_condition__order {}

.sort_condition__ctrl {
  width: 25px;
  text-align: right;
  margin-top: 4px;
  position: relative;
}

.sort_condition__ctrl_items {
  position: absolute;
  left: 32px;
  top: 16px;
  padding: 8px;
  min-width: 80px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
}
.sort_condition__ctrl_item {
  padding: 4px 8px;
  text-align: left;
}
.sort_condition__ctrl_item:hover {
  background: #d7dae2;
}

.filter_item {
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
}
.filter_item:hover {
  background: #DFE1E7;
}

.filter_items {
  position: absolute;
  left: 32px;
  top: 16px;
  padding: 8px;
  width: 200px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
}

.filter_items--sortoffset {
  left: 112px;
}

.filter_sort_items {
  position: absolute;
  left: 0px;
  top: 24px;
  padding: 8px;
  width: 100px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  z-index: 2;
  width: 120px;
}

.filter_sort_items__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.filter_sort_item {
  padding: 4px 8px;
  text-align: left;
}
.filter_sort_item:hover {
  background: #d7dae2;
}

.sort_condition_candidates {
  position: absolute;
  left: 0px;
  top: 24px;
  padding: 8px;
  min-width: 170px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  z-index: 2;
}
.sort_condition_candidates__background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.sort_condition_candidate {
  padding: 4px 8px;
  text-align: left;
}
.sort_condition_candidate:hover {
  background: #d7dae2;
}


.filter_condition__order {
  position: relative;
  width: 120px;
  margin-left: 8px;
}

.filter_condition_order__items {
  position: absolute;
  left: 0px;
  top: 24px;
  padding: 8px;
  width: 120px;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  z-index: 2;
}

.filter_condition__candidateitems {
  position: absolute;
  left: 0px;
  top: 24px;
  padding: 8px;
  width: fit-content;
  border: 0.5px solid var(--unnamed-color-dfe1e7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #DFE1E7;
  border-radius: 2px;
  z-index: 2;
  min-width: 160px;
}

.filter_condition__candidateitems__background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
</style>

<style scoped>
.filterbox {
  display: flex;
}

.filterbox__filters {
  display: flex;
}
</style>

<style scoped>
.full_main_content {
  min-width: 1920px;
}

.actionlog_stattab {
  min-width: 1900px;
  background: white;
  margin-top: 20px
}

.yubi {
  cursor: pointer;
}
</style>

<style scoped>
.cmark {
  width: 16px;
}
</style>

<style scoped>
.markertd {
  background: #fff;
  border: 0px;
  width: 32px;
}

tr:hover .markertd .mark {
  display: block;
  /* opacity: 1;
  transition: 0.8s; */
}

tr .markertd .mark {
  display: none;
  /* opacity: 0; */
}
</style>

<style scoped>
.adx_log {
  min-width: 1920px;
}

.floater_table__body div .success_circle {
  height: 10px;
}

.floater_table__body div .active_circle {
  height: 10px;
}

.floater_table__body div .secondary_circle {
  height: 10px;
}

.floater_table {
  max-width: initial;
}
</style>

<style scoped>
/* tbd override */
.floater_table__keyword {
  height: 150px;
  padding: 8px;
  font: 14px / 20px 'Noto Sans JP';
}

.floater_table__body div {
  height: initial;
}
</style>

<style scoped>
.edititem {
  background: rgb(55, 102, 231);
}

.slide-fade-leave-active {
  transition: opacity 0.3s;
}

.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.btn_add_log {
  background: #0a8143 0% 0% no-repeat padding-box;
  border-radius: 2px;
  font: normal normal normal 12px/17px Source Sans Pro;
  letter-spacing: 1.44px;
  color: #ffffff;
  box-shadow: 0 0 2px 0 rgb(108 121 129);
  padding: 8px;
  display: initial;
}

.btn_add_log:hover {
  background: #086435 0% 0% no-repeat padding-box;
}

.txtitem {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.floater_table__body div.txtitem {
  /* max-height: 100px; */
  overflow: hidden;
}

.moving-box {
  height: 100px;
  width: 100px;
  transition: transform 0.5s ease;
  box-sizing: border-box;
  border: 1px solid black;
  background-color: #41b883;
}
</style>

<style scoped>
.txtitem_title {
  background: rgb(200, 200, 200);
}

.cell--selected {
  background: rgb(60, 170, 255, 0.3);
}
</style>

<style scoped>
.tr--dragcandidate {
  border-top: 8px solid rgba(52, 183, 254, 0.5);
}

.dragmouse_layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.dragmouse_title {
  position: absolute;
  padding: 8px;
  color: rgb(38, 128, 235);
  background: rgba(255, 255, 255, 0.8);
}

.dmark {
  width: 16px;
  height: 16px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('@/assets/icon/drag_indicator_black_24dp.svg');
}
</style>

<style scoped>
.upfilebox {
  width: 104px;
  padding: 2px 8px;
  font: normal normal normal 11px/13px Noto Sans JP;
  background: #d4d4d4;
  color: #39434e;
  border-radius: 4px;
  margin: 4px;
  white-space: initial;
  overflow-wrap: break-word;
  word-break: break-all;
}

.saup {
  display: none;
}

.saupbox {
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.35);
  background: #fff;
  width: 120px;
  padding: 8px 0px;
  border-radius: 2px;
}

.saupbox__line {
  display: flex;
  font: normal normal 300 11px/13px Noto Sans JP;
  padding: 4px 0;
}

.saupbox__line_i {
  width: 18px;
}

.saupbox__line_body {
  width: 86px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.saupbox__line_mark {
  width: 16px;
}

.saupbox__line:hover {
  background: #d7dae2;
}

.saupbox__icon {
  width: 10px;
  height: 10px;
  margin-left: 4px;
}

.saupbox__addline {
  font: normal normal 300 11px/13px Noto Sans JP;
}
</style>


<script>
// @ is an alias to /src
import Spinner from '@/components/layout/Spinner.vue';
import EditItem3 from '@/components/EditItem3.vue';
// import EditSelectItem from '@/components/EditSelectItem.vue';
import EditCstSelectItem from '@/components/EditCstSelectItem.vue';
import EditMultiSelectItem from '@/components/EditMultiSelectItem.vue';

import { SeamlessActionLogPage } from '@/js/page/log/SeamlessActionLogPage.js';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';
import { TimeYmdRangeSe } from '@/js/tacklebox/date/TimeYmdRangeSe.js';
import DateSelectCalendarSimple from '@/components/DateSelectCalendarSimple.vue';

import SelectAutoUploader from '@/components/SelectAutoUploader.vue';

import { CustomReportRangeCalendarJs } from '@/js/components/CustomReportRangeCalendarJs.js';
import { CustomReportRangeSelector } from '@/js/components/CustomReportRangeCalendarJs.js';

import CustomReportRangeCalendar from '@/components/CustomReportRangeCalendar.vue';
import EditCstMultiSelectItem from '@/components/EditCstMultiSelectItem.vue';



export default {
  name: 'SeamlessLog',
  data() {
    const rs = new CustomReportRangeSelector();
    return {
      name: '施策ログ',
      page: new SeamlessActionLogPage(),
      isShowRange: false,
      rangePositionX: 0,
      rangePositionY: 0,
      dateRangeSelector: new TimeYmdRangeSe(),
      uploadFile: null,
      isDragMode: false,
      dragCandidate: null,
      dragTitle: {
        x: 0,
        y: 0,
        title: 'test',
        idx: 0,
        targetIdx: 0,
        targetAdvId: 0,
        target: null,
      },
      longpressTimerId: null,
      isShowUpstreamFileList: true,
      isShowCommunicationFileList: true,
      isShowMicroFileList: true,
      selectFileUploaderId: null,
      selectOpenFileMenuId: null,
      isActive: true,
      isInitialized: false,
      filters: ["1"],
      sorts: ["1"],
      filter_xy: { x: 0, y: 0, item: null },
      singlefilter_xy: { x: 0, y: 0, item: null },
      deleteTargetSummaryFitlerItem: null,
      isShowSortControl: false,
      isShowSortorder: false,
      deleteTargetsingleFilterItem: null,
      crrc: new CustomReportRangeCalendarJs(rs, function () {
        //c
      }),
      changeDateRangeItem: null,
      isSingleFilterConditionCandidateitems: false,
      isSingleFilterOrders: false,
      isFilterConditionCandidateitems: false,
      filterConditionCandidateitems: null,
      filterOrders: null,
      isStartSortCandidates: false,
      isFilterAndOr: false,
      changeTarget: null,
      changeSingleTarget: null,
    };
  },
  emits: ['screenevent'],
  watch: {
  },
  mounted() {
    const self = this;
    this.$emit('screenevent', 'log');
    let p = this.adx.getScreenStructureById('log');
    if (p.getActivePages().length > 0) {
      this.initialize();
    } else {
      p.subject.addObserver(function () {
        self.initialize();
      });
    }
    document.addEventListener('keydown', this.onKeyEvent);
    document.addEventListener('keyup', this.onKeyUpEvent);
    document.addEventListener('click', this.onClickEvent);
    document.addEventListener('mouseleave', this.onMouseLeave);
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('mousemove', this.onMouseMove);

    this.crrc.selector.setYmdMode(0);
    this.crrc.selector.setRangeYmd(
        2023,
        1,
        1,
        2023,
        1,
        1,
      );
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.onKeyEvent);
    document.removeEventListener('keyup', this.onKeyUpEvent);
    document.removeEventListener('click', this.onClickEvent);
    document.removeEventListener('mouseleave', this.onMouseLeave);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mousemove', this.onMouseMove);
    this.isActive = false;
    this.adx.changeLastUpdate('');
  },
  methods: {
    changeTarge(target) {
      this.changeTarget = target;
    },
    closeChangeTarge() {
      this.changeTarget = null;
    },
    changeSingleTarge(target) {
      this.changeSingleTarget = target;
    },
    closeChangeSingleTarge() {
      this.changeSingleTarget = null;
    },
    selectAndOr(andor) {
      if (this.page) {
        this.page.filterandor = andor;
      }
      this.page.reloadLogDatas();
      this.closeFilterAndOr();
    },
    getAndOrText() {
      if (this.page?.filterandor == "and") {
        return "AND";
      }
      if (this.page?.filterandor == "or") {
        return "OR";
      }
      return "";
    },
    onFliterTextEnter(e) {
      if (e) {
        //
      }
      if (this.composing) {
        // 変換確定時
      } else {
        this.onFilterChangeText();
      }
    },
    onFilterChangeText() {
      this.page.reloadLogDatas();
    },
    startFilterAndOr(){
      this.isFilterAndOr = true;
    },
    closeFilterAndOr(){
      this.isFilterAndOr = false;
    },
    onStartAndOr(index) {
      if (index == 1) {
        this.startFilterAndOr();
      } 
    },
    startFilterOrder(item) {
      this.filterOrders = item;
    },
    closeFilterOrder() {
      this.filterOrders = null;
    },
    selectValue(item, id) {
      try{
        item.selectValue(id);
      }catch(ex){
        console.log(ex);
      }
      this.page.reloadLogDatas();
      this.closeSingleFilterConditionSelect();
      this.closeFilterConditionSelect();
    },
    startSortCandidates() {
      this.isStartSortCandidates = true;
    },
    closeSortCandidates() {
      this.isStartSortCandidates = false;
    },
    startFilterConditionSelect(item) {
      this.isFilterConditionCandidateitems = true;
      this.filterConditionCandidateitems = item;
    },
    closeFilterConditionSelect() {
      this.isFilterConditionCandidateitems = false;
      this.filterConditionCandidateitems = null;
    },
    startSingleFilterConditionSelect() {
      this.isSingleFilterConditionCandidateitems = true;
    },
    closeSingleFilterConditionSelect() {
      this.isSingleFilterConditionCandidateitems = false;
    },
    startSingleFilterOrder() {
      this.isSingleFilterOrders = true;
    },
    closeSingleFilterOrder() {
      this.isSingleFilterOrders = false;
    },
    startTimeRange(item) {
      let symd = item.value.start.split("-");
      let eymd = item.value.end.split("-");
      if (item) {
        this.crrc.selector.setRangeYmd(
          symd[0],
          symd[1],
          symd[2],
          eymd[0],
          eymd[1],
          eymd[2],
        );

        this.changeDateRangeItem = item;
      }
    },
    onSelectTime() {
      let startYmd = this.crrc.selector.range.startYmd.getYmd();
      let endYmd = this.crrc.selector.range.endYmd.getYmd();

      this.changeDateRangeItem.value.start = startYmd;
      this.changeDateRangeItem.value.end = endYmd;
      this.changeDateRangeItem = null;

      this.page.reloadLogDatas();
    },
    selectFilterItem(item, id) {
      item.selectFilterItem(id);
      this.page.reloadLogDatas();
      this.closeSingleFilterOrder();
      this.closeFilterOrder();
    },
    sortCandidates() {
      let fs = [];
      for (let i in this.page.filtermaster) {
        let f = this.page.filtermaster[i];
        fs.push({
          id: f.id,
          title: f.title
        });
      }
      return fs;
    },
    showSortorder() {
      this.isShowSortorder = true;
    },
    closeSortorder() {
      this.isShowSortorder = false;
    },
    showSortControl() {
      this.isShowSortControl = true;
    },
    summaryFilterDelete() {
      this.deleteTargetSummaryFitlerItem = null;
    },
    summaryfilterDeleteTarget(item) {
      this.deleteTargetSummaryFitlerItem = item;
    },
    singlefilterDeleteTarget(item) {
      this.deleteTargetsingleFilterItem = item;
    },
    closeSingleDeletefilter() {
      this.deleteTargetsingleFilterItem = null;
    },
    singlefilterdelete(item) {
      this.closeSingleFilter();
      this.closeSingleDeletefilter();
      this.page.removeFilter(item);
    },
    filterdelete(item) {
      this.summaryFilterDelete();
      this.page.removeFilter(item);
    },
    sortreset() {
      this.isShowSortControl = false;
      this.isShowSortorder = false;
      this.page.resetSort();
      this.page.closeSortFilter();
    },
    selectOrder(item, da) {
      switch (item) {
        case 20:
          break;
        case 110:
          break;
      }
      this.page.order(item, da);
      this.closeLogFilter();
      this.closeSortCandidates();
    },
    addFilter(item) {
      this.page.addFilter(item);
      this.closeLogFilter();
    },
    closeLogFilter() {
      this.filter_xy.item = null;
      this.deleteTargetSummaryFitlerItem = null;
    },
    singlefilter(item, evt) {
      this.filter_xy.x = evt.clientX;
      this.filter_xy.y = evt.clientY + 16;
      this.filter_xy.item = item;
    },
    changefilter(dst_id) {
      this.page.changefilter(this.changeTarget, dst_id);
      this.changeTarget = null;
    },
    changesinglefilter(dst_id) {
      let f = this.page.changefilter(this.changeSingleTarget, dst_id);
      this.changeSingleTarget = null;
      this.singlefilter_xy.item = f;
    },
    ttt(item, evt) {
      this.singlefilter_xy.x = evt.clientX;
      this.singlefilter_xy.y = evt.clientY + 16;
      this.singlefilter_xy.item = item;
    },
    closeSingleFilter() {
      this.singlefilter_xy.item = null;
    },
    ppp(param) {
      console.log(param)
    },
    newFilter() {
      this.page.addFilter(10);
      this.page.openSummaryFilter();
    },
    openSummaryFilter() {
      this.page.openSummaryFilter();
    },
    filterClear() {
      this.page.resetFilter();
    },
    closeSummaryFilter() {
      this.page.closeSummaryFilter();
    },
    closeSortFilter() {
      this.isShowSortControl = false;
      this.isShowSortorder = false;
      this.page.closeSortFilter();
    },
    addUpstream() {
      this.page.registActionLog();
    },
    isAdvertizer() {
      if (this.adx.user.nowRole() == 'advertizer') {
        return true;
      }
      return false;
    },
    filedownload(file) {
      let self = this;
      function _doDownload(url, filename) {
        var alink = document.createElement('a');
        alink.download = filename;
        alink.href = url;
        alink.click();
        return false;
      }
      _doDownload(file.getFileUrl(), file.getFileName());
      self.selectOpenFileMenuId = null;
    },
    filedelete(file) {
      let self = this;
      file.delete();
      self.selectOpenFileMenuId = null;
    },
    releaseFileMenu() {
      this.selectOpenFileMenuId = null;
    },
    openFileMenu(id) {
      this.selectOpenFileMenuId = id;
    },
    releaseFileUploder() {
      this.selectFileUploaderId = null;
    },
    selectFileUploder(id) {
      this.selectFileUploaderId = id;
    },
    longpressPress(item, idx) {
      if(item || idx) {
        //
      }
    },
    _longpressPress(item, idx) {
      let self = this;
      let cnt = 0;
      let lptid = setInterval(function () {
        if (lptid != self.longpressTimerId) {
          if (lptid) {
            clearInterval(lptid);
          }
          return;
        }
        cnt++;
        if (cnt > 2) {
          self.longressRelease();
          self.dragstart(item, idx);
        }
      }, 100);

      this.longpressTimerId = lptid;
    },
    longressRelease() {
      if (this.longpressTimerId) {
        try {
          clearInterval(this.longpressTimerId);
        } catch (ex) {
          console.log(ex);
        }
      }
      this.longpressTimerId = null;
    },
    dragstart(item, idx) {
      if (item.status.value == 'done') {
        return;
      }
      console.log(item);
      this.dragCandidate = item;
      this.isDragMode = true;
      this.dragTitle.title =
        '開始日：' +
        item.implementationDate.startYmd.getYyyymmddString() +
        '　施策タイトル：' +
        item.title.value;
      this.dragTitle.idx = idx;
      this.dragTitle.targetIdx = idx;
      this.dragTitle.targetAdvId = item.advertizerid;
      this.dragTitle.target = item;
    },
    dragmouseover(item, idx) {
      if (this.isDragMode) {
        if (this.dragTitle.target) {
          if (this.dragTitle?.target?.type != item?.type) {
            return;
          }
        }
        this.dragCandidate = item;
        this.dragTitle.targetIdx = idx;
      }
    },
    dragmouseleave(item) {
      if (this.dragCandidate == item) {
        this.dragCandidate = null;
      }
    },
    onMouseLeave() {
      this.isDragMode = false;
      this.dragCandidate = null;
    },
    onMouseUp() {
      this.longressRelease();
      if (this.dragCandidate) {
        if (this.isDragMode) {
          switch (this.dragTitle?.target?.type) {
            case 1:
              this.page.sortUpstream(this.dragCandidate, this.dragTitle.target);
              break;
            case 2:
              this.page.sortCommunication(this.dragCandidate, this.dragTitle.target);
              break;
            case 3:
              this.page.sortMicro(this.dragCandidate, this.dragTitle.target);
              break;
          }
        }
      }
      this.dragCandidate = null;
      this.isDragMode = false;
    },
    onMouseMove(e) {
      this.dragTitle.x = e.clientX;
      this.dragTitle.y = e.clientY;
    },
    onClickEvent(e) {
      if (e) {
        //
      }
      if (this.page.selectedCells.length > 0) {
        this.page.resetSelectCell();
      }
    },
    onKeyEvent(e) {
      if (e) {
        //
      }
      if (e.key == 'ArrowDown') {
        this.page.keydown();
      }
      if (e.key == 'ArrowUp') {
        this.page.keyup();
      }
      if (e.key == 'ArrowLeft') {
        if (e.shiftKey) {
          this.page.keyleftShift();
        } else {
          this.page.keyleft();
        }
      }
      if (e.key == 'ArrowRight') {
        if (e.shiftKey) {
          this.page.keyrightShift();
        } else {
          this.page.keyright();
        }
      }
      if (e.key == 'C' || e.key == 'c') {
        if (e.ctrlKey) {
          this.page.copyClipboradSelectedCell();
        }
      }
    },
    onKeyUpEvent(e) {
      if (e.key == 'Enter') {
        if (this.page.selectedCells.length > 0) {
          this.page.keyEntry(this.page.selectedCells[0].id, this.page.selectedCells[0].key);
        }
      }
    },
    isSelectedCell(id, key) {
      for (let i in this.page.selectedCells) {
        if (this.page.selectedCells[i].id == id && this.page.selectedCells[i].key == key) {
          return true;
        }
      }

      return false;
    },
    adproString(str) {
      if (str) {
        if (str.length > 0) {
          return str.replaceAll(',', '\n');
        }
      }
      return '-';
    },
    addActionLog() {
      this.page.registActionLog();
    },
    inputText(item) {
      item.edit();
    },
    inputTextCell(item, itemcell) {
      this.page.selectedCells = [];
      this.page.saveKeyEnterCell(item.id, itemcell.id);

      itemcell.edit();
    },
    selectDateCol(e, i, mode = 1) {
      if (this.isAdvertizer()) {
        return;
      }

      switch (mode) {
        case 1:
          this.dateRangeSelector.setRangeYmd(
            i.implementationDate.startYmd.year,
            i.implementationDate.startYmd.month,
            i.implementationDate.startYmd.day,
            i.implementationDate.endYmd.year,
            i.implementationDate.endYmd.month,
            i.implementationDate.endYmd.day
          );
          break;
        case 2:
          this.dateRangeSelector.setRangeYmd(
            i.validationDate.startYmd.year,
            i.validationDate.startYmd.month,
            i.validationDate.startYmd.day,
            i.validationDate.endYmd.year,
            i.validationDate.endYmd.month,
            i.validationDate.endYmd.day
          );
          break;
      }
      this.dateRangeSelector.id = i.id;
      this.dateRangeSelector.type = i.type;
      this.dateRangeSelector.mode = mode;
      this.rangePositionY = e.layerY;
      this.rangePositionX = e.layerX;
      this.isShowRange = true;
      if (
        window.innerHeight - e.clientY < 300 &&
        document.documentElement.scrollHeight - e.pageY < 300
      ) {
        setTimeout(function () {
          window.scrollBy(0, 300);
        }, 100);
      }
    },
    startYmd() {
      let time = new TimeYmd(
        this.dateRangeSelector.endYmd.year,
        this.dateRangeSelector.endYmd.month,
        this.dateRangeSelector.endYmd.day
      );
      //time.decMonth();
      return time;
    },
    getTodayYmd() {
      let time = this.adx.getNowTime();
      return new TimeYmd(time.ymd.year, time.ymd.month, time.ymd.day);
    },
    rangecalendarevent: function (event, value) {
      if (event || value) {
        //
      }

      this.page.updateDate(this.dateRangeSelector.id, this.dateRangeSelector);

      this.dateRangeSelector.mode = 0;
      this.dateRangeSelector.id = 0;
      this.dateRangeSelector.type = 0;
      this.isShowRange = false;
    },
    initialize() {
      const self = this;

      if (this.isInitialized == true) {
        return;
      }

      this.page.initialize(
        self.adx.getScreenStructureById('log').advertizerId,
        self.getTodayYmd(),
        self.adx.user
      );
      this.page.updateload(function (date) {
        if (self.isActive) {
          self.adx.changeLastUpdate(date);
        }
      });
      this.page.updatecstrp(function (isExist) {
        if (self.isActive) {
          if (isExist) {
            self.adx.changeCstCustomReport(true);
          }
        }
      });
    }
  },
  components: {
    Spinner,
    EditItem3,
    EditCstSelectItem,
    DateSelectCalendarSimple,
    EditMultiSelectItem,
    SelectAutoUploader,
    CustomReportRangeCalendar,
    EditCstMultiSelectItem,
  },
  props: {
    adx: Object,
  },
};
</script>