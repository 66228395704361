/**
 * @fileoverview gRPC-Web generated client stub for adx.custom_report
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.custom_report = require('./custom_report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.custom_report.CustomReportClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.custom_report.CustomReportPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.CopyCustomReportPageRequest,
 *   !proto.adx.custom_report.CopyCustomReportPageResponse>}
 */
const methodDescriptor_CustomReport_CopyCustomReportPage = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/CopyCustomReportPage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.CopyCustomReportPageRequest,
  proto.adx.custom_report.CopyCustomReportPageResponse,
  /**
   * @param {!proto.adx.custom_report.CopyCustomReportPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.CopyCustomReportPageResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.CopyCustomReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.CopyCustomReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.copyCustomReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/CopyCustomReportPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_CopyCustomReportPage);
};


/**
 * @param {!proto.adx.custom_report.CopyCustomReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.CopyCustomReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.copyCustomReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/CopyCustomReportPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_CopyCustomReportPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.CreateCustomReportPageRequest,
 *   !proto.adx.custom_report.CreateCustomReportPageResponse>}
 */
const methodDescriptor_CustomReport_CreateCustomReportPage = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/CreateCustomReportPage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.CreateCustomReportPageRequest,
  proto.adx.custom_report.CreateCustomReportPageResponse,
  /**
   * @param {!proto.adx.custom_report.CreateCustomReportPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.CreateCustomReportPageResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.CreateCustomReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.CreateCustomReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.createCustomReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/CreateCustomReportPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_CreateCustomReportPage);
};


/**
 * @param {!proto.adx.custom_report.CreateCustomReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.CreateCustomReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.createCustomReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/CreateCustomReportPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_CreateCustomReportPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.CreateCustomReportRequest,
 *   !proto.adx.custom_report.CreateCustomReportResponse>}
 */
const methodDescriptor_CustomReport_CreateCustomReport = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/CreateCustomReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.CreateCustomReportRequest,
  proto.adx.custom_report.CreateCustomReportResponse,
  /**
   * @param {!proto.adx.custom_report.CreateCustomReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.CreateCustomReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.CreateCustomReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.CreateCustomReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.createCustomReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/CreateCustomReport',
      request,
      metadata || {},
      methodDescriptor_CustomReport_CreateCustomReport);
};


/**
 * @param {!proto.adx.custom_report.CreateCustomReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.CreateCustomReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.createCustomReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/CreateCustomReport',
      request,
      metadata || {},
      methodDescriptor_CustomReport_CreateCustomReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.DeleteCustomReportPageRequest,
 *   !proto.adx.custom_report.DeleteCustomReportPageResponse>}
 */
const methodDescriptor_CustomReport_DeleteCustomReportPage = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/DeleteCustomReportPage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.DeleteCustomReportPageRequest,
  proto.adx.custom_report.DeleteCustomReportPageResponse,
  /**
   * @param {!proto.adx.custom_report.DeleteCustomReportPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.DeleteCustomReportPageResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.DeleteCustomReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.DeleteCustomReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.deleteCustomReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/DeleteCustomReportPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_DeleteCustomReportPage);
};


/**
 * @param {!proto.adx.custom_report.DeleteCustomReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.DeleteCustomReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.deleteCustomReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/DeleteCustomReportPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_DeleteCustomReportPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.DeleteCustomReportRequest,
 *   !proto.adx.custom_report.DeleteCustomReportResponse>}
 */
const methodDescriptor_CustomReport_DeleteCustomReport = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/DeleteCustomReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.DeleteCustomReportRequest,
  proto.adx.custom_report.DeleteCustomReportResponse,
  /**
   * @param {!proto.adx.custom_report.DeleteCustomReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.DeleteCustomReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.DeleteCustomReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.DeleteCustomReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.deleteCustomReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/DeleteCustomReport',
      request,
      metadata || {},
      methodDescriptor_CustomReport_DeleteCustomReport);
};


/**
 * @param {!proto.adx.custom_report.DeleteCustomReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.DeleteCustomReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.deleteCustomReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/DeleteCustomReport',
      request,
      metadata || {},
      methodDescriptor_CustomReport_DeleteCustomReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.GetCustomAvailableKeysRequest,
 *   !proto.adx.custom_report.GetCustomAvailableKeysResponse>}
 */
const methodDescriptor_CustomReport_GetCustomAvailableKeys = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/GetCustomAvailableKeys',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.GetCustomAvailableKeysRequest,
  proto.adx.custom_report.GetCustomAvailableKeysResponse,
  /**
   * @param {!proto.adx.custom_report.GetCustomAvailableKeysRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.GetCustomAvailableKeysResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetCustomAvailableKeysResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.getCustomAvailableKeys =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetCustomAvailableKeys',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetCustomAvailableKeys);
};


/**
 * @param {!proto.adx.custom_report.GetCustomAvailableKeysRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetCustomAvailableKeysResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.getCustomAvailableKeys =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetCustomAvailableKeys',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetCustomAvailableKeys);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.GetCustomReportPagesRequest,
 *   !proto.adx.custom_report.GetCustomReportPagesResponse>}
 */
const methodDescriptor_CustomReport_GetCustomReportPages = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/GetCustomReportPages',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.GetCustomReportPagesRequest,
  proto.adx.custom_report.GetCustomReportPagesResponse,
  /**
   * @param {!proto.adx.custom_report.GetCustomReportPagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.GetCustomReportPagesResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.GetCustomReportPagesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetCustomReportPagesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.getCustomReportPages =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetCustomReportPages',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetCustomReportPages);
};


/**
 * @param {!proto.adx.custom_report.GetCustomReportPagesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetCustomReportPagesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.getCustomReportPages =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetCustomReportPages',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetCustomReportPages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.GetCustomReportsRequest,
 *   !proto.adx.custom_report.GetCustomReportsResponse>}
 */
const methodDescriptor_CustomReport_GetCustomReports = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/GetCustomReports',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.GetCustomReportsRequest,
  proto.adx.custom_report.GetCustomReportsResponse,
  /**
   * @param {!proto.adx.custom_report.GetCustomReportsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.GetCustomReportsResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.GetCustomReportsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetCustomReportsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.getCustomReports =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetCustomReports',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetCustomReports);
};


/**
 * @param {!proto.adx.custom_report.GetCustomReportsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetCustomReportsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.getCustomReports =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetCustomReports',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetCustomReports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.GetSegmentNameRequest,
 *   !proto.adx.custom_report.GetSegmentNameResponse>}
 */
const methodDescriptor_CustomReport_GetSegmentName = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/GetSegmentName',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.GetSegmentNameRequest,
  proto.adx.custom_report.GetSegmentNameResponse,
  /**
   * @param {!proto.adx.custom_report.GetSegmentNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.GetSegmentNameResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.GetSegmentNameRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetSegmentNameResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.getSegmentName =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetSegmentName',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetSegmentName);
};


/**
 * @param {!proto.adx.custom_report.GetSegmentNameRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetSegmentNameResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.getSegmentName =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetSegmentName',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetSegmentName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.GetSegmentValueRequest,
 *   !proto.adx.custom_report.GetSegmentValueResponse>}
 */
const methodDescriptor_CustomReport_GetSegmentValue = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/GetSegmentValue',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.GetSegmentValueRequest,
  proto.adx.custom_report.GetSegmentValueResponse,
  /**
   * @param {!proto.adx.custom_report.GetSegmentValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.GetSegmentValueResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.GetSegmentValueRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetSegmentValueResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.getSegmentValue =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetSegmentValue',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetSegmentValue);
};


/**
 * @param {!proto.adx.custom_report.GetSegmentValueRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.GetSegmentValueResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.getSegmentValue =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/GetSegmentValue',
      request,
      metadata || {},
      methodDescriptor_CustomReport_GetSegmentValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.RelateCustomReportWithPageRequest,
 *   !proto.adx.custom_report.RelateCustomReportWithPageResponse>}
 */
const methodDescriptor_CustomReport_RelateCustomReportWithPage = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/RelateCustomReportWithPage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.RelateCustomReportWithPageRequest,
  proto.adx.custom_report.RelateCustomReportWithPageResponse,
  /**
   * @param {!proto.adx.custom_report.RelateCustomReportWithPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.RelateCustomReportWithPageResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.RelateCustomReportWithPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.relateCustomReportWithPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/RelateCustomReportWithPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_RelateCustomReportWithPage);
};


/**
 * @param {!proto.adx.custom_report.RelateCustomReportWithPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.RelateCustomReportWithPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.relateCustomReportWithPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/RelateCustomReportWithPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_RelateCustomReportWithPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.ReorderCustomReportPagesRequest,
 *   !proto.adx.custom_report.ReorderCustomReportPagesResponse>}
 */
const methodDescriptor_CustomReport_ReorderCustomReportPages = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/ReorderCustomReportPages',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.ReorderCustomReportPagesRequest,
  proto.adx.custom_report.ReorderCustomReportPagesResponse,
  /**
   * @param {!proto.adx.custom_report.ReorderCustomReportPagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.ReorderCustomReportPagesResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.ReorderCustomReportPagesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.reorderCustomReportPages =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/ReorderCustomReportPages',
      request,
      metadata || {},
      methodDescriptor_CustomReport_ReorderCustomReportPages);
};


/**
 * @param {!proto.adx.custom_report.ReorderCustomReportPagesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.ReorderCustomReportPagesResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.reorderCustomReportPages =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/ReorderCustomReportPages',
      request,
      metadata || {},
      methodDescriptor_CustomReport_ReorderCustomReportPages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.ReorderCustomReportsRequest,
 *   !proto.adx.custom_report.ReorderCustomReportsResponse>}
 */
const methodDescriptor_CustomReport_ReorderCustomReports = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/ReorderCustomReports',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.ReorderCustomReportsRequest,
  proto.adx.custom_report.ReorderCustomReportsResponse,
  /**
   * @param {!proto.adx.custom_report.ReorderCustomReportsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.ReorderCustomReportsResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.ReorderCustomReportsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.ReorderCustomReportsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.reorderCustomReports =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/ReorderCustomReports',
      request,
      metadata || {},
      methodDescriptor_CustomReport_ReorderCustomReports);
};


/**
 * @param {!proto.adx.custom_report.ReorderCustomReportsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.ReorderCustomReportsResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.reorderCustomReports =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/ReorderCustomReports',
      request,
      metadata || {},
      methodDescriptor_CustomReport_ReorderCustomReports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.UpdateCustomReportPageRequest,
 *   !proto.adx.custom_report.UpdateCustomReportPageResponse>}
 */
const methodDescriptor_CustomReport_UpdateCustomReportPage = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/UpdateCustomReportPage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.UpdateCustomReportPageRequest,
  proto.adx.custom_report.UpdateCustomReportPageResponse,
  /**
   * @param {!proto.adx.custom_report.UpdateCustomReportPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.UpdateCustomReportPageResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.UpdateCustomReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.UpdateCustomReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.updateCustomReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/UpdateCustomReportPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_UpdateCustomReportPage);
};


/**
 * @param {!proto.adx.custom_report.UpdateCustomReportPageRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.UpdateCustomReportPageResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.updateCustomReportPage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/UpdateCustomReportPage',
      request,
      metadata || {},
      methodDescriptor_CustomReport_UpdateCustomReportPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.custom_report.UpdateCustomReportRequest,
 *   !proto.adx.custom_report.UpdateCustomReportResponse>}
 */
const methodDescriptor_CustomReport_UpdateCustomReport = new grpc.web.MethodDescriptor(
  '/adx.custom_report.CustomReport/UpdateCustomReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.custom_report.UpdateCustomReportRequest,
  proto.adx.custom_report.UpdateCustomReportResponse,
  /**
   * @param {!proto.adx.custom_report.UpdateCustomReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.custom_report.UpdateCustomReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.custom_report.UpdateCustomReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.UpdateCustomReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportClient.prototype.updateCustomReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/UpdateCustomReport',
      request,
      metadata || {},
      methodDescriptor_CustomReport_UpdateCustomReport);
};


/**
 * @param {!proto.adx.custom_report.UpdateCustomReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.custom_report.UpdateCustomReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.custom_report.CustomReportPromiseClient.prototype.updateCustomReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.custom_report.CustomReport/UpdateCustomReport',
      request,
      metadata || {},
      methodDescriptor_CustomReport_UpdateCustomReport);
};


module.exports = proto.adx.custom_report;

