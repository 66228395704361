<template>
  <div>
    <svg :viewBox="vbox" xmlns="http://www.w3.org/2000/svg">
      <line
        :x1="stroke"
        :y1="stroke"
        :x2="100 - stroke"
        :y2="stroke"
        v-bind:stroke-width="stroke"
        stroke-linecap="round"
        stroke="#E6ECF0"
      />
      <line
        id="bar"
        class="bar"
        :x1="stroke"
        :y1="stroke"
        :x2="100 - stroke"
        :y2="stroke"
        v-bind:stroke-width="stroke"
        stroke-linecap="round"
        stroke-dasharray="100"
        v-bind:stroke-dashoffset="100 - value"
        v-bind:stroke="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Summary',
  data() {
    return {
      name: 'サマリ',
      color: '#FF0000',
      barp: 100,
      vbox: '0 0 100 ' + this.stroke * 2,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
  components: {},
  props: {
    value: Number,
    stroke: {
      type: Number,
      default: 1.5,
    },
  },
};
</script>

