// source: sprint.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.adx.sprint.ActionLog', null, global);
goog.exportSymbol('proto.adx.sprint.CreateCustomFieldRequest', null, global);
goog.exportSymbol('proto.adx.sprint.CreateCustomFieldResponse', null, global);
goog.exportSymbol('proto.adx.sprint.CreateCustomFieldResponse.Result', null, global);
goog.exportSymbol('proto.adx.sprint.CreateCustomFieldResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.sprint.CreateSprintRequest', null, global);
goog.exportSymbol('proto.adx.sprint.CreateSprintResponse', null, global);
goog.exportSymbol('proto.adx.sprint.CreateSprintResponse.Result', null, global);
goog.exportSymbol('proto.adx.sprint.CreateSprintResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.sprint.CustomField', null, global);
goog.exportSymbol('proto.adx.sprint.CustomFieldStruct', null, global);
goog.exportSymbol('proto.adx.sprint.DeleteCustomFieldRequest', null, global);
goog.exportSymbol('proto.adx.sprint.DeleteCustomFieldResponse', null, global);
goog.exportSymbol('proto.adx.sprint.DeleteCustomFieldResponse.Field', null, global);
goog.exportSymbol('proto.adx.sprint.DeleteCustomFieldResponse.Result', null, global);
goog.exportSymbol('proto.adx.sprint.DeleteCustomFieldResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.sprint.DeleteSprintRequest', null, global);
goog.exportSymbol('proto.adx.sprint.DeleteSprintResponse', null, global);
goog.exportSymbol('proto.adx.sprint.DeleteSprintResponse.Result', null, global);
goog.exportSymbol('proto.adx.sprint.DeleteSprintResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.sprint.GenerateNewSprintRequest', null, global);
goog.exportSymbol('proto.adx.sprint.GenerateNewSprintResponse', null, global);
goog.exportSymbol('proto.adx.sprint.GenerateNewSprintResponse.Result', null, global);
goog.exportSymbol('proto.adx.sprint.GenerateNewSprintResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.sprint.GetAdvertizerSprintsRequest', null, global);
goog.exportSymbol('proto.adx.sprint.GetAdvertizerSprintsResponse', null, global);
goog.exportSymbol('proto.adx.sprint.GetAdvertizerSprintsResponse.Result', null, global);
goog.exportSymbol('proto.adx.sprint.GetAdvertizerSprintsResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.sprint.GetConsultantSprintsRequest', null, global);
goog.exportSymbol('proto.adx.sprint.GetConsultantSprintsResponse', null, global);
goog.exportSymbol('proto.adx.sprint.GetConsultantSprintsResponse.Result', null, global);
goog.exportSymbol('proto.adx.sprint.GetConsultantSprintsResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.sprint.GetCustomFieldsRequest', null, global);
goog.exportSymbol('proto.adx.sprint.GetCustomFieldsResponse', null, global);
goog.exportSymbol('proto.adx.sprint.GetCustomFieldsResponse.Result', null, global);
goog.exportSymbol('proto.adx.sprint.GetCustomFieldsResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.sprint.MonthlyStatus', null, global);
goog.exportSymbol('proto.adx.sprint.Sprint', null, global);
goog.exportSymbol('proto.adx.sprint.UpdateCustomFieldRequest', null, global);
goog.exportSymbol('proto.adx.sprint.UpdateCustomFieldResponse', null, global);
goog.exportSymbol('proto.adx.sprint.UpdateCustomFieldResponse.Result', null, global);
goog.exportSymbol('proto.adx.sprint.UpdateCustomFieldResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.MonthlyStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.MonthlyStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.MonthlyStatus.displayName = 'proto.adx.sprint.MonthlyStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.ActionLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.ActionLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.ActionLog.displayName = 'proto.adx.sprint.ActionLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.CustomField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.CustomField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.CustomField.displayName = 'proto.adx.sprint.CustomField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.CustomFieldStruct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.CustomFieldStruct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.CustomFieldStruct.displayName = 'proto.adx.sprint.CustomFieldStruct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.Sprint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.sprint.Sprint.repeatedFields_, null);
};
goog.inherits(proto.adx.sprint.Sprint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.Sprint.displayName = 'proto.adx.sprint.Sprint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GenerateNewSprintRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.GenerateNewSprintRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GenerateNewSprintRequest.displayName = 'proto.adx.sprint.GenerateNewSprintRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GenerateNewSprintResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.sprint.GenerateNewSprintResponse.oneofGroups_);
};
goog.inherits(proto.adx.sprint.GenerateNewSprintResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GenerateNewSprintResponse.displayName = 'proto.adx.sprint.GenerateNewSprintResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GenerateNewSprintResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.GenerateNewSprintResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GenerateNewSprintResponse.Result.displayName = 'proto.adx.sprint.GenerateNewSprintResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.CreateSprintRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.CreateSprintRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.CreateSprintRequest.displayName = 'proto.adx.sprint.CreateSprintRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.CreateSprintResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.sprint.CreateSprintResponse.oneofGroups_);
};
goog.inherits(proto.adx.sprint.CreateSprintResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.CreateSprintResponse.displayName = 'proto.adx.sprint.CreateSprintResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.CreateSprintResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.CreateSprintResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.CreateSprintResponse.Result.displayName = 'proto.adx.sprint.CreateSprintResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.DeleteSprintRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.DeleteSprintRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.DeleteSprintRequest.displayName = 'proto.adx.sprint.DeleteSprintRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.DeleteSprintResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.sprint.DeleteSprintResponse.oneofGroups_);
};
goog.inherits(proto.adx.sprint.DeleteSprintResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.DeleteSprintResponse.displayName = 'proto.adx.sprint.DeleteSprintResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.DeleteSprintResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.DeleteSprintResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.DeleteSprintResponse.Result.displayName = 'proto.adx.sprint.DeleteSprintResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GetConsultantSprintsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.GetConsultantSprintsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GetConsultantSprintsRequest.displayName = 'proto.adx.sprint.GetConsultantSprintsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GetConsultantSprintsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.sprint.GetConsultantSprintsResponse.oneofGroups_);
};
goog.inherits(proto.adx.sprint.GetConsultantSprintsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GetConsultantSprintsResponse.displayName = 'proto.adx.sprint.GetConsultantSprintsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.sprint.GetConsultantSprintsResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.sprint.GetConsultantSprintsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GetConsultantSprintsResponse.Result.displayName = 'proto.adx.sprint.GetConsultantSprintsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GetAdvertizerSprintsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.GetAdvertizerSprintsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GetAdvertizerSprintsRequest.displayName = 'proto.adx.sprint.GetAdvertizerSprintsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GetAdvertizerSprintsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.sprint.GetAdvertizerSprintsResponse.oneofGroups_);
};
goog.inherits(proto.adx.sprint.GetAdvertizerSprintsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GetAdvertizerSprintsResponse.displayName = 'proto.adx.sprint.GetAdvertizerSprintsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.sprint.GetAdvertizerSprintsResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.sprint.GetAdvertizerSprintsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GetAdvertizerSprintsResponse.Result.displayName = 'proto.adx.sprint.GetAdvertizerSprintsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.CreateCustomFieldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.CreateCustomFieldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.CreateCustomFieldRequest.displayName = 'proto.adx.sprint.CreateCustomFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.CreateCustomFieldResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.sprint.CreateCustomFieldResponse.oneofGroups_);
};
goog.inherits(proto.adx.sprint.CreateCustomFieldResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.CreateCustomFieldResponse.displayName = 'proto.adx.sprint.CreateCustomFieldResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.CreateCustomFieldResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.CreateCustomFieldResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.CreateCustomFieldResponse.Result.displayName = 'proto.adx.sprint.CreateCustomFieldResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.UpdateCustomFieldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.UpdateCustomFieldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.UpdateCustomFieldRequest.displayName = 'proto.adx.sprint.UpdateCustomFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.UpdateCustomFieldResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.sprint.UpdateCustomFieldResponse.oneofGroups_);
};
goog.inherits(proto.adx.sprint.UpdateCustomFieldResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.UpdateCustomFieldResponse.displayName = 'proto.adx.sprint.UpdateCustomFieldResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.UpdateCustomFieldResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.UpdateCustomFieldResponse.Result.displayName = 'proto.adx.sprint.UpdateCustomFieldResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.DeleteCustomFieldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.DeleteCustomFieldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.DeleteCustomFieldRequest.displayName = 'proto.adx.sprint.DeleteCustomFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.DeleteCustomFieldResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.sprint.DeleteCustomFieldResponse.oneofGroups_);
};
goog.inherits(proto.adx.sprint.DeleteCustomFieldResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.DeleteCustomFieldResponse.displayName = 'proto.adx.sprint.DeleteCustomFieldResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.DeleteCustomFieldResponse.Field = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.DeleteCustomFieldResponse.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.DeleteCustomFieldResponse.Field.displayName = 'proto.adx.sprint.DeleteCustomFieldResponse.Field';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.DeleteCustomFieldResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.DeleteCustomFieldResponse.Result.displayName = 'proto.adx.sprint.DeleteCustomFieldResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GetCustomFieldsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.sprint.GetCustomFieldsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GetCustomFieldsRequest.displayName = 'proto.adx.sprint.GetCustomFieldsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GetCustomFieldsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.sprint.GetCustomFieldsResponse.oneofGroups_);
};
goog.inherits(proto.adx.sprint.GetCustomFieldsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GetCustomFieldsResponse.displayName = 'proto.adx.sprint.GetCustomFieldsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.sprint.GetCustomFieldsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.sprint.GetCustomFieldsResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.sprint.GetCustomFieldsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.sprint.GetCustomFieldsResponse.Result.displayName = 'proto.adx.sprint.GetCustomFieldsResponse.Result';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.MonthlyStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.MonthlyStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.MonthlyStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.MonthlyStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rate: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.MonthlyStatus}
 */
proto.adx.sprint.MonthlyStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.MonthlyStatus;
  return proto.adx.sprint.MonthlyStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.MonthlyStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.MonthlyStatus}
 */
proto.adx.sprint.MonthlyStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.MonthlyStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.MonthlyStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.MonthlyStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.MonthlyStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.adx.sprint.MonthlyStatus.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.MonthlyStatus} returns this
 */
proto.adx.sprint.MonthlyStatus.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.sprint.MonthlyStatus.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.MonthlyStatus} returns this
 */
proto.adx.sprint.MonthlyStatus.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.adx.sprint.MonthlyStatus.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.MonthlyStatus} returns this
 */
proto.adx.sprint.MonthlyStatus.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string rate = 4;
 * @return {string}
 */
proto.adx.sprint.MonthlyStatus.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.MonthlyStatus} returns this
 */
proto.adx.sprint.MonthlyStatus.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.ActionLog.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.ActionLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.ActionLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.ActionLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    implementationdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    validationdate: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.ActionLog}
 */
proto.adx.sprint.ActionLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.ActionLog;
  return proto.adx.sprint.ActionLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.ActionLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.ActionLog}
 */
proto.adx.sprint.ActionLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImplementationdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.ActionLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.ActionLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.ActionLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.ActionLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImplementationdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValidationdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string provider = 1;
 * @return {string}
 */
proto.adx.sprint.ActionLog.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.ActionLog} returns this
 */
proto.adx.sprint.ActionLog.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.adx.sprint.ActionLog.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.ActionLog} returns this
 */
proto.adx.sprint.ActionLog.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string implementationDate = 3;
 * @return {string}
 */
proto.adx.sprint.ActionLog.prototype.getImplementationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.ActionLog} returns this
 */
proto.adx.sprint.ActionLog.prototype.setImplementationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string validationDate = 4;
 * @return {string}
 */
proto.adx.sprint.ActionLog.prototype.getValidationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.ActionLog} returns this
 */
proto.adx.sprint.ActionLog.prototype.setValidationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.CustomField.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.CustomField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.CustomField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CustomField.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.CustomField}
 */
proto.adx.sprint.CustomField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.CustomField;
  return proto.adx.sprint.CustomField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.CustomField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.CustomField}
 */
proto.adx.sprint.CustomField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.CustomField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.CustomField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.CustomField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CustomField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.adx.sprint.CustomField.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CustomField} returns this
 */
proto.adx.sprint.CustomField.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.sprint.CustomField.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CustomField} returns this
 */
proto.adx.sprint.CustomField.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.CustomFieldStruct.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.CustomFieldStruct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.CustomFieldStruct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CustomFieldStruct.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.CustomFieldStruct}
 */
proto.adx.sprint.CustomFieldStruct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.CustomFieldStruct;
  return proto.adx.sprint.CustomFieldStruct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.CustomFieldStruct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.CustomFieldStruct}
 */
proto.adx.sprint.CustomFieldStruct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.CustomFieldStruct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.CustomFieldStruct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.CustomFieldStruct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CustomFieldStruct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.sprint.CustomFieldStruct.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CustomFieldStruct} returns this
 */
proto.adx.sprint.CustomFieldStruct.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.adx.sprint.CustomFieldStruct.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CustomFieldStruct} returns this
 */
proto.adx.sprint.CustomFieldStruct.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.sprint.Sprint.repeatedFields_ = [9,15,16,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.Sprint.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.Sprint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.Sprint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.Sprint.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatedby: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updatedat: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mayorpolicy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    monthlygoalachieve: jspb.Message.getFieldWithDefault(msg, 7, ""),
    monthlypolicy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    monthlystatusList: jspb.Message.toObjectList(msg.getMonthlystatusList(),
    proto.adx.sprint.MonthlyStatus.toObject, includeInstance),
    monthlystatustext: jspb.Message.getFieldWithDefault(msg, 10, ""),
    overview: jspb.Message.getFieldWithDefault(msg, 11, ""),
    problempoints: jspb.Message.getFieldWithDefault(msg, 12, ""),
    problemfactors: jspb.Message.getFieldWithDefault(msg, 13, ""),
    approachpolicy: jspb.Message.getFieldWithDefault(msg, 14, ""),
    todoactionitemsList: jspb.Message.toObjectList(msg.getTodoactionitemsList(),
    proto.adx.sprint.ActionLog.toObject, includeInstance),
    inprogressactionitemsList: jspb.Message.toObjectList(msg.getInprogressactionitemsList(),
    proto.adx.sprint.ActionLog.toObject, includeInstance),
    mdconfirm: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    mdfeedback: jspb.Message.getFieldWithDefault(msg, 18, ""),
    customfieldsList: jspb.Message.toObjectList(msg.getCustomfieldsList(),
    proto.adx.sprint.CustomField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.Sprint}
 */
proto.adx.sprint.Sprint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.Sprint;
  return proto.adx.sprint.Sprint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.Sprint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.Sprint}
 */
proto.adx.sprint.Sprint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedby(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMayorpolicy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonthlygoalachieve(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonthlypolicy(value);
      break;
    case 9:
      var value = new proto.adx.sprint.MonthlyStatus;
      reader.readMessage(value,proto.adx.sprint.MonthlyStatus.deserializeBinaryFromReader);
      msg.addMonthlystatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonthlystatustext(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverview(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProblempoints(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setProblemfactors(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproachpolicy(value);
      break;
    case 15:
      var value = new proto.adx.sprint.ActionLog;
      reader.readMessage(value,proto.adx.sprint.ActionLog.deserializeBinaryFromReader);
      msg.addTodoactionitems(value);
      break;
    case 16:
      var value = new proto.adx.sprint.ActionLog;
      reader.readMessage(value,proto.adx.sprint.ActionLog.deserializeBinaryFromReader);
      msg.addInprogressactionitems(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMdconfirm(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setMdfeedback(value);
      break;
    case 19:
      var value = new proto.adx.sprint.CustomField;
      reader.readMessage(value,proto.adx.sprint.CustomField.deserializeBinaryFromReader);
      msg.addCustomfields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.Sprint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.Sprint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.Sprint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.Sprint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMonthlystatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.adx.sprint.MonthlyStatus.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTodoactionitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.adx.sprint.ActionLog.serializeBinaryToWriter
    );
  }
  f = message.getInprogressactionitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.adx.sprint.ActionLog.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeBool(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCustomfieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.adx.sprint.CustomField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string updatedBy = 2;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getUpdatedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setUpdatedby = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearUpdatedby = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasUpdatedby = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string updatedAt = 3;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getUpdatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setUpdatedat = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearUpdatedat = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string mayorPolicy = 6;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getMayorpolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setMayorpolicy = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearMayorpolicy = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasMayorpolicy = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string monthlyGoalAchieve = 7;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getMonthlygoalachieve = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setMonthlygoalachieve = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearMonthlygoalachieve = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasMonthlygoalachieve = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string monthlyPolicy = 8;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getMonthlypolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setMonthlypolicy = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearMonthlypolicy = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasMonthlypolicy = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated MonthlyStatus monthlyStatus = 9;
 * @return {!Array<!proto.adx.sprint.MonthlyStatus>}
 */
proto.adx.sprint.Sprint.prototype.getMonthlystatusList = function() {
  return /** @type{!Array<!proto.adx.sprint.MonthlyStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.sprint.MonthlyStatus, 9));
};


/**
 * @param {!Array<!proto.adx.sprint.MonthlyStatus>} value
 * @return {!proto.adx.sprint.Sprint} returns this
*/
proto.adx.sprint.Sprint.prototype.setMonthlystatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.adx.sprint.MonthlyStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.sprint.MonthlyStatus}
 */
proto.adx.sprint.Sprint.prototype.addMonthlystatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.adx.sprint.MonthlyStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearMonthlystatusList = function() {
  return this.setMonthlystatusList([]);
};


/**
 * optional string monthlyStatusText = 10;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getMonthlystatustext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setMonthlystatustext = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearMonthlystatustext = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasMonthlystatustext = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string overview = 11;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getOverview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setOverview = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearOverview = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasOverview = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string problemPoints = 12;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getProblempoints = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setProblempoints = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearProblempoints = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasProblempoints = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string problemFactors = 13;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getProblemfactors = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setProblemfactors = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearProblemfactors = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasProblemfactors = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string approachPolicy = 14;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getApproachpolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setApproachpolicy = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearApproachpolicy = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasApproachpolicy = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated ActionLog todoActionItems = 15;
 * @return {!Array<!proto.adx.sprint.ActionLog>}
 */
proto.adx.sprint.Sprint.prototype.getTodoactionitemsList = function() {
  return /** @type{!Array<!proto.adx.sprint.ActionLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.sprint.ActionLog, 15));
};


/**
 * @param {!Array<!proto.adx.sprint.ActionLog>} value
 * @return {!proto.adx.sprint.Sprint} returns this
*/
proto.adx.sprint.Sprint.prototype.setTodoactionitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.adx.sprint.ActionLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.sprint.ActionLog}
 */
proto.adx.sprint.Sprint.prototype.addTodoactionitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.adx.sprint.ActionLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearTodoactionitemsList = function() {
  return this.setTodoactionitemsList([]);
};


/**
 * repeated ActionLog inProgressActionItems = 16;
 * @return {!Array<!proto.adx.sprint.ActionLog>}
 */
proto.adx.sprint.Sprint.prototype.getInprogressactionitemsList = function() {
  return /** @type{!Array<!proto.adx.sprint.ActionLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.sprint.ActionLog, 16));
};


/**
 * @param {!Array<!proto.adx.sprint.ActionLog>} value
 * @return {!proto.adx.sprint.Sprint} returns this
*/
proto.adx.sprint.Sprint.prototype.setInprogressactionitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.adx.sprint.ActionLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.sprint.ActionLog}
 */
proto.adx.sprint.Sprint.prototype.addInprogressactionitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.adx.sprint.ActionLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearInprogressactionitemsList = function() {
  return this.setInprogressactionitemsList([]);
};


/**
 * optional bool mdConfirm = 17;
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.getMdconfirm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setMdconfirm = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearMdconfirm = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasMdconfirm = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string mdFeedback = 18;
 * @return {string}
 */
proto.adx.sprint.Sprint.prototype.getMdfeedback = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.setMdfeedback = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearMdfeedback = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.Sprint.prototype.hasMdfeedback = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * repeated CustomField customFields = 19;
 * @return {!Array<!proto.adx.sprint.CustomField>}
 */
proto.adx.sprint.Sprint.prototype.getCustomfieldsList = function() {
  return /** @type{!Array<!proto.adx.sprint.CustomField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.sprint.CustomField, 19));
};


/**
 * @param {!Array<!proto.adx.sprint.CustomField>} value
 * @return {!proto.adx.sprint.Sprint} returns this
*/
proto.adx.sprint.Sprint.prototype.setCustomfieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.adx.sprint.CustomField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.sprint.CustomField}
 */
proto.adx.sprint.Sprint.prototype.addCustomfields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.adx.sprint.CustomField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.sprint.Sprint} returns this
 */
proto.adx.sprint.Sprint.prototype.clearCustomfieldsList = function() {
  return this.setCustomfieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GenerateNewSprintRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GenerateNewSprintRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GenerateNewSprintRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GenerateNewSprintRequest}
 */
proto.adx.sprint.GenerateNewSprintRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GenerateNewSprintRequest;
  return proto.adx.sprint.GenerateNewSprintRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GenerateNewSprintRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GenerateNewSprintRequest}
 */
proto.adx.sprint.GenerateNewSprintRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GenerateNewSprintRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GenerateNewSprintRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GenerateNewSprintRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GenerateNewSprintRequest} returns this
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string startDate = 2;
 * @return {string}
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GenerateNewSprintRequest} returns this
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endDate = 3;
 * @return {string}
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GenerateNewSprintRequest} returns this
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 advertizerId = 4;
 * @return {number}
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.sprint.GenerateNewSprintRequest} returns this
 */
proto.adx.sprint.GenerateNewSprintRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.sprint.GenerateNewSprintResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.sprint.GenerateNewSprintResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.sprint.GenerateNewSprintResponse.TypeCase}
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.sprint.GenerateNewSprintResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.sprint.GenerateNewSprintResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GenerateNewSprintResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GenerateNewSprintResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GenerateNewSprintResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.sprint.GenerateNewSprintResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GenerateNewSprintResponse}
 */
proto.adx.sprint.GenerateNewSprintResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GenerateNewSprintResponse;
  return proto.adx.sprint.GenerateNewSprintResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GenerateNewSprintResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GenerateNewSprintResponse}
 */
proto.adx.sprint.GenerateNewSprintResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.sprint.GenerateNewSprintResponse.Result;
      reader.readMessage(value,proto.adx.sprint.GenerateNewSprintResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GenerateNewSprintResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GenerateNewSprintResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GenerateNewSprintResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.sprint.GenerateNewSprintResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GenerateNewSprintResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GenerateNewSprintResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sprint: (f = msg.getSprint()) && proto.adx.sprint.Sprint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GenerateNewSprintResponse.Result}
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GenerateNewSprintResponse.Result;
  return proto.adx.sprint.GenerateNewSprintResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GenerateNewSprintResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GenerateNewSprintResponse.Result}
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.Sprint;
      reader.readMessage(value,proto.adx.sprint.Sprint.deserializeBinaryFromReader);
      msg.setSprint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GenerateNewSprintResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GenerateNewSprintResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSprint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.sprint.Sprint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GenerateNewSprintResponse.Result} returns this
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Sprint sprint = 2;
 * @return {?proto.adx.sprint.Sprint}
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.prototype.getSprint = function() {
  return /** @type{?proto.adx.sprint.Sprint} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.Sprint, 2));
};


/**
 * @param {?proto.adx.sprint.Sprint|undefined} value
 * @return {!proto.adx.sprint.GenerateNewSprintResponse.Result} returns this
*/
proto.adx.sprint.GenerateNewSprintResponse.Result.prototype.setSprint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.GenerateNewSprintResponse.Result} returns this
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.prototype.clearSprint = function() {
  return this.setSprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.GenerateNewSprintResponse.Result.prototype.hasSprint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.sprint.GenerateNewSprintResponse.Result}
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.sprint.GenerateNewSprintResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.GenerateNewSprintResponse.Result, 1));
};


/**
 * @param {?proto.adx.sprint.GenerateNewSprintResponse.Result|undefined} value
 * @return {!proto.adx.sprint.GenerateNewSprintResponse} returns this
*/
proto.adx.sprint.GenerateNewSprintResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.sprint.GenerateNewSprintResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.GenerateNewSprintResponse} returns this
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GenerateNewSprintResponse} returns this
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.GenerateNewSprintResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.GenerateNewSprintResponse} returns this
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.GenerateNewSprintResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.GenerateNewSprintResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.CreateSprintRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.CreateSprintRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.CreateSprintRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateSprintRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sprint: (f = msg.getSprint()) && proto.adx.sprint.Sprint.toObject(includeInstance, f),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.CreateSprintRequest}
 */
proto.adx.sprint.CreateSprintRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.CreateSprintRequest;
  return proto.adx.sprint.CreateSprintRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.CreateSprintRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.CreateSprintRequest}
 */
proto.adx.sprint.CreateSprintRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.Sprint;
      reader.readMessage(value,proto.adx.sprint.Sprint.deserializeBinaryFromReader);
      msg.setSprint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.CreateSprintRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.CreateSprintRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.CreateSprintRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateSprintRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSprint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.sprint.Sprint.serializeBinaryToWriter
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.CreateSprintRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CreateSprintRequest} returns this
 */
proto.adx.sprint.CreateSprintRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Sprint sprint = 2;
 * @return {?proto.adx.sprint.Sprint}
 */
proto.adx.sprint.CreateSprintRequest.prototype.getSprint = function() {
  return /** @type{?proto.adx.sprint.Sprint} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.Sprint, 2));
};


/**
 * @param {?proto.adx.sprint.Sprint|undefined} value
 * @return {!proto.adx.sprint.CreateSprintRequest} returns this
*/
proto.adx.sprint.CreateSprintRequest.prototype.setSprint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.CreateSprintRequest} returns this
 */
proto.adx.sprint.CreateSprintRequest.prototype.clearSprint = function() {
  return this.setSprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.CreateSprintRequest.prototype.hasSprint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.sprint.CreateSprintRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.sprint.CreateSprintRequest} returns this
 */
proto.adx.sprint.CreateSprintRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.sprint.CreateSprintResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.sprint.CreateSprintResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.sprint.CreateSprintResponse.TypeCase}
 */
proto.adx.sprint.CreateSprintResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.sprint.CreateSprintResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.sprint.CreateSprintResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.CreateSprintResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.CreateSprintResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.CreateSprintResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateSprintResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.sprint.CreateSprintResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.CreateSprintResponse}
 */
proto.adx.sprint.CreateSprintResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.CreateSprintResponse;
  return proto.adx.sprint.CreateSprintResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.CreateSprintResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.CreateSprintResponse}
 */
proto.adx.sprint.CreateSprintResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.sprint.CreateSprintResponse.Result;
      reader.readMessage(value,proto.adx.sprint.CreateSprintResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.CreateSprintResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.CreateSprintResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.CreateSprintResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateSprintResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.sprint.CreateSprintResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.CreateSprintResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.CreateSprintResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.CreateSprintResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateSprintResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sprint: (f = msg.getSprint()) && proto.adx.sprint.Sprint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.CreateSprintResponse.Result}
 */
proto.adx.sprint.CreateSprintResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.CreateSprintResponse.Result;
  return proto.adx.sprint.CreateSprintResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.CreateSprintResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.CreateSprintResponse.Result}
 */
proto.adx.sprint.CreateSprintResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.Sprint;
      reader.readMessage(value,proto.adx.sprint.Sprint.deserializeBinaryFromReader);
      msg.setSprint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.CreateSprintResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.CreateSprintResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.CreateSprintResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateSprintResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSprint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.sprint.Sprint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.CreateSprintResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CreateSprintResponse.Result} returns this
 */
proto.adx.sprint.CreateSprintResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Sprint sprint = 2;
 * @return {?proto.adx.sprint.Sprint}
 */
proto.adx.sprint.CreateSprintResponse.Result.prototype.getSprint = function() {
  return /** @type{?proto.adx.sprint.Sprint} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.Sprint, 2));
};


/**
 * @param {?proto.adx.sprint.Sprint|undefined} value
 * @return {!proto.adx.sprint.CreateSprintResponse.Result} returns this
*/
proto.adx.sprint.CreateSprintResponse.Result.prototype.setSprint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.CreateSprintResponse.Result} returns this
 */
proto.adx.sprint.CreateSprintResponse.Result.prototype.clearSprint = function() {
  return this.setSprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.CreateSprintResponse.Result.prototype.hasSprint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.sprint.CreateSprintResponse.Result}
 */
proto.adx.sprint.CreateSprintResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.sprint.CreateSprintResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.CreateSprintResponse.Result, 1));
};


/**
 * @param {?proto.adx.sprint.CreateSprintResponse.Result|undefined} value
 * @return {!proto.adx.sprint.CreateSprintResponse} returns this
*/
proto.adx.sprint.CreateSprintResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.sprint.CreateSprintResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.CreateSprintResponse} returns this
 */
proto.adx.sprint.CreateSprintResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.CreateSprintResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.sprint.CreateSprintResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CreateSprintResponse} returns this
 */
proto.adx.sprint.CreateSprintResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.CreateSprintResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.CreateSprintResponse} returns this
 */
proto.adx.sprint.CreateSprintResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.CreateSprintResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.CreateSprintResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.DeleteSprintRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.DeleteSprintRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.DeleteSprintRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteSprintRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.DeleteSprintRequest}
 */
proto.adx.sprint.DeleteSprintRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.DeleteSprintRequest;
  return proto.adx.sprint.DeleteSprintRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.DeleteSprintRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.DeleteSprintRequest}
 */
proto.adx.sprint.DeleteSprintRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.DeleteSprintRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.DeleteSprintRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.DeleteSprintRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteSprintRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.DeleteSprintRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteSprintRequest} returns this
 */
proto.adx.sprint.DeleteSprintRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.adx.sprint.DeleteSprintRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteSprintRequest} returns this
 */
proto.adx.sprint.DeleteSprintRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.sprint.DeleteSprintResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.sprint.DeleteSprintResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.sprint.DeleteSprintResponse.TypeCase}
 */
proto.adx.sprint.DeleteSprintResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.sprint.DeleteSprintResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.sprint.DeleteSprintResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.DeleteSprintResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.DeleteSprintResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.DeleteSprintResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteSprintResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.sprint.DeleteSprintResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.DeleteSprintResponse}
 */
proto.adx.sprint.DeleteSprintResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.DeleteSprintResponse;
  return proto.adx.sprint.DeleteSprintResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.DeleteSprintResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.DeleteSprintResponse}
 */
proto.adx.sprint.DeleteSprintResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.sprint.DeleteSprintResponse.Result;
      reader.readMessage(value,proto.adx.sprint.DeleteSprintResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.DeleteSprintResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.DeleteSprintResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.DeleteSprintResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteSprintResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.sprint.DeleteSprintResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.DeleteSprintResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.DeleteSprintResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.DeleteSprintResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteSprintResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.DeleteSprintResponse.Result}
 */
proto.adx.sprint.DeleteSprintResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.DeleteSprintResponse.Result;
  return proto.adx.sprint.DeleteSprintResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.DeleteSprintResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.DeleteSprintResponse.Result}
 */
proto.adx.sprint.DeleteSprintResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.DeleteSprintResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.DeleteSprintResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.DeleteSprintResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteSprintResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.DeleteSprintResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteSprintResponse.Result} returns this
 */
proto.adx.sprint.DeleteSprintResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.adx.sprint.DeleteSprintResponse.Result.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteSprintResponse.Result} returns this
 */
proto.adx.sprint.DeleteSprintResponse.Result.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.sprint.DeleteSprintResponse.Result}
 */
proto.adx.sprint.DeleteSprintResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.sprint.DeleteSprintResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.DeleteSprintResponse.Result, 1));
};


/**
 * @param {?proto.adx.sprint.DeleteSprintResponse.Result|undefined} value
 * @return {!proto.adx.sprint.DeleteSprintResponse} returns this
*/
proto.adx.sprint.DeleteSprintResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.sprint.DeleteSprintResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.DeleteSprintResponse} returns this
 */
proto.adx.sprint.DeleteSprintResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.DeleteSprintResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.sprint.DeleteSprintResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteSprintResponse} returns this
 */
proto.adx.sprint.DeleteSprintResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.DeleteSprintResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.DeleteSprintResponse} returns this
 */
proto.adx.sprint.DeleteSprintResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.DeleteSprintResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.DeleteSprintResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GetConsultantSprintsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GetConsultantSprintsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GetConsultantSprintsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetConsultantSprintsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GetConsultantSprintsRequest}
 */
proto.adx.sprint.GetConsultantSprintsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GetConsultantSprintsRequest;
  return proto.adx.sprint.GetConsultantSprintsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GetConsultantSprintsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GetConsultantSprintsRequest}
 */
proto.adx.sprint.GetConsultantSprintsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GetConsultantSprintsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GetConsultantSprintsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GetConsultantSprintsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetConsultantSprintsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.GetConsultantSprintsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetConsultantSprintsRequest} returns this
 */
proto.adx.sprint.GetConsultantSprintsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string actualMonth = 2;
 * @return {string}
 */
proto.adx.sprint.GetConsultantSprintsRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetConsultantSprintsRequest} returns this
 */
proto.adx.sprint.GetConsultantSprintsRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.sprint.GetConsultantSprintsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.sprint.GetConsultantSprintsRequest} returns this
 */
proto.adx.sprint.GetConsultantSprintsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.sprint.GetConsultantSprintsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.sprint.GetConsultantSprintsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.sprint.GetConsultantSprintsResponse.TypeCase}
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.sprint.GetConsultantSprintsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.sprint.GetConsultantSprintsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GetConsultantSprintsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GetConsultantSprintsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetConsultantSprintsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.sprint.GetConsultantSprintsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse}
 */
proto.adx.sprint.GetConsultantSprintsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GetConsultantSprintsResponse;
  return proto.adx.sprint.GetConsultantSprintsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GetConsultantSprintsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse}
 */
proto.adx.sprint.GetConsultantSprintsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.sprint.GetConsultantSprintsResponse.Result;
      reader.readMessage(value,proto.adx.sprint.GetConsultantSprintsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GetConsultantSprintsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GetConsultantSprintsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetConsultantSprintsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.sprint.GetConsultantSprintsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GetConsultantSprintsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GetConsultantSprintsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sprintsList: jspb.Message.toObjectList(msg.getSprintsList(),
    proto.adx.sprint.Sprint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse.Result}
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GetConsultantSprintsResponse.Result;
  return proto.adx.sprint.GetConsultantSprintsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GetConsultantSprintsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse.Result}
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.Sprint;
      reader.readMessage(value,proto.adx.sprint.Sprint.deserializeBinaryFromReader);
      msg.addSprints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GetConsultantSprintsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GetConsultantSprintsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSprintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.sprint.Sprint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse.Result} returns this
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Sprint sprints = 2;
 * @return {!Array<!proto.adx.sprint.Sprint>}
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.prototype.getSprintsList = function() {
  return /** @type{!Array<!proto.adx.sprint.Sprint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.sprint.Sprint, 2));
};


/**
 * @param {!Array<!proto.adx.sprint.Sprint>} value
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse.Result} returns this
*/
proto.adx.sprint.GetConsultantSprintsResponse.Result.prototype.setSprintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.sprint.Sprint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.sprint.Sprint}
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.prototype.addSprints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.sprint.Sprint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse.Result} returns this
 */
proto.adx.sprint.GetConsultantSprintsResponse.Result.prototype.clearSprintsList = function() {
  return this.setSprintsList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.sprint.GetConsultantSprintsResponse.Result}
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.sprint.GetConsultantSprintsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.GetConsultantSprintsResponse.Result, 1));
};


/**
 * @param {?proto.adx.sprint.GetConsultantSprintsResponse.Result|undefined} value
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse} returns this
*/
proto.adx.sprint.GetConsultantSprintsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.sprint.GetConsultantSprintsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse} returns this
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse} returns this
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.GetConsultantSprintsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.GetConsultantSprintsResponse} returns this
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.GetConsultantSprintsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.GetConsultantSprintsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GetAdvertizerSprintsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GetAdvertizerSprintsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GetAdvertizerSprintsRequest}
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GetAdvertizerSprintsRequest;
  return proto.adx.sprint.GetAdvertizerSprintsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GetAdvertizerSprintsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GetAdvertizerSprintsRequest}
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GetAdvertizerSprintsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GetAdvertizerSprintsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetAdvertizerSprintsRequest} returns this
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string actualMonth = 2;
 * @return {string}
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetAdvertizerSprintsRequest} returns this
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.sprint.GetAdvertizerSprintsRequest} returns this
 */
proto.adx.sprint.GetAdvertizerSprintsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.sprint.GetAdvertizerSprintsResponse.TypeCase}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.sprint.GetAdvertizerSprintsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.sprint.GetAdvertizerSprintsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GetAdvertizerSprintsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GetAdvertizerSprintsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.sprint.GetAdvertizerSprintsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GetAdvertizerSprintsResponse;
  return proto.adx.sprint.GetAdvertizerSprintsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GetAdvertizerSprintsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.sprint.GetAdvertizerSprintsResponse.Result;
      reader.readMessage(value,proto.adx.sprint.GetAdvertizerSprintsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GetAdvertizerSprintsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GetAdvertizerSprintsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.sprint.GetAdvertizerSprintsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GetAdvertizerSprintsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GetAdvertizerSprintsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sprintsList: jspb.Message.toObjectList(msg.getSprintsList(),
    proto.adx.sprint.Sprint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse.Result}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GetAdvertizerSprintsResponse.Result;
  return proto.adx.sprint.GetAdvertizerSprintsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GetAdvertizerSprintsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse.Result}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.Sprint;
      reader.readMessage(value,proto.adx.sprint.Sprint.deserializeBinaryFromReader);
      msg.addSprints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GetAdvertizerSprintsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GetAdvertizerSprintsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSprintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.sprint.Sprint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse.Result} returns this
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Sprint sprints = 2;
 * @return {!Array<!proto.adx.sprint.Sprint>}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.prototype.getSprintsList = function() {
  return /** @type{!Array<!proto.adx.sprint.Sprint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.sprint.Sprint, 2));
};


/**
 * @param {!Array<!proto.adx.sprint.Sprint>} value
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse.Result} returns this
*/
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.prototype.setSprintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.sprint.Sprint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.sprint.Sprint}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.prototype.addSprints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.sprint.Sprint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse.Result} returns this
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.Result.prototype.clearSprintsList = function() {
  return this.setSprintsList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.sprint.GetAdvertizerSprintsResponse.Result}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.sprint.GetAdvertizerSprintsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.GetAdvertizerSprintsResponse.Result, 1));
};


/**
 * @param {?proto.adx.sprint.GetAdvertizerSprintsResponse.Result|undefined} value
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse} returns this
*/
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.sprint.GetAdvertizerSprintsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse} returns this
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse} returns this
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.GetAdvertizerSprintsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.GetAdvertizerSprintsResponse} returns this
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.GetAdvertizerSprintsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.GetAdvertizerSprintsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.CreateCustomFieldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.CreateCustomFieldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.CreateCustomFieldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateCustomFieldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.CreateCustomFieldRequest}
 */
proto.adx.sprint.CreateCustomFieldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.CreateCustomFieldRequest;
  return proto.adx.sprint.CreateCustomFieldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.CreateCustomFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.CreateCustomFieldRequest}
 */
proto.adx.sprint.CreateCustomFieldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.CreateCustomFieldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.CreateCustomFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.CreateCustomFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateCustomFieldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.CreateCustomFieldRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CreateCustomFieldRequest} returns this
 */
proto.adx.sprint.CreateCustomFieldRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.adx.sprint.CreateCustomFieldRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CreateCustomFieldRequest} returns this
 */
proto.adx.sprint.CreateCustomFieldRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.sprint.CreateCustomFieldRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.sprint.CreateCustomFieldRequest} returns this
 */
proto.adx.sprint.CreateCustomFieldRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.sprint.CreateCustomFieldResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.sprint.CreateCustomFieldResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.sprint.CreateCustomFieldResponse.TypeCase}
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.sprint.CreateCustomFieldResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.sprint.CreateCustomFieldResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.CreateCustomFieldResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.CreateCustomFieldResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateCustomFieldResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.sprint.CreateCustomFieldResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.CreateCustomFieldResponse}
 */
proto.adx.sprint.CreateCustomFieldResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.CreateCustomFieldResponse;
  return proto.adx.sprint.CreateCustomFieldResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.CreateCustomFieldResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.CreateCustomFieldResponse}
 */
proto.adx.sprint.CreateCustomFieldResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.sprint.CreateCustomFieldResponse.Result;
      reader.readMessage(value,proto.adx.sprint.CreateCustomFieldResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.CreateCustomFieldResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.CreateCustomFieldResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateCustomFieldResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.sprint.CreateCustomFieldResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.CreateCustomFieldResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.CreateCustomFieldResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: (f = msg.getField()) && proto.adx.sprint.CustomFieldStruct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.CreateCustomFieldResponse.Result}
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.CreateCustomFieldResponse.Result;
  return proto.adx.sprint.CreateCustomFieldResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.CreateCustomFieldResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.CreateCustomFieldResponse.Result}
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.CustomFieldStruct;
      reader.readMessage(value,proto.adx.sprint.CustomFieldStruct.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.CreateCustomFieldResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.CreateCustomFieldResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.sprint.CustomFieldStruct.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CreateCustomFieldResponse.Result} returns this
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CustomFieldStruct field = 2;
 * @return {?proto.adx.sprint.CustomFieldStruct}
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.prototype.getField = function() {
  return /** @type{?proto.adx.sprint.CustomFieldStruct} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.CustomFieldStruct, 2));
};


/**
 * @param {?proto.adx.sprint.CustomFieldStruct|undefined} value
 * @return {!proto.adx.sprint.CreateCustomFieldResponse.Result} returns this
*/
proto.adx.sprint.CreateCustomFieldResponse.Result.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.CreateCustomFieldResponse.Result} returns this
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.CreateCustomFieldResponse.Result.prototype.hasField = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.sprint.CreateCustomFieldResponse.Result}
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.sprint.CreateCustomFieldResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.CreateCustomFieldResponse.Result, 1));
};


/**
 * @param {?proto.adx.sprint.CreateCustomFieldResponse.Result|undefined} value
 * @return {!proto.adx.sprint.CreateCustomFieldResponse} returns this
*/
proto.adx.sprint.CreateCustomFieldResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.sprint.CreateCustomFieldResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.CreateCustomFieldResponse} returns this
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.CreateCustomFieldResponse} returns this
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.CreateCustomFieldResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.CreateCustomFieldResponse} returns this
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.CreateCustomFieldResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.CreateCustomFieldResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.UpdateCustomFieldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.UpdateCustomFieldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.UpdateCustomFieldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.UpdateCustomFieldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: (f = msg.getField()) && proto.adx.sprint.CustomFieldStruct.toObject(includeInstance, f),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.UpdateCustomFieldRequest}
 */
proto.adx.sprint.UpdateCustomFieldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.UpdateCustomFieldRequest;
  return proto.adx.sprint.UpdateCustomFieldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.UpdateCustomFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.UpdateCustomFieldRequest}
 */
proto.adx.sprint.UpdateCustomFieldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.CustomFieldStruct;
      reader.readMessage(value,proto.adx.sprint.CustomFieldStruct.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.UpdateCustomFieldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.UpdateCustomFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.UpdateCustomFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.UpdateCustomFieldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.sprint.CustomFieldStruct.serializeBinaryToWriter
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.UpdateCustomFieldRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.UpdateCustomFieldRequest} returns this
 */
proto.adx.sprint.UpdateCustomFieldRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CustomFieldStruct field = 2;
 * @return {?proto.adx.sprint.CustomFieldStruct}
 */
proto.adx.sprint.UpdateCustomFieldRequest.prototype.getField = function() {
  return /** @type{?proto.adx.sprint.CustomFieldStruct} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.CustomFieldStruct, 2));
};


/**
 * @param {?proto.adx.sprint.CustomFieldStruct|undefined} value
 * @return {!proto.adx.sprint.UpdateCustomFieldRequest} returns this
*/
proto.adx.sprint.UpdateCustomFieldRequest.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.UpdateCustomFieldRequest} returns this
 */
proto.adx.sprint.UpdateCustomFieldRequest.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.UpdateCustomFieldRequest.prototype.hasField = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.sprint.UpdateCustomFieldRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.sprint.UpdateCustomFieldRequest} returns this
 */
proto.adx.sprint.UpdateCustomFieldRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.sprint.UpdateCustomFieldResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.sprint.UpdateCustomFieldResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.sprint.UpdateCustomFieldResponse.TypeCase}
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.sprint.UpdateCustomFieldResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.sprint.UpdateCustomFieldResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.UpdateCustomFieldResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.UpdateCustomFieldResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.UpdateCustomFieldResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.sprint.UpdateCustomFieldResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse}
 */
proto.adx.sprint.UpdateCustomFieldResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.UpdateCustomFieldResponse;
  return proto.adx.sprint.UpdateCustomFieldResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.UpdateCustomFieldResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse}
 */
proto.adx.sprint.UpdateCustomFieldResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.sprint.UpdateCustomFieldResponse.Result;
      reader.readMessage(value,proto.adx.sprint.UpdateCustomFieldResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.UpdateCustomFieldResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.UpdateCustomFieldResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.UpdateCustomFieldResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.sprint.UpdateCustomFieldResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.UpdateCustomFieldResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.UpdateCustomFieldResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: (f = msg.getField()) && proto.adx.sprint.CustomFieldStruct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse.Result}
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.UpdateCustomFieldResponse.Result;
  return proto.adx.sprint.UpdateCustomFieldResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.UpdateCustomFieldResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse.Result}
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.CustomFieldStruct;
      reader.readMessage(value,proto.adx.sprint.CustomFieldStruct.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.UpdateCustomFieldResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.UpdateCustomFieldResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.sprint.CustomFieldStruct.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse.Result} returns this
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CustomFieldStruct field = 2;
 * @return {?proto.adx.sprint.CustomFieldStruct}
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.prototype.getField = function() {
  return /** @type{?proto.adx.sprint.CustomFieldStruct} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.CustomFieldStruct, 2));
};


/**
 * @param {?proto.adx.sprint.CustomFieldStruct|undefined} value
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse.Result} returns this
*/
proto.adx.sprint.UpdateCustomFieldResponse.Result.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse.Result} returns this
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.UpdateCustomFieldResponse.Result.prototype.hasField = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.sprint.UpdateCustomFieldResponse.Result}
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.sprint.UpdateCustomFieldResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.UpdateCustomFieldResponse.Result, 1));
};


/**
 * @param {?proto.adx.sprint.UpdateCustomFieldResponse.Result|undefined} value
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse} returns this
*/
proto.adx.sprint.UpdateCustomFieldResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.sprint.UpdateCustomFieldResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse} returns this
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse} returns this
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.UpdateCustomFieldResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.UpdateCustomFieldResponse} returns this
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.UpdateCustomFieldResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.UpdateCustomFieldResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.DeleteCustomFieldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.DeleteCustomFieldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.DeleteCustomFieldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteCustomFieldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.DeleteCustomFieldRequest}
 */
proto.adx.sprint.DeleteCustomFieldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.DeleteCustomFieldRequest;
  return proto.adx.sprint.DeleteCustomFieldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.DeleteCustomFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.DeleteCustomFieldRequest}
 */
proto.adx.sprint.DeleteCustomFieldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.DeleteCustomFieldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.DeleteCustomFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.DeleteCustomFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteCustomFieldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.DeleteCustomFieldRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteCustomFieldRequest} returns this
 */
proto.adx.sprint.DeleteCustomFieldRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.adx.sprint.DeleteCustomFieldRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteCustomFieldRequest} returns this
 */
proto.adx.sprint.DeleteCustomFieldRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.sprint.DeleteCustomFieldRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.sprint.DeleteCustomFieldRequest} returns this
 */
proto.adx.sprint.DeleteCustomFieldRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.sprint.DeleteCustomFieldResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.sprint.DeleteCustomFieldResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.sprint.DeleteCustomFieldResponse.TypeCase}
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.sprint.DeleteCustomFieldResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.sprint.DeleteCustomFieldResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.DeleteCustomFieldResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.DeleteCustomFieldResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteCustomFieldResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.sprint.DeleteCustomFieldResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse}
 */
proto.adx.sprint.DeleteCustomFieldResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.DeleteCustomFieldResponse;
  return proto.adx.sprint.DeleteCustomFieldResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.DeleteCustomFieldResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse}
 */
proto.adx.sprint.DeleteCustomFieldResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.sprint.DeleteCustomFieldResponse.Result;
      reader.readMessage(value,proto.adx.sprint.DeleteCustomFieldResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.DeleteCustomFieldResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.DeleteCustomFieldResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteCustomFieldResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.sprint.DeleteCustomFieldResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Field.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.DeleteCustomFieldResponse.Field.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.DeleteCustomFieldResponse.Field} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteCustomFieldResponse.Field.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse.Field}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Field.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.DeleteCustomFieldResponse.Field;
  return proto.adx.sprint.DeleteCustomFieldResponse.Field.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.DeleteCustomFieldResponse.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse.Field}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Field.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Field.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.DeleteCustomFieldResponse.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.DeleteCustomFieldResponse.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteCustomFieldResponse.Field.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Field.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse.Field} returns this
 */
proto.adx.sprint.DeleteCustomFieldResponse.Field.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.DeleteCustomFieldResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.DeleteCustomFieldResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field: (f = msg.getField()) && proto.adx.sprint.DeleteCustomFieldResponse.Field.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse.Result}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.DeleteCustomFieldResponse.Result;
  return proto.adx.sprint.DeleteCustomFieldResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.DeleteCustomFieldResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse.Result}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.DeleteCustomFieldResponse.Field;
      reader.readMessage(value,proto.adx.sprint.DeleteCustomFieldResponse.Field.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.DeleteCustomFieldResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.DeleteCustomFieldResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.adx.sprint.DeleteCustomFieldResponse.Field.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse.Result} returns this
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Field field = 2;
 * @return {?proto.adx.sprint.DeleteCustomFieldResponse.Field}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.prototype.getField = function() {
  return /** @type{?proto.adx.sprint.DeleteCustomFieldResponse.Field} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.DeleteCustomFieldResponse.Field, 2));
};


/**
 * @param {?proto.adx.sprint.DeleteCustomFieldResponse.Field|undefined} value
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse.Result} returns this
*/
proto.adx.sprint.DeleteCustomFieldResponse.Result.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse.Result} returns this
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.DeleteCustomFieldResponse.Result.prototype.hasField = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.sprint.DeleteCustomFieldResponse.Result}
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.sprint.DeleteCustomFieldResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.DeleteCustomFieldResponse.Result, 1));
};


/**
 * @param {?proto.adx.sprint.DeleteCustomFieldResponse.Result|undefined} value
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse} returns this
*/
proto.adx.sprint.DeleteCustomFieldResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.sprint.DeleteCustomFieldResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse} returns this
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse} returns this
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.DeleteCustomFieldResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.DeleteCustomFieldResponse} returns this
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.DeleteCustomFieldResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.DeleteCustomFieldResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GetCustomFieldsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GetCustomFieldsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GetCustomFieldsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetCustomFieldsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GetCustomFieldsRequest}
 */
proto.adx.sprint.GetCustomFieldsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GetCustomFieldsRequest;
  return proto.adx.sprint.GetCustomFieldsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GetCustomFieldsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GetCustomFieldsRequest}
 */
proto.adx.sprint.GetCustomFieldsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GetCustomFieldsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GetCustomFieldsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GetCustomFieldsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetCustomFieldsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.GetCustomFieldsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetCustomFieldsRequest} returns this
 */
proto.adx.sprint.GetCustomFieldsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.sprint.GetCustomFieldsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.sprint.GetCustomFieldsRequest} returns this
 */
proto.adx.sprint.GetCustomFieldsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.sprint.GetCustomFieldsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.sprint.GetCustomFieldsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.sprint.GetCustomFieldsResponse.TypeCase}
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.sprint.GetCustomFieldsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.sprint.GetCustomFieldsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GetCustomFieldsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GetCustomFieldsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetCustomFieldsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.sprint.GetCustomFieldsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GetCustomFieldsResponse}
 */
proto.adx.sprint.GetCustomFieldsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GetCustomFieldsResponse;
  return proto.adx.sprint.GetCustomFieldsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GetCustomFieldsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GetCustomFieldsResponse}
 */
proto.adx.sprint.GetCustomFieldsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.sprint.GetCustomFieldsResponse.Result;
      reader.readMessage(value,proto.adx.sprint.GetCustomFieldsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GetCustomFieldsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GetCustomFieldsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetCustomFieldsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.sprint.GetCustomFieldsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.sprint.GetCustomFieldsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.sprint.GetCustomFieldsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.adx.sprint.CustomFieldStruct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.sprint.GetCustomFieldsResponse.Result}
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.sprint.GetCustomFieldsResponse.Result;
  return proto.adx.sprint.GetCustomFieldsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.sprint.GetCustomFieldsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.sprint.GetCustomFieldsResponse.Result}
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.sprint.CustomFieldStruct;
      reader.readMessage(value,proto.adx.sprint.CustomFieldStruct.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.sprint.GetCustomFieldsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.sprint.GetCustomFieldsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.sprint.CustomFieldStruct.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetCustomFieldsResponse.Result} returns this
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CustomFieldStruct fields = 2;
 * @return {!Array<!proto.adx.sprint.CustomFieldStruct>}
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.adx.sprint.CustomFieldStruct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.sprint.CustomFieldStruct, 2));
};


/**
 * @param {!Array<!proto.adx.sprint.CustomFieldStruct>} value
 * @return {!proto.adx.sprint.GetCustomFieldsResponse.Result} returns this
*/
proto.adx.sprint.GetCustomFieldsResponse.Result.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.sprint.CustomFieldStruct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.sprint.CustomFieldStruct}
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.sprint.CustomFieldStruct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.sprint.GetCustomFieldsResponse.Result} returns this
 */
proto.adx.sprint.GetCustomFieldsResponse.Result.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.sprint.GetCustomFieldsResponse.Result}
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.sprint.GetCustomFieldsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.sprint.GetCustomFieldsResponse.Result, 1));
};


/**
 * @param {?proto.adx.sprint.GetCustomFieldsResponse.Result|undefined} value
 * @return {!proto.adx.sprint.GetCustomFieldsResponse} returns this
*/
proto.adx.sprint.GetCustomFieldsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.sprint.GetCustomFieldsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.sprint.GetCustomFieldsResponse} returns this
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.sprint.GetCustomFieldsResponse} returns this
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.GetCustomFieldsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.sprint.GetCustomFieldsResponse} returns this
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.sprint.GetCustomFieldsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.sprint.GetCustomFieldsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.sprint);
