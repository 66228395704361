import { getTabListOfAdProviderReportPage, /*getConsultantCategoryMonthlyReport*/ } from "../../com/grpc/ad_provider.js";
import {
    getConsultantAdProviderCategoryMonthlyReport, getConsultantAdProviderCategoryWeeklyReport, getConsultantAdProviderCategoryDailyReport, getConsultantAdProviderCampaignMonthlyReport, getConsultantAdProviderCampaignWeeklyReport, getConsultantAdProviderCampaignDailyReport, getConsultantAdProviderAdGroupMonthlyReport, getConsultantAdProviderAdGroupWeeklyReport, getConsultantAdProviderAdGroupDailyReport, getConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport,
    getConsultantAdProviderCategoriesDimensionMonthlyReport,
    getConsultantAdProviderCategoriesDimensionWeeklyReport,
    getConsultantAdProviderCategoriesDimensionDailyReport,
} from "../../com/grpc/summary.js";
import { AdProviderTab, Cell, ChartStructureTable, ChartStructure } from '../data.js'
import * as tacklebox from '../../tacklebox/core/index.js'
import { GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest } from '../../../js/com/grpc/protos_gen/consultant_ad_provider_report_pb.js';
import { ConsultantAdProviderReportClient } from '../../../js/com/grpc/protos_gen/consultant_ad_provider_report_grpc_web_pb.js';
import { url } from '../../setting.js';
import { uuid } from '../../tacklebox/core/uuid.js';
import { /*ratescale3Step, */getColorKeyCodeByBackendKey, cpa11StepColor } from '../../setting.js';
import { ScreenBarValue2 } from '@/js/components/ScreenBarValue2.js';
import {
    // ComponentInvisibleColumn,
    // PageInvisibleColumnStocker,
    // AdvertizerInvisibleColumnStocker,
    InvisibleColumnStocker,
    ColumnStyleStocker,
    // AdvertizerColumnStyleStocker,
    // PageColumnStyleStocker,
    // ComponentColumnStyle,
    // ColumnStyle,
    ColumnDefaultHideSaver,
} from '@/js/components/column.js';
import { getInformationLatestUpdate } from "../../com/grpc/information";

import {data2dateandweek} from '../../tacklebox/date/time.js';
import {
    getCustomReportPages,
} from '../../com/grpc/custom_report';

function hideColIndex2ByStructure(structure) {
    let is = [];
    for (let i in structure?.chart) {
        if (structure?.chart[i]?.label) {
            if (structure.chart[i].label.indexOf("(i)") >= 0) {
                is.push(Number(i) + 1);
            }
        }
    }
    return is;
}
function categoryLengthByStructure(structure) {
    try {
        return structure?.category?.length;
    }catch(ex) {
        console.log(ex);
    }
    return 0;
}

let colScale3 = cpa11StepColor();

//let colScale2 = [
//    "#37803A", // good
//    "#449E48",
//    "#5CB860",
//    "#9BD39E",
//    "#FAAEAA",
//    "#F66D65",
//    "#F2382C",
//    "#DA1B0E", // bad
//]

// MVPなので、設定は埋め込みで間に合わせの実装。
// TODO: BEから設定を取得する。
let getAdvertizerValueSettings_advertizerId = null;
let getAdvertizerValueSettings = function (backendKey) {
    return getColorKeyCodeByBackendKey(backendKey, getAdvertizerValueSettings_advertizerId);
}

function AllTabChartStructureTable() {
    ChartStructureTable.call(this);
}
tacklebox.extend(AllTabChartStructureTable, ChartStructureTable);

function AdproDCCAdGroupMonthlyRecord(structure, raw_report, span = 1) {
    this.structure = structure;
    this.raw_report = raw_report;
    this.span = span;
}
AdproDCCAdGroupMonthlyRecord.prototype.getCols = function () {
    let cols = [];
    let self = this;
    function _key2type(key) {
        if (self.structure) {
            let charts = self.structure.getActiveCharts();
            for (let i in charts) {
                if (charts[i].backendKey == key) {
                    return charts[i].type;
                }
            }
        }
        return "string";
    }
    for (let i in this.raw_report.tableList) {
        cols.push(new Cell(this.raw_report.tableList[i].value, _key2type(this.raw_report.tableList[i].key)))
    }

    return cols;
};

function AdproDCCAdGroupMonthlyHolder() {
    this.structureHolder = null;
    this.raw_reports = [];
    this.advertizerId = null;
    this.stocks = [];
    this.activeUuid = uuid();
}
AdproDCCAdGroupMonthlyHolder.prototype.initialize = function (structure, accountType, advertizerId, active_uuid) {
    this.structureHolder = new ChartStructure(structure, accountType);
    this.raw_reports = [];
    this.advertizerId = advertizerId;
    this.activeUuid = active_uuid;
    this.stocks = this.loadShowHide();
}
AdproDCCAdGroupMonthlyHolder.prototype.saveShowHide = function (stocks) {
    try {
        let jmsg = JSON.stringify(stocks)
        localStorage.setItem("ta_" + this.advertizer_id , jmsg);
    } catch (ex) {
        console.log(ex);
    }
};
AdproDCCAdGroupMonthlyHolder.prototype.loadShowHide = function () {
    try {
        let smsg = localStorage.getItem("ta_" + this.advertizer_id);
        if (smsg) {
            return JSON.parse(smsg);
        }
    } catch (ex) {
        console.log(ex);
    }
    return [];
}
AdproDCCAdGroupMonthlyHolder.prototype.addReports = function (reports, check_uuid) {
    if (this.activeUuid != check_uuid) {
        return;
    }
    for (let i in reports) {
        this.raw_reports.push(reports[i]);
    }
};
AdproDCCAdGroupMonthlyHolder.prototype.getCategoryNames = function () {
    if (this.structureHolder?.structure) {
        return this.structureHolder?.structure.category;
    }
    return [""];
};
AdproDCCAdGroupMonthlyHolder.prototype.getDimension = function () {
    if (this.structureHolder?.structure) {
        return this.structureHolder?.structure.category.length;
    }
    return 0
};

AdproDCCAdGroupMonthlyHolder.prototype.isHideCampaign = function (campaign_name) {
    for (let i in this.stocks) {
        if (campaign_name == this.stocks[i]) {
            return true;
        }
    }
    return false;
};
AdproDCCAdGroupMonthlyHolder.prototype.showHideToggle = function (campaign_name) {
    for (let i = 0 ; i < this.stocks.length ; i++) {
        if (campaign_name == this.stocks[i]) {
            this.stocks.splice(i, 1);
            this.saveShowHide(this.stocks);
            return;
        }
    }
    this.stocks.push(campaign_name);
    this.saveShowHide(this.stocks);
};
AdproDCCAdGroupMonthlyHolder.prototype.getAdproDCCAdGroupMonthlyRecords = function () {
    let reports = [];

    function _samedimenstion1count(target, master_reports = []) {
        const reports = [];
        for (let i in master_reports) {
            if (master_reports[i].dimensionList.length > 1) {
                if (master_reports[i].dimensionList[0].value == target.dimensionList[0].value) {
                    reports.push(master_reports[i]);
                }
            }
        }

        return reports;
    }

    function _samedimenstion2count(target, master_reports = []) {
        const reports = [];
        for (let i in master_reports) {
            if (master_reports[i].dimensionList.length > 1) {
                if (master_reports[i].dimensionList[0].value == target.dimensionList[0].value) {
                    if (master_reports[i].dimensionList[1].value == target.dimensionList[1].value) {
                        reports.push(master_reports[i]);
                    }
                }
            }
        }

        return reports;
    }

    function _samedimenstion3count(target, master_reports = []) {
        const reports = [];
        for (let i in master_reports) {
            if (master_reports[i].dimensionList.length > 1) {
                if (master_reports[i].dimensionList[0].value == target.dimensionList[0].value) {
                    if (master_reports[i].dimensionList[1].value == target.dimensionList[1].value) {
                        if (master_reports[i].dimensionList[2].value == target.dimensionList[2].value) {
                            reports.push(master_reports[i]);
                        }
                    }
                }
            }
        }

        return reports;
    }

    if (this.structureHolder.structure.category.length == 2) {
        const hidereports = []
        for (let i in this.raw_reports) {
            let r = this.raw_reports[i];
            if (r.dimensionList.length == 4 && r.dimensionList[3].value != "ALL" && this.isHideCampaign(r.dimensionList[2].value)) {
                console.log(r);
            }else{
                hidereports.push(r);
            }
            // hidereports.push(r);
        }

        for (let i = 0; i < hidereports.length; i++) {
            let target = hidereports[i];
            let span = 0;

            if (target.dimensionList.length == 1) {
                span = 1;
            } else if (target.dimensionList.length == 2) {
                let x = _samedimenstion1count(target, hidereports);
                span = x.length;
            } else {
                if (target.dimensionList.length > 2 && target.dimensionList[2].value == "ALL") {
                    let x = _samedimenstion2count(target, hidereports);
                    span = x.length;
                }

                if (target.dimensionList.length > 3 && target.dimensionList[3].value == "ALL") {
                    let x = _samedimenstion3count(target, hidereports);
                    span = x.length;
                }
            }

            reports.push(new AdproDCCAdGroupMonthlyRecord(this.structureHolder, target, span));
        }
    }

    if (this.structureHolder.structure.category.length == 1) {
        const hidereports = []
        for (let i in this.raw_reports) {
            let r = this.raw_reports[i];
            if (r.dimensionList.length == 3 && r.dimensionList[2].value != "ALL" && this.isHideCampaign(r.dimensionList[1].value)) {
                console.log(r);
            }else{
                hidereports.push(r);
            }
            // hidereports.push(r);
        }
        
        for (let i = 0; i < hidereports.length; i++) {
            let target = hidereports[i];
            let span = 0;

            if (target.dimensionList.length == 1) {
                span = 1;
            } else if (target.dimensionList.length == 2) {
                let x = _samedimenstion1count(target, hidereports);
                span = x.length;
            } else {
                if (target.dimensionList[2].value == "ALL") {
                    let x = _samedimenstion2count(target, hidereports);
                    span = x.length;
                }
            }

            reports.push(new AdproDCCAdGroupMonthlyRecord(this.structureHolder, target, span));
        }
    }




    return reports;
};

function AdProviderPage() {
    this.currentPageStreams = [];

    this.tab = new AdProviderTab();
    this.campainStructure = null;
    this.selectedId = 0;
    this.loadingUuid = null;
    this.isAdProviderMonthlyNetworking = false;
    this.isLoadCampaignNetworking = false;
    this.mode = 0;
    this.pageId = 0;
    this.timeRange = null;
    this.advertizerId = null;
    this.user = null;
    this.currentMonth = "";
    this.selectedMode = 1;

    this.providerTables = [];
    this.providerTablesList = [];
    this.providerTablesProviders = [];
    this.providerTablesLoaded = [];

    this.providerCampTables = [];
    this.providerCampTablesList = [];
    this.providerCampTablesProviders = [];
    this.providerCampTablesLoaded = [];

    this.providerAdgTables = [];
    this.providerAdgTablesList = [];
    this.providerAdgTablesProviders = [];
    this.providerAdgTablesLoaded = [];

    this.providerCampaignAdgroupTable = [];
    this.providerCampaignAdgroupStructure = null;
    this.providerCampaignAdgroupTableNetworking = false;

    this.providerAllTabTable = [];
    this.providerAllTabStructure = null;
    this.providerAllTabTableNetworking = false;

    this.categoryAllTables = [];
    this.categoryAllTablesList = [];
    this.categoryAllTablesLoaded = [];

    this.providerDimensionTables = [];
    this.providerDimensionTablesList = [];
    this.providerDimensionTablesProviders = [];
    this.providerDimensionTablesLoaded = [];


    this.cuuidList = [];

    this.process = new ScreenBarValue2();
    this.loadCountDown = 3;
    this.ActiveUuidAdgroupTable = uuid();
    this.ActiveUuidCampaignAdgroupTable = uuid();
    this.ActiveUuidCategoryMonthlyReport = uuid();

    this.limitmode = 0;

    this.invisibleColumnStocker = new InvisibleColumnStocker();
    this.columnStyleStocker = new ColumnStyleStocker();

    this.cuuid_adcam = "";
    this.adproDCCAdGroupMonthlyHolder = new AdproDCCAdGroupMonthlyHolder()
}
AdProviderPage.prototype.updateload = function (callback) {
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    const cuuid = getInformationLatestUpdate(this.advertizerId, request, function (uuid, data) {
        if (cuuid == uuid) {
            try {
                let json = JSON.parse(JSON.stringify(data.result));
                if (json?.latestupdate?.date) {
                    if (callback) {
                        try{
                            callback(data2dateandweek(json.latestupdate.date));
                        }catch(ex) {
                            console.log(ex);
                        }
                    }
                }
            } catch (ex) {
                console.log(ex);
            }
        }
    });
};
AdProviderPage.prototype.setLimitMode = function (mode) {
    this.limitmode = mode;
};
AdProviderPage.prototype.getSelectedModeString = function () {
    switch (this.selectedMode) {
        case 1:
            return "monthly";
        case 2:
            return "weekly";
        case 3:
            return "daily";
    }
    return "";
};
AdProviderPage.prototype.comLoaded = function () {
    this.loadCountDown--;
    if (this.loadCountDown <= 0) {
        this.process.stop();
    }
};
AdProviderPage.prototype.initialize = function (pageId, advertizerId, timeRange, user, colorMaster) {
    this.cuuidList = [];

    this.currentPageStreams = [];

    this.providerTables = [];
    this.providerTablesList = [];
    this.providerTablesProviders = [];
    this.providerTablesLoaded = [];

    this.providerCampTables = [];
    this.providerCampTablesList = [];
    this.providerCampTablesProviders = [];
    this.providerCampTablesLoaded = [];

    this.providerAdgTables = [];
    this.providerAdgTablesList = [];
    this.providerAdgTablesProviders = [];
    this.providerAdgTablesLoaded = [];

    this.providerCampaignAdgroupTable = [];
    this.providerCampaignAdgroupStructure = null;
    this.providerCampaignAdgroupTableNetworking = false;

    this.providerAllTabTable = [];
    this.providerAllTabStructure = null;
    this.providerAllTabTableNetworking = false;

    this.providerDimensionTables = [];
    this.providerDimensionTablesList = [];
    this.providerDimensionTablesProviders = [];
    this.providerDimensionTablesLoaded = [];
    this.activeYmdUidM = "";
    this.activeYmdUidW = "";
    this.activeYmdUidD = "";
    this.dayOfWeekMaster = [];


    this.loadCountDown = 3;
    this.process.start();

    this.pageId = pageId;
    this.timeRange = timeRange;
    getAdvertizerValueSettings_advertizerId = advertizerId;
    this.advertizerId = advertizerId;
    this.tabs = [];
    this.user = user;
    this.currentMonth = this.timeRange.getTimeRequest().actualMonth.replace("-", "/");
    this.colorMaster = colorMaster;
    let self = this;
    self.tab.cells = [];
    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.advertizerId = this.advertizerId;

    const cuuid = getTabListOfAdProviderReportPage(pageId, request, function (uuid, data) {
        if (self.cuuidList.includes(uuid)) {
            let json = JSON.parse(JSON.stringify(data.result));
            self.providerTablesProviders = [];
            for (let a of json.adproviderList) {
                self.providerTablesProviders.push({ label: a.tab, id: a.adproviderid, name: a.provider });
            }
        }
    }, function (uuid) {
        if (self.ActiveUuidAdgroupTable == uuid) {
            self.comLoaded();
        }
    });
    self.ActiveUuidAdgroupTable = cuuid;
    self.cuuidList.push(cuuid);

    this.invisibleColumnStocker.initialize(this.advertizerId, "adprovider");
    this.columnStyleStocker.initialize(this.advertizerId, "adprovider");

};
AdProviderPage.prototype.refreshCampaignAdgroupTable = function (providerId) {
    let self = this;
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': `Bearer ${this.user.auth0.token}`
    };
    let uuidStr = uuid();
    self.cuuidList.push(uuidStr);
    let request = new GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdproviderid(providerId);
    request.setAdvertizerid(this.advertizerId);
    let timeRequest = this.timeRange.getTimeRequest();
    if (!timeRequest.isRange()) {
        request.setActualmonth(timeRequest.actualMonth);
    } else {
        request.setStartdate(timeRequest.startDate);
        request.setEnddate(timeRequest.endDate);
    }

    this.ActiveUuidCampaignAdgroupTable = uuidStr;
    this.providerCampaignAdgroupStructure = null;
    this.providerCampaignAdgroupTable = [];
    this.providerCampaignAdgroupTableNetworking = true;

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderDimensionCampaignAdGroupMonthlyReport(request, meta);
    this.currentPageStreams.push(stream);
    let fcuuid_adcam = uuid();
    this.cuuid_adcam = fcuuid_adcam;
    stream.on('data', message => {
        if (!self.cuuidList.includes(uuidStr)) return;
        if (message["array"].length == 0) return;
        message = message.toObject();

        if (self.cuuid_adcam != fcuuid_adcam) {
            return;
        }
        let json = JSON.parse(JSON.stringify(message.result));
        if (!!json.structure != false) {
            let structure = JSON.parse(json.structure);
            self.providerCampaignAdgroupStructure = structure;
            return;
        } else {
            self.providerCampaignAdgroupTable.push({
                "campaign": new Cell(message.result.campaignreportList[0].campaign, "string"),
                "adgroupList": message.result.campaignreportList[0].adgroupreportList.map(adgroup => {
                    return {
                        "adgroup": new Cell(adgroup.adgroup, "string"),
                        "row": self.providerCampaignAdgroupStructure.chart.map(s =>
                            adgroup.tableList.filter(t => t.key == s.backendKey).map(t => new Cell(t.value, s.type))[0]
                        )
                    };
                })
            });
            return;
        }
    });
    stream.on('end', () => {
        function _getCategoryNameByCampaignName(campname) {
            if (campname) {
                let startTag = '<';
                let endTag = '>';
                if (self.advertizerId == 4) {
                    startTag = '(';
                    endTag = ')';
                }
                let sas = campname.split(startTag);
                if (sas?.length > 1) {
                    let sbs = sas[1].split(endTag);
                    if (sbs?.length > 0) {
                        return sbs[0];
                    }
                }
            }

            return '';
        }

        if (self.ActiveUuidCampaignAdgroupTable == uuidStr) {
            self.providerCampaignAdgroupTable.sort(function (a, b) {
                if (_getCategoryNameByCampaignName(a.campaign.data) > _getCategoryNameByCampaignName(b.campaign.data)) {
                    return -1;
                } else if (_getCategoryNameByCampaignName(a.campaign.data) < _getCategoryNameByCampaignName(b.campaign.data)) {
                    return 1;
                } else {
                    return 0;
                }
            })
            self.comLoaded();
        }
        self.providerCampaignAdgroupTableNetworking = false;
    });
    stream.on('error', (e) => {
        if (self.ActiveUuidCampaignAdgroupTable == uuidStr) {
            self.comLoaded();
        }
        console.log('Error returned from BFF.');
        throw e;
    });
};
AdProviderPage.prototype.cancelAllAPICall = function () {
    this.currentPageStreams.forEach(stream => stream.cancel());
}
AdProviderPage.prototype.providerTablesInitialize = function (pageId, advertizerId, timeRange, user) {
    let self = this;
    let request = {
        sortItem: "",
        sort: "",
        token: user.auth0.token,
        userUuid: user.userUuid,
    };
    request.advertizerId = this.advertizerId;

    // init provider all tables
    self.cuuidList.push(getTabListOfAdProviderReportPage(pageId, request, function (uuid, data) {
        if (self.cuuidList.includes(uuid)) {
            let json = JSON.parse(JSON.stringify(data.result));
            json.adproviderList.forEach(provider => {
                if (self.providerTables[provider.provider] == undefined) self.providerTables[provider.provider] = [];
                if (self.providerCampTables[provider.provider] == undefined) self.providerCampTables[provider.provider] = [];
                if (self.providerAdgTables[provider.provider] == undefined) self.providerAdgTables[provider.provider] = [];
                if (self.providerDimensionTables[provider.provider] == undefined) self.providerDimensionTables[provider.provider] = [];
                self.dayOfWeekMaster = [];

                let request2 = {
                    sortItem: "",
                    sort: "",
                    adProviderId: provider.adproviderid,
                    token: user.auth0.token,
                    userUuid: user.userUuid,
                };
                request2.timeRequest = timeRange.getTimeRequest();
                request2.advertizerId = advertizerId;
                const mntuuid = getConsultantAdProviderCategoryMonthlyReport(request2, function (uuid, data, stream_idx) {
                    if (data.error) {
                        console.error(data.error);
                        return;
                    }
                    if (self.cuuidList.includes(uuid) && data != null) {
                        let json = JSON.parse(JSON.stringify(data.result));
                        if (!!json.structure != false) {
                            let structure = JSON.parse(json.structure);
                            if (self.providerTables.structure == undefined) self.providerTables.structure = structure;
                        } else if (
                            Object.entries(json.categoryreportList).length != 0
                        ) {
                            let report = json.categoryreportList[0];
                            if (self.providerTables[provider.provider][report.name] == undefined) self.providerTables[provider.provider][report.name] = [];
                            if (self.providerTables[provider.provider][report.name]['monthly'] == undefined) self.providerTables[provider.provider][report.name]['monthly'] = [];
                            report.monthlyreportList
                                .forEach(table => self.providerTables[provider.provider][report.name]['monthly'].push(table));
                            self.providerTablesList.push({ provider: provider.provider, category: report.name, category_idx: stream_idx, range: 'monthly' });
                        }
                    }
                }, () => {
                    self.providerTablesLoaded.push({
                        providerName: provider.provider,
                        range: 'monthly'
                    });
                    if (self.providerTablesProviders.length > 0) {
                        if (self.isProviderTableNetworking(self.providerTablesProviders[0].name, 'monthly') == false) {
                            self.comLoaded();
                        }
                    }
                }, "consultant", self.currentPageStreams);
                self.ActiveUuidCategoryMonthlyReport = mntuuid;
                self.cuuidList.push(mntuuid);
                self.cuuidList.push(getConsultantAdProviderCategoryWeeklyReport(request2, function (uuid, data, stream_idx) {
                    if (data.error) {
                        console.error(data.error);
                        return;
                    }
                    if (self.cuuidList.includes(uuid) && data != null) {
                        let json = JSON.parse(JSON.stringify(data.result));
                        if (!!json.structure != false) {
                            //let structure = JSON.parse(json.structure);
                            //if(self.providerTables.structure == undefined) self.providerTables.structure = structure; // Monthly の structureのみ利用する。
                        } else if (
                            Object.entries(json.categoryreportList).length != 0
                        ) {
                            let report = json.categoryreportList[0];
                            if (self.providerTables[provider.provider][report.name] == undefined) self.providerTables[provider.provider][report.name] = [];
                            if (self.providerTables[provider.provider][report.name]['weekly'] == undefined) self.providerTables[provider.provider][report.name]['weekly'] = [];
                            report.weeklyreportList
                                .forEach(table => self.providerTables[provider.provider][report.name]['weekly'].push(table));
                            self.providerTablesList.push({ provider: provider.provider, category: report.name, category_idx: stream_idx, range: 'weekly' });
                        }
                    }
                }, () => {
                    self.providerTablesLoaded.push({
                        providerName: provider.provider,
                        range: 'weekly'
                    });
                }, "consultant", self.currentPageStreams));
                self.cuuidList.push(getConsultantAdProviderCategoryDailyReport(request2, function (uuid, data, stream_idx) {

                    if (data.error) {
                        console.error(data.error);
                        return;
                    }
                    if (self.cuuidList.includes(uuid) && data != null) {
                        let json = JSON.parse(JSON.stringify(data.result));
                        if (!!json.structure != false) {
                            //let structure = JSON.parse(json.structure);
                            //if(self.providerTables.structure == undefined) self.providerTables.structure = structure;
                        } else if (
                            Object.entries(json.categoryreportList).length != 0
                        ) {
                            let report = json.categoryreportList[0];
                            if (self.providerTables[provider.provider][report.name] == undefined) self.providerTables[provider.provider][report.name] = [];
                            if (self.providerTables[provider.provider][report.name]['daily'] == undefined) self.providerTables[provider.provider][report.name]['daily'] = [];

                            // dow
                            report.dailyreportList.forEach((x) => {
                                if (self.dayOfWeekMaster.filter(rx=>rx.name == x.name).length == 0) {
                                    self.dayOfWeekMaster.push({
                                        name: x.name,
                                        dayOfWeek: x.dayofweek
                                    });
                                }
                            });

                            report.dailyreportList
                                .forEach(table => self.providerTables[provider.provider][report.name]['daily'].push(table));
                            self.providerTablesList.push({ provider: provider.provider, category: report.name, category_idx: stream_idx, range: 'daily' });
                        }
                    }
                }, () => {
                    self.providerTablesLoaded.push({
                        providerName: provider.provider,
                        range: 'daily'
                    });
                }, "consultant", self.currentPageStreams));


                self.cuuidList.push(getConsultantAdProviderCampaignMonthlyReport(request2, function (uuid, data, stream_idx) {
                    if (data.error) {
                        console.error(data.error);
                        return;
                    }
                    if (self.cuuidList.includes(uuid) && data != null) {
                        let json = JSON.parse(JSON.stringify(data.result));
                        if (!!json.structure != false) {
                            let structure = JSON.parse(json.structure);
                            if (self.providerCampTables.structure == undefined) self.providerCampTables.structure = structure;
                        } else if (
                            Object.entries(json.reportList).length != 0
                        ) {
                            let report = json.reportList[0];
                            if (self.providerCampTables[provider.provider][report.name] == undefined) self.providerCampTables[provider.provider][report.name] = [];
                            if (self.providerCampTables[provider.provider][report.name]['monthly'] == undefined) self.providerCampTables[provider.provider][report.name]['monthly'] = [];
                            report.monthlyreportList
                                .forEach(table => self.providerCampTables[provider.provider][report.name]['monthly'].push(table));
                            self.providerCampTablesList.push({ provider: provider.provider, category: report.name, category_idx: stream_idx, range: 'monthly' });
                        }
                    }
                }, () => {
                    self.providerCampTablesLoaded.push({
                        providerName: provider.provider,
                        range: 'monthly'
                    });
                    // if (self.providerCampTablesProviders.length > 0) {
                    //     if (self.isProviderCampTableNetworking(self.providerCampTablesProviders[0].name, 'monthly') == false) {
                    //         self.comLoaded();
                    //     }
                    // }
                    self.comLoaded();
                }, "consultant", self.currentPageStreams));
                self.cuuidList.push(getConsultantAdProviderCampaignWeeklyReport(request2, function (uuid, data, stream_idx) {

                    if (data.error) {
                        console.error(data.error);
                        return;
                    }
                    if (self.cuuidList.includes(uuid) && data != null) {
                        let json = JSON.parse(JSON.stringify(data.result));
                        if (!!json.structure != false) {
                            // let structure = JSON.parse(json.structure);
                            // if (self.providerCampTables.structure == undefined) self.providerCampTables.structure = structure;
                        } else if (
                            Object.entries(json.reportList).length != 0
                        ) {
                            let report = json.reportList[0];
                            if (self.providerCampTables[provider.provider][report.name] == undefined) self.providerCampTables[provider.provider][report.name] = [];
                            if (self.providerCampTables[provider.provider][report.name]['weekly'] == undefined) self.providerCampTables[provider.provider][report.name]['weekly'] = [];
                            report.weeklyreportList
                                .forEach(table => self.providerCampTables[provider.provider][report.name]['weekly'].push(table));
                            self.providerCampTablesList.push({ provider: provider.provider, category: report.name, category_idx: stream_idx, range: 'weekly' });
                        }
                    }

                }, () => {
                    self.providerCampTablesLoaded.push({
                        providerName: provider.provider,
                        range: 'weekly'
                    });
                }, "consultant", self.currentPageStreams));
                self.cuuidList.push(getConsultantAdProviderCampaignDailyReport(request2, function (uuid, data, stream_idx) {

                    if (data.error) {
                        console.error(data.error);
                        return;
                    }
                    if (self.cuuidList.includes(uuid) && data != null) {
                        let json = JSON.parse(JSON.stringify(data.result));
                        if (!!json.structure != false) {
                            // let structure = JSON.parse(json.structure);
                            // if (self.providerCampTables.structure == undefined) self.providerCampTables.structure = structure;
                        } else if (
                            Object.entries(json.reportList).length != 0
                        ) {
                            let report = json.reportList[0];
                            if (self.providerCampTables[provider.provider][report.name] == undefined) self.providerCampTables[provider.provider][report.name] = [];
                            if (self.providerCampTables[provider.provider][report.name]['daily'] == undefined) self.providerCampTables[provider.provider][report.name]['daily'] = [];

                            // dow
                            report.dailyreportList.forEach((x) => {
                                if (self.dayOfWeekMaster.filter(rx=>rx.name == x.name).length == 0) {
                                    self.dayOfWeekMaster.push({
                                        name: x.name,
                                        dayOfWeek: x.dayofweek
                                    });
                                }
                            });

                            report.dailyreportList
                                .forEach(table => self.providerCampTables[provider.provider][report.name]['daily'].push(table));
                            self.providerCampTablesList.push({ provider: provider.provider, category: report.name, category_idx: stream_idx, range: 'daily' });
                        }
                    }
                }, () => {
                    self.providerCampTablesLoaded.push({
                        providerName: provider.provider,
                        range: 'daily'
                    });
                }, "consultant", self.currentPageStreams));




            });
        }
    }));
    let request3 = {
        sortItem: "",
        sort: "",
        token: user.auth0.token,
        userUuid: user.userUuid,
    };
    request3.timeRequest = timeRange.getTimeRequest();
    request3.advertizerId = advertizerId;

    self.providerAllTabTable = [];
    self.providerAllTabStructure = null;

    let request10 = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request10.timeRequest = this.timeRange.getTimeRequest();
    request10.advertizerId = this.advertizerId;
    request10.providerId = null;
    const ccat_uuid = getConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport(request10, function (uuid, data) {
        if (data.error) {
            console.error(data.error);
            return;
        }
        let json = JSON.parse(JSON.stringify(data.result));
        if (ccat_uuid == uuid) {
            if (!!json.structure != false) {
                let structure = JSON.parse(json.structure);
                self.providerAllTabStructure = structure;

            } else if (Object.entries(json.reportList).length != 0) {
                let dmax = 2;
                if (self.getProviderAllCategoryLength() == 2) {
                    dmax = 3;
                }

                self.providerAllTabTable = data.result.reportList.filter(x => x.dimensionList.length <= dmax).map(list => {
                    return {
                        "dimensionList": list.dimensionList,
                        "name": new Cell(list.dimensionList[0].value, "string"),
                        "row": self.providerAllTabStructure.chart.map(s =>
                            list.tableList.filter(t => t.key == s.backendKey).map(t => new Cell(t.value, s.type))[0]
                        )
                    };
                });

            }
        }
    }, () => {
        //

    }, "consultant", self.currentPageStreams)


    this.categoryAllTables = [];
    this.categoryAllTablesList = [];

    const apcmuid = getConsultantAdProviderCategoryMonthlyReport(request3, function (uuid, data, stream_idx) {
        if (data.error) {
            console.log(stream_idx);
            console.error(data.error);
            return;
        }

        if ((apcmuid == uuid) && data != null) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!json.structure != false) {
                let structure = JSON.parse(json.structure);
                if (self.categoryAllTables.structure == undefined) self.categoryAllTables.structure = structure;
            } else {
                let report = json.categoryreportList[0];
                if (self.categoryAllTables[report.name] == undefined) self.categoryAllTables[report.name] = [];
                if (self.categoryAllTables[report.name]['monthly'] == undefined) self.categoryAllTables[report.name]['monthly'] = [];

                report.monthlyreportList.forEach(table => {
                    self.categoryAllTables[report.name]['monthly'].push(table)
                });
            }
        }
    }, () => {
        //
    }, "consultant", self.currentPageStreams);

    const apcwuid = getConsultantAdProviderCategoryWeeklyReport(request3, function (uuid, data, stream_idx) {
        if (data.error) {
            console.log(stream_idx);
            console.error(data.error);
            return;
        }

        if ((apcwuid == uuid) && data != null) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!json.structure != false) {
                // let structure = JSON.parse(json.structure);
                // if (self.categoryAllTables.structure == undefined) self.categoryAllTables.structure = structure;
            } else {
                let report = json.categoryreportList[0];
                if (self.categoryAllTables[report.name] == undefined) self.categoryAllTables[report.name] = [];
                if (self.categoryAllTables[report.name]['weekly'] == undefined) self.categoryAllTables[report.name]['weekly'] = [];

                report.weeklyreportList.forEach(table => {
                    self.categoryAllTables[report.name]['weekly'].push(table)
                });
            }
        }
    }, () => {
        //
    }, "consultant", self.currentPageStreams);

    const apcduid = getConsultantAdProviderCategoryDailyReport(request3, function (uuid, data, stream_idx) {
        if (data.error) {
            console.log(stream_idx);
            console.error(data.error);
            return;
        }

        if ((apcduid == uuid) && data != null) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!json.structure != false) {
                // let structure = JSON.parse(json.structure);
                // if (self.categoryAllTables.structure == undefined) self.categoryAllTables.structure = structure;
            } else {
                let report = json.categoryreportList[0];
                if (self.categoryAllTables[report.name] == undefined) self.categoryAllTables[report.name] = [];
                if (self.categoryAllTables[report.name]['daily'] == undefined) self.categoryAllTables[report.name]['daily'] = [];

                // dow
                report.dailyreportList.forEach((x) => {
                    if (self.dayOfWeekMaster.filter(rx=>rx.name == x.name).length == 0) {
                        self.dayOfWeekMaster.push({
                            name: x.name,
                            dayOfWeek: x.dayofweek
                        });
                    }
                });

                report.dailyreportList.forEach(table => {
                    self.categoryAllTables[report.name]['daily'].push(table)
                });
            }
        }
    }, () => {
        //
    }, "consultant", self.currentPageStreams);

};
AdProviderPage.prototype.getProviderTableProviderList = function () {
    return this.providerTablesProviders;
};
AdProviderPage.prototype.getProviderCampTableProviderList = function () {
    return this.providerCampTablesProviders;
};
AdProviderPage.prototype.getProviderTableCategoryList = function (provider, range, target = this.providerTablesList) {
    return target?.filter(l => l.provider == provider && l.range == range)
        .sort((a, b) => a.category_idx - b.category_idx)
        .map(l => l.category);
}
AdProviderPage.prototype.getProviderCampTableCategoryList = function (provider, range) {
    return this.getProviderTableCategoryList(provider, range, this.providerCampTablesList);
};
AdProviderPage.prototype.getProviderAdgTableCategoryList = function (provider, range) {
    return this.getProviderTableCategoryList(provider, range, this.providerAdgTablesList);
};
AdProviderPage.prototype.getProviderDimensionTableCategoryList = function (provider, range) {
    return this.getProviderTableCategoryList(provider, range, this.providerDimensionTablesList);
};
AdProviderPage.prototype.getProviderTableCategory = function (provider, category_name, range) {
    let cs = this.getProviderTableCategoryList(provider, range);
    for (let i in cs) {
        let c = cs[i];
        if (c == category_name) {
            return this.getProviderTable(provider, c, range);
        }
    }
    // this.getProviderTable(provider, range);
};
AdProviderPage.prototype.getProviderCampTableCategory = function (provider, camp_name, range) {
    let cs = this.getProviderCampTableCategoryList(provider, range);
    for (let i in cs) {
        let c = cs[i];
        if (c == camp_name) {
            return this.getProviderCampTable(provider, c, range);
        }
    }
    // this.getProviderTable(provider, range);
};
AdProviderPage.prototype.getProviderAdgTableCategory = function (provider, camp_name, range) {
    let cs = this.getProviderAdgTableCategoryList(provider, range);
    for (let i in cs) {
        let c = cs[i];
        if (c == camp_name) {
            return this.getProviderAdgTable(provider, c, range);
        }
    }
    // this.getProviderTable(provider, range);
};
AdProviderPage.prototype.getProviderDimensionTableCategory = function (provider, camp_name, range) {
    let cs = this.getProviderDimensionTableCategoryList(provider, range);
    for (let i in cs) {
        let c = cs[i];
        if (c == camp_name) {
            return this.getProviderDimensionTable(provider, c, range);
        }
    }
    // this.getProviderTable(provider, range);
};
AdProviderPage.prototype.getProviderTableHeader = function (target = this.providerTables) {
    if (target['structure'] != undefined) {
        return [new Cell('種別', "string")].concat(target.structure.chart.map(c => new Cell(c.label, "string")));
    }
};
AdProviderPage.prototype.getProviderCampTableHeader = function () {
    return this.getProviderTableHeader(this.providerCampTables);
};
AdProviderPage.prototype.getProviderAdgTableHeader = function () {
    return this.getProviderTableHeader(this.providerAdgTables);
};
AdProviderPage.prototype.getProviderDimensionTableHeader = function () {
    return this.getProviderTableHeader(this.providerDimensionTables);
};
AdProviderPage.prototype.isProviderTableNetworking = function (providerName, range) {
    if (this.providerTablesLoaded.filter(loaded => loaded.providerName == providerName && loaded.range == range).length == 0)
        return true;
    return false;
}
AdProviderPage.prototype.isProviderCampTableNetworking = function (providerName, range) {
    if (this.providerCampTablesLoaded.filter(loaded => loaded.providerName == providerName && loaded.range == range).length == 0)
        return true;
    return false;
}
AdProviderPage.prototype.isProviderAdgTableNetworking = function (providerName, range) {
    if (this.providerAdgTablesLoaded.filter(loaded => loaded.providerName == providerName && loaded.range == range).length == 0)
        return true;
    return false;
}
AdProviderPage.prototype.isProviderDimensionTableNetworking = function (providerName, range) {
    if (this.providerDimensionTablesLoaded.filter(loaded => loaded.providerName == providerName && loaded.range == range).length == 0)
        return true;
    return false;
}
AdProviderPage.prototype.getProviderTable = function (providerName, category, range, targetTable = this.providerTables) { // 全マップするより、データアクセッサーとしてAdapterを被せた方がよかったかも。
    if (targetTable[providerName] != undefined &&
        targetTable[providerName][category] != undefined &&
        targetTable[providerName][category][range] != undefined &&
        targetTable.structure != undefined) {
        let ts = targetTable[providerName][category][range]
            .filter(row => (range == 'monthly' && row.name.indexOf("20") == 0)
                || (range == 'daily')
                || (range == 'weekly')) // dailyデータは全スルーで通す。
            .sort((row1, row2) => {
                if (row1.name.includes("着地想定")) return row1.name.includes(row2.name) ? -1 : 1;
                if (row2.name.includes("着地想定")) return row2.name.includes(row1.name) ? 1 : -1;
                return row1.name > row2.name ? 1 : -1
            })
            .map(row => { // structureにバインドしていく。
                return [new Cell(row.name, "string")]
                    .concat(targetTable.structure.chart
                        .map(c => {
                            let a = row.tableList
                                .filter(t => t.key == c.backendKey)
                                .map(t => new Cell(t.value, c.type))
                                .concat([new Cell("null", "string")])[0]
                            if (c.backendKey.includes("roas")) return new Cell(a.data, "rate_roas");
                            return a;
                        }))
            });

        let rs = [];
        //
        for (let i = 0; i < ts.length; i++) {
            if (this.limitmode == 1 && range == "monthly") {
                if (i > (ts.length - 7)) {
                    rs.push(ts[i]);
                }
            } else if (this.limitmode == 1 && range == "weekly") {
                if (i > (ts.length - 13)) {
                    rs.push(ts[i]);
                }
            } else if (this.limitmode == 1 && range == "daily") {
                // let year = this.timeRange.getTimeRequest().actualMonth.slice(0, 4);
                let month = this.timeRange.getTimeRequest().actualMonth.slice(5);

                ts = ts.filter(function (row) {
                    return (parseInt(row[0].data.split("/")[1]) == parseInt(month));
                });
                rs = ts;
            } else {
                rs.push(ts[i]);
            }
        }
        return rs;
    }
};
AdProviderPage.prototype.getProviderCampTable = function (providerName, category, range) {
    return this.getProviderTable(providerName, category, range, this.providerCampTables);
};
AdProviderPage.prototype.getProviderAdgTable = function (providerName, category, range) {
    return this.getProviderTable(providerName, category, range, this.providerAdgTables);
};
AdProviderPage.prototype.getProviderDimensionTable = function (providerName, category, range) {
    return this.getProviderTable(providerName, category, range, this.providerDimensionTables);
};

AdProviderPage.prototype.getProviderTableCellDesign = function (data, table, index_col, targetTable = this.providerTables) {
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
        border: null
    };

    if (index_col == 0) return ret; // 種別
    ret.isColored = true;

    let keyList = ["種別"].concat(targetTable['structure'].chart.map(c => c.backendKey));

    //if (getAdvertizerValueSettings(ks[index - 1].backendKey) == null) return ret; 

    let numList = table.map(row => Number(row[index_col].data));
    if (keyList[index_col].includes('cpa')) numList = numList.filter(val => val != 0);
    if (keyList[index_col].includes('roas')) numList = numList.filter(val => val != 0);
    let max = numList.length != 0 ? numList.reduce((a, b) => a > b ? a : b) : 0;
    let min = numList.length != 0 ? numList.reduce((a, b) => a < b ? a : b) : 0;

    // define color 
    switch (getAdvertizerValueSettings(keyList[index_col])) {
        case "colored_ctr":
            ret.color = '#BCECFE';
            break;
        case "colored_cpc":
            ret.color = '#FFBF00';
            break;
        case "colored_cost":
            ret.color = '#FF579B';
            break;
        case "colored_cv":
            ret.color = '#34B7FD';
            break;
        case "colored_cvr":
            ret.color = '#A6E7FF';
            break;
        case "colored_cpa": {
            let colScale = Array.from(colScale3);
            ret.color = colScale
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
        case "colored_roas": {
            let colScale = Array.from(colScale3);
            ret.color = colScale.reverse()
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
    }

    // define percent
    let r = data / max * 100;
    r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
    if (r > 100) {
        r = 100;
    }
    switch (getAdvertizerValueSettings(keyList[index_col])) {
        case "colored_ctr":
            ret.percent = r; break;
        case "colored_cpc":
            ret.percent = r; break;
        case "colored_cost":
            ret.percent = r; break;
        case "colored_cv":
            ret.percent = r; break;
        case "colored_cvr":
            ret.percent = r; break;
        case "colored_cpa":
            ret.percent = 100; break;
        case "colored_roas":
            ret.percent = 100; break;
    }

    switch (getAdvertizerValueSettings(keyList[index_col])) {
        case "colored_ctr":
            ret.design = "bar"; break;
        case "colored_cpc":
            ret.design = "bar"; break;
        case "colored_cost":
            ret.design = "bar_gradation"; break;
        case "colored_cv":
            ret.design = "bar_gradation"; break;
        case "colored_cvr":
            ret.design = "bar"; break;
        case "colored_cpa":
            ret.design = "box"; break;
        case "colored_roas":
            ret.design = "box"; break;
    }


    let cd = this.colorMaster.getDesignByKey(keyList[index_col]);
    if (cd == null) {
        return ret;
    }
    if (cd.type == "bar" && cd.colors.length > 0) {
        ret.design = "bar";
        ret.color = '#FFFFFF';
        ret.percent = r;
        ret.color = cd.colors[0];
    }
    if (cd.type == "scale" && cd.colors.length > 0) {
        ret.design = "box";
        ret.percent = 100;

        let colScales = cd.colors;

        let colScale = Array.from(colScales);
        ret.color = colScale.reverse()
            .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
            .reduce((colVal1, colVal2) =>
                Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                    colVal2
                    : colVal1
            )[0];
        if (data == 0) ret.color = "";

    }

    return ret;
};
AdProviderPage.prototype.getProviderCampTableCellDesign = function (data, table, index_col) {
    return this.getProviderTableCellDesign(data, table, index_col, this.providerCampTables);
};
AdProviderPage.prototype.getProviderAdgTableCellDesign = function (data, table, index_col) {
    return this.getProviderTableCellDesign(data, table, index_col, this.providerAdgTables);
};
AdProviderPage.prototype.getProviderDimensionTableCellDesign = function (data, table, index_col) {
    return this.getProviderTableCellDesign(data, table, index_col, this.providerDimensionTables);
};
AdProviderPage.prototype.getProviderCampaignAdgroupTableHeader = function () {
    if (this.providerCampaignAdgroupStructure == null) return [];
    return [new Cell("キャンペーン", "string"), new Cell("広告グループ", "string")]
        .concat(this.providerCampaignAdgroupStructure?.chart.map(c => new Cell(c.label, "string")));
}
AdProviderPage.prototype.getProviderCampaignAdgroupTable = function () {
    // ROAS小数点切捨て
    this?.providerCampaignAdgroupStructure?.chart.forEach((key, i) => {
        if (key.backendKey.includes('roas')) this.providerCampaignAdgroupTable.flatMap(ar => ar.adgroupList).map(p => p.row).forEach(row => {
            row[i].dataType = "rate_roas";
        });
    });
    return this.providerCampaignAdgroupTable;
}
AdProviderPage.prototype.getProviderCampaignAdgroupTableCellDesign = function (data, index_col, index_campaign, index_adgroup) {
    data = Number(data);
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
        border: null
    };
    ret.isColored = true;

    let keyList = this.providerCampaignAdgroupStructure?.chart.map(c => c.backendKey);

    if (index_adgroup == 0)
        if (["colored_cv", "colored_cpa", "colored_roas"].includes(getAdvertizerValueSettings(keyList[index_col])))
            return ret;

    //if (getAdvertizerValueSettings(ks[index - 1].backendKey) == null) return ret; 

    let numList = this.providerCampaignAdgroupTable.flatMap(campaign =>
        campaign.adgroupList.filter((e, i) => i != 0) // ALL 除外
            .map(adgroup => Number(adgroup.row[index_col].data)))
    //table.map(row => Number(row[index_col].data));
    if (keyList[index_col].includes('cpa')) numList = numList.filter(val => val != 0);
    if (keyList[index_col].includes('roas')) numList = numList.filter(val => val != 0);
    let max = numList.length != 0 ? numList.reduce((a, b) => a > b ? a : b) : 0;
    let min = numList.length != 0 ? numList.reduce((a, b) => a < b ? a : b) : 0;

    // define color 
    switch (getAdvertizerValueSettings(keyList[index_col])) {
        case "colored_cpc":
            //ret.color = '#E3F2FD';break;
            break;
        case "colored_cost":
            //ret.color =  'linear-gradient(to right, #FF595E, 50%, #FEE3E4)';
            //ret.border = '1px solid #FF595E';break;
            break;
        case "colored_cv":
            ret.color = '#34B7FD';
            ret.border = '1px solid #068DEF'; break;
        case "colored_cvr":
            //ret.color = '#F9E9DA';break;
            break;
        case "colored_cpa": {
            let colScale = Array.from(colScale3);
            ret.color = colScale
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
        case "colored_roas": {
            let colScale = Array.from(colScale3);
            ret.color = colScale.reverse()
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
    }

    // define percent
    let r = data / max * 100;
    r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
    if (r > 100) {
        r = 100;
    }
    switch (getAdvertizerValueSettings(keyList[index_col])) {
        case "colored_cpc":
            ret.percent = r; break;
        case "colored_cost":
            ret.percent = r; break;
        case "colored_cv":
            ret.percent = r; break;
        case "colored_cvr":
            ret.percent = r; break;
        case "colored_cpa":
            ret.percent = 100; break;
        case "colored_roas":
            ret.percent = 100; break;
    }

    switch (getAdvertizerValueSettings(keyList[index_col])) {
        case "colored_cpc":
            ret.design = "bar"; break;
        case "colored_cost":
            ret.design = "bar_gradation"; break;
        case "colored_cv":
            ret.design = "bar_gradation"; break;
        case "colored_cvr":
            ret.design = "bar"; break;
        case "colored_cpa":
            ret.design = "box"; break;
        case "colored_roas":
            ret.design = "box"; break;
    }

    return ret;
}

AdProviderPage.prototype.loadConsultantAdProviderAdGroupReport = function (providerId, providerName, campname) {
    let self = this;

    let request = {
        sortItem: "",
        sort: "",
        adProviderId: providerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.timeRequest = this.timeRange.getTimeRequest();
    request.advertizerId = this.advertizerId;
    request.campaign = campname;
    self.providerAdgTables[providerName] = [];

    if (this.providerAdgTablesLoaded?.length > 0) {
        for (let i = this.providerAdgTablesLoaded.length - 1 ; i >=0 ; i--) {
            if (this.providerAdgTablesLoaded[i].providerName == providerName) {
                this.providerAdgTablesLoaded.splice(i, 1);
            }
        }
    }

    const caamr_uuid = getConsultantAdProviderAdGroupMonthlyReport(request, function (uuid, data, stream_idx) {
        if (data.error) {
            console.error(data.error);
            return;
        }
        let json = JSON.parse(JSON.stringify(data.result));
        if (caamr_uuid == uuid) {
            if (!!json.structure != false) {
                let structure = JSON.parse(json.structure);
                if (self.providerAdgTables.structure == undefined) self.providerAdgTables.structure = structure;
            } else if (Object.entries(json.reportList).length != 0) {
                let report = json.reportList[0];
                if (self.providerAdgTables[providerName][report.name] == undefined) self.providerAdgTables[providerName][report.name] = [];
                if (self.providerAdgTables[providerName][report.name]['monthly'] == undefined) self.providerAdgTables[providerName][report.name]['monthly'] = [];

                report.monthlyreportList
                    .forEach(table => self.providerAdgTables[providerName][report.name]['monthly'].push(table));
                self.providerAdgTablesList.push({ provider: providerName, category: report.name, category_idx: stream_idx, range: 'monthly' });
            }
        }
    }, () => {
        self.providerAdgTablesLoaded.push({
            providerName: providerName,
            range: 'monthly'
        });

    }, "consultant", self.currentPageStreams)

    const caawr_uuid = getConsultantAdProviderAdGroupWeeklyReport(request, function (uuid, data, stream_idx) {
        if (data.error) {
            console.error(data.error);
            return;
        }
        let json = JSON.parse(JSON.stringify(data.result));
        if (caawr_uuid == uuid) {
            if (!!json.structure != false) {
                // let structure = JSON.parse(json.structure);
                // if (self.providerAdgTables.structure == undefined) self.providerAdgTables.structure = structure;
            } else if (Object.entries(json.reportList).length != 0) {
                let report = json.reportList[0];

                if (self.providerAdgTables[providerName][report.name] == undefined) self.providerAdgTables[providerName][report.name] = [];
                if (self.providerAdgTables[providerName][report.name]['weekly'] == undefined) self.providerAdgTables[providerName][report.name]['weekly'] = [];

                report.weeklyreportList
                    .forEach(table => self.providerAdgTables[providerName][report.name]['weekly'].push(table));
                self.providerAdgTablesList.push({ provider: providerName, category: report.name, category_idx: stream_idx, range: 'weekly' });
            }
        }
    }, () => {
        self.providerAdgTablesLoaded.push({
            providerName: providerName,
            range: 'weekly'
        });

    }, "consultant", self.currentPageStreams)


    const caadr_uuid = getConsultantAdProviderAdGroupDailyReport(request, function (uuid, data, stream_idx) {
        if (data.error) {
            console.error(data.error);
            return;
        }
        let json = JSON.parse(JSON.stringify(data.result));
        console.log("getConsultantAdProviderAdGroupDailyReport", json);
        if (caadr_uuid == uuid) {
            if (!!json.structure != false) {
                // let structure = JSON.parse(json.structure);
                // if (self.providerAdgTables.structure == undefined) self.providerAdgTables.structure = structure;
            } else if (Object.entries(json.reportList).length != 0) {
                let report = json.reportList[0];

                if (self.providerAdgTables[providerName][report.name] == undefined) self.providerAdgTables[providerName][report.name] = [];
                if (self.providerAdgTables[providerName][report.name]['daily'] == undefined) self.providerAdgTables[providerName][report.name]['daily'] = [];

                // dow
                report.dailyreportList.forEach((x) => {
                    if (self.dayOfWeekMaster.filter(rx=>rx.name == x.name).length == 0) {
                        self.dayOfWeekMaster.push({
                            name: x.name,
                            dayOfWeek: x.dayofweek
                        });
                    }
                });

                report.dailyreportList
                    .forEach(table => self.providerAdgTables[providerName][report.name]['daily'].push(table));
                self.providerAdgTablesList.push({ provider: providerName, category: report.name, category_idx: stream_idx, range: 'daily' });
            }
        }
    }, () => {
        self.providerAdgTablesLoaded.push({
            providerName: providerName,
            range: 'daily'
        });

    }, "consultant", self.currentPageStreams)

};
AdProviderPage.prototype.getrowspan = function (i1, cname, i2) {
    let advid = this.advertizerId;
    function _getCategoryNameByCampaignName(campname) {
        if (campname) {
            let startTag = '<';
            let endTag = '>';
            if (advid == 4) {
                startTag = '(';
                endTag = ')';
            }
            let sas = campname.split(startTag);
            if (sas?.length > 1) {
                let sbs = sas[1].split(endTag);
                if (sbs?.length > 0) {
                    return sbs[0];
                }
            }
        }

        return '';
    }


    if (cname == "") {
        return 1;
    }
    let alls = this.getProviderCampaignAdgroupTable();
    for (let i = 0; i < alls.length; i++) {
        let campname = alls[i].campaign.data;
        if (_getCategoryNameByCampaignName(campname) == cname) {
            if ((i1 == i) && (i2 == 0)) {
                let cnt = 0;
                for (let x = 0; x < alls.length; x++) {
                    let c = alls[x].campaign.data;
                    if (_getCategoryNameByCampaignName(c) == cname) {
                        cnt += alls[x].adgroupList.length;
                    }
                }
                return cnt;
            } else {
                break;
            }
        }
        //no
    }
    return 0;
};

AdProviderPage.prototype.getProviderAllCategoryLength = function () {
    if (this.providerAllTabStructure) {
        if (this.providerAllTabStructure.category) {
            return this.providerAllTabStructure.category.length;
        }
    }
    return 0;
};
AdProviderPage.prototype.getProviderAllCategories = function () {
    if (this.getProviderAllCategoryLength() > 0) {
        return this.providerAllTabStructure.category;
    }
    return [];
};

AdProviderPage.prototype.getProviderAllTabTableHeader = function () {
    if (this.providerAllTabStructure == null) return [];

    let cells = [];
    cells.push(new Cell(this.providerAllTabStructure.category[0], "string"));
    if (this.getProviderAllCategoryLength() > 1) {
        cells.push(new Cell(this.providerAllTabStructure.category[1], "string"));
    }
    return cells.concat(this.providerAllTabStructure?.chart.map(c => new Cell(c.label, "string")));
}
AdProviderPage.prototype.getProviderAllTabTable = function () {
    // ROAS小数点切捨て
    // this?.providerAllTabStructure?.chart.forEach((key, i) => {
    //     if (key.backendKey.includes('roas')) this.providerAllTabTable.flatMap(ar => ar.tabList).map(p => p.row).forEach(row => {
    //         row[i].dataType = "rate_roas";
    //     });
    // });
    return this.providerAllTabTable;
}
AdProviderPage.prototype.getCategoryAllTable = function (category, range, targetTable = this.categoryAllTables) { // 全マップするより、データアクセッサーとしてAdapterを被せた方がよかったかも。
    if (targetTable[category] != undefined &&
        targetTable[category][range] != undefined &&
        targetTable.structure != undefined) {
        let ts = targetTable[category][range]
            .filter(row => (range == 'monthly' && row.name.indexOf("20") == 0)
                || (range == 'daily')
                || (range == 'weekly')) // dailyデータは全スルーで通す。
            .sort((row1, row2) => {
                if (row1.name.includes("着地想定")) return row1.name.includes(row2.name) ? -1 : 1;
                if (row2.name.includes("着地想定")) return row2.name.includes(row1.name) ? 1 : -1;
                return row1.name > row2.name ? 1 : -1
            })
            .map(row => { // structureにバインドしていく。
                return [new Cell(row.name, "string")]
                    .concat(targetTable.structure.chart
                        .map(c => {
                            let a = row.tableList
                                .filter(t => t.key == c.backendKey)
                                .map(t => new Cell(t.value, c.type))
                                .concat([new Cell("null", "string")])[0]
                            if (c.backendKey.includes("roas")) return new Cell(a.data, "rate_roas");
                            return a;
                        }))
            });

        let rs = [];
        //
        for (let i = 0; i < ts.length; i++) {
            if (this.limitmode == 1 && range == "monthly") {
                if (i > (ts.length - 7)) {
                    rs.push(ts[i]);
                }
            } else if (this.limitmode == 1 && range == "weekly") {
                if (i > (ts.length - 13)) {
                    rs.push(ts[i]);
                }
            } else if (this.limitmode == 1 && range == "daily") {
                // let year = this.timeRange.getTimeRequest().actualMonth.slice(0, 4);
                let month = this.timeRange.getTimeRequest().actualMonth.slice(5);

                ts = ts.filter(function (row) {
                    return (parseInt(row[0].data.split("/")[1]) == parseInt(month));
                });
                rs = ts;
            } else {
                rs.push(ts[i]);
            }
        }
        return rs;
    }
};


AdProviderPage.prototype.refreshCategoryCampaignAdgroupTable = function (id) {
    let self = this;

    let request = {
        sortItem: "",
        sort: "",
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.timeRequest = this.timeRange.getTimeRequest();
    request.advertizerId = this.advertizerId;
    request.providerId = id;
    self.providerCampaignAdgroupTableNetworking = true;
    const checkuid = uuid();
    const ccat_uuid = getConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport(request, function (uuid, data) {
        if (data.error) {
            console.error(data.error);
            return;
        }
        let json = JSON.parse(JSON.stringify(data.result));
        if (ccat_uuid == uuid) {
            if (!!json.structure != false) {
                let structure = JSON.parse(json.structure);
                self.adproDCCAdGroupMonthlyHolder.initialize(structure, "all", self.advertizerId, checkuid);
                let hides = hideColIndex2ByStructure(structure);
                if (hides.length > 0) {
                    let cc = new ColumnDefaultHideSaver();
                    if (!cc.isExist(self.advertizerId, "adprovider")) {
                        const viselecttable = self.invisibleColumnStocker.getComponentInvisibleColumn(self.advertizerId, "adprovider", "selecttable");
                        hides.map(x=>viselecttable.setNumberInvisible(x+1));
                        const viselectalltable = self.invisibleColumnStocker.getComponentInvisibleColumn(self.advertizerId, "adprovider", "selectalltable");
                        hides.map(x=>viselectalltable.setNumberInvisible(x+categoryLengthByStructure(structure)-1));
                        const viselectedtable = self.invisibleColumnStocker.getComponentInvisibleColumn(self.advertizerId, "adprovider", "selectedtable");
                        hides.map(x=>viselectedtable.setNumberInvisible(x));
                        cc.save(self.advertizerId, "adprovider");
                    }
                }
            } else if (Object.entries(json.reportList).length != 0) {
                self.adproDCCAdGroupMonthlyHolder.addReports(json.reportList, checkuid);
            }
        }
    }, () => {
        //
        self.providerCampaignAdgroupTableNetworking = false;
    }, "consultant", self.currentPageStreams)
};

AdProviderPage.prototype.getProviderCampaignAdgroupTableHeaderV2 = function () {
    let result = [];
    if (this.adproDCCAdGroupMonthlyHolder.structureHolder) {
        result.push(new Cell("キャンペーン", "string"));
        result.push(new Cell("広告グループ", "string"));
        let charts = this.adproDCCAdGroupMonthlyHolder.structureHolder.getActiveCharts();
        for (let i in charts) {
            result.push(new Cell(charts[i].label, "string"));
        }
    }
    return result;
};
AdProviderPage.prototype.getProviderCampaignAdgroupTableCellDesign2 = function (data, cindex, item, rs = []) {
    data = Number(data);
    let ret = {
        isColored: false,
        color: null,
        percent: null,
        design: null, // box, bar, bar_gradation
        border: null
    };

    if (item.raw_report.dimensionList.length < 3) {
        return ret;
    }
    if (item.raw_report.dimensionList[2].value == 'ALL') {
        return ret;
    }

    let charts = this.adproDCCAdGroupMonthlyHolder.structureHolder.getActiveCharts();
    let colortype = null;
    if (this.colorMaster) {
        if (charts[cindex].backendKey == this.colorMaster.getKeyIndicator("cv")) {
            colortype = "colored_cv";
        }
        if (charts[cindex].backendKey == this.colorMaster.getKeyIndicator("cparoas")) {
            colortype = "colored_cpa";
            if (charts[cindex].backendKey.indexOf("roas") > 0) {
                colortype = "colored_roas";
            }
        }
    }
    if (colortype == null) {
        return ret;
    }

    ret.isColored = true;

    if (rs?.length == 0) {
        rs = this.adproDCCAdGroupMonthlyHolder.getAdproDCCAdGroupMonthlyRecords();
    }
    rs = rs.filter(r =>
        r.raw_report.dimensionList.length > 2 && r.raw_report.dimensionList[2].value != "ALL"
    );

    let numList = rs.flatMap(x => Number(x.raw_report.tableList[cindex].value));

    if (charts[cindex].backendKey.includes('cpa')) numList = numList.filter(val => val != 0);
    if (charts[cindex].backendKey.includes('roas')) numList = numList.filter(val => val != 0);
    let max = numList.length != 0 ? numList.reduce((a, b) => a > b ? a : b) : 0;
    let min = numList.length != 0 ? numList.reduce((a, b) => a < b ? a : b) : 0;

    // define color 
    switch (colortype) {
        case "colored_cpc":
            //ret.color = '#E3F2FD';break;
            break;
        case "colored_cost":
            //ret.color =  'linear-gradient(to right, #FF595E, 50%, #FEE3E4)';
            //ret.border = '1px solid #FF595E';break;
            break;
        case "colored_cv":
            ret.color = '#34B7FD';
            ret.border = '1px solid #068DEF'; break;
        case "colored_cvr":
            //ret.color = '#F9E9DA';break;
            break;
        case "colored_cpa": {
            let colScale = Array.from(colScale3);
            ret.color = colScale
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
        case "colored_roas": {
            let colScale = Array.from(colScale3);
            ret.color = colScale.reverse()
                .map((col, i) => [col, min + (max - min) * i / (colScale.length - 1)])
                .reduce((colVal1, colVal2) =>
                    Math.abs(colVal1[1] - data) > Math.abs(colVal2[1] - data) ?
                        colVal2
                        : colVal1
                )[0];
            if (data == 0) ret.color = "";
            break;
        }
    }

    // define percent
    let r = data / max * 100;
    r = !isNaN(r) ? r : 0; // 0除算の場合NaNになる。
    if (r > 100) {
        r = 100;
    }
    switch (colortype) {
        case "colored_cpc":
            ret.percent = r; break;
        case "colored_cost":
            ret.percent = r; break;
        case "colored_cv":
            ret.percent = r; break;
        case "colored_cvr":
            ret.percent = r; break;
        case "colored_cpa":
            ret.percent = 100; break;
        case "colored_roas":
            ret.percent = 100; break;
    }

    switch (colortype) {
        case "colored_cpc":
            ret.design = "bar"; break;
        case "colored_cost":
            ret.design = "bar_gradation"; break;
        case "colored_cv":
            ret.design = "bar_gradation"; break;
        case "colored_cvr":
            ret.design = "bar"; break;
        case "colored_cpa":
            ret.design = "box"; break;
        case "colored_roas":
            ret.design = "box"; break;
    }

    return ret;
}


AdProviderPage.prototype.loadConsultantAdProviderCategoriesDimension = function (providerId, providerName, categoryIds) {
    let self = this;

    let request = {
        sortItem: "",
        sort: "",
        adProviderId: providerId,
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
    };
    request.timeRequest = this.timeRange.getTimeRequest();
    request.advertizerId = this.advertizerId;
    request.categoryIds = categoryIds;
    self.providerDimensionTables[providerName] = [];
    if (this.providerDimensionTablesLoaded?.length > 0) {
        for (let i = this.providerDimensionTablesLoaded.length - 1 ; i >=0 ; i--) {
            if (this.providerDimensionTablesLoaded[i].providerName == providerName) {
                this.providerDimensionTablesLoaded.splice(i, 1);
            }
        }    
    }

    this.activeYmdUidM = "";
    this.activeYmdUidW = "";
    this.activeYmdUidD = "";

    const m_uuid = getConsultantAdProviderCategoriesDimensionMonthlyReport(request, function (uuid, data, stream_idx) {
        if (data.error) {
            console.log(stream_idx);
            console.error(data.error);
            return;
        }

        if ((m_uuid == uuid) && data != null && (self.activeYmdUidM == uuid)) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!json.structure != false) {
                let structure = JSON.parse(json.structure);
                if (self.providerDimensionTables.structure == undefined) self.providerDimensionTables.structure = structure;
            } else if (Object.entries(json.reportList).length != 0) {
                // let report = json.reportList[0];
                let cs = json.categorynamesList;
                let cname = "" + cs[0];
                if (cs.length > 1) {
                    cname = cname + cs[1];
                }
                if (self.providerDimensionTables[providerName][cname] == undefined) {
                    self.providerDimensionTables[providerName][cname] = [];
                }
                if (self.providerDimensionTables[providerName][cname]["monthly"] == undefined) {
                    self.providerDimensionTables[providerName][cname]["monthly"] = [];
                }

                json.reportList
                    .forEach(table => self.providerDimensionTables[providerName][cname]['monthly'].push(table));
                self.providerDimensionTablesList.push({ provider: providerName, category: cname, category_idx: stream_idx, range: 'monthly' });
            }

        }
    }, () => {
        self.providerDimensionTablesLoaded.push({
            providerName: providerName,
            range: 'monthly'
        });
    }, "consultant", self.currentPageStreams);
    this.activeYmdUidM = m_uuid;
    const w_uuid = getConsultantAdProviderCategoriesDimensionWeeklyReport(request, function (uuid, data, stream_idx) {
        if (data.error) {
            console.log(stream_idx);
            console.error(data.error);
            return;
        }

        if ((w_uuid == uuid) && data != null && (self.activeYmdUidW == uuid)) {
            let json = JSON.parse(JSON.stringify(data.result));
            if (!!json.structure != false) {
                // let structure = JSON.parse(json.structure);
                // if (self.providerDimensionTables.structure == undefined) self.providerDimensionTables.structure = structure;
            } else if (Object.entries(json.reportList).length != 0) {
                // let report = json.reportList[0];
                let cs = json.categorynamesList;
                let cname = "" + cs[0];
                if (cs.length > 1) {
                    cname = cname + cs[1];
                }
                if (self.providerDimensionTables[providerName][cname] == undefined) {
                    self.providerDimensionTables[providerName][cname] = [];
                }
                if (self.providerDimensionTables[providerName][cname]["weekly"] == undefined) {
                    self.providerDimensionTables[providerName][cname]["weekly"] = [];
                }
                json.reportList
                    .forEach(table => self.providerDimensionTables[providerName][cname]['weekly'].push(table));
                self.providerDimensionTablesList.push({ provider: providerName, category: cname, category_idx: stream_idx, range: 'weekly' });
            }

        }
    }, () => {
        self.providerDimensionTablesLoaded.push({
            providerName: providerName,
            range: 'weekly'
        });

    }, "consultant", self.currentPageStreams);
    this.activeYmdUidW = w_uuid;

    const d_uuid = getConsultantAdProviderCategoriesDimensionDailyReport(request, function (uuid, data, stream_idx) {
        if (data.error) {
            console.log(stream_idx);
            console.error(data.error);
            return;
        }

        if ((d_uuid == uuid) && data != null && (self.activeYmdUidD == uuid)) {
            let json = JSON.parse(JSON.stringify(data.result));
            console.log("getConsultantAdProviderCategoriesDimensionDailyReport", json);
            if (!!json.structure != false) {
                // let structure = JSON.parse(json.structure);
                // if (self.providerDimensionTables.structure == undefined) self.providerDimensionTables.structure = structure;
            } else if (Object.entries(json.reportList).length != 0) {
                // let report = json.reportList[0];
                let cs = json.categorynamesList;
                let cname = "" + cs[0];
                if (cs.length > 1) {
                    cname = cname + cs[1];
                }
                if (self.providerDimensionTables[providerName][cname] == undefined) {
                    self.providerDimensionTables[providerName][cname] = [];
                }
                if (self.providerDimensionTables[providerName][cname]["daily"] == undefined) {
                    self.providerDimensionTables[providerName][cname]["daily"] = [];
                }

                // dow
                json.reportList.forEach((x) => {
                    if (self.dayOfWeekMaster.filter(rx=>rx.name == x.name).length == 0) {
                        self.dayOfWeekMaster.push({
                            name: x.name,
                            dayOfWeek: x.dayofweek
                        });
                    }
                });

                json.reportList
                    .forEach(table => self.providerDimensionTables[providerName][cname]['daily'].push(table));
                self.providerDimensionTablesList.push({ provider: providerName, category: cname, category_idx: stream_idx, range: 'daily' });
            }

        }
    }, () => {
        self.providerDimensionTablesLoaded.push({
            providerName: providerName,
            range: 'daily'
        });
    }, "consultant", self.currentPageStreams);
    this.activeYmdUidD = d_uuid;

};

AdProviderPage.prototype.day2dayOfWeek = function (day) {
    for (let i in this.dayOfWeekMaster) {
        if (this.dayOfWeekMaster[i].name == day) {
            return this.dayOfWeekMaster[i].dayOfWeek;
        }
    }
    return ""
};
AdProviderPage.prototype.updatecstrp = function (callback) {
    let request = {
        sortItem: '',
        sort: '',
        token: this.user.auth0.token,
        userUuid: this.user.userUuid,
      };
    
      request.advertizerId = this.advertizerId;
      const cuid = getCustomReportPages(
        [],
        request,
        function (uid, data) {
            if (cuid == uid) {
                if (data) {
                    if (data?.result) {
                      let result = data?.result;
                      if (result?.pagesList) {
                        for (let i in result?.pagesList) {
                            if (result?.pagesList[i].order < 0) {
                                callback(true);
                                return;
                            }
                        }
                      }
                    }
                }
            }
        },
        function () {
            // if (callback) {
            //     callback();
            // }
        },
        null,
        this._currentPageStreams
      );
};


export { AdProviderPage }