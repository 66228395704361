<template>
  <div class="side_menu">
    <ul class="side-nav">
      <li class="side-nav__item_wrapper" v-if="advertizerId != null">
        <router-link :to="'/consultant/summary'+'?'+makeStatusQuery()">
          <div
            class="side-nav__item"
            v-bind:class="{
              'side-nav__item--selected': selectedId == 'summary',
            }"
          >
            <i class="circle_icon"></i>サマリー
          </div>
        </router-link>
        <router-link :to="'/consultant/adprovider'+'?'+makeStatusQuery()">
          <div
            class="side-nav__item"
            v-bind:class="{
              'side-nav__item--selected': selectedId == 'adprovider',
            }"
          >
            <i class="circle_icon"></i>媒体別実績
          </div>
        </router-link>
        <router-link :to="'/consultant/customreport'+'?'+makeStatusQuery()" v-if="true">
          <div class="side-nav__item"><i class="circle_icon"></i>カスタムレポート</div>
        </router-link>
        <router-link to="/consultant/sprint" v-if="false">
          <div
            class="side-nav__item"
            v-bind:class="{
              'side-nav__item--selected': selectedId == 'sprint',
            }"
          >
            <i class="circle_icon"></i>ウィークリースプリント
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  props: {
    selectId: String,
    adx: Object,
    submenu: Object,
  },
  data() {
    return {
      selectedId: '',
      menus: [],
      keywordmenus: [],
      advertizerId: null,
    };
  },
  mounted() {
    let self = this;
    this.selectedId = this.selectId;

    this.advertizerId = this.adx.getScreenStructureById('consultant').advertizerId;
    this.adx.getScreenStructureById('consultant').subject.addObserver(function () {
      self.loadmenu();
      self.advertizerId = self.adx.getScreenStructureById('consultant').advertizerId;
    });
    this.loadmenu();
  },
  methods: {
    makeStatusQuery() {
      let timeQuery = "";
      let dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
      if (dateRangeSelector) {
        timeQuery = '&rdate=' + dateRangeSelector.timeYmdRange.getYm();
      }
      return 'advertizer_id=' + this.adx.getScreenStructureById('consultant').advertizerId + timeQuery;
    },
    loadmenu() {
      let sideMenus = this.adx.getScreenStructureById('consultant').getDynamicSideMenus();

      this.menus = [];
      this.menus = sideMenus;

      // for (let key in sideMenus) {
      //   let page = sideMenus[key];
      //   let isHit = false;
      //   for (let key in this.menus) {
      //     let menu = this.menus[key];
      //     if (menu.id == page.id) {
      //       isHit = true;
      //       break;
      //     }
      //   }
      //   if (!isHit) {
      //     this.menus.push(page);
      //   }
      // }
    },
  },
};
</script>
