import {
    GetAdBindingKeysRequest,
    GetAdProviderDynamicCategoryMonthlyReportRequest,
    GetAdProviderDynamicCategoryWeeklyReportRequest,
    GetAdProviderDynamicCategoryDailyReportRequest,
} from './protos_gen/excel_report_pb.js'
import { ExcelReportClient } from './protos_gen/excel_report_grpc_web_pb.js'

import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function getAdBindingKeys(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetAdBindingKeysRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);

    let client = new ExcelReportClient(url.grpc, null, null);
    const stream = client.getAdBindingKeys(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getAdProviderDynamicCategoryMonthlyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetAdProviderDynamicCategoryMonthlyReportRequest();
    request.setRequestid(uuidStr);
    request.setLimit(27);
    if ('adProviderId' in dto) {
        request.setAdproviderid(dto.adProviderId);
    }
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ExcelReportClient(url.grpc, null, null);
    const stream = client.getAdProviderDynamicCategoryMonthlyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getAdProviderDynamicCategoryWeeklyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetAdProviderDynamicCategoryWeeklyReportRequest();
    request.setRequestid(uuidStr);
    request.setLimit(30);
    if ('adProviderId' in dto) {
        request.setAdproviderid(dto.adProviderId);
    }
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ExcelReportClient(url.grpc, null, null);
    const stream = client.getAdProviderDynamicCategoryWeeklyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

function getAdProviderDynamicCategoryDailyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetAdProviderDynamicCategoryDailyReportRequest();
    request.setRequestid(uuidStr);
    request.setLimit(60);
    if ('adProviderId' in dto) {
        request.setAdproviderid(dto.adProviderId);
    }
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ExcelReportClient(url.grpc, null, null);
    const stream = client.getAdProviderDynamicCategoryDailyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

export {
    getAdBindingKeys,
    getAdProviderDynamicCategoryMonthlyReport,
    getAdProviderDynamicCategoryWeeklyReport,
    getAdProviderDynamicCategoryDailyReport,
}