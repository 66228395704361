/* eslint-disable */

import {
  TabListOfDynamicDimensionCategoryReportPageRequest,
  DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest,
  DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest,
  DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest,
  DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest,
  DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest,
  DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest,
  DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest,
  DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest,
  DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest,
  DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest,
} from './protos_gen/dynamic_dimension_category_report_pb.js';
import { DynamicDimensionCategoryReportClient } from './protos_gen/dynamic_dimension_category_report_grpc_web_pb.js';
import { url } from '../../setting.js';
import { uuid } from '../../tacklebox/core/uuid.js';

function getTabListOfDynamicDimensionCategoryReportPage(id, dto, callback) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new TabListOfDynamicDimensionCategoryReportPageRequest();
  request.setAdpageid(id);
  request.setRequestid(uuidStr);
  request.setAdvertizerid(dto.advertizerId);

  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getTabListOfDynamicDimensionCategoryReportPage(request, meta);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryMonthlyResultReportByAdProvider(id, categoryId, dto, callback, callback_end = ()=>{}, user_role = "", currentPageStreams = []) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };

  let uuidStr = uuid();
  let request = new DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setAdvertizerid(dto.advertizerId);
  if(user_role == "consultant") request.setLimit(27);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }

  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryMonthlyResultReportByAdProvider(request, meta);
  currentPageStreams.push(stream);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider(
  id,
  categoryId,
  dto,
  callback,
  callback_end = ()=>{},
  user_role = "",
  currentPageStreams = []
) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setAdvertizerid(dto.advertizerId);
  if(user_role == "consultant") request.setLimit(30);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }

  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider(request, meta);
  currentPageStreams.push(stream);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryDailyResultGraphReportByAdProvider(
  id,
  categoryId,
  dto,
  callback,
  callback_end = ()=>{},
  user_role = "",
  currentPageStreams = []
) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setAdvertizerid(dto.advertizerId);
  if(user_role == "consultant") request.setLimit(60);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }

  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryDailyResultGraphReportByAdProvider(request, meta);
  currentPageStreams.push(stream);
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('end', message => {
    callback_end(uuidStr, message);
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider(
  id,
  categoryId,
  pageComponentId,
  dto,
  callback
) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let request = new DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setAdpagecomponentid(pageComponentId);
  request.setAdvertizerid(dto.advertizerId);

  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }
  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider(
  id,
  categoryId,
  dto,
  callback
) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };

  let uuidStr = uuid();
  let request = new DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setAdvertizerid(dto.advertizerId);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }
  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider(
  id,
  dto,
  callback
) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let actualMonth = dto.actualMonth;
  let startDate = dto.startDate;
  let endDate = dto.endDate;
  let categoryId = dto.categoryId;
  let request = new DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setActualmonth(actualMonth);
  request.setStartdate(startDate);
  request.setEnddate(endDate);
  request.setAdvertizerid(dto.advertizerId);

  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider(
  id,
  dto,
  callback
) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let actualMonth = dto.actualMonth;
  let startDate = dto.startDate;
  let endDate = dto.endDate;
  let categoryId = dto.categoryId;
  let request = new DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setActualmonth(actualMonth);
  request.setStartdate(startDate);
  request.setEnddate(endDate);
  request.setAdvertizerid(dto.advertizerId);

  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider(
  id,
  categoryId,
  dto,
  callback
) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };

  let uuidStr = uuid();
  let request = new DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setAdvertizerid(dto.advertizerId);
  // request.setCampaign(dto.campaign);
  if (!dto.timeRequest.isRange()) {
    request.setActualmonth(dto.timeRequest.actualMonth); //TBD 時刻オブジェクト
  } else {
    request.setStartdate(dto.timeRequest.startDate);
    request.setEnddate(dto.timeRequest.endDate);
  }

  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider(
  id,
  dto,
  callback
) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let actualMonth = dto.actualMonth;
  let startDate = dto.startDate;
  let endDate = dto.endDate;
  let categoryId = dto.categoryId;
  let request = new DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setCampaign(dto.campaign);
  request.setActualmonth(actualMonth);
  request.setStartdate(startDate);
  request.setEnddate(endDate);
  request.setAdvertizerid(dto.advertizerId);

  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

function getDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider(
  id,
  dto,
  callback
) {
  let meta = {
    'X-Timezone': 'Asia/Tokyo',
    'X-API-Version': '2',
    'Authorization': "Bearer " + dto.token,
  };
  let uuidStr = uuid();
  let actualMonth = dto.actualMonth;
  let startDate = dto.startDate;
  let endDate = dto.endDate;
  let categoryId = dto.categoryId;
  let request = new DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest();
  request.setRequestid(uuidStr);
  request.setAdproviderid(id);
  request.setCategoryid(categoryId);
  request.setCampaign(dto.campaign);
  request.setActualmonth(actualMonth);
  request.setStartdate(startDate);
  request.setEnddate(endDate);
  request.setAdvertizerid(dto.advertizerId);

  let client = new DynamicDimensionCategoryReportClient(url.grpc, null, null);
  const stream = client.getDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider(
    request,
    meta
  );
  stream.on('data', message => {
    if (callback) {
      if (message['array'].length > 0) callback(uuidStr, message.toObject());
      else callback(uuidStr, null);
    }
  });
  stream.on('error', (e) => {
    console.log('Error returned from BFF.');
    throw e;
  });
  //TBD エラー

  return uuidStr;
}

export {
  getTabListOfDynamicDimensionCategoryReportPage,
  getDynamicDimensionCategoryMonthlyResultReportByAdProvider,
  getDynamicDimensionCategoryWeeklyResultGraphReportByAdProvider,
  getDynamicDimensionCategoryDailyResultGraphReportByAdProvider,
  getDynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider,
  getDynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider,
  getDynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider,
  getDynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider,
  getDynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider,
  getDynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider,
  getDynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider,
};
