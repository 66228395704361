
// -- data -----------------------------
import * as tacklebox from '../tacklebox/core/index.js'


function AdProviderTabCell(adProvider) {
    this.adProvider = JSON.parse(JSON.stringify(adProvider));

}
AdProviderTabCell.prototype.getName = function () {
    return this.adProvider.tab;
};
AdProviderTabCell.prototype.getId = function () {
    return this.adProvider.adproviderid;
};

function AdProviderTab() {
    this.cells = [];
}
AdProviderTab.prototype.addAdProvider = function (adProvider) {
    this.cells.push(new AdProviderTabCell(adProvider));
};

function ChartStructure(structure, accountType) {
    this.structure = JSON.parse(JSON.stringify(structure));
    this.accountType = accountType;
}
ChartStructure.prototype.getActiveCharts = function () {
    var charts = [];
    for (let i in this.structure.chart) {
        charts.push(this.structure.chart[i]);
    }
    return charts;
}


function Cell(data, dataType = "string") {
    this.data = data;
    this.dataType = dataType;
    this.isFloorMode = false;
    this.isRoundMode = false;
}
Cell.prototype.getString = function () {
    function comma(num) {
        return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    let result = this.data;
    let ret = null;
    switch (this.dataType) {
        case "rate":
            if (this.isFloorMode) {
                try {
                    ret = comma(Math.floor(Number(this.data)));
                } catch (ex) {
                    console.log(ex);
                }

                return ret + "%";
            }
            if (this.isRoundMode) {
                try {
                    ret = comma(Math.round(Number(this.data)));
                } catch (ex) {
                    console.log(ex);
                }

                return ret + "%";
            }
            ret = comma(this.data);
            if (Number(this.data) < 10) { // 小数点以下2位まで表示
                if (ret.includes(".")) {
                    if (ret.split(".")[1].length == 1) ret = ret + "0";
                } else {
                    ret = ret + ".00";
                }
            } else { // 小数点以下1位まで表示
                if (!ret.includes(".")) {
                    ret = ret + ".0";
                }
                if (ret.split(".")[1].length == 2) ret = ret.slice(0, -1);
            }
            return ret + "%";
        case "currency":
            return "¥" + comma(this.data);
        case "number":
            return comma(this.data);
        case "count":
            return comma(this.data);
        case "rate_roas":
            return comma(this.data) + "%";
    }

    return result;
};
Cell.prototype.isMinus = function () {
    switch (this.dataType) {
        case "rate":
        case "currency":
        case "number":
        case "count":
            if (Number(this.data) < 0) {
                return true;
            }
            break;
    }
    return false
};
Cell.prototype.isPlus = function () {
    switch (this.dataType) {
        case "rate":
        case "currency":
        case "number":
        case "count":
            if (Number(this.data) > 0) {
                return true;
            }
            break;
    }
    return false
};
Cell.prototype.getPmString = function () {
    function comma(num) {
        return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    function pmsign(num) {
        try {
            if (Number(num) > 0) {
                return "+"
            }
        } catch (ex) {
            console.log(ex);
        }

        return "";
    }
    let result = this.data;
    switch (this.dataType) {
        case "rate":
            return pmsign(this.data) + comma(this.data) + "%";
        case "currency":
            return "¥" + pmsign(this.data) + comma(this.data);
        case "number":
            return pmsign(this.data) + comma(this.data);
        case "count":
            return pmsign(this.data) + comma(this.data) + "件";
    }

    return result;
};


function ChartStructureTable() {
    this.structure = null;
    this.cellRows = [];
}
ChartStructureTable.prototype.initialize = function (structure, accountType) {
    this.cellRows = [];
    this.structure = new ChartStructure(structure, accountType);
};
ChartStructureTable.prototype.addRow = function (row) {
    let r = JSON.parse(JSON.stringify(row));
    if (this.structure) {
        let cell = [];
        let charts = this.structure.getActiveCharts();
        for (let i in charts) {
            let chart = charts[i];
            for (let key of r) {
                if (key[0] == chart.backendKey) {
                    cell.push(new Cell(key[1], chart.type));
                    break;
                }
            }
        }
        // console.log(cell)
        this.cellRows.push(cell);
    }
}
ChartStructureTable.prototype.getHeaderCell = function () {
    let result = [];
    if (this.structure) {
        for (let i in this.structure.getActiveCharts()) {
            let chart = this.structure.getActiveCharts()[i];
            result.push(new Cell(chart.label, "string"));
        }
    }
    return result;
};

ChartStructureTable.prototype.reset = function () {
    this.structure = null;
    this.cellRows = [];
};

function NameChartStructureTable() {
    ChartStructureTable.call(this);
}
tacklebox.extend(NameChartStructureTable, ChartStructureTable);
NameChartStructureTable.prototype.getHeaderCell = function (title = null) {
    let result = [];
    if (this.structure) {
        if (title) {
            result.push(new Cell(title));
        } else {
            result.push(new Cell(this.structure.structure.name));
        }
        for (let i in this.structure.getActiveCharts()) {
            let chart = this.structure.getActiveCharts()[i];
            result.push(new Cell(chart.label, "string"));
        }
    }
    return result;
};
NameChartStructureTable.prototype.addNameAndRow = function (name, row) {
    let r = JSON.parse(JSON.stringify(row));
    if (this.structure) {
        let cell = [];

        cell.push(new Cell(name, "string"));
        let charts = this.structure.getActiveCharts();
        for (let i in charts) {
            let chart = charts[i];
            for (let key in r) {
                if (r[key].key == chart.backendKey) {
                    cell.push(new Cell(r[key].value, chart.type));
                    break;
                }
            }
        }
        this.cellRows.push(cell);
    }
};

function ItemWithTitle(title = "", value = "", key = "", dataType = "string") {
    this.key = key;
    this.title = title;
    this.cell = new Cell(value, dataType);
}
ItemWithTitle.prototype.getTitle = function () {
    return this.title;
};
ItemWithTitle.prototype.getValue = function () {
    return this.cell.getString();
};
ItemWithTitle.prototype.getRawValue = function () {
    return this.cell.data;
};
ItemWithTitle.prototype.getPmValue = function () {
    return this.cell.getPmString();
};

function StructureHolder(structure, accountType) {
    this.structure = JSON.parse(JSON.stringify(structure));
    this.accountType = accountType;
}
StructureHolder.prototype.getActiveBlock = function () {
    var results = [];
    for (let i in this.structure.block) {
        results.push(this.structure.block[i]);
    }
    return results;
}
StructureHolder.prototype.getActiveGraph = function () {
    var results = [];
    for (let i in this.structure.graph) {
        results.push(this.structure.graph[i]);
    }
    return results;
}
StructureHolder.prototype.getActiveHover = function () {
    var results = [];
    for (let i in this.structure.hover) {
        results.push(this.structure.hover[i]);
    }
    return results;
}


function GraphRecord(name, items = []) {
    this.name = name;
    this.items = items;
}
GraphRecord.prototype.addItem = function (item) {
    this.items.push(item);
};

function Graph() {
    this.structureHolder = null;
    this.records = [];
}
Graph.prototype.initialize = function (structure, accountType) {
    this.structureHolder = new StructureHolder(structure, accountType);
    this.records = [];
};
Graph.prototype.setDatas = function (rows) {
    if (this.structureHolder) {
        let activeHover = this.structureHolder.getActiveHover();
        // for (let key in rows) {
        // let row = rows[key];
        let row = rows;
        let r = new GraphRecord(row.name);
        for (let key in row.tableList) {
            let table = row.tableList[key];
            for (let key in activeHover) {
                if (activeHover[key].backendKey == table.key) {
                    r.addItem(new ItemWithTitle(activeHover[key].label, table.value, table.key, activeHover[key].type));
                    break;
                }
            }
            // }
        }
        if ('isalert' in row) {
            if (row.isalert == "true" || row.isalert == true) {
                r.isAlert = true;
            }
        }
        this.records.push(r);
    }
};
Graph.prototype.getActiveAxes = function () {
    let self = this;
    function _getItemByKey(key) {
        let activeHover = self.structureHolder.getActiveHover();
        for (let k in activeHover) {
            if (activeHover[k].backendKey == key) {
                return activeHover[k];
            }
        }
        return null;
    }
    var axss = [];
    if (this.structureHolder) {
        let graphs = this.structureHolder.getActiveGraph();
        for (let key in graphs) {
            let item = _getItemByKey(graphs[key].backendKey);
            axss.push({
                key: graphs[key].backendKey,
                type: graphs[key].type,
                label: item.label
            });
        }
    }

    return axss.reverse();
};
Graph.prototype.getItems = function () {
    let rss = [];

    let axss = this.getActiveAxes();
    for (let key in this.records) {
        let r = this.records[key];
        let res = {};
        res.name = r.name;

        res.datas = [];
        for (let k in axss) {
            let axs = axss[k];
            for (let k in r.items) {
                let item = r.items[k];
                if (axs.key == item.key) {
                    res.datas.push(item.getRawValue());
                }

            }

        }
        rss.push(res);
    }
    return rss;
};
Graph.prototype.reset = function () {
    this.structureHolder = null;
    this.records = [];
}

function CategoryCell(data, dataType, variables) {
    Cell.call(this, data, dataType);
    this.variables = variables;
}
tacklebox.extend(CategoryCell, Cell);


function DimensionCategoryParentGroup(group) {
    this.group = group;
    this.dimensionCategoryGroups = [];
}
DimensionCategoryParentGroup.prototype.addDimensionCategoryGroup = function (dimensionCategoryGroup) {
    this.dimensionCategoryGroups.push(dimensionCategoryGroup);
};


function DimensionCategoryGroup(group) {
    this.group = group;
    this.dimensionCategories = [];
}
DimensionCategoryGroup.prototype.addDimensionCategory = function (dimensionCategory) {
    this.dimensionCategories.push(dimensionCategory);
};
DimensionCategoryGroup.prototype.getLength = function () {
    let len = 0;
    for (let i in this.dimensionCategories) {
        len += (this.dimensionCategories[i].cellRows.length);
    }
    // console.log(len);
    return len;
};

function DimensionCategory(categoryCell) {
    this.categoryCell = categoryCell;
    this.cellRows = [];
}
DimensionCategory.prototype.addRecord = function (record) {
    this.cellRows.push(record);
};
DimensionCategory.prototype.isEmpty = function () {
    let sum = 0;
    for (let i in this.cellRows) {
        let cell = this.cellRows[i];
        for (let j in cell) {
            if (cell[j].dataType != "string") {
                try {
                    sum = sum + Number(cell[j].data);
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    return sum == 0;
};

function DynamicDimension() {
    this.structureHolder = null;
    this.requestId = null;
    this.dimensions = [];
    this.order = 0;
}
DynamicDimension.prototype.initialize = function (structure, accountType, requestId, order) {
    this.structureHolder = new ChartStructure(structure, accountType);
    this.requestId = requestId;
    this.order = order;
};
DynamicDimension.prototype.getHeaderCell = function () {
    let result = [];
    if (this.structureHolder) {
        for (let i in this.structureHolder.structure.category) {
            result.push(new Cell(this.structureHolder.structure.category[i], "string"));
        }
        result.push(new Cell("種別", "string"));
        for (let i in this.structureHolder.getActiveCharts()) {
            let chart = this.structureHolder.getActiveCharts()[i];
            result.push(new Cell(chart.label, "string"));
        }
    }
    return result;
};
DynamicDimension.prototype.getActiveVariablesStructureByKey = function (key) {
    let structure = null;

    if (this.structureHolder) {
        if (this.structureHolder.structure) {
            if (this.structureHolder.structure.variables) {
                if (this.structureHolder.structure.variables.length > 0) {
                    for (let i in this.structureHolder.structure.variables[0].item) {
                        if (this.structureHolder.structure.variables[0].item[i].backendKey == key) {
                            return this.structureHolder.structure.variables[0].item[i];
                        }
                    }
                }
            }
        }
    }
    return structure;
};
DynamicDimension.prototype.addRecord = function (categoryType, dimensionCategoryResultReport) {
    let r = JSON.parse(JSON.stringify(dimensionCategoryResultReport));
    if (this.structureHolder) {

        if (this.count() == 1) {
            for (let i in r) {
                let c = r[i];
                let vs = [];
                for (let i in c.variablesList) {
                    let st = this.getActiveVariablesStructureByKey(c.variablesList[i].key);
                    let item = new ItemWithTitle(st.label, c.variablesList[i].value, c.variablesList[i].key, st.type);
                    item.rate = 0;
                    if (!isNaN(Number(c.variablesList[i].rate))) {
                        item.rate = c.variablesList[i].rate;
                    }
                    vs.push(item);
                }
                let d = new DimensionCategory(new CategoryCell(c.category, "string", vs));
                for (let i in c.rowsList) {
                    let row = c.rowsList[i];
                    let cells = [];
                    cells.push(new Cell(row.name, "string"));
                    let charts = this.structureHolder.getActiveCharts();
                    for (let i in charts) {
                        let chart = charts[i];
                        for (let key in row.tableList) {
                            if (row.tableList[key].key == chart.backendKey) {
                                cells.push(new Cell(row.tableList[key].value, chart.type));
                                break;
                            }
                        }
                    }
                    d.addRecord(cells);
                }
                if (!d.isEmpty()) {
                    this.dimensions.push(d);
                }
            }
        } else if (this.count() == 3) {
            //
            for (let j in r) {
                let p = new DimensionCategoryParentGroup(r[j].category);
                if ('adproviderid' in r[j]) {
                    p.adProviderId = r[j].adproviderid;
                }

                for (let x in r[j].nestcategoryList) {
                    let ncg = r[j].nestcategoryList[x];
                    let g = new DimensionCategoryGroup(ncg.category);
                    g.adProviderId = -1;
                    if ('adproviderid' in ncg) {
                        g.adProviderId = ncg.adproviderid;
                    }
                    for (let x in ncg.nestcategoryList) {
                        let nc = ncg.nestcategoryList[x];
                        let vs = [];
                        for (let vi in nc.variablesList) {
                            let struc = this.getActiveVariablesStructureByKey(nc.variablesList[vi].key);
                            let item = new ItemWithTitle(struc.label, nc.variablesList[vi].value, nc.variablesList[vi].key, struc.type);
                            item.rate = 0;
                            if (!isNaN(Number(nc.variablesList[vi].rate))) {
                                item.rate = nc.variablesList[vi].rate;
                            }
                            vs.push(item);
                        }
                        let d2 = new DimensionCategory(new CategoryCell(nc.category, "string", vs));
                        for (let k in nc.rowsList) {
                            let rrr = nc.rowsList[k];
                            let ccc = [];
                            ccc.push(new Cell(rrr.name, "string"));
                            let chs = this.structureHolder.getActiveCharts();
                            for (let y in chs) {
                                let ct = chs[y];
                                for (let key in rrr.tableList) {
                                    if (rrr.tableList[key].key == ct.backendKey) {
                                        ccc.push(new Cell(rrr.tableList[key].value, ct.type));
                                        break;
                                    }
                                }
                            }
                            d2.addRecord(ccc);
                        }
                        if (!d2.isEmpty()) {
                            g.addDimensionCategory(d2);
                        }
                    }
                    p.addDimensionCategoryGroup(g);

                }
                this.dimensions.push(p);

            }
        } else {
            // console.log(r);
            for (let j in r) {
                let g = new DimensionCategoryGroup(r[j].category);
                g.adProviderId = -1;
                if ('adproviderid' in r[j]) {
                    g.adProviderId = r[j].adproviderid;
                }
                for (let x in r[j].nestcategoryList) {
                    let nc = r[j].nestcategoryList[x];
                    let vs = [];
                    for (let vi in nc.variablesList) {
                        let struc = this.getActiveVariablesStructureByKey(nc.variablesList[vi].key);
                        let item = new ItemWithTitle(struc.label, nc.variablesList[vi].value, nc.variablesList[vi].key, struc.type);
                        item.rate = 0;
                        if (!isNaN(Number(nc.variablesList[vi].rate))) {
                            item.rate = nc.variablesList[vi].rate;
                        }
                        vs.push(item);
                    }
                    let d2 = new DimensionCategory(new CategoryCell(nc.category, "string", vs));
                    for (let k in nc.rowsList) {
                        let rrr = nc.rowsList[k];
                        let ccc = [];
                        ccc.push(new Cell(rrr.name, "string"));
                        let chs = this.structureHolder.getActiveCharts();
                        for (let y in chs) {
                            let ct = chs[y];
                            for (let key in rrr.tableList) {
                                if (rrr.tableList[key].key == ct.backendKey) {
                                    ccc.push(new Cell(rrr.tableList[key].value, ct.type));
                                    break;
                                }
                            }
                        }
                        d2.addRecord(ccc);
                    }
                    if (!d2.isEmpty()) {
                        g.addDimensionCategory(d2);
                    }
                }
                this.dimensions.push(g);
            }

        }
    }

};
DynamicDimension.prototype.count = function () {
    if (this.structureHolder) {
        if ('category' in this.structureHolder.structure) {
            return this.structureHolder.structure.category.length;
        }
    }
    return 0;
};
DynamicDimension.prototype.title = function () {
    if (this.structureHolder) {
        if ('name' in this.structureHolder.structure) {
            return this.structureHolder.structure.name;
        }
    }
    return "";
};

function DimensionHolder() {
    this.dimensions = [];
}
DimensionHolder.prototype.initialize = function () {
    this.dimensions = [];
};
DimensionHolder.prototype.pushInitStream = function (structure, accountType, requestId, order) {
    let dd = new DynamicDimension();
    dd.initialize(structure, accountType, requestId, order);
    this.dimensions.push(dd);
};
DimensionHolder.prototype.pushDataStream = function (categoryType, dimensionCategoryResultReport, requestId) {
    let target = null
    for (let i in this.dimensions) {
        if (this.dimensions[i].requestId == requestId) {
            target = this.dimensions[i];
            break;
        }
    }
    if (target) {
        target.addRecord(categoryType, dimensionCategoryResultReport)
    }
};
DimensionHolder.prototype.getDimenstionsOrderRecords = function () {
    return this.dimensions.sort(function (a, b) {
        return a.order - b.order;
    });
};

function ContentRecordHolder() {
    this.requestId = null;
    this.structure = null;
    this.accountType = null;
    this.records = [];
}
ContentRecordHolder.prototype.initialize = function (structure = null, accountType = null) {
    this.reset();
    this.accountType = accountType;
    this.structure = structure;
};
ContentRecordHolder.prototype._onContentRecordCreateRecord = function (data) { console.log(data) };
ContentRecordHolder.prototype.pushDataStream = function (data, requestId) {
    if (this.requestId == null) {
        this.requestId = requestId;
        this.records.push(this._onContentRecordCreateRecord(data));
    } else if (this.requestId == requestId) {
        this.records.push(this._onContentRecordCreateRecord(data));
    }
};
ContentRecordHolder.prototype.reset = function () {
    this.requestId = null;
    this.structure = null;
    this.accountType = null;
    this.records = [];
};
ContentRecordHolder.prototype._onContentRecordGetHeaderCell = function () { };
ContentRecordHolder.prototype.getHeaderCell = function () {
    return this._onContentRecordGetHeaderCell();
};

export { AdProviderTab, ChartStructureTable, NameChartStructureTable, ItemWithTitle, StructureHolder, Graph, GraphRecord, DynamicDimension, DimensionHolder, ChartStructure, Cell, ContentRecordHolder }
