// source: color_patterns.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.adx.color_patterns.GetColorInfoRequest', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorInfoResponse', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorInfoResponse.ColorBar', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorInfoResponse.ColorScale', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorInfoResponse.Result', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorInfoResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorPatternsRequest', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorPatternsResponse', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorPatternsResponse.Detail', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorPatternsResponse.Pattern', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorPatternsResponse.Result', null, global);
goog.exportSymbol('proto.adx.color_patterns.GetColorPatternsResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorPatternsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.color_patterns.GetColorPatternsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorPatternsRequest.displayName = 'proto.adx.color_patterns.GetColorPatternsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorPatternsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.color_patterns.GetColorPatternsResponse.oneofGroups_);
};
goog.inherits(proto.adx.color_patterns.GetColorPatternsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorPatternsResponse.displayName = 'proto.adx.color_patterns.GetColorPatternsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.color_patterns.GetColorPatternsResponse.Detail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorPatternsResponse.Detail.displayName = 'proto.adx.color_patterns.GetColorPatternsResponse.Detail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.color_patterns.GetColorPatternsResponse.Pattern.repeatedFields_, null);
};
goog.inherits(proto.adx.color_patterns.GetColorPatternsResponse.Pattern, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorPatternsResponse.Pattern.displayName = 'proto.adx.color_patterns.GetColorPatternsResponse.Pattern';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.color_patterns.GetColorPatternsResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.color_patterns.GetColorPatternsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorPatternsResponse.Result.displayName = 'proto.adx.color_patterns.GetColorPatternsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.color_patterns.GetColorInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorInfoRequest.displayName = 'proto.adx.color_patterns.GetColorInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.color_patterns.GetColorInfoResponse.oneofGroups_);
};
goog.inherits(proto.adx.color_patterns.GetColorInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorInfoResponse.displayName = 'proto.adx.color_patterns.GetColorInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.displayName = 'proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.color_patterns.GetColorInfoResponse.ColorBar.repeatedFields_, null);
};
goog.inherits(proto.adx.color_patterns.GetColorInfoResponse.ColorBar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorInfoResponse.ColorBar.displayName = 'proto.adx.color_patterns.GetColorInfoResponse.ColorBar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.color_patterns.GetColorInfoResponse.ColorScale.repeatedFields_, null);
};
goog.inherits(proto.adx.color_patterns.GetColorInfoResponse.ColorScale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorInfoResponse.ColorScale.displayName = 'proto.adx.color_patterns.GetColorInfoResponse.ColorScale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.color_patterns.GetColorInfoResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.color_patterns.GetColorInfoResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.color_patterns.GetColorInfoResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.color_patterns.GetColorInfoResponse.Result.displayName = 'proto.adx.color_patterns.GetColorInfoResponse.Result';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorPatternsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorPatternsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorPatternsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorPatternsRequest}
 */
proto.adx.color_patterns.GetColorPatternsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorPatternsRequest;
  return proto.adx.color_patterns.GetColorPatternsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorPatternsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorPatternsRequest}
 */
proto.adx.color_patterns.GetColorPatternsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorPatternsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorPatternsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorPatternsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.color_patterns.GetColorPatternsRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorPatternsRequest} returns this
 */
proto.adx.color_patterns.GetColorPatternsRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.color_patterns.GetColorPatternsRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.color_patterns.GetColorPatternsRequest} returns this
 */
proto.adx.color_patterns.GetColorPatternsRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.color_patterns.GetColorPatternsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.color_patterns.GetColorPatternsResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.color_patterns.GetColorPatternsResponse.TypeCase}
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.color_patterns.GetColorPatternsResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.color_patterns.GetColorPatternsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorPatternsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.color_patterns.GetColorPatternsResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse}
 */
proto.adx.color_patterns.GetColorPatternsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorPatternsResponse;
  return proto.adx.color_patterns.GetColorPatternsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse}
 */
proto.adx.color_patterns.GetColorPatternsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.color_patterns.GetColorPatternsResponse.Result;
      reader.readMessage(value,proto.adx.color_patterns.GetColorPatternsResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorPatternsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.color_patterns.GetColorPatternsResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorPatternsResponse.Detail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Detail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.toObject = function(includeInstance, msg) {
  var f, obj = {
    colorcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    minrate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxrate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Detail}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorPatternsResponse.Detail;
  return proto.adx.color_patterns.GetColorPatternsResponse.Detail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Detail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Detail}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorcode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinrate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorPatternsResponse.Detail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Detail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColorcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMinrate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMaxrate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string colorCode = 1;
 * @return {string}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.prototype.getColorcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Detail} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.prototype.setColorcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 minRate = 2;
 * @return {number}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.prototype.getMinrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Detail} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.prototype.setMinrate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 maxRate = 3;
 * @return {number}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.prototype.getMaxrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Detail} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.Detail.prototype.setMaxrate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorPatternsResponse.Pattern.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.adx.color_patterns.GetColorPatternsResponse.Detail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorPatternsResponse.Pattern;
  return proto.adx.color_patterns.GetColorPatternsResponse.Pattern.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.color_patterns.GetColorPatternsResponse.Detail;
      reader.readMessage(value,proto.adx.color_patterns.GetColorPatternsResponse.Detail.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorPatternsResponse.Pattern.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.color_patterns.GetColorPatternsResponse.Detail.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Detail details = 3;
 * @return {!Array<!proto.adx.color_patterns.GetColorPatternsResponse.Detail>}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.adx.color_patterns.GetColorPatternsResponse.Detail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.color_patterns.GetColorPatternsResponse.Detail, 3));
};


/**
 * @param {!Array<!proto.adx.color_patterns.GetColorPatternsResponse.Detail>} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern} returns this
*/
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Detail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Detail}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.color_patterns.GetColorPatternsResponse.Detail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.Pattern.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorPatternsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    patternsList: jspb.Message.toObjectList(msg.getPatternsList(),
    proto.adx.color_patterns.GetColorPatternsResponse.Pattern.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Result}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorPatternsResponse.Result;
  return proto.adx.color_patterns.GetColorPatternsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Result}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = new proto.adx.color_patterns.GetColorPatternsResponse.Pattern;
      reader.readMessage(value,proto.adx.color_patterns.GetColorPatternsResponse.Pattern.deserializeBinaryFromReader);
      msg.addPatterns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorPatternsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPatternsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.color_patterns.GetColorPatternsResponse.Pattern.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Result} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Result} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Pattern patterns = 3;
 * @return {!Array<!proto.adx.color_patterns.GetColorPatternsResponse.Pattern>}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.getPatternsList = function() {
  return /** @type{!Array<!proto.adx.color_patterns.GetColorPatternsResponse.Pattern>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.color_patterns.GetColorPatternsResponse.Pattern, 3));
};


/**
 * @param {!Array<!proto.adx.color_patterns.GetColorPatternsResponse.Pattern>} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Result} returns this
*/
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.setPatternsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Pattern}
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.addPatterns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.color_patterns.GetColorPatternsResponse.Pattern, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse.Result} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.Result.prototype.clearPatternsList = function() {
  return this.setPatternsList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.color_patterns.GetColorPatternsResponse.Result}
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.color_patterns.GetColorPatternsResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.color_patterns.GetColorPatternsResponse.Result, 1));
};


/**
 * @param {?proto.adx.color_patterns.GetColorPatternsResponse.Result|undefined} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse} returns this
*/
proto.adx.color_patterns.GetColorPatternsResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.color_patterns.GetColorPatternsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.color_patterns.GetColorPatternsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.color_patterns.GetColorPatternsResponse} returns this
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.color_patterns.GetColorPatternsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.color_patterns.GetColorPatternsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorInfoRequest}
 */
proto.adx.color_patterns.GetColorInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorInfoRequest;
  return proto.adx.color_patterns.GetColorInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorInfoRequest}
 */
proto.adx.color_patterns.GetColorInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.color_patterns.GetColorInfoRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorInfoRequest} returns this
 */
proto.adx.color_patterns.GetColorInfoRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.color_patterns.GetColorInfoRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.color_patterns.GetColorInfoRequest} returns this
 */
proto.adx.color_patterns.GetColorInfoRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.color_patterns.GetColorInfoResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.color_patterns.GetColorInfoResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.color_patterns.GetColorInfoResponse.TypeCase}
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.color_patterns.GetColorInfoResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.color_patterns.GetColorInfoResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.color_patterns.GetColorInfoResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse}
 */
proto.adx.color_patterns.GetColorInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorInfoResponse;
  return proto.adx.color_patterns.GetColorInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse}
 */
proto.adx.color_patterns.GetColorInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.color_patterns.GetColorInfoResponse.Result;
      reader.readMessage(value,proto.adx.color_patterns.GetColorInfoResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.color_patterns.GetColorInfoResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.toObject = function(includeInstance, msg) {
  var f, obj = {
    cost: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cv: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cparoas: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator}
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator;
  return proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator}
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCost(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCv(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCparoas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCv();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCparoas();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string cost = 1;
 * @return {string}
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.prototype.getCost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.prototype.setCost = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cv = 2;
 * @return {string}
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.prototype.getCv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.prototype.setCv = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cparoas = 3;
 * @return {string}
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.prototype.getCparoas = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.prototype.setCparoas = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorInfoResponse.ColorBar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.toObject = function(includeInstance, msg) {
  var f, obj = {
    backendkey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    colorsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorInfoResponse.ColorBar;
  return proto.adx.color_patterns.GetColorInfoResponse.ColorBar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackendkey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addColors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorInfoResponse.ColorBar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBackendkey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string backendKey = 1;
 * @return {string}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.prototype.getBackendkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.prototype.setBackendkey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string colors = 2;
 * @return {!Array<string>}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.prototype.getColorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.prototype.setColorsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.prototype.addColors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorBar.prototype.clearColorsList = function() {
  return this.setColorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorInfoResponse.ColorScale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.toObject = function(includeInstance, msg) {
  var f, obj = {
    backendkey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    colorsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorInfoResponse.ColorScale;
  return proto.adx.color_patterns.GetColorInfoResponse.ColorScale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackendkey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addColors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorInfoResponse.ColorScale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBackendkey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string backendKey = 1;
 * @return {string}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.prototype.getBackendkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.prototype.setBackendkey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string colors = 2;
 * @return {!Array<string>}
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.prototype.getColorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.prototype.setColorsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.prototype.addColors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.ColorScale.prototype.clearColorsList = function() {
  return this.setColorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.color_patterns.GetColorInfoResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    keyindicator: (f = msg.getKeyindicator()) && proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.toObject(includeInstance, f),
    colorbarsList: jspb.Message.toObjectList(msg.getColorbarsList(),
    proto.adx.color_patterns.GetColorInfoResponse.ColorBar.toObject, includeInstance),
    colorscalesList: jspb.Message.toObjectList(msg.getColorscalesList(),
    proto.adx.color_patterns.GetColorInfoResponse.ColorScale.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.color_patterns.GetColorInfoResponse.Result;
  return proto.adx.color_patterns.GetColorInfoResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = new proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator;
      reader.readMessage(value,proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.deserializeBinaryFromReader);
      msg.setKeyindicator(value);
      break;
    case 4:
      var value = new proto.adx.color_patterns.GetColorInfoResponse.ColorBar;
      reader.readMessage(value,proto.adx.color_patterns.GetColorInfoResponse.ColorBar.deserializeBinaryFromReader);
      msg.addColorbars(value);
      break;
    case 5:
      var value = new proto.adx.color_patterns.GetColorInfoResponse.ColorScale;
      reader.readMessage(value,proto.adx.color_patterns.GetColorInfoResponse.ColorScale.deserializeBinaryFromReader);
      msg.addColorscales(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.color_patterns.GetColorInfoResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getKeyindicator();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator.serializeBinaryToWriter
    );
  }
  f = message.getColorbarsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.color_patterns.GetColorInfoResponse.ColorBar.serializeBinaryToWriter
    );
  }
  f = message.getColorscalesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.color_patterns.GetColorInfoResponse.ColorScale.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional KeyIndicator keyIndicator = 3;
 * @return {?proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.getKeyindicator = function() {
  return /** @type{?proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator} */ (
    jspb.Message.getWrapperField(this, proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator, 3));
};


/**
 * @param {?proto.adx.color_patterns.GetColorInfoResponse.KeyIndicator|undefined} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result} returns this
*/
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.setKeyindicator = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.clearKeyindicator = function() {
  return this.setKeyindicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.hasKeyindicator = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ColorBar colorBars = 4;
 * @return {!Array<!proto.adx.color_patterns.GetColorInfoResponse.ColorBar>}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.getColorbarsList = function() {
  return /** @type{!Array<!proto.adx.color_patterns.GetColorInfoResponse.ColorBar>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.color_patterns.GetColorInfoResponse.ColorBar, 4));
};


/**
 * @param {!Array<!proto.adx.color_patterns.GetColorInfoResponse.ColorBar>} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result} returns this
*/
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.setColorbarsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorBar}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.addColorbars = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.color_patterns.GetColorInfoResponse.ColorBar, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.clearColorbarsList = function() {
  return this.setColorbarsList([]);
};


/**
 * repeated ColorScale colorScales = 5;
 * @return {!Array<!proto.adx.color_patterns.GetColorInfoResponse.ColorScale>}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.getColorscalesList = function() {
  return /** @type{!Array<!proto.adx.color_patterns.GetColorInfoResponse.ColorScale>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.color_patterns.GetColorInfoResponse.ColorScale, 5));
};


/**
 * @param {!Array<!proto.adx.color_patterns.GetColorInfoResponse.ColorScale>} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result} returns this
*/
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.setColorscalesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.ColorScale}
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.addColorscales = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.color_patterns.GetColorInfoResponse.ColorScale, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse.Result} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.Result.prototype.clearColorscalesList = function() {
  return this.setColorscalesList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.color_patterns.GetColorInfoResponse.Result}
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.color_patterns.GetColorInfoResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.color_patterns.GetColorInfoResponse.Result, 1));
};


/**
 * @param {?proto.adx.color_patterns.GetColorInfoResponse.Result|undefined} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse} returns this
*/
proto.adx.color_patterns.GetColorInfoResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.color_patterns.GetColorInfoResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.color_patterns.GetColorInfoResponse} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.color_patterns.GetColorInfoResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.color_patterns.GetColorInfoResponse} returns this
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.color_patterns.GetColorInfoResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.color_patterns.GetColorInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.color_patterns);
