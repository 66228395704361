import {
    TabListOfSummaryReportPageRequest,
    ActualStatusReportRequest,
    MonthlyResultReportRequest,
    WeeklyResultReportRequest,
    DailyResultGraphReportRequest,
    DimensionCategoryResultReportRequest,
    LowPerformanceCampaignReportRequest,
    LowPerformanceAdGroupReportRequest,
    GetDimensionCategoryAndComponentRequest,
} from './protos_gen/summary_report_pb.js'
import { SummaryReportClient } from './protos_gen/summary_report_grpc_web_pb.js'

import {
    GetConsultantAdProviderCategoryDailyReportRequest,
    GetConsultantAdProviderCategoryMonthlyReportRequest,
    GetConsultantAdProviderCategoryWeeklyReportRequest,
    GetConsultantAdProviderCampaignMonthlyReportRequest,
    GetConsultantAdProviderCampaignWeeklyReportRequest,
    GetConsultantAdProviderCampaignDailyReportRequest,
    GetConsultantAdProviderAdGroupMonthlyReportRequest,
    GetConsultantAdProviderAdGroupWeeklyReportRequest,
    GetConsultantAdProviderAdGroupDailyReportRequest,
    GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest,
    GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest,
    GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest,
    GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest,
    GetConsultantAdProviderCategoriesDimensionDailyReportRequest,

} from './protos_gen/consultant_ad_provider_report_pb.js'
import { ConsultantAdProviderReportClient } from './protos_gen/consultant_ad_provider_report_grpc_web_pb.js'

import {
    GetAdProviderDynamicCategoryDailyReportRequest,
    GetAdProviderDynamicCategoryMonthlyReportRequest,
    GetAdProviderDynamicCategoryWeeklyReportRequest,
} from './protos_gen/dynamic_dimension_report_pb.js'
import { DynamicDimensionReportClient } from './protos_gen/dynamic_dimension_report_grpc_web_pb.js'


import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function getConsultantAdProviderAdGroupMonthlyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderAdGroupMonthlyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdproviderid(dto.adProviderId);
    request.setAdvertizerid(dto.advertizerId);
    request.setCampaign(dto.campaign);
    request.setLimit(27);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderAdGroupMonthlyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderAdGroupWeeklyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderAdGroupWeeklyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdproviderid(dto.adProviderId);
    request.setAdvertizerid(dto.advertizerId);
    request.setCampaign(dto.campaign);
    request.setLimit(30);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderAdGroupWeeklyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderAdGroupDailyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderAdGroupDailyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdproviderid(dto.adProviderId);
    request.setAdvertizerid(dto.advertizerId);
    request.setCampaign(dto.campaign);
    request.setLimit(60);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderAdGroupDailyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderCampaignMonthlyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderCampaignMonthlyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdproviderid(dto.adProviderId);
    request.setAdvertizerid(dto.advertizerId);
    request.setLimit(27);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderCampaignMonthlyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderCampaignWeeklyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderCampaignWeeklyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdproviderid(dto.adProviderId);
    request.setAdvertizerid(dto.advertizerId);
    request.setLimit(30);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderCampaignWeeklyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderCampaignDailyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderCampaignDailyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdproviderid(dto.adProviderId);
    request.setAdvertizerid(dto.advertizerId);
    request.setLimit(60);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderCampaignDailyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function _getConsultantAdProviderCategoryMonthlyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderCategoryMonthlyReportRequest();
    request.setRequestid(uuidStr);
    request.setLimit(27);
    if ('adProviderId' in dto) {
        request.setAdproviderid(dto.adProviderId);
    }
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderCategoryMonthlyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function _getConsultantAdProviderCategoryWeeklyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderCategoryWeeklyReportRequest();
    request.setRequestid(uuidStr);
    request.setLimit(30);
    if ('adProviderId' in dto) {
        request.setAdproviderid(dto.adProviderId);
    }
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderCategoryWeeklyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

function _getConsultantAdProviderCategoryDailyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderCategoryDailyReportRequest();
    request.setRequestid(uuidStr);
    request.setLimit(60);
    if ('adProviderId' in dto) {
        request.setAdproviderid(dto.adProviderId);
    }
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderCategoryDailyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

function getTabListOfSummaryReportPage(id, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
    };
    let uuidStr = uuid();
    let request = new TabListOfSummaryReportPageRequest();
    request.setAdpageid(id);
    request.setAdvertizerid(dto.advertizerId);

    let client = new SummaryReportClient(url.grpc, null, null);
    const stream = client.getTabListOfSummaryReportPage(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        console.log(e);
    });
    //TBD エラー

    return uuidStr;
}

function getActualStatusReport(id, dto, callback, callback_end = () => { }, currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new ActualStatusReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }
    request.setAdvertizerid(id);

    let client = new SummaryReportClient(url.grpc, null, null);
    const stream = client.getActualStatusReport(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

function getMonthlyResultReport(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new MonthlyResultReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(id);
    request.setAdvertizerid(dto.advertizerId);
    if (user_role == "consultant") request.setLimit(27);
    if ('limit' in dto) {
        request.setLimit(dto.limit);
    }
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new SummaryReportClient(url.grpc, null, null);
    const stream = client.getMonthlyResultReport(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getWeeklyResultReport(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new WeeklyResultReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    if (user_role == "consultant") request.setLimit(30);
    if ('limit' in dto) {
        request.setLimit(dto.limit);
    }
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new SummaryReportClient(url.grpc, null, null);
    const stream = client.getWeeklyResultReport(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getDailyResultGraphReport(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new DailyResultGraphReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    if (user_role == "consultant") request.setLimit(60);
    if ('limit' in dto) {
        request.setLimit(dto.limit);
    }
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new SummaryReportClient(url.grpc, null, null);
    const stream = client.getDailyResultGraphReport(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getDimensionCategoryResultReport(id, pageComponentId, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new DimensionCategoryResultReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setAdpagecomponentid(pageComponentId);
    if (user_role == "consultant") request.setLimit(0);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new SummaryReportClient(url.grpc, null, null);
    const stream = client.getDimensionCategoryResultReport(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getLowPerformanceCampaignReport(id, pageComponentId, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new LowPerformanceCampaignReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }
    request.setAdpagecomponentid(pageComponentId);

    let client = new SummaryReportClient(url.grpc, null, null);
    const stream = client.getLowPerformanceCampaignReport(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getLowPerformanceAdGroupReport(id, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let actualMonth = dto.actualMonth;
    let startDate = dto.startDate;
    let endDate = dto.endDate;

    let request = new LowPerformanceAdGroupReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setAdproviderid(dto.adProviderId);
    request.setBackendkey(dto.backendKey);
    request.setCampaign(dto.campaign);
    request.setActualmonth(actualMonth);
    request.setStartdate(startDate);
    request.setEnddate(endDate);

    let client = new SummaryReportClient(url.grpc, null, null);
    const stream = client.getLowPerformanceAdGroupReport(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}


function getConsultantAdProviderDimensionCampaignAdGroupMonthlyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdproviderid(dto.adProviderId);
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderDimensionCampaignAdGroupMonthlyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setAdproviderid(dto.providerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport(request, meta);
    currentPageStreams.push(stream);

    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderCategoriesDimensionMonthlyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setAdproviderid(dto.adProviderId);
    request.setLimit(27);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }
    request.setCategoryidsList(dto.categoryIds);

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderCategoriesDimensionMonthlyReport(request, meta);
    currentPageStreams.push(stream);

    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderCategoriesDimensionWeeklyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setAdproviderid(dto.adProviderId);
    request.setLimit(30);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }
    request.setCategoryidsList(dto.categoryIds);

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderCategoriesDimensionWeeklyReport(request, meta);
    currentPageStreams.push(stream);

    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderCategoriesDimensionDailyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetConsultantAdProviderCategoriesDimensionDailyReportRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(dto.advertizerId);
    request.setAdproviderid(dto.adProviderId);
    request.setLimit(60);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }
    request.setCategoryidsList(dto.categoryIds);

    let client = new ConsultantAdProviderReportClient(url.grpc, null, null);
    const stream = client.getConsultantAdProviderCategoriesDimensionDailyReport(request, meta);
    currentPageStreams.push(stream);

    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderCategoryMonthlyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetAdProviderDynamicCategoryMonthlyReportRequest();
    request.setRequestid(uuidStr);
    request.setLimit(27);
    if ('adProviderId' in dto) {
        request.setAdproviderid(dto.adProviderId);
    }
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new DynamicDimensionReportClient(url.grpc, null, null);
    const stream = client.getAdProviderDynamicCategoryMonthlyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        // console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderCategoryWeeklyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetAdProviderDynamicCategoryWeeklyReportRequest();
    request.setRequestid(uuidStr);
    request.setLimit(30);
    if ('adProviderId' in dto) {
        request.setAdproviderid(dto.adProviderId);
    }
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new DynamicDimensionReportClient(url.grpc, null, null);
    const stream = client.getAdProviderDynamicCategoryWeeklyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

function getConsultantAdProviderCategoryDailyReport(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetAdProviderDynamicCategoryDailyReportRequest();
    request.setRequestid(uuidStr);
    request.setLimit(60);
    if ('adProviderId' in dto) {
        request.setAdproviderid(dto.adProviderId);
    }
    request.setAdvertizerid(dto.advertizerId);
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new DynamicDimensionReportClient(url.grpc, null, null);
    const stream = client.getAdProviderDynamicCategoryDailyReport(request, meta);
    currentPageStreams.push(stream);
    let stream_idx = 0;
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject(), stream_idx++);
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}



function getDimensionCategoryAndComponent(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetDimensionCategoryAndComponentRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(id);

    let client = new SummaryReportClient(url.grpc, null, null);
    const stream = client.getDimensionCategoryAndComponent(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        // throw e;
        callback_end(uuidStr, e);
    });
    //TBD エラー

    return uuidStr;
}


export {
    getTabListOfSummaryReportPage,
    getActualStatusReport,
    getMonthlyResultReport,
    getWeeklyResultReport,
    getDailyResultGraphReport,
    getDimensionCategoryResultReport,
    getLowPerformanceCampaignReport,
    getLowPerformanceAdGroupReport,
    getConsultantAdProviderCategoryMonthlyReport,
    getConsultantAdProviderCategoryWeeklyReport,
    getConsultantAdProviderCategoryDailyReport,
    getConsultantAdProviderCampaignMonthlyReport,
    getConsultantAdProviderCampaignWeeklyReport,
    getConsultantAdProviderCampaignDailyReport,
    getConsultantAdProviderAdGroupMonthlyReport,
    getConsultantAdProviderAdGroupWeeklyReport,
    getConsultantAdProviderAdGroupDailyReport,
    getConsultantAdProviderDimensionCampaignAdGroupMonthlyReport,
    getConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport,
    getConsultantAdProviderCategoriesDimensionMonthlyReport,
    getConsultantAdProviderCategoriesDimensionWeeklyReport,
    getConsultantAdProviderCategoriesDimensionDailyReport,
    getDimensionCategoryAndComponent,
}
