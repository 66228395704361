<template>
  <div class="calendar">
    <div class="calendar__dow">
      <template v-for="(dow, index) in dows" :key="index">
        <div class="calendar__dow_day">
          <div class="calendar__dow_day_box">
            <div class="calendar__dow_day_item">{{ dow }}</div>
          </div>
        </div>
      </template>
    </div>

    <template v-for="(week, index) in weekdata()" :key="index">
      <div class="calendar__week">
        <template v-for="(day, index) in week" :key="index">
          <div class="calendar__week_day" v-on:click="selectDay(day)">
            <div
              class="calendar__week_day_box"
              v-bind:class="[isRangeDay(day) ? 'calendar__dow_day_box--range' : '']"
            >
              <div
                class="calendar__week_day_item"
                v-bind:class="[
                  isStartDay(day) ? 'calendar__week_day_item--startday' : '',
                  isEndDay(day) ? 'calendar__week_day_item--endday' : '',
                  isRangeDay(day) ? 'calendar__week_day_item--active' : '',
                ]"
                v-if="day > 0"
              >
                {{ day }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped>
.calendar {
  width: 100%;
  font-size: 12px;
  margin: auto;
  text-align: center;
}

.calendar__dow {
  display: flex;
}

.calendar__dow_day {
  width: 15%;
}
.calendar__dow_day--return {
  width: 14%;
}

.calendar__dow_day_box {
  width: 36px;
  height: 36px;
  margin: auto;
}

.calendar__dow_day_box--range {
  width: 100%;
}

.calendar__dow_day_item {
  border-radius: 50%;
  padding: 8px;
  margin: auto;
}

.calendar__week {
  display: flex;
  pointer-events: all;
}

.calendar__week_day {
  margin-top: 5px;
  width: 15%;
}

.calendar__week_day:hover {
  background: #0033ff88;
}

.calendar__week_day_box {
  height: 36px;
  margin: auto;
}

.calendar__week_day_item {
  padding: 8px;
  margin: auto;
}

.calendar__week_day_item--active {
  background: #0033ff88;
}

.calendar__week_day_item--startday {
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  background: #0033ff88;
}

.calendar__week_day_item--endday {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  background: #0033ff88;
}
</style>

<script>
export default {
  name: 'Calendar',
  data() {
    return {
      name: 'Calendar',
      inputData: '',
      dows: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      weeks: [],
      showYear: 2021,
      showMonth: 1,
    };
  },
  computed: {},
  mounted() {
    let self = this;
    if (this.range) {
      this.range.subject.addObserver(function () {
        if (self) {
          //
        }
        //self.draw();
      });
    }
  },
  methods: {
    weekdata() {
      let startDate = new Date(this.year, this.month - 1, 1);
      let endDate = new Date(this.year, this.month - 1 + 1, 0);
      let startDay = startDate.getDay();
      let textDate = 0;
      let weeks = [];
      for (var r = 0; r < 6; r++) {
        let days = [];
        for (var w = 0; w < 7; w++) {
          if (textDate == 0) {
            if (startDay == w) {
              textDate = 1;
              days.push(textDate);
            } else {
              days.push(-1);
            }
          } else {
            if (textDate < endDate.getDate()) {
              if (textDate > 0) {
                textDate++;
              }
            } else {
              textDate = -1;
            }
            days.push(textDate);
          }
        }
        weeks.push(days);
        if (textDate < 0) {
          break;
        }
      }
      return weeks;
    },
    selectDay(day) {
      if (day < 0) {
        return;
      }
      let s = new Date(
        this.range.startYmd.year,
        this.range.startYmd.month - 1,
        this.range.startYmd.day
      );
      let e = new Date(this.range.endYmd.year, this.range.endYmd.month - 1, this.range.endYmd.day);
      let c = new Date(this.year, this.month - 1, day);
      if (this.mode == 'start') {
        if (e.getTime() >= c) {
          this.range.setStartYmd(this.year, this.month, day);
        }
      }
      if (this.mode == 'end') {
        if (s.getTime() <= c) {
          this.range.setEndYmd(this.year, this.month, day);
        }
      }
      if (this.mode == 'one') {
        this.range.setStartYmd(this.year, this.month, day);
        this.range.setEndYmd(this.year, this.month, day);
      }
    },
    isStartDay(day) {
      if (this.range) {
        if (this.month == this.range.startYmd.month) {
          if (day == this.range.startYmd.day) {
            return true;
          }
        }
      }
      return false;
    },
    isEndDay(day) {
      if (this.range) {
        if (this.month == this.range.endYmd.month) {
          if (day == this.range.endYmd.day) {
            return true;
          }
        }
      }
      return false;
    },
    isRangeDay(day) {
      if (this.range) {
        let s = new Date(
          this.range.startYmd.year,
          this.range.startYmd.month - 1,
          this.range.startYmd.day
        );
        let e = new Date(
          this.range.endYmd.year,
          this.range.endYmd.month - 1,
          this.range.endYmd.day
        );
        let n = new Date(this.year, this.month - 1, day);
        if (e > n && s < n) {
          return true;
        }
      }
      return false;
    },
  },
  components: {},
  props: {
    mode: String,
    year: Number,
    month: Number,
    range: Object,
  },
};
</script>
