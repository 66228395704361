import { createRouter, createWebHistory } from 'vue-router'
import ReportKeyword from '../../views/report/Keyword.vue'
import ReportAdProvider from '../../views/report/AdProvider.vue'
import ReportSummary from '../../views/report/Summary.vue'
import ReportDimension from '../../views/report/Dimension.vue'
import News from '../../views/news/News.vue'
import BudgetAllocation from '../../views/budget/Allocation.vue'
import BudgetSimulation from '../../views/budget/Simulation.vue'
import BudgetAllocations from '../../views/budget/Allocations.vue'
import BudgetSimulations from '../../views/budget/Simulations.vue'
import SeamlessLog from '../../views/log/SeamlessLog.vue'
// import Log from '../../views/log/Log.vue'
import ConsultantSummary from '../../views/consultant/Summary.vue'
import ConsultantAdProvider from '../../views/consultant/AdProvider.vue'
import ManagerSummary from '../../views/manager/Summary.vue'
import ConsultantSprint from '../../views/consultant/Sprint.vue'
import ConsultantCustomReport from '../../views/consultant/CustomReport.vue'

//import Empty from '../../views/Empty.vue'

const routes = [
  {
    path: '/index.html',
    redirect: '/report/summary',
  },
  {
    path: '/',
    redirect: '/report/summary',
  },
  {
    path: '/report',
    redirect: '/report/summary',
  },
  {
    path: '/budget',
    redirect: '/budget/simulation',
  },
  {
    path: '/log',
    name: 'Log',
    component: SeamlessLog
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/report/summary',
    name: 'ReportSummary',
    component: ReportSummary
  },
  {
    path: '/report/adprovider',
    name: 'ReportAdProvider',
    component: ReportAdProvider
  },
  {
    path: '/report/dimension/:id/:cid',
    name: 'ReportDimension',
    component: ReportDimension
  },
  {
    path: '/report/keyword/:id/:cid',
    name: 'ReportKeyword',
    component: ReportKeyword
  },
  {
    path: '/budget/allocation',
    name: 'BudgetAllocation',
    component: BudgetAllocation
  },
  {
    path: '/budget/simulation',
    name: 'BudgetSimulation',
    component: BudgetSimulation
  },
  {
    path: '/budget/allocations',
    name: 'BudgetAllocations',
    component: BudgetAllocations
  },
  {
    path: '/budget/simulations',
    name: 'BudgetSimulations',
    component: BudgetSimulations
  },
  {
    path: '/consultant/summary',
    name: 'ConsultantSummary',
    component: ConsultantSummary
  },
  {
    path: '/consultant/adprovider',
    name: 'ConsultantAdProvider',
    component: ConsultantAdProvider
  },
  {
    path: '/manager/summary',
    name: 'ManagerSummary',
    component: ManagerSummary
  },
  {
    path: '/consultant/sprint',
    name: 'ConsultantSprint',
    component: ConsultantSprint
  },
  {
    path: '/consultant/customreport',
    name: 'ConsultantCustomReport',
    component: ConsultantCustomReport
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
