
import {
    CreateActionLogsRequest,
    DeleteActionLogsRequest,
    DeleteActionLogsFilesRequest,
    GetActionLogsRequest,
    GetActionLogsFilesRequest,
    UpdateActionLogsRequest,
    SaveActionLogsOrderRequest,
} from './protos_gen/action_log_pb'
import { ActionLogClient } from './protos_gen/action_log_grpc_web_pb'
import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function createActionLog(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new CreateActionLogsRequest();
    request.setRequestid(uuidStr);
    request.setStatus(dto.status);
    request.setImplementationstart(dto.implementationStart);
    request.setImplementationend(dto.implementationEnd);
    request.setAdprovider(dto.adProvider);
    request.setTitle(dto.title);
    request.setPurpose(dto.purpose);
    request.setDetail(dto.detail);
    request.setValidationstart(dto.validationStart);
    request.setValidationend(dto.validationEnd);
    request.setResult(dto.result);
    request.setDetailofresult(dto.detailOfResult);
    request.setNextaction(dto.nextAction);
    request.setAdvertizerid(id);
    for (let i in dto?.tags) {
        let tag = dto.tags[i];
        request.addTags(tag);
    }
    request.setNote(dto.note);

    let client = new ActionLogClient(url.grpc, null, null);
    const stream = client.createActionLogs(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}
function deleteActionLog(dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new DeleteActionLogsRequest();
    request.setRequestid(uuidStr);
    request.setId(dto.id);

    let client = new ActionLogClient(url.grpc, null, null);
    const stream = client.deleteActionLogs(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;

}
function deleteActionLogFile(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new DeleteActionLogsFilesRequest();
    request.setAdvertizerid(dto.advertizerId);
    request.setRequestid(uuidStr);
    request.setFileid(id);

    let client = new ActionLogClient(url.grpc, null, null);
    const stream = client.deleteActionLogsFiles(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;

}
function getActionLogs(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetActionLogsRequest();
    request.setRequestid(uuidStr);
    request.setSize(10000);
    request.setPage(1);
    // request.setSort(dto.sort);
    // request.setStatus(dto.status);
    if (dto) {
        if (dto?.sorts) {
            for (let i in dto.sorts) {
                request.addSort(dto.sorts[i]);
            }
        }
    }
    request.setAdvertizerid(id);

    if (dto) {
        if (dto.filter) {
            let filter = new GetActionLogsRequest.Filter();
            filter.setOperator(dto.filter.operator);
            for (let i in dto.filter.conditions) {
                let c = new GetActionLogsRequest.Filter.Condition();
                c.setProperty(dto.filter.conditions[i].property);
                c.setValue(dto.filter.conditions[i].value);
                c.setMatch(dto.filter.conditions[i].match);
                filter.addConditions(c);
            }
            request.setFilter(filter);
        }
    }

    let client = new ActionLogClient(url.grpc, null, null);
    const stream = client.getActionLogs(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;

}
function getActionLogFiles(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetActionLogsFilesRequest();
    request.setRequestid(uuidStr);
    request.setFileid(id);

    let client = new ActionLogClient(url.grpc, null, null);
    const stream = client.getActionLogsFiles(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;

}
function updateActionLog(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new UpdateActionLogsRequest();
    request.setRequestid(uuidStr);
    request.setId(dto.id);
    request.setStatus(dto.status);
    request.setImplementationstart(dto.implementationStart);
    request.setImplementationend(dto.implementationEnd);
    request.setAdprovider(dto.adProvider);
    request.setTitle(dto.title);
    request.setPurpose(dto.purpose);
    request.setDetail(dto.detail);
    request.setValidationstart(dto.validationStart);
    request.setValidationend(dto.validationEnd);
    request.setResult(dto.result);
    request.setDetailofresult(dto.detailOfResult);
    request.setNextaction(dto.nextAction);
    request.setAdvertizerid(id);
    for (let i in dto?.tags) {
        let tag = dto.tags[i];
        request.addTags(tag);
    }
    request.setNote(dto.note);

    let client = new ActionLogClient(url.grpc, null, null);
    const stream = client.updateActionLogs(request, meta);
    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;

}
function saveActionLogsOrder(id, dto, callback, callback_end = () => { }, user_role = "", currentPageStreams = []) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'advertizerId': dto.advertizerId,
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new SaveActionLogsOrderRequest();
    request.setRequestid(uuidStr);
    for (let i in dto?.idors) {
        let ao = new SaveActionLogsOrderRequest.ActionLogOrder();
        let idorder = dto.idors[i];
        ao.setId(idorder.id);
        ao.setOrder(idorder.order);
        request.addActionlogsorder(ao);
    }

    let client = new ActionLogClient(url.grpc, null, null);
    const stream = client.saveActionLogsOrder(request, meta);

    currentPageStreams.push(stream);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('end', message => {
        callback_end(uuidStr, message);
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

export {
    createActionLog,
    deleteActionLog,
    deleteActionLogFile,
    getActionLogs,
    getActionLogFiles,
    updateActionLog,
    saveActionLogsOrder
}
