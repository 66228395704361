/**
 * @fileoverview gRPC-Web generated client stub for adx.budget_allocation
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.budget_allocation = require('./budget_allocation_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.budget_allocation.BudgetAllocationClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.budget_allocation.BudgetAllocationPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest,
 *   !proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse>}
 */
const methodDescriptor_BudgetAllocation_GetCampaignAllocatedBudgetInitialBuild = new grpc.web.MethodDescriptor(
  '/adx.budget_allocation.BudgetAllocation/GetCampaignAllocatedBudgetInitialBuild',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest,
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse,
  /**
   * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_allocation.BudgetAllocationClient.prototype.getCampaignAllocatedBudgetInitialBuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_allocation.BudgetAllocation/GetCampaignAllocatedBudgetInitialBuild',
      request,
      metadata || {},
      methodDescriptor_BudgetAllocation_GetCampaignAllocatedBudgetInitialBuild);
};


/**
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_allocation.CampaignAllocatedBudgetInitialBuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_allocation.BudgetAllocationPromiseClient.prototype.getCampaignAllocatedBudgetInitialBuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_allocation.BudgetAllocation/GetCampaignAllocatedBudgetInitialBuild',
      request,
      metadata || {},
      methodDescriptor_BudgetAllocation_GetCampaignAllocatedBudgetInitialBuild);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest,
 *   !proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse>}
 */
const methodDescriptor_BudgetAllocation_GetCampaignAllocatedBudgetRebuild = new grpc.web.MethodDescriptor(
  '/adx.budget_allocation.BudgetAllocation/GetCampaignAllocatedBudgetRebuild',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest,
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse,
  /**
   * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse.deserializeBinary
);


/**
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_allocation.BudgetAllocationClient.prototype.getCampaignAllocatedBudgetRebuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_allocation.BudgetAllocation/GetCampaignAllocatedBudgetRebuild',
      request,
      metadata || {},
      methodDescriptor_BudgetAllocation_GetCampaignAllocatedBudgetRebuild);
};


/**
 * @param {!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_allocation.CampaignAllocatedBudgetRebuildResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_allocation.BudgetAllocationPromiseClient.prototype.getCampaignAllocatedBudgetRebuild =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_allocation.BudgetAllocation/GetCampaignAllocatedBudgetRebuild',
      request,
      metadata || {},
      methodDescriptor_BudgetAllocation_GetCampaignAllocatedBudgetRebuild);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.budget_allocation.AllocatedDailyBudgetRequest,
 *   !proto.adx.budget_allocation.Response>}
 */
const methodDescriptor_BudgetAllocation_UpdateAllocatedDailyBudget = new grpc.web.MethodDescriptor(
  '/adx.budget_allocation.BudgetAllocation/UpdateAllocatedDailyBudget',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.budget_allocation.AllocatedDailyBudgetRequest,
  proto.adx.budget_allocation.Response,
  /**
   * @param {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.budget_allocation.Response.deserializeBinary
);


/**
 * @param {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_allocation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_allocation.BudgetAllocationClient.prototype.updateAllocatedDailyBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_allocation.BudgetAllocation/UpdateAllocatedDailyBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetAllocation_UpdateAllocatedDailyBudget);
};


/**
 * @param {!proto.adx.budget_allocation.AllocatedDailyBudgetRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.budget_allocation.Response>}
 *     The XHR Node Readable Stream
 */
proto.adx.budget_allocation.BudgetAllocationPromiseClient.prototype.updateAllocatedDailyBudget =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.budget_allocation.BudgetAllocation/UpdateAllocatedDailyBudget',
      request,
      metadata || {},
      methodDescriptor_BudgetAllocation_UpdateAllocatedDailyBudget);
};


module.exports = proto.adx.budget_allocation;

