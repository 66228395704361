<template>
  <div style="width: 100%">
    <div style="padding: 8px; margin: auto; width: 100%" v-if="isUploading">
      <div class="smallspinningcircle"></div>
    </div>
    <div
      style="width: 100%; text-align: center; height: auto"
      v-if="!isUploading"
      v-show="uploader.user.role != 'advertizer'"
    >
      <label style="padding: 8px">
        + Add a file or image
        <input type="file" ref="datafile" />
      </label>
    </div>
  </div>
</template>

<style scoped>
.smallspinningcircle {
  margin: auto;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0);
  border-top-color: 4px solid #39434e;
  border-right-color: 4px solid #39434e;
  -webkit-animation: single 2s infinite linear;
  animation: single 2s infinite linear;
}

@-webkit-keyframes single {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
  50% {
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
}

@keyframes single {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
  50% {
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
    border-top-color: #39434e;
    border-right-color: #39434e;
    border-bottom-color: #39434e;
  }
}
</style>


<style scoped>
input[type='file'] {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  height: 10px;
}

label {
  width: 100%;
  height: 100%;
  display: inline-block;
  cursor: pointer;
}
</style>

<script>
export default {
  name: 'SelectAutoUploader',
  data() {
    return {
      name: 'SelectAutoUploader',
      isUploading: false,
    };
  },
  computed: {},
  mounted() {
    let self = this;
    this.$refs.datafile.onchange = function () {
      let el = self.$refs.datafile;
      self.isUploading = true;
      try {
        self.uploader.upload(el.files[0]);
      } catch (ex) {
        console.log(ex);
      }
    };
  },
  emits: [],
  methods: {},
  components: {},
  props: {
    uploader: Object,
  },
};
</script>
