/**
 * @fileoverview gRPC-Web generated client stub for adx.excel_report
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.excel_report = require('./excel_report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.excel_report.ExcelReportClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.excel_report.ExcelReportPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.excel_report.GetAdBindingKeysRequest,
 *   !proto.adx.excel_report.GetAdBindingKeysResponse>}
 */
const methodDescriptor_ExcelReport_GetAdBindingKeys = new grpc.web.MethodDescriptor(
  '/adx.excel_report.ExcelReport/GetAdBindingKeys',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.excel_report.GetAdBindingKeysRequest,
  proto.adx.excel_report.GetAdBindingKeysResponse,
  /**
   * @param {!proto.adx.excel_report.GetAdBindingKeysRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.excel_report.GetAdBindingKeysResponse.deserializeBinary
);


/**
 * @param {!proto.adx.excel_report.GetAdBindingKeysRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetAdBindingKeysResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportClient.prototype.getAdBindingKeys =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetAdBindingKeys',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetAdBindingKeys);
};


/**
 * @param {!proto.adx.excel_report.GetAdBindingKeysRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetAdBindingKeysResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportPromiseClient.prototype.getAdBindingKeys =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetAdBindingKeys',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetAdBindingKeys);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.excel_report.GetExcelReportTemplateRequest,
 *   !proto.adx.excel_report.GetExcelReportTemplateResponse>}
 */
const methodDescriptor_ExcelReport_GetExcelReportTemplate = new grpc.web.MethodDescriptor(
  '/adx.excel_report.ExcelReport/GetExcelReportTemplate',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.excel_report.GetExcelReportTemplateRequest,
  proto.adx.excel_report.GetExcelReportTemplateResponse,
  /**
   * @param {!proto.adx.excel_report.GetExcelReportTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.excel_report.GetExcelReportTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.adx.excel_report.GetExcelReportTemplateRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetExcelReportTemplateResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportClient.prototype.getExcelReportTemplate =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetExcelReportTemplate',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetExcelReportTemplate);
};


/**
 * @param {!proto.adx.excel_report.GetExcelReportTemplateRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetExcelReportTemplateResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportPromiseClient.prototype.getExcelReportTemplate =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetExcelReportTemplate',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetExcelReportTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest,
 *   !proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse>}
 */
const methodDescriptor_ExcelReport_GetAdProviderDynamicCategoryMonthlyReport = new grpc.web.MethodDescriptor(
  '/adx.excel_report.ExcelReport/GetAdProviderDynamicCategoryMonthlyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest,
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse,
  /**
   * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportClient.prototype.getAdProviderDynamicCategoryMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetAdProviderDynamicCategoryMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetAdProviderDynamicCategoryMonthlyReport);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetAdProviderDynamicCategoryMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportPromiseClient.prototype.getAdProviderDynamicCategoryMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetAdProviderDynamicCategoryMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetAdProviderDynamicCategoryMonthlyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest,
 *   !proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse>}
 */
const methodDescriptor_ExcelReport_GetAdProviderDynamicCategoryWeeklyReport = new grpc.web.MethodDescriptor(
  '/adx.excel_report.ExcelReport/GetAdProviderDynamicCategoryWeeklyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest,
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse,
  /**
   * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportClient.prototype.getAdProviderDynamicCategoryWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetAdProviderDynamicCategoryWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetAdProviderDynamicCategoryWeeklyReport);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetAdProviderDynamicCategoryWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportPromiseClient.prototype.getAdProviderDynamicCategoryWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetAdProviderDynamicCategoryWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetAdProviderDynamicCategoryWeeklyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest,
 *   !proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse>}
 */
const methodDescriptor_ExcelReport_GetAdProviderDynamicCategoryDailyReport = new grpc.web.MethodDescriptor(
  '/adx.excel_report.ExcelReport/GetAdProviderDynamicCategoryDailyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest,
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse,
  /**
   * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportClient.prototype.getAdProviderDynamicCategoryDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetAdProviderDynamicCategoryDailyReport',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetAdProviderDynamicCategoryDailyReport);
};


/**
 * @param {!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.excel_report.GetAdProviderDynamicCategoryDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.excel_report.ExcelReportPromiseClient.prototype.getAdProviderDynamicCategoryDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.excel_report.ExcelReport/GetAdProviderDynamicCategoryDailyReport',
      request,
      metadata || {},
      methodDescriptor_ExcelReport_GetAdProviderDynamicCategoryDailyReport);
};


module.exports = proto.adx.excel_report;

