import * as tacklebox from '../tacklebox/core/index.js';

function ComponentInvisibleColumn(type, invisiblenumbers = [], saver = null) {
    this.type = type;
    this.invisiblenumbers = invisiblenumbers;
    this.saver = saver;
}
ComponentInvisibleColumn.prototype.setNumberInvisible = function (number) {
    for (let i in this.invisiblenumbers) {
        let inn = this.invisiblenumbers[i];
        if (inn == number) {
            return;
        }
    }
    this.invisiblenumbers.push(number);
    if (this.saver) {
        this.saver.save(this.invisiblenumbers);
    }
};
ComponentInvisibleColumn.prototype.toggleNumberInvisible = function (number) {
    for (let i = 0; i < this.invisiblenumbers.length; i++) {
        let inn = this.invisiblenumbers[i];
        if (inn == number) {
            this.invisiblenumbers.splice(i, 1);
            try {
                if (this.saver) {
                    this.saver.save(this.invisiblenumbers);
                }
            } catch (ex) {
                console.log(ex);
            }
            return;
        }
    }
    this.invisiblenumbers.push(number);
    if (this.saver) {
        this.saver.save(this.invisiblenumbers);
    }
};
ComponentInvisibleColumn.prototype.reset = function () {
    this.invisiblenumbers = [];
    if (this.saver) {
        this.saver.save(this.invisiblenumbers);
    }
}
ComponentInvisibleColumn.prototype.isHide = function (number) {
    for (let i = 0; i < this.invisiblenumbers.length; i++) {
        let inn = this.invisiblenumbers[i];
        if (inn == number) {
            return true;
        }
    }

    return false;
}


function PageInvisibleColumnStocker(type, componentInvisibles = []) {
    this.type = type;
    this.componentInvisibles = componentInvisibles;
}
PageInvisibleColumnStocker.prototype.setPageInvisible = function (componentInvisible) {
    let isHit = false;
    for (let i in this.componentInvisibles) {
        let component = this.componentInvisibles[i];
        if (component.type == componentInvisible.type) {
            isHit = true;
            //更新
        }
    }
    if (!isHit) {
        this.componentInvisibles.push(componentInvisible);
    }
};

function AdvertizerInvisibleColumnStocker(advertizer_id, pagesinvisibles = []) {
    this.advertizer_id = advertizer_id;
    this.pagesinvisibles = pagesinvisibles;
}
AdvertizerInvisibleColumnStocker.prototype.setPageInvisible = function (pageinvisible) {
    let isHit = false;
    for (let i in this.pagesinvisibles) {
        let page = this.pagesinvisibles[i];
        if (page.type == pageinvisible.type) {
            isHit = true;
            //更新
        }
    }
    if (!isHit) {
        this.pagesinvisibles.push(pageinvisible);
    }
};
AdvertizerInvisibleColumnStocker.prototype.getComponentInvisibleColumn = function (page_type, component_type) {
    for (let i in this.pagesinvisibles) {
        let page = this.pagesinvisibles[i];
        if (page.type == page_type) {
            for (let j in page.componentInvisibles) {
                let component = page.componentInvisibles[j];
                if (component.type == component_type) {
                    return component;
                }
            }
        }
    }
    return null;
}

function ColumnSaver(advertizer_id, page_id, component_id) {
    this.advertizer_id = advertizer_id;
    this.page_id = page_id;
    this.component_id = component_id;
}
ColumnSaver.prototype.save = function (values) {
    try {
        let jmsg = JSON.stringify(values)
        localStorage.setItem("colv_" + this.advertizer_id + "_" + this.page_id + "_" + this.component_id, jmsg);
    } catch (ex) {
        console.log(ex);
    }
};
ColumnSaver.prototype.load = function () {
    try {
        let smsg = localStorage.getItem("colv_" + this.advertizer_id + "_" + this.page_id + "_" + this.component_id);
        if (smsg) {
            return JSON.parse(smsg);
        }
    } catch (ex) {
        console.log(ex);
    }
    return [];
}

function ColumnStylesSaver(advertizer_id, page_id, component_id) {
    ColumnSaver.call(this, advertizer_id, page_id, component_id);
}
tacklebox.extend(ColumnStylesSaver, ColumnSaver);
ColumnStylesSaver.prototype.save = function (css) {
    try {
        let jmsg = JSON.stringify(css)
        localStorage.setItem("colstyle_" + this.advertizer_id + "_" + this.page_id + "_" + this.component_id, jmsg);
    } catch (ex) {
        console.log(ex);
    }
};
ColumnStylesSaver.prototype.load = function () {
    try {
        let smsg = localStorage.getItem("colstyle_" + this.advertizer_id + "_" + this.page_id + "_" + this.component_id);
        if (smsg) {
            let css = [];
            let js = JSON.parse(smsg);
            for (let i in js) {
                let j = js[i];
                css.push(new ColumnStyle(j.index, j.style));
            }
            return css;
        } else {
            return [];
        }
    } catch (ex) {
        console.log(ex);
    }
    return [];
}


function InvisibleColumnStocker(advertizerinvisibles = []) {
    this.advertizerinvisibles = advertizerinvisibles;
}
InvisibleColumnStocker.prototype.setAdvertizerInvisible = function (advertizerinvisible) {
    let isHit = false;
    for (let i in this.advertizerinvisibles) {
        let advertizer = this.advertizerinvisibles[i];
        if (advertizer.advertizer_id == advertizerinvisible.advertizer_id) {
            isHit = true;
            //更新
        }
    }
    if (!isHit) {
        this.advertizerinvisibles.push(advertizerinvisible);
    }
};
InvisibleColumnStocker.prototype.getAdvertizerInvisible = function (advertizer_id) {
    for (let i in this.advertizerinvisibles) {
        let a = this.advertizerinvisibles[i];
        if (a.advertizer_id == advertizer_id) {
            return a;
        }
    }

    return null;
};

InvisibleColumnStocker.prototype.getComponentInvisibleColumn = function (advertizer_id, page_type, component_type) {
    let a = this.getAdvertizerInvisible(advertizer_id);
    if (a != null) {
        return a.getComponentInvisibleColumn(page_type, component_type);
    }
    return null;
};

InvisibleColumnStocker.prototype.setComponentInvisibleColumn = function (advertizer_id, page_type, component_type, number) {
    let a = this.getAdvertizerInvisible(advertizer_id);
    if (a != null) {
        let c = a.getComponentInvisibleColumn(page_type, component_type);
        if (c != null) {
            c.setNumberInvisible(number);
        }
    }
    return false;
};
InvisibleColumnStocker.prototype.initialize = function (advertizer_id, screen) {
    let p = [];
    if (screen == "summary") {
        let ss = new ColumnSaver(advertizer_id, screen, "summary");
        let sm = new ColumnSaver(advertizer_id, screen, "monthly");
        let sd1 = new ColumnSaver(advertizer_id, screen, "dimension1");
        let sd2 = new ColumnSaver(advertizer_id, screen, "dimension2");
        let sd3 = new ColumnSaver(advertizer_id, screen, "dimension3");
        p = [new PageInvisibleColumnStocker(screen, [new ComponentInvisibleColumn("summary", ss.load(), ss), new ComponentInvisibleColumn("monthly", sm.load(), sm), new ComponentInvisibleColumn("dimension1", sd1.load(), sd1), new ComponentInvisibleColumn("dimension2", sd2.load(), sd2), new ComponentInvisibleColumn("dimension3", sd3.load(), sd3)])];
    } else if (screen == "adprovider") {
        let ss = new ColumnSaver(advertizer_id, screen, "selecttable");
        let ssa = new ColumnSaver(advertizer_id, screen, "selectalltable");
        let ssd = new ColumnSaver(advertizer_id, screen, "selectedtable");

        p = [new PageInvisibleColumnStocker(screen, [new ComponentInvisibleColumn("selecttable", ss.load(), ss), new ComponentInvisibleColumn("selectalltable", ssa.load(), ssa), new ComponentInvisibleColumn("selectedtable", ssd.load(), ssd)])];
    }
    this.setAdvertizerInvisible(new AdvertizerInvisibleColumnStocker(advertizer_id, p));
};

function ColumnStyle(index, style = {}) {
    this.index = index;
    this.style = style;
    this.width = 0;
    if (this.style) {
        if (this.style.width) {
            try {
                let w = Number(this.style.width.replace("px", ""));
                this.width = w;
            } catch (ex) {
                console.log(ex);
            }
        }
    }
}
ColumnStyle.prototype.setWidth = function (width) {
    this.style.width = width + 'px';
    this.width = width;
}
ColumnStyle.prototype.deleteWidth = function () {
    try {
        delete this.style.width;
    } catch (ex) {
        //
    }
    this.width = null;
};

function ComponentColumnStyle(type, columns = [], saver = null) {
    this.type = type;
    this.columns = columns;
    this.saver = saver;
}
ComponentColumnStyle.prototype.getColumnStyle = function (index) {
    for (let i in this.columns) {
        let c = this.columns[i];
        if (c.index == index) {
            return c;
        }
    }

    return null;
};
ComponentColumnStyle.prototype.setWidth = function (index, width) {
    let cs = this.getColumnStyle(index);
    if (cs == null) {
        cs = new ColumnStyle(index);
        this.columns.push(cs);
    }
    cs.setWidth(width);
    if (this.saver) {
        this.saver.save(this.columns);
    }
};
ComponentColumnStyle.prototype.reset = function () {
    //
};


function PageColumnStyleStocker(type, components = []) {
    this.type = type;
    this.components = components;
}
PageColumnStyleStocker.prototype.getComponentColumnStyle = function (type) {
    for (let i in this.components) {
        let c = this.components[i];
        if (c.type == type) {
            return c;
        }
    }

    return null;
};
PageColumnStyleStocker.prototype.getColumnStyle = function (type, index) {
    return this.getComponentColumnStyle(type)?.getColumnStyle(index);
};


function AdvertizerColumnStyleStocker(advertizer_id, pages = []) {
    this.advertizer_id = advertizer_id;
    this.pages = pages;
}
AdvertizerColumnStyleStocker.prototype.getPageColumnStyleStocker = function (type) {
    for (let i in this.pages) {
        let p = this.pages[i];
        if (p.type == type) {
            return p;
        }
    }

    return null;
}

function ColumnStyleStocker(advertizers = []) {
    this.advertizers = advertizers;
}
ColumnStyleStocker.prototype.initialize = function (advertizer_id, screen) {
    let p = [];
    if (screen == "summary") {
        let ssu = new ColumnStylesSaver(advertizer_id, screen, "summary");
        let smd = new ColumnStylesSaver(advertizer_id, screen, "mwd");
        let sd1 = new ColumnStylesSaver(advertizer_id, screen, "d1");
        let sd2 = new ColumnStylesSaver(advertizer_id, screen, "d2");
        let sd3 = new ColumnStylesSaver(advertizer_id, screen, "d3");
        p = [new PageColumnStyleStocker(screen, [new ComponentColumnStyle("summary", ssu.load(), ssu)
            , new ComponentColumnStyle("mwd", smd.load(), smd)
            , new ComponentColumnStyle("d1", sd1.load(), sd1)
            , new ComponentColumnStyle("d2", sd2.load(), sd2)
            , new ComponentColumnStyle("d3", sd3.load(), sd3)
        ])];
    } else if (screen == "adprovider") {
        let ssu = new ColumnStylesSaver(advertizer_id, screen, "summary");
        let sat = new ColumnStylesSaver(advertizer_id, screen, "alltab");
        let sam = new ColumnStylesSaver(advertizer_id, screen, "allmwd");
        let smw = new ColumnStylesSaver(advertizer_id, screen, "mwd");
        let sm1 = new ColumnStylesSaver(advertizer_id, screen, "mwd1");
        let sm2 = new ColumnStylesSaver(advertizer_id, screen, "mwd2");
        p = [new PageColumnStyleStocker(screen,
            [new ComponentColumnStyle("summary", ssu.load(), ssu)
                , new ComponentColumnStyle("alltab", sat.load(), sat)
                , new ComponentColumnStyle("allmwd", sam.load(), sam)
                , new ComponentColumnStyle("mwd", smw.load(), smw)
                , new ComponentColumnStyle("mwd1", sm1.load(), sm1)
                , new ComponentColumnStyle("mwd2", sm2.load(), sm2)])];
    }
    this.setAdvertizerColumnStyleStocker(new AdvertizerColumnStyleStocker(advertizer_id, p));
};
ColumnStyleStocker.prototype.getAdvertizerColumnStyleStocker = function (advertizer_id) {
    for (let i in this.advertizers) {
        let advertizer = this.advertizers[i];
        if (advertizer.advertizer_id == advertizer_id) {
            return advertizer;
        }
    }

    return null;
};
ColumnStyleStocker.prototype.setAdvertizerColumnStyleStocker = function (advertizer) {
    let isHit = false;
    for (let i in this.advertizers) {
        let a = this.advertizers[i];
        if (a.advertizer_id == advertizer.advertizer_id) {
            isHit = true;
            //更新
            this.advertizers[i] = advertizer;
        }
    }
    if (!isHit) {
        this.advertizers.push(advertizer);
    }
};
ColumnStyleStocker.prototype.getColumnStyles = function (advertizer_id, page, component) {
    return this.getAdvertizerColumnStyleStocker(advertizer_id)?.getPageColumnStyleStocker(page)?.getComponentColumnStyle(component);
};
ColumnStyleStocker.prototype.setWidth = function (advertizer_id, page, component, index, width) {
    this.getAdvertizerColumnStyleStocker(advertizer_id)?.getPageColumnStyleStocker(page)?.getComponentColumnStyle(component).setWidth(index, width);
};

function ColumnDefaultHideSaver() {
    this.hides = [];
    this.load();
}
ColumnDefaultHideSaver.prototype.load = function() {
    this.hides = [];
    try {
        let smsg = localStorage.getItem("defaultcolv");
        if (smsg) {
            let ls = JSON.parse(smsg);
            for (let i in ls) {
                let l = ls[i];
                this.hides.push(l);
            }
        }
    } catch (ex) {
        console.log(ex);
    }
};
ColumnDefaultHideSaver.prototype.save = function (advertizer_id, page_id) {
    this.load();
    this.hides.push({
        "advertizer_id": advertizer_id,
        "page_id": page_id
    });
    try {
        let jmsg = JSON.stringify(this.hides)
        localStorage.setItem("defaultcolv", jmsg);
    } catch (ex) {
        console.log(ex);
    }
    this.load();
};
ColumnDefaultHideSaver.prototype.isExist = function (advertizer_id, page_id) {
    for (let i in this.hides) {
        if (this.hides[i].advertizer_id == advertizer_id && this.hides[i].page_id == page_id) {
            return true;
        }
    }

    return false;
};

export { ComponentInvisibleColumn, PageInvisibleColumnStocker, AdvertizerInvisibleColumnStocker, InvisibleColumnStocker, ColumnStyleStocker, AdvertizerColumnStyleStocker, PageColumnStyleStocker, ComponentColumnStyle, ColumnStyle, ColumnDefaultHideSaver }
