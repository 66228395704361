<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<template>
  <div class="overlay_layer" v-if="isShowRange">
    <div class="summary_rangecalendar_background" v-on:click="rangecalendarevent"></div>
    <div class="summary_rangecalendar_layer">
      <div class="summary_rangecalendar">
        <div class="summary_rangecalendar__wrapper">
          <div v-if="dateRangeSelector">
            <RangeCalendar
              :range="dateRangeSelector.timeYmdRange"
              :todayYmd="getTodayYmd()"
              @rangecalendarevent="rangecalendarevent"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main_content_wrapper">
    <div class="adx_report_keyword">
      <div class="left_main_content">
        <div style="background: white; width: calc(100% - 20px)">
          <div class="left_main_content__header">
            <ContentHeader
              :title="contentTitle"
              :timeRange="dateRangeSelector"
              :adx="adx"
              @timerangeevent="timerangeevent"
            />
          </div>

          <div style="padding: 10px; width: 100%; display: flex">
            <div
              v-for="(cell, index) in page.tab.cells"
              :key="index"
              v-bind:class="{
                backlash_tab: true,
                'backlash_tab--active': cell.getId() == page.selectedId,
              }"
              v-on:click="tabselect(cell.getId())"
            >
              <div>{{ cell.getName() }}</div>
            </div>
          </div>
        </div>
        <div class="left_main_content__body">
          <div class="box">
            <div class="frame">
              <div class="box">
                <div style="width: 100%">
                  <div class="frame__header">月別実績</div>
                  <div class="frame__body">
                    <Spinner v-if="page.isAdProviderMonthlyNetworking" />
                    <div
                      class="nodataline"
                      v-if="!page.isAdProviderMonthlyNetworking && page.isAdProviderMonthlyNoData"
                    >
                      NoData
                    </div>
                    <table
                      class="floater_table floater_table--full"
                      v-if="!page.isAdProviderMonthlyNetworking && !page.isAdProviderMonthlyNoData"
                    >
                      <thead class="floater_table__header">
                        <tr>
                          <template
                            v-for="(item, index) in page.table.getHeaderCell('種別')"
                            :key="index"
                          >
                            <th
                              v-bind:class="[index == 0 ? 'floater_table__titlecell' : '']"
                              class="floater_table__keyword"
                            >
                              {{ item.getString() }}
                            </th>
                          </template>
                        </tr>
                      </thead>

                      <tbody class="floater_table__body">
                        <template v-for="(cellColms, index) in page.table.cellRows" :key="index">
                          <tr>
                            <template v-for="(cell, cindex) in cellColms" :key="cindex">
                              <td
                                v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                class="floater_table__keyword"
                              >
                                {{ cell.getString() }}
                              </td>
                            </template>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="frame" style="margin-right: 10px">
              <div class="box">
                <div style="width: 100%">
                  <div class="frame__header">週次グラフ</div>
                  <div class="frame__body">
                    <div style="width: 100%">
                      <ChartJs :graph="page.weeklyGraph" ref="gr_weekly" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="frame" style="margin-left: 10px">
              <div class="box">
                <div style="width: 100%">
                  <div class="frame__header">日次グラフ</div>
                  <div class="frame__body">
                    <div style="width: 100%">
                      <ChartJs :graph="page.dailyGraph" titleskip="2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-for="(dimension, index) in page.dimensionHolder.dimensions" :key="index">
            <div class="box" v-if="dimension.count() == 1">
              <div class="frame">
                <div class="box">
                  <div style="width: 100%">
                    <div class="frame__header">{{ dimension.title() }}</div>
                    <div class="frame__body">
                      <table class="floater_table floater_table--full">
                        <thead class="floater_table__header">
                          <tr>
                            <template
                              v-for="(cell, index2) in dimension.getHeaderCell()"
                              :key="index2"
                            >
                              <th
                                v-bind:class="[
                                  index2 == 0 ? 'floater_table__keyword__categorytitle' : '',
                                ]"
                                class="floater_table__keyword"
                              >
                                {{ cell.getString() }}
                              </th>
                            </template>
                          </tr>
                        </thead>
                        <tbody class="floater_table__body">
                          <template
                            v-for="(dimension, index2) in dimension.dimensions"
                            :key="index2"
                          >
                            <template v-for="(cells, index3) in dimension.cellRows" :key="index3">
                              <tr>
                                <td
                                  style="background: white"
                                  class="floater_table__keyword"
                                  v-bind:class="[
                                    index3 == 0 ? 'floater_table__keyword__categorytitle' : '',
                                  ]"
                                  :rowSpan="dimension.cellRows.length"
                                  v-if="index3 == 0"
                                >
                                  <div
                                    style="
                                      top: 0;
                                      position: absolute;
                                      margin-top: 10px;
                                      text-align: left;
                                      color: #2680eb;
                                      border: solid 0px;
                                    "
                                  >
                                    {{ dimension.categoryCell.getString() }}
                                  </div>
                                  <template
                                    v-for="(vcell, index4) in dimension.categoryCell.variables"
                                    :key="index4"
                                  >
                                    <div style="margin-top: 5px" v-if="index4 < 2">
                                      <div style="font-weight: bold">
                                        {{ vcell.getTitle() }}
                                      </div>
                                      <div>{{ vcell.getValue() }}</div>
                                    </div>
                                  </template>
                                  <div
                                    style="
                                      text-align: left;
                                      display: flex;
                                      position: absolute;
                                      bottom: 10px;
                                    "
                                  >
                                    <template
                                      v-for="(vcell, index4) in dimension.categoryCell.variables"
                                      :key="index4"
                                    >
                                      <div v-if="index4 > 1">
                                        <Tsv
                                          :title="vcell.getTitle()"
                                          :value="vcell.getValue()"
                                          titleCss="itemwithtitle"
                                          valueCss="itemwithtitle"
                                        />
                                      </div>
                                    </template>
                                  </div>
                                </td>
                                <template v-for="(cell, index4) in cells" :key="index4">
                                  <td class="floater_table__keyword">{{ cell.getString() }}</td>
                                </template>
                              </tr>
                            </template>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" v-if="dimension.count() == 2">
              <div class="frame">
                <div class="box">
                  <div style="width: 100%">
                    <div class="frame__header">{{ dimension.title() }}</div>
                    <div class="frame__body">
                      <table class="floater_table floater_table--full">
                        <thead class="floater_table__header">
                          <tr>
                            <template
                              v-for="(cell, index2) in dimension.getHeaderCell()"
                              :key="index2"
                            >
                              <th
                                v-bind:class="[
                                  index2 == 0 ? 'floater_table__keyword__2categorytitle' : '',
                                ]"
                                class="floater_table__keyword"
                              >
                                {{ cell.getString() }}
                              </th>
                            </template>
                          </tr>
                        </thead>
                        <tbody class="floater_table__body">
                          <template
                            v-for="(dimension, index2) in dimension.dimensions"
                            :key="index2"
                          >
                            <template
                              v-for="(group, gindex) in dimension.dimensionCategories"
                              :key="gindex"
                            >
                              <template v-for="(cells, index3) in group.cellRows" :key="index3">
                                <tr>
                                  <td
                                    style="background: white"
                                    class="
                                      floater_table__keyword floater_table__keyword__2categorytitle
                                    "
                                    :rowSpan="dimension.getLength()"
                                    v-if="gindex == 0 && index3 == 0"
                                  >
                                    <div style="position: absolute; top: 10px">
                                      <div style="color: #2680eb">{{ dimension.group }}</div>
                                      <div style="padding: 5px">
                                        <img
                                          width="32"
                                          height="32"
                                          :src="getIcon(parseInt(dimension.adProviderId))"
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    style="background: white"
                                    class="
                                      floater_table__keyword floater_table__keyword__1categorytitle
                                    "
                                    :rowSpan="group.cellRows.length"
                                    v-if="index3 == 0"
                                  >
                                    <div style="position: absolute; top: 10px; right: 10px">
                                      <div style="color: #2680eb">
                                        {{ group.categoryCell.getString() }}
                                      </div>
                                    </div>
                                    <div>
                                      <template
                                        v-for="(vcell, index4) in group.categoryCell.variables"
                                        :key="index4"
                                      >
                                        <div style="margin-top: 5px" v-if="index4 < 2">
                                          <div style="font-weight: bold">
                                            {{ vcell.getTitle() }}
                                          </div>
                                          <div>{{ vcell.getValue() }}</div>
                                        </div>
                                      </template>
                                    </div>
                                  </td>
                                  <template v-for="(cell, index4) in cells" :key="index4">
                                    <td class="floater_table__keyword">{{ cell.getString() }}</td>
                                  </template>
                                </tr>
                              </template>
                            </template>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="box">
            <div class="frame">
              <div class="box">
                <div style="width: 100%">
                  <div class="frame__header">キャンペーン × 期間別実績</div>
                  <div class="frame__body">
                    <Spinner v-if="page.isLoadCampaignNetworking" />
                    <div
                      class="nodataline"
                      v-if="!page.isLoadCampaignNetworking && page.isLoadCampaignNoData"
                    >
                      NoData
                    </div>
                    <div v-if="!page.isLoadCampaignNetworking && !page.isLoadCampaignNoData">
                      <template v-for="(item, index) in page.campaignTables" :key="index">
                        <div style="margin-bottom: 20px">
                          <table class="floater_table floater_table--full">
                            <thead class="floater_table__header">
                              <tr>
                                <th
                                  colspan="12"
                                  style="
                                    background: #fff;
                                    color: black;
                                    text-align: left;
                                    padding: 10px 20px;
                                    font: normal normal 300 12px/12px Noto Sans JP;
                                  "
                                >
                                  {{ item.title }}
                                </th>
                              </tr>
                              <tr>
                                <template
                                  v-for="(item, index) in item.getHeaderCell('種別')"
                                  :key="index"
                                >
                                  <th
                                    v-bind:class="[index == 0 ? 'floater_table__titlecell' : '']"
                                    class="floater_table__keyword"
                                  >
                                    {{ item.getString() }}
                                  </th>
                                </template>
                              </tr>
                            </thead>
                            <tbody class="floater_table__body">
                              <template v-for="(cellColms, index) in item.cellRows" :key="index">
                                <tr>
                                  <template v-for="(cell, cindex) in cellColms" :key="cindex">
                                    <td
                                      v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                      class="floater_table__keyword"
                                    >
                                      {{ cell.getString() }}
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub_content_layer">
        <div class="sub_content_wrapper">
          <div id="lay_right_sidemenu" class="right_sidemenu_layer">
            <SideMenu :adx="adx" :selectId="getSelectedPageId()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideMenu from '@/components/report/SideMenu.vue';
import ContentHeader from '@/components/report/ContentHeader.vue';
import Spinner from '@/components/layout/Spinner.vue';

import ChartJs from '@/components/element/ChartJs.vue';

//import VProgress from "@/components/VProgress.vue";

import { DimensionPage } from '@/js/page/report/DimensionPage.js';

import RangeCalendar from '@/components/RangeCalendar.vue';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';

import Tsv from '@/components/element/Tsv.vue';

export default {
  name: 'DimensionPage',
  data() {
    return {
      name: 'カテゴリ別実績',
      page: new DimensionPage(),
      isLoad: false,
      contentTitle: '',
      selectedPageId: 0,
      dateRangeSelector: null,
      isShowRange: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.isLoad = false;

    let p = this.adx.getScreenStructureById('report').getPageById(this.$route.params['id']);

    this.initialize(to.params['id'], p.pageComponents, to.params['cid'], this.dateRangeSelector);
  },
  emits: ['screenevent'],
  mounted() {
    this.$emit('screenevent', 'keyword');
    let self = this;
    let p = this.adx.getScreenStructureById('report').getPageById(this.$route.params['id']);
    if (p) {
      this.dateRangeSelector = this.adx.getScreenStructureById('report').dateRangeSelector;
      this.dateRangeSelector.timeYmdRange.subject.removeAll();
      this.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
        self.isLoad = false;
        self.initialize(
          self.$route.params['id'],
          p.pageComponents,
          self.$route.params['cid'],
          self.dateRangeSelector
        );
      });

      this.initialize(
        this.$route.params['id'],
        p.pageComponents,
        this.$route.params['cid'],
        this.dateRangeSelector
      );
    } else {
      this.adx.getScreenStructureById('report').subject.addObserver(function () {
        let p = self.adx.getScreenStructureById('report').getPageById(self.$route.params['id']);
        if (p) {
          self.dateRangeSelector = self.adx.getScreenStructureById('report').dateRangeSelector;
          self.dateRangeSelector.timeYmdRange.subject.removeAll();
          self.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
            self.isLoad = false;
            self.initialize(
              self.$route.params['id'],
              p.pageComponents,
              self.$route.params['cid'],
              self.dateRangeSelector
            );
          });

          self.initialize(
            self.$route.params['id'],
            p.pageComponents,
            self.$route.params['cid'],
            self.dateRangeSelector
          );
        }
      });
    }
  },
  methods: {
    tabselect(id) {
      this.page.selectTabByAdProviderId(id);
    },
    initialize(id, pageComponents, cid, timeRange) {
      if (this.isLoad) {
        return;
      }
      this.isLoad = true;
      this.selectedPageId = id;
      let page = this.adx.getScreenStructureById('report').getPageById(id);
      if (page) {
        this.contentTitle = page.group;
      }
      this.page.initialize(
        id,
        pageComponents,
        cid,
        this.adx.getScreenStructureById('report').advertizerId,
        timeRange,
        this.adx.user
      );
    },
    getTodayYmd() {
      let time = this.adx.getNowTime();
      return new TimeYmd(time.ymd.year, time.ymd.month, time.ymd.day);
    },
    getSelectedPageId() {
      return String(this.selectedPageId);
    },
    rangecalendarevent: function (event, arg) {
      if (event == 'apply') {
        this.dateRangeSelector.setRangeYmd(
          arg.startYmd.year,
          arg.startYmd.month,
          arg.startYmd.day,
          arg.endYmd.year,
          arg.endYmd.month,
          arg.endYmd.day
        );
      }
      this.isShowRange = false;
    },
    timerangeevent: function (event) {
      console.log(event);
      this.isShowRange = true;
    },
  },
  components: {
    SideMenu,
    ContentHeader,
    Spinner,
    ChartJs,
    RangeCalendar,
    Tsv,
  },
  props: {
    adx: Object,
  },
};
</script>
