import { KeywordReportByAdProviderRequest, TabListOfKeywordReportPageRequest } from './protos_gen/keyword_report_pb.js'
import { KeywordReportClient } from './protos_gen/keyword_report_grpc_web_pb.js'
import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function getTabListOfKeywordReportPage(id, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new TabListOfKeywordReportPageRequest();
    request.setRequestid(uuidStr);
    request.setAdpageid(id);
    request.setAdvertizerid(dto.advertizerId);

    let client = new KeywordReportClient(url.grpc, null, null);
    let stream = client.getTabListOfKeywordReportPage(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

function getKeywordReportByAdProvider(id, cid = null, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new KeywordReportByAdProviderRequest();
    request.setRequestid(uuidStr);
    request.setAdproviderid(id);
    request.setAdvertizerid(dto.advertizerId);
    
    if (cid) {
        request.setCategoryid(cid);
    }
    if (!dto.timeRequest.isRange()) {
        request.setActualmonth(dto.timeRequest.actualMonth);
    } else {
        request.setStartdate(dto.timeRequest.startDate);
        request.setEnddate(dto.timeRequest.endDate);
    }

    let client = new KeywordReportClient(url.grpc, null, null);
    const stream = client.getKeywordReportByAdProvider(request, meta);
    stream.on('data', message => {
        if (callback) {

            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}

export { getKeywordReportByAdProvider, getTabListOfKeywordReportPage }
