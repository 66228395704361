import { GetInformationLatestUpdateRequest } from './protos_gen/information_pb.js'
import { InformationClient } from './protos_gen/information_grpc_web_pb.js'
import { url } from '../../setting.js'
import { uuid } from '../../tacklebox/core/uuid.js'
/* eslint-disable */

function getInformationLatestUpdate(id, dto, callback) {
    let meta = {
        'X-Timezone': 'Asia/Tokyo',
        'X-API-Version': '2',
        'Authorization': "Bearer " + dto.token,
    };
    let uuidStr = uuid();
    let request = new GetInformationLatestUpdateRequest();
    request.setRequestid(uuidStr);
    request.setAdvertizerid(id);

    let client = new InformationClient(url.grpc, null, null);
    const stream = client.getInformationLatestUpdate(request, meta);
    stream.on('data', message => {
        if (callback) {
            if (message["array"].length > 0)
                callback(uuidStr, message.toObject());
            else
                callback(uuidStr, null);
        }
    });
    stream.on('error', (e) => {
        console.log('Error returned from BFF.');
        throw e;
    });
    //TBD エラー

    return uuidStr;
}


export { getInformationLatestUpdate }
