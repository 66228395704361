<template>
  <div class="main_content_wrapper">
    <div class="adx_news">
      <div class="full_main_content" style="padding: 0 10px">
        <div class="box" style="padding: 0 10px">
          <div class="frame">
            <div class="box">
              <div style="padding: 10px 0px; width: 100%">
                <div class="frame__body" style="display: flex">
                  <div
                    style="
                      background: #ffffff 0% 0% no-repeat padding-box;
                      box-shadow: 0px 2px 10px #00000027;
                      width: 910px;
                      margin-right: 10px;
                    "
                  >
                    <div style="display: block; width: 100%">
                      <div class="frame__header">お知らせ</div>
                      <spinner v-if="page.isNetworkingAnnouncement" />
                      <template v-for="(item, index) in page.announcements" :key="index">
                        <div style="border-bottom: 1px solid #d7d7d7; width: 100%"></div>
                        <div style="display: flex; width: 100%; padding: 20px">
                          <div style="width: 150px">
                            <div>{{ item.getYmd() }}</div>
                            <div
                              style="
                                border-radius: 50px;
                                background: #333333;
                                width: 100px;
                                color: white;
                                text-align: center;
                                font-size: 10px;
                              "
                            >
                              NEW!
                            </div>
                          </div>
                          <div style="width: 700px">
                            <div>【{{ item.title }}】</div>
                            <div style="margin-top: 20px; white-space: break-spaces">
                              {{ item.message }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div
                    style="
                      background: #ffffff 0% 0% no-repeat padding-box;
                      box-shadow: 0px 2px 10px #00000027;
                      width: 910px;
                      margin-left: 10px;
                    "
                  >
                    <div class="frame__header">データステータス {{ getYmd() }}</div>
                    <spinner v-if="page.isNetworkingInformation" />
                    <div
                      v-if="!page.isNetworkingInformation"
                      style="border-bottom: 1px solid #d7d7d7; width: 100%"
                    ></div>
                    <template v-for="(item, index) in page.informations" :key="index">
                      <div style="width: 100%; margin-top: 20px" v-if="item.isDisplayAdX">
                        <div style="display: flex; padding: 0px 20px">
                          <div style="width: 100%">
                            <div style="display: flex">
                              <div style="width: 70px; margin-left: 10px">
                                <img width="48" height="48" :src="getIcon(item.adProviderName)" />
                              </div>
                              <div style="margin-top: 10px">
                                {{ item.adProviderName }}
                              </div>
                            </div>
                            <div style="width: 800px">
                              <BarProgress :value="item.getProgress()" :stroke="1.0"></BarProgress>
                            </div>
                          </div>
                          <div style="margin-top: 5px">
                            <div class="circle" v-if="item.getProgress() >= 100">
                              <img
                                src="@/assets/icon/check.png"
                                style="
                                  margin-top: 10px;
                                  margin-left: 10px;
                                  width: 30px;
                                  height: 30px;
                                "
                              />
                            </div>
                            <div class="circle circle--danger" v-if="item.getProgress() < 100">
                              <div
                                style="
                                  margin-top: 10px;
                                  font-size: 20px;
                                  text-align: center;
                                  color: #fff;
                                  font-weight: bold;
                                "
                              >
                                !
                              </div>
                            </div>
                            <div v-if="false">{{ item.getProgress() }}</div>
                            <i class="fa fa-check" style="width: 40px; height: 40px"></i>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.adx_news {
  min-width: 1920px;
}
</style>

<style>
.circle {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: rgb(59, 134, 255);
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

.circle--danger {
  background: #ff6565;
}

.success_circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: rgb(59, 134, 255);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.secondary_circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: rgb(70, 70, 70);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.active_circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: rgb(67, 180, 23);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
</style>


<script>
// @ is an alias to /src
import Spinner from '@/components/layout/Spinner.vue';

import { NewsPage } from '@/js/page/news/NewsPage.js';

import BarProgress from '@/components/BarProgress.vue';

export default {
  name: 'Summary',
  data() {
    return {
      name: 'サマリ',
      page: new NewsPage(),
    };
  },
  emits: ['screenevent'],
  mounted() {
    this.$emit('screenevent', 'news');

    const self = this;

    let p = this.adx.getScreenStructureById('news');
    if (p.getActivePages().length > 0) {
      this.page.initialize(this.adx.getScreenStructureById('news').advertizerId, this.adx.user);
    } else {
      p.subject.addObserver(function () {
        self.page.initialize(self.adx.getScreenStructureById('news').advertizerId, self.adx.user);
      });
    }
  },
  methods: {
    getYmd() {
      if (this.page.informations.length > 0) {
        return this.page.informations[0].getYmd().replace(/-/g, '/');
      }
      return '';
    },
    getIcon(name) {
      //TBD
      switch (name) {
        case 'GDN':
          return require('@/assets/icon/Google.png');
        case 'GoogleAnalytics':
          return require('@/assets/icon/Analytics.jpeg');
        case 'GoogleDiscovery':
          return require('@/assets/icon/Google.png');
        case 'GoogleSearch':
          return require('@/assets/icon/Google.png');
        case 'GoogleShopping':
          return require('@/assets/icon/Google.png');
        case 'GoogleUAC':
          return require('@/assets/icon/Google.png');
        case 'GoogleYoutube':
          return require('@/assets/icon/Google.png');
        case 'LINE':
          return require('@/assets/icon/LINE_APP_Android.png');
        case 'RTBHOUSE':
          return require('@/assets/thirdparty/icon/RTBHOUSE.jpg');
        case 'Salesforce':
          return require('@/assets/thirdparty/icon/salesforce.png');
        case 'SmartNews':
          return require('@/assets/thirdparty/icon/Smartnews.png');
        case 'Twitter':
          return require('@/assets/icon/Twitter social icons - circle - blue.png');
        case 'YDN':
          return require('@/assets/thirdparty/icon/yahoo.png');
        case 'YahooSearch':
          return require('@/assets/thirdparty/icon/yahoo.png');
        case 'AdEbis':
          return require('@/assets/thirdparty/icon/ADEbis.png');
        case 'Adjust':
          return require('@/assets/thirdparty/icon/Adjust.png');
        case 'AppleSearchAds':
          return require('@/assets/thirdparty/icon/Apple.png');
        case 'Appsflyer':
          return require('@/assets/thirdparty/icon/appsflyer.png');
        case 'Criteo':
          return require('@/assets/thirdparty/icon/Criteo.png');
        case 'Facebook':
          return require('@/assets/icon/f_logo_RGB-Blue_1024.png');
      }
      return require('@/assets/icon/no_image.png');
    },
  },
  components: {
    Spinner,
    BarProgress,
  },
  props: {
    adx: Object,
  },
};
</script>