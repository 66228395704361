<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<template>
  <div>
    <div class="overlay_layer" v-if="isShowRange">
      <div class="summary_rangecalendar_background" v-on:click="rangecalendarevent"></div>
      <div class="summary_rangecalendar_layer">
        <div class="summary_rangecalendar">
          <div class="summary_rangecalendar__wrapper">
            <div v-if="dateRangeSelector">
              <RangeCalendar
                :range="dateRangeSelector.timeYmdRange"
                :todayYmd="getTodayYmd()"
                @rangecalendarevent="rangecalendarevent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_content_wrapper">
      <div class="adx_report_keyword">
        <div class="left_main_content">
          <div style="background: white; width: calc(100% - 20px)">
            <div class="left_main_content__header" style="position: relative">
              <ContentHeader
                title="媒体別実績"
                :timeRange="dateRangeSelector"
                :adx="adx"
                @timerangeevent="timerangeevent"
              />
              <div style="position: absolute; top: 76px; left: 0px; right: 0">
                <ScreenBarProgress2 :process="page.process" />
              </div>
            </div>

            <div style="padding: 10px; width: 100%; display: flex">
              <BoxSpinner
                v-if="page.getProviderTableProviderList().length == 0 || !isLoad"
                style="width: 100%; height: 45px"
              />
              <div
                v-on:click="selectAllTab"
                v-if="page.getProviderTableProviderList().length > 0 && isLoad"
                class="clickable"
                v-bind:class="{
                  backlash_tab: true,
                  'backlash_tab--active': isAllTabSelected,
                }"
              >
                <div>ALL</div>
              </div>
              <div
                v-for="(provider, index) in page.getProviderTableProviderList()"
                :key="index"
                class="clickable"
                v-bind:class="{
                  backlash_tab: true,
                  'backlash_tab--active': isProviderSelected(provider) && !isAllTabSelected,
                }"
                v-on:click="selectProvider(provider)"
              >
                <div>{{ provider.label }}</div>
              </div>
            </div>
          </div>
          <div class="left_main_content__body">
            <div class="box" style="width: calc(100% - 20px)">
              <div class="frame frame--expansion" style="width: 100%">
                <div class="box" style="width: 100%">
                  <div style="width: 100%">
                    <div class="frame__header frame__header--expansion_lock">
                      <div :style="{ paddingLeft: '1px' }">
                        <div
                          :style="{ borderLeft: '3px solid #39434E', paddingLeft: '8px' }"
                          v-on:click="copyToClipboard('provider_campaign_table')"
                        >
                        {{ isAllTabSelected ? 'カテゴリ別実績' : '広告グループ別実績' }}
                        </div>
                      </div>
                    </div>
                    <div class="frame__body">
                      <Spinner v-if="page.providerCampaignAdgroupTableNetworking && false" />
                      <BoxSpinner
                        v-if="page.providerCampaignAdgroupTableNetworking || !isLoad"
                        style="max-width: 1600px; height: 300px"
                      />
                      <div
                        style="overflow: auto; width: 100%"
                        class="scroll_bar"
                        v-if="
                          !isAllTabSelected &&
                          !page.providerCampaignAdgroupTableNetworking &&
                          page.adproDCCAdGroupMonthlyHolder.getDimension() == 1
                        "
                      >
                        <div
                          :style="
                            adprovidertalbewidth(
                              'summary',
                              page.getProviderCampaignAdgroupTableHeaderV2(),
                              'selecttable'
                            )
                          "
                          style="padding-bottom: 8px"
                        >
                          <table
                            id="summary_table_tag"
                            class="
                              floater_table floater_table--full
                              provider_campaign_table
                              expansion_table
                            "
                            v-if="!isAllTabSelected"
                          >
                            <thead class="floater_table__header expansion_table__header">
                              <tr
                                v-if="
                                  isCells2SpanCells(
                                    page.getProviderCampaignAdgroupTableHeaderV2(),
                                    'selecttable'
                                  )
                                "
                              >
                                <template
                                  v-for="(item, index) in cells2SpanCells(
                                    page.getProviderCampaignAdgroupTableHeaderV2(),
                                    'selecttable'
                                  )"
                                  :key="index"
                                >
                                  <th v-if="index == 0" style="background: white; border: initial">
                                    カテゴリ
                                  </th>
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <template
                                  v-for="(
                                    header_cell, index
                                  ) in page.getProviderCampaignAdgroupTableHeaderV2()"
                                  :key="index"
                                >
                                  <th
                                    id="catetag"
                                    v-if="index == 0"
                                    class="floater_table__summary_weekly expansion_table__th"
                                  >
                                    {{ page.adproDCCAdGroupMonthlyHolder.getCategoryNames()[0] }}
                                  </th>
                                  <th
                                    v-if="isShowAdproviderColum(index, 'selecttable')"
                                    v-on:click.stop="showShowHide($event, index, 'selecttable')"
                                    style="position: relative; cursor: pointer"
                                    ref="summary_header_ref"
                                    :id="'summary_header_id_' + index"
                                    :style="[teststyle('summary', index)]"
                                    class="floater_table__summary_weekly expansion_table__th"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == index && expType == 'summary'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('summary', index, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    {{ spanTilte2HeaderTitle(header_cell.getString()) }}
                                  </th>
                                </template>
                              </tr>
                            </thead>
                            <tbody class="floater_table__body"
                              :set="(adprodccmrs = page.adproDCCAdGroupMonthlyHolder.getAdproDCCAdGroupMonthlyRecords())">
                              <template
                                v-for="(
                                  item, index
                                ) in adprodccmrs"
                                :key="index"
                              >
                                <tr>
                                  <td
                                    v-on:click.stop="
                                      selectCategory2(
                                        item.raw_report.dimensionList[0].value,
                                        item.raw_report.dimensionList[0].id
                                      )
                                    "
                                    style="
                                      padding: 8px;
                                      text-decoration: underline;
                                      cursor: pointer;
                                      overflow: hidden;
                                    "
                                    class="floater_table__titlecell floater_table__summary_weekly"
                                    :colspan="
                                      item.raw_report.dimensionList.length == 1
                                        ? 3 -
                                          (isShowAdproviderColum(0, 'selecttable') == false
                                            ? 1
                                            : 0) -
                                          (isShowAdproviderColum(1, 'selecttable') == false ? 1 : 0)
                                        : 1
                                    "
                                    v-if="
                                      item.raw_report.dimensionList.length == 1 ||
                                      item.raw_report.dimensionList.length == 2
                                    "
                                    :rowspan="item.span"
                                  >
                                    {{ item.raw_report.dimensionList[0].value }}
                                  </td>
                                  <td
                                    v-on:click.stop="
                                      [
                                        item.raw_report.dimensionList[1].value == 'ALL'
                                          ? selectCategory2(item.raw_report.dimensionList[0].value, item.raw_report.dimensionList[0].id)
                                          : selectCampaign(item.raw_report.dimensionList[1].value, item.raw_report.dimensionList[0].value, item.raw_report.dimensionList[0].id),
                                      ]
                                    "
                                    class="floater_table__titlecell floater_table__summary_weekly"
                                    style="
                                      padding: 0px;
                                      position: relative;
                                      text-decoration: underline;
                                      cursor: pointer;
                                    "
                                    :colspan="
                                      item.raw_report.dimensionList.length == 2
                                        ? 2 -
                                          (isShowAdproviderColum(0, 'selecttable') == false ||
                                          isShowAdproviderColum(1, 'selecttable') == false
                                            ? 1
                                            : 0)
                                        : 1
                                    "
                                    v-if="
                                      (item.raw_report.dimensionList.length == 2 &&
                                        isShowAdproviderColum(0, 'selecttable')) ||
                                      (item.raw_report.dimensionList.length == 3 &&
                                        isShowAdproviderColum(0, 'selecttable') &&
                                        item.span >= 1)
                                    "
                                    :rowspan="
                                      item.raw_report.dimensionList.length == 3 && item.span > 1
                                        ? item.span
                                        : 1
                                    "
                                  >
                                    <div
                                      :title="item.raw_report.dimensionList[1].value"
                                      class="
                                        floater_table__titlecell--consultant
                                        summary_table_td_class_0
                                      "
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        overflow: hidden;
                                      "
                                      :style="xxx()"
                                    >
                                      {{ item.raw_report.dimensionList[1].value }}
                                    </div>
                                  </td>
                                  <td
                                    class="floater_table__titlecell floater_table__summary_weekly"
                                    style="
                                      padding: 0px;
                                      position: relative;
                                      text-decoration: underline;
                                      cursor: pointer;
                                    "
                                    v-if="
                                      item.raw_report.dimensionList.length > 2 &&
                                      isShowAdproviderColum(1, 'selecttable')
                                    "
                                    v-on:click.stop="
                                      selectAdgroup2(
                                        item.raw_report.dimensionList[1].value,
                                        item.raw_report.dimensionList[2].value,
                                        item.raw_report.dimensionList[0].value,
                                        item.raw_report.dimensionList[0].id
                                      )
                                    "
                                  >
                                    <div
                                      :title="item.raw_report.dimensionList[2].value"
                                      class="
                                        floater_table__titlecell--consultant
                                        summary_table_td_class_1
                                      "
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                      :style="yyy()"
                                    >
                                      {{ item.raw_report.dimensionList[2].value }}
                                    </div>
                                    <div style="position:absolute; right: 0; top: 0; width: 24px; height:24px;padding: 2px" v-if="item.raw_report.dimensionList[2].value == 'ALL'" v-on:click.stop="page.adproDCCAdGroupMonthlyHolder.showHideToggle(item.raw_report.dimensionList[1].value)">
                                      <img width="24" height="24" src="@/assets/icon/add_black_24dp.svg"  v-if="page.adproDCCAdGroupMonthlyHolder.isHideCampaign(item.raw_report.dimensionList[1].value)">
                                      <img width="24" height="24" src="@/assets/icon/remove_black_24dp.png"  v-if="!page.adproDCCAdGroupMonthlyHolder.isHideCampaign(item.raw_report.dimensionList[1].value)">
                                    </div>
                                  </td>
                                  <template v-for="(col, cindex) in item.getCols()" :key="cindex">
                                    <td
                                      v-if="isShowAdproviderColum(2 + cindex, 'selecttable')"
                                      class="floater_table__summary_weekly"
                                      style="padding: 0px; position: relative"
                                      :set="
                                        (cellDesign =
                                          page.getProviderCampaignAdgroupTableCellDesign2(
                                            col.data,
                                            cindex,
                                            item,
                                            adprodccmrs
                                          ))
                                      "
                                    >
                                      <div
                                        v-bind:style="{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          padding: cellDesign.design == 'box' ? '0px' : '4px',
                                          paddingLeft: '0px',
                                        }"
                                      >
                                        <div
                                          style="height: 100%; width: "
                                          v-bind:style="{
                                            width: cellDesign.isColored
                                              ? cellDesign.percent + '%'
                                              : '0%',
                                            background: cellDesign.isColored
                                              ? cellDesign.color
                                              : '#FFFFFF',
                                            border:
                                              cellDesign.border != null ? cellDesign.border : '',
                                          }"
                                        ></div>
                                      </div>

                                      <div
                                        style="
                                          padding: 8px;
                                          position: relative;
                                          top: 0;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "
                                      >
                                        {{ col.getString('consultant') }}
                                      </div>
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div
                        style="overflow: auto; width: 100%"
                        class="scroll_bar"
                        v-if="
                          !isAllTabSelected &&
                          !page.providerCampaignAdgroupTableNetworking &&
                          page.adproDCCAdGroupMonthlyHolder.getDimension() == 2
                        "
                      >
                        <div
                          :style="
                            adprovidertalbewidth(
                              'summary',
                              page.getProviderCampaignAdgroupTableHeaderV2(),
                              'selecttable'
                            )
                          "
                          style="padding-bottom: 8px"
                        >
                          <table
                            id="summary_table_tag"
                            class="
                              floater_table floater_table--full
                              provider_campaign_table
                              expansion_table
                            "
                            v-if="!isAllTabSelected"
                          >
                            <thead class="floater_table__header expansion_table__header">
                              <tr
                                v-if="
                                  isCells2SpanCells(
                                    page.getProviderCampaignAdgroupTableHeaderV2(),
                                    'selecttable'
                                  )
                                "
                              >
                                <template
                                  v-for="(item, index) in cells2SpanCells(
                                    page.getProviderCampaignAdgroupTableHeaderV2(),
                                    'selecttable'
                                  )"
                                  :key="index"
                                >
                                  <th
                                    v-if="index == 0"
                                    style="background: white; border: initial"
                                  ></th>
                                  <th
                                    v-if="index == 0"
                                    style="background: white; border: initial"
                                  ></th>
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <template
                                  v-for="(
                                    header_cell, index
                                  ) in page.getProviderCampaignAdgroupTableHeaderV2()"
                                  :key="index"
                                >
                                  <th
                                    id="catetag"
                                    v-if="index == 0"
                                    class="floater_table__summary_weekly expansion_table__th"
                                  >
                                    {{ page.adproDCCAdGroupMonthlyHolder.getCategoryNames()[0] }}
                                  </th>
                                  <th
                                    id="catetag"
                                    v-if="index == 0"
                                    class="floater_table__summary_weekly expansion_table__th"
                                  >
                                    {{ page.adproDCCAdGroupMonthlyHolder.getCategoryNames()[1] }}
                                  </th>
                                  <th
                                    v-if="isShowAdproviderColum(index, 'selecttable')"
                                    v-on:click.stop="showShowHide($event, index, 'selecttable')"
                                    style="position: relative; cursor: pointer"
                                    ref="summary_header_ref"
                                    :id="'summary_header_id_' + (index + 2)"
                                    :style="[teststyle('summary', index + 2)]"
                                    class="floater_table__summary_weekly expansion_table__th"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == index + 2 && expType == 'summary'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('summary', index + 2, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    {{ spanTilte2HeaderTitle(header_cell.getString()) }}
                                  </th>
                                </template>
                              </tr>
                            </thead>
                            <tbody class="floater_table__body"
                              :set="(adprodccmrs = page.adproDCCAdGroupMonthlyHolder.getAdproDCCAdGroupMonthlyRecords())">
                              <template
                                v-for="(
                                  item, index
                                ) in adprodccmrs"
                                :key="index"
                              >
                                <tr>
                                  <td
                                    v-on:click="
                                      selectCategory2(
                                        item.raw_report.dimensionList[0].value,
                                        item.raw_report.dimensionList[0].id
                                      )
                                    "
                                    style="
                                      padding: 8px;
                                      text-decoration: underline;
                                      cursor: pointer;
                                      overflow: hidden;
                                    "
                                    class="floater_table__titlecell floater_table__summary_weekly"
                                    :colspan="
                                      item.raw_report.dimensionList.length == 1
                                        ? 4 -
                                          (isShowAdproviderColum(0, 'selecttable') == false
                                            ? 1
                                            : 0) -
                                          (isShowAdproviderColum(1, 'selecttable') == false ? 1 : 0)
                                        : 1
                                    "
                                    v-if="
                                      item.raw_report.dimensionList.length == 1 ||
                                      item.raw_report.dimensionList.length == 2
                                    "
                                    :rowspan="item.span"
                                  >
                                    {{ item.raw_report.dimensionList[0].value }}
                                  </td>
                                  <td
                                    v-on:click="
                                      [
                                        item.raw_report.dimensionList[1].value == 'ALL'
                                          ? selectCategory2(
                                              item.raw_report.dimensionList[0].value,
                                              item.raw_report.dimensionList[0].id
                                            )
                                          : selectCategory2(
                                              item.raw_report.dimensionList[0].value,
                                              item.raw_report.dimensionList[0].id,
                                              item.raw_report.dimensionList[1].value,

                                              item.raw_report.dimensionList[1].id
                                            ),
                                      ]
                                    "
                                    class="floater_table__titlecell floater_table__summary_weekly"
                                    style="
                                      padding: 0px;
                                      position: relative;
                                      text-decoration: underline;
                                      cursor: pointer;
                                    "
                                    :colspan="
                                      item.raw_report.dimensionList.length == 2
                                        ? 3 -
                                          (isShowAdproviderColum(0, 'selecttable') == false ||
                                          isShowAdproviderColum(1, 'selecttable') == false
                                            ? 1
                                            : 0)
                                        : 1
                                    "
                                    v-if="
                                      (item.raw_report.dimensionList.length == 2 &&
                                        isShowAdproviderColum(0, 'selecttable')) ||
                                      (item.raw_report.dimensionList.length == 3 &&
                                        isShowAdproviderColum(0, 'selecttable'))
                                    "
                                    :rowspan="
                                      item.raw_report.dimensionList.length == 3 && item.span > 1
                                        ? item.span
                                        : 1
                                    "
                                  >
                                    <div
                                      :title="item.raw_report.dimensionList[1].value"
                                      class="
                                        floater_table__titlecell--consultant
                                        summary_table_td_class_0
                                      "
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        overflow: hidden;
                                      "
                                    >
                                      {{ item.raw_report.dimensionList[1].value }}
                                    </div>
                                  </td>

                                  <td
                                    v-on:click="
                                      [
                                        item.raw_report.dimensionList[2].value == 'ALL'
                                          ? selectCategory2(
                                              item.raw_report.dimensionList[0].value,
                                              item.raw_report.dimensionList[0].id,
                                              item.raw_report.dimensionList[1].value,
                                              item.raw_report.dimensionList[1].id
                                            )
                                          : selectCampaign(item.raw_report.dimensionList[2].value, item.raw_report.dimensionList[0].value, item.raw_report.dimensionList[0].id, item.raw_report.dimensionList[1].value, item.raw_report.dimensionList[1].id),
                                      ]
                                    "
                                    class="floater_table__titlecell floater_table__summary_weekly"
                                    style="
                                      padding: 0px;
                                      position: relative;
                                      text-decoration: underline;
                                      cursor: pointer;
                                    "
                                    :colspan="
                                      item.raw_report.dimensionList.length == 3
                                        ? 2 -
                                          (isShowAdproviderColum(0, 'selecttable') == false ||
                                          isShowAdproviderColum(1, 'selecttable') == false
                                            ? 1
                                            : 0)
                                        : 1
                                    "
                                    v-if="
                                      (item.raw_report.dimensionList.length == 3 &&
                                        isShowAdproviderColum(0, 'selecttable')) ||
                                      (item.raw_report.dimensionList.length == 4 &&
                                        isShowAdproviderColum(0, 'selecttable') &&
                                        item.span >= 1)
                                    "
                                    :rowspan="[
                                      item.raw_report.dimensionList.length == 4 && item.span > 1
                                        ? item.span
                                        : 1,
                                    ]"
                                  >
                                    <div
                                      :title="item.raw_report.dimensionList[2].value"
                                      class="
                                        floater_table__titlecell--consultant
                                        summary_table_td_class_2
                                      "
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        overflow: hidden;
                                      "
                                      :style="xxx2()"
                                    >
                                      {{ item.raw_report.dimensionList[2].value }}
                                    </div>
                                  </td>

                                  <td
                                    class="floater_table__titlecell floater_table__summary_weekly"
                                    style="
                                      padding: 0px;
                                      position: relative;
                                      text-decoration: underline;
                                      cursor: pointer;
                                    "
                                    v-if="
                                      item.raw_report.dimensionList.length > 3 &&
                                      isShowAdproviderColum(1, 'selecttable')
                                    "
                                    v-on:click="[(item.raw_report.dimensionList[3].value == 'ALL') ? selectCampaign(item.raw_report.dimensionList[2].value, item.raw_report.dimensionList[0].value, item.raw_report.dimensionList[0].id, item.raw_report.dimensionList[1].value, item.raw_report.dimensionList[1].id) : selectAdgroup2(item.raw_report.dimensionList[2].value,item.raw_report.dimensionList[3].value,item.raw_report.dimensionList[0].value,item.raw_report.dimensionList[0].id,item.raw_report.dimensionList[1].value,item.raw_report.dimensionList[1].id)]"
                                  >
                                    <div
                                      :title="item.raw_report.dimensionList[3].value"
                                      class="
                                        floater_table__titlecell--consultant
                                        summary_table_td_class_3
                                      "
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                      :style="yyy2()"
                                    >
                                      {{ item.raw_report.dimensionList[3].value }}
                                    </div>
                                    <div style="position:absolute; right: 0; top: 0; width: 24px; height:24px;padding: 2px" v-if="item.raw_report.dimensionList[3].value == 'ALL'" v-on:click.stop="page.adproDCCAdGroupMonthlyHolder.showHideToggle(item.raw_report.dimensionList[2].value)">
                                      <img width="24" height="24" src="@/assets/icon/add_black_24dp.svg"  v-if="page.adproDCCAdGroupMonthlyHolder.isHideCampaign(item.raw_report.dimensionList[2].value)">
                                      <img width="24" height="24" src="@/assets/icon/remove_black_24dp.png"  v-if="!page.adproDCCAdGroupMonthlyHolder.isHideCampaign(item.raw_report.dimensionList[2].value)">
                                    </div>
                                  </td>
                                  <template v-for="(col, cindex) in item.getCols()" :key="cindex">
                                    <td
                                      v-if="isShowAdproviderColum(2 + cindex, 'selecttable')"
                                      class="floater_table__summary_weekly"
                                      style="padding: 0px; position: relative"
                                      :set="
                                        (cellDesign =
                                          page.getProviderCampaignAdgroupTableCellDesign2(
                                            col.data,
                                            cindex,
                                            item,
                                            adprodccmrs
                                          ))
                                      "
                                    >
                                      <div
                                        v-bind:style="{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          padding: cellDesign.design == 'box' ? '0px' : '4px',
                                          paddingLeft: '0px',
                                        }"
                                      >
                                        <div
                                          style="height: 100%; width: "
                                          v-bind:style="{
                                            width: cellDesign.isColored
                                              ? cellDesign.percent + '%'
                                              : '0%',
                                            background: cellDesign.isColored
                                              ? cellDesign.color
                                              : '#FFFFFF',
                                            border:
                                              cellDesign.border != null ? cellDesign.border : '',
                                          }"
                                        ></div>
                                      </div>

                                      <div
                                        style="
                                          padding: 8px;
                                          position: relative;
                                          top: 0;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "
                                      >
                                        {{ col.getString('consultant') }}
                                      </div>
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div
                        style="overflow: auto; width: 100%"
                        class="scroll_bar"
                        v-if="isAllTabSelected"
                      >
                        <div
                          :style="
                            adprovidertalbewidth(
                              'alltab',
                              page.getProviderAllTabTableHeader(),
                              'selectalltable'
                            )
                          "
                          style="padding-bottom: 8px"
                        >
                          <table
                            id="alltab_table_tag"
                            class="
                              floater_table floater_table--full
                              provider_campaign_table
                              expansion_table
                            "
                            v-if="!page.providerCampaignAdgroupTableNetworking && isAllTabSelected"
                          >
                            <thead class="floater_table__header expansion_table__header">
                              <tr
                                v-if="
                                  isCells2SpanCells(
                                    page.getProviderAllTabTableHeader(),
                                    'selectalltable'
                                  )
                                "
                              >
                                <template
                                  v-for="(item, index) in cells2SpanCells(
                                    page.getProviderAllTabTableHeader(),
                                    'selectalltable'
                                  )"
                                  :key="index"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <template
                                  v-for="(
                                    header_cell, index
                                  ) in page.getProviderAllTabTableHeader()"
                                  :key="index"
                                >
                                  <th
                                    ref="alltab_header_ref"
                                    :id="'alltab_header_id_' + index"
                                    v-if="isShowAdproviderColum(index, 'selectalltable')"
                                    v-on:click.stop="showShowHide($event, index, 'selectalltable')"
                                    style="position: relative; cursor: pointer"
                                    :style="[teststyle('alltab', index)]"
                                    class="floater_table__summary_weekly expansion_table__th"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == index && expType == 'alltab'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('alltab', index, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    {{ spanTilte2HeaderTitle(header_cell.getString()) }}
                                  </th>
                                </template>
                              </tr>
                            </thead>

                            <tbody class="floater_table__body"
                              :set="(pats = page.getProviderAllTabTable())">
                              <template
                                v-for="(item, index) in pats"
                                :key="index"
                              >
                                <tr :class="[index == 0 ? 'consultant_record__trmark2' : '']">
                                  <td
                                    class="floater_table__titlecell floater_table__summary_weekly"
                                    v-on:click="
                                      selectCategory3(item.name.getString(), item.dimensionList)
                                    "
                                    :colspan="[
                                    (page.getProviderAllCategoryLength() == 2 && item.dimensionList.length == 1) ? 2 : 1
                                    ]"
                                    v-if="
                                    (page.getProviderAllCategoryLength() == 1) ||
                                    (page.getProviderAllCategoryLength() == 2 && item.dimensionList[0].value == 'ALL') ||
                                    (page.getProviderAllCategoryLength() == 2 && item.dimensionList.length > 1 && item.dimensionList[1].value == 'ALL')
                                    "
                                    :rowspan="patsrowspan(pats, item.dimensionList)"
                                  >
                                    <div
                                      style="
                                        padding: 8px;
                                        text-decoration: underline;
                                        cursor: pointer;
                                      "
                                    >
                                      {{ item.name.getString() }}
                                    </div>
                                  </td>
                                  <td v-if="page.getProviderAllCategoryLength() == 2 && item.dimensionList.length > 1"
                                    class="floater_table__titlecell floater_table__summary_weekly" v-on:click="
                                      selectCategory3(item.name.getString(), item.dimensionList)
                                    ">
                                    <div style="
                                          padding: 8px;
                                          text-decoration: underline;
                                          cursor: pointer;
                                        "
                                        v-if="item.dimensionList.length == 2">
                                      {{ item.dimensionList[1].value }}
                                    </div>
                                    <div style="
                                          padding: 8px;
                                          text-decoration: underline;
                                          cursor: pointer;
                                        "
                                        v-if="item.dimensionList.length == 3">
                                      {{ item.dimensionList[1].value }}
                                    </div>
                                  </td>
                                  <template v-for="(cell, index_col) in item.row" :key="index_col">
                                    <td
                                      v-if="isShowAdproviderColum(page.getProviderAllCategoryLength() + index_col, 'selectalltable')"
                                      class="floater_table__summary_weekly"
                                    >
                                      <div style="padding: 8px">{{ cell.getString() }}</div>
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hspacer" :style="{ height: getHSpacerHeight() + 'px' }"></div>
                <div class="box" style="width: 100%">
                  <div style="width: 100%">
                    <div class="frame__body">
                      <div
                        v-if="!page.isProviderTableNetworking(selectedProvider.name, 'monthly')"
                        :style="{ width: getProviderCampaignAdgroupTableWidth()[1] }"
                      >
                        <div style="width: fit-content">
                          <div style="display: flex">
                            <div
                              class="selectbtn clickable"
                              v-bind:class="[page.selectedMode == 1 ? 'selectbtn--active' : '']"
                              v-on:click="changeMode(1)"
                            >
                              月別
                            </div>
                            <div
                              class="selectbtn clickable"
                              v-bind:class="[page.selectedMode == 2 ? 'selectbtn--active' : '']"
                              v-on:click="changeMode(2)"
                            >
                              週別
                            </div>
                            <div
                              class="selectbtn clickable"
                              v-bind:class="[page.selectedMode == 3 ? 'selectbtn--active' : '']"
                              v-on:click="changeMode(3)"
                            >
                              日別
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="scroll_bar" style="overflow: auto" v-if="isAllTabSelected">
                      <div class="frame__body" v-if="selectedCategory != ''">
                        <div
                          v-if="
                            page.isProviderTableNetworking(
                              selectedProvider.name,
                              page.selectedMode == 1
                                ? 'monthly'
                                : page.selectedMode == 2
                                ? 'weekly'
                                : page.selectedMode == 3
                                ? 'daily'
                                : ''
                            )
                          "
                        >
                          <Spinner v-if="false" />
                          <BoxSpinner style="height: 200px; width: 1600px" />
                        </div>
                        <div
                          v-else
                          style="margin-bottom: 8px"
                          class="providerTable"
                          :style="
                            adprovidertalbewidth(
                              'allmwd',
                              page.getProviderTableHeader(),
                              'selectedtable'
                            )
                          "
                        >
                          <div
                            :style="{
                              paddingBottom: '16px',
                              backgroundColor: 'white',
                              zIndex: '1',
                            }"
                            class="providerTableTitle"
                            v-on:click="
                              copyToClipboard(
                                `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`
                              )
                            "
                          >
                            <div v-if="selectedCategory == 'ALL'">ALL</div>
                            <div v-if="selectedCategory != 'ALL'" style="display: flex">
                              <div
                                v-on:click="selectCategory('ALL')"
                                style="text-decoration: underline; cursor: pointer"
                              >
                                ALL
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px">></div>
                              <div>{{ selectedCategory }}</div>
                            </div>
                            <div v-if="selectedCategory != 'ALL'" style="display: flex"></div>
                          </div>

                          <table
                            id="allmwd_table_tag"
                            class="floater_table floater_table--full expansion_table"
                            :class="[
                              `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`,
                            ]"
                          >
                            <thead
                              class="floater_table__header expansion_table__header"
                              v-if="true"
                            >
                              <tr
                                v-if="
                                  isCells2SpanCells(page.getProviderTableHeader(), 'selectedtable')
                                "
                              >
                                <template
                                  v-for="(item, i1) in cells2SpanCells(
                                    page.getProviderTableHeader(),
                                    'selectedtable'
                                  )"
                                  :key="i1"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <template
                                  v-for="(cell, i1) in page.getProviderTableHeader()"
                                  :key="i1"
                                >
                                  <th
                                    class="floater_table__summary_weekly expansion_table__th"
                                    style="position: relative; cursor: pointer"
                                    selectoption="on"
                                    ref="allmwd_header_ref"
                                    :id="'allmwd_header_id_' + i1"
                                    :style="[teststyle('allmwd', i1)]"
                                    v-if="isShowAdproviderColum(i1, 'selectedtable')"
                                    v-on:click.stop="onMonthHeaderClick(i1, $event)"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == i1 && expType == 'allmwd'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('allmwd', i1, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="limitselector"
                                      v-if="i1 == 0 && isShowMonthLimit == true"
                                    >
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(1)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">短期間表示する</div>
                                        </div>
                                      </div>
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(0)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">長期間表示する</div>
                                        </div>
                                      </div>
                                    </div>
                                    {{ mwd_title_change(spanTilte2HeaderTitle(cell.getString('consultant'))) }}
                                  </th>
                                  <th class="table_dow_header" v-if="i1==0 && page.selectedMode == 3" style="width: 47px">
                                    曜日
                                  </th>
                                </template>
                              </tr>
                            </thead>

                            <tbody class="floater_table__body">
                              <template
                                :set="
                                  (providerTable = page.getCategoryAllTable(
                                    selectedCategory + selectedDimension2,
                                    page.getSelectedModeString()
                                  ))
                                "
                              />
                              <template v-for="(row, i2) in providerTable" :key="i2">
                                <tr>
                                  <template v-for="(cell, i3) in row" :key="i3">
                                    <td
                                      v-if="isShowAdproviderColum(i3, 'selectedtable')"
                                      v-bind:class="[i3 == 0 ? 'floater_table__titlecell' : '']"
                                      class="floater_table__summary_weekly"
                                      style="padding: 0px; position: relative"
                                      :set="
                                        (cellDesign = page.getProviderTableCellDesign(
                                          cell.data,
                                          providerTable,
                                          i3
                                        ))
                                      "
                                    >
                                      <div
                                        v-bind:style="{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          padding: cellDesign.design == 'box' ? '0px' : '4px',
                                          paddingLeft: '0px',
                                        }"
                                      >
                                        <div
                                          style="height: 100%; width: "
                                          v-bind:style="{
                                            width: cellDesign.isColored
                                              ? cellDesign.percent + '%'
                                              : '0%',
                                            background: cellDesign.isColored
                                              ? cellDesign.color
                                              : '#FFFFFF',
                                            border:
                                              cellDesign.border != null ? cellDesign.border : '',
                                          }"
                                        ></div>
                                      </div>
                                      <div
                                        style="
                                          padding: 8px;
                                          position: relative;
                                          top: 0;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "
                                      >
                                        <div
                                          v-if="
                                            !cell.getString('consultant').includes('(着地想定)')
                                          "
                                        >
                                          {{ cell.getString('consultant') }}
                                        </div>
                                        <div
                                          v-if="cell.getString('consultant').includes('(着地想定)')"
                                        >
                                          {{
                                            cell.getString('consultant').replace('(着地想定)', '')
                                          }}
                                          <div style="font-size: 80%; display: inline-block">
                                            (着地想定)
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td v-if="i3==0 && page.selectedMode == 3" class="table_dow_cell" style="width: 47px">
                                      {{ page.day2dayOfWeek(row[0].data) }}
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="frame__body" v-if="selectedDimension1 != ''">
                        <div
                          v-if="
                            page.isProviderDimensionTableNetworking(
                              selectedProvider.name,
                              page.selectedMode == 1
                                ? 'monthly'
                                : page.selectedMode == 2
                                ? 'weekly'
                                : page.selectedMode == 3
                                ? 'daily'
                                : ''
                            )
                          "
                        >
                          <Spinner v-if="false" />
                          <BoxSpinner style="height: 200px; width: 1600px" />
                        </div>
                        <div
                          v-else
                          style="margin-bottom: 8px"
                          class="providerTable"
                          :style="
                            adprovidertalbewidth(
                              'mwd2',
                              page.getProviderTableHeader(),
                              'selectedtable'
                            )
                          "
                        >
                          <div
                            :style="{
                              paddingBottom: '16px',
                              backgroundColor: 'white',
                              zIndex: '1',
                            }"
                            class="providerTableTitle"
                            v-on:click="
                              copyToClipboard(
                                `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`
                              )
                            "
                          >
                            <div v-if="selectedCategory == 'ALL'">ALL</div>
                            <div v-if="selectedCategory != 'ALL'" style="display: flex">
                              <div
                                v-on:click.stop="selectCategory('ALL')"
                                style="text-decoration: underline; cursor: pointer"
                              >
                                ALL
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px">></div>
                              <div v-if="selectedDimension2 != ''" v-on:click.stop="selectCategory3(selectedDimension1, [selectedDimensionListAlltab[0]])" style="text-decoration: underline; cursor: pointer">
                                {{ selectedDimension1 }}
                              </div>
                              <div v-if="selectedDimension2 == ''">
                                {{ selectedDimension1 }}
                              </div>
                              <div
                                style="margin-left: 8px; margin-right: 8px"
                                v-if="selectedDimension2 != ''"
                              >
                                >
                              </div>
                              <div>{{ selectedDimension2 }}</div>
                            </div>
                          </div>

                          <table
                            id="mwd2_table_tag"
                            class="floater_table floater_table--full expansion_table"
                            :class="[
                              `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`,
                            ]"
                          >
                            <thead
                              class="floater_table__header expansion_table__header"
                              v-if="true"
                            >
                              <tr v-if="isCells2SpanCells(page.getProviderDimensionTableHeader())">
                                <template
                                  v-for="(item, i1) in cells2SpanCells(
                                    page.getProviderDimensionTableHeader(),
                                    'selectedtable'
                                  )"
                                  :key="i1"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <template
                                  v-for="(cell, i1) in page.getProviderDimensionTableHeader()"
                                  :key="i1"
                                >
                                  <th
                                    class="floater_table__summary_weekly expansion_table__th"
                                    style="position: relative; cursor: pointer"
                                    selectoption="on"
                                    ref="mwd2_header_ref"
                                    :id="'mwd2_header_id_' + i1"
                                    :style="[teststyle('mwd2', i1)]"
                                    v-if="isShowAdproviderColum(i1, 'selectedtable')"
                                    v-on:click.stop="onMonthHeaderClick(i1, $event)"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == i1 && expType == 'mwd2'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('mwd2', i1, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="limitselector"
                                      v-if="i1 == 0 && isShowMonthLimit == true"
                                    >
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(1)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">短期間表示する</div>
                                        </div>
                                      </div>
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(0)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">長期間表示する</div>
                                        </div>
                                      </div>
                                    </div>
                                    {{ mwd_title_change(spanTilte2HeaderTitle(cell.getString('consultant'))) }}
                                  </th>
                                  <th class="table_dow_header" v-if="i1==0 && page.selectedMode == 3" style="width: 47px">
                                    曜日
                                  </th>
                                </template>
                              </tr>
                            </thead>

                            <tbody class="floater_table__body">
                              <template
                                :set="
                                  (providerTable = page.getProviderDimensionTableCategory(
                                    selectedProvider.name,
                                    selectedDimension1 + selectedDimension2,
                                    page.getSelectedModeString()
                                  ))
                                "
                              />
                              <template v-for="(row, i2) in providerTable" :key="i2">
                                <tr>
                                  <template v-for="(cell, i3) in row" :key="i3">
                                    <td
                                      v-if="isShowAdproviderColum(i3, 'selectedtable')"
                                      v-bind:class="[i3 == 0 ? 'floater_table__titlecell' : '']"
                                      class="floater_table__summary_weekly"
                                      style="padding: 0px; position: relative"
                                      :set="
                                        (cellDesign = page.getProviderDimensionTableCellDesign(
                                          cell.data,
                                          providerTable,
                                          i3
                                        ))
                                      "
                                    >
                                      <div
                                        v-bind:style="{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          padding: cellDesign.design == 'box' ? '0px' : '4px',
                                          paddingLeft: '0px',
                                        }"
                                      >
                                        <div
                                          style="height: 100%; width: "
                                          v-bind:style="{
                                            width: cellDesign.isColored
                                              ? cellDesign.percent + '%'
                                              : '0%',
                                            background: cellDesign.isColored
                                              ? cellDesign.color
                                              : '#FFFFFF',
                                            border:
                                              cellDesign.border != null ? cellDesign.border : '',
                                          }"
                                        ></div>
                                      </div>
                                      <div
                                        style="
                                          padding: 8px;
                                          position: relative;
                                          top: 0;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "
                                      >
                                        <div
                                          v-if="
                                            !cell.getString('consultant').includes('(着地想定)')
                                          "
                                        >
                                          {{ cell.getString('consultant') }}
                                        </div>
                                        <div
                                          v-if="cell.getString('consultant').includes('(着地想定)')"
                                        >
                                          {{
                                            cell.getString('consultant').replace('(着地想定)', '')
                                          }}
                                          <div style="font-size: 80%; display: inline-block">
                                            (着地想定)
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td v-if="i3==0 && page.selectedMode == 3" class="table_dow_cell" style="width: 47px">
                                      {{ page.day2dayOfWeek(row[0].data) }}
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="scroll_bar" style="overflow: auto" v-if="!isAllTabSelected">
                      <div class="frame__body" v-if="selectedCategory != ''">
                        <div
                          v-if="
                            page.isProviderTableNetworking(
                              selectedProvider.name,
                              page.selectedMode == 1
                                ? 'monthly'
                                : page.selectedMode == 2
                                ? 'weekly'
                                : page.selectedMode == 3
                                ? 'daily'
                                : ''
                            )
                          "
                        >
                          <Spinner v-if="false" />
                          <BoxSpinner style="height: 200px; width: 1600px" />
                        </div>
                        <div
                          v-else
                          style="margin-bottom: 8px"
                          class="providerTable"
                          :style="
                            adprovidertalbewidth(
                              'mwd',
                              page.getProviderTableHeader(),
                              'selectedtable'
                            )
                          "
                        >
                          <div
                            :style="{
                              paddingBottom: '16px',
                              backgroundColor: 'white',
                              zIndex: '1',
                            }"
                            class="providerTableTitle"
                            v-on:click="
                              copyToClipboard(
                                `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`
                              )
                            "
                          >
                            <div v-if="selectedCategory == 'ALL'">ALL</div>
                            <div v-if="selectedCategory != 'ALL'" style="display: flex">
                              <div
                                v-on:click="selectCategory('ALL')"
                                style="text-decoration: underline; cursor: pointer"
                              >
                                ALL
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px">></div>
                              <div>{{ selectedCategory }}</div>
                            </div>
                            <div v-if="selectedCategory != 'ALL'" style="display: flex"></div>
                          </div>

                          <table
                            id="mwd_table_tag"
                            class="floater_table floater_table--full expansion_table"
                            :class="[
                              `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`,
                            ]"
                          >
                            <thead
                              class="floater_table__header expansion_table__header"
                              v-if="true"
                            >
                              <tr
                                v-if="
                                  isCells2SpanCells(page.getProviderTableHeader(), 'selectedtable')
                                "
                              >
                                <template
                                  v-for="(item, i1) in cells2SpanCells(
                                    page.getProviderTableHeader(),
                                    'selectedtable'
                                  )"
                                  :key="i1"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <template
                                  v-for="(cell, i1) in page.getProviderTableHeader()"
                                  :key="i1"
                                >
                                  <th
                                    class="floater_table__summary_weekly expansion_table__th"
                                    style="position: relative; cursor: pointer"
                                    selectoption="on"
                                    ref="mwd_header_ref"
                                    :id="'mwd_header_id_' + i1"
                                    :style="[teststyle('mwd', i1)]"
                                    v-if="isShowAdproviderColum(i1, 'selectedtable')"
                                    v-on:click.stop="onMonthHeaderClick(i1, $event)"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == i1 && expType == 'mwd'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('mwd', i1, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="limitselector"
                                      v-if="i1 == 0 && isShowMonthLimit == true"
                                    >
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(1)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">短期間表示する</div>
                                        </div>
                                      </div>
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(0)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">長期間表示する</div>
                                        </div>
                                      </div>
                                    </div>
                                    {{ mwd_title_change(spanTilte2HeaderTitle(cell.getString('consultant'))) }}
                                  </th>
                                  <th class="table_dow_header" v-if="i1==0 && page.selectedMode == 3" style="width: 47px">
                                    曜日
                                  </th>
                                </template>
                              </tr>
                            </thead>

                            <tbody class="floater_table__body">
                              <template
                                :set="
                                  (providerTable = page.getProviderTableCategory(
                                    selectedProvider.name,
                                    selectedCategory,
                                    page.getSelectedModeString()
                                  ))
                                "
                              />
                              <template v-for="(row, i2) in providerTable" :key="i2">
                                <tr>
                                  <template v-for="(cell, i3) in row" :key="i3">
                                    <td
                                      v-if="isShowAdproviderColum(i3, 'selectedtable')"
                                      v-bind:class="[i3 == 0 ? 'floater_table__titlecell' : '']"
                                      class="floater_table__summary_weekly"
                                      style="padding: 0px; position: relative"
                                      :set="
                                        (cellDesign = page.getProviderTableCellDesign(
                                          cell.data,
                                          providerTable,
                                          i3
                                        ))
                                      "
                                    >
                                      <div
                                        v-bind:style="{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          padding: cellDesign.design == 'box' ? '0px' : '4px',
                                          paddingLeft: '0px',
                                        }"
                                      >
                                        <div
                                          style="height: 100%; width: "
                                          v-bind:style="{
                                            width: cellDesign.isColored
                                              ? cellDesign.percent + '%'
                                              : '0%',
                                            background: cellDesign.isColored
                                              ? cellDesign.color
                                              : '#FFFFFF',
                                            border:
                                              cellDesign.border != null ? cellDesign.border : '',
                                          }"
                                        ></div>
                                      </div>
                                      <div
                                        style="
                                          padding: 8px;
                                          position: relative;
                                          top: 0;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "
                                      >
                                        <div
                                          v-if="
                                            !cell.getString('consultant').includes('(着地想定)')
                                          "
                                        >
                                          {{ cell.getString('consultant') }}
                                        </div>
                                        <div
                                          v-if="cell.getString('consultant').includes('(着地想定)')"
                                        >
                                          {{
                                            cell.getString('consultant').replace('(着地想定)', '')
                                          }}
                                          <div style="font-size: 80%; display: inline-block">
                                            (着地想定)
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td v-if="i3==0 && page.selectedMode == 3" class="table_dow_cell" style="width: 47px">
                                      {{ page.day2dayOfWeek(row[0].data) }}
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="frame__body" v-if="selectedCampaign != ''">
                        <div
                          v-if="
                            page.isProviderCampTableNetworking(
                              selectedProvider.name,
                              page.selectedMode == 1
                                ? 'monthly'
                                : page.selectedMode == 2
                                ? 'weekly'
                                : page.selectedMode == 3
                                ? 'daily'
                                : ''
                            )
                          "
                        >
                          <Spinner v-if="false" />
                          <BoxSpinner style="height: 200px; width: 1600px" />
                        </div>
                        <div
                          v-else
                          style="margin-bottom: 8px"
                          class="providerTable"
                          :style="
                            adprovidertalbewidth(
                              'mwd1',
                              page.getProviderTableHeader(),
                              'selectedtable'
                            )
                          "
                        >
                          <div
                            :style="{
                              paddingBottom: '16px',
                              backgroundColor: 'white',
                              zIndex: '1',
                            }"
                            class="providerTableTitle"
                            v-on:click="
                              copyToClipboard(
                                `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`
                              )
                            "
                          >
                            <div v-if="selectedCategory == 'ALL'">ALL</div>
                            <div v-if="selectedCategory != 'ALL'" style="display: flex">
                              <div
                                v-on:click.stop="selectCategory('ALL')"
                                style="text-decoration: underline; cursor: pointer"
                              >
                                ALL
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px">></div>
                              <div
                                style="text-decoration: underline; cursor: pointer"
                                v-on:click.stop="selectCategory2(selectedCampaignCategory, selectedCampaignCategoryId)"
                              >
                                {{ selectedCampaignCategory }}
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px" v-if="selectedCampaignCategory2 != ''">></div>
                              <div
                                v-if="selectedCampaignCategory2 != ''"
                                style="text-decoration: underline; cursor: pointer"
                                v-on:click.stop="selectCategory2(selectedCampaignCategory, selectedCampaignCategoryId, selectedCampaignCategory2, selectedCampaignCategory2Id)"
                              >
                                {{ selectedCampaignCategory2 }}
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px">></div>
                              <div>{{ selectedCampaign }}</div>
                            </div>
                          </div>

                          <table
                            id="mwd1_table_tag"
                            class="floater_table floater_table--full expansion_table"
                            :class="[
                              `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`,
                            ]"
                          >
                            <thead
                              class="floater_table__header expansion_table__header"
                              v-if="true"
                            >
                              <tr v-if="isCells2SpanCells(page.getProviderCampTableHeader())">
                                <template
                                  v-for="(item, i1) in cells2SpanCells(
                                    page.getProviderCampTableHeader(),
                                    'selectedtable'
                                  )"
                                  :key="i1"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <template
                                  v-for="(cell, i1) in page.getProviderCampTableHeader()"
                                  :key="i1"
                                >
                                  <th
                                    class="floater_table__summary_weekly expansion_table__th"
                                    style="position: relative; cursor: pointer"
                                    selectoption="on"
                                    ref="mwd1_header_ref"
                                    :id="'mwd1_header_id_' + i1"
                                    :style="[teststyle('mwd1', i1)]"
                                    v-if="isShowAdproviderColum(i1, 'selectedtable')"
                                    v-on:click.stop="onMonthHeaderClick(i1, $event)"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == i1 && expType == 'mwd1'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('mwd1', i1, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="limitselector"
                                      v-if="i1 == 0 && isShowMonthLimit == true"
                                    >
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(1)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">短期間表示する</div>
                                        </div>
                                      </div>
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(0)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">長期間表示する</div>
                                        </div>
                                      </div>
                                    </div>
                                    {{ mwd_title_change(spanTilte2HeaderTitle(cell.getString('consultant'))) }}
                                  </th>
                                  <th class="table_dow_header" v-if="i1==0 && page.selectedMode == 3" style="width: 47px">
                                    曜日
                                  </th>
                                </template>
                              </tr>
                            </thead>

                            <tbody class="floater_table__body">
                              <template
                                :set="
                                  (providerTable = page.getProviderCampTableCategory(
                                    selectedProvider.name,
                                    selectedCampaign,
                                    page.getSelectedModeString()
                                  ))
                                "
                              />
                              <template v-for="(row, i2) in providerTable" :key="i2">
                                <tr>
                                  <template v-for="(cell, i3) in row" :key="i3">
                                    <td
                                      v-if="isShowAdproviderColum(i3, 'selectedtable')"
                                      v-bind:class="[i3 == 0 ? 'floater_table__titlecell' : '']"
                                      class="floater_table__summary_weekly"
                                      style="padding: 0px; position: relative"
                                      :set="
                                        (cellDesign = page.getProviderCampTableCellDesign(
                                          cell.data,
                                          providerTable,
                                          i3
                                        ))
                                      "
                                    >
                                      <div
                                        v-bind:style="{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          padding: cellDesign.design == 'box' ? '0px' : '4px',
                                          paddingLeft: '0px',
                                        }"
                                      >
                                        <div
                                          style="height: 100%; width: "
                                          v-bind:style="{
                                            width: cellDesign.isColored
                                              ? cellDesign.percent + '%'
                                              : '0%',
                                            background: cellDesign.isColored
                                              ? cellDesign.color
                                              : '#FFFFFF',
                                            border:
                                              cellDesign.border != null ? cellDesign.border : '',
                                          }"
                                        ></div>
                                      </div>
                                      <div
                                        style="
                                          padding: 8px;
                                          position: relative;
                                          top: 0;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "
                                      >
                                        <div
                                          v-if="
                                            !cell.getString('consultant').includes('(着地想定)')
                                          "
                                        >
                                          {{ cell.getString('consultant') }}
                                        </div>
                                        <div
                                          v-if="cell.getString('consultant').includes('(着地想定)')"
                                        >
                                          {{
                                            cell.getString('consultant').replace('(着地想定)', '')
                                          }}
                                          <div style="font-size: 80%; display: inline-block">
                                            (着地想定)
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td v-if="i3==0 && page.selectedMode == 3" class="table_dow_cell" style="width: 47px">
                                      {{ page.day2dayOfWeek(row[0].data) }}
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="frame__body" v-if="selectedAdgroup != ''">
                        <div
                          v-if="
                            page.isProviderAdgTableNetworking(
                              selectedProvider.name,
                              page.selectedMode == 1
                                ? 'monthly'
                                : page.selectedMode == 2
                                ? 'weekly'
                                : page.selectedMode == 3
                                ? 'daily'
                                : ''
                            )
                          "
                        >
                          <Spinner v-if="false" />
                          <BoxSpinner style="height: 200px; width: 1600px" />
                        </div>
                        <div
                          v-else
                          style="margin-bottom: 8px"
                          class="providerTable"
                          :style="
                            adprovidertalbewidth(
                              'mwd2',
                              page.getProviderTableHeader(),
                              'selectedtable'
                            )
                          "
                        >
                          <div
                            :style="{
                              paddingBottom: '16px',
                              backgroundColor: 'white',
                              zIndex: '1',
                            }"
                            class="providerTableTitle"
                            v-on:click="
                              copyToClipboard(
                                `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`
                              )
                            "
                          >
                            <div v-if="selectedCategory == 'ALL'">ALL</div>
                            <div v-if="selectedCategory != 'ALL'" style="display: flex">
                              <div
                                v-on:click.stop="selectCategory('ALL')"
                                style="text-decoration: underline; cursor: pointer"
                              >
                                ALL
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px">></div>
                              <div
                                style="text-decoration: underline; cursor: pointer"
                                v-on:click.stop="
                                [(page.adproDCCAdGroupMonthlyHolder.getDimension() == 1) ? selectCategory(selectedAdgroupCategory) : selectCategory2(selectedAdgroupCategory, selectedAdgroupCategoryId, selectedAdgroupCategory2, selectedAdgroupCategoryId2)]"
                              >
                                {{ selectedAdgroupCategory }}
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px" v-if="selectedAdgroupCategory2 != ''">></div>
                              <div
                                v-if="selectedAdgroupCategory2 != ''"
                                style="text-decoration: underline; cursor: pointer"
                                v-on:click.stop="selectCategory2(selectedAdgroupCategory, selectedAdgroupCategoryId, selectedAdgroupCategory2, selectedAdgroupCategoryId2)"
                              >
                                {{ selectedAdgroupCategory2 }}
                              </div>

                              <div style="margin-left: 8px; margin-right: 8px">></div>
                              <div
                                v-on:click.stop="selectCampaign(selectedAdgroupCampaign, selectedAdgroupCategory, selectedAdgroupCategoryId, selectedAdgroupCategory2, selectedAdgroupCategoryId2)"
                                style="text-decoration: underline; cursor: pointer"
                              >
                                {{ selectedAdgroupCampaign }}
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px">></div>
                              <div>{{ selectedAdgroup }}</div>
                            </div>
                          </div>

                          <table
                            id="mwd2_table_tag"
                            class="floater_table floater_table--full expansion_table"
                            :class="[
                              `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`,
                            ]"
                          >
                            <thead
                              class="floater_table__header expansion_table__header"
                              v-if="true"
                            >
                              <tr v-if="isCells2SpanCells(page.getProviderAdgTableHeader())">
                                <template
                                  v-for="(item, i1) in cells2SpanCells(
                                    page.getProviderAdgTableHeader(),
                                    'selectedtable'
                                  )"
                                  :key="i1"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <template
                                  v-for="(cell, i1) in page.getProviderAdgTableHeader()"
                                  :key="i1"
                                >
                                  <th
                                    class="floater_table__summary_weekly expansion_table__th"
                                    style="position: relative; cursor: pointer"
                                    selectoption="on"
                                    ref="mwd2_header_ref"
                                    :id="'mwd2_header_id_' + i1"
                                    :style="[teststyle('mwd2', i1)]"
                                    v-if="isShowAdproviderColum(i1, 'selectedtable')"
                                    v-on:click.stop="onMonthHeaderClick(i1, $event)"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == i1 && expType == 'mwd2'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('mwd2', i1, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="limitselector"
                                      v-if="i1 == 0 && isShowMonthLimit == true"
                                    >
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(1)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">短期間表示する</div>
                                        </div>
                                      </div>
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(0)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">長期間表示する</div>
                                        </div>
                                      </div>
                                    </div>
                                    {{ mwd_title_change(spanTilte2HeaderTitle(cell.getString('consultant'))) }}
                                  </th>
                                  <th class="table_dow_header" v-if="i1==0 && page.selectedMode == 3" style="width: 47px">
                                    曜日
                                  </th>
                                </template>
                              </tr>
                            </thead>

                            <tbody class="floater_table__body">
                              <template
                                :set="
                                  (providerTable = page.getProviderAdgTableCategory(
                                    selectedProvider.name,
                                    selectedAdgroup,
                                    page.getSelectedModeString()
                                  ))
                                "
                              />
                              <template v-for="(row, i2) in providerTable" :key="i2">
                                <tr>
                                  <template v-for="(cell, i3) in row" :key="i3">
                                    <td
                                      v-if="isShowAdproviderColum(i3, 'selectedtable')"
                                      v-bind:class="[i3 == 0 ? 'floater_table__titlecell' : '']"
                                      class="floater_table__summary_weekly"
                                      style="padding: 0px; position: relative"
                                      :set="
                                        (cellDesign = page.getProviderAdgTableCellDesign(
                                          cell.data,
                                          providerTable,
                                          i3
                                        ))
                                      "
                                    >
                                      <div
                                        v-bind:style="{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          padding: cellDesign.design == 'box' ? '0px' : '4px',
                                          paddingLeft: '0px',
                                        }"
                                      >
                                        <div
                                          style="height: 100%; width: "
                                          v-bind:style="{
                                            width: cellDesign.isColored
                                              ? cellDesign.percent + '%'
                                              : '0%',
                                            background: cellDesign.isColored
                                              ? cellDesign.color
                                              : '#FFFFFF',
                                            border:
                                              cellDesign.border != null ? cellDesign.border : '',
                                          }"
                                        ></div>
                                      </div>
                                      <div
                                        style="
                                          padding: 8px;
                                          position: relative;
                                          top: 0;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "
                                      >
                                        <div
                                          v-if="
                                            !cell.getString('consultant').includes('(着地想定)')
                                          "
                                        >
                                          {{ cell.getString('consultant') }}
                                        </div>
                                        <div
                                          v-if="cell.getString('consultant').includes('(着地想定)')"
                                        >
                                          {{
                                            cell.getString('consultant').replace('(着地想定)', '')
                                          }}
                                          <div style="font-size: 80%; display: inline-block">
                                            (着地想定)
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td v-if="i3==0 && page.selectedMode == 3" class="table_dow_cell" style="width: 47px">
                                      {{ page.day2dayOfWeek(row[0].data) }}
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="frame__body" v-if="selectedDimension1 != ''">
                        <div
                          v-if="
                            page.isProviderDimensionTableNetworking(
                              selectedProvider.name,
                              page.selectedMode == 1
                                ? 'monthly'
                                : page.selectedMode == 2
                                ? 'weekly'
                                : page.selectedMode == 3
                                ? 'daily'
                                : ''
                            )
                          "
                        >
                          <Spinner v-if="false" />
                          <BoxSpinner style="height: 200px; width: 1600px" />
                        </div>
                        <div
                          v-else
                          style="margin-bottom: 8px"
                          class="providerTable"
                          :style="
                            adprovidertalbewidth(
                              'mwd2',
                              page.getProviderTableHeader(),
                              'selectedtable'
                            )
                          "
                        >
                          <div
                            :style="{
                              paddingBottom: '16px',
                              backgroundColor: 'white',
                              zIndex: '1',
                            }"
                            class="providerTableTitle"
                            v-on:click="
                              copyToClipboard(
                                `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`
                              )
                            "
                          >
                            <div v-if="selectedCategory == 'ALL'">ALL</div>
                            <div v-if="selectedCategory != 'ALL'" style="display: flex">
                              <div
                                v-on:click.stop="selectCategory('ALL')"
                                style="text-decoration: underline; cursor: pointer"
                              >
                                ALL
                              </div>
                              <div style="margin-left: 8px; margin-right: 8px">></div>
                              <div
                                v-if="selectedDimension2 == ''"
                              >
                                {{ selectedDimension1 }}
                              </div>
                              <div
                                style="text-decoration: underline; cursor: pointer"
                                v-if="selectedDimension2 != ''"
                                v-on:click.stop="selectCategory2(selectedDimension1, selectedDimension1Id)"
                              >
                                {{ selectedDimension1 }}
                              </div>
                              <div
                                style="margin-left: 8px; margin-right: 8px"
                                v-if="selectedDimension2 != ''"
                              >
                                >
                              </div>
                              <div>{{ selectedDimension2 }}</div>
                            </div>
                          </div>

                          <table
                            id="mwd2_table_tag"
                            class="floater_table floater_table--full expansion_table"
                            :class="[
                              `table_${selectedProvider.name}${selectedCategory}${page.selectedMode}`,
                            ]"
                          >
                            <thead
                              class="floater_table__header expansion_table__header"
                              v-if="true"
                            >
                              <tr v-if="isCells2SpanCells(page.getProviderDimensionTableHeader())">
                                <template
                                  v-for="(item, i1) in cells2SpanCells(
                                    page.getProviderDimensionTableHeader(),
                                    'selectedtable'
                                  )"
                                  :key="i1"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>
                              <tr>
                                <template
                                  v-for="(cell, i1) in page.getProviderDimensionTableHeader()"
                                  :key="i1"
                                >
                                  <th
                                    class="floater_table__summary_weekly expansion_table__th"
                                    style="position: relative; cursor: pointer"
                                    selectoption="on"
                                    ref="mwd2_header_ref"
                                    :id="'mwd2_header_id_' + i1"
                                    :style="[teststyle('mwd2', i1)]"
                                    v-if="isShowAdproviderColum(i1, 'selectedtable')"
                                    v-on:click.stop="onMonthHeaderClick(i1, $event)"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == i1 && expType == 'mwd2'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('mwd2', i1, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="limitselector"
                                      v-if="i1 == 0 && isShowMonthLimit == true"
                                    >
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(1)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">短期間表示する</div>
                                        </div>
                                      </div>
                                      <div
                                        class="limitselector__item"
                                        v-on:click.stop="onMonthLimitClick(0)"
                                      >
                                        <div style="display: flex">
                                          <div style="margin-top: -2px">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="14"
                                              width="14"
                                              viewBox="0 0 48 48"
                                            >
                                              <path
                                                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z"
                                              />
                                            </svg>
                                          </div>
                                          <div style="margin-left: 4px">長期間表示する</div>
                                        </div>
                                      </div>
                                    </div>
                                    {{ mwd_title_change(spanTilte2HeaderTitle(cell.getString('consultant'))) }}
                                  </th>
                                  <th class="table_dow_header" v-if="i1==0 && page.selectedMode == 3" style="width: 47px">
                                    曜日
                                  </th>
                                </template>
                              </tr>
                            </thead>

                            <tbody class="floater_table__body">
                              <template
                                :set="
                                  (providerTable = page.getProviderDimensionTableCategory(
                                    selectedProvider.name,
                                    selectedDimension1 + selectedDimension2,
                                    page.getSelectedModeString()
                                  ))
                                "
                              />
                              <template v-for="(row, i2) in providerTable" :key="i2">
                                <tr>
                                  <template v-for="(cell, i3) in row" :key="i3">
                                    <td
                                      v-if="isShowAdproviderColum(i3, 'selectedtable')"
                                      v-bind:class="[i3 == 0 ? 'floater_table__titlecell' : '']"
                                      class="floater_table__summary_weekly"
                                      style="padding: 0px; position: relative"
                                      :set="
                                        (cellDesign = page.getProviderDimensionTableCellDesign(
                                          cell.data,
                                          providerTable,
                                          i3
                                        ))
                                      "
                                    >
                                      <div
                                        v-bind:style="{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          padding: cellDesign.design == 'box' ? '0px' : '4px',
                                          paddingLeft: '0px',
                                        }"
                                      >
                                        <div
                                          style="height: 100%; width: "
                                          v-bind:style="{
                                            width: cellDesign.isColored
                                              ? cellDesign.percent + '%'
                                              : '0%',
                                            background: cellDesign.isColored
                                              ? cellDesign.color
                                              : '#FFFFFF',
                                            border:
                                              cellDesign.border != null ? cellDesign.border : '',
                                          }"
                                        ></div>
                                      </div>
                                      <div
                                        style="
                                          padding: 8px;
                                          position: relative;
                                          top: 0;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "
                                      >
                                        <div
                                          v-if="
                                            !cell.getString('consultant').includes('(着地想定)')
                                          "
                                        >
                                          {{ cell.getString('consultant') }}
                                        </div>
                                        <div
                                          v-if="cell.getString('consultant').includes('(着地想定)')"
                                        >
                                          {{
                                            cell.getString('consultant').replace('(着地想定)', '')
                                          }}
                                          <div style="font-size: 80%; display: inline-block">
                                            (着地想定)
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td v-if="i3==0 && page.selectedMode == 3" class="table_dow_cell" style="width: 47px">
                                      {{ page.day2dayOfWeek(row[0].data) }}
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sub_content_layer">
          <div class="sub_content_wrapper">
            <div id="lay_right_sidemenu" class="right_sidemenu_layer" v-if="false">
              <SideMenu :adx="adx" selectId="adprovider" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ColumnShowHide :proxy="csh" />
  </div>
</template>

<style scoped>

.hspacer {
  width: 100px;
  position: absolute;
  pointer-events: none;
}

</style>

<style scoped>
.left_main_content {
  padding-left: 0px;
}
</style>

<style scoped>
.draglock {
  user-select: none;
}

.expansion_table {
  table-layout: auto;
  width: initial;
  max-width: initial;
}

.expansion_table__th {
  width: initial;
  min-width: 104px;
}

.expansion_table__th:hover {
  user-select: none;
}

.expansion_table__td {
  width: initial;
  min-width: initial;
}

.expansion_table__dragmarker {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 8px;
  height: 29px;
}
.expansion_table__dragmarker--active {
  background: #2680ebdd;
}

.expansion_table__dragmarker:hover {
  background: #2680ebdd;
}

.frame--expansion {
  width: initial;
}

.frame__header--expansion_lock {
  width: 1544px;
}

.scroll_bar::-webkit-scrollbar {
  border-radius: 6px;
  width: 8px;
  height: 12px;
}
.scroll_bar::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: rgba(241, 241, 241, 1);
}
.scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(193, 193, 193, 1);
}

.expansion_table__dragmarker__arrow {
  display: none;
  position: relative;
  pointer-events: none;
}
.expansion_table__dragmarker__larrow {
  position: absolute;
  top: 6px;
  left: -14px;
}
.expansion_table__dragmarker__rarrow {
  position: absolute;
  top: 6px;
  left: -2px;
}

.expansion_table__dragmarker__lrarrow {
  position: absolute;
  top: 6px;
  left: -4px;
}

.expansion_table__dragmarker:hover .expansion_table__dragmarker__arrow {
  display: block;
}
.expansion_table__dragmarker--active .expansion_table__dragmarker__arrow {
  display: block;
}
</style>

<style scoped>
.selectbtn {
  font-size: 12px;
  border-bottom: solid 2px #cccccc;
  padding: 0px 32px 16px;
  color: #cccccc;
}

.selectbtn--active {
  color: #2680eb;
  border-bottom: solid 2px #2680eb;
}
.consultant_record__trmark {
  background: #e3f2fd;
}

.consultant_record__trmark:hover {
  background-color: rgba(212, 212, 212, 0.3);
}
.consultant_record__trmark2 {
  background: #eeeeee;
}

.consultant_record__trmark2:hover {
  background-color: rgba(212, 212, 212, 0.3);
}

.floater_table__titlecell--consultant {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<style scoped>
.limitselector {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 8px;
  background: #fff;
  color: #000;
  z-index: 10;
  border-radius: 8px;
  font: normal normal normal 14px/9px Noto Sans JP;
  box-shadow: 0px 4px 10px #00000088;
}

.limitselector__item {
  padding: 8px;
}

.limitselector__item:hover {
  background: #ccc;
}
</style>

<script>
// @ is an alias to /src
import SideMenu from '@/components/consultant/SideMenu.vue';
import ContentHeader from '@/components/report/ContentHeader.vue';
import Spinner from '@/components/layout/Spinner.vue';
import BoxSpinner from '@/components/layout/BoxSpinner.vue';
import ScreenBarProgress2 from '@/components/ScreenBarProgress2.vue';
//import ChartJs from '@/components/element/ChartJs.vue';

//import VProgress from "@/components/VProgress.vue";

import { AdProviderPage } from '@/js/page/consultant/AdProviderPage.js';

import RangeCalendar from '@/components/RangeCalendar.vue';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';
import {
  cells2SpanCells,
  spanTilte2HeaderTitle,
  titles2Cell,
  is2SpanCell,
} from '@/js/components/spancell.js';

import ColumnShowHide from '@/components/ColumnShowHide.vue';
import { ColumnShowHideProxy, ColumnInfo } from '@/js/components/ColumnShowHideProxy.js';

export default {
  name: 'ConsultantAdProvider',
  data() {
    return {
      name: '媒体別実績',
      page: new AdProviderPage(),
      isLoad: false,
      dateRangeSelector: null,
      isShowRange: false,
      selectedProvider: { label: null, id: null, name: null },
      selectedCategory: 'ALL',
      selectedCampaign: '',
      selectedCampaignCategory: '',
      selectedAdgroupCategory: '',
      selectedAdgroupCategory2: '',
      selectedAdgroupCampaign: '',
      selectedDimension1: '',
      selectedDimension2: '',
      selectedAdgroup: '',
      isShowMonthLimit: false,
      csh: new ColumnShowHideProxy(),
      isAllTabSelected: true,
      isDragmode: false,
      dragstate: 0,
      baseClientX: 0,
      baseWidth: 100,
      exptarget: null,
      expMarkerId: -1,
      expType: '',
      expTemp: 3000,
      exptargetTdClasses: [],
      lastLimitNumber: 0,
      lastMwd: 1,
      selectedDimensionListAlltab: [],
      isActive: true,
    };
  },
  emits: ['screenevent'],
  mounted() {
    let self = this;

    this.isActive = true;
    this.page.process.start();
    this.$emit('screenevent', 'adprovider');

    document.addEventListener('click', this.onClickEvent);
    document.addEventListener('mousemove', this.onMouseMoveEvent);
    document.addEventListener('mouseup', this.onMouseUpEvent);

    let p = this.adx.getScreenStructureById('report').getPageByKey('媒体別実績');
    if (p) {
      this.dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
      this.dateRangeSelector.timeYmdRange.subject.removeAll();
      this.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
        self.isLoad = false;
        self.initialize(p.id, self.dateRangeSelector);
      });

      this.initialize(p.id, this.dateRangeSelector);
    } else {
      this.adx.getScreenStructureById('report').subject.addObserver(function () {
        let p = self.adx.getScreenStructureById('report').getPageByKey('媒体別実績');
        if (p) {
          self.dateRangeSelector = self.adx.getScreenStructureById('report').dateRangeSelector;
          self.dateRangeSelector.timeYmdRange.subject.removeAll();
          self.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
            self.isLoad = false;
            self.initialize(p.id, self.dateRangeSelector);
          });

          self.initialize(p.id, self.dateRangeSelector);
        }
      });
    }
    window.onscroll = function () {
      let providerTables = document.getElementsByClassName('providerTable');
      Array.from(providerTables).forEach((providerTable) => {
        let header = providerTable.getElementsByClassName('floater_table__header')[0];
        let title = providerTable.getElementsByClassName('providerTableTitle')[0];
        if (!header || !title) return;
        if (
          providerTable.offsetTop < document.documentElement.scrollTop + 100 &&
          providerTable.offsetTop + providerTable.offsetHeight >
            document.documentElement.scrollTop + 100
        ) {
          header.style.position = 'relative';
          header.style.top =
            document.documentElement.scrollTop - providerTable.offsetTop + 100 + 'px';
          title.style.position = 'relative';
          title.style.top =
            document.documentElement.scrollTop - providerTable.offsetTop + 100 + 'px';
        } else {
          header.style.position = '';
          header.style.top = '';
          title.style.position = '';
          title.style.top = '';
        }
      });
    };
  },
  methods: {
    patsrowspan(rs, dim) {
      if(dim.length > 1) {
        if (dim[1].value == "ALL") {
          let cnt = 0;
          for (let i in rs) {
            if (rs[i].dimensionList.length > 1) {
              if(rs[i].dimensionList[0].value == dim[0].value) {
                cnt += 1;
              }
            }
          }
          return cnt;
        }
      }
      return 1;
    },
    mwd_title_change(title) {
      if (title == "種別" || title == "週") {
        if (this.page.selectedMode == 1) {
          return "月別";
        }
        if (this.page.selectedMode == 2) {
          return "週別";
        }
        if (this.page.selectedMode == 3) {
          return "日別";
        }
      }
      return title;
    },
    getHSpacerHeight() {
      switch(this.lastMwd) {
        case 1:
          if (this.lastLimitNumber == 0) {
            return 950;
          }
          return 380;
        case 2:
          if (this.lastLimitNumber == 0) {
            return 1000;
          }
          return 530;
        case 3:
          if (this.page.limitmode  == 0) {
            return 1770;
          }
          return 940;
      }
      return 380;
    },
    is2SpanCell(cell) {
      return is2SpanCell(cell);
    },
    xxx() {
      if (this.dragstate == 2 && 'summary' == this.expType && this.expMarkerId == 0) {
        return {};
      }
      return this.teststyle('summary', 0);
    },
    yyy() {
      if (this.dragstate == 2 && 'summary' == this.expType && this.expMarkerId == 1) {
        return {};
      }
      return this.teststyle('summary', 1);
    },
    xxx2() {
      if (this.dragstate == 2 && 'summary' == this.expType && this.expMarkerId == 2) {
        return {};
      }
      return this.teststyle('summary', 2);
    },
    yyy2() {
      if (this.dragstate == 2 && 'summary' == this.expType && this.expMarkerId == 3) {
        return {};
      }
      return this.teststyle('summary', 3);
    },

    adprovidertalbewidth(type, hs, showtype = '') {
      if (this.dragstate == 2 && type == this.expType) {
        return {
          width: this.expTemp + 'px',
        };
      }

      let width = 0;
      for (let i = 0; i < hs.length; i++) {
        let css = this.page.columnStyleStocker
          .getColumnStyles(this.page.advertizerId, 'adprovider', type)
          ?.getColumnStyle(i);
        if (this.isShowAdproviderColum(i, showtype)) {
          if (css) {
            if (css.width && css.width > 0) {
              width += css.width;
            }
          } else {
            width += 104;
          }
        }
      }

      if (type == 'summary') {
        if (document.getElementById('catetag')) {
          width += document.getElementById('catetag').clientWidth;
        }
      }

      if (type != 'summary') {
        width += 50;
      }

      if (width > 0) {
        return {
          width: width + 'px',
        };
      }

      return {};
    },
    allcolspan() {
      let res = 3;

      if (this.isShowAdproviderColum(0, 'selecttable') == false) {
        res -= 1;
      }
      if (this.isShowAdproviderColum(1, 'selecttable') == false) {
        res -= 1;
      }
      return res;
    },
    getCategoryAll() {
      let ret = [];
      let x = this.page.getProviderTableCategory(this.selectedProvider.name, 'ALL', 'monthly');
      if (x.length > 0) {
        let r = x[x.length - 1];
        for (let i = 0; i < r.length; i++) {
          if (i != 0) {
            ret.push(r[i]);
          }
        }
      }
      return ret;
    },
    teststyle(type, index) {
      let css = this.page.columnStyleStocker
        .getColumnStyles(this.page.advertizerId, 'adprovider', type)
        ?.getColumnStyle(index);
      if (css) {
        return css.style;
      }
      return {};
    },
    onMouseUpEvent(/*event*/) {
      // console.log('onMouseUpEvent', event);
      if (this.dragstate > 0) {
        this.page.columnStyleStocker.setWidth(
          this.page.advertizerId,
          'adprovider',
          this.expType,
          this.expMarkerId,
          this.exptarget.clientWidth
        );
        if (this.expType == 'summary') {
          for (let i = 0; i < this.$refs.summary_header_ref?.length; i++) {
            this.page.columnStyleStocker.setWidth(
              this.page.advertizerId,
              'adprovider',
              'summary',
              Number(this.$refs.summary_header_ref[i].id.replace('summary_header_id_', '')),
              this.$refs.summary_header_ref[i].clientWidth
            );
          }
        }
      }

      this.exptargetTdClasses = [];
      this.dragstate = 0;
      this.expMarkerId = -1;
    },
    onMouseMoveEvent(event) {
      if (this.dragstate > 0) {
        // console.log('onMouseMoveEvent', event);
        this.dragstate = 2;
        this.exptarget.style.width = this.baseWidth + event.clientX - this.baseClientX + 'px';
        for (let i = 0; i < this.exptargetTdClasses.length; i++) {
          this.exptargetTdClasses[i].style.width = this.exptarget.style.width;
        }
      }
    },
    dragstart(type, index, e) {
      this.baseClientX = e.clientX;
      this.dragstate = 1;
      if (type == 'summary') {
        // this.exptarget = this.$refs.summary_header_ref[index];
        this.exptarget = document.getElementById('summary_header_id_' + index);
        this.expTemp = document.getElementById('summary_table_tag').clientWidth + 1000;
        if (index == 0) {
          this.exptargetTdClasses = document.getElementsByClassName('summary_table_td_class_0');
        }
        if (index == 1) {
          this.exptargetTdClasses = document.getElementsByClassName('summary_table_td_class_1');
        }
        if (index == 2) {
          this.exptargetTdClasses = document.getElementsByClassName('summary_table_td_class_2');
        }
        if (index == 3) {
          this.exptargetTdClasses = document.getElementsByClassName('summary_table_td_class_3');
        }

        for (let i = 0; i < this.$refs.summary_header_ref?.length; i++) {
          this.page.columnStyleStocker.setWidth(
            this.page.advertizerId,
            'adprovider',
            'summary',
            Number(this.$refs.summary_header_ref[i].id.replace('summary_header_id_', '')),
            this.$refs.summary_header_ref[i].clientWidth
          );
        }
      } else if (type == 'alltab') {
        // this.exptarget = this.$refs.alltab_header_ref[index];
        this.exptarget = document.getElementById('alltab_header_id_' + index);
        this.expTemp = document.getElementById('alltab_table_tag').clientWidth + 1000;
      } else if (type == 'allmwd') {
        // this.exptarget = this.$refs.allmwd_header_ref[index];
        this.exptarget = document.getElementById('allmwd_header_id_' + index);
        this.expTemp = document.getElementById('allmwd_table_tag').clientWidth + 1000;
      } else if (type == 'mwd') {
        // this.exptarget = this.$refs.mwd_header_ref[index];
        this.exptarget = document.getElementById('mwd_header_id_' + index);
        this.expTemp = document.getElementById('mwd_table_tag').clientWidth + 1000;
      } else if (type == 'mwd1') {
        // this.exptarget = this.$refs.mwd1_header_ref[index];
        this.exptarget = document.getElementById('mwd1_header_id_' + index);
        this.expTemp = document.getElementById('mwd1_table_tag').clientWidth + 1000;
      } else if (type == 'mwd2') {
        // this.exptarget = this.$refs.mwd2_header_ref[index];
        this.exptarget = document.getElementById('mwd2_header_id_' + index);
        this.expTemp = document.getElementById('mwd2_table_tag').clientWidth + 1000;
      }

      this.baseWidth = this.exptarget.clientWidth;
      this.expMarkerId = index;
      this.expType = type;
    },

    showShowHide(e, index, type) {
      this.csh.setTop(e.pageY);
      this.csh.setLeft(e.pageX);
      let is = [];

      if (type == 'selecttable') {
        let hs = this.page.getProviderCampaignAdgroupTableHeaderV2();

        for (let i = 0; i < hs.length; i++) {
          is.push(new ColumnInfo(i, hs[i].data));
        }
      } else if (type == 'selectalltable') {
        this.csh.exclusions = [];
        for (let i in this.page.getProviderAllCategories()) {
          this.csh.exclusions.push(this.page.getProviderAllCategories()[i]);
        }
        let hs = this.page.getProviderAllTabTableHeader();

        for (let i = 0; i < hs.length; i++) {
          is.push(new ColumnInfo(i, hs[i].data));
        }
      } else if (type == 'selectedtable') {
        let hs = this.page.getProviderTableHeader();

        for (let i = 0; i < hs.length; i++) {
          is.push(new ColumnInfo(i, hs[i].data));
        }
      }

      this.csh.setColumnInfos(is);
      this.csh.setStocker(
        this.page.invisibleColumnStocker.getComponentInvisibleColumn(
          this.page.advertizerId,
          'adprovider',
          type
        ),
        index
      );
    },
    isShowAdproviderColum(index, type = 'selectedtable') {
      let ic = this.page.invisibleColumnStocker.getComponentInvisibleColumn(
        this.page.advertizerId,
        'adprovider',
        type
      );
      if (ic) {
        for (let i in ic.invisiblenumbers) {
          let number = ic.invisiblenumbers[i];
          if (index == number) {
            return false;
          }
        }
      }

      return true;
    },
    onClickEvent(e) {
      this.csh.clear();
      if (e?.srcElement?.attributes?.selectoption == undefined) {
        this.isShowMonthLimit = false;
      }
    },
    onMonthHeaderClick(number, event) {
      if (number == 0) {
        this.isShowMonthLimit = true;
      } else {
        this.showShowHide(event, number, 'selectedtable');
      }
    },
    onMonthLimitClick(number) {
      this.isShowMonthLimit = false;
      this.page.setLimitMode(number);
      this.lastLimitNumber = number;
    },
    selectAdgroup(campname, adgroupname, category) {
      if (adgroupname == 'ALL') {
        this.selectCampaign(campname, category);
        return;
      }
      this.selectedCategory = '';
      this.selectedCampaign = '';
      this.selectedDimension1 = '';
      this.selectedDimension2 = '';
      if (this.selectedAdgroupCampaign != campname) {
        this.page.loadConsultantAdProviderAdGroupReport(
          this.selectedProvider.id,
          this.selectedProvider.name,
          campname
        );
      }
      this.selectedAdgroupCategory = category;
      this.selectedAdgroupCampaign = campname;
      this.selectedAdgroup = adgroupname;
    },
    selectAdgroup2(campname, adgroupname, category = "", categoryid = "", category2 = "", categoryid2 = "") {
      if (adgroupname == 'ALL') {
        this.selectCampaign(campname, category, categoryid);
        return;
      }

      this.selectedCategory = '';
      this.selectedCampaign = '';
      this.selectedDimension1 = '';
      this.selectedDimension2 = '';
      if (this.selectedAdgroupCampaign != campname) {
        this.page.loadConsultantAdProviderAdGroupReport(
          this.selectedProvider.id,
          this.selectedProvider.name,
          campname
        );
      }
      this.selectedAdgroupCategory = category;
      this.selectedAdgroupCategoryId = categoryid;
      this.selectedAdgroupCategory2 = category2;
      this.selectedAdgroupCategoryId2 = categoryid2;
      this.selectedAdgroupCampaign = campname;
      this.selectedAdgroup = adgroupname;
    },
    selectCampaign(campname, category = '', categoryid = '', category2 = '', category2id = '') {
      this.selectedCategory = '';
      this.selectedCampaign = campname;
      this.selectedCampaignCategory = category;
      this.selectedCampaignCategoryId = categoryid;
      this.selectedCampaignCategory2 = category2;
      this.selectedCampaignCategory2Id = category2id;
      this.selectedAdgroup = '';
      this.selectedAdgroupCategory = '';
      this.selectedAdgroupCampaign = '';
      this.selectedDimension1 = '';
      this.selectedDimension2 = '';
    },
    getCategoryNameByCampaign() {
      if (this.selectedCampaign) {
        let startTag = '<';
        let endTag = '>';
        let advid = this.adx.getScreenStructureById('report').advertizerId;
        if (advid == 4) {
          startTag = '(';
          endTag = ')';
        }

        let sas = this.selectedCampaign.split(startTag);
        if (sas?.length > 1) {
          let sbs = sas[1].split(endTag);
          if (sbs?.length > 0) {
            return sbs[0];
          }
        }
      }
      return '';
    },
    selectCategory(category) {
      this.selectedCategory = category;
      this.selectedCampaign = '';
      this.selectedAdgroup = '';
      this.selectedAdgroupCategory = '';
      this.selectedAdgroupCampaign = '';
      this.selectedDimension1 = '';
      this.selectedDimension2 = '';
    },
    selectCategory2(category1_name, category1_id, category2_name = '', category2_id = null) {
      if (category1_name == "ALL" && (category1_id == null || category1_id == undefined || category1_id == "")) {
        this.selectCategory('ALL');
        return;
      }
      this.selectedCategory = '';
      this.selectedCampaign = '';
      this.selectedAdgroup = '';
      this.selectedAdgroupCategory = '';
      this.selectedAdgroupCampaign = '';
      this.selectedDimension1 = category1_name;
      this.selectedDimension1Id = category1_id;
      this.selectedDimension2 = category2_name;
      this.selectedDimension2Id = category2_id;

      let cs = [category1_id];
      if (category2_id != null) {
        cs.push(category2_id);
      }

      this.page.loadConsultantAdProviderCategoriesDimension(
        this.selectedProvider.id,
        this.selectedProvider.name,
        cs
      );
    },
    selectCategory3(category, dimensionList) {
      this.selectedCategory = '';
      this.selectedCampaign = '';
      this.selectedAdgroup = '';
      this.selectedAdgroupCategory = '';
      this.selectedAdgroupCampaign = '';
      this.selectedDimension1 = category;
      this.selectedDimension2 = '';
      this.selectedDimensionListAlltab = dimensionList;

      if (category == 'ALL') {
        this.selectCategory(category);
        return;
      }

      let cs = [dimensionList[0].id];
      if (dimensionList.length > 1) {
        if (dimensionList[1].value != "ALL") {
          cs.push(dimensionList[1].id);
          this.selectedDimension2 = dimensionList[1].value;
        }
      }
      this.page.loadConsultantAdProviderCategoriesDimension(null, this.selectedProvider.name, cs);
    },
    getCategoryNameByCampaignName(campname) {
      if (campname) {
        let startTag = '<';
        let endTag = '>';
        let advid = this.adx.getScreenStructureById('report').advertizerId;
        if (advid == 4) {
          startTag = '(';
          endTag = ')';
        }
        let sas = campname.split(startTag);
        if (sas?.length > 1) {
          let sbs = sas[1].split(endTag);
          if (sbs?.length > 0) {
            return sbs[0];
          }
        }
      }

      return '';
    },
    spanTilte2HeaderTitle(title) {
      return spanTilte2HeaderTitle(title);
    },
    isCells2SpanCells(cells, type = null) {
      let cs = cells2SpanCells(cells, type);
      for (let i in cs) {
        let c = cs[i];
        if (c.isSpan()) {
          return true;
        }
      }
      return false;
    },
    cells2SpanCells(cells, type = null) {
      let numbers = [];
      if (type != null) {
        let ic = this.page.invisibleColumnStocker.getComponentInvisibleColumn(
          this.page.advertizerId,
          'adprovider',
          type
        );
        if (ic) {
          numbers = ic.invisiblenumbers;
        }
      }
      return cells2SpanCells(cells, numbers);
    },
    titles2Cell(titles) {
      return titles2Cell(titles);
    },
    tabselect(id) {
      this.page.selectTabByAdProviderId(id);
    },
    initialize(id, timeRange) {
      const self = this;
      if (this.isLoad) {
        return;
      }
      if (!this.isActive) {
        return;
      }
      this.isLoad = true;
      this.page.cancelAllAPICall();
      this.page.initialize(
        id,
        this.adx.getScreenStructureById('report').advertizerId,
        timeRange,
        this.adx.user,
        this.adx.getScreenStructureById('report').colorMaster
      );
      if (this.selectedProvider.id != null) {
        // this.page.refreshCampaignAdgroupTable(this.selectedProvider.id);
        this.page.refreshCategoryCampaignAdgroupTable(this.selectedProvider.id);
      }
      let p2 = this.adx.getScreenStructureById('report').getPageByKey('媒体別実績');
      this.page.providerTablesInitialize(
        p2.id,
        this.adx.getScreenStructureById('consultant').advertizerId,
        this.dateRangeSelector,
        this.adx.user
      );
      this.page.updateload(function (date) {
        if (self.isActive) {
          self.adx.changeLastUpdate(date);
        }
      });
      this.page.updatecstrp(function(isExist){
        if (self.isActive) {
          if (isExist) {
            self.adx.changeCstCustomReport(true);
          }
        }
      });

    },
    getTodayYmd() {
      let time = this.adx.getNowTime();
      return new TimeYmd(time.ymd.year, time.ymd.month, time.ymd.day);
    },
    changeMode(mode) {
      this.page.selectedMode = mode;
      this.lastMwd = mode;
    },
    getModeName() {
      let name = '';
      switch (this.page.selectedMode) {
        case 1:
          name = '月別';
          break;
        case 2:
          name = '週別';
          break;
        case 3:
          name = '日別';
          break;
      }
      return name;
    },
    isRangeMode() {
      if (this.dateRangeSelector) {
        return this.dateRangeSelector.mode == 1;
      }
      return false;
    },
    isProviderSelected(provider) {
      if (this.selectedProvider.id == null) this.selectedProvider = provider;
      return this.selectedProvider.id == provider.id;
    },
    selectProvider(provider) {
      this.selectedProvider = provider;
      this.selectedCategory = 'ALL';
      this.selectedCampaign = '';
      this.isAllTabSelected = false;

      this.selectedCampaign = '';
      this.selectedAdgroup = '';
      this.selectedAdgroupCategory = '';
      this.selectedAdgroupCampaign = '';
      this.selectedDimension1 = '';
      this.selectedDimension2 = '';
    },
    selectAllTab() {
      this.isAllTabSelected = true;
    },
    rangecalendarevent: function (event, arg) {
      if (event == 'apply') {
        this.dateRangeSelector.setRangeYmd(
          arg.startYmd.year,
          arg.startYmd.month,
          arg.startYmd.day,
          arg.endYmd.year,
          arg.endYmd.month,
          arg.endYmd.day
        );
      }
      this.isShowRange = false;
    },
    timerangeevent: function (event) {
      console.log(event);
      this.isShowRange = true;
    },
    getProviderCampaignAdgroupTableWidth() {
      let cols = this.page.getProviderCampaignAdgroupTableHeader().length - 2; // 基本カラム数 (種別カラムを除外)
      let ret = 60 * (cols / 13);
      return ['20%', String(ret + 40) + '%']; // キャンペーン、広告グループの幅とテーブル全体の幅を配列で返す。
    },
    copyToClipboard: (className) => {
      // display copy message
      let copyMessage = document.createElement('div');
      copyMessage.className = 'copy_message';
      copyMessage.style.position = 'absolute';
      copyMessage.style.zIndex = '999';
      copyMessage.style.top = `${window.event.clientY + document.documentElement.scrollTop + 5}px`;
      copyMessage.style.left = `${
        window.event.clientX + document.documentElement.scrollLeft + 10
      }px`;
      copyMessage.style.backgroundColor = 'rgb(200,200,200)';
      copyMessage.style.padding = '3px';
      copyMessage.style.borderRadius = '5px';
      copyMessage.innerHTML = 'Copying...';
      document.getElementsByTagName('body')[0].appendChild(copyMessage);

      // copy element
      let applyInline = (el_clone, el_origin) => {
        const cs = window.getComputedStyle(el_origin);

        for (let key in cs) {
          if (!isNaN(parseInt(key))) continue;
          if (['length', 'parentRule'].includes(key)) continue;
          //if(! ["background", "font"].includes(key)) return ;
          if (['font', 'backgroundColor', 'color'].includes(key)) el_clone.style[key] = cs[key];
        }
        el_clone.style['fontSize'] = '100%';
        Array.from(el_clone.children).forEach((n, i) => applyInline(n, el_origin.children[i]));
      };
      try {
        let origin = document.getElementsByClassName(className)[0];
        let tbl = origin.cloneNode(true);
        applyInline(tbl, origin);
        var type = 'text/html';
        var blob = new Blob([tbl.outerHTML], { type });
        var data = [new window.ClipboardItem({ [type]: blob })];
        navigator.clipboard.write(data).then(
          () => {
            copyMessage.innerHTML = 'Copy done!';
            setTimeout(() => {
              copyMessage.remove();
            }, 1000);
          },
          () => {
            copyMessage.innerHTML = 'Copy failed!';
            setTimeout(() => {
              copyMessage.remove();
            }, 1000);
          }
        );
      } catch (e) {
        copyMessage.innerHTML = 'Copy failed!';
        setTimeout(() => {
          copyMessage.remove();
        }, 1000);
      }
    },
  },
  watch: {
    selectedProvider: {
      handler: function (val) {
        // if (val.id != null) this.page.refreshCampaignAdgroupTable(val.id);
        if (val.id != null) this.page.refreshCategoryCampaignAdgroupTable(val.id);
      },
      deep: true,
    },
  },
  components: {
    SideMenu,
    ContentHeader,
    Spinner,
    BoxSpinner,
    ScreenBarProgress2,
    RangeCalendar,
    ColumnShowHide,
  },
  props: {
    adx: Object,
  },
  beforeUnmount() {
    this.page.cancelAllAPICall();
    this.isActive = false;

    this.adx.changeLastUpdate('');
    // this.adx.changeCstCustomReport(false);
  },
};
</script>

