// source: manager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.adx.manager.InsertSummaryMemoRequest', null, global);
goog.exportSymbol('proto.adx.manager.InsertSummaryMemoResponse', null, global);
goog.exportSymbol('proto.adx.manager.InsertSummaryMemoResponse.Result', null, global);
goog.exportSymbol('proto.adx.manager.InsertSummaryMemoResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.manager.SaveSummaryReportOrderRequest', null, global);
goog.exportSymbol('proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder', null, global);
goog.exportSymbol('proto.adx.manager.SaveSummaryReportOrderResponse', null, global);
goog.exportSymbol('proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder', null, global);
goog.exportSymbol('proto.adx.manager.SaveSummaryReportOrderResponse.Result', null, global);
goog.exportSymbol('proto.adx.manager.SaveSummaryReportOrderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.manager.SummaryReportRequest', null, global);
goog.exportSymbol('proto.adx.manager.SummaryReportResponse', null, global);
goog.exportSymbol('proto.adx.manager.SummaryReportResponse.Graph', null, global);
goog.exportSymbol('proto.adx.manager.SummaryReportResponse.GraphTable', null, global);
goog.exportSymbol('proto.adx.manager.SummaryReportResponse.Result', null, global);
goog.exportSymbol('proto.adx.manager.SummaryReportResponse.SummaryReport', null, global);
goog.exportSymbol('proto.adx.manager.SummaryReportResponse.Table', null, global);
goog.exportSymbol('proto.adx.manager.SummaryReportResponse.TableData', null, global);
goog.exportSymbol('proto.adx.manager.SummaryReportResponse.TypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SummaryReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.manager.SummaryReportRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.manager.SummaryReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SummaryReportRequest.displayName = 'proto.adx.manager.SummaryReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SummaryReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.manager.SummaryReportResponse.oneofGroups_);
};
goog.inherits(proto.adx.manager.SummaryReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SummaryReportResponse.displayName = 'proto.adx.manager.SummaryReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SummaryReportResponse.GraphTable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.manager.SummaryReportResponse.GraphTable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SummaryReportResponse.GraphTable.displayName = 'proto.adx.manager.SummaryReportResponse.GraphTable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SummaryReportResponse.Graph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.manager.SummaryReportResponse.Graph.repeatedFields_, null);
};
goog.inherits(proto.adx.manager.SummaryReportResponse.Graph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SummaryReportResponse.Graph.displayName = 'proto.adx.manager.SummaryReportResponse.Graph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SummaryReportResponse.TableData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.manager.SummaryReportResponse.TableData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SummaryReportResponse.TableData.displayName = 'proto.adx.manager.SummaryReportResponse.TableData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SummaryReportResponse.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.manager.SummaryReportResponse.Table.repeatedFields_, null);
};
goog.inherits(proto.adx.manager.SummaryReportResponse.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SummaryReportResponse.Table.displayName = 'proto.adx.manager.SummaryReportResponse.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SummaryReportResponse.SummaryReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.manager.SummaryReportResponse.SummaryReport.repeatedFields_, null);
};
goog.inherits(proto.adx.manager.SummaryReportResponse.SummaryReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SummaryReportResponse.SummaryReport.displayName = 'proto.adx.manager.SummaryReportResponse.SummaryReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SummaryReportResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.manager.SummaryReportResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SummaryReportResponse.Result.displayName = 'proto.adx.manager.SummaryReportResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.InsertSummaryMemoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.manager.InsertSummaryMemoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.InsertSummaryMemoRequest.displayName = 'proto.adx.manager.InsertSummaryMemoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.InsertSummaryMemoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.manager.InsertSummaryMemoResponse.oneofGroups_);
};
goog.inherits(proto.adx.manager.InsertSummaryMemoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.InsertSummaryMemoResponse.displayName = 'proto.adx.manager.InsertSummaryMemoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.InsertSummaryMemoResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.manager.InsertSummaryMemoResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.InsertSummaryMemoResponse.Result.displayName = 'proto.adx.manager.InsertSummaryMemoResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SaveSummaryReportOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.manager.SaveSummaryReportOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.manager.SaveSummaryReportOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SaveSummaryReportOrderRequest.displayName = 'proto.adx.manager.SaveSummaryReportOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.displayName = 'proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SaveSummaryReportOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.manager.SaveSummaryReportOrderResponse.oneofGroups_);
};
goog.inherits(proto.adx.manager.SaveSummaryReportOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SaveSummaryReportOrderResponse.displayName = 'proto.adx.manager.SaveSummaryReportOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.manager.SaveSummaryReportOrderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.manager.SaveSummaryReportOrderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SaveSummaryReportOrderResponse.Result.displayName = 'proto.adx.manager.SaveSummaryReportOrderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.displayName = 'proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.manager.SummaryReportRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SummaryReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SummaryReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SummaryReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizeridsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SummaryReportRequest}
 */
proto.adx.manager.SummaryReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SummaryReportRequest;
  return proto.adx.manager.SummaryReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SummaryReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SummaryReportRequest}
 */
proto.adx.manager.SummaryReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdvertizerids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SummaryReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SummaryReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SummaryReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizeridsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.manager.SummaryReportRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportRequest} returns this
 */
proto.adx.manager.SummaryReportRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string actualMonth = 2;
 * @return {string}
 */
proto.adx.manager.SummaryReportRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportRequest} returns this
 */
proto.adx.manager.SummaryReportRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 advertizerIds = 3;
 * @return {!Array<number>}
 */
proto.adx.manager.SummaryReportRequest.prototype.getAdvertizeridsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.adx.manager.SummaryReportRequest} returns this
 */
proto.adx.manager.SummaryReportRequest.prototype.setAdvertizeridsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.adx.manager.SummaryReportRequest} returns this
 */
proto.adx.manager.SummaryReportRequest.prototype.addAdvertizerids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.manager.SummaryReportRequest} returns this
 */
proto.adx.manager.SummaryReportRequest.prototype.clearAdvertizeridsList = function() {
  return this.setAdvertizeridsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.manager.SummaryReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.manager.SummaryReportResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.manager.SummaryReportResponse.TypeCase}
 */
proto.adx.manager.SummaryReportResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.manager.SummaryReportResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.manager.SummaryReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SummaryReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SummaryReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SummaryReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.manager.SummaryReportResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SummaryReportResponse}
 */
proto.adx.manager.SummaryReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SummaryReportResponse;
  return proto.adx.manager.SummaryReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SummaryReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SummaryReportResponse}
 */
proto.adx.manager.SummaryReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.manager.SummaryReportResponse.Result;
      reader.readMessage(value,proto.adx.manager.SummaryReportResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SummaryReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SummaryReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SummaryReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.manager.SummaryReportResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SummaryReportResponse.GraphTable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SummaryReportResponse.GraphTable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.GraphTable.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valuetype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    expected: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SummaryReportResponse.GraphTable}
 */
proto.adx.manager.SummaryReportResponse.GraphTable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SummaryReportResponse.GraphTable;
  return proto.adx.manager.SummaryReportResponse.GraphTable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SummaryReportResponse.GraphTable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SummaryReportResponse.GraphTable}
 */
proto.adx.manager.SummaryReportResponse.GraphTable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValuetype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SummaryReportResponse.GraphTable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SummaryReportResponse.GraphTable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.GraphTable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValuetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.GraphTable} returns this
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.GraphTable} returns this
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string valueType = 3;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.getValuetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.GraphTable} returns this
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.setValuetype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string expected = 4;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.getExpected = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.GraphTable} returns this
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.setExpected = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.manager.SummaryReportResponse.GraphTable} returns this
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.clearExpected = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.manager.SummaryReportResponse.GraphTable.prototype.hasExpected = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.manager.SummaryReportResponse.Graph.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SummaryReportResponse.Graph.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SummaryReportResponse.Graph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SummaryReportResponse.Graph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.Graph.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.manager.SummaryReportResponse.GraphTable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SummaryReportResponse.Graph}
 */
proto.adx.manager.SummaryReportResponse.Graph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SummaryReportResponse.Graph;
  return proto.adx.manager.SummaryReportResponse.Graph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SummaryReportResponse.Graph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SummaryReportResponse.Graph}
 */
proto.adx.manager.SummaryReportResponse.Graph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 2:
      var value = new proto.adx.manager.SummaryReportResponse.GraphTable;
      reader.readMessage(value,proto.adx.manager.SummaryReportResponse.GraphTable.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SummaryReportResponse.Graph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SummaryReportResponse.Graph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SummaryReportResponse.Graph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.Graph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.manager.SummaryReportResponse.GraphTable.serializeBinaryToWriter
    );
  }
};


/**
 * optional string month = 1;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.Graph.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.Graph} returns this
 */
proto.adx.manager.SummaryReportResponse.Graph.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated GraphTable table = 2;
 * @return {!Array<!proto.adx.manager.SummaryReportResponse.GraphTable>}
 */
proto.adx.manager.SummaryReportResponse.Graph.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.manager.SummaryReportResponse.GraphTable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.manager.SummaryReportResponse.GraphTable, 2));
};


/**
 * @param {!Array<!proto.adx.manager.SummaryReportResponse.GraphTable>} value
 * @return {!proto.adx.manager.SummaryReportResponse.Graph} returns this
*/
proto.adx.manager.SummaryReportResponse.Graph.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.manager.SummaryReportResponse.GraphTable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.manager.SummaryReportResponse.GraphTable}
 */
proto.adx.manager.SummaryReportResponse.Graph.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.manager.SummaryReportResponse.GraphTable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.manager.SummaryReportResponse.Graph} returns this
 */
proto.adx.manager.SummaryReportResponse.Graph.prototype.clearTableList = function() {
  return this.setTableList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SummaryReportResponse.TableData.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SummaryReportResponse.TableData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SummaryReportResponse.TableData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.TableData.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SummaryReportResponse.TableData}
 */
proto.adx.manager.SummaryReportResponse.TableData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SummaryReportResponse.TableData;
  return proto.adx.manager.SummaryReportResponse.TableData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SummaryReportResponse.TableData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SummaryReportResponse.TableData}
 */
proto.adx.manager.SummaryReportResponse.TableData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SummaryReportResponse.TableData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SummaryReportResponse.TableData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SummaryReportResponse.TableData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.TableData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.TableData.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.TableData} returns this
 */
proto.adx.manager.SummaryReportResponse.TableData.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.TableData.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.TableData} returns this
 */
proto.adx.manager.SummaryReportResponse.TableData.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.manager.SummaryReportResponse.Table.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SummaryReportResponse.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SummaryReportResponse.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ratevalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expectedvalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    simulatedvalue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.adx.manager.SummaryReportResponse.TableData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SummaryReportResponse.Table}
 */
proto.adx.manager.SummaryReportResponse.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SummaryReportResponse.Table;
  return proto.adx.manager.SummaryReportResponse.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SummaryReportResponse.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SummaryReportResponse.Table}
 */
proto.adx.manager.SummaryReportResponse.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRatevalue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpectedvalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSimulatedvalue(value);
      break;
    case 5:
      var value = new proto.adx.manager.SummaryReportResponse.TableData;
      reader.readMessage(value,proto.adx.manager.SummaryReportResponse.TableData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SummaryReportResponse.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SummaryReportResponse.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRatevalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpectedvalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSimulatedvalue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.manager.SummaryReportResponse.TableData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.Table} returns this
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rateValue = 2;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.getRatevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.Table} returns this
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.setRatevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string expectedValue = 3;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.getExpectedvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.Table} returns this
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.setExpectedvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string simulatedValue = 4;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.getSimulatedvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.Table} returns this
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.setSimulatedvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated TableData data = 5;
 * @return {!Array<!proto.adx.manager.SummaryReportResponse.TableData>}
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.getDataList = function() {
  return /** @type{!Array<!proto.adx.manager.SummaryReportResponse.TableData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.manager.SummaryReportResponse.TableData, 5));
};


/**
 * @param {!Array<!proto.adx.manager.SummaryReportResponse.TableData>} value
 * @return {!proto.adx.manager.SummaryReportResponse.Table} returns this
*/
proto.adx.manager.SummaryReportResponse.Table.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.manager.SummaryReportResponse.TableData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.manager.SummaryReportResponse.TableData}
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.manager.SummaryReportResponse.TableData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.manager.SummaryReportResponse.Table} returns this
 */
proto.adx.manager.SummaryReportResponse.Table.prototype.clearDataList = function() {
  return this.setDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SummaryReportResponse.SummaryReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SummaryReportResponse.SummaryReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertizerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    client: jspb.Message.getFieldWithDefault(msg, 2, ""),
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.manager.SummaryReportResponse.Graph.toObject, includeInstance),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.manager.SummaryReportResponse.Table.toObject, includeInstance),
    memo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    memoupdated: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SummaryReportResponse.SummaryReport;
  return proto.adx.manager.SummaryReportResponse.SummaryReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SummaryReportResponse.SummaryReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClient(value);
      break;
    case 3:
      var value = new proto.adx.manager.SummaryReportResponse.Graph;
      reader.readMessage(value,proto.adx.manager.SummaryReportResponse.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 4:
      var value = new proto.adx.manager.SummaryReportResponse.Table;
      reader.readMessage(value,proto.adx.manager.SummaryReportResponse.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemoupdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SummaryReportResponse.SummaryReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SummaryReportResponse.SummaryReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getClient();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.manager.SummaryReportResponse.Graph.serializeBinaryToWriter
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.manager.SummaryReportResponse.Table.serializeBinaryToWriter
    );
  }
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMemoupdated();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 advertizerId = 1;
 * @return {number}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport} returns this
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string client = 2;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.getClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport} returns this
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.setClient = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Graph graph = 3;
 * @return {!Array<!proto.adx.manager.SummaryReportResponse.Graph>}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.manager.SummaryReportResponse.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.manager.SummaryReportResponse.Graph, 3));
};


/**
 * @param {!Array<!proto.adx.manager.SummaryReportResponse.Graph>} value
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport} returns this
*/
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.manager.SummaryReportResponse.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.manager.SummaryReportResponse.Graph}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.manager.SummaryReportResponse.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport} returns this
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated Table table = 4;
 * @return {!Array<!proto.adx.manager.SummaryReportResponse.Table>}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.manager.SummaryReportResponse.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.manager.SummaryReportResponse.Table, 4));
};


/**
 * @param {!Array<!proto.adx.manager.SummaryReportResponse.Table>} value
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport} returns this
*/
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.manager.SummaryReportResponse.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.manager.SummaryReportResponse.Table}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.manager.SummaryReportResponse.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport} returns this
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string memo = 5;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport} returns this
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.setMemo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string memoUpdated = 6;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.getMemoupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.SummaryReport} returns this
 */
proto.adx.manager.SummaryReportResponse.SummaryReport.prototype.setMemoupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SummaryReportResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SummaryReportResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SummaryReportResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    summaryreport: (f = msg.getSummaryreport()) && proto.adx.manager.SummaryReportResponse.SummaryReport.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SummaryReportResponse.Result}
 */
proto.adx.manager.SummaryReportResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SummaryReportResponse.Result;
  return proto.adx.manager.SummaryReportResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SummaryReportResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SummaryReportResponse.Result}
 */
proto.adx.manager.SummaryReportResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.manager.SummaryReportResponse.SummaryReport;
      reader.readMessage(value,proto.adx.manager.SummaryReportResponse.SummaryReport.deserializeBinaryFromReader);
      msg.setSummaryreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SummaryReportResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SummaryReportResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SummaryReportResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SummaryReportResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSummaryreport();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.adx.manager.SummaryReportResponse.SummaryReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.Result} returns this
 */
proto.adx.manager.SummaryReportResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse.Result} returns this
 */
proto.adx.manager.SummaryReportResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SummaryReport summaryReport = 3;
 * @return {?proto.adx.manager.SummaryReportResponse.SummaryReport}
 */
proto.adx.manager.SummaryReportResponse.Result.prototype.getSummaryreport = function() {
  return /** @type{?proto.adx.manager.SummaryReportResponse.SummaryReport} */ (
    jspb.Message.getWrapperField(this, proto.adx.manager.SummaryReportResponse.SummaryReport, 3));
};


/**
 * @param {?proto.adx.manager.SummaryReportResponse.SummaryReport|undefined} value
 * @return {!proto.adx.manager.SummaryReportResponse.Result} returns this
*/
proto.adx.manager.SummaryReportResponse.Result.prototype.setSummaryreport = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.manager.SummaryReportResponse.Result} returns this
 */
proto.adx.manager.SummaryReportResponse.Result.prototype.clearSummaryreport = function() {
  return this.setSummaryreport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.manager.SummaryReportResponse.Result.prototype.hasSummaryreport = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.manager.SummaryReportResponse.Result}
 */
proto.adx.manager.SummaryReportResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.manager.SummaryReportResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.manager.SummaryReportResponse.Result, 1));
};


/**
 * @param {?proto.adx.manager.SummaryReportResponse.Result|undefined} value
 * @return {!proto.adx.manager.SummaryReportResponse} returns this
*/
proto.adx.manager.SummaryReportResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.manager.SummaryReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.manager.SummaryReportResponse} returns this
 */
proto.adx.manager.SummaryReportResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.manager.SummaryReportResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.manager.SummaryReportResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SummaryReportResponse} returns this
 */
proto.adx.manager.SummaryReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.manager.SummaryReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.manager.SummaryReportResponse} returns this
 */
proto.adx.manager.SummaryReportResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.manager.SummaryReportResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.manager.SummaryReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.InsertSummaryMemoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.InsertSummaryMemoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.InsertSummaryMemoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    month: jspb.Message.getFieldWithDefault(msg, 3, ""),
    memo: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.InsertSummaryMemoRequest}
 */
proto.adx.manager.InsertSummaryMemoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.InsertSummaryMemoRequest;
  return proto.adx.manager.InsertSummaryMemoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.InsertSummaryMemoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.InsertSummaryMemoRequest}
 */
proto.adx.manager.InsertSummaryMemoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.InsertSummaryMemoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.InsertSummaryMemoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.InsertSummaryMemoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.InsertSummaryMemoRequest} returns this
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 advertizerId = 2;
 * @return {number}
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.manager.InsertSummaryMemoRequest} returns this
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string month = 3;
 * @return {string}
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.InsertSummaryMemoRequest} returns this
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string memo = 4;
 * @return {string}
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.InsertSummaryMemoRequest} returns this
 */
proto.adx.manager.InsertSummaryMemoRequest.prototype.setMemo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.manager.InsertSummaryMemoResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.manager.InsertSummaryMemoResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.manager.InsertSummaryMemoResponse.TypeCase}
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.manager.InsertSummaryMemoResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.manager.InsertSummaryMemoResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.InsertSummaryMemoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.InsertSummaryMemoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.InsertSummaryMemoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.manager.InsertSummaryMemoResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.InsertSummaryMemoResponse}
 */
proto.adx.manager.InsertSummaryMemoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.InsertSummaryMemoResponse;
  return proto.adx.manager.InsertSummaryMemoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.InsertSummaryMemoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.InsertSummaryMemoResponse}
 */
proto.adx.manager.InsertSummaryMemoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.manager.InsertSummaryMemoResponse.Result;
      reader.readMessage(value,proto.adx.manager.InsertSummaryMemoResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.InsertSummaryMemoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.InsertSummaryMemoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.InsertSummaryMemoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.manager.InsertSummaryMemoResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.InsertSummaryMemoResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.InsertSummaryMemoResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requestid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    month: jspb.Message.getFieldWithDefault(msg, 4, ""),
    memo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    memoupdated: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.InsertSummaryMemoResponse.Result}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.InsertSummaryMemoResponse.Result;
  return proto.adx.manager.InsertSummaryMemoResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.InsertSummaryMemoResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.InsertSummaryMemoResponse.Result}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemoupdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.InsertSummaryMemoResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.InsertSummaryMemoResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMemoupdated();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.manager.InsertSummaryMemoResponse.Result} returns this
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string requestId = 2;
 * @return {string}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.InsertSummaryMemoResponse.Result} returns this
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.manager.InsertSummaryMemoResponse.Result} returns this
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string month = 4;
 * @return {string}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.InsertSummaryMemoResponse.Result} returns this
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string memo = 5;
 * @return {string}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.InsertSummaryMemoResponse.Result} returns this
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.setMemo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string memoUpdated = 6;
 * @return {string}
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.getMemoupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.InsertSummaryMemoResponse.Result} returns this
 */
proto.adx.manager.InsertSummaryMemoResponse.Result.prototype.setMemoupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.manager.InsertSummaryMemoResponse.Result}
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.manager.InsertSummaryMemoResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.manager.InsertSummaryMemoResponse.Result, 1));
};


/**
 * @param {?proto.adx.manager.InsertSummaryMemoResponse.Result|undefined} value
 * @return {!proto.adx.manager.InsertSummaryMemoResponse} returns this
*/
proto.adx.manager.InsertSummaryMemoResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.manager.InsertSummaryMemoResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.manager.InsertSummaryMemoResponse} returns this
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.InsertSummaryMemoResponse} returns this
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.manager.InsertSummaryMemoResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.manager.InsertSummaryMemoResponse} returns this
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.manager.InsertSummaryMemoResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.manager.InsertSummaryMemoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.manager.SaveSummaryReportOrderRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SaveSummaryReportOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SaveSummaryReportOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportorderList: jspb.Message.toObjectList(msg.getReportorderList(),
    proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SaveSummaryReportOrderRequest;
  return proto.adx.manager.SaveSummaryReportOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SaveSummaryReportOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder;
      reader.readMessage(value,proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.deserializeBinaryFromReader);
      msg.addReportorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SaveSummaryReportOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SaveSummaryReportOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportorderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertizerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder;
  return proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 advertizerId = 1;
 * @return {number}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder} returns this
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder} returns this
 */
proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest} returns this
 */
proto.adx.manager.SaveSummaryReportOrderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ReportOrder reportOrder = 2;
 * @return {!Array<!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder>}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.prototype.getReportorderList = function() {
  return /** @type{!Array<!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder, 2));
};


/**
 * @param {!Array<!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder>} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest} returns this
*/
proto.adx.manager.SaveSummaryReportOrderRequest.prototype.setReportorderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder}
 */
proto.adx.manager.SaveSummaryReportOrderRequest.prototype.addReportorder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.manager.SaveSummaryReportOrderRequest.ReportOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.manager.SaveSummaryReportOrderRequest} returns this
 */
proto.adx.manager.SaveSummaryReportOrderRequest.prototype.clearReportorderList = function() {
  return this.setReportorderList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.manager.SaveSummaryReportOrderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.manager.SaveSummaryReportOrderResponse.TypeCase}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.manager.SaveSummaryReportOrderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.manager.SaveSummaryReportOrderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SaveSummaryReportOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.manager.SaveSummaryReportOrderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SaveSummaryReportOrderResponse;
  return proto.adx.manager.SaveSummaryReportOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.manager.SaveSummaryReportOrderResponse.Result;
      reader.readMessage(value,proto.adx.manager.SaveSummaryReportOrderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SaveSummaryReportOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.manager.SaveSummaryReportOrderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SaveSummaryReportOrderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportorderList: jspb.Message.toObjectList(msg.getReportorderList(),
    proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.Result}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SaveSummaryReportOrderResponse.Result;
  return proto.adx.manager.SaveSummaryReportOrderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.Result}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = new proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder;
      reader.readMessage(value,proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.deserializeBinaryFromReader);
      msg.addReportorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SaveSummaryReportOrderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportorderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.Result} returns this
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ReportOrder reportOrder = 2;
 * @return {!Array<!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder>}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.prototype.getReportorderList = function() {
  return /** @type{!Array<!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder, 2));
};


/**
 * @param {!Array<!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder>} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.Result} returns this
*/
proto.adx.manager.SaveSummaryReportOrderResponse.Result.prototype.setReportorderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.prototype.addReportorder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.Result} returns this
 */
proto.adx.manager.SaveSummaryReportOrderResponse.Result.prototype.clearReportorderList = function() {
  return this.setReportorderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    advertizerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder;
  return proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 advertizerId = 1;
 * @return {number}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder} returns this
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder} returns this
 */
proto.adx.manager.SaveSummaryReportOrderResponse.ReportOrder.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.manager.SaveSummaryReportOrderResponse.Result}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.manager.SaveSummaryReportOrderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.manager.SaveSummaryReportOrderResponse.Result, 1));
};


/**
 * @param {?proto.adx.manager.SaveSummaryReportOrderResponse.Result|undefined} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse} returns this
*/
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.manager.SaveSummaryReportOrderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse} returns this
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse} returns this
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.manager.SaveSummaryReportOrderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.manager.SaveSummaryReportOrderResponse} returns this
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.manager.SaveSummaryReportOrderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.manager.SaveSummaryReportOrderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.manager);
