<template>
  <div class="overlay_layer" v-if="isShowDateSelect">
    <div class="summary_rangecalendar_background" v-on:click="dateselectbackground"></div>
    <div class="date_select_layer">
      <div class="date_range">
        <div></div>
        <div class="simple_rangecalendar">
          <div class="summary_rangecalendar__wrapper">
            <div v-if="dateRangeSelector">
              <RangeCalendarSimple
                year="2021"
                month="7"
                :range="dateRangeSelector.timeYmdRange"
                :todayYmd="getTodayYmd()"
                @rangecalendarevent="rangecalendarevent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main_content_wrapper">
    <div class="adx_sim">
      <div class="main_content">
        <div style="background: white; width: calc(100% - 20px)">
          <div class="left_main_content__header">
            <ContentHeader
              title="予算決定"
              :timeRange="dateRangeSelector"
              :adx="adx"
              @simcalceevent="simcalceevent"
            />
          </div>
        </div>
        <div class="left_main_content__body">
          <div class="box">
            <div class="frame">
              <div class="box">
                <div style="width: 100%">
                  <div class="frame__header" v-if="false">シミュレーション</div>
                  <div class="frame__body">
                    <Spinner v-if="page.isNetworking" />
                    <div style="padding: 16px; background: rgba(58, 67, 78); color: #fff">
                      <div
                        class="clickable"
                        style="text-decoration: underline; position: relative; padding-left: 32px"
                        v-on:click="download"
                      >
                        <img
                          style="position: absolute; top: 0; left: 0px"
                          width="24"
                          src="@/assets/icon/description_file_wght400_grad0_opsz48.svg"
                        />
                        シミュレーションフォーマットファイル名（押すとダウンロード）
                      </div>
                      <div class="fileformatarea" style="margin-top: 16px; position: relative">
                        <div
                          class="fileformatarea__ddarea"
                          id="ddformat"
                          @dragover.prevent="templatedrag = true"
                          @dragleave.prevent="templatedrag = false"
                          @drop.prevent="onDropFileFormat"
                          v-bind:class="[templatedrag ? 'filedataarea__ddarea--active' : '']"
                        >
                          <div style="color: #d4d5d57f" v-if="templatefileuploadname.length == 0">
                            ここにファイルをドラッグ＆ドロップしてください。
                          </div>
                          <div v-if="templatefileuploadname.length > 0">
                            <div>
                              <div class="filewrapper">
                                <div class="filewrapper__file">
                                  <div>
                                    <img
                                      width="0"
                                      height="0"
                                      src="@/assets/thirdparty/icon/icooon_excel.png"
                                      style="
                                        margin-left: 0px;
                                        margin-right: 8px;
                                        margin-top: 5px;
                                        width: 14px;
                                        height: 14px;
                                      "
                                    />
                                  </div>
                                  <div>{{ templatefileuploadname }}</div>
                                </div>
                                <div
                                  class="filewrapper__close"
                                  v-on:click="onClickFormatfileCancel"
                                >
                                  <img
                                    width="0"
                                    height="0"
                                    class="filewrapper__close_img"
                                    src="@/assets/thirdparty/icon/icooon_close.png"
                                    style="width: 20px; height: 20px; border-radius: 50%"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="fileformatarea__control">
                          <div class="fileformatarea__flex">
                            <div>
                              <label class="fileformatarea__selectbtn" for="filetemplate">
                                ファイル選択
                                <input type="file" id="filetemplate" />
                              </label>
                            </div>
                            <div
                              class="fileformatarea__uploadbtn_wrapper"
                              v-if="templatefileuploadname.length > 0"
                            >
                              <button v-on:click="formatup" class="fileformatarea__uploadbtn">
                                フォーマット登録
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="fileformatarea__ddarea_overlay" v-if="page.fileformatprogress">
                          <div>
                            <div style="width: 400px; font-size: 12px; text-align: center">
                              フォーマット変更{{
                                page.fileformatprogressPercent == 100 ? '完了' : '中'
                              }}
                            </div>
                            <div style="width: 400px">
                              <BarProgress2
                                :value="page.fileformatprogressPercent"
                                :stroke="1.0"
                              ></BarProgress2>
                              <div
                                style="text-align: center"
                                v-if="page.fileformatprogressPercent != 100"
                              >
                                {{ page.fileformatprogressPercent }}%
                              </div>
                            </div>
                          </div>
                          <div style="display: flex" v-show="page.fileformatprogressPercent == 100">
                            <div>
                              <button
                                v-on:click="onClickFormatDone"
                                class="fileformatarea__uploadbtn"
                                style="background: #f3f3f3; color: #111"
                              >
                                完了
                              </button>
                            </div>
                            <div>
                              <button
                                v-on:click="onClickFormatRetry"
                                class="fileformatarea__uploadbtn"
                              >
                                もう一度変更する
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="margin-top: 32px; position: relative; padding-left: 32px">
                        <img
                          style="position: absolute; top: 0; left: 0px"
                          width="24"
                          src="@/assets/icon/description_file_wght400_grad0_opsz48.svg"
                        />シミュレーションアップロード
                      </div>
                      <div style="margin-top: 16px">
                        <div
                          v-on:click="startTimeRange"
                          class="dateselectarea"
                          v-if="page.timeRange?.timeYmdRange"
                          style="position: relative; color: #39434e99"
                        >
                          <img
                            width="0"
                            height="0"
                            src="@/assets/icon/date_range-24px.svg"
                            style="
                              margin-left: 0px;
                              margin-right: 8px;
                              margin-top: 5px;
                              width: 14px;
                              height: 14px;
                              float: left;
                            "
                          />
                          {{ date2Str() }}
                          <div
                            v-if="isTimeRangeShow"
                            style="
                              position: absolute;
                              top: 32px;
                              left: 32px;
                              background: #ffffff 0% 0% no-repeat padding-box;
                              box-shadow: 0px 3px 6px #00000029;
                              border: 1px solid #ccc;
                              border-radius: 4px;
                              padding: 20px 8px;
                              text-align: left;
                              color: #3f4a56;
                              z-index: 999;
                            "
                          >
                            <div style="display: flex">
                              <div>
                                <img
                                  class="daterangearrow"
                                  v-on:click="decMonth"
                                  src="@/assets/icon/keyboard_arrow_left-24px.svg"
                                />
                              </div>
                              <div>
                                <div
                                  style="
                                    display: flex;
                                    border: 1px solid #3b86ff;
                                    padding: 1px;
                                    border-radius: 5px;
                                    width: 240px;
                                  "
                                >
                                  <img
                                    src="@/assets/icon/date_range-24px.svg"
                                    style="
                                      margin-left: 8px;
                                      margin-right: 8px;
                                      margin-top: 4px;
                                      width: 18px;
                                      height: 18px;
                                    "
                                  />
                                  <div
                                    style="Noto Sans JP; letter-spacing: 0px; color: rgb(77, 79, 92);"
                                  >
                                    {{ date2StrDay1() }}
                                  </div>
                                </div>
                                <div style="text-align: center; margin-top: 20px; font-size: 12px">
                                  {{ date2StrEnglishMonth() }}
                                </div>
                                <div style="text-align: center; margin-top: 16px; font-size: 24px">
                                  {{ date2StrMonth() }}
                                </div>
                              </div>
                              <div>
                                <img
                                  class="daterangearrow"
                                  v-on:click="incMonth"
                                  src="@/assets/icon/keyboard_arrow_right-24px.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="filedataarea" style="margin-top: 16px">
                        <div
                          class="filedataarea__ddarea"
                          id="dddata"
                          @dragover.prevent="datadrag = true"
                          @dragleave.prevent="datadrag = false"
                          @drop.prevent="onDropFileData"
                          v-bind:class="[datadrag ? 'filedataarea__ddarea--active' : '']"
                        >
                          <div style="color: #d4d5d57f" v-if="datafileuploadname.length == 0">
                            ここにファイルをドラッグ＆ドロップしてください。
                          </div>
                          <div v-if="datafileuploadname.length > 0">
                            <div>
                              <div class="filewrapper">
                                <div class="filewrapper__file">
                                  <div>
                                    <img
                                      width="0"
                                      height="0"
                                      src="@/assets/thirdparty/icon/icooon_excel.png"
                                      style="
                                        margin-left: 0px;
                                        margin-right: 8px;
                                        margin-top: 5px;
                                        width: 14px;
                                        height: 14px;
                                      "
                                    />
                                  </div>
                                  <div>{{ datafileuploadname }}</div>
                                </div>
                                <div class="filewrapper__close" v-on:click="onClickDatafileCancel">
                                  <img
                                    width="0"
                                    height="0"
                                    class="filewrapper__close_img"
                                    src="@/assets/thirdparty/icon/icooon_close.png"
                                    style="width: 20px; height: 20px; border-radius: 50%"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="filedataarea__control">
                          <div class="filedataarea__flex">
                            <div>
                              <label class="filedataarea__selectbtn" for="datafile">
                                ファイル選択
                                <input type="file" id="datafile" />
                              </label>
                            </div>
                            <div class="filedataarea__uploadbtn_wrapper">
                              <button
                                v-on:click="datafileup"
                                class="filedataarea__uploadbtn"
                                v-if="datafileuploadname.length > 0"
                              >
                                シミュレーション登録
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="filedataarea__ddarea_overlay" v-if="page.filedataprogress">
                          <div>
                            <div
                              style="width: 400px; font-size: 12px; text-align: center"
                              v-if="page.isFileuploadError == false"
                            >
                              シミュレーション登録{{
                                page.fileuploadprogressPercent == 100 ? '完了' : '中'
                              }}
                            </div>
                            <div
                              style="width: 400px; font-size: 12px; text-align: center; color: #f00"
                              v-if="page.isFileuploadError == true"
                            >
                              シミュレーション登録失敗
                            </div>
                            <div style="width: 400px">
                              <BarProgress2
                                :value="page.fileuploadprogressPercent"
                                :stroke="1.0"
                                v-if="page.isFileuploadError == false"
                              ></BarProgress2>
                              <BarProgress3
                                value="100"
                                :stroke="1.0"
                                v-if="page.isFileuploadError == true"
                              ></BarProgress3>
                              <div
                                style="text-align: center"
                                v-if="page.fileuploadprogressPercent != 100"
                              >
                                {{ page.fileuploadprogressPercent }}%
                              </div>
                            </div>
                          </div>
                          <div style="display: flex" v-show="page.fileuploadprogressPercent == 100">
                            <div>
                              <button
                                v-on:click="onClickDataDone"
                                class="filedataarea__uploadbtn"
                                style="background: #f3f3f3; color: #111"
                              >
                                完了
                              </button>
                            </div>
                            <div>
                              <button v-on:click="onClickDataRetry" class="filedataarea__uploadbtn">
                                もう一度やり直す
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="isTimeRangeShow"
    style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; index: 999"
    v-on:click="closeTimeRange"
  ></div>
</template>

<style scoped>
.adx_sim {
  min-width: 1920px;
}
.btn_p {
  background: rgb(77, 134, 255);
  border: solid 1px rgb(77, 134, 255);
}
.btn_p:hover {
  background: rgb(77, 160, 255);
  border: solid 1px rgb(77, 164, 255);
}

.date_range {
  margin: auto;
  width: 680px;
  background: white;
  box-shadow: 0px 4px 10px #00000088;
}

.date_select_layer {
  position: fixed;
  top: 150px;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
}

.date_fromto_selector__decide {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #4d86ff;
  color: #fff;
  border: 1px solid #39434e;
  font: 400 13.3333px Arial;
  border-radius: 0.25rem;
  box-shadow: 0 0 2px 0 rgb(108 121 129);
  text-align: center;
}
</style>

<style scoped>
.fileformatarea {
  margin-top: 16px;
  position: relative;
}

.fileformatarea__ddarea {
  width: 100%;
  height: 104px;
  border-radius: 4px;
  padding: 8px;
}
.fileformatarea__ddarea {
  background-image: linear-gradient(
      to right,
      #d4d5d57f,
      #d4d5d57f 6px,
      transparent 3px,
      transparent 8px
    ),
    /* 上の線 */
      linear-gradient(to bottom, #d4d5d57f, #d4d5d57f 3px, transparent 6px, transparent 8px),
    /* 右の線 */
      linear-gradient(to left, #d4d5d57f, #d4d5d57f 6px, transparent 3px, transparent 8px),
    /* 下の線 */ linear-gradient(to top, #d4d5d57f, #d4d5d57f 3px, transparent 6px, transparent 8px); /* 左の線 */
  background-size: 12px 1px, /* 上の線 */ 1px 12px, /* 右の線 */ 12px 1px, /* 下の線 */ 1px 12px; /* 左の線 */
  background-position: left top, /* 上の線 */ right top, /* 右の線 */ right bottom,
    /* 下の線 */ left bottom; /* 左の線 */
  background-repeat: repeat-x, /* 上の線 */ repeat-y, /* 右の線 */ repeat-x, /* 下の線 */ repeat-y; /* 左の線 */
}
.fileformatarea__ddarea--active {
  background: #003399;
}
.fileformatarea__ddarea_overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 104px;
  border-radius: 4px;
  padding: 8px;
  background: rgba(0, 0, 0, 0.8);
}
.fileformatarea__control {
  position: absolute;
  top: 56px;
  padding: 8px;
  width: 400px;
}
.fileformatarea__flex {
  position: relative;
  display: flex;
}

.fileformatarea__selectbtn {
  background: #e3e3e3;
  color: #111;
  padding: 8px 24px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.fileformatarea__selectbtn:hover {
  background: #f3f3f3;
}

.fileformatarea__uploadbtn {
  background: #3b86ff;
  color: #fff;
  margin: 8px;
  padding: 6px 24px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0);
  font-size: 15px;
}
.fileformatarea__uploadbtn:hover {
  background: #3ba6ff;
}
.fileformatarea__uploadbtn_wrapper {
  position: absolute;
  top: -16px;
  left: 160px;
}

.filedataarea {
  margin-top: 16px;
  position: relative;
}

.filedataarea__ddarea {
  width: 100%;
  height: 104px;
  border-radius: 4px;
  padding: 8px;
}
.filedataarea__ddarea {
  background-image: linear-gradient(
      to right,
      #d4d5d57f,
      #d4d5d57f 6px,
      transparent 3px,
      transparent 8px
    ),
    /* 上の線 */
      linear-gradient(to bottom, #d4d5d57f, #d4d5d57f 3px, transparent 6px, transparent 8px),
    /* 右の線 */
      linear-gradient(to left, #d4d5d57f, #d4d5d57f 6px, transparent 3px, transparent 8px),
    /* 下の線 */ linear-gradient(to top, #d4d5d57f, #d4d5d57f 3px, transparent 6px, transparent 8px); /* 左の線 */
  background-size: 12px 1px, /* 上の線 */ 1px 12px, /* 右の線 */ 12px 1px, /* 下の線 */ 1px 12px; /* 左の線 */
  background-position: left top, /* 上の線 */ right top, /* 右の線 */ right bottom,
    /* 下の線 */ left bottom; /* 左の線 */
  background-repeat: repeat-x, /* 上の線 */ repeat-y, /* 右の線 */ repeat-x, /* 下の線 */ repeat-y; /* 左の線 */
}
.filedataarea__ddarea--active {
  background: #003399;
}
.filedataarea__ddarea_overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 104px;
  border-radius: 4px;
  padding: 8px;
  background: rgba(0, 0, 0, 0.8);
}
.filedataarea__control {
  position: absolute;
  top: 56px;
  padding: 8px;
  width: 400px;
}
.filedataarea__flex {
  position: relative;
  display: flex;
}

.filedataarea__selectbtn {
  background: #e3e3e3;
  color: #111;
  padding: 8px 24px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.filedataarea__selectbtn:hover {
  background: #f3f3f3;
}
.filedataarea__uploadbtn {
  background: #3b86ff;
  color: #fff;
  margin: 8px;
  padding: 6px 24px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0);
  font-size: 15px;
}
.filedataarea__uploadbtn:hover {
  background: #3ba6ff;
}
.filedataarea__uploadbtn_wrapper {
  position: absolute;
  top: -16px;
  left: 160px;
}

#filetemplate {
  display: none;
}
#datafile {
  display: none;
}

.dateselectarea {
  background: #fff;
  color: #000;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  cursor: pointer;
}
</style>

<style scoped>
.filewrapper {
  position: relative;
  width: fit-content;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: rgba(200, 200, 200, 0.3);
}

.filewrapper__file {
  display: flex;
}

.filewrapper__close {
  position: absolute;
  top: -8px;
  right: -8px;
}
.filewrapper__close_img {
  background: #fff;
}
.filewrapper__close_img:hover {
  background: #333;
}
</style>

<style scoped>
.daterangearrow {
  margin-top: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.daterangearrow:hover {
  background: #ccc;
}
</style>

<script>
// @ is an alias to /src
import ContentHeader from '@/components/budget/ContentHeader.vue';
import Spinner from '@/components/layout/Spinner.vue';

import { BudgetSimulationPage } from '@/js/page/budget/BudgetSimulationPage.js';
import RangeCalendarSimple from '@/components/RangeCalendarSimple.vue';
import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';

import BarProgress2 from '@/components/BarProgress2.vue';
import BarProgress3 from '@/components/BarProgress3.vue';

export default {
  name: 'BudgetSimulation',
  data() {
    return {
      name: 'アロケーション',
      page: new BudgetSimulationPage(),
      isShowDateSelect: false,
      dateRangeSelector: null,
      isLoad: false,
      templatefileuploadname: '',
      datafileuploadname: '',
      templatedrag: false,
      datadrag: false,
      isTimeRangeShow: false,
      isActive: true,
    };
  },
  emits: ['screenevent'],
  beforeUnmount() {
    this.isActive = false;
    this.adx.changeLastUpdate('');
  },
  mounted() {
    this.$emit('screenevent', 'budget');
    let self = this;
    let p = this.adx.getScreenStructureById('allocation').getPageById(1);
    if (p) {
      this.dateRangeSelector = this.adx.getScreenStructureById('allocation').dateRangeSelector;
      this.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
        //
      });
      this.initialize();
    } else {
      this.adx.getScreenStructureById('allocation').subject.addObserver(function () {
        let p = self.adx.getScreenStructureById('allocation').getPageById(1);
        if (p) {
          self.dateRangeSelector = self.adx.getScreenStructureById('allocation').dateRangeSelector;
          self.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
            //
          });
          self.initialize();
        }
      });
    }

    document.getElementById('filetemplate').onchange = function (event) {
      if (event) {
        //
      }
      self.templatefileuploadname = document.getElementById('filetemplate').files[0].name;
    };
    document.getElementById('datafile').onchange = function (event) {
      if (event) {
        //
      }
      self.datafileuploadname = document.getElementById('datafile').files[0].name;
    };
  },
  methods: {
    onClickEvent() {
      if (this.isTimeRangeShow) {
        this.isTimeRangeShow = false;
      }
    },
    startTimeRange() {
      this.isTimeRangeShow = true;
    },
    closeTimeRange() {
      this.isTimeRangeShow = false;
    },
    initialize() {
      const self = this;
      if (this.isLoad) {
        return;
      }
      this.isLoad = true;
      this.page.initialize(
        this.adx.getScreenStructureById('allocation').advertizerId,
        this.dateRangeSelector,
        this.adx.user
      );
      this.page.updateload(function (date) {
        if (self.isActive) {
          self.adx.changeLastUpdate(date);
        }
      });
    },
    download() {
      this.page.download();
    },
    formatup() {
      const filetemplate = document.querySelector('#filetemplate');
      this.page.fileformatupload(filetemplate.files[0]);
    },
    datafileup() {
      const datafile = document.querySelector('#datafile');
      this.page.fileupload(datafile.files[0], this.page.timeRange.timeYmdRange.getYm());
    },
    onCalc() {
      this.isShowDateSelect = false;
    },
    dateselectbackground(event) {
      if (event) {
        //
      }
      this.isShowDateSelect = false;
    },
    tabselect(id) {
      switch (id) {
        case 1:
          this.$router.push({ name: 'BudgetSimulation' });
          break;
        case 2:
          this.$router.push({ name: 'BudgetAllocation' });
          break;
      }
    },
    getTodayYmd() {
      let time = this.adx.getNowTime();
      return new TimeYmd(time.ymd.year, time.ymd.month, time.ymd.day);
    },
    simcalceevent: function (event) {
      if (event) {
        //
      }
      this.isShowDateSelect = true;
    },
    onDropFileFormat(event) {
      this.templatedrag = false;
      const filetemplate = document.getElementById('filetemplate');
      filetemplate.files = event.dataTransfer.files;
      this.templatefileuploadname = event.dataTransfer.files[0].name;
    },
    onDropFileData(event) {
      this.datadrag = false;
      const filetemplate = document.getElementById('datafile');
      filetemplate.files = event.dataTransfer.files;
      this.datafileuploadname = event.dataTransfer.files[0].name;
    },
    onClickFormatDone() {
      this.page.fileformatprogress = false;
      document.getElementById('filetemplate').value = '';
      this.templatefileuploadname = '';
    },
    onClickFormatRetry() {
      this.page.fileformatprogress = false;
    },
    onClickFormatfileCancel() {
      document.getElementById('filetemplate').value = '';
      this.templatefileuploadname = '';
    },
    onClickDataDone() {
      this.page.filedataprogress = false;
      document.getElementById('datafile').value = '';
      this.datafileuploadname = '';
    },
    onClickDataRetry() {
      this.page.filedataprogress = false;
    },
    onClickDatafileCancel() {
      document.getElementById('datafile').value = '';
      this.datafileuploadname = '';
    },
    decMonth() {
      this.page.timeRange.decMonth();
    },
    incMonth() {
      this.page.timeRange.incMonth();
    },
    date2Str() {
      if (this.isTimeRangeShow) {
        return 'シミュレーション期間選択';
      }
      if (this.page.timeRange?.timeYmdRange) {
        return this.page.timeRange.timeYmdRange.getYm().replace('-', '年') + '月';
      }
      return '期間選択';
    },
    date2StrDay1() {
      if (this.page.timeRange?.timeYmdRange) {
        return this.page.timeRange.timeYmdRange.getYm().replace('-', '/') + '/' + '1';
      }
      return '期間選択';
    },
    date2StrMonth() {
      if (this.page.timeRange?.timeYmdRange) {
        return this.page.timeRange.timeYmdRange.startYmd.month + '月';
      }
      return '期間選択';
    },
    date2StrEnglishMonth() {
      if (this.page.timeRange?.timeYmdRange) {
        let m = this.page.timeRange.timeYmdRange.startYmd.month;
        switch (this.page.timeRange.timeYmdRange.startYmd.month) {
          case 1:
            m = 'January';
            break;
          case 2:
            m = 'February';
            break;
          case 3:
            m = 'March';
            break;
          case 4:
            m = 'April';
            break;
          case 5:
            m = 'May';
            break;
          case 6:
            m = 'June';
            break;
          case 7:
            m = 'July';
            break;
          case 8:
            m = 'August';
            break;
          case 9:
            m = 'September';
            break;
          case 10:
            m = 'October';
            break;
          case 11:
            m = 'November';
            break;
          case 12:
            m = 'December';
            break;
        }
        return m + ' ' + this.page.timeRange.timeYmdRange.startYmd.year;
      }
      return '期間選択';
    },
  },
  components: {
    ContentHeader,
    Spinner,
    RangeCalendarSimple,
    BarProgress2,
    BarProgress3,
  },
  props: {
    adx: Object,
  },
};
</script>

