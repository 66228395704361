// source: report_page/dynamic_dimension_category_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.Graph', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.Graph.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.Rows', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.Rows.Table', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.TypeCase', null, global);
goog.exportSymbol('proto.adx.dynamic_dimension_category_report.Variables', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.displayName = 'proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.Rows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.Rows.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.Rows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.Rows.displayName = 'proto.adx.dynamic_dimension_category_report.Rows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.Rows.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.Rows.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.Rows.Table.displayName = 'proto.adx.dynamic_dimension_category_report.Rows.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.Variables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.Variables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.Variables.displayName = 'proto.adx.dynamic_dimension_category_report.Variables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.Graph = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.Graph.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.Graph, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.Graph.displayName = 'proto.adx.dynamic_dimension_category_report.Graph';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.displayName = 'proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.oneofGroups_);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.displayName = 'proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.displayName = 'proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.displayName = 'proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    campaign: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string campaign = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actualMonth = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string startDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string endDate = 7;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    campaign: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string campaign = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actualMonth = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string startDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string endDate = 7;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    campaign: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string campaign = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actualMonth = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string startDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string endDate = 7;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adpageid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest;
  return proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpageid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdpageid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adPageId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.prototype.getAdpageid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.prototype.setAdpageid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 advertizerId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string sort = 8;
 * @return {!Array<string>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 9;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    adpagecomponentid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagecomponentid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAdpagecomponentid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 adPageComponentId = 4;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.getAdpagecomponentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.setAdpagecomponentid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string actualMonth = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string startDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string endDate = 7;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 advertizerId = 8;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sortList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    advertizerid: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSort(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string sort = 8;
 * @return {!Array<string>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getSortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setSortList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.addSort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 advertizerId = 9;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adproviderid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actualmonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    advertizerid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualmonth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdvertizerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getActualmonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdvertizerid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 adProviderId = 2;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 categoryId = 3;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getCategoryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string actualMonth = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getActualmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setActualmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 advertizerId = 7;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.getAdvertizerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderRequest.prototype.setAdvertizerid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dynamicdimensioncategorymonthlyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getDynamicdimensioncategorymonthlyresultreportbyadproviderList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addDynamicdimensioncategorymonthlyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDynamicdimensioncategorymonthlyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table, 2));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DynamicDimensionCategoryMonthlyResultReportByAdProvider dynamicDimensionCategoryMonthlyResultReportByAdProvider = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getDynamicdimensioncategorymonthlyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setDynamicdimensioncategorymonthlyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.addDynamicdimensioncategorymonthlyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.clearDynamicdimensioncategorymonthlyresultreportbyadproviderList = function() {
  return this.setDynamicdimensioncategorymonthlyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.dynamic_dimension_category_report.Graph.toObject, includeInstance),
    dynamicdimensioncategoryweeklyresultgraphreportbyadprovider: (f = msg.getDynamicdimensioncategoryweeklyresultgraphreportbyadprovider()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.Graph;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.deserializeBinaryFromReader);
      msg.setDynamicdimensioncategoryweeklyresultgraphreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getDynamicdimensioncategoryweeklyresultgraphreportbyadprovider();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isalert: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsalert(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsalert();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string isAlert = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.getIsalert = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.setIsalert = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Graph graph = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.Graph>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.Graph, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.Graph>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.Graph}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * optional DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider dynamicDimensionCategoryWeeklyResultGraphReportByAdProvider = 4;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.getDynamicdimensioncategoryweeklyresultgraphreportbyadprovider = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider, 4));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryWeeklyResultGraphReportByAdProvider|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.setDynamicdimensioncategoryweeklyresultgraphreportbyadprovider = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.clearDynamicdimensioncategoryweeklyresultgraphreportbyadprovider = function() {
  return this.setDynamicdimensioncategoryweeklyresultgraphreportbyadprovider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result.prototype.hasDynamicdimensioncategoryweeklyresultgraphreportbyadprovider = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryWeeklyResultGraphReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.dynamic_dimension_category_report.Graph.toObject, includeInstance),
    dynamicdimensioncategorydailyresultgraphreportbyadproviderList: jspb.Message.toObjectList(msg.getDynamicdimensioncategorydailyresultgraphreportbyadproviderList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.Graph;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.deserializeBinaryFromReader);
      msg.addDynamicdimensioncategorydailyresultgraphreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getDynamicdimensioncategorydailyresultgraphreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isalert: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsalert(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsalert();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string isAlert = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.getIsalert = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.setIsalert = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Graph graph = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.Graph>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.Graph, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.Graph>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.Graph}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated DynamicDimensionCategoryDailyResultGraphReportByAdProvider dynamicDimensionCategoryDailyResultGraphReportByAdProvider = 4;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.getDynamicdimensioncategorydailyresultgraphreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.setDynamicdimensioncategorydailyresultgraphreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.addDynamicdimensioncategorydailyresultgraphreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.DynamicDimensionCategoryDailyResultGraphReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result.prototype.clearDynamicdimensioncategorydailyresultgraphreportbyadproviderList = function() {
  return this.setDynamicdimensioncategorydailyresultgraphreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDailyResultGraphReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getDynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCampaign(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addDynamicdimensioncategorydimensioncategorymonthlyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string campaign = 3;
 * @return {!Array<string>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getCampaignList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setCampaignList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.addCampaign = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.clearCampaignList = function() {
  return this.setCampaignList([]);
};


/**
 * repeated DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider dynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider = 4;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.getDynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.setDynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.addDynamicdimensioncategorydimensioncategorymonthlyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result.prototype.clearDynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList = function() {
  return this.setDynamicdimensioncategorydimensioncategorymonthlyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryMonthlyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.Rows.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.Rows.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.Rows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.Rows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.Rows.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.Rows.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.Rows}
 */
proto.adx.dynamic_dimension_category_report.Rows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.Rows;
  return proto.adx.dynamic_dimension_category_report.Rows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.Rows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.Rows}
 */
proto.adx.dynamic_dimension_category_report.Rows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.adx.dynamic_dimension_category_report.Rows.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.Rows.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.Rows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.Rows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.Rows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.Rows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.dynamic_dimension_category_report.Rows.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.Rows.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.Rows.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.Rows.Table}
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.Rows.Table;
  return proto.adx.dynamic_dimension_category_report.Rows.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.Rows.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.Rows.Table}
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.Rows.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.Rows.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.Rows.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.Rows.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.Rows.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.Rows.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.Rows} returns this
 */
proto.adx.dynamic_dimension_category_report.Rows.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Table table = 2;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.Rows.Table>}
 */
proto.adx.dynamic_dimension_category_report.Rows.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.Rows.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.Rows.Table, 2));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.Rows.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.Rows} returns this
*/
proto.adx.dynamic_dimension_category_report.Rows.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.Rows.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.Rows.Table}
 */
proto.adx.dynamic_dimension_category_report.Rows.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.dynamic_dimension_category_report.Rows.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.Rows} returns this
 */
proto.adx.dynamic_dimension_category_report.Rows.prototype.clearTableList = function() {
  return this.setTableList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.Variables.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.Variables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.Variables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.Variables.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.Variables}
 */
proto.adx.dynamic_dimension_category_report.Variables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.Variables;
  return proto.adx.dynamic_dimension_category_report.Variables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.Variables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.Variables}
 */
proto.adx.dynamic_dimension_category_report.Variables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.Variables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.Variables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.Variables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.Variables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.Variables.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.Variables} returns this
 */
proto.adx.dynamic_dimension_category_report.Variables.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.Variables.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.Variables} returns this
 */
proto.adx.dynamic_dimension_category_report.Variables.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categorytypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dimensioncategoryresultreportList: jspb.Message.toObjectList(msg.getDimensioncategoryresultreportList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategorytype(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.deserializeBinaryFromReader);
      msg.addDimensioncategoryresultreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategorytypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDimensioncategoryresultreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    proto.adx.dynamic_dimension_category_report.Variables.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.dynamic_dimension_category_report.Rows.toObject, includeInstance),
    nestcategoryList: jspb.Message.toObjectList(msg.getNestcategoryList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.dynamic_dimension_category_report.Variables;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.Variables.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.Rows;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.deserializeBinaryFromReader);
      msg.addNestcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.dynamic_dimension_category_report.Variables.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.Rows.serializeBinaryToWriter
    );
  }
  f = message.getNestcategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    proto.adx.dynamic_dimension_category_report.Variables.toObject, includeInstance),
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.adx.dynamic_dimension_category_report.Rows.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.adx.dynamic_dimension_category_report.Variables;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.Variables.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.Rows;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.Rows.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.dynamic_dimension_category_report.Variables.serializeBinaryToWriter
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.Rows.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Variables variables = 2;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.Variables>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.Variables>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.Variables, 2));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.Variables>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.Variables=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.Variables}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.dynamic_dimension_category_report.Variables, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.Rows>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.Rows>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.Rows}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Variables variables = 2;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.Variables>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.Variables>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.Variables, 2));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.Variables>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.Variables=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.Variables}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.dynamic_dimension_category_report.Variables, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};


/**
 * repeated Rows rows = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.Rows>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.Rows>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.Rows, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.Rows>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.Rows=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.Rows}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.Rows, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * repeated NestCategory nestCategory = 4;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.getNestcategoryList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory, 4));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.setNestcategoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.addNestcategory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.NestCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider.prototype.clearNestcategoryList = function() {
  return this.setNestcategoryList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string categoryType = 3;
 * @return {!Array<string>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.getCategorytypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.setCategorytypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.addCategorytype = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.clearCategorytypeList = function() {
  return this.setCategorytypeList([]);
};


/**
 * repeated DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider dimensionCategoryResultReport = 4;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.getDimensioncategoryresultreportList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.setDimensioncategoryresultreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.addDimensioncategoryresultreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result.prototype.clearDimensioncategoryresultreportList = function() {
  return this.setDimensioncategoryresultreportList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryMonthlyResultReportPerAdProviderOrCategoryByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dynamicdimensioncategorydimensioncategoryweeklyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getDynamicdimensioncategorydimensioncategoryweeklyresultreportbyadproviderList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCampaign(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addDynamicdimensioncategorydimensioncategoryweeklyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDynamicdimensioncategorydimensioncategoryweeklyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string campaign = 3;
 * @return {!Array<string>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.getCampaignList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.setCampaignList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.addCampaign = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.clearCampaignList = function() {
  return this.setCampaignList([]);
};


/**
 * repeated DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider dynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider = 4;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.getDynamicdimensioncategorydimensioncategoryweeklyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.setDynamicdimensioncategorydimensioncategoryweeklyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.addDynamicdimensioncategorydimensioncategoryweeklyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result.prototype.clearDynamicdimensioncategorydimensioncategoryweeklyresultreportbyadproviderList = function() {
  return this.setDynamicdimensioncategorydimensioncategoryweeklyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryWeeklyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.Graph.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.Graph.TypeCase = {
  TYPE_NOT_SET: 0,
  BAR: 1,
  LINE: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.Graph.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.Graph.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.Graph.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.Graph.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.Graph} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.Graph.toObject = function(includeInstance, msg) {
  var f, obj = {
    bar: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.Graph}
 */
proto.adx.dynamic_dimension_category_report.Graph.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.Graph;
  return proto.adx.dynamic_dimension_category_report.Graph.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.Graph} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.Graph}
 */
proto.adx.dynamic_dimension_category_report.Graph.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBar(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.Graph.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.Graph} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.Graph.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string bar = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.getBar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.Graph} returns this
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.setBar = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.adx.dynamic_dimension_category_report.Graph.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.Graph} returns this
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.clearBar = function() {
  return jspb.Message.setOneofField(this, 1, proto.adx.dynamic_dimension_category_report.Graph.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.hasBar = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string line = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.getLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.Graph} returns this
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.setLine = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.Graph.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.Graph} returns this
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.clearLine = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.Graph.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.Graph.prototype.hasLine = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    campaignList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.dynamic_dimension_category_report.Graph.toObject, includeInstance),
    dynamicdimensioncategorydimensioncategorydailyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getDynamicdimensioncategorydimensioncategorydailyresultreportbyadproviderList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCampaign(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.Graph;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 5:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addDynamicdimensioncategorydimensioncategorydailyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCampaignList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getDynamicdimensioncategorydimensioncategorydailyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaign(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string campaign = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.getCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.setCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string campaign = 3;
 * @return {!Array<string>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getCampaignList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setCampaignList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.addCampaign = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.clearCampaignList = function() {
  return this.setCampaignList([]);
};


/**
 * repeated Graph graph = 4;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.Graph>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.Graph, 4));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.Graph>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.Graph}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.dynamic_dimension_category_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider dynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider = 5;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.getDynamicdimensioncategorydimensioncategorydailyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider, 5));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.setDynamicdimensioncategorydimensioncategorydailyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.addDynamicdimensioncategorydimensioncategorydailyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result.prototype.clearDynamicdimensioncategorydimensioncategorydailyresultreportbyadproviderList = function() {
  return this.setDynamicdimensioncategorydimensioncategorydailyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryDailyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adgroupList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dynamicdimensioncategorydimensioncategoryadgroupmonthlyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getDynamicdimensioncategorydimensioncategoryadgroupmonthlyresultreportbyadproviderList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdgroup(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addDynamicdimensioncategorydimensioncategoryadgroupmonthlyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdgroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDynamicdimensioncategorydimensioncategoryadgroupmonthlyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adgroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdgroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdgroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adGroup = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.getAdgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.setAdgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string adGroup = 3;
 * @return {!Array<string>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.getAdgroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.setAdgroupList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.addAdgroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.clearAdgroupList = function() {
  return this.setAdgroupList([]);
};


/**
 * repeated DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider dynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider = 4;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.getDynamicdimensioncategorydimensioncategoryadgroupmonthlyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.setDynamicdimensioncategorydimensioncategoryadgroupmonthlyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.addDynamicdimensioncategorydimensioncategoryadgroupmonthlyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result.prototype.clearDynamicdimensioncategorydimensioncategoryadgroupmonthlyresultreportbyadproviderList = function() {
  return this.setDynamicdimensioncategorydimensioncategoryadgroupmonthlyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupMonthlyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adgroupList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dynamicdimensioncategorydimensioncategoryadgroupweeklyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getDynamicdimensioncategorydimensioncategoryadgroupweeklyresultreportbyadproviderList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdgroup(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addDynamicdimensioncategorydimensioncategoryadgroupweeklyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdgroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDynamicdimensioncategorydimensioncategoryadgroupweeklyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adgroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdgroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdgroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adGroup = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.getAdgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.setAdgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string adGroup = 3;
 * @return {!Array<string>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.getAdgroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.setAdgroupList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.addAdgroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.clearAdgroupList = function() {
  return this.setAdgroupList([]);
};


/**
 * repeated DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider dynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider = 4;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.getDynamicdimensioncategorydimensioncategoryadgroupweeklyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider, 4));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.setDynamicdimensioncategorydimensioncategoryadgroupweeklyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.addDynamicdimensioncategorydimensioncategoryadgroupweeklyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result.prototype.clearDynamicdimensioncategorydimensioncategoryadgroupweeklyresultreportbyadproviderList = function() {
  return this.setDynamicdimensioncategorydimensioncategoryadgroupweeklyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupWeeklyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structure: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adgroupList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.adx.dynamic_dimension_category_report.Graph.toObject, includeInstance),
    dynamicdimensioncategorydimensioncategoryadgroupdailyresultreportbyadproviderList: jspb.Message.toObjectList(msg.getDynamicdimensioncategorydimensioncategoryadgroupdailyresultreportbyadproviderList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructure(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAdgroup(value);
      break;
    case 4:
      var value = new proto.adx.dynamic_dimension_category_report.Graph;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.Graph.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    case 5:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.deserializeBinaryFromReader);
      msg.addDynamicdimensioncategorydimensioncategoryadgroupdailyresultreportbyadprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdgroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.adx.dynamic_dimension_category_report.Graph.serializeBinaryToWriter
    );
  }
  f = message.getDynamicdimensioncategorydimensioncategoryadgroupdailyresultreportbyadproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adgroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableList: jspb.Message.toObjectList(msg.getTableList(),
    proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdgroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.deserializeBinaryFromReader);
      msg.addTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdgroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table;
  return proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adGroup = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.getAdgroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.setAdgroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Table table = 3;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.getTableList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table, 3));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.setTableList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.addTable = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.Table, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider.prototype.clearTableList = function() {
  return this.setTableList([]);
};


/**
 * optional string requestId = 1;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string structure = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getStructure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setStructure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string adGroup = 3;
 * @return {!Array<string>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getAdgroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setAdgroupList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.addAdgroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.clearAdgroupList = function() {
  return this.setAdgroupList([]);
};


/**
 * repeated Graph graph = 4;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.Graph>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.Graph>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.Graph, 4));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.Graph>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setGraphList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.Graph=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.Graph}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.adx.dynamic_dimension_category_report.Graph, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.clearGraphList = function() {
  return this.setGraphList([]);
};


/**
 * repeated DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider dynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider = 5;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider>}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.getDynamicdimensioncategorydimensioncategoryadgroupdailyresultreportbyadproviderList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider, 5));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.setDynamicdimensioncategorydimensioncategoryadgroupdailyresultreportbyadproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.addDynamicdimensioncategorydimensioncategoryadgroupdailyresultreportbyadprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result.prototype.clearDynamicdimensioncategorydimensioncategoryadgroupdailyresultreportbyadproviderList = function() {
  return this.setDynamicdimensioncategorydimensioncategoryadgroupdailyresultreportbyadproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.DynamicDimensionCategoryDimensionCategoryAdGroupDailyResultReportByAdProviderResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.TypeCase}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.getTypeCase = function() {
  return /** @type {proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse;
  return proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    adpagesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adproviderList: jspb.Message.toObjectList(msg.getAdproviderList(),
    proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result;
  return proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdpagesid(value);
      break;
    case 2:
      var value = new proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider;
      reader.readMessage(value,proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.deserializeBinaryFromReader);
      msg.addAdprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdpagesid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    adproviderid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tab: jspb.Message.getFieldWithDefault(msg, 2, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 3, ""),
    providerforshort: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider;
  return proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdproviderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTab(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderforshort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdproviderid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTab();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProviderforshort();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 adProviderId = 1;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.getAdproviderid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.setAdproviderid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tab = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.getTab = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.setTab = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string provider = 3;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string providerForShort = 4;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.getProviderforshort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider.prototype.setProviderforshort = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 adPagesId = 1;
 * @return {number}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.prototype.getAdpagesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.prototype.setAdpagesid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated AdProvider adProvider = 2;
 * @return {!Array<!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider>}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.prototype.getAdproviderList = function() {
  return /** @type{!Array<!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider, 2));
};


/**
 * @param {!Array<!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider>} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result} returns this
*/
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.prototype.setAdproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.prototype.addAdprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.AdProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result.prototype.clearAdproviderList = function() {
  return this.setAdproviderList([]);
};


/**
 * optional Result result = 1;
 * @return {?proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.getResult = function() {
  return /** @type{?proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result, 1));
};


/**
 * @param {?proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.Result|undefined} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse} returns this
*/
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse} returns this
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.adx.dynamic_dimension_category_report.TabListOfDynamicDimensionCategoryReportPageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.adx.dynamic_dimension_category_report);
