/**
 * @fileoverview gRPC-Web generated client stub for adx.consultant_ad_provider_report
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.adx = {};
proto.adx.consultant_ad_provider_report = require('./consultant_ad_provider_report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderDimensionCategoryCampaignAdGroupMonthlyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderDimensionCampaignAdGroupMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderDimensionCampaignAdGroupMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderDimensionCampaignAdGroupMonthlyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoryMonthlyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoryMonthlyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderCategoryMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoryMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoryMonthlyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderCategoryMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoryMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoryMonthlyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoryWeeklyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoryWeeklyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderCategoryWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoryWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoryWeeklyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderCategoryWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoryWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoryWeeklyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoryDailyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoryDailyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderCategoryDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoryDailyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoryDailyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoryDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderCategoryDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoryDailyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoryDailyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCampaignMonthlyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCampaignMonthlyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderCampaignMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCampaignMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCampaignMonthlyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderCampaignMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCampaignMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCampaignMonthlyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCampaignWeeklyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCampaignWeeklyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderCampaignWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCampaignWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCampaignWeeklyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderCampaignWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCampaignWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCampaignWeeklyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCampaignDailyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCampaignDailyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderCampaignDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCampaignDailyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCampaignDailyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCampaignDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderCampaignDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCampaignDailyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCampaignDailyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderAdGroupMonthlyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderAdGroupMonthlyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderAdGroupMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderAdGroupMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderAdGroupMonthlyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderAdGroupMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderAdGroupMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderAdGroupMonthlyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderAdGroupWeeklyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderAdGroupWeeklyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderAdGroupWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderAdGroupWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderAdGroupWeeklyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderAdGroupWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderAdGroupWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderAdGroupWeeklyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderAdGroupDailyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderAdGroupDailyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderAdGroupDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderAdGroupDailyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderAdGroupDailyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderAdGroupDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderAdGroupDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderAdGroupDailyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderAdGroupDailyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoriesDimensionMonthlyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoriesDimensionMonthlyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderCategoriesDimensionMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoriesDimensionMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoriesDimensionMonthlyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionMonthlyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderCategoriesDimensionMonthlyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoriesDimensionMonthlyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoriesDimensionMonthlyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoriesDimensionWeeklyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoriesDimensionWeeklyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderCategoriesDimensionWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoriesDimensionWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoriesDimensionWeeklyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionWeeklyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderCategoriesDimensionWeeklyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoriesDimensionWeeklyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoriesDimensionWeeklyReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest,
 *   !proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse>}
 */
const methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoriesDimensionDailyReport = new grpc.web.MethodDescriptor(
  '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoriesDimensionDailyReport',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest,
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse,
  /**
   * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse.deserializeBinary
);


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportClient.prototype.getConsultantAdProviderCategoriesDimensionDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoriesDimensionDailyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoriesDimensionDailyReport);
};


/**
 * @param {!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.adx.consultant_ad_provider_report.GetConsultantAdProviderCategoriesDimensionDailyReportResponse>}
 *     The XHR Node Readable Stream
 */
proto.adx.consultant_ad_provider_report.ConsultantAdProviderReportPromiseClient.prototype.getConsultantAdProviderCategoriesDimensionDailyReport =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/adx.consultant_ad_provider_report.ConsultantAdProviderReport/GetConsultantAdProviderCategoriesDimensionDailyReport',
      request,
      metadata || {},
      methodDescriptor_ConsultantAdProviderReport_GetConsultantAdProviderCategoriesDimensionDailyReport);
};


module.exports = proto.adx.consultant_ad_provider_report;

