<template>
  <div tabindex="0" ref="fmain" class="tabledivreset" style="height: initial" v-on:blur="lost()">
    <div
      style="
        padding: 8px;
        background: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 16%) 0px 3px 20px;
      "
    >
      <template v-for="(option, index) in optionitems()" :key="index">
        <div
          class="msitem"
          v-bind:class="[isSelect(option.name) ? 'msitem--selected' : '']"
          v-on:click="onCheck(option.name)"
        >
          <img
            style="margin-top: 4px"
            width="10"
            src="@/assets/icon/fa_check-square-regular.svg"
            v-if="!isSelect(option.name)"
          /><img
            style="margin-top: 4px"
            width="10"
            src="@/assets/icon/fa_check-square-solid.svg"
            v-if="isSelect(option.name)"
          />
          {{ option.name }}
        </div>
      </template>
    </div>
  </div>

</template>

<style scoped>

.floater_table__body div {
  height: initial;
}

.tabledivreset:focus{
  outline: none;
}

</style>

<style scoped>
.selectitem {
  text-align: center;
  padding: 8px;
}

.selectitem:hover {
  background: rgba(200, 200, 200, 0.5);
}

.edtcstselectitem:focus {
  outline: solid 1px #00000029;
  box-shadow: 0px 3px 20px #00000029;
}

</style>

<script>
export default {
  name: 'EditCstMultiSelectItem',
  data() {
    return {
      name: 'EditCstMultiSelectItem',
      selects: '',
      options: [],
    };
  },
  computed: {},
  mounted() {
    this.selects = this.item.value;
    this.$refs.fmain.focus();
  },
  methods: {
    optionitems() {
      let ops = [];
      // this.item.options
      for (let i in this.item.options) {
        let isTarget = true;
        let isHit = false;
        switch(this.item.options[i].name) {
          case "各論運用施策":
            for (let j in this.selects) {
              if (this.selects[j] == "各論運用施策") {
                isHit = true;
                break;
              }
            }
            if (!isHit) {
              isTarget = false;
            }
            break;
          case "上流行程施策":
            for (let j in this.selects) {
              if (this.selects[j] == "上流行程施策") {
                isHit = true;
                break;
              }
            }
            if (!isHit) {
              isTarget = false;
            }
            break;
          case "コミュニケーション施策":
            for (let j in this.selects) {
              if (this.selects[j] == "コミュニケーション施策") {
                isHit = true;
                break;
              }
            }
            if (!isHit) {
              isTarget = false;
            }
            break;
        }
        if (isTarget) {
          ops.push(this.item.options[i]);
        }
      }

      return ops;
    },
    onCheck(name) {
      let isHit = false;
      for (let i = 0; i < this.selects.length; i++) {
        if (this.selects[i] == name) {
          this.selects.splice(i, 1);
          isHit = true;
          break;
        }
      }
      if (!isHit) {
        this.selects.push(name);
      }
    },
    isSelect(name) {
      for (let i in this.selects) {
        if (this.selects[i] == name) {
          return true;
        }
      }
      return false;
    },
    lost() {
      this.item.decide();
    },
  },
  components: {},
  props: {
    mode: Number,
    item: Object,
    next: Object,
    prev: Object,
  },
};
</script>
