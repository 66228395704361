function TimeYmd(y = 2021, m = 5, d = 1) {
    this.year = y;
    this.month = m;
    this.day = d;
}
TimeYmd.prototype.isLeapYear = function () {
    if ((this.year % 4 === 0 && this.year % 100 !== 0) || this.year % 400 === 0) {
        return true;
    }
    return false;
};
TimeYmd.prototype.getYm = function () {
    return this.year + "-" + ('0' + this.month).slice(-2);
};
TimeYmd.prototype.getYmd = function () {
    return this.year + "-" + ('0' + this.month).slice(-2) + "-" + this.day;
};
TimeYmd.prototype.incYear = function () {
    this.year++;
    this._autoDayRefresh();
};
TimeYmd.prototype.decYear = function () {
    this.year--;
    this._autoDayRefresh();
}
TimeYmd.prototype.incMonth = function () {
    this.month++;
    if (this.month > 12) {
        this.month = 1;
        this.year++;
    }
    this._autoDayRefresh();
};
TimeYmd.prototype.decMonth = function () {
    this.month--;
    if (this.month < 1) {
        this.month = 12;
        this.year--;
    }
    this._autoDayRefresh();
};
TimeYmd.prototype._autoDayRefresh = function () {
    let lastDay = new Date(this.year, this.month, 0).getDate();
    if (lastDay < this.day) {
        this.day = lastDay;
    }
};
TimeYmd.prototype.calcDay = function (day) {
    let d = new Date(this.year, this.month - 1, this.day)
    d.setDate(this.day + day);

    this.year = d.getFullYear();
    this.month = d.getMonth() + 1;
    this.day = d.getDate();
};
TimeYmd.prototype.getYmdString = function () {
    return this.year + "年" + ('0' + this.month).slice(-2) + "/" + ('0' + this.day).slice(-2);
};
TimeYmd.prototype.getYmdNenGatsuHiString = function () {
    return this.year + "年" + ('0' + this.month).slice(-2) + "月" + ('0' + this.day).slice(-2) + "日";
};
TimeYmd.prototype.getYyyymmddString = function () {
    return this.year + "/" + ('0' + this.month).slice(-2) + "/" + ('0' + this.day).slice(-2);
};

export { TimeYmd }
